import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { LogService } from 'src/app/core/log.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { TranslateService } from '@ngx-translate/core';
import { MessageService } from 'src/app/core/message.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { Guid } from 'src/app/shared/helpers/guid';
import { Subject } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { FormGroupService } from 'src/app/shared/services/form-group.service';
import { ProjectVersionsService } from '../project-versions.service';
import { StateService } from '@uirouter/core';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import {
  CreateProjectVersionMode,
  CreateProjectVersionModes,
} from 'src/app/shared/models/enums/create-project-version-mode.enum';
import { CreateProjectVersionModeItem } from 'src/app/shared/models/entities/projects/create-project-version-mode-item.model';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**
 * Represents the Create Project version dialog content.
 * */
@Component({
  selector: 'tmt-create-project-version-modal',
  templateUrl: './create-project-version-modal.component.html',
  standalone: false,
})
export class CreateProjectVersionModalComponent implements OnInit {
  @Input() moveToCard = false;
  @Input() sourceVersions: ProjectVersion[] = [];
  @Input() sourceVersion: ProjectVersion;

  collection = this.data.collection('ProjectVersions');

  public isLoading = false;
  public isSaving = false;
  public isSourceVersionCtrlVisible: boolean;

  public form = this.fb.group({
    id: '',
    name: [
      null,
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    sourceVersion: [null, Validators.required],
    mode: null,
  });

  public get modes(): Array<any> {
    return this._modes;
  }

  private _modes: Array<CreateProjectVersionModeItem>;
  private _sourceVersionsCopy: ProjectVersion[] = [];
  private destroyRef = inject(DestroyRef);

  constructor(
    private service: ProjectVersionsService,
    private versionCardService: ProjectVersionCardService,
    private log: LogService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private formGroupService: FormGroupService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private message: MessageService,
    private data: DataService,
    private state: StateService,
  ) {}

  public ngOnInit(): void {
    this._modes = CreateProjectVersionModes.map((m) => ({
      id: Guid.generate(),
      name: this.translate.instant(
        `projects.projectVersions.createProjectVersionModal.props.mode.values.${m[0]
          .toLowerCase()
          .concat(m.slice(1))}`,
      ),
      value: m,
    }));
    this._sourceVersionsCopy = this.sourceVersions.slice();

    this.initCreateForm();
  }

  /**
   * The modal Ok button click handler.
   * Creates new/Saves the Project version.
   * */
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.updateFormState(true);
    this.log.debug('Saving version entity...');

    const projectVersion = this.form.getRawValue() as ProjectVersion;
    const mode = this.form.controls.mode.value.value;
    const sourceVersionId =
      !ProjectVersionUtil.isWorkProjectVersion(projectVersion.sourceVersion) &&
      mode === CreateProjectVersionMode.CopyEstimate
        ? projectVersion.sourceVersion.id
        : null;

    const data = {
      id: projectVersion.id,
      name: projectVersion.name,
      sourceVersionId,
      mainProjectId: this.sourceVersion.mainProject.id,
      mode,
    };

    this.collection
      .action('CreateVersion')
      .execute(data)
      .pipe(
        switchMap(() => this.service.getVersion(this.form.value.id)),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (version) => this.okFunc(version),
        error: this.errorFunc,
      });
  }

  /**
   * The modal Cancel/Cross button click handler.
   * Closes the active modal.
   * */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Initializes the Project version form group in create mode.
   * */
  private initCreateForm(): void {
    this.isLoading = true;
    this.updateControlVisibility();

    const projectVersion = {
      id: Guid.generate(),
      name: '',
      sourceVersion: this.sourceVersion,
      mode: this._modes.find(
        (x) => x.value === CreateProjectVersionMode.CopyEstimate,
      ),
    };

    this.form.patchValue(projectVersion);

    this.service
      .getVersionNamePrefix(this.sourceVersion.mainProject.id)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (prefix) => {
          projectVersion.name = prefix;
          this.form.patchValue(projectVersion);
          this.isLoading = false;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading = false;
        },
      });

    this.form.controls.mode.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.handleModeChange(value);
      });
  }

  private updateControlVisibility(): void {
    this.isSourceVersionCtrlVisible =
      this.form.controls.mode.value?.value !== CreateProjectVersionMode.Empty;
  }

  private updateFormState(disable: boolean): void {
    if (disable) {
      this.form.disable();
    } else {
      this.form.enable();
    }
  }

  /**
   * Project version mode form control value change handler.
   * Updates Project version form group.
   *
   * @param mode New mode form control value.
   * */
  private handleModeChange(mode: CreateProjectVersionModeItem): void {
    const sourceVersionCtrl = this.form.controls
      .sourceVersion as UntypedFormControl;
    if (mode.value === CreateProjectVersionMode.CopyForecast) {
      this.sourceVersions = this.sourceVersions.filter((x) =>
        ProjectVersionUtil.isWorkProjectVersion(x),
      );
      if (!ProjectVersionUtil.isWorkProjectVersion(sourceVersionCtrl.value)) {
        sourceVersionCtrl.patchValue(this.sourceVersions[0]);
      }
    } else {
      this.sourceVersions = this._sourceVersionsCopy.slice();
    }
    if (mode.value === CreateProjectVersionMode.Empty) {
      this.formGroupService.toggleFormControlValidators(sourceVersionCtrl);
    } else {
      this.formGroupService.toggleFormControlValidators(
        sourceVersionCtrl,
        Validators.required,
      );
    }
    this.updateControlVisibility();
  }

  /**
   * The Project version Create success event handler.
   *
   * @param version The created Project version.
   * */
  private okFunc = (version: ProjectVersion): void => {
    this.isSaving = false;

    this.notification.successLocal(
      'projects.projectVersions.createProjectVersionModal.messages.created',
    );

    this.activeModal.close();
    if (this.moveToCard) {
      // Slide to the Project version card after creation.
      this.state.go('projectVersion', {
        routeMode: RouteMode.continue,
        entityId: version.id,
        projectId: this.sourceVersion.mainProject.id,
      });
    } else {
      // Open the Project version on the Project card after creation.
      this.versionCardService.openVersionCard(version);
    }
  };

  /**
   * The Project version Create error event handler.
   *
   * @param error The error.
   * */
  private errorFunc = (error: Exception): void => {
    this.message.errorDetailed(error);
    this.updateFormState(false);
    this.isSaving = false;
  };
}
