import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectCardService } from '../../core/project-card.service';
import { FinancialTaskCellService } from '../../shared/financial-task-cell/financial-task-cell.service';
import { ProjectRevenueEstimatesService } from './project-revenue-estimates.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-project-revenue-estimates',
  templateUrl: './project-revenue-estimates.component.html',
  providers: [
    SavingQueueService,
    ListService,
    GridService,
    ProjectRevenueEstimatesService,
    FinancialTaskCellService,
  ],
  standalone: false,
})
export class ProjectRevenueEstimatesComponent implements OnInit, OnDestroy {
  private projectSubscription: Subscription;
  private reloadListener: Subscription;

  constructor(
    private projectService: ProjectCardService,
    public projectRevenueService: ProjectRevenueEstimatesService,
    private savingQueueService: SavingQueueService,
  ) {}

  ngOnInit(): void {
    this.projectSubscription = this.projectService.project$.subscribe(
      (project: Project) => {
        this.projectRevenueService.setReadonly(
          !project.revenueEstimateEditAllowed,
        );
        this.projectRevenueService.updateWorkProjectVersionFlag();
      },
    );

    this.reloadListener = this.projectService.reloadTab$.subscribe(() =>
      this.projectRevenueService.load(),
    );

    this.projectRevenueService.init();
  }

  ngOnDestroy(): void {
    this.projectSubscription.unsubscribe();
    this.reloadListener.unsubscribe();
  }
}
