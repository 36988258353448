import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import {
  TimeOffRequest,
  TimesheetLine,
} from 'src/app/shared/models/entities/base/timesheet.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { TranslateService } from '@ngx-translate/core';
import { TimesheetCardService } from '../core/timesheet-card.service';
import { TimesheetTemplate } from 'src/app/shared/models/entities/settings/timesheet-template.model';
import { Day } from '../shared/models/day.model';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { Line } from '../shared/models/line.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { NotificationService } from 'src/app/core/notification.service';
import { filter, switchMap, tap } from 'rxjs/operators';
import _ from 'lodash';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { LocalStorageService } from 'ngx-webstorage';
import { NavigationService } from 'src/app/core/navigation.service';
import { DateTime } from 'luxon';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { Command } from 'src/app/shared-features/grid/models/grid-options.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { of } from 'rxjs';

/** Табличное представление таймшита. */
@Component({
  selector: 'wp-table-view',
  templateUrl: './table-view.component.html',
  styleUrls: ['./table-view.component.scss'],
  providers: [CellsOrchestratorService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TableViewComponent implements OnInit, OnDestroy {
  // Constants.
  public readonly columnsWidths: Record<string, number> = {
    taskColumnWidth: 335,
    checkBoxColumnWidth: 40,
    roleRateColumnWidth: 110,
    activityColumnWidth: 110,
    customColumnWidth: 110,
    projectCostCenterColumnWidth: 110,
    projectTariffColumnWidth: 110,
  };
  public readonly propagationMode = PropagationMode.onExitFromEditing;
  public readonly routeMode = RouteMode;
  public readonly = true;
  public hasLinesInStorage = signal<boolean>(false);
  public addingCommands: Command[];
  public fixTableColumnCount = 0;
  public fixTableWidth: number;
  public dataTableWidth: number;
  public days: Day[] = [];
  public totalHours: number;
  /** Number of scheduled hours for timesheet. */
  public totalSchedule: number;

  private destroyRef = inject(DestroyRef);

  public get template(): TimesheetTemplate {
    return this.service.timesheet?.template;
  }

  public get timeOffRequests(): TimeOffRequest[] {
    return this.service.timesheet?.timeOffRequests;
  }

  constructor(
    public navigationService: NavigationService,
    public service: TimesheetCardService,
    private autosave: SavingQueueService,
    private stopwatchService: StopwatchService,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    private cellsOrchestrator: CellsOrchestratorService,
    private translate: TranslateService,
    private customFieldService: CustomFieldService,
    private app: AppService,
    private blockUI: BlockUIService,
    private changeDetector: ChangeDetectorRef,
    private localStorageService: LocalStorageService,
    private infoPopupService: InfoPopupService,
  ) {
    cellsOrchestrator.selectedCells$
      .pipe(
        filter((v) => v > 1),
        takeUntilDestroyed(),
      )
      .subscribe(() => this.infoPopupService.close());
  }

  ngOnInit() {
    this.addingCommands = [];

    this.addingCommands.push({
      handlerFn: () => this.copyLines(),
      name: 'copyLines',
      label: 'timesheets.card.actions.copyLines',
    });

    if (this.app.session.configuration.copyHoursAllowed) {
      this.addingCommands.push({
        name: 'copyLinesWithHours',
        handlerFn: () => this.copyLines(true),
        label: 'timesheets.card.actions.copyLinesWithHours',
      });
    }

    this.addingCommands.push({
      name: 'createLinesFromResourcePlan',
      handlerFn: () => this.createLinesFromResourcePlan(),
      label: 'timesheets.card.actions.createLinesFromResourcePlan',
    });

    if (this.app.session.configuration.copyHoursAllowed) {
      this.addingCommands.push({
        name: 'createLinesFromResourcePlanWithHours',
        handlerFn: () => this.createLinesFromResourcePlan(true),
        label: 'timesheets.card.actions.createLinesFromResourcePlanWithHours',
      });
    }

    if (
      this.app.checkFeature(Feature.timeOff) &&
      this.app.checkEntityPermission('TimeOffRequest', PermissionType.Modify)
    ) {
      this.addingCommands.push({
        name: 'addTimeOffRequest',
        handlerFn: () => this.addTimeOffRequest(),
        label: 'timesheets.card.actions.addTimeOffLine',
      });
    }

    this.service.timesheet$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.fillOutDays();
        this.calculateWidth();

        this.service.fillOutDataLines();
        this.service.fillOutIssueDataLines();

        if (!this.service.timesheet.editAllowed) {
          this.service.dataLines.disable({ emitEvent: false });
        }

        this.readonly = !this.service.timesheet.editAllowed;
        this.cellsOrchestrator.init();

        this.calculateTotals();
      });

    this.service.selectAllControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const value = this.service.selectAllControl.value as boolean;
        this.service.selectControls.controls.forEach(
          (control: UntypedFormControl) => {
            control.setValue(value, { emitEvent: false });
          },
        );
      });

    this.service.selectControls.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const values = this.service.selectControls.value as boolean[];

        if (values.length === 0 || values.some((v) => !v)) {
          this.service.selectAllControl.setValue(false, { emitEvent: false });
        }
      });

    this.service.linesUpdated$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.calculateTotals();
        this.changeDetector.markForCheck();
      });

    this.hasLinesInStorage.set(
      this.localStorageService.retrieve(this.service.timesheetLinesStorageName)
        ?.length > 0,
    );
  }

  ngOnDestroy(): void {
    this.cellsOrchestrator.dispose();
  }

  public hasSelectedLines(): boolean {
    return (this.service.selectControls.value as boolean[]).some(
      (control) => control,
    );
  }

  /** Deletes selected lines. */
  public removeLines(): void {
    const removeNextLine = () => {
      if (!this.hasSelectedLines()) {
        setTimeout(() => this.cellsOrchestrator.reset());
        return;
      }
      const selectedLines = this.service.selectControls.value as boolean[];
      const index = selectedLines.findIndex((sl) => sl);

      const needStopStopwatch =
        this.stopwatchService.stopwatch?.timeSheetLineId ===
        this.service.dataLines.at(index).value.id;

      if (needStopStopwatch) {
        this.notification.warningLocal('timesheets.stopwatchMustBeStopped');
        this.service.selectControls.at(index).setValue(false);
      } else {
        const id = this.service.dataLines.at(index).getRawValue().id;
        this.service.dataLines.removeAt(index);
        this.service.selectControls.removeAt(index);
        this.autosave.addToQueue(
          id,
          this.service.timeSheetLineCollection
            .entity(id)
            .delete({ withResponse: true })
            .pipe(
              tap((result) => {
                this.service.resolveIndirectUpdates(result.updated);
                removeNextLine();
              }),
            ),
        );
      }
    };

    removeNextLine();
  }

  /** Copies selected lines to the buffer. */
  public copyLinesToStorage() {
    const result: Partial<TimesheetLine>[] = [];

    this.service.selectControls.controls.forEach((control, index) => {
      if (control.value) {
        const selectedLine = this.service.dataLines.at(index);

        if (selectedLine.value.task?.projectTask) {
          const data: Partial<TimesheetLine> = {};
          this.customFieldService.assignValues(
            data,
            selectedLine.value,
            'TimeSheetLine',
          );
          data.task = _.cloneDeep(selectedLine.value.task);
          data.activity = _.cloneDeep(selectedLine.value.activity);
          data.role = _.cloneDeep(selectedLine.value.role);
          data.projectCostCenter = _.cloneDeep(
            selectedLine.value.projectCostCenter,
          );
          data.projectTariff = _.cloneDeep(selectedLine.value.projectTariff);

          result.push(data);
        }
      }
    });

    if (result.length > 0) {
      this.localStorageService.store(
        this.service.timesheetLinesStorageName,
        result,
      );

      this.hasLinesInStorage.set(true);

      this.notification.successLocal('shared.messages.copiedToClipboard');
    }
  }

  /** Pastes lines from the buffer. */
  public async pasteLinesFromStorage(): Promise<void> {
    const linesData: Partial<TimesheetLine>[] = this.localStorageService
      .retrieve(this.service.timesheetLinesStorageName)
      ?.map((lineData: Partial<TimesheetLine>) => ({
        ...lineData,
        id: Guid.generate(),
      }));
    const newLines: Partial<TimesheetLine>[] = [];

    for (const lineData of linesData) {
      this.blockUI.start();

      const newLine: Partial<TimesheetLine> = { task: lineData.task };
      this.customFieldService.assignValues(newLine, lineData, 'TimeSheetLine');

      this.autosave.addToQueue(
        Guid.generate(),
        this.service.checkIfTaskCanBeUsed(lineData.task).pipe(
          switchMap((taskCanBeUsed) => {
            if (!taskCanBeUsed) {
              this.notification.warningLocal(
                'timesheets.card.messages.taskCannotBeUsed',
                { name: lineData.task.projectTask.name },
              );

              return of(null);
            }

            return this.service.checkAndFillTimeSheetLine(newLine, lineData);
          }),
          switchMap((taskCanBeUsed) =>
            taskCanBeUsed
              ? this.service.timeSheetLineCollection
                  .insert({
                    ...this.service.getTimesheetLineToSave(
                      lineData,
                      this.service.dataLines.controls.length,
                    ),
                    timeSheetId: this.service.timesheet.id,
                  })
                  .pipe(
                    tap((createdLine: TimesheetLine) => {
                      const group = this.service.addLine(false);
                      group.patchValue(
                        {
                          ...newLine,
                          ...createdLine,
                        },
                        { emitEvent: false },
                      );
                      newLines.push(newLine);
                    }),
                  )
              : of(null),
          ),
        ),
      );

      await this.autosave.save();
    }

    newLines.forEach((_, index) => {
      this.service.selectControls
        .at(this.service.dataLines.length - 1 - index)
        ?.setValue(true);
    });

    this.hasLinesInStorage.set(false);
    this.localStorageService.clear(this.service.timesheetLinesStorageName);
  }

  /**
   * Moves selected rows up or down in the timesheet.
   *
   * @param direction The direction to move the lines. Can be 'Up' or 'Down'.
   */
  public moveLines(direction: 'Up' | 'Down'): void {
    const directionSign = direction === 'Up' ? -1 : 1;
    const lineIndexesToMove = [];
    this.service.selectControls.value.forEach((selectControl, index) => {
      if (selectControl) {
        lineIndexesToMove.push(index);
      }
    });

    if (!lineIndexesToMove.length) return;

    const moveNextLine = () => {
      const moveIndex =
        direction === 'Up'
          ? lineIndexesToMove.shift()
          : lineIndexesToMove.pop();

      // If line is first and no need to move it up
      if (direction === 'Up' && moveIndex === 0) {
        if (lineIndexesToMove.length) {
          moveNextLine();
        }
        return;
      }
      // If line is last and no need to move it down
      if (
        direction === 'Down' &&
        moveIndex === this.service.dataLines.controls.length - 1
      ) {
        if (lineIndexesToMove.length) {
          moveNextLine();
        }
        return;
      }
      const lineToMove = this.service.dataLines.controls[moveIndex];
      this.autosave.addToQueue(lineToMove.value.id, () =>
        this.service.timeSheetLineCollection
          .entity(lineToMove.value.id)
          .patch(
            {
              rowVersion: lineToMove.value.rowVersion,
              orderNumber: moveIndex + directionSign,
            },
            {
              withResponse: true,
            },
          )
          .pipe(
            tap((result: TimesheetLine) => {
              lineToMove.patchValue(result, { emitEvent: false });
              this.service.resolveIndirectUpdates(result.updated);

              const group = this.service.dataLines.at(moveIndex);
              this.service.dataLines.removeAt(moveIndex, { emitEvent: false });
              this.service.dataLines.insert(moveIndex + directionSign, group, {
                emitEvent: false,
              });

              const control = this.service.selectControls.at(moveIndex);
              this.service.selectControls.removeAt(moveIndex, {
                emitEvent: false,
              });
              this.service.selectControls.insert(
                moveIndex + directionSign,
                control,
                {
                  emitEvent: false,
                },
              );

              if (lineIndexesToMove.length) {
                moveNextLine();
              } else {
                setTimeout(() => this.cellsOrchestrator.reset());
              }
            }),
          ),
      );
    };

    moveNextLine();
  }

  /** Creates timesheet line. */
  public createLine(): void {
    const group = this.service.addLine();
    const data: Partial<TimesheetLine> = {
      timeSheetId: this.service.timesheet.id,
      orderNumber: this.service.dataLines.controls.length - 1,
    };

    this.customFieldService.enrichFormGroupWithDefaultValues(
      group,
      'TimeSheetLine',
    );

    this.autosave.addToQueue(
      Guid.generate(),
      this.service.timeSheetLineCollection
        .insert(Object.assign({}, group.getRawValue(), data))
        .pipe(
          tap((result) => {
            group.patchValue(result, { emitEvent: false });
          }),
        ),
    );
  }

  /** Copies lines from the previous timesheet.
   *
   * @param withHours include hours
   */
  public copyLines(withHours?: boolean) {
    this.service.copyLines(withHours);
  }

  /** Creates lines from the resource plan.
   *
   * @param withHours include hours
   */
  public createLinesFromResourcePlan(withHours?: boolean) {
    this.service.createLinesFromResourcePlan(withHours);
  }

  /** Adds an time-off request. */
  public addTimeOffRequest() {
    this.service.createTimeOffRequest();
  }

  /** Returns of the hours of time off during the day.
   *
   * @param request time-off request
   * @param day day on which
   *
   * @returns hours
   */
  public getDayOffHours(request: TimeOffRequest, day: Day): number {
    const allocation = request.timeAllocations.find((a) => a.date === day.date);
    return allocation?.hours;
  }

  /** Returns of the hours of time off during the period.
   *
   * @param request time-off request
   *
   * @returns hours
   */
  public geTimeOffRequestHours(request: TimeOffRequest): number {
    let hours = 0;

    this.days.forEach((day) => {
      const allocation = request.timeAllocations.find(
        (a) => a.date === day.date,
      );
      hours += allocation?.hours ?? 0;
    });

    return hours;
  }

  /** Calculates table widths. */
  private calculateWidth() {
    this.fixTableColumnCount = 2;

    this.fixTableWidth =
      this.columnsWidths.taskColumnWidth +
      this.columnsWidths.checkBoxColumnWidth;
    if (this.template.showActivity) {
      this.fixTableWidth += this.columnsWidths.activityColumnWidth;
      this.fixTableColumnCount++;
    }

    if (this.template.showRole) {
      this.fixTableWidth += this.columnsWidths.roleRateColumnWidth;
      this.fixTableColumnCount++;
    }

    if (this.template.showProjectCostCenter) {
      this.fixTableWidth += this.columnsWidths.projectCostCenterColumnWidth;
      this.fixTableColumnCount++;
    }

    if (this.template.showTariff) {
      this.fixTableWidth += this.columnsWidths.projectTariffColumnWidth;
      this.fixTableColumnCount++;
    }

    this.fixTableWidth +=
      this.columnsWidths.customColumnWidth *
      this.service.lineCustomFields.length;
    this.fixTableColumnCount += this.service.lineCustomFields.length;
    this.dataTableWidth = this.days.length * 47 + 53;
  }

  /** Fills the timesheet days array. */
  private fillOutDays() {
    this.days = [];
    this.totalSchedule = 0;

    // Заполняем дни.
    const dateTo = DateTime.fromISO(this.service.timesheet.dateTo);
    let currentDate = DateTime.fromISO(this.service.timesheet.dateFrom);

    while (currentDate <= dateTo) {
      let hintAddon = '';
      let schedule = 0;
      let isExceptionDay = false;

      if (this.service.timesheet.schedule) {
        const scheduleDay = this.service.timesheet.schedule.find(
          (d) => d.date === currentDate.toISODate(),
        );
        if (scheduleDay) {
          isExceptionDay = scheduleDay.hours === 0;
          schedule = scheduleDay.hours;

          // Добавить длительность по расписанию к общему итогу.
          this.totalSchedule += scheduleDay.hours ?? 0;

          hintAddon +=
            '\n' +
            this.translate.instant('timesheets.dayHintWorkHours', {
              hours: scheduleDay.hours,
            });
        }
      }

      const day = {
        schedule,
        date: currentDate.toFormat('yyyy-MM-dd'),
        header: currentDate.toFormat('dd.LL'),
        hint: currentDate.toLocaleString(DateTime.DATE_FULL) + hintAddon,
        stamp: currentDate.valueOf(),
        isException: isExceptionDay,
        isToday: currentDate.hasSame(DateTime.now(), 'day'),
      } as Day;
      this.days.push(day);
      currentDate = currentDate.plus({ days: 1 });
    }
  }

  private calculateTotals(): void {
    this.totalHours = 0;
    this.days.forEach((day) => (day.totalHours = 0));

    const lines = this.service.dataLines.value as Line[];

    lines.forEach((line) => {
      line.allocations.forEach((allocation, index) => {
        this.days[index].totalHours += allocation.hours ?? 0;
        this.totalHours += allocation.hours ?? 0;
      });
    });

    this.days.forEach((day) => {
      this.service.timesheet.timeOffRequests.forEach((line) => {
        const allocation = line.timeAllocations.find(
          (a) => a.date === day.date,
        );

        if (allocation) {
          day.totalHours += allocation.hours ?? 0;
          this.totalHours += allocation.hours ?? 0;
        }
      });

      this.service.timesheet.issues.forEach((line) => {
        const allocations = line.timeAllocations.filter(
          (a) => a.date === day.date,
        );

        allocations.forEach((allocation) => {
          day.totalHours += allocation.hours ?? 0;
          this.totalHours += allocation.hours ?? 0;
        });
      });
    });
  }
}
