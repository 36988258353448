<div class="criterion clearfix">
  <div class="header">
    <span>
      {{ criterion.field.title }}
      &nbsp;<button
        type="button"
        class="close"
        (click)="remove()"
        title="{{ 'analytics.reportFilters.actions.removeCriterion' | translate }}"
      >
        &times;
      </button>
    </span>
  </div>

  <div
    class="clearfix condition"
    *ngFor="let group of form.controls; index as index"
    [formGroup]="group"
    style="margin-bottom: 15px"
  >
    <div
      ngbDropdown
      class="d-inline-block"
      style="float: left; padding-right: 15px"
      placement="bottom-left"
    >
      <button
        type="button"
        style="width: 160px; text-align: left"
        class="btn btn-default"
        ngbDropdownToggle
        title="{{ 'analytics.reportFilters.condition.operator' | translate }}"
      >
        {{ getOperatorLocalTitle(group.value.operator) }}
      </button>

      <div ngbDropdownMenu class="dropdown-scroll">
        <button
          *ngFor="let operator of criterion.field.operators"
          ngbDropdownItem
          (click)="setOperator(group, operator)"
        >
          {{ getOperatorLocalTitle(operator) }}
        </button>
      </div>
    </div>

    <!-- ТИПЫ ЗНАЧЕНИЙ -->
    <div style="float: left; padding-right: 15px">
      <ng-container
        *ngIf="group.value.operator !== 'IsNull' && group.value.operator !== 'IsNotNull'"
      >
        <!--String-->
        <wp-text-box
          *ngIf="criterion.field.type === 'String'"
          style="width: 250px"
          formControlName="value"
          placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
        ></wp-text-box>

        <!--Integer - Value-->
        <wp-number-box
          *ngIf="criterion.field.type === 'Integer' && group.value.operator !== 'Between'"
          style="width: 150px"
          placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
          type="integer"
          formControlName="value"
          [allowNull]="true"
        ></wp-number-box>

        <!--Integer - Between-->
        <div
          *ngIf="criterion.field.type === 'Integer' && group.value.operator === 'Between'"
          class="value-container"
        >
          <wp-number-box
            style="width: 150px"
            placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
            type="integer"
            formControlName="valueLeft"
            [allowNull]="true"
          ></wp-number-box>

          <span style="padding-left: 10px; padding-right: 10px">{{
            'analytics.reportFilters.and' | translate
          }}</span>
          <wp-number-box
            style="width: 150px"
            placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
            type="integer"
            formControlName="valueRight"
            [allowNull]="true"
          ></wp-number-box>
        </div>

        <!--Decimal - Value-->
        <!--Percent - Value-->
        <wp-number-box
          *ngIf="
            (criterion.field.type === 'Percent' || criterion.field.type === 'Decimal') &&
            group.value.operator !== 'Between'
          "
          style="width: 150px"
          placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
          type="decimal"
          formControlName="value"
          [allowNull]="true"
        ></wp-number-box>

        <!--Decimal | Percent - Between-->
        <div
          *ngIf="
            (criterion.field.type === 'Decimal' || criterion.field.type === 'Percent') &&
            group.value.operator === 'Between'
          "
          class="value-container"
        >
          <wp-number-box
            style="width: 150px"
            placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
            type="decimal"
            formControlName="valueLeft"
            [allowNull]="true"
          ></wp-number-box>

          <span style="padding-left: 10px; padding-right: 10px">{{
            'analytics.reportFilters.and' | translate
          }}</span>
          <wp-number-box
            style="width: 150px"
            placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
            type="decimal"
            formControlName="valueRight"
            [allowNull]="true"
          ></wp-number-box>
        </div>

        <!--Date | DateTimeOffset - Value-->
        <wp-date-box
          *ngIf="
            (criterion.field.type === 'Date' || criterion.field.type === 'DateTimeOffset') &&
            group.value.operator !== 'InPeriod'
          "
          formControlName="value"
        ></wp-date-box>

        <!--Date | DateTimeOffset - Period-->
        <wp-date-period-box
          *ngIf="
            (criterion.field.type === 'Date' || criterion.field.type === 'DateTimeOffset') &&
            group.value.operator === 'InPeriod'
          "
          [advanced]="true"
          style="width: 250px"
          formControlName="value"
          placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
        ></wp-date-period-box>

        <!--Bool-->
        <div *ngIf="criterion.field.type === 'Bool'" class="wp-checkbox">
          <label>
            <input type="checkbox" formControlName="value" />
            <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
          </label>
        </div>

        <!--EntityId + !Department + !User - Value-->
        <wp-select-box
          *ngIf="
            criterion.field.type === 'EntityId' &&
            criterion.field.entityTypeName !== 'User' &&
            criterion.field.entityTypeName !== 'Department'
          "
          style="width: 400px"
          [collection]="collectionName"
          formControlName="entityValue"
          [allowInactive]="allowInactive"
          placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
        >
        </wp-select-box>

        <!--Directory-->
        <wp-select-box
          *ngIf="criterion.field.type === 'Directory'"
          style="width: 400px"
          [directory]="criterion.field.directoryId"
          formControlName="entityValue"
          [allowInactive]="allowInactive"
          placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
        >
        </wp-select-box>

        <!--EntityId + User - Value-->
        <wp-user-box
          *ngIf="
            criterion.field.type === 'EntityId' &&
            criterion.field.entityTypeName === 'User' &&
            group.value.operator !== 'IsNull' &&
            group.value.operator !== 'IsNotNull'
          "
          style="width: 400px"
          formControlName="entityValue"
          [allowInactive]="allowInactive"
          placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
        >
        </wp-user-box>

        <!--EntityId + Department - Value-->
        @if (
          criterion.field.type === 'EntityId' &&
          criterion.field.entityTypeName === 'Department' &&
          group.value.operator !== 'IsNull' &&
          group.value.operator !== 'IsNotNull'
        ) {
          <tmt-hierarchical-box
            collection="Departments"
            style="width: 400px"
            formControlName="entityValue"
            parentIdKey="leadDepartmentId"
            placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
            [allowInactive]="allowInactive"
          />
        }

        <!--Enum, State - Value-->
        <wp-select-box
          *ngIf="
            (criterion.field.type === 'Enum' || criterion.field.type === 'State') &&
            criterion.field.entityTypeName !== 'Department' &&
            group.value.operator !== 'IsNull' &&
            group.value.operator !== 'IsNotNull'
          "
          style="width: 400px"
          [values]="criterion.field.values"
          formControlName="entityValue"
          placeholder="{{ 'analytics.reportFilters.condition.value' | translate }}"
        >
        </wp-select-box>
      </ng-container>
    </div>

    <button
      [hidden]="form.length === 1"
      type="button"
      class="close"
      (click)="removeCondition(index)"
      title="{{ 'analytics.reportFilters.actions.removeCondition' | translate }}"
    >
      &times;
    </button>

    <span
      *ngIf="index != form.length - 1"
      style="float: left; margin-left: 25px; line-height: 34px"
      class="bt-action"
      >{{ 'analytics.reportFilters.or' | translate }}</span
    >

    <button
      *ngIf="index == form.length - 1"
      class="btn btn-link add-condition"
      style="margin-left: 25px"
      (click)="addCondition()"
    >
      {{ 'analytics.reportFilters.actions.addCondition' | translate }}
    </button>
  </div>
</div>
