import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { TimeOffRequestFilterService } from './filter/time-off-request-filter.service';
import { StateService } from '@uirouter/core';
import { Subject } from 'rxjs';
import { TimeOffCreationComponent } from '../creation/time-off-creation.component';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';
import { takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wp-time-off-list',
  template: '<wp-entity-list></wp-entity-list>',
  providers: [
    { provide: FilterService, useClass: TimeOffRequestFilterService },
    EntityListService,
    ListService,
    GridService,
    LifecycleListService,
  ],
  standalone: false,
})
export class TimeOffRequestListComponent implements OnInit, OnDestroy {
  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private state: StateService,
    private actions: ActionPanelService,
    private listService: EntityListService,
    private gridService: GridService,
    private modalService: NgbModal,
    private lifecycleListService: LifecycleListService,
  ) {}

  ngOnInit() {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'timeOff.list.actions.create',
        isVisible: true,
        handler: this.create,
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go(`timeOffRequest`, {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.listService.delete,
      }),
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((rows: UntypedFormGroup[]) => {
        this.actions.action('card').isShown = rows?.length === 1;
      });
  }

  private create = () => {
    this.modalService.open(TimeOffCreationComponent, {
      size: 'lg',
    });
  };

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
