import {
  Component,
  OnInit,
  Input,
  Inject,
  LOCALE_ID,
  DestroyRef,
  inject,
} from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ScheduleCardService } from './schedule-card.service';
import {
  Schedule,
  ScheduleDay,
} from 'src/app/shared/models/entities/settings/schedule.model';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  getLocaleDayNames,
  FormStyle,
  TranslationWidth,
  getLocaleFirstDayOfWeek,
} from '@angular/common';
import { Exception } from 'src/app/shared/models/exception';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-schedule-card',
  templateUrl: './schedule-card.component.html',
  providers: [ScheduleCardService],
  standalone: false,
})
export class ScheduleCardComponent implements OnInit {
  @Input() private entityId: string;

  public isLoading: boolean;
  public isSaving: boolean;
  public readonly: boolean;

  public form = this.fb.group({
    id: [''],
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
    daysCount: [0, Validators.required],
    scheduleException: [null],
    isActive: [false],
    isDefault: [false],
    firstDay: [null],
    patternDays: this.fb.array([]),
  });
  localeDaysOfWeek: string[];

  private destroyRef = inject(DestroyRef);

  public get patternDays(): UntypedFormArray {
    return this.form.controls['patternDays'] as UntypedFormArray;
  }

  public get schedule(): Schedule {
    return this.form.getRawValue() as Schedule;
  }

  constructor(
    private notification: NotificationService,
    private data: DataService,
    private message: MessageService,
    private actionService: ActionPanelService,
    public service: ScheduleCardService,
    @Inject(LOCALE_ID) public locale: string,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: this.setAsDefault,
      },
    ]);
    const localeFirstDayOfWeek = getLocaleFirstDayOfWeek(this.locale);
    this.localeDaysOfWeek = getLocaleDayNames(
      this.locale,
      FormStyle.Standalone,
      TranslationWidth.Wide,
    ) as [];

    if (localeFirstDayOfWeek === 1) {
      this.localeDaysOfWeek = [...this.localeDaysOfWeek];
      this.localeDaysOfWeek.push(this.localeDaysOfWeek.shift());
    }

    this.form.controls['daysCount'].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.form.controls['daysCount'].value !== 7) {
          this.form.controls['firstDay'].setValidators(Validators.required);
          this.form.controls['firstDay'].enable();
        } else {
          this.form.controls['firstDay'].clearValidators();
          this.form.controls['firstDay'].disable();
          this.form.updateValueAndValidity();
        }

        this.rebuildDays();
      });

    this.service.schedule$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((schedule?: Schedule) => {
        if (!schedule) {
          return;
        }

        this.isLoading = false;

        this.form.patchValue(schedule);
        this.form.markAsPristine();
        this.form.markAsUntouched();

        this.readonly = !schedule.editAllowed;
        if (schedule.editAllowed) {
          this.actionService.action('save').show();
          this.form.enable();
        } else {
          this.form.disable();
        }
        this.actionService.action('setAsDefault').isShown =
          !this.readonly && !this.schedule.isDefault;
      });

    this.service.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!this.form.dirty) {
          this.load();
        } else {
          this.message
            .confirmLocal('shared.leavePageMessage')
            .then(this.load, () => null);
        }
      });

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!this.form.dirty) {
          this.load();
        } else {
          this.message
            .confirmLocal('shared.leavePageMessage')
            .then(this.load, () => null);
        }
      });

    this.service.reload();
  }

  /**
   * gets day title.
   * @param index
   * @returns dayNumber
   */
  public getDayTitle(index: number): string {
    const day = this.patternDays.controls[index].value as ScheduleDay;

    if (this.schedule.daysCount === 7) {
      return this.localeDaysOfWeek[day.dayNumber - 1];
    } else {
      return `# ${day.dayNumber}`;
    }
  }

  public setToWeek(): void {
    this.form.controls['daysCount'].setValue(7);
  }

  /** Saves Data. */
  public save = (): void => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const schedule = this.schedule;

    const data = {
      id: schedule.id,
      name: schedule.name,
      description: schedule.description,
      daysCount: schedule.daysCount,
      scheduleExceptionId: schedule.scheduleException?.id ?? null,
      isActive: schedule.isActive,
      firstDay: schedule.firstDay,
      patternDays: this.patternDays.value,
      isDefault: schedule.isDefault,
    };

    this.data
      .collection('Schedules')
      .entity(this.entityId)
      .update(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.schedules.card.messages.saved',
          );
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
          this.actionService.action('save').stop();
        },
      });
  };

  private rebuildDays(): void {
    const daysCount = this.form.controls['daysCount'].value;

    for (let index = 1; index <= daysCount; index++) {
      const day = (this.patternDays.value as ScheduleDay[]).find(
        (d) => d.dayNumber === index,
      );
      if (day == null) {
        this.patternDays.push(
          this.fb.group({
            id: Guid.generate(),
            dayNumber: index,
            dayLength: [8, Validators.required],
            scheduleId: this.entityId,
          }),
        );
      }
    }

    (this.patternDays.value as ScheduleDay[])
      .filter((day) => day.dayNumber > daysCount)
      .forEach((day) => {
        const index = (this.patternDays.value as ScheduleDay[]).indexOf(day);
        this.patternDays.removeAt(index);
      });
  }

  public setAsDefault = (): void => {
    this.data
      .collection('Schedules')
      .entity(this.entityId)
      .action('WP.SetAsDefault')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.schedules.card.messages.setAsDefault',
          );

          this.actionService.action('setAsDefault').isShown = false;
          this.form.controls['isDefault'].setValue(true);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  };

  private load = (): void => {
    this.service.load();
    this.isLoading = true;
  };
}
