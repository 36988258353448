import {
  Component,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  ViewRef,
  DestroyRef,
  inject,
  AfterViewInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
} from '@angular/forms';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { PerformersService } from '../performers.service';
import { Performer } from 'src/app/shared/models/entities/state.model';
import { firstValueFrom, Observable, of } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'tmt-performer-box',
  templateUrl: './performer-box.component.html',
  styleUrls: ['./performer-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PerformerBoxComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class PerformerBoxComponent
  implements ControlValueAccessor, AfterViewInit
{
  @Input() placeholder: string;
  @ViewChild('input') inputEl: ElementRef;

  public isOpened = false;
  public performer: Performer;
  public isLoading: boolean;
  public readonly: boolean;
  public empty: boolean;

  public form = this.fb.group({
    performerControl: null,
    type: 'user',
  });

  /**Доступные к выбору роли */
  public roles$: NamedEntity[] | Observable<NamedEntity[]>;

  private destroyRef = inject(DestroyRef);
  constructor(
    private translate: TranslateService,
    public performersService: PerformersService,
    private fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit(): void {
    this.performersService.predefinedRoles
      ? this.getAvailableRoles().then((value) => (this.roles$ = value))
      : (this.roles$ = this.performersService.getAvailableRoles().pipe(
          takeUntilDestroyed(this.destroyRef),
          switchMap((roles) => {
            const availableRoles = roles.filter((role) => {
              let currentRoles = [];
              this.performersService.performers.map((performerLine) => {
                if (performerLine.performer.type === 'role') {
                  currentRoles.push(performerLine.performer.id);
                }
              });
              currentRoles = currentRoles.concat(
                this.performersService.excludedRoles,
              );
              return (
                !currentRoles.some(
                  (i) => i === role.id && i !== this.performer.id,
                ) && role
              );
            });

            return of(
              availableRoles.map((role: NamedEntity) => ({
                id: role.id,
                name: this.performersService.getRoleName(role.name),
              })),
            );
          }),
        ));

    this.form.controls.type.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.form.controls.performerControl.setValue(null);
      });

    this.form.controls.performerControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.ref.markForCheck();
      });
  }

  public propagateChange = (_: Performer): void => null;
  public propagateTouch = (): void => null;

  public writeValue(performer: Performer): void {
    this.performer = performer;

    this.form.patchValue(performer);
    if (performer.id && performer.name) {
      this.form.controls.performerControl.patchValue({
        id: performer.id,
        name: performer.name,
      });
    } else {
      this.form.controls.performerControl.patchValue(null);
    }

    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  public setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;

    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  /**
   * Gets performer's display name.
   *
   * @returns display name.
   */
  public getPerformerDisplayName(): string {
    this.empty = !this.performer.name;

    return this.performer.name
      ? `${this.translate.instant('components.performerBoxComponent.props.' + this.performer.type)}: ${this.performer.name}`
      : this.translate.instant('shared.valueNotSelected');
  }

  /** Listens event onClick. */
  public onInputClick(): void {
    if (!this.isOpened) {
      this.openList();
    }
  }

  /** Opens List. */
  public openList(): void {
    if (!this.isOpened) {
      this.isOpened = true;
      return;
    }
  }

  /** Cancels changes. */
  public cancel(): void {
    this.isOpened = false;

    /* Репатч на случай, если был изменен тип и нажата отмена */
    this.form.patchValue(this.performer);
    if (this.performer.id && this.performer.name) {
      this.form.controls.performerControl.patchValue({
        id: this.performer.id,
        name: this.performer.name,
      });
    } else {
      this.form.controls.performerControl.patchValue(null);
    }
  }

  /** Saves changes. */
  public ok() {
    this.performer.id = this.form.value.performerControl.id ?? null;
    this.performer.name = this.form.value.performerControl.name ?? null;
    this.performer.type = this.form.value.type;

    this.cancel();
    this.propagateChange(this.performer);
  }

  /**
   * Gets filter.
   *
   * @param performerType performerType.
   *
   * @returns Filter.
   */
  public getFilter(performerType: string) {
    let idsString = '';
    this.performersService.performers.map((performerLine) => {
      if (
        performerLine.performer.type === performerType &&
        performerLine.performer.id !== this.performer.id
      ) {
        idsString =
          idsString === ''
            ? performerLine.performer.id
            : idsString + `,${performerLine.performer.id}`;
      }
    });
    return { filter: [`not (id in (${idsString}))`] };
  }

  /**
   * Gets performerTypeName.
   *
   * @param type type.
   *
   * @returns performerTypeName.
   */
  public getPerformerTypeName(type: string): string {
    return this.translate.instant(
      'components.performerBoxComponent.props.' + type,
    );
  }

  /**
   * Gets available roles.
   *
   * @returns available roles array promise.
   */
  private async getAvailableRoles(): Promise<NamedEntity[]> {
    const roles = Array.isArray(this.performersService.predefinedRoles)
      ? this.performersService.predefinedRoles
      : await firstValueFrom(this.performersService.predefinedRoles);
    return roles.filter(
      (role) =>
        !this.performersService.performers
          .filter((value) => value.performer.type === 'role')
          .find((value) => value.performer.id === role.id) ||
        this.form.controls.performerControl.value?.id === role.id,
    );
  }
}
