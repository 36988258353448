import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { AppMenuComponent } from './components/chrome/app-menu/app-menu.component';
import { NavigationPanelComponent } from './components/chrome/navigation-panel/navigation-panel.component';
import { UIRouterModule } from '@uirouter/angular';
import { NgxDropzoneModule } from 'ngx-dropzone';
import { BooleanPipe } from './pipes/boolean.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TextBoxComponent } from './components/controls/text-box/text-box.component';
import { LoadingIndicatorComponent } from './components/features/loading-indicator/loading-indicator.component';
import { ActionPanelComponent } from './components/chrome/action-panel/action-panel.component';
import { ButtonSpinnerDirective } from './directives/button-spinner.directive';
import { MultilineTextBoxComponent } from './components/controls/multiline-text-box/multiline-text-box.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { LinkyModule } from 'ngx-linky';
import { NotificationComponent } from './components/chrome/notification/notification.component';
import { DataNotSavedIndicatorComponent } from './components/features/data-not-saved-indicator/data-not-saved-indicator.component';
import { MessageComponent } from './components/chrome/message/message.component';
import { AngularResizeEventModule } from 'angular-resize-event';
import { FilterComponent } from './components/features/filter/filter.component';
import { FormHeaderComponent } from './components/chrome/form-header/form-header.component';
import { FilterDetailsHostDirective } from './components/features/filter/filter-details-host.directive';
import { PathPipe } from './pipes/path.pipe';
import { ViewSettingsModalComponent } from './components/features/view-settings-modal/view-settings-modal.component';
import { AngularDraggableDirective } from './directives/angular-draggable/angular-draggable.directive';
import { SelectBoxComponent } from './components/controls/select-box/select-box.component';
import { DateBoxComponent } from './components/controls/date-box/date-box.component';
import { EmailBoxComponent } from './components/controls/email-box/email-box.component';
import { routerConfigFn } from '../router.config';
import { UserBoxComponent } from './components/controls/user-box/user-box.component';
import { FreezeTableDirective } from './directives/freeze-table/freeze-table.directive';
import { ProjectVersionsBoxComponent } from './components/controls/project-versions-box/project-versions-box.component';
import { WorkPipe } from './pipes/work.pipe';
import { DatePeriodBoxComponent } from './components/controls/date-period-box/date-period-box.component';
import { NumberBoxComponent } from './components/controls/number-box/number-box.component';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { AutofocusDirective } from './directives/autofocus/autofocus.directive';
import { CustomFieldsComponent } from './components/features/custom-fields/custom-fields.component';
import { CustomFieldComponent } from './components/features/custom-fields/custom-field/custom-field.component';
import { UserInfoComponent } from './components/features/user-info/user-info.component';
import { NotFoundComponent } from './components/chrome/not-found/not-found.component';
import { TranslateCutPipe } from './pipes/translate-cut.pipe';
import { TimeDurationPipe } from './pipes/time-duration.pipe';
import { AutosizeModule } from 'ngx-autosize';
import { CustomFieldValuePipe } from './pipes/custom-field-value.pipe';
import { StopwatchIndicatorComponent } from './components/features/stopwatch-indicator/stopwatch-indicator.component';
import { StopwatchPopupComponent } from './components/features/stopwatch-indicator/stopwatch-popup/stopwatch-popup.component';
import { ResizingDirective } from './directives/resizing.directive';
import { AvatarPipe } from './pipes/avatar.pipe';
import { FinancePipe } from './pipes/finance.pipe';
import { LoadingValueDirective } from './directives/loading-value.directive';
import { TaskBoxComponent } from './components/controls/task-box/task-box.component';
import { WpCurrencyPipe } from './pipes/currency.pipe';
import { ProjectInfoComponent } from './components/features/project-info/project-info.component';
import { TaskInfoComponent } from './components/features/task-info/task-info.component';
import { FinancialTypeCellComponent } from './components/features/financial-type-cell/financial-type-cell.component';
import { RadioGroupModule } from './directives/radio-group/radio-group.module';
import { ZeroToNullPipe } from './pipes/zero-to-null.pipe';
import { UnsignedPipe } from './pipes/unsigned-pipe';
import { InfoPopupComponent } from './components/features/info-popup/info-popup.component';
import { StateIndicatorComponent } from './components/features/state-indicator/state-indicator.component';
import { PercentPointPipe } from 'src/app/shared/pipes/percent-point.pipe';
import { CurrencyBoxComponent } from './components/controls/currency-box/currency-box.component';
import { StateSelectComponent } from './components/features/state-filter/state-select.component';
import { RouteNavigatorComponent } from './components/chrome/route-navigator/route-navigator.component';
import { TransitionFormModalComponent } from './components/features/transition-form-modal/transition-form-modal.component';
import { FormHeader2Component } from './components/chrome/form-header2/form-header2.component';
import { UserNotificationIndicatorComponent } from 'src/app/shared/components/features/user-notification-indicator/user-notification-indicator.component';
import { UserNotificationPopupComponent } from 'src/app/shared/components/features/user-notification-indicator/user-notification-popup/user-notification-popup.component';
import { IntersectableDirective } from './directives/intersectable.directive';
import { SortComponent } from './components/features/sort/sort.component';
import { MenuComponent } from './components/features/menu/menu.component';
import { DocsArticleDirective } from 'src/app/shared/directives/docs-article.directive';
import { DocModalComponent } from 'src/app/shared/components/controls/doc-modal/doc-modal.component';
import { WorkflowActionCommentFormComponent } from './components/features/workflow-action-comment-form/workflow-action-comment-form.component';
import { LabelIndicatorDirective } from './directives/label-indicator.directive';
import { StateBadgeComponent } from './components/features/state-badge/state-badge.component';
// eslint-disable-next-line max-len
import { DurationBoxComponent } from './components/controls/duration-box/duration-box.component';
import { ViewerComponent } from 'src/app/shared/components/features/viewer/viewer.component';
import { ImageZoomDirective } from 'src/app/shared/directives/image-zoom/image-zoom.directive';
import { SafeValuePipe } from 'src/app/shared/pipes/safe-value.pipe';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { WorkflowIndicatorComponent } from './components/features/workflow-indicator/workflow-indicator.component';
import { WorkflowIndicatorPopupComponent } from './components/features/workflow-indicator/workflow-indicator-popup/workflow-indicator-popup.component';
import { ProductSelectorModalComponent } from 'src/app/shared/components/features/product-selector-modal/product-selector-modal.component';
import { GarlandComponent } from './components/features/garland/garland.component';
import { NgxNl2brModule } from 'ngx-nl2br';
import { SortablejsModule } from 'nxt-sortablejs';
import { HierarchicalBoxComponent } from 'src/app/shared/components/controls/hierarchical-box/hierarchical-box.component';
import { TreeListComponent } from 'src/app/shared/components/features/tree-list';
import { MultiSelectBoxComponent } from 'src/app/shared/components/controls/multiselect-box/multiselect-box.component';
import { OffCanvasResizeDirective } from './directives/off-canvas-resize.directive';
import { OffCanvasComponent } from './components/features/off-canvas/off-canvas.component';
import { InfoPopupEntryComponent } from 'src/app/shared/components/features/info-popup/info-popup-item/info-popup-item.component';
import { TruncatePipe } from 'src/app/shared/pipes/truncate.pipe';
import { IconPickerComponent } from 'src/app/shared/components/icon-picker/icon-picker.component';
import { CurrencyBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/currency-box-wrapper.component';
import { DateBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/date-box-wrapper.component';
import { NumberBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/number-box-wrapper.component';
import { SelectBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/select-box-wrapper.component';
import { TextBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/text-box-wrapper.component';
import { UserBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/user-box-wrapper.component';
import { CustomFieldHostDirective } from 'src/app/shared/components/features/custom-fields/wrappers/custom-field-host.directive';
import { TruncateDirective } from 'src/app/shared/directives/truncate/truncate.directive';
import { AccordionStateDirective } from 'src/app/shared/directives/accordion-state/accordion-state.directive';

@NgModule({
  declarations: [
    SortComponent,
    AppMenuComponent,
    RouteNavigatorComponent,
    NavigationPanelComponent,
    BooleanPipe,
    FinancePipe,
    FormHeaderComponent,
    LoadingIndicatorComponent,
    ActionPanelComponent,
    ButtonSpinnerDirective,
    LoadingValueDirective,
    NotificationComponent,
    DataNotSavedIndicatorComponent,
    MessageComponent,
    FilterComponent,
    FilterDetailsHostDirective,
    PathPipe,
    ViewSettingsModalComponent,
    AngularDraggableDirective,
    ClickOutsideDirective,
    FreezeTableDirective,
    WpCurrencyPipe,
    ZeroToNullPipe,
    UnsignedPipe,
    PercentPointPipe,
    AutofocusDirective,
    CustomFieldsComponent,
    CustomFieldComponent,
    UserInfoComponent,
    ProjectInfoComponent,
    TaskInfoComponent,
    NotFoundComponent,
    TranslateCutPipe,
    TimeDurationPipe,
    CustomFieldValuePipe,
    StopwatchIndicatorComponent,
    StopwatchPopupComponent,
    UserNotificationPopupComponent,
    ResizingDirective,
    LabelIndicatorDirective,
    DocsArticleDirective,
    TextBoxComponent,
    MultilineTextBoxComponent,
    SelectBoxComponent,
    UserBoxComponent,
    TaskBoxComponent,
    DateBoxComponent,
    EmailBoxComponent,
    DatePeriodBoxComponent,
    NumberBoxComponent,
    ProjectVersionsBoxComponent,
    CurrencyBoxComponent,
    DurationBoxComponent,
    HierarchicalBoxComponent,
    MultiSelectBoxComponent,
    FinancialTypeCellComponent,
    InfoPopupComponent,
    InfoPopupEntryComponent,
    StateIndicatorComponent,
    WorkflowIndicatorComponent,
    WorkflowIndicatorPopupComponent,
    StateBadgeComponent,
    StateSelectComponent,
    TransitionFormModalComponent,
    FormHeader2Component,
    UserNotificationIndicatorComponent,
    IntersectableDirective,
    MenuComponent,
    DocModalComponent,
    WorkflowActionCommentFormComponent,
    ViewerComponent,
    ImageZoomDirective,
    SafeValuePipe,
    ProductSelectorModalComponent,
    GarlandComponent,
    OffCanvasComponent,
    OffCanvasResizeDirective,
    TruncatePipe,
    IconPickerComponent,
    UserBoxWrapperComponent,
    TextBoxWrapperComponent,
    SelectBoxWrapperComponent,
    NumberBoxWrapperComponent,
    DateBoxWrapperComponent,
    CurrencyBoxWrapperComponent,
    CustomFieldHostDirective,
    TruncateDirective,
  ],
  exports: [
    UserBoxWrapperComponent,
    TextBoxWrapperComponent,
    SelectBoxWrapperComponent,
    NumberBoxWrapperComponent,
    DateBoxWrapperComponent,
    CurrencyBoxWrapperComponent,
    FormsModule,
    ReactiveFormsModule,
    SortComponent,
    AppMenuComponent,
    RouteNavigatorComponent,
    NavigationPanelComponent,
    TranslateModule,
    NgxNl2brModule,
    LinkyModule,
    NgbModule,
    NotificationComponent,
    FormHeaderComponent,
    FormHeader2Component,
    AngularDraggableDirective,
    FreezeTableDirective,
    LoadingIndicatorComponent,
    ActionPanelComponent,
    ButtonSpinnerDirective,
    LoadingValueDirective,
    BooleanPipe,
    DataNotSavedIndicatorComponent,
    MessageComponent,
    AvatarPipe,
    WorkPipe,
    WpCurrencyPipe,
    ZeroToNullPipe,
    UnsignedPipe,
    FinancePipe,
    PercentPointPipe,
    AngularResizeEventModule,
    FilterComponent,
    SortablejsModule,
    NgxDropzoneModule,
    ClickOutsideDirective,
    CustomFieldsComponent,
    UserInfoComponent,
    ProjectInfoComponent,
    TaskInfoComponent,
    NotFoundComponent,
    TranslateCutPipe,
    TimeDurationPipe,
    AutosizeModule,
    CustomFieldValuePipe,
    StopwatchIndicatorComponent,
    StopwatchPopupComponent,
    UserNotificationPopupComponent,
    ResizingDirective,
    DocsArticleDirective,
    TextBoxComponent,
    MultilineTextBoxComponent,
    SelectBoxComponent,
    CurrencyBoxComponent,
    UserBoxComponent,
    TaskBoxComponent,
    DateBoxComponent,
    EmailBoxComponent,
    DatePeriodBoxComponent,
    NumberBoxComponent,
    ProjectVersionsBoxComponent,
    HierarchicalBoxComponent,
    MultiSelectBoxComponent,
    AutofocusDirective,
    RadioGroupModule,
    FinancialTypeCellComponent,
    InfoPopupComponent,
    InfoPopupEntryComponent,
    StateIndicatorComponent,
    WorkflowIndicatorComponent,
    WorkflowIndicatorPopupComponent,
    StateBadgeComponent,
    StateSelectComponent,
    UserNotificationIndicatorComponent,
    IntersectableDirective,
    DocModalComponent,
    LabelIndicatorDirective,
    DurationBoxComponent,
    ViewerComponent,
    ImageZoomDirective,
    SafeValuePipe,
    GarlandComponent,
    OffCanvasComponent,
    OffCanvasResizeDirective,
    TruncatePipe,
    IconPickerComponent,
    TruncateDirective,
  ],
  imports: [
    UIRouterModule.forChild({
      config: routerConfigFn,
    }),
    FormsModule,
    CommonModule,
    TranslateModule,
    ReactiveFormsModule,
    NgxNl2brModule,
    LinkyModule,
    NgbModule,
    AngularResizeEventModule,
    SortablejsModule,
    NgxDropzoneModule,
    AutosizeModule,
    RadioGroupModule,
    PdfViewerModule,
    TreeListComponent,
    AvatarPipe,
    WorkPipe,
    AccordionStateDirective,
  ],
})
export class SharedModule {}
