import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { TaskAssignmentsService } from 'src/app/projects/card/project-tasks/shared/task-card-modal/task-assignments/task-assignments.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridColumnType,
  GridNumberControlColumn,
  GridSelectControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';

@Component({
  selector: 'tmt-assignments-table',
  templateUrl: './assignments-table.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [GridService],
  standalone: false,
})
export class AssignmentTableComponent implements OnInit {
  @Input() assignmentRows: UntypedFormArray;

  public assignmentsGridOptions: GridOptions;

  constructor(
    public taskAssignmentsService: TaskAssignmentsService,
    public gridService: GridService,
    private projectTasksDataService: ProjectTasksDataService,
    private projectCardService: ProjectCardService,
  ) {}

  public ngOnInit(): void {
    if (this.taskAssignmentsService.readonly) {
      this.assignmentRows.disable({ emitEvent: false });
    } else {
      this.assignmentRows.enable({ emitEvent: false });
    }

    this.assignmentsGridOptions = {
      selectionType: SelectionType.row,
      rowCommands: [
        {
          name: 'delete',
          label: 'shared.actions.delete',
          allowedFn: () => !this.taskAssignmentsService.readonly,
          handlerFn: (formGroup: UntypedFormGroup, index: number) => {
            this.assignmentRows.removeAt(index);
          },
        },
      ],
      view: {
        name: 'default',
        columns: [
          <GridSelectControlColumn>{
            name: 'projectTeamMember',
            header: 'shared2.props.resource',
            hint: 'shared2.props.resource',
            placeholder: 'shared2.props.resource',
            type: GridColumnType.SelectControl,
            values: this.getAvailableMembers,
            fixedWidth: true,
            allowNull: false,
          },
          <GridNumberControlColumn>{
            name: 'units',
            header: 'components.assignmentTableComponent.groups.units',
            hint: 'components.assignmentTableComponent.groups.units',
            type: GridColumnType.NumberControl,
            controlType: 'percent',
            contentType: GridColumnType.Percent,
            propagationMode: PropagationMode.onExitFromEditing,
            min: 0,
            allowNull: false,
            width: '135px',
          },
        ],
      },
    };
  }

  /** Adds new assignment line in the table. */
  public addAssignmentsLine(): void {
    const group = this.taskAssignmentsService.getAssignmentFormGroup();
    this.assignmentRows.insert(0, group);
    this.gridService.selectGroup(group);
  }

  /** Deletes assignment from array. */
  public deleteAssignment(): void {
    if (!this.gridService.selectedGroup$.getValue()) {
      return;
    }
    const index = this.assignmentRows.controls.findIndex(
      (control) => control.value.id === this.gridService.selectedGroupValue.id,
    );
    this.assignmentRows.removeAt(index);
    if (this.assignmentRows.controls.length) {
      this.gridService.selectGroup(
        this.assignmentRows.controls[0] as UntypedFormGroup,
      );
    }
  }

  /** Returns available project team members.
   *
   * @returns available members.
   */
  private getAvailableMembers = (): Partial<ProjectTeamMember>[] => {
    let members: Partial<ProjectTeamMember>[] =
      this.projectTasksDataService.members.sort(naturalSort('name'));

    if (this.projectCardService.project.isAutoPlanning) {
      members = members.filter(
        (member) => member?.resource?.resourceType !== ResourceType.department,
      );
    }

    members.forEach((member) => {
      if (!member.isActive) {
        member.name = StringHelper.getCrossedOutString(member.name);
      }
    });

    const assignedMemberIds = [];
    this.taskAssignmentsService.task.projectTaskAssignments.forEach(
      (assignments) => {
        if (assignments.projectTeamMember?.id) {
          assignedMemberIds.push(assignments.projectTeamMember.id);
        }
      },
    );
    return members.filter((member) => !assignedMemberIds.includes(member.id));
  };
}
