import { Inject, Injectable, Injector } from '@angular/core';
import { DateTime, DurationLike, Interval } from 'luxon';
import { BehaviorSubject, Subject } from 'rxjs';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import { SlotGroup } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import {
  getUnitFromPlanningScale,
  PlanningScale,
} from 'src/app/shared/models/enums/planning-scale.enum';
import { ProjectCardService } from '../../../core/project-card.service';
import { DateValue, RuleDateValue } from '../../models/expenses-data.model';
import {
  ExpensesGroupType,
  ExpensesSectionType,
  ExpensesViewGroup,
  ExpensesViewRuleLine,
  ExpensesViewTaskLine,
  ExpensesViewTypeLine,
} from '../../models/expenses-view.model';
import { ExpensesCalendarSettings } from '../../models/expenses-calendar.settings';
import { ProjectExpensesCalendarDataService } from './project-expenses-calendar-data.service';
import { ProjectExpensesCalendarSlotInfoService } from './project-expenses-calendar-slot-info.service';
import { AppService } from 'src/app/core/app.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectVersionCardService } from '../../../core/project-version-card.service';
import { takeUntil } from 'rxjs/operators';
import { RecurringExpenseRuleDto } from 'src/app/shared/models/entities/projects/recurring-expense-rule-dto.model';
import { RuleCalculationMethod } from 'src/app/shared/models/enums/rule-calculation-method.enum';
import { ProjectExpensesService } from 'src/app/projects/card/project-expenses/project-expenses.service';
import { ExpenseSlot } from 'src/app/projects/card/project-expenses/models/expense-slot.model';
import { ExpensesAccountFilterService } from '../../shared/core/expenses-account-filter.service';

@Injectable()
export class ProjectExpensesCalendarService {
  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private toggleSubject = new Subject<string>();
  public toggle$ = this.toggleSubject.asObservable();

  public loading$ = new BehaviorSubject<boolean>(true);
  public frameLoading$ = new BehaviorSubject<boolean>(false);

  public slots: ExpenseSlot[];
  public slotGroups: SlotGroup[];

  public leftTableWidth = 400;
  public rightTableWidth;

  private interval: Interval;
  private readonly forecastBreakpoint: string;
  private settings: ExpensesCalendarSettings;

  private _readonly: boolean;
  public get readonly(): boolean {
    return this._readonly;
  }

  public get planningScale(): PlanningScale {
    return this.settings.planningScale;
  }
  public get displayedOthers(): Record<
    ExpensesSectionType,
    ExpensesGroupType[]
  > {
    return (
      this.settings.displayedOthers ??
      ({} as Record<ExpensesSectionType, ExpensesGroupType[]>)
    );
  }

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') public entityId: string,
    private injector: Injector,
    private dataService: ProjectExpensesCalendarDataService,
    private navigationService: ScheduleNavigationService,
    private localConfigService: LocalConfigService,
    private projectService: ProjectCardService,
    private versionCardService: ProjectVersionCardService,
    private projectExpensesService: ProjectExpensesService,
    private slotInfoService: ProjectExpensesCalendarSlotInfoService,
    private accountFilterService: ExpensesAccountFilterService,
    private blockUI: BlockUIService,
    private freezeTableService: FreezeTableService,
    private appService: AppService,
  ) {
    this.settings = this.localConfigService.getConfig(ExpensesCalendarSettings);

    this.forecastBreakpoint = ProjectVersionUtil.getForecastBreakpoint(
      this.versionCardService.projectVersion,
      this.appService.session,
    );
  }

  /**
   * Inits navigation service, service subscriptions and calendar itself.
   */
  public init() {
    this.navigationService.init(ScheduleNavigationContext.Expenses);

    this.projectService.project$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((project) => {
        this._readonly =
          !project.expenseEstimateEditAllowed ||
          !this.versionCardService.projectVersion.editAllowed;
      });

    this.slotInfoService.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((sectionTypes) => this.load(sectionTypes, true));

    this.projectExpensesService.ruleModalClosed$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load(null, true);
      });

    this.accountFilterService.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load(null, true);
      });

    this.projectExpensesService.ruleEstimateUpdated$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load(null, true);
      });

    this.navigationService.next$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loadFrame('right');
      });
    this.navigationService.previous$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.loadFrame('left');
      });
    this.navigationService.jump$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((date) => {
        this.reload(date);
      });
    this.navigationService.planningScale$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.reload();
      });

    this.reload();
  }

  /**
   * Disposes all subscriptions.
   * */
  public dispose() {
    this.destroyed$.next();
  }

  /**
   * Toggles group.
   *
   * @param id Group ID.
   */
  public toggleGroup(id: string) {
    this.toggleSubject.next(id);
  }

  /**
   * Toggles group other data.
   *
   * @param group Group.
   */
  public toggleGroupOther(group: ExpensesViewGroup) {
    const updateOthersInSettings = (
      others: Record<ExpensesSectionType, ExpensesGroupType[]>,
    ) => {
      this.settings.displayedOthers = others;
      const settings = this.localConfigService.getConfig(
        ExpensesCalendarSettings,
      );
      settings.displayedOthers = others;
      this.localConfigService.setConfig(ExpensesCalendarSettings, settings);
    };

    const section = this.dataService.getSectionByGroup(group);
    const displayedOthers =
      this.settings.displayedOthers ??
      ({} as Record<ExpensesSectionType, ExpensesGroupType[]>);
    const sectionGroups = displayedOthers[section.type] ?? [];
    if (sectionGroups.includes(group.type)) {
      const indexToRemove = sectionGroups.indexOf(group.type);
      sectionGroups.splice(indexToRemove, 1);
      displayedOthers[section.type] = sectionGroups;

      updateOthersInSettings(displayedOthers);
      this.changesSubject.next();
      return;
    }
    sectionGroups.push(group.type);
    displayedOthers[section.type] = sectionGroups;

    updateOthersInSettings(displayedOthers);
    this.changesSubject.next();
  }

  /**
   * Reloads whole calendar.
   *
   * @param toDate - date to which calendar should make transition.
   */
  public reload(toDate?: DateTime) {
    this.settings = this.localConfigService.getConfig(ExpensesCalendarSettings);
    this.interval = this.navigationService.getInterval(
      this.settings.planningScale,
      toDate,
    );
    this.updateDates();
    this.load();
  }

  /**
   * Loads calendar frame.
   *
   * @param direction Direction in which frame should be expanded.
   * */
  public loadFrame(direction: 'left' | 'right') {
    this.frameLoading$.next(true);
    this.blockUI.start();

    let shift: DurationLike;

    switch (this.settings.planningScale) {
      case PlanningScale.Day:
        shift = { weeks: 2 };
        break;
      case PlanningScale.Week:
        shift = { weeks: 10 };
        break;
      case PlanningScale.Month:
        shift = { month: 5 };
        break;
      case PlanningScale.Quarter:
        shift = { year: 1 };
        break;
      case PlanningScale.Year:
        shift = { year: 2 };
        break;
    }

    let loadingInterval =
      direction === 'left'
        ? Interval.fromDateTimes(
            this.interval.start.minus(shift),
            this.interval.start.minus({ days: 1 }),
          )
        : Interval.fromDateTimes(
            this.interval.end.plus({ days: 1 }),
            this.interval.end.plus(shift),
          );

    this.interval =
      direction === 'left'
        ? this.interval.set({
            start: this.interval.start.minus(shift),
          })
        : this.interval.set({
            end: this.interval.end.plus(shift),
          });

    if (
      this.settings.planningScale === PlanningScale.Month &&
      direction === 'right'
    ) {
      loadingInterval = loadingInterval.set({
        end: loadingInterval.end.endOf('month'),
      });
      this.interval = this.interval.set({
        end: this.interval.end.endOf('month'),
      });
    }

    this.dataService.loadFrame(loadingInterval).then(() => {
      this.updateDates();

      this.frameLoading$.next(false);
      this.blockUI.stop();
      this.changesSubject.next();

      setTimeout(() => {
        this.freezeTableService.disableMutationObserver();
        if (direction === 'left') {
          this.freezeTableService.scrollToLeft();
        } else {
          this.freezeTableService.scrollToRight();
        }

        setTimeout(() => {
          this.freezeTableService.enableMutationObserver();
        }, 500);
      }, 10);
    });
  }

  /**
   * Returns filled slots.
   *
   * @param data Array of DateValue.
   * @returns Filled slots.
   * */
  public getFilledSlots(data: DateValue[] = []): DateValue[] {
    const filledSlots = [];

    this.slots.forEach((slot) => {
      const newSlot = {
        id: slot.id,
        date: null,
        amount: 0,
      };

      data.forEach((d) => {
        if (d.date === slot.date.toISODate()) {
          newSlot.amount = d.amount;
        }
      });

      newSlot.date = slot.date.toISODate();
      filledSlots.push(newSlot);
    });

    return filledSlots;
  }

  /**
   * Returns Expense Rule filled slots.
   *
   * @param data Array of RuleDateValue.
   * @returns Expense Rule filled slots.
   * */
  public getRuleFilledSlots(data: RuleDateValue[] = []): RuleDateValue[] {
    const filledSlots = [];

    this.slots.forEach((slot) => {
      const newSlot = {
        id: slot.id,
        date: null,
        amount: 0,
        deviatesFromBase: false,
        baseHint: null,
      };

      data.forEach((d) => {
        if (d.date === slot.date.toISODate()) {
          newSlot.amount = d.amount;
          newSlot.deviatesFromBase = d.deviatesFromBase;
          newSlot.baseHint = d.baseHint;
        }
      });

      newSlot.date = slot.date.toISODate();
      filledSlots.push(newSlot);
    });

    return filledSlots;
  }

  /**
   * Determines and returns slot width.
   *
   * @param planningScale Planning scale. Not required.
   * If not present service settings are used.
   * @returns Slot width.
   *  */
  public getSlotWidth(planningScale?: PlanningScale): number {
    if (!planningScale) {
      planningScale = this.settings.planningScale;
    }

    switch (planningScale) {
      case PlanningScale.Day:
        return 90;
      case PlanningScale.Week:
        return 90;
      case PlanningScale.Month:
        return 90;
      case PlanningScale.Quarter:
        return 120;
      case PlanningScale.Year:
        return 90;
    }
  }

  /**
   * Determines and returns table width.
   *
   * @param slotWidth Slot width. Not required. If not present, getSlotWidth() is used.
   * @param countOfSlots Slot cound. Not required. If not present, service's slots are used.
   * @returns Table width.
   */
  public getTableWidth(slotWidth?: number, countOfSlots?: number): number {
    if (!slotWidth) {
      slotWidth = this.getSlotWidth();
    }
    if (!countOfSlots) {
      countOfSlots = this.slots.length;
    }

    return slotWidth * countOfSlots;
  }

  /**
   * Determines whether group task line has expense type lines or not.
   *
   * @param taskLine Task line.
   * @returns <code>true</code> if task line has expense type lines, <code>false</code> otherwise.
   */
  public hasExpenseTypes(taskLine: ExpensesViewTaskLine): boolean {
    return taskLine.typeLines.length !== 0;
  }

  /**
   * Determines whether group expense type line has expense rules or not.
   *
   * @param group Group.
   * @param typeLine Expense type line.
   * @returns <code>true</code> if expense type line has rules, <code>false</code> otherwise.
   */
  public hasRules(
    group: ExpensesViewGroup,
    typeLine: ExpensesViewTypeLine,
  ): boolean {
    return (
      group.type !== ExpensesGroupType.actual &&
      !!typeLine.account?.id &&
      typeLine.rules.length !== 0
    );
  }

  /**
   * Returns whether it is allowed to edit slot or not
   *
   * @param group Slot's group.
   * @param entry Slot itself.
   * @returns <code>true</code> if slot is editable, <code>false</code> otherwise.
   */
  public isSlotEditable(group: ExpensesViewGroup, entry: DateValue): boolean {
    const isEstimate = group.type === ExpensesGroupType.estimate;
    const isForecast = group.type === ExpensesGroupType.forecast;
    const entryEndDate = this.getSlotEndDate(entry);
    const isPastBreakpoint = entryEndDate >= this.forecastBreakpoint;
    return isEstimate || (isForecast && isPastBreakpoint);
  }

  /**
   * Returns whether it is allowed to edit rule slot or not.
   *
   * @param group Slot's group.
   * @param expenseRule slot's group.
   * @param entry Slot itself.
   * @returns <code>true</code> if rule slot is editable, <code>false</code> otherwise.
   */
  public isRuleSlotEditable(
    group: ExpensesViewGroup,
    expenseRule: RecurringExpenseRuleDto,
    entry: RuleDateValue,
  ): boolean {
    const isEditable = this.isSlotEditable(group, entry);
    const entryStartDate = this.getSlotStartDate(entry);
    const entryEndDate = this.getSlotEndDate(entry);
    const ruleStartDate = this.getRuleStartDate(expenseRule);
    const ruleEndDate = this.getRuleEndDate(expenseRule);
    const isFixed =
      expenseRule.calculationMethod !== RuleCalculationMethod.Percent;
    const isInRulePeriod =
      entryStartDate >= ruleStartDate && entryEndDate <= ruleEndDate;
    return isEditable && isFixed && isInRulePeriod;
  }

  /**
   * Returns slot scaled start date.
   *
   * @param entry Slot which we try to find date from.
   * @returns Slot scaled start date.
   */
  public getSlotStartDate(entry: DateValue): string {
    const date = DateTime.fromISO(entry.date);
    const unit = getUnitFromPlanningScale(this.planningScale);
    return date.startOf(unit).toISODate();
  }

  /**
   * Returns slot scaled end date.
   *
   * @param entry Slot which we try to find date from.
   * @returns Slot scaled end date.
   */
  public getSlotEndDate(entry: DateValue): string {
    const date = DateTime.fromISO(entry.date);
    const unit = getUnitFromPlanningScale(this.planningScale);
    return date.endOf(unit).toISODate();
  }

  /**
   * Determines whether group expense rule line has deviation indicator or not.
   *
   * @param group Group.
   * @param ruleLine Expense rule line.
   * @returns <code>true</code> if expense rule line has deviation indicator, <code>false</code> otherwise.
   */
  public hasDeviationIndicator(
    group: ExpensesViewGroup,
    ruleLine: ExpensesViewRuleLine,
  ) {
    return (
      (group.type === ExpensesGroupType.estimate ||
        group.type === ExpensesGroupType.forecast) &&
      ruleLine.entries.some((e) => e.deviatesFromBase === true)
    );
  }

  /**
   * Loads calendar data.
   *
   * @param sectionTypes Section types.
   * @param silent Determines whether to block UI or not.
   */
  private load(sectionTypes?: ExpensesSectionType[], silent = false) {
    if (silent) {
      this.blockUI.start();
    } else {
      this.loading$.next(true);
    }

    this.dataService.loadSections(this.interval, sectionTypes).then(() => {
      this.changesSubject.next();
      if (silent) {
        this.blockUI.stop();
      } else {
        this.loading$.next(false);
      }
    });
  }

  /**
   * Updates dates used by view.
   * */
  private updateDates(): void {
    const slotInfo = this.navigationService.getSlots(
      this.interval,
      this.settings.planningScale,
    );

    this.slotGroups = slotInfo.groups;
    this.slots = slotInfo.slots;

    this.rightTableWidth = this.getTableWidth();
  }

  /**
   * Gets Rule scaled start date.
   *
   * @param expenseRule Recurring expense rule.
   * @returns Rule scaled start date.
   */
  private getRuleStartDate(expenseRule: RecurringExpenseRuleDto): string {
    const date = DateTime.fromISO(expenseRule.startDate);
    const unit = getUnitFromPlanningScale(this.planningScale);
    return date.startOf(unit).toISODate();
  }

  /**
   * Gets Rule scaled end date.
   *
   * @param expenseRule Recurring expense rule.
   * @returns Rule scaled end date.
   */
  private getRuleEndDate(expenseRule: RecurringExpenseRuleDto): string {
    const date = DateTime.fromISO(expenseRule.endDate);
    const unit = getUnitFromPlanningScale(this.planningScale);
    return date.endOf(unit).toISODate();
  }
}
