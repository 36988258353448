import {
  Component,
  OnInit,
  ElementRef,
  OnDestroy,
  AfterViewInit,
  Renderer2,
  input,
  viewChild,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Guid } from 'src/app/shared/helpers/guid';
import { WidgetConfig } from '../../../shared/widget-config.model';
import { cloneDeep, orderBy } from 'lodash';
import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { WidgetType } from '../widget-type.model';
import { ReportSourceDescription } from 'src/app/analytics/shared/models/source-description/report-source-description.model';
import { ReportFieldType } from 'src/app/analytics/shared/models/report-field-type.enum';
import { DataService } from 'src/app/core/data.service';
import { AnalyticsService } from 'src/app/core/analytics.service';
import { Subscription, combineLatest, Subject, Observable } from 'rxjs';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { Exception } from 'src/app/shared/models/exception';
import { WidgetService } from '../widget.service';
import { DashboardService } from '../../dashboard.service';
import { ChromeService } from 'src/app/core/chrome.service';
import * as highcharts from 'highcharts';
import { ViewValueField } from 'src/app/analytics/shared/models/view-settings/view-value-field.model';
import HC_more from 'highcharts/highcharts-more';
HC_more(highcharts);

import HC_solidgauge from 'highcharts/modules/solid-gauge';
import { auditTime, filter } from 'rxjs/operators';
import { WidgetDoubleValueProperties } from '../models/widget-double-value-properties.model';
import { WidgetValueProperties } from '../models/widget-value-properties.model';
import { WidgetFunnelChartComponent } from 'src/app/analytics/dashboards/dashboard/widget/funnel/widget-funnel-chart.component';
import { GaugeCustomComponent } from 'src/app/analytics/dashboards/dashboard/widget/gauge/widget-gauge-custom.component';
import { SpeedometerConfig } from 'src/app/analytics/dashboards/dashboard/widget/models/widget-speedometer';
import { StackedColumnsChartComponent } from 'src/app/analytics/dashboards/dashboard/widget/widget-histogram/widget-stacked-column.component';
HC_solidgauge(highcharts);

@Component({
  selector: 'wp-widget-chart',
  templateUrl: './widget-chart.component.html',
  styleUrls: ['./widget-chart.component.scss'],
  standalone: false,
})
export class WidgetChartComponent implements OnInit, OnDestroy, AfterViewInit {
  public widget = input<WidgetConfig>();
  public dashboardId = input<string>();
  public period = input<DatePeriod>();
  public funnelChart = viewChild<WidgetFunnelChartComponent>('funnelChart');
  public gauge = viewChild<GaugeCustomComponent>('gauge');
  public stackedColumnsChart = viewChild<StackedColumnsChartComponent>(
    'stackedColumnsChart',
  );

  // Текстовая часть сокращения (тыс.).
  public abbreviation: string;
  public highcharts = highcharts;
  public chartOptions: Highcharts.Options = {};
  public chartId = Guid.generate();
  public valueId = Guid.generate();
  public emptyValue = this.translate.instant(
    'analytics.dashboards.widget.empty',
  );
  public yesLabel = this.translate.instant('shared.yes');
  public noLabel = this.translate.instant('shared.no');
  public isLoading = true;
  public hasMessage = false;
  public message: string;
  public loadingSubscription: Subscription;
  public data: any[][];
  public hasMaxCount: boolean;
  public fieldNames: string[];
  public value: any;
  public fieldTitle: string;
  public valueFirst: any;
  public valueSecond: any;
  public fieldTitleFirst: string;
  public fieldTitleSecond: string;
  public proportion: number;
  public widgetType = WidgetType;

  private maxDoubleValueFontSize = 34;
  private maxDoubleTitleFontSize = 14;
  private maxSingleValueFontSize = 45;
  private maxSingleTitleFontSize = 17;
  private speedForFontSizeAdaptation = 1;
  private chart: highcharts.Chart;
  private subscriptions: Subscription[];
  private sourceDescription: ReportSourceDescription;
  public speedometerConfig: SpeedometerConfig = {
    value: '0',
    min: 0,
    max: 100,
    title: '',
  };
  private adaptSizeSubject = new Subject<void>();

  constructor(
    private widgetService: WidgetService,
    private chromeService: ChromeService,
    private renderer: Renderer2,
    private dataService: DataService,
    private analyticsService: AnalyticsService,
    private numberPipe: DecimalPipe,
    private datePipe: DatePipe,
    private percentPipe: PercentPipe,
    private translate: TranslateService,
    private elementRef: ElementRef,
    public dashboardService: DashboardService,
  ) {}

  public ngOnInit(): void {
    this.adaptSizeSubject
      .pipe(auditTime(250))
      .subscribe(() => this.adaptSize());

    this.subscriptions = [
      this.widgetService.rebuild$.subscribe(() => this.rebuild()),
      this.widgetService.reflow$
        .pipe(auditTime(50))
        .subscribe(() => this.adaptSizeSubject.next()),
      this.chromeService.mainAreaSize$
        .pipe(auditTime(50))
        .subscribe(() => this.adaptSizeSubject.next()),
      this.dashboardService.reloadWidgets$.subscribe(() => this.loadData(true)),
      this.dashboardService.reflowWidgets$
        .pipe(filter((w) => w.id === this.widget().id))
        .subscribe(() => setTimeout(() => this.adaptSizeSubject.next())),
    ];
  }

  public ngAfterViewInit(): void {
    this.adaptSizeSubject.next();
    this.rebuild();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  /** Changes size of a chart. */
  public adaptSize(): void {
    const widgetPadding = 10;

    const container = this.elementRef.nativeElement as HTMLElement;
    if (!container) {
      return;
    }
    const rect = container.getBoundingClientRect();
    const chartjsRect =
      container.parentElement.parentElement.getBoundingClientRect();
    const funnelChartRect =
      container.parentElement.parentElement.getBoundingClientRect();

    if (this.chart) {
      this.chart.setSize(
        rect.width - widgetPadding * 2,
        rect.height - widgetPadding * 2,
        false,
      );
      this.chart.reflow();
    }

    if (this.stackedColumnsChart) {
      this.stackedColumnsChart()?.resize(
        chartjsRect.width - widgetPadding * 2,
        chartjsRect.height - widgetPadding * 2,
      );
    }

    if (this.funnelChart) {
      this.funnelChart()?.resize(
        funnelChartRect.width - widgetPadding * 6,
        funnelChartRect.height - widgetPadding * 6,
      );
    }

    // Адаптировать размер шрифта для вписывания в пределы виджета.
    if (
      this.widget().type === WidgetType.Value ||
      this.widget().type === WidgetType.DoubleValue
    ) {
      const valueContainer = container.querySelector(
        this.widget().type === WidgetType.Value
          ? '.single-value'
          : '.double-value',
      );
      const valueNumberElements =
        valueContainer.querySelectorAll('.value-number');
      const valueAbbreviationElements = valueContainer.querySelectorAll(
        '.value-abbreviation',
      );
      const titleElements = valueContainer.querySelectorAll('.title');
      this.renderer.setStyle(
        valueContainer,
        'width',
        `${rect.width - widgetPadding * 2}px`,
      );

      const setFontSize = () => {
        valueNumberElements.forEach((el) => {
          this.renderer.setStyle(el, 'font-size', `${valueFontSize}px`);
        });

        valueAbbreviationElements.forEach((el) => {
          this.renderer.setStyle(el, 'font-size', `${valueFontSize / 2.5}px`);
        });

        titleElements.forEach((el) => {
          this.renderer.setStyle(el, 'font-size', `${titleFontSize}px`);
        });
      };

      let valueFontSize =
        this.widget().type === WidgetType.Value
          ? this.maxSingleValueFontSize
          : this.maxDoubleValueFontSize;

      let titleFontSize =
        this.widget().type === WidgetType.Value
          ? this.maxSingleTitleFontSize
          : this.maxDoubleTitleFontSize;

      setFontSize();

      const adaptFontSize = (): void => {
        const valueRect = valueContainer.getBoundingClientRect();
        if (
          valueRect.height > rect.height - widgetPadding * 2 &&
          valueFontSize > 12
        ) {
          valueFontSize -= this.speedForFontSizeAdaptation;

          if (titleFontSize > 9) {
            titleFontSize -= this.speedForFontSizeAdaptation;
          }

          setFontSize();
          adaptFontSize();
        }
      };

      adaptFontSize();
    }
  }

  private showNoData(): void {
    this.message = this.translate.instant('shared.noDisplayData');
    this.hasMessage = true;
  }

  private showTooMuchData(): void {
    this.hasMessage = true;
    this.message = this.translate.instant(
      'analytics.dashboards.widget.tooMuchData',
    );
  }

  // Возвращает массив полей-значений, даже если в виджете одно поле-значение.
  private getValueFields(): ViewValueField[] {
    if (
      this.widget().viewSettings.valueFields &&
      this.widget().viewSettings.valueFields.length > 0
    ) {
      return cloneDeep(this.widget().viewSettings.valueFields);
    }

    if (this.widget().viewSettings.valueField) {
      return [this.widget().viewSettings.valueField];
    }

    return [];
  }

  private getValueFieldTitle(name: string): string {
    const valueFields = this.getValueFields();
    const field = valueFields.find((f) => f.name === name);
    return field.customTitle
      ? field.customTitle
      : this.sourceDescription.allFields.find((f) => f.name === name).title;
  }

  private getSeriesName(seriesName: string, hasLegend: boolean): string {
    if (!seriesName) {
      return this.emptyValue;
    }

    if (!hasLegend) {
      return this.getValueFieldTitle(seriesName);
    }

    const sourceColumn = this.sourceDescription.allFields.find(
      (f) => f.name === this.widget().viewSettings.legendField.name,
    );
    return this.getFormattedValue(seriesName, sourceColumn.type);
  }

  private getFormattedValue(value: any, type: ReportFieldType): string | null {
    if (value === null || value === 'null') {
      return this.emptyValue;
    }

    switch (type) {
      case ReportFieldType.Date:
        return this.datePipe.transform(value, 'shortDate');
      case ReportFieldType.DateTimeOffset:
        return this.datePipe.transform(value, 'short');
      case ReportFieldType.Decimal:
        return this.numberPipe.transform(value, '1.0-2');
      case ReportFieldType.Integer:
        return this.numberPipe.transform(value, '1.0-0');
      case ReportFieldType.Percent:
        return this.percentPipe.transform(value, '1.0-2');
      case ReportFieldType.Bool:
        return value ? this.yesLabel : this.noLabel;

      default:
        return value;
    }
  }

  private getFieldIndex(name: string): number {
    return this.fieldNames.indexOf(name);
  }

  private processChartData(): void {
    // Предварительная подготовка данных.
    const valueFields = this.getValueFields();

    // Нормализованные данные.
    const normalData = [];

    const hasLegend = this.widget().viewSettings.legendField != null;
    const hasCategory = this.widget().viewSettings.categoryField != null;
    const hasValues = valueFields && valueFields.length > 0;

    if (this.data.length === 0 || (!hasLegend && !hasValues)) {
      this.showNoData();
      return;
    }

    const legendFieldIndex = hasLegend
      ? this.getFieldIndex(this.widget().viewSettings.legendField.name)
      : null;
    const valueFieldIndex = hasValues
      ? this.getFieldIndex(valueFields[0].name)
      : null;
    const categoryFieldIndex = hasCategory
      ? this.getFieldIndex(this.widget().viewSettings.categoryField.name)
      : null;
    const categorySourceColumn = hasCategory
      ? this.sourceDescription.allFields.find(
          (f) => f.name === this.widget().viewSettings.categoryField.name,
        )
      : null;

    // Трансформация в нормализованный массив объектов.
    this.data.forEach((row) => {
      const addNormRow = (
        seriesName: string,
        value: any,
        valueField: ViewValueField,
      ) => {
        const normRow: {
          category?: string;
          series?: string;
          value?: number;
        } = {};

        if (!hasCategory) {
          normRow.category = null;
        } else {
          normRow.category = row[categoryFieldIndex];
        }

        normRow.series = seriesName;

        if (valueField) {
          const valueFieldType = this.sourceDescription.allFields.find(
            (f) => f.name === valueField.name,
          ).type;
          if (valueFieldType === ReportFieldType.Percent) {
            value *= 100;
          }
        }

        normRow.value = value;
        normalData.push(normRow);
      };

      // Несколько значений (значит нет легенды).
      if (valueFields && valueFields.length > 1) {
        valueFields.forEach((valueField) => {
          const valueIndex = this.getFieldIndex(valueField.name);
          addNormRow(valueField.name, row[valueIndex], valueField);
        });

        return;
      }

      // Есть легенда (значит одно значение).
      if (hasLegend) {
        const value = hasValues ? row[valueFieldIndex] : 0;
        addNormRow(row[legendFieldIndex], value, valueFields[0]);
        return;
      }

      // И вариант когда нет легенды, но по условию ранее есть значение.
      addNormRow(valueFields[0].name, row[valueFieldIndex], valueFields[0]);
    });

    // Для сортировки получить массив категорий [{category: , value}].
    let categoryData = !hasCategory
      ? []
      : normalData.reduce((acc, x) => {
          const categoryRow = acc.find((c) => c.category === x.category);

          if (categoryRow) {
            if (!isNaN(x.value)) {
              categoryRow.value += x.value;
            }
          } else {
            acc.push({
              category: x.category,
              value: x.value,
              formated: this.getFormattedValue(
                x.category,
                categorySourceColumn.type,
              ),
            });
          }
          return acc;
        }, []);

    // Сортировать категории.
    const sortProperty =
      this.widget().properties.sortBy === 'Value' ? 'value' : 'category';
    const sortOrder: 'asc' | 'desc' = this.widget().properties.sortReverse
      ? 'desc'
      : 'asc';
    categoryData = orderBy(
      categoryData,
      [(o) => o[sortProperty] ?? 0],
      [sortOrder],
    );

    // Взять первые n категорий.
    if (this.widget().properties.showTop) {
      categoryData = categoryData.slice(0, this.widget().properties.showTop);
    }

    // Получить массив серий.
    const seriesData = normalData.reduce((acc, x) => {
      const seriesRow = acc.find((a) => a.series === x.series);

      if (!seriesRow) {
        acc.push({
          series: x.series,
        });
      }
      return acc;
    }, []);

    const chartSeries = {};

    seriesData.forEach((seriesDataRow) => {
      const seriesName = seriesDataRow.series;

      if (!chartSeries[seriesName]) {
        chartSeries[seriesName] = {
          name: seriesName,
          data: [],
        };
      }

      categoryData.forEach((categoryDataRow) => {
        let index = normalData.length;
        let wasFound = false;
        while (index--) {
          if (
            normalData[index].category === categoryDataRow.category &&
            normalData[index].series === seriesDataRow.series
          ) {
            const row = normalData.splice(index, 1)[0];
            chartSeries[seriesName].data.push({
              y: row.value,
              name: categoryDataRow.formated,
            });
            wasFound = true;
            break;
          }
        }

        if (!wasFound) {
          chartSeries[seriesName].data.push({
            y: null,
            name: categoryDataRow.formated,
          });
        }
      });
    });

    for (const seriesName in chartSeries) {
      if (Object.prototype.hasOwnProperty.call(chartSeries, seriesName)) {
        const series = chartSeries[seriesName];
        try {
          this.chart.addSeries(
            {
              type: undefined,
              name: this.getSeriesName(seriesName, hasLegend),
              data: series.data,
            },
            false,
          );
        } catch (ex) {
          /* empty */
        }
      }
    }
  }

  private processValueData(): void {
    const props = this.widget().properties as WidgetValueProperties;

    if (this.data.length === 0) {
      this.showNoData();
      return;
    }

    const valueFieldIndex = this.getFieldIndex(
      this.widget().viewSettings.valueField.name,
    );
    this.value = this.data[0][valueFieldIndex];

    if (this.value === null) {
      this.value = 0;
    }
    const sourceField = this.sourceDescription.allFields.find(
      (f) => f.name === this.widget().viewSettings.valueField.name,
    );

    const formatValue = (value) => {
      let format = '1.0-2';
      if (
        sourceField.type === ReportFieldType.Integer ||
        (props.abbreviation && props.abbreviation !== 'none' && value >= 100)
      ) {
        format = '1.0-0';
      }
      switch (sourceField.type) {
        case ReportFieldType.Percent:
          value = this.percentPipe.transform(value, format);
          break;
        default:
          value = this.numberPipe.transform(value, format);
      }

      return value;
    };

    switch (props.abbreviation) {
      case 'thousands':
        this.value = this.value / 1000;
        this.abbreviation = this.translate.instant(
          'analytics.abbreviations.thousands',
        );
        break;

      case 'millions':
        this.value = this.value / 1000000;
        this.abbreviation = this.translate.instant(
          'analytics.abbreviations.millions',
        );
        break;

      default:
        this.abbreviation = '';
        break;
    }

    this.value = formatValue(this.value);
    this.fieldTitle =
      this.widget().viewSettings.valueField &&
      this.widget().viewSettings.valueField.customTitle
        ? this.widget().viewSettings.valueField.customTitle
        : sourceField.title;
  }

  private processDoubleValueData(): void {
    const props = this.widget().properties as WidgetDoubleValueProperties;

    if (this.data.length === 0) {
      this.showNoData();
      return;
    }
    const firstRow = this.fieldNames;

    const getFieldIndex = (name: string) => firstRow.indexOf(name);

    const getValue = (index: number) => {
      if (this.widget().viewSettings.valueFields.length - 1 < index) {
        return 0;
      }
      const valueFieldIndex = getFieldIndex(
        this.widget().viewSettings.valueFields[index].name,
      );
      const value = this.data[0][valueFieldIndex];
      return value ?? 0;
    };

    const formatValue = (value: any, index: number) => {
      if (!this.widget().viewSettings.valueFields[index]) {
        return null;
      }

      const sourceField = this.sourceDescription.allFields.find(
        (f) => f.name === this.widget().viewSettings.valueFields[index].name,
      );

      let format = '1.0-2';
      if (
        sourceField.type === ReportFieldType.Integer ||
        (props.abbreviation && props.abbreviation !== 'none' && value >= 100)
      ) {
        format = '1.0-0';
      }
      switch (sourceField.type) {
        case ReportFieldType.Percent:
          value = this.percentPipe.transform(value, format);
          break;
        default:
          value = this.numberPipe.transform(value, format);
      }

      return value;
    };

    const getTitle = (index: number) => {
      if (this.widget().viewSettings.valueFields.length - 1 < index) {
        return null;
      }

      const sourceField = this.sourceDescription.allFields.find(
        (f) => f.name === this.widget().viewSettings.valueFields[index].name,
      );

      return this.widget().viewSettings.valueFields[index].customTitle
        ? this.widget().viewSettings.valueFields[index].customTitle
        : sourceField.title;
    };

    this.valueFirst = getValue(0);
    this.valueSecond = getValue(1);

    this.fieldTitleFirst = getTitle(0);
    this.fieldTitleSecond = getTitle(1);

    this.proportion = 0;

    if (this.widget().properties.proportion === 'inverse') {
      if (this.valueFirst > 0) {
        this.proportion = this.valueSecond / this.valueFirst;
      }
    } else {
      if (this.valueSecond > 0) {
        this.proportion = this.valueFirst / this.valueSecond;
      }
    }

    switch (props.abbreviation) {
      case 'thousands':
        this.valueFirst = this.valueFirst / 1000;
        this.valueSecond = this.valueSecond / 1000;
        this.abbreviation = this.translate.instant(
          'analytics.abbreviations.thousands',
        );
        break;

      case 'millions':
        this.valueFirst = this.valueFirst / 1000000;
        this.valueSecond = this.valueSecond / 1000000;
        this.abbreviation = this.translate.instant(
          'analytics.abbreviations.millions',
        );
        break;

      default:
        this.abbreviation = '';
        break;
    }

    this.valueFirst = formatValue(this.valueFirst, 0);
    this.valueSecond = formatValue(this.valueSecond, 1);
  }

  private processSpeedometerData(): void {
    if (this.data.length === 0) {
      this.showNoData();
      return;
    }

    const valueFieldIndex = this.getFieldIndex(
      this.widget().viewSettings.valueField.name,
    );
    this.value = this.data[0][valueFieldIndex];

    const sourceField = this.sourceDescription.allFields.find(
      (f) => f.name === this.widget().viewSettings.valueField.name,
    );

    let point: any;

    switch (sourceField.type) {
      case ReportFieldType.Percent:
        this.speedometerConfig = {
          ...this.speedometerConfig,
          value: this.percentPipe.transform(this?.value ?? 0, '1.0-2'),
          title: sourceField.title,
        };
        point = {
          y: this.value * 100,
          dataLabels: {
            formatter: () =>
              this.percentPipe.transform(this?.value ?? 0, '1.0-2'),
            enabled: true,
          },
        };
        break;
      case ReportFieldType.Decimal:
        this.speedometerConfig = {
          ...this.speedometerConfig,
          value: this.numberPipe.transform(this?.value ?? 0, '1.0-2'),
          title: sourceField.title,
        };
        point = {
          y: this.value,
          dataLabels: {
            formatter: () =>
              this.numberPipe.transform(this?.value ?? 0, '1.0-2'),
            enabled: true,
          },
        };
        break;

      default:
        point = {
          y: this.value,
          dataLabels: {
            formatter: () =>
              this.numberPipe.transform(this?.value ?? 0, '1.0-0'),
            enabled: true,
          },
        };
    }

    const seriesName = this.widget().viewSettings.valueField.customTitle
      ? this.widget().viewSettings.valueField.customTitle
      : this.sourceDescription.allFields.find(
          (f) => f.name === this.widget().viewSettings.valueField.name,
        ).title;

    try {
      this.chart.addSeries(
        {
          type: undefined,
          name: seriesName,
          data: [point],
        },
        false,
      );
    } catch (ex) {
      /* empty */
    }

    this.adaptSizeSubject.next();
  }

  private loadData(force?: boolean): void {
    if (!this.widget().viewSettings.sourceName) {
      return;
    }

    this.hasMessage = false;
    this.isLoading = true;
    this.adaptSizeSubject.next();

    while (this.chart && this.chart.series.length > 0) {
      this.chart.series[0].remove(false);
    }

    const observables: Observable<any>[] = [
      this.analyticsService.getSourceDescription(
        this.widget().viewSettings.sourceName,
      ),
    ];

    const params: Dictionary<any> = {
      datasetId: this.widget().id,
      forceRefresh: force === true,
      viewPeriod: '@viewPeriod',
    };

    // TODO Совместимость.
    const urlParams: Dictionary<any> = {
      '@viewPeriod': this.period()
        ? JSON.stringify({
            from: this.period().from,
            to: this.period().to,
            periodType: this.period().periodType,
          })
        : null,
    };

    observables.push(
      this.dataService
        .collection('Dashboards')
        .entity(this.dashboardId())
        .function('WP.GetDataset')
        .query(params, null, urlParams),
    );

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = combineLatest(observables).subscribe((value) => {
      this.sourceDescription = value[0];

      this.data = value[1].data;
      this.hasMaxCount = value[1].hasMaxCount;
      this.fieldNames = value[1].fieldNames;
    });

    this.loadingSubscription = combineLatest(observables).subscribe({
      next: (value) => {
        this.sourceDescription = value[0];

        this.data = value[1].data;
        this.hasMaxCount = value[1].hasMaxCount;
        this.fieldNames = value[1].fieldNames;

        if (
          [
            WidgetType.Column,
            WidgetType.StackedColumns,
            WidgetType.StackedPercentageColumns,
            WidgetType.Line,
            WidgetType.Pie,
          ].includes(this.widget().type)
        ) {
          this.processChartData();
        }

        if (this.widget().type === WidgetType.Speedometer) {
          this.processSpeedometerData();
        }

        if (this.widget().type === WidgetType.Value) {
          this.processValueData();
        }

        if (this.widget().type === WidgetType.DoubleValue) {
          this.processDoubleValueData();
        }

        this.isLoading = false;

        if (this.hasMaxCount) {
          this.showTooMuchData();
          return;
        }

        if (this.chart) {
          this.chart.redraw();
        }
      },
      error: (error: Exception) => {
        this.isLoading = false;
        this.showNoData();
      },
    });
  }

  private initChart(): void {
    if (!this.widget().viewSettings.sourceName) {
      return;
    }

    let stacking;
    if (this.widget().type === WidgetType.StackedColumns) {
      stacking = 'normal';
    }
    if (this.widget().type === WidgetType.StackedPercentageColumns) {
      stacking = 'percent';
    }

    let type = 'column';
    if (this.widget().type === WidgetType.Line) {
      type = 'line';
    }

    const numberPipe = this.numberPipe;
    this.chartOptions = {
      chart: {
        type,
      },
      legend: {
        enabled: this.widget().properties.hasLegend,
      },
      title: null,
      credits: {
        enabled: false,
      },
      xAxis: {
        type: 'category',
      },
      yAxis: {
        title: null,
      },
      tooltip: {
        valueDecimals: 2,
      },
      plotOptions: {
        column: {
          stacking,
          dataLabels: {
            enabled: this.widget().properties.hasDataLabels,
            formatter() {
              return numberPipe.transform(this?.y ?? 0, '1.0-2');
            },
          },
        },
        line: {
          dataLabels: {
            enabled: this.widget().properties.hasDataLabels,
            formatter() {
              return numberPipe.transform(this?.y ?? 0, '1.0-2');
            },
          },
        },
      },
      series: [],
    };
    this.chart = highcharts.chart(this.chartId, this.chartOptions);
    this.adaptSizeSubject.next();
    this.loadData();
  }

  private initPie(): void {
    this.chartOptions = {
      chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
      },
      title: null,
      legend: {
        enabled: this.widget().properties.hasLegend,
      },

      credits: {
        enabled: false,
      },
      tooltip: {},
      plotOptions: {
        pie: {
          allowPointSelect: true,
          cursor: 'pointer',
          dataLabels: {
            enabled: this.widget().properties.hasDataLabels,
            format: '{point.name}: {point.percentage:.1f}%',
            style: { fontWeight: 'normal' },
          },
          showInLegend: true,
        },
      },
      series: [],
    };
    this.chart = highcharts.chart(this.chartId, this.chartOptions);
    this.adaptSizeSubject.next();
    this.loadData();
  }

  private initSpeedometer(): void {
    const { min, max, value, segments } = this.widget().properties;
    Object.assign(this.speedometerConfig, {
      min,
      max,
      value,
      segments,
    });

    this.chartOptions = {
      chart: {
        type: 'gauge',
        plotBackgroundColor: null,
        plotBackgroundImage: null,
        plotBorderWidth: 0,
        plotShadow: false,
        displayErrors: true,
      },

      title: {
        text: null,
      },
      credits: {
        enabled: false,
      },
      pane: {
        size: '100%',
        startAngle: -150,
        endAngle: 150,
        background: null,
      },

      // the value axis
      yAxis: {
        min: this.widget().properties.min,
        max: this.widget().properties.max,

        minorTickInterval: 'auto',
        minorTickWidth: 1,
        minorTickLength: 10,
        minorTickPosition: 'inside',
        minorTickColor: '#665',

        tickPixelInterval: 30,
        tickWidth: 2,
        tickPosition: 'inside',
        tickLength: 10,
        tickColor: '#665',
        labels: {
          step: 2,
        },

        plotBands: this.widget().properties.segments,
      },
      tooltip: {
        valueDecimals: 2,
      },
    };
    this.chart = highcharts.chart(this.chartId, this.chartOptions);
    this.adaptSizeSubject.next();
    this.loadData();
  }

  private rebuild(): void {
    if (
      this.widget().type === WidgetType.Value ||
      this.widget().type === WidgetType.DoubleValue
    ) {
      this.loadData();
      return;
    }

    if (this.widget().type === WidgetType.Speedometer) {
      this.initSpeedometer();
      return;
    }

    if (this.widget().type === WidgetType.Pie) {
      this.initPie();
      return;
    }

    this.initChart();
  }
}
