import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { ProgramCardService } from './program-card.service';
import { UIRouterGlobals } from '@uirouter/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Program } from 'src/app/shared/models/entities/projects/program.model';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { AppService } from 'src/app/core/app.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { OverviewKpiService } from 'src/app/shared/components/features/overview/overview-kpi/core/overview-kpi.service';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { CoManagersModalComponent } from 'src/app/shared/components/co-managers-modal/co-managers-modal.component';

@Component({
  selector: 'wp-program-card',
  templateUrl: './program-card.component.html',
  styleUrls: ['./program-card.component.scss'],
  providers: [ProgramCardService, OverviewKpiService],
  standalone: false,
})
export class ProgramCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public program: Program;
  public programTotal: any;

  private readonly destroyed$ = new Subject<void>();

  tabs: TabLink[] = [
    {
      header: 'projects.programs.card.tabs.overview',
      state: 'program.overview',
    },
    {
      header: 'projects.programs.card.tabs.projects',
      state: 'program.projects',
    },
  ];

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    private app: AppService,
    private notification: NotificationService,
    private data: DataService,
    public service: ProgramCardService,
    private modal: NgbModal,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private actionPanelService: ActionPanelService,
  ) {}

  public getProgramStateTitle() {
    return this.program && this.program.isActive
      ? 'projects.programs.card.states.active'
      : 'projects.programs.card.states.closed';
  }

  // Изменение состояния.
  public selectState(isActive: boolean) {
    this.program.isActive = isActive;
    this.data
      .collection('Programs')
      .entity(this.entityId)
      .patch({ isActive })
      .subscribe({
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  public saveName = (name: string) =>
    this.data.collection('Programs').entity(this.entityId).patch({ name });

  public openCoManagers() {
    const modalRef = this.modal.open(CoManagersModalComponent);
    const instance = modalRef.componentInstance as CoManagersModalComponent;
    instance.readonly = !this.program.coManagersEditAllowed;
    instance.type = 'program';
    instance.manager = this.program.manager;
    instance.entityId = this.entityId;
  }

  public openUserInfo() {
    const userId = this.program.manager.id;
    const target = document.getElementById('manager');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  ngOnInit() {
    this.service.load(this.entityId);
    this.service.program$.subscribe((program: Program) => {
      this.program = program;
    });

    this.service.programTotal$.subscribe((programTotal: any) => {
      this.programTotal = programTotal;
    });

    if (this.app.checkFeature(Feature.finance)) {
      this.tabs.push({
        header: 'projects.programs.card.tabs.pnl',
        state: 'program.pnl',
      });
    }

    this.actionPanelService.setHasAutosave(true);
    this.initSubscribers();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private initSubscribers(): void {
    this.actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.reloadTab();
      });
  }
}
