import { NodeType } from 'prosemirror-model';
import {
  liftListItem,
  sinkListItem,
  wrapInList,
} from 'prosemirror-schema-list';
import { Command, EditorState } from 'prosemirror-state';
import {
  blType,
  findAnyParentList,
  isIntoListOfType,
  ListNode,
  liType,
  olType,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/lists/consts';
import { MenuItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';

/**
 * Wraps in list depending on list type or just change type if parent is already list.
 * @param listType list type (bullet or ordered).
 * @returns editor view state command.
 */
export function toList(listType: NodeType): Command {
  return (state, dispatch) => {
    const parentList = findAnyParentList(state.schema)(state.selection);
    if (parentList) {
      if (listType === parentList.node.type) return true;

      dispatch?.(state.tr.setNodeMarkup(parentList.pos, listType));
      return true;
    }
    return wrapInList(listType)(state, dispatch);
  };
}

/**
 * Adds list block type.
 *
 * @param type list type.
 * @returns editor view state command.
 */
export function list(
  type: ListNode.BulletList | ListNode.OrderedList,
): Command {
  return (state, dispatch) => {
    if (type === ListNode.BulletList) {
      return toList(blType(state.schema))(state, dispatch);
    }
    if (type === ListNode.OrderedList) {
      return toList(olType(state.schema))(state, dispatch);
    }
  };
}

/**
 * Lifts list item.
 *
 * @returns editor view state command.
 */
export function liftLiItem(): Command {
  return (state, dispatch) =>
    liftListItem(liType(state.schema))(state, dispatch);
}

/**
 * Sinks list item.
 *
 * @returns editor view state command.
 */
export function sinkLiItem(): Command {
  return (state, dispatch) =>
    sinkListItem(liType(state.schema))(state, dispatch);
}

/**
 * Changes lists menu items isActive and disabled properties.
 * @param item lists menu item.
 * @param state editor view current state.
 */
export function toggleListActiveAndDisable(
  item: MenuItem,
  state: EditorState,
): void {
  item.subItems.find((item) => item.id === ListNode.BulletList).isActive =
    isIntoListOfType(ListNode.BulletList)(state);
  item.subItems.find((item) => item.id === ListNode.OrderedList).isActive =
    isIntoListOfType(ListNode.OrderedList)(state);

  const isParentActive = item.subItems.find(
    (item) =>
      (ListNode.BulletList === item.id || ListNode.OrderedList === item.id) &&
      item.isActive,
  );
  item.isActive = !!isParentActive;

  item.subItems.find((item) => item.id === 'lift_item').disabled =
    !item.isActive || !liftListItem(liType(state.schema))(state);
  item.subItems.find((item) => item.id === 'sink_item').disabled =
    !item.isActive || !sinkListItem(liType(state.schema))(state);
}
