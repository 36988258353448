import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Injector,
  Input,
  OnInit,
} from '@angular/core';
import { ResourceSummaryViewSettingsService } from 'src/app/resource-summary/shared/resource-summary-view-settings/core/resource-summary-view-settings.service';
import { filter } from 'rxjs/operators';
import { ResourceSummaryService } from 'src/app/resource-summary/core/resource-summary.service';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ProjectInfoComponent } from 'src/app/shared/components/features/project-info/project-info.component';
import { ResourceSummaryPageDto } from 'src/app/resource-summary/models/resource-summary-data.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Resource Summary Left Grid content. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtResourceSummaryLeftGroup]',
  templateUrl: './resource-summary-left-group.component.html',
  styleUrls: ['./resource-summary-left-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceSummaryLeftGroupComponent implements OnInit {
  @Input() summaryPageDto: ResourceSummaryPageDto;

  /**
   * Indicates whether row is expanded or collapsed.
   *
   * @returns `true` if row is expanded, `false` otherwise.
   * */
  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  /** Gets Selected KPI Types. */
  public get selectedKpiTypes(): KpiType[] {
    return this._selectedKpiTypes;
  }

  /** Gets Selected KPI Types without Schedule data. */
  public get selectedKpiTypesWithoutSchedule(): KpiType[] {
    return this._selectedKpiTypesWithoutSchedule;
  }

  /**
   * Indicates whether Actual KPI type is selected or not.
   *
   * @returns `true` if selected, `false` otherwise.
   * */
  public get isActualKpiTypeSelected(): boolean {
    return this._isActualKpiTypeSelected;
  }

  /**
   * Indicates whether Summary Project lines are empty or not.
   *
   * @returns `true` if Project lines are empty, `false` otherwise.
   * */
  public get isProjectLinesEmpty(): boolean {
    const isEmptyLines = !this.summaryPageDto.lines.length;
    const isOnlyTimeOffAndActualTypeNotSelected =
      this.summaryPageDto.lines.length === 1 &&
      !this.summaryPageDto.lines[0].id &&
      this.summaryPageDto.hasTimeOff &&
      !this._isActualKpiTypeSelected;
    return isEmptyLines || isOnlyTimeOffAndActualTypeNotSelected;
  }

  protected kpiType = KpiType;
  protected valueMode = ValueMode;

  private _isExpanded: boolean;
  private _selectedKpiTypes: KpiType[];
  private _selectedKpiTypesWithoutSchedule: KpiType[];
  private _isActualKpiTypeSelected: boolean;

  private destroyRef = inject(DestroyRef);

  constructor(
    public summaryService: ResourceSummaryService,
    private summaryViewSettingsService: ResourceSummaryViewSettingsService,
    private cdRef: ChangeDetectorRef,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {
    this._selectedKpiTypes = this.summaryService.selectedKpiTypes;
    this._selectedKpiTypesWithoutSchedule =
      this.summaryService.selectedKpiTypesWithoutSchedule;
    this._isActualKpiTypeSelected = this.summaryService.isActualKpiTypeSelected;
  }

  ngOnInit(): void {
    this.initSubscriptions();
  }

  /**
   * Resource info element click handler.
   * Opens Resource Info popup.
   *
   * @param container HTML element container.
   * @param userId User ID.
   * */
  public openResourceInfo(container: HTMLElement, userId: string): void {
    this.infoPopupService.open({
      target: container,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Project info element click handler.
   * Opens Project Info popup.
   *
   * @param container HTML element container.
   * @param projectId Project ID.
   * */
  public openProjectInfo(container: HTMLElement, projectId: string): void {
    this.infoPopupService.open({
      target: container,
      data: {
        component: ProjectInfoComponent,
        params: {
          projectId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Row toggle click handler.
   * Propagates expand/collapse to Resource Summary component.
   * */
  public onToggleClick(): void {
    this._isExpanded = !this._isExpanded;

    this.summaryService.toggleGroup(this.summaryPageDto.id, this.isExpanded);
    this.summaryService.detectGroupChanges(this.summaryPageDto.id);
  }

  /**
   * Gets Project Total value.
   *
   * @param projectId Project ID.
   * @param kpiType KPI Type.
   * @returns User Total value.
   * */
  public getProjectTotal(projectId: string, kpiType: KpiType): number | null {
    const line = this.summaryPageDto.lines.find((l) => l.id === projectId);
    return line?.totalValues.find(
      (totalValue) => totalValue.kpiType === kpiType,
    )?.value;
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.summaryService.detectChanges$
      .pipe(
        filter((id) => !id || id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.summaryService.toggleGroup$
      .pipe(
        filter((event) => event?.id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        this._isExpanded = event.state;
      });

    this.summaryViewSettingsService.settings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this._selectedKpiTypes = this.summaryService.selectedKpiTypes;
        this._selectedKpiTypesWithoutSchedule =
          this.summaryService.selectedKpiTypesWithoutSchedule;
        this._isActualKpiTypeSelected =
          this.summaryService.isActualKpiTypeSelected;
        this.cdRef.detectChanges();
      });
  }
}
