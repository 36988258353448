import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormArray,
  FormBuilder,
  FormGroup,
  UntypedFormGroup,
} from '@angular/forms';
import { AuthProviderType } from 'src/app/shared/models/enums/auth-provider-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { SettingsAuthCreateProviderModalComponent } from 'src/app/settings-app/settings/settings-security/settings-auth-create-provider-modal/settings-auth-create-provider-modal.component';
import { DataService } from 'src/app/core/data.service';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';
import { MessageService } from 'src/app/core/message.service';
import { BehaviorSubject, Subject } from 'rxjs';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { SettingsCardService } from 'src/app/settings-app/settings/settings-card.service';
import { takeUntil } from 'rxjs/operators';
import { Order } from 'src/app/shared/models/inner/order';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridComponentColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { SettingAuthProvidersToolbarComponent } from 'src/app/settings-app/settings/settings-security/settings-auth-providers/setting-auth-providers-toolbar/setting-auth-providers-toolbar.component';

@Component({
  selector: 'wp-settings-auth-providers',
  templateUrl: './settings-auth-providers.component.html',
  styleUrls: ['./settings-auth-providers.component.scss'],
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class SettingsAuthProvidersComponent implements OnInit {
  public readonly = signal<boolean>(false);
  public isReadingAllowed = signal<boolean>(true);
  public isSelectedProviderEditable = signal<boolean>(false);

  public providersArray = this.formBuilder.array([]);

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    sorting: true,
    toolbar: SettingAuthProvidersToolbarComponent,
    commands: [
      {
        name: 'createLocal',
        handlerFn: () => {
          this.create(AuthProviderType.Local);
        },
      },
      {
        name: 'createOpenId',
        handlerFn: () => {
          this.create(AuthProviderType.OpenId);
        },
      },
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: () => this.isSelectedProviderEditable(),
        handlerFn: (formGroup: FormGroup) => {
          this.edit(formGroup);
        },
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: (formGroup) => {
          const formGroupType = formGroup.getRawValue().type;
          return formGroupType !== AuthProviderType.AzureAd;
        },
        handlerFn: (formGroup: FormGroup) => {
          this.edit(formGroup);
        },
      },

      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup) => {
          const formGroupType = formGroup.getRawValue().type;
          return !this.readonly() && formGroupType !== AuthProviderType.AzureAd;
        },
        handlerFn: (formGroup: FormGroup) => {
          this.delete(formGroup);
        },
      },
    ],
    view: {
      name: 'providers',
      columns: [
        <GridComponentColumn>{
          name: 'name',
          type: GridColumnType.String,
          width: '50%',
          header: 'settings.settings.authProviders.columns.name',
          hint: 'settings.settings.authProviders.columns.name',
        },
        {
          name: 'type',
          header: 'settings.settings.authProviders.columns.type',
          hint: 'settings.settings.authProviders.columns.type',
          type: GridColumnType.String,
          width: '50%',
        },
      ],
    },
  };

  private isLoadingSubject = new BehaviorSubject<boolean>(false);
  public isLoading$ = this.isLoadingSubject.asObservable();

  private sortingOrder: Order = { column: 'name', reverse: true };
  private destroySubject = new Subject<void>();

  constructor(
    private formBuilder: FormBuilder,
    private ngbModal: NgbModal,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
    private app: AppService,
    private service: SettingsCardService,
    public gridService: GridService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.readonly.set(
      !this.app.checkPermission('AuthProvider', 'All', PermissionType.Modify),
    );

    this.gridService.setOrder({ column: 'name', reverse: true });
    this.gridService.order$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((order: Order) => {
        this.sortingOrder = order;
        this.reload();
      });
    this.service.reloadTab$
      .pipe(takeUntil(this.destroySubject))
      .subscribe(() => {
        this.reload();
      });

    this.gridService.selectedGroup$
      .pipe(takeUntil(this.destroySubject))
      .subscribe((group: UntypedFormGroup) => {
        if (!group) {
          this.isSelectedProviderEditable.set(false);
          return;
        }
        const providerType = group.getRawValue().type;
        if (providerType === AuthProviderType.AzureAd) {
          this.isSelectedProviderEditable.set(false);
          return;
        }
        this.isSelectedProviderEditable.set(true);
      });
    this.load();
  }

  /** Opens a modal to create a new authentication provider. */
  public create(providerType: AuthProviderType): void {
    const ref = this.ngbModal.open(SettingsAuthCreateProviderModalComponent, {
      size: 'md',
    });

    const instance =
      ref.componentInstance as SettingsAuthCreateProviderModalComponent;
    instance.providerType = providerType;

    ref.closed.subscribe(() => {
      this.reload();
    });
  }

  /**
   * Edits an authentication provider based on the provided form group.
   *
   * @param formGroup The form group containing the provider's details.
   */
  public edit(
    formGroup: FormGroup = this.gridService.selectedGroup$.getValue(),
  ): void {
    if (!formGroup) {
      return;
    }

    const ref = this.ngbModal.open(SettingsAuthCreateProviderModalComponent, {
      size: 'md',
    });
    const instance =
      ref.componentInstance as SettingsAuthCreateProviderModalComponent;
    instance.providerToEdit = formGroup.getRawValue();

    ref.closed.subscribe(() => {
      this.reload();
    });
  }

  /**
   * Deletes an authentication provider based on the provided form group.
   *
   * @param formGroup The form group containing the provider's details.
   */
  private delete(formGroup: FormGroup): void {
    this.message
      .confirmLocal('settings.settings.authProviders.messages.deletionConfirm')
      .then(
        () => {
          this.data
            .collection('AuthProviders')
            .entity(formGroup.value.id)
            .delete()
            .subscribe({
              next: () => {
                this.reload();
                this.notification.successLocal('shared.messages.deleted');
              },
              error: (error: Exception) =>
                this.notification.error(error.message),
            });
        },
        () => null,
      );
  }

  /** Reloads the component's data. */
  private reload(): void {
    this.providersArray.clear();
    this.gridService.selectGroup(null);
    this.load();
    this.cdr.detectChanges();
  }

  /** Loads the component's data. */
  private load(): void {
    this.isReadingAllowed.set(
      this.app.checkPermission('AuthProvider', 'All', PermissionType.Read),
    );
    this.fillForm();
  }

  /** Fills the form with data from the AuthProviders collection. */
  private fillForm(): void {
    const providers = this.providersArray as FormArray;
    let query = null;
    if (this.sortingOrder.column) {
      query = {
        orderBy: `${this.sortingOrder.column} ${
          this.sortingOrder.reverse ? 'desc' : 'asc'
        }`,
      };
    }
    this.isLoadingSubject.next(true);
    this.data
      .collection('AuthProviders')
      .query<AuthProvider[]>(query)
      .subscribe((authProviders) => {
        authProviders.forEach((authProvider) => {
          const openIdConfig = JSON.parse(
            authProvider.openIdConfigurationJsonData,
          );
          const providerGroup = this.formBuilder.group({
            id: authProvider.id,
            name: authProvider.name,
            type: authProvider.type,
            openIdConfiguration: this.formBuilder.group({
              clientSecret: openIdConfig?.clientSecret,
              authority: openIdConfig?.authority,
              clientId: openIdConfig?.clientId,
            }),
            localSetting: this.formBuilder.group({
              passwordUpdatePeriodDays:
                authProvider?.localSetting?.passwordUpdatePeriodDays,
              maxInvalidLoginAttempts:
                authProvider?.localSetting?.maxInvalidLoginAttempts,
              lockoutMinutes: authProvider?.localSetting?.lockoutMinutes,
              minPasswordLength: authProvider?.localSetting?.minPasswordLength,
              requireDigits: authProvider?.localSetting?.requireDigits,
              requireCapitalLetters:
                authProvider?.localSetting?.requireCapitalLetters,
              requireSpecSymbols:
                authProvider?.localSetting?.requireSpecSymbols,
              useOtp: authProvider?.localSetting?.useOtp,
              allowedGrantPassword:
                authProvider?.localSetting?.allowedGrantPassword,
            }),
          });
          providers.push(providerGroup);
        });
        this.isLoadingSubject.next(false);
      });
  }
}
