import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnDestroy,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';

import _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ResourceRequest } from 'src/app/shared/models/entities/resources/resource-request.model';

import { ResourceRequestService } from '../resource-request/resource-request.service';
import { ResourceRequestMode } from '../resource-request/resource-request.interface';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-resource-request-form-advanced',
  templateUrl: './resource-request-form-advanced.component.html',
  styleUrls: ['./resource-request-form-advanced.component.scss'],
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceRequestFormAdvancedComponent implements OnInit, OnDestroy {
  @Input() public mode: ResourceRequestMode = 'edit';

  public readonly = false;
  public formRequirements = this.fb.group({
    preferredResources: this.fb.array([]),
  });
  public request: ResourceRequest;

  private readonly destroyed$ = new Subject<void>();

  constructor(
    private resourceRequestService: ResourceRequestService,
    private savingQueueService: SavingQueueService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.resourceRequestService.request$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((request: ResourceRequest) => {
        this.request = request;
        this.readonly = this.resourceRequestService.readonly;

        request.preferredResources?.forEach((pr) => {
          const group = this.fb.group({
            ...pr,
          });

          (
            this.formRequirements.controls
              .preferredResources as UntypedFormArray
          ).push(group, {
            emitEvent: false,
          });
        });

        this.toggleFormEnable();
        this.cdr.markForCheck();
      });

    this.formRequirements.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.resourceRequestService.onChange(value, this.mode);
        this.cdr.markForCheck();
      });
  }

  public ngOnDestroy(): void {
    this.savingQueueService.save();
    this.destroyed$.next();
  }

  private toggleFormEnable(): void {
    if (this.readonly) {
      this.formRequirements.disable({ emitEvent: false });
    } else {
      this.formRequirements.enable({ emitEvent: false });
    }
  }
}
