import { Injectable, Type } from '@angular/core';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { ClientFilterComponent } from './client-filter.component';

@Injectable()
export class ClientFilterService extends FilterService {
  public override component: Type<FilterDetails> = ClientFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = true;
  public override placeholder = 'projects.clients.list.filter.placeholder';
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
  };

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      const textFilters = this.getTextFilter(
        [
          'organization/name',
          'organization/code',
          'organization/site',
          'organization/legal/name',
        ],
        text,
      );
      textFilters.or['organization/legal/taxNumber'] = parseInt(text);
      textFilters.or['organization/legal/registrationNumber'] = parseInt(text);

      result.push(textFilters);
    }

    if (this.values.manager) {
      result.push({
        organization: {
          managerId: { type: 'guid', value: this.values.manager.id },
        },
      });
    }

    if (
      this.values.view &&
      this.values.view.code === 'active' &&
      this.hasViewSelector
    ) {
      result.push({ isActive: true });
    }

    return result;
  }

  public override getDatePeriodUrlParams(): Dictionary<string> {
    return this.dateService.getDatePairFromPeriodForFilter(this.values.period);
  }

  protected override getDefaultValues(): any {
    return {
      text: '',
      period: null,
      manager: null,
      view: this.views[0],
    };
  }
}
