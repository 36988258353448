import { ResolvedPos } from 'prosemirror-model';
import { NodeSelection, Selection, TextSelection } from 'prosemirror-state';

NodeSelection.prototype['selectionName'] = 'NodeSelection';
TextSelection.prototype['selectionName'] = 'TextSelection';

/**
 * Checks if state selection is text type.
 *
 * @param selection current selection.
 * @returns boolean value.
 */
export const isTextSelection = (
  selection: Selection,
): selection is TextSelection =>
  selection['selectionName'] === TextSelection.prototype['selectionName'];

/**
 * Checks if state selection is node type.
 *
 * @param selection current selection.
 * @returns boolean value.
 */
export const isNodeSelection = (
  selection: Selection,
): selection is NodeSelection =>
  selection['selectionName'] === NodeSelection.prototype['selectionName'];

/**
 * Gets cursor position.
 * @param selection editor view state current selection.
 * @returns cursor position or null.
 */
export const get$Cursor = (selection: Selection): ResolvedPos | null =>
  isTextSelection(selection) ? selection.$cursor : null;

/**
 * Gets cursor position at current selection start.
 * @param selection editor view state current selection.
 * @returns cursor position or null.
 */
export function get$CursorAtBlockStart(
  selection: Selection,
): ResolvedPos | null {
  const $cursor = get$Cursor(selection);
  if (!$cursor || $cursor.parentOffset > 0 || $cursor.parent.isInline)
    return null;
  return $cursor;
}
