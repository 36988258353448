import { Component, Input, OnInit } from '@angular/core';
import { GridNavigationColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { NavigationService } from 'src/app/core/navigation.service';
import { UIRouterGlobals } from '@uirouter/core';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import _ from 'lodash';

@Component({
  selector: 'tmt-navigation-cell',
  templateUrl: './navigation-cell.component.html',
  styleUrls: ['./navigation-cell.component.scss'],
  standalone: false,
})
export class NavigationCellComponent implements OnInit {
  @Input() row: any;
  @Input() column: GridNavigationColumn;

  public uiParams: any;
  public displayName: string;

  constructor(
    private navigationService: NavigationService,
    private uiRouterGlobals: UIRouterGlobals,
  ) {}

  public ngOnInit(): void {
    this.uiParams = {};
    if (this.column.stateParams) {
      Object.keys(this.column.stateParams).forEach((key) => {
        if (key !== 'fromRow') {
          this.uiParams[key] = this.column.stateParams[key];
        }
      });
      if (this.column.stateParams.fromRow) {
        const fromRow = this.column.stateParams.fromRow;
        Object.keys(fromRow).forEach((key) => {
          this.uiParams[key] = _.get(this.row, fromRow[key]);
        });
      }
    } else {
      this.uiParams = {
        entityId: this.row.id,
      };
    }

    this.uiParams['routeMode'] = RouteMode.continue;

    if (!this.uiParams.navigation) {
      this.uiParams.navigation = this.uiRouterGlobals.params?.navigation;
    }

    if (!this.uiParams.route && this.navigationService.storedRoute.length) {
      this.uiParams.route = _.cloneDeep(this.navigationService.storedRoute);
    }

    const columnValue: any = this.row[this.column.name];
    this.displayName = _.isObject(columnValue)
      ? (columnValue as any).name
      : columnValue;
  }
}
