import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Subscription } from 'rxjs';
import { ProjectRevenueModalComponent } from '../../../project-revenue-estimates/shared/project-revenue-modal/project-revenue-modal.component';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  ProjectRbcCalendarSlotInfoService,
  SlotInfo,
} from '../../core/project-rbc-calendar-slot-info.service';
import { RbcSectionType } from '../../../models/rbc-view.model';
import { ProjectBillingModalComponent } from '../../../project-billing-estimates/shared/project-billing-modal/project-billing-modal.component';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ProjectCardService } from '../../../../core/project-card.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { ProjectBillingEstimate } from 'src/app/shared/models/entities/projects/project-billing-estimate.model';
import { ProjectRevenueEstimate } from 'src/app/shared/models/entities/projects/project-revenue-estimate.model';

@Component({
  selector: 'wp-project-rbc-calendar-slot-info',
  templateUrl: './project-rbc-calendar-slot-info.component.html',
  styleUrls: ['./project-rbc-calendar-slot-info.component.scss'],
  standalone: false,
})
export class ProjectRbcCalendarSlotInfoComponent implements OnInit, OnDestroy {
  @Input() params: any;

  private subscriptions: Subscription[] = [];
  public slotInfo: SlotInfo[] = [];

  public isShown: boolean;
  public isLoading$ = new BehaviorSubject<boolean>(true);

  private project: Project;
  private projectVersion: ProjectVersion;
  private defaultTask: ProjectTask;

  public get projectCurrencyCode() {
    return this.project.currency.alpha3Code;
  }

  /**
   * Checks if Slot creation is allowed on popup or not.
   *
   * @returns `true` if creation is allowed, `false` otherwise.
   * */
  public get createAllowed(): boolean {
    if (this.params.isAutomatic === true) {
      return false;
    }
    return this.editAllowed();
  }

  constructor(
    private service: ProjectRbcCalendarSlotInfoService,
    private projectTasksService: ProjectTasksService,
    private projectCardService: ProjectCardService,
    private changeDetector: ChangeDetectorRef,
    private modal: NgbModal,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.projectCardService.project$.subscribe((proj) => {
        this.project = proj;
      }),
    );

    this.open();
  }
  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe);
  }

  /**
   * Checks if Slot edit is allowed on popup or not.
   *
   * @param slot Slot to check. If not passed, general access rights will be checked.
   * @returns `true<` if edit is allowed, `false` otherwise.
   * */
  public editAllowed(slot?: SlotInfo): boolean {
    if (slot?.isAutomatic === true) {
      return false;
    }
    if (!this.project) {
      return true;
    }
    let editAllowed: boolean;
    switch (this.params.sectionType) {
      case RbcSectionType.revenue:
        editAllowed = this.project.revenueEstimateEditAllowed;
        break;
      case RbcSectionType.billing:
        editAllowed = this.project.billingEstimateEditAllowed;
        break;
      case RbcSectionType.collection:
        editAllowed = this.project.billingEstimateEditAllowed;
        break;
    }

    return editAllowed && this.projectVersion.editAllowed;
  }

  /* Открывает окно с загруженными данными */
  public open(): void {
    this.service.sectionType = this.params.sectionType;
    this.service.projectId = this.params.projectId;
    this.projectVersion = this.service.versionCardService.projectVersion;

    this.setDefaultTask();

    this.isShown = true;
    this.changeDetector.detectChanges();

    this.service
      .load(this.params.projectTaskId, this.params.dateFrom, this.params.dateTo)
      .then(
        (loaded) => {
          this.slotInfo = loaded;
          this.isLoading$.next(false);
          this.changeDetector.detectChanges();
          this.infoPopupService.update();
        },
        () => null,
      );
  }

  /* Открывает модальное окно и сохраняет заполненные данные */
  public onCreate(): void {
    this.isShown = false;
    this.infoPopupService.close();
    this.openModal().then(
      (filled) => {
        filled.id = Guid.generate();
        ProjectVersionUtil.setEntityRootPropertyId(
          this.projectVersion,
          filled,
          this.params.projectId,
        );
        this.slotInfo.push(filled as SlotInfo);

        const data = {
          id: filled.id,
          projectTaskId: filled.projectTask.id,
          date: filled.date,
          billingDate: filled.billingDate,
          collectionDate: filled.collectionDate,
          amount: filled.amount,
          description: filled.description,
        };
        ProjectVersionUtil.setEntityRootPropertyId(
          this.projectVersion,
          data,
          this.params.projectId,
        );
        this.service.insert(data).then(
          () => this.infoPopupService.close(),
          () => null,
        );
      },
      () => null,
    );
  }

  /* Открывает модальное окно и сохраняет заполненные данные */
  public onEdit(info: SlotInfo): void {
    this.isShown = false;
    this.infoPopupService.close();
    this.openModal(info).then(
      (filled) => {
        const index = this.slotInfo.findIndex((i) => i.id === filled.id);
        this.slotInfo[index] = filled as SlotInfo;

        const data = {
          id: filled.id,
          projectTaskId: filled.projectTask.id,
          date: filled.date,
          billingDate: filled.billingDate,
          collectionDate: filled.collectionDate,
          amount: filled.amount,
          description: filled.description,
        };
        if (filled.isAutomatic) {
          data['isAutomatic'] = filled.isAutomatic;
        }
        ProjectVersionUtil.setEntityRootPropertyId(
          this.projectVersion,
          data,
          this.params.projectId,
        );

        this.service.update(data).then(
          () => this.infoPopupService.close(),
          () => null,
        );
      },
      () => null,
    );
  }

  /* Удаляет сущность из памяти и сервера */
  public onRemove(info: SlotInfo): void {
    if (!this.editAllowed(info)) {
      return;
    }
    this.slotInfo = this.slotInfo.filter((i) => i.id !== info.id);
    this.service.delete(info.id).then(
      () => this.infoPopupService.close(),
      () => null,
    );
  }

  private openModal(info?: SlotInfo): Promise<any> {
    const ref = (() => {
      switch (this.params.sectionType) {
        case RbcSectionType.revenue:
          return this.modal.open(ProjectRevenueModalComponent);
        case RbcSectionType.billing:
        case RbcSectionType.collection:
          return this.modal.open(ProjectBillingModalComponent, {
            injector: this.injector,
          });
      }
    })();
    const instance = ref.componentInstance;
    instance.projectId = this.params.projectId;
    instance.projectVersion = this.projectVersion;
    instance.projectCurrencyCode = this.projectCurrencyCode;
    instance.readonly = !this.editAllowed(info);

    if (info) {
      instance.entry = info as ProjectBillingEstimate | ProjectRevenueEstimate;
      return ref.result;
    }
    instance.entry = {
      date: this.params.dateTo,
      billingDate: this.params.dateTo,
      collectionDate: this.params.dateTo,
      projectTask: this.defaultTask as NamedEntity,
      isAutomatic: this.params.isAutomatic,
    };
    return ref.result;
  }

  private setDefaultTask() {
    this.projectTasksService
      .getProjectTasks(this.params.projectId, this.projectVersion)
      .subscribe((tasks) => {
        const currTask = tasks.find((t) => t.id === this.params.projectTaskId);
        const leadTask = tasks.find((t) => !t.leadTaskId);
        this.defaultTask = currTask ?? leadTask;
      });
  }
}
