import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  Input,
} from '@angular/core';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { VisibleParts } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-parts.enum';
import { TASKS_VIEW } from 'src/app/projects/card/project-tasks/shared/models/project-task-constants';
import { ProjectTaskView } from 'src/app/projects/card/project-tasks/shared/models/project-task-view.enum';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { TasksGridActionsService } from 'src/app/projects/card/project-tasks/core/tasks-grid-actions.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge } from 'rxjs';

@Component({
  selector: 'wp-tasks-toolbar',
  templateUrl: './tasks-toolbar.component.html',
  styleUrls: ['./tasks-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TasksToolbarComponent extends AbstractToolbar {
  @Input() project: Project;

  public createCanBeExecuted = false;
  public editCanBeExecuted = false;
  public deleteCanBeExecuted = false;
  public increaseLevelCanBeExecuted = false;
  public decreaseLevelCanBeExecuted = false;
  public upCanBeExecuted = false;
  public downCanBeExecuted = false;

  public readonly planningScale = PlanningScale;
  public visibleParts = [
    VisibleParts.calendar,
    VisibleParts.planningScaleSelector,
  ];

  constructor(
    @Inject(TASKS_VIEW) public tasksView: ProjectTaskView,
    gridService: GridService,
    cdr: ChangeDetectorRef,
    public taskActionsService: TasksGridActionsService,
    public projectTasksDataService: ProjectTasksDataService,
  ) {
    super(gridService, cdr);
  }

  public override ngOnInit(): void {
    super.ngOnInit();

    merge(this.gridService.selectedGroup$, this.gridService.detectChanges$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const selectedGroup = this.gridService.selectedGroup$.getValue();
        if (!selectedGroup) {
          this.createCanBeExecuted = this.gridService.canBeExecuted(
            'create',
            selectedGroup?.value.id,
          );
          this.editCanBeExecuted = false;
          this.deleteCanBeExecuted = false;
          this.increaseLevelCanBeExecuted = false;
          this.decreaseLevelCanBeExecuted = false;
          this.upCanBeExecuted = false;
          this.downCanBeExecuted = false;
        } else {
          this.createCanBeExecuted = this.gridService.canBeExecuted(
            'create',
            selectedGroup.value.id,
          );
          this.editCanBeExecuted = this.gridService.canBeExecuted(
            'edit',
            selectedGroup.value.id,
          );
          this.deleteCanBeExecuted = this.gridService.canBeExecuted(
            'delete',
            selectedGroup.value.id,
          );
          this.increaseLevelCanBeExecuted = this.gridService.canBeExecuted(
            'increaseLevel',
            selectedGroup.value.id,
          );
          this.decreaseLevelCanBeExecuted = this.gridService.canBeExecuted(
            'decreaseLevel',
            selectedGroup.value.id,
          );
          this.upCanBeExecuted = this.gridService.canBeExecuted(
            'up',
            selectedGroup.value.id,
          );
          this.downCanBeExecuted = this.gridService.canBeExecuted(
            'down',
            selectedGroup.value.id,
          );
        }
        this.cdr.detectChanges();
      });
  }
}
