<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'shared2.filters.period.value' | translate }}</label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{ 'shared2.filters.period.ph' | translate }}"
        >
        </wp-date-period-box>
      </div>

      <div class="form-group">
        <label>{{
          'components.interactionsListFilterComponent.filters.performer.value' | translate
        }}</label>
        <wp-user-box
          formControlName="performer"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{
            'components.interactionsListFilterComponent.filters.performer.ph' | translate
          }}"
        >
        </wp-user-box>
      </div>

      <div class="form-group">
        <label>{{
          'components.interactionsListFilterComponent.filters.client.value' | translate
        }}</label>
        <wp-select-box
          formControlName="organization"
          collection="Organizations"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{
            'components.interactionsListFilterComponent.filters.client.ph' | translate
          }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group">
        <label>{{
          'components.interactionsListFilterComponent.filters.deal.value' | translate
        }}</label>
        <wp-select-box
          formControlName="deal"
          collection="Deals"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{
            'components.interactionsListFilterComponent.filters.deal.ph' | translate
          }}"
        >
        </wp-select-box>
      </div>

      <div class="form-group">
        <label>{{
          'components.interactionsListFilterComponent.filters.interactionContact.value' | translate
        }}</label>
        <wp-select-box
          formControlName="interactionContact"
          collection="Contacts"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{
            'components.interactionsListFilterComponent.filters.interactionContact.ph' | translate
          }}"
        >
        </wp-select-box>
      </div>
    </div>
  </div>
</form>
