import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { UserFilterService } from 'src/app/settings-app/users/list/user-filter/user-filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { USER_LIST } from 'src/app/shared/lists/user.list';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, MULTI_SELECT_LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { PermissionType } from '../../../../shared/models/inner/permission-type.enum';
import { ActionPanelService } from '../../../../core/action-panel.service';
import { AppService } from '../../../../core/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddUserToGroupModalComponent } from './add-user-to-group-modal/add-user-to-group-modal.component';
import { User } from '../../../../shared/models/entities/settings/user.model';
import { DataService } from '../../../../core/data.service';
import { Subject, firstValueFrom } from 'rxjs';
import { switchMap, takeUntil } from 'rxjs/operators';
import { UserGroup } from '../../../../shared/models/entities/settings/user-group.model';
import { MessageService } from '../../../../core/message.service';
import { MassOperationHelper } from 'src/app/shared/helpers/mass-operation.helper';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-group-users',
  templateUrl: './group-users.component.html',
  styleUrls: ['./group-users.component.scss'],
  providers: [
    { provide: VIEW_NAME, useValue: 'inGroup' },
    { provide: LIST, useValue: USER_LIST },
    { provide: FilterService, useClass: UserFilterService },
    { provide: MULTI_SELECT_LIST, useValue: true },
    EntityListService,
    ListService,
    GridService,
  ],
  standalone: false,
})
export class GroupUsersComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    @Inject('entityId') private entityId: string,
    public entityListService: EntityListService,
    private actions: ActionPanelService,
    private app: AppService,
    private modalService: NgbModal,
    private dataService: DataService,
    private gridService: GridService,
    private messageService: MessageService,
    private filterService: FilterService,
    private blockUI: BlockUIService,
  ) {
    entityListService.contextFilter = [
      {
        userGroups: {
          any: {
            groupId: { type: 'guid', value: this.entityId },
          },
        },
      },
    ];
  }

  public ngOnInit(): void {
    this.actions.set([
      {
        title: 'settings.users.list.actions.add',
        hint: 'settings.users.list.actions.add',
        name: 'create',
        isDropDown: false,
        iconClass: 'bi bi-plus-lg bi-15',
        isBusy: false,
        isVisible: this.app.checkEntityPermission(
          'UserGroup',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      },
      {
        title: 'shared.actions.exclude',
        hint: 'shared.actions.exclude',
        name: 'exclude',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.exclude(),
      },
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((groups) => {
        this.actions.action('exclude').isShown =
          groups?.length > 0 &&
          this.app.checkEntityPermission('UserGroup', PermissionType.Modify);
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private create(): void {
    const modalRef = this.modalService.open(AddUserToGroupModalComponent, {
      size: 'lg',
    });
    const instance = modalRef.componentInstance as AddUserToGroupModalComponent;

    instance.entityId = this.entityId;
    instance.alreadyAddedIds = this.entityListService.formArray.value.map(
      (u: User) => u.id,
    );

    modalRef.result.then(
      (users: User[]) => {
        this.blockUI.start();

        const massOperation = new MassOperationHelper(
          users.map((user) =>
            this.dataService
              .collection('UserGroups')
              .insert({ groupId: this.entityId, userId: user.id }),
          ),
          { takeUntil: takeUntil(this.destroyed$) },
        )
          .start()
          .pipe(takeUntil(this.destroyed$))
          .subscribe((progress) => {
            if (progress === 100) {
              this.entityListService.reload();
              this.blockUI.stop();
              massOperation.unsubscribe();
            }
          });
      },
      () => null,
    );
  }

  private exclude(): void {
    this.messageService
      .confirmLocal('shared.excludeConfirmation')
      .then(async () => {
        this.blockUI.start();

        let selectedRows = this.gridService.selectedGroupsValue;

        if (this.gridService.isAllSelected) {
          selectedRows = await firstValueFrom(
            this.dataService.collection('Users').query<any[]>({
              select: ['id'],
              filter: [
                ...this.filterService.getODataFilter(),
                ...this.entityListService.contextFilter,
              ],
            }),
          );
        }

        const massOperation = new MassOperationHelper(
          selectedRows.map((user) =>
            this.dataService
              .collection('UserGroups')
              .query({
                filter: {
                  userId: {
                    type: 'guid',
                    value: user.id,
                  },
                  groupId: {
                    type: 'guid',
                    value: this.entityId,
                  },
                },
              })
              .pipe(
                switchMap((userGroups: UserGroup[]) =>
                  this.dataService
                    .collection('UserGroups')
                    .entity(userGroups[0].id)
                    .delete(),
                ),
              ),
          ),
          {
            takeUntil: takeUntil(this.destroyed$),
            delay: selectedRows.length > 100 ? 100 : 0,
          },
        )
          .start()
          .pipe(takeUntil(this.destroyed$))
          .subscribe((progress) => {
            if (progress === 100) {
              this.entityListService.reload();
              this.blockUI.stop();
              massOperation.unsubscribe();
            }
          });
      });
  }
}
