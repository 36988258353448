import {
  AfterViewInit,
  ChangeDetectorRef,
  Directive,
  ElementRef,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { throttle } from 'lodash';

/** Directive for text truncation depending on element width. */
@Directive({
  selector: '[tmtTruncateDirective]',
  standalone: false,
})
export class TruncateDirective implements AfterViewInit, OnDestroy {
  private containerWidthWithoutGaps = 0;
  private elementsWidth: number[] = [];
  private elementsWidthInitial: number[] = [];
  private throttleTime = 100; // ContainerSizeCheckThrottleTime
  private mutationObserver: MutationObserver;
  private resizeObserver: ResizeObserver;
  constructor(
    private renderer: Renderer2,
    private elementRef: ElementRef,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit(): void {
    this.initWidths();

    this.resizeObserver = new ResizeObserver(
      throttle(() => {
        this.calculateWidth();
      }, this.throttleTime),
    );

    this.resizeObserver.observe(this.elementRef.nativeElement);

    this.mutationObserver = new MutationObserver(() => {
      this.initWidths();
      this.calculateWidth();
    });
    this.mutationObserver.observe(this.elementRef.nativeElement, {
      attributes: true,
    });
  }

  public ngOnDestroy(): void {
    this.resizeObserver.disconnect();
    this.mutationObserver.disconnect();
  }

  private calculateWidth(): void {
    const elementsArray = Array.from(this.elementRef.nativeElement.children);
    const htmlElements: HTMLElement[] = elementsArray as HTMLElement[];

    let elementsGap = 0;
    if (elementsArray.length === 1) {
      elementsGap = 0;
    } else {
      elementsGap =
        htmlElements[1].offsetLeft -
        htmlElements[0].offsetWidth -
        htmlElements[0].offsetLeft;
    }

    this.containerWidthWithoutGaps =
      this.elementRef.nativeElement.offsetWidth -
      elementsGap * (elementsArray.length - 1);

    let elementsWidthSum = 0;
    this.elementsWidth.forEach((el) => (elementsWidthSum += el));
    if (elementsWidthSum > this.containerWidthWithoutGaps) {
      const delta = elementsWidthSum - this.containerWidthWithoutGaps;

      for (let i = 0; i < delta; i++) {
        const maxElementWidth = Math.max(...this.elementsWidth);
        const maxElementWidthIndex = this.elementsWidth.findIndex(
          (el) => el === maxElementWidth,
        );
        this.elementsWidth[maxElementWidthIndex] = maxElementWidth - 1;
      }
      this.elementsWidth.forEach((elementWidth, index) => {
        this.renderer.setStyle(
          elementsArray[index],
          'width',
          elementWidth + 'px',
        );
      });

      this.cdr.detectChanges();
    } else {
      const delta = this.containerWidthWithoutGaps - elementsWidthSum;
      const truncatedElementsIndexes = [];
      this.elementsWidthInitial.forEach((el, index) => {
        if (el !== this.elementsWidth[index]) {
          truncatedElementsIndexes.push(index);
        }
      });

      truncatedElementsIndexes.forEach((index) => {
        if (this.elementsWidth[index] < this.elementsWidthInitial[index]) {
          let newWidth =
            this.elementsWidth[index] + delta / truncatedElementsIndexes.length;
          if (newWidth > this.elementsWidthInitial[index]) {
            newWidth = this.elementsWidthInitial[index];
          }
          this.elementsWidth[index] = newWidth;
          this.renderer.setStyle(
            elementsArray[index],
            'width',
            newWidth + 'px',
          );
        }
      });
    }
  }

  private initWidths(): void {
    this.elementsWidthInitial.length = 0;
    this.elementsWidth.length = 0;
    const childrenElements = [...this.elementRef.nativeElement.children];
    childrenElements.forEach((el) => {
      this.renderer.removeStyle(el, 'width');
    });

    this.cdr.detectChanges();

    childrenElements.forEach((el) => {
      const offsetWidth = el.offsetWidth + 1;
      this.elementsWidthInitial.push(offsetWidth);
      this.elementsWidth.push(offsetWidth);
    });
  }
}
