import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  Injector,
  input,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';

import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import _ from 'lodash';

import { NavigationService } from 'src/app/core/navigation.service';

import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import {
  TimeAllocation,
  TimesheetLine,
} from 'src/app/shared/models/entities/base/timesheet.model';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

import { WorkLogService } from 'src/app/work-log/work-log.service';
import { WorkLogCardComponent } from 'src/app/work-log/card/work-log-card.component';
import { TimesheetCardService } from 'src/app/timesheets/card/core/timesheet-card.service';

@Component({
  selector: 'tmt-allocation-issue-info',
  templateUrl: './allocation-issue-info.component.html',
  styleUrl: './allocation-issue-info.component.scss',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AllocationIssueInfoComponent {
  @HostBinding('id') public id = this.cellsOrchestratorService.infoBlockId;

  public allocation = input.required<TimeAllocation>();
  public line = input.required<TimesheetLine>();

  public issuesForm: UntypedFormArray;
  public issueTimeAllocations: TimeAllocation[] = [];
  public readonly routeMode = RouteMode;

  constructor(
    public navigationService: NavigationService,
    public workLogService: WorkLogService,
    public modal: NgbModal,
    private timesheetCardService: TimesheetCardService,
    private infoPopupService: InfoPopupService,
    private cellsOrchestratorService: CellsOrchestratorService,
    private customFieldService: CustomFieldService,
    private fb: UntypedFormBuilder,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    this.issuesForm = this.fb.array([]);
    this.issueTimeAllocations = this.line().allTimeAllocations.filter(
      (v) => v.date === this.allocation().date,
    );

    this.issueTimeAllocations.forEach((entry) => {
      const group = this.fb.group({});
      this.customFieldService.enrichFormGroup(group, 'TimeAllocation');
      group.patchValue(entry);
      this.issuesForm.push(group);
    });

    this.issuesForm.disable();
  }

  /**
   *  Opens the modal to edit the time entry.
   *
   * @param timeEntry the time allocation to edit.
   */
  public async openTimeEntry(
    timeEntry: Partial<TimeAllocation>,
  ): Promise<void> {
    this.cellsOrchestratorService.reset();
    this.infoPopupService.close();

    const modalRef = this.modal.open(WorkLogCardComponent, {
      injector: this.injector,
    });
    const workLogCardComponent: WorkLogCardComponent =
      modalRef.componentInstance;

    workLogCardComponent.timeEntry.set(_.cloneDeep(timeEntry));
    workLogCardComponent.issueNameIsLink.set(true);
    workLogCardComponent.readonly.set(
      !this.timesheetCardService.timesheet.editAllowed,
    );

    try {
      const result: TimeAllocation | null = await modalRef.result;

      this.timesheetCardService.updateIssueAllocation(
        result || timeEntry,
        !result,
      );
    } catch {
      return;
    }
  }
}
