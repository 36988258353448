import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { sumBy } from 'lodash';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { ProjectResourcesHelper } from 'src/app/projects/card/project-resources/core/project-resources.helper';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import { ResourcePlanSlot } from 'src/app/projects/card/project-resources/models/forecast-slot';
import { ResourceViewGroup } from 'src/app/projects/card/project-resources/models/project-resources-view.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtProjectResourcesCalendarRightGroup]',
  templateUrl: './project-resources-calendar-right-group.component.html',
  styleUrls: ['./project-resources-calendar-right-group.component.scss'],
  providers: [CellsOrchestratorService],
  standalone: false,
})
export class ProjectResourcesCalendarRightGroupComponent
  implements OnInit, OnDestroy
{
  @Input() group: ResourceViewGroup;
  @Input() valueMode: ValueMode;
  @Input() scale: PlanningScale;
  @Input() isShowTaskDuration: boolean;

  // For forecast logic
  @Input() currentPeriodSlot: ResourcePlanSlot | null = null;
  @Input() static = false;
  @Input() empty = false;

  public formLines: UntypedFormArray = this.fb.array([]);

  public readonly propagationMode = PropagationMode;

  private wasBuilt = false;

  private destroyed$ = new Subject<void>();

  constructor(
    public service: ProjectResourceService,
    public projectCardService: ProjectCardService,
    public dataService: ProjectResourceDataService,
    private cellsOrchestrator: CellsOrchestratorService,
    private fb: UntypedFormBuilder,
    private changeDetector: ChangeDetectorRef,
    private cellsOrchestratorService: CellsOrchestratorService,
  ) {}

  ngOnInit(): void {
    this.service.toggle$
      .pipe(
        takeUntil(this.destroyed$),
        filter((id) => id === this.group.id),
      )
      .subscribe(() => this.recalculateEntries());

    this.service.changes$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.recalculateEntries();
      this.recalculateTotals();
    });

    this.cellsOrchestratorService.massValueChangeInProgress$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((inProgress: boolean) => {
        if (inProgress) {
          this.dataService.isAddToSavePrevented = inProgress;
          return;
        }
        if (!inProgress && this.dataService.isAddToSavePrevented) {
          this.dataService.saveCurrentEntriesToSave();
        }
      });

    this.recalculateEntries();
    this.recalculateTotals();
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public isToday(date: string): boolean {
    return this.currentPeriodSlot?.date?.toISODate() === date;
  }

  /** Рассчитывает итоги по строкам и слотам.  */
  private recalculateTotals() {
    if (this.static) {
      return;
    }
    this.group.totals.forEach((t) => {
      t.hours = 0;
      t.cost = 0;
    });

    this.group.lines.forEach((line) => {
      line.totalHours = line.extraTotal;
      for (let entryIndex = 0; entryIndex < line.entries.length; entryIndex++) {
        const entry = line.entries[entryIndex];
        if (entry.hours > 0) {
          this.group.totals[entryIndex].hours += entry.hours;
          this.group.totals[entryIndex].cost += entry.cost;
          line.totalHours += entry.hours;
        }
      }
    });

    this.group.totalHours = sumBy(this.group.lines, 'totalHours');
    this.group.totalCost = sumBy(this.group.lines, 'totalCost');
    this.changeDetector.detectChanges();
  }

  private recalculateEntries() {
    if (!this.group.isExpanded || this.static) {
      return;
    }

    if (!this.wasBuilt) {
      this.wasBuilt = true;
      this.rebuildLines();
    }
    if (this.wasBuilt) {
      this.updateLines();
    }
    this.recalculateTotals();
  }

  private rebuildLines() {
    this.formLines = this.fb.array([]);
    for (const line of this.group.lines) {
      const array = this.fb.array([]);
      this.formLines.push(array);

      for (const entry of line.entries) {
        const control = this.fb.control(entry);
        if (this.projectCardService.isEntryBlocked(entry)) {
          control.disable({ emitEvent: false });
        }
        array.push(control);
        control.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
          this.dataService.addEntryToQueue(
            control.value,
            line,
            this.group,
            this.scale,
          );
        });
      }
      if (this.projectCardService.isLineBlocked(line)) {
        array.disable({ emitEvent: false });
      }
    }
    this.cellsOrchestrator.init();

    this.formLines.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.recalculateTotals();
        this.service.updateSlotTotals();
      });

    if (!this.group.isEditable || this.dataService.readonly) {
      this.formLines.disable({ emitEvent: false });
    }

    this.changeDetector.detectChanges();
  }

  private updateLines() {
    this.group.lines.forEach((line, lineIndex) => {
      let formLine = this.formLines.at(lineIndex) as UntypedFormArray;
      if (!formLine) {
        this.wasBuilt = false;
        this.rebuildLines();
        formLine = this.formLines.at(lineIndex) as UntypedFormArray;
      }
      const isLineBlocked = this.projectCardService.isLineBlocked(line);
      if (isLineBlocked) {
        formLine.disable({ emitEvent: false });
      } else {
        formLine.enable({ emitEvent: false });
      }

      // Cut unused cells
      if (formLine.controls.length > line.entries.length) {
        formLine.controls.length = line.entries.length;
      }

      line.entries.forEach((entry, entryIndex) => {
        entry.taskDurationPercent =
          ProjectResourcesHelper.getTaskDurationPercent(
            this.scale,
            entry.date,
            entry.taskStartDate,
            entry.taskEndDate,
          );

        entry.backgroundStyle = ProjectResourcesHelper.getBackgroundStyle(
          entry.taskDurationPercent,
        );

        const formEntry = formLine.at(entryIndex);
        if (formEntry) {
          formEntry.setValue(entry, { emitEvent: false });
          if (!isLineBlocked) {
            if (this.projectCardService.isEntryBlocked(entry)) {
              formEntry.disable({ emitEvent: false });
            } else {
              formEntry.enable({ emitEvent: false });
            }
          }
        } else {
          const control = this.fb.control(entry);
          if (this.projectCardService.isEntryBlocked(entry)) {
            control.disable({ emitEvent: false });
          }
          formLine.push(control, { emitEvent: false });
          control.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => {
              this.dataService.addEntryToQueue(
                control.value,
                line,
                this.group,
                this.service.planningScale,
              );
            });
        }
      });
    });
  }
}
