import {
  ChangeDetectionStrategy,
  Component,
  HostBinding,
  inject,
  input,
  output,
  signal,
} from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';

import { TranslateService } from '@ngx-translate/core';

import {
  catchError,
  filter,
  firstValueFrom,
  map,
  merge,
  Observable,
  of,
  pairwise,
  switchMap,
  take,
} from 'rxjs';

import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { SharedModule } from 'src/app/shared/shared.module';
import { Exception } from 'src/app/shared/models/exception';
import { DirectoriesService } from 'src/app/shared/services/directories.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { EntityTypesService } from 'src/app/shared/services/entity-types.service';
import {
  MetaEntityDirectoryProperty,
  MetaEntityPropertyKind,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

import {
  FilterCondition,
  FilterOperator,
  MetaEntityData,
  OPERATOR_BY_META_ENTITY_TYPE,
} from '../filter-conditions-builder.model';
import _ from 'lodash';

@Component({
    selector: 'tmt-filter-condition',
    imports: [SharedModule],
    templateUrl: './filter-condition.component.html',
    styleUrl: './filter-condition.component.scss',
    host: {
        class: 'condition',
    },
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class FilterConditionComponent {
  @HostBinding('class.condition--nullable')
  protected get nullableOperator(): boolean {
    return ['IsNull', 'IsNotNull'].includes(this.operator());
  }

  public filterCondition = input.required<FilterCondition>();
  public metaEntityPropertyData = input.required<MetaEntityData>();
  public entityName = input.required<string>();
  public allowInactive = input.required<boolean>();

  public onRemoveClick = output<void>();
  public onConditionChange = output<FilterCondition>();

  public loading = signal<boolean>(false);
  public operators: FilterOperator[] = [];
  public operator = signal<FilterOperator>(null);
  public propertyControl = new FormControl(null);
  public propertyRangeForm = new FormGroup({
    left: new FormControl(),
    right: new FormControl(),
  });
  public collectionName?: string;
  public directoryId?: string;
  public parentIdKey?: string;
  public states$?: Observable<NamedEntity[]>;

  private readonly appService = inject(AppService);
  private readonly entityTypesService = inject(EntityTypesService);
  private readonly dataService = inject(DataService);
  private readonly directoriesService = inject(DirectoriesService);
  private readonly translateService = inject(TranslateService);
  private readonly notificationService = inject(NotificationService);

  constructor() {
    toObservable(this.operator)
      .pipe(
        filter((v) => !!v),
        pairwise(),
        takeUntilDestroyed(),
      )
      .subscribe(([prev, current]) => {
        if (current === 'IsNull' || current === 'IsNotNull') {
          this.propertyControl.setValue(null);
        } else if (
          (current === 'InPeriod' && prev !== 'InPeriod') ||
          (current !== 'InPeriod' && prev === 'InPeriod')
        ) {
          this.propertyControl.setValue(null);
        } else {
          this.onConditionChange.emit({
            operator: current,
            value: this.getPlainValue(this.propertyControl.value),
          });
        }
      });

    merge(
      this.propertyControl.valueChanges,
      this.propertyRangeForm.valueChanges,
    )
      .pipe(takeUntilDestroyed())
      .subscribe((value: any) => {
        this.onConditionChange.emit({
          operator: this.operator(),
          value: this.getPlainValue(value),
        });
      });
  }

  public async ngOnInit(): Promise<void> {
    const metaEntityPropertyData = this.metaEntityPropertyData();
    const metaEntity = this.appService.getMetaEntity(
      metaEntityPropertyData.clrType,
    );
    const { operator, value } = this.filterCondition();

    this.operator.set(operator);
    this.operators =
      OPERATOR_BY_META_ENTITY_TYPE[metaEntityPropertyData.type].slice(0);
    this.parentIdKey =
      metaEntity?.hierarchyProperty &&
      _.camelCase(`${metaEntity.hierarchyProperty}Id`);

    if (
      metaEntityPropertyData.type === MetaEntityPropertyType.boolean &&
      value === null
    ) {
      this.propertyControl.setValue(false);

      return;
    }

    if (operator === 'Between' && value) {
      const [left, right] = value;

      this.propertyRangeForm.patchValue(
        {
          left,
          right,
        },
        { emitEvent: false },
      );

      return;
      // TODO: add for Percent type
    }

    this.loading.set(true);

    let data$: Observable<NamedEntity | null> | null = of(value);

    if (metaEntityPropertyData.kind === MetaEntityPropertyKind.directory) {
      this.directoryId = (
        metaEntityPropertyData as MetaEntityDirectoryProperty
      ).directoryId;

      if (value) {
        data$ = this.directoriesService
          .getDirectoryEntries(this.directoryId)
          .pipe(
            take(1),
            map((entries: NamedEntity[]) => entries.pop() || null),
          );
      }
    }

    if (metaEntityPropertyData.kind === MetaEntityPropertyKind.navigation) {
      this.collectionName = await firstValueFrom(
        this.entityTypesService.getEntityTypeCollection(
          metaEntityPropertyData.clrType,
        ),
      );

      if (value) {
        data$ = this.dataService
          .collection(this.collectionName)
          .entity(value)
          .get();
      }

      if (metaEntityPropertyData.clrType === 'State') {
        data$ = this.entityTypesService
          .getEntityTypeCollection(this.entityName())
          .pipe(
            switchMap((collectionName) => {
              this.states$ = this.getStates(collectionName);
              return value ? this.states$ : of([]);
            }),
            map(
              (entries: NamedEntity[]) =>
                entries.find((state) => state.id === value) ?? null,
            ),
          );
      }
    }

    const result = await firstValueFrom(
      data$.pipe(
        catchError((error) => {
          if (error.code === Exception.BtEntityNotFoundException.code) {
            return of({
              id: value,
              name: this.translateService.instant(
                'shared.entityNotFound.title',
              ),
            });
          } else {
            this.notificationService.error(error.message);
            return of(null);
          }
        }),
      ),
    );

    this.propertyControl.setValue(result, { emitEvent: false });
    this.loading.set(false);
  }

  private getPlainValue(
    value: number | string | boolean | Record<string, any>,
  ): number | boolean | string | [number | null, number | null] {
    switch (typeof value) {
      case 'string':
      case 'number':
      case 'bigint':
      case 'symbol':
        return value;
      case 'boolean':
        return Boolean(value);
      case 'object':
        return typeof value?.left === 'number'
          ? [value.left ?? null, value.right ?? null]
          : value?.id
            ? value.id
            : value;
      default:
        return null;
    }
  }

  private getStates(collectionName: string): Observable<NamedEntity[]> {
    return this.dataService
      .collection(collectionName)
      .function('GetStates')
      .query(null, { select: ['id', 'code', 'name', 'index'] });
  }
}
