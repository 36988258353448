import { Component, Input } from '@angular/core';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridCommandColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-command-cell',
  templateUrl: './command-cell.component.html',
  styleUrls: ['./command-cell.component.scss'],
  standalone: false,
})
export class CommandCellComponent {
  @Input() row: any;
  @Input() column: GridCommandColumn;

  constructor(public service: GridService) {}

  /** Executes grid command. */
  public executeCommand(): void {
    setTimeout(() => this.service.execute(this.column.command), 0);
  }
}
