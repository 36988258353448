import { Injectable } from '@angular/core';
import {
  GridBooleanColumn,
  GridColumn,
  GridColumnType,
} from 'src/app/shared-features/grid/models/grid-column.interface';

/** Service for default column value management. */
@Injectable()
export class ColumnDefaultValueService {
  /**
   * Returns default value for target grid column type.
   *
   * @param column grid column.
   * @returns default value.
   */
  public getDefaultValue(column: GridColumn): unknown {
    switch (column.type) {
      case GridColumnType.String:
      case GridColumnType.StringControl:
        return '';
      case GridColumnType.Boolean:
      case GridColumnType.BooleanControl:
        return !!(column as GridBooleanColumn).reversed;
      default:
        return null;
    }
  }
}
