import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewRef,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { ProjectExpensesService } from 'src/app/projects/card/project-expenses/project-expenses.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'wp-recurring-expense-rule-cell',
  templateUrl: './recurring-expense-rule-cell.component.html',
  styleUrls: ['./recurring-expense-rule-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RecurringExpenseRuleCellComponent
  implements OnInit, OnDestroy, GridComponentCell
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: GridService,
    public ref: ChangeDetectorRef,
    public projectExpensesService: ProjectExpensesService,
  ) {}

  ngOnInit(): void {
    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
