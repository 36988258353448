import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { Guid } from 'src/app/shared/helpers/guid';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { PreferredResourcesToolbarComponent } from './preferred-resources-toolbar/preferred-resources-toolbar.component';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridColumnType,
  GridUserControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-preferred-resources',
  templateUrl: './preferred-resources.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class PreferredResourcesComponent {
  @Input() entityId: string;
  @Input() readonly: boolean;
  @Input() users: UntypedFormArray = this.fb.array([]);

  @Input() roleId: string;
  @Input() resourcePoolId: string;

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: PreferredResourcesToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.users.removeAt(index);
          this.users.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          const group = this.getGridFormGroup({});
          this.users.push(group);
          this.gridService.selectGroup(group);
          this.users.markAsDirty();
        },
      },
    ],
    view: {
      name: 'users',
      columns: [
        <GridUserControlColumn>{
          name: 'resource',
          header: 'resources.requests.props.preferredResource',
          hint: 'resources.requests.props.preferredResource',
          type: GridColumnType.UserControl,
          placeholder: 'resources.requests.props.preferredResource',
          query: () => {
            const query = { filter: [] };

            if (this.roleId) {
              query.filter.push({
                or: [
                  { roleId: { type: 'guid', value: this.roleId } },
                  {
                    additionalUserRoles: {
                      any: {
                        roleId: { type: 'guid', value: this.roleId },
                      },
                    },
                  },
                ],
              });
            }

            if (this.resourcePoolId) {
              query.filter.push({
                resourcePoolId: {
                  type: 'guid',
                  value: this.resourcePoolId,
                },
              });
            }

            const usersIds: string[] = this.users.value
              .filter((pr) => pr.resource)
              .map((pr) => pr.resource.id);

            if (usersIds.length) {
              query.filter.push([`not (id in (${usersIds.join(',')}))`]);
            }

            return query;
          },
          width: '100%',
          required: true,
        },
      ],
    },
  };

  constructor(
    private gridService: GridService,
    public actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
  ) {}

  private getGridFormGroup(row: any): UntypedFormGroup {
    return this.fb.group({
      resource: [row.resource],
      id: row.id ? row.id : Guid.generate(),
    });
  }
}
