import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { ResourceSummaryResourceEntryDto } from 'src/app/resource-summary/models/resource-summary-data.model';

@Pipe({
  name: 'resourceSummaryEntry',
  standalone: false,
})
export class ResourceSummaryEntryPipe implements PipeTransform {
  private readonly templates = {
    decimal: '1.0-2',
    currency: '1.0-0',
  };

  constructor(
    private currencyPipe: WpCurrencyPipe,
    private decimalPipe: DecimalPipe,
  ) {}

  transform(value: ResourceSummaryResourceEntryDto, mode: ValueMode): string {
    if (value == null || ((value.hours ?? 0) === 0 && (value.cost ?? 0) === 0))
      return null;

    switch (mode) {
      case ValueMode.Hours:
        return this.decimalPipe.transform(value.hours, this.templates.decimal);
      case ValueMode.Cost:
        return this.currencyPipe.transform(
          value.cost,
          null,
          this.templates.currency,
          true,
        );
      default:
        return null;
    }
  }
}
