import { Component, OnInit } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { InteractionListFilterService } from 'src/app/interactions/list/interaction-list-filter/interaction-list-filter.service';

@Component({
  selector: 'tmt-interaction-list',
  template: '<wp-entity-list/>',
  providers: [
    { provide: FilterService, useClass: InteractionListFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  standalone: false,
})
export class InteractionListComponent implements OnInit {
  constructor(private actions: ActionPanelService) {}

  public ngOnInit(): void {
    this.actions.set([]);
  }
}
