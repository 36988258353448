import { Component, OnInit, Input } from '@angular/core';
import { WidgetConfig } from '../../../shared/widget-config.model';
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  UntypedFormArray,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { WidgetType } from '../widget-type.model';
import { TranslateService } from '@ngx-translate/core';
import { cloneDeep } from 'lodash';
import { ViewSettings } from 'src/app/analytics/shared/models/view-settings/view-settings.model';
import { ReportSourceDescription } from 'src/app/analytics/shared/models/source-description/report-source-description.model';
import { AnalyticsService } from 'src/app/core/analytics.service';
import { DatasetFieldSetting } from '../../../shared/dataset-field-setting.model';
import { CodedEntity } from 'src/app/shared/models/entities/coded-entity.model';
import { Filter } from 'src/app/analytics/shared/models/filter/filter-model';
import { WidgetSortBy } from '../../../shared/widget-sort-by.enum';
import { ReportPeriodService } from 'src/app/analytics/shared/report-period/report-period.service';

@Component({
  selector: 'wp-widget-settings',
  templateUrl: './widget-settings.component.html',
  styleUrls: ['./widget-settings.component.scss'],
  providers: [ReportPeriodService],
  standalone: false,
})
export class WidgetSettingsComponent implements OnInit {
  private _widget: WidgetConfig;
  @Input() get widget(): WidgetConfig {
    return this._widget;
  }
  set widget(value: WidgetConfig) {
    this._widget = cloneDeep(value);
  }

  public hasDataTab: boolean;
  public hasFilterTab: boolean;
  public hasPeriodTab: boolean;
  public sourceDescription: ReportSourceDescription;

  public isSaving: boolean;
  public types: NamedEntity[];

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    type: [null, Validators.required],
    properties: this.fb.group({}),
  });

  public get propertiesGroup(): UntypedFormGroup {
    return this.form.controls['properties'] as UntypedFormGroup;
  }

  constructor(
    private reportPeriodService: ReportPeriodService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private analyticsService: AnalyticsService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
  ) {}

  public ok() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.widget.properties = this.propertiesGroup.value;
    this.activeModal.close(this.widget);
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  private updateTabsVisible() {
    this.hasDataTab = this.widget.type && this.widget.type !== WidgetType.Frame;

    this.hasFilterTab =
      this.widget.viewSettings?.sourceName &&
      this.widget.type &&
      this.widget.type !== WidgetType.Frame;

    this.hasPeriodTab =
      this.sourceDescription?.hasPeriod &&
      this.widget.type &&
      this.widget.type !== WidgetType.Frame;
  }

  private loadSourceDescription() {
    this.sourceDescription = null;
    if (this.widget.viewSettings && this.widget.viewSettings.sourceName) {
      this.analyticsService
        .getSourceDescription(this.widget.viewSettings.sourceName)
        .subscribe((description) => {
          this.sourceDescription = description;
          this.updateTabsVisible();
        });
    } else {
      this.updateTabsVisible();
    }
  }

  public onFiltersChanged(filters: Filter[]) {
    this.widget.filters = cloneDeep(filters);
  }

  public onViewSettingChanged(data: [ViewSettings, DatasetFieldSetting[]]) {
    this.widget.viewSettings = data[0];
    this.widget.datasetFields = data[1];
  }

  public onReportTypeChanged(reportType: CodedEntity) {
    this.widget.filters = [];
    this.widget.viewSettings.sourceName = reportType.code;
    this.loadSourceDescription();
    this.updateTabsVisible();
  }

  private onTypeChanged() {
    this.widget.properties = {};

    if (this.widget.type === 'Table') {
      this.widget.viewSettings.columnFields = [];
      this.widget.viewSettings.rowGroupFields = [];
      this.widget.viewSettings.valueFields = [];
      this.widget.viewSettings.columnGroupFields = [];

      if (!this.widget.userSettings) {
        this.widget.userSettings = {};
      }
    }

    if (this.widget.type !== 'Frame') {
      if (!this.widget.viewSettings) {
        this.widget.viewSettings = {
          sourceName: null,
        };
      }
      if (!this.widget.filters) {
        this.widget.filters = [];
      }
    }

    this.initWidgetProperties();
    this.updateTabsVisible();
  }

  private initWidgetProperties() {
    this.form.controls['properties'] = this.fb.group({});

    if (this.widget.type === WidgetType.Value) {
      this.propertiesGroup.addControl('abbreviation', this.fb.control('none'));
    }

    if (this.widget.type === WidgetType.DoubleValue) {
      this.propertiesGroup.addControl('proportion', this.fb.control('direct'));
      this.propertiesGroup.addControl('showProportion', this.fb.control(false));
      this.propertiesGroup.addControl('abbreviation', this.fb.control('none'));
    }

    if (
      [
        WidgetType.Pie,
        WidgetType.Column,
        WidgetType.StackedColumns,
        WidgetType.StackedPercentageColumns,
      ].includes(this.widget.type)
    ) {
      this.propertiesGroup.addControl('showTop', this.fb.control(10));
      this.propertiesGroup.addControl(
        'sortBy',
        this.fb.control(WidgetSortBy.Category),
      );
      this.propertiesGroup.addControl('sortReverse', this.fb.control(false));
      this.propertiesGroup.addControl('hasLegend', this.fb.control(false));
      this.propertiesGroup.addControl('hasDataLabels', this.fb.control(true));
    }

    if (this.widget.type === WidgetType.Line) {
      this.propertiesGroup.addControl('hasLegend', this.fb.control(false));
      this.propertiesGroup.addControl('hasDataLabels', this.fb.control(true));
    }

    if (this.widget.type === WidgetType.Speedometer) {
      this.propertiesGroup.addControl('min', this.fb.control(0));
      this.propertiesGroup.addControl('max', this.fb.control(100));
      this.propertiesGroup.addControl('segments', this.fb.array([]));

      if (this.widget.properties?.segments) {
        (this.widget.properties.segments as any[]).forEach((segment) => {
          (this.propertiesGroup.controls['segments'] as UntypedFormArray).push(
            this.fb.group({
              from: [null],
              to: [null],
              color: [null],
            }),
          );
        });
      }
    }

    if (this.widget.type === WidgetType.Frame) {
      this.propertiesGroup.addControl('url', this.fb.control(''));
      this.propertiesGroup.addControl('content', this.fb.control(''));
    }

    if (this.widget.properties) {
      this.propertiesGroup.patchValue(this.widget.properties);
    }

    this.propertiesGroup.valueChanges.subscribe(() => {
      this.widget.properties = this.propertiesGroup.value;
    });
  }

  ngOnInit(): void {
    this.initWidgetProperties();

    this.types = [];
    for (const key in WidgetType) {
      if (Object.prototype.hasOwnProperty.call(WidgetType, key)) {
        this.types.push({
          id: key,
          name: this.translate.instant(`enums.widgetType.${key}`),
        });
      }
    }

    if (this.widget.type) {
      this.form.controls['type'].setValue(
        this.types.find((t) => t.id === this.widget.type),
      );
    }
    this.form.controls['name'].setValue(this.widget.name);

    this.loadSourceDescription();
    this.updateTabsVisible();

    this.form.valueChanges.subscribe(() => {
      this.widget.name = this.form.value.name;
    });

    this.form.controls['type'].valueChanges.subscribe(() => {
      this.widget.type = this.form.controls['type'].value.id;
      this.onTypeChanged();
    });

    this.reportPeriodService.setPeriod(
      this.widget.period
        ? {
            from: this.widget.period.From,
            to: this.widget.period.To,
            periodType: this.widget.period.PeriodType,
          }
        : null,
    );

    this.reportPeriodService.period$.subscribe((period) => {
      this.widget.period = period
        ? {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            From: period.from,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            To: period.to,
            // eslint-disable-next-line @typescript-eslint/naming-convention
            PeriodType: period.periodType,
          }
        : null;
    });
  }
}
