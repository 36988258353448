import {
  Component,
  OnInit,
  Output,
  EventEmitter,
  Input,
  DestroyRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { AppService } from 'src/app/core/app.service';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

@Component({
  selector: 'wp-rule-required-fields',
  templateUrl: './rule-required-fields.component.html',
  standalone: false,
})
export class RuleRequiredFieldsComponent implements OnInit {
  @Output() changes = new EventEmitter<string>();
  @Input() data: string;
  @Input() readonly: boolean;

  public lineCustomFields: MetaEntityBaseProperty[];
  public entryCustomFields: MetaEntityBaseProperty[];
  public form: UntypedFormGroup = this.fb.group({
    ActivityRequired: false,
    RoleRequired: false,
    CommentRequired: false,
    CostCenterRequired: false,
    EntryFields: this.fb.group({}),
    LineFields: this.fb.group({}),
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    app: AppService,
  ) {
    this.entryCustomFields = app.getCustomFields('TimeAllocation');
    this.lineCustomFields = app.getCustomFields('TimeSheetLine');

    this.entryCustomFields.forEach((f) => {
      (this.form.controls['EntryFields'] as UntypedFormGroup).addControl(
        f.name,
        this.fb.control(false),
      );
    });

    this.lineCustomFields.forEach((f) => {
      (this.form.controls['LineFields'] as UntypedFormGroup).addControl(
        f.name,
        this.fb.control(false),
      );
    });
  }

  ngOnInit() {
    const obj = JSON.parse(this.data);
    this.form.patchValue(obj);

    if (obj.TimeAllocationRequired) {
      (obj.TimeAllocationRequired as string[]).forEach((f) => {
        (this.form.controls['EntryFields'] as UntypedFormGroup).controls[
          _.camelCase(f)
        ].setValue(true);
      });
    }

    if (obj.TimeSheetLineRequired) {
      (obj.TimeSheetLineRequired as string[]).forEach((f) => {
        (this.form.controls['LineFields'] as UntypedFormGroup).controls[
          _.camelCase(f)
        ].setValue(true);
      });
    }

    if (this.readonly) {
      this.form.disable();
    }

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const formValue = this.form.value;
        const data: any = {
          ActivityRequired: formValue.ActivityRequired,
          RoleRequired: formValue.RoleRequired,
          CostCenterRequired: formValue.CostCenterRequired,
          CommentRequired: formValue.CommentRequired,
          TimeAllocationRequired: [],
          TimeSheetLineRequired: [],
        };

        this.entryCustomFields.forEach((f) => {
          if (formValue.EntryFields[f.name]) {
            data.TimeAllocationRequired.push(_.upperFirst(f.name));
          }
        });

        this.lineCustomFields.forEach((f) => {
          if (formValue.LineFields[f.name]) {
            data.TimeSheetLineRequired.push(_.upperFirst(f.name));
          }
        });

        this.changes.emit(JSON.stringify(data));
      });
  }
}
