import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { InteractionListComponent } from 'src/app/interactions/list/interaction-list.component';
import { INTERACTIONS_LIST } from 'src/app/shared/lists/interactions.list';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { InteractionListFilterComponent } from 'src/app/interactions/list/interaction-list-filter/interaction-list-filter.component';

@NgModule({
  declarations: [InteractionListComponent, InteractionListFilterComponent],
  imports: [
    CommonModule,
    SharedModule,
    EntityListComponent,
    UIRouterModule.forChild({
      states: [
        {
          name: 'interactions',
          url: '/interactions/{view:viewName}?{navigation}&{route}',
          params: {
            route: ROUTE_DEFAULT_PARAMS,
            navigation: 'clients.interactions',
            routeMode: null,
            view: null,
          },
          component: InteractionListComponent,

          resolve: [
            { provide: LIST, useValue: INTERACTIONS_LIST },
            {
              provide: VIEW_NAME,
              useFactory: StateHelper.resolveView,
              deps: [Transition],
            },
          ],
        },
      ],
    }),
  ],
})
export class InteractionsModule {}
