import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Injector,
  ElementRef,
  Optional,
  NgZone,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormArray,
  FormControl,
} from '@angular/forms';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import _ from 'lodash';
import { Subject, Subscription, firstValueFrom } from 'rxjs';
import { debounceTime, startWith, takeUntil, first } from 'rxjs/operators';

import { AppService } from 'src/app/core/app.service';
import { MessageService } from 'src/app/core/message.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { DateHours } from 'src/app/shared/models/entities/date-hours.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { FreezeTableOptions } from 'src/app/shared/directives/freeze-table/freeze-table.interface';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ProjectResourcesHelper } from 'src/app/projects/card/project-resources/core/project-resources.helper';
import {
  ResourceRequest,
  ResourceRequestTemplate,
} from 'src/app/shared/models/entities/resources/resource-request.model';

import { ResourceRequestService } from '../resource-request/resource-request.service';
import { ResourceRequestCalendarService } from './resource-request-calendar.service';
import {
  ResourceRequestLine,
  ResourceRequestEvents,
  ResourceRequestMode,
  EventItem,
  TotalsCalcMode,
} from '../resource-request/resource-request.interface';
import {
  BookingEntry,
  BookingResource,
} from 'src/app/shared/models/entities/resources/booking-entry.model';
import { BookingService } from 'src/app/booking/booking/core/booking.service';
import { BookingRenderingService } from 'src/app/booking/booking/core/booking-rendering.service';
import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { BookingManageService } from 'src/app/booking/booking/core/booking-manage.service';
import { BookingFilterService } from 'src/app/booking/booking/shared/booking-filter/booking-filter.service';
import { BookingViewSettingsService } from 'src/app/booking/booking/shared/booking-view-settings/booking-view-settings.service';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import { bookingProviderFactory } from 'src/app/booking/booking-schedule/booking-schedule.component';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-resource-request-calendar',
  templateUrl: './resource-request-calendar.component.html',
  styleUrls: ['./resource-request-calendar.component.scss'],
  providers: [
    NgbActiveModal,
    GridService,
    CellsOrchestratorService,
    BookingService,
    BookingDataService,
    BookingManageService,
    BookingViewSettingsService,
    { provide: FilterService, useClass: BookingFilterService },
    {
      provide: BookingRenderingService,
      deps: [
        AppService,
        BookingDataService,
        ScheduleNavigationService,
        [new Optional(), 'entityData'],
        [new Optional(), 'context'],
        NgZone,
      ],
      useFactory: bookingProviderFactory,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceRequestCalendarComponent implements OnInit {
  @Input() public hasToolbar = true;
  @Input() public freezeTableOptions: FreezeTableOptions;
  @Input() public bookingFreezeTableOptions: FreezeTableOptions;
  @Input() public mode: ResourceRequestMode = 'edit';

  public pendingRequest = true;
  public pendingBooking = true;
  public readonly = false;
  public isDeviation = false;
  public isBookingHidden = false;
  public request: ResourceRequest | ResourceRequestTemplate;
  public bookingEntries: BookingEntry[] | null;
  public lines: ResourceRequestLine[] = [];
  public formLines: UntypedFormArray = this.fb.array([]);
  public requestFormLine: UntypedFormArray;
  public switcherControl = new FormControl<TotalsCalcMode>('new');

  private resultFormLine: UntypedFormArray;
  private formLinesSubscription: Subscription;
  private readonly defaultFreezeTableOptions: FreezeTableOptions = {
    inContainerMode: false,
    rootContainerId: 'planner-container',
    name: 'planner',
    syncWith: ['booking', 'planner', 'resources'],
    disableHorizontalScroller: true,
  };
  private readonly destroyed$ = new Subject<void>();

  public get requestWithResourcePlan(): boolean {
    return (
      this.request['teamMemberId'] &&
      (this.mode === 'create' ||
        this.mode === 'menuCreate' ||
        this.mode === 'edit')
    );
  }

  constructor(
    public resourceRequestCalendarService: ResourceRequestCalendarService,
    private resourceRequestService: ResourceRequestService,
    private cellsOrchestratorService: CellsOrchestratorService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private infoPopupService: InfoPopupService,
    private messageService: MessageService,
    private injector: Injector,
    private el: ElementRef<HTMLElement>,
    private bookingService: BookingService,
    private bookingDataService: BookingDataService,
    private bookingViewSettingsService: BookingViewSettingsService,
    private scheduleNavigationService: ScheduleNavigationService,
    bookingFilterService: FilterService,
  ) {
    bookingFilterService.storageName = null;
    this.initFreezeTableOptions();
  }

  public ngOnInit(): void {
    this.request = this.resourceRequestService.request;
    this.bookingService.isAssistantTopMode = this.mode === 'assistant';
    this.bookingViewSettingsService.assistantMode = this.mode === 'assistant';
    this.bookingDataService.resourceRequestMode = this.mode;
    this.bookingDataService.resourceRequest =
      this.resourceRequestService.request;

    this.resourceRequestCalendarService.mode = this.mode;
    this.resourceRequestCalendarService.loadFrame(null);
    this.initRequestSubscribers();
    this.initBookingSubscribers();

    if (!this.isBookingEntryViewAllowed()) {
      this.switcherControl.disable();
    }

    if (this.mode === 'create' || this.mode === 'menuCreate') {
      this.scheduleNavigationService.asyncActionBeforeChange = () =>
        this.messageService
          .confirmLocal('resources.requests.actions.requestResourceUpdate')
          .then(
            () => true,
            () => false,
          );
    }
  }

  public trackById(index: number, row: any): string {
    return row.id;
  }

  /** Resets dateHours in the calendar. */
  public resetData(): void {
    this.messageService
      .confirmLocal('resources.requests.actions.requestResourceUpdate')
      .then(
        () => this.resourceRequestCalendarService.resetEntries(),
        () => null,
      );
  }

  /** Opens user info. */
  public openUserInfo(userId: string): void {
    const userNameDiv: HTMLElement = this.el.nativeElement.querySelector(
      `#id${userId}`,
    );
    const userNameSpan: HTMLElement = this.el.nativeElement.querySelector(
      `#id${userId}-name`,
    );

    const target =
      userNameDiv.offsetWidth < userNameSpan.offsetWidth
        ? userNameDiv
        : userNameSpan;

    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Returns `true`, if entry hours is null or is equal to `0`.
   *
   * @param index Array index.
   * @param row ResourceRequestLine.
   * @param entry DateHours.
   *
   */
  public isValidValue(
    index: number,
    row: ResourceRequestLine,
    entry: DateHours,
  ): boolean {
    return (
      this.mode === 'assistant' &&
      row.id === 'result' &&
      this.requestFormLine.value[index]?.hours &&
      (!entry?.hours || !+entry?.hours?.toFixed(2))
    );
  }

  /** Checks if BookingComponent can be shown  */
  public isBookingEntryViewAllowed(): boolean {
    return (
      this.mode !== 'create' &&
      this.mode !== 'menuCreate' &&
      this.request['bookingEntryViewAllowed']
    );
  }

  private rebuildLines(): void {
    this.bookingEntries = this.resourceRequestCalendarService.bookingEntries;
    this.lines.length = 0;
    this.formLines = this.fb.array([]);

    const limitHours =
      (this.request as ResourceRequest).teamMember?.resource.resourceType ===
      ResourceType.user
        ? 24
        : 999;

    for (const key in this.resourceRequestCalendarService.entries) {
      if (key === 'result' && !this.bookingEntries.length) {
        continue;
      }

      if (key === 'result' || key === 'request') {
        const dateHours = this.resourceRequestCalendarService.entries[key];

        this.lines.push({
          id: key,
          total: dateHours.reduce((prev, curr) => prev + curr.hours, 0),
        });

        const rowFormArray = this.fb.array([]);

        for (const entry of dateHours) {
          const control = this.fb.control({
            ...entry,
            hours: +entry.hours.toFixed(2),
            limitHours,
          });

          control.valueChanges
            .pipe(takeUntil(this.destroyed$))
            .subscribe((value) => {
              this.resourceRequestCalendarService.addEntryToRequest(value);

              if (this.mode === 'edit') {
                this.resourceRequestCalendarService.addEntryToQueue(value);
              }
            });

          rowFormArray.push(control);
        }

        this.formLines.push(rowFormArray);
        rowFormArray.disable({ emitEvent: false });
      }
    }

    this.toggleFormEnable();
    this.calcTotals();
    this.initFormLinesSubscriber();
    this.cellsOrchestratorService.init();
  }

  private calcRequestDatesAndHours(value: DateHours[]): void {
    const fromIndex = value.findIndex((el) => !!el.hours);
    const toIndex = _.findLastIndex(value, (el) => !!el.hours);

    if (fromIndex > -1 && toIndex > -1) {
      this.resourceRequestService.onChange(
        {
          from: value[fromIndex].date,
          to: ProjectResourcesHelper.getEndDateByScale(
            value[toIndex].date,
            this.resourceRequestCalendarService.planningScale,
          ),
          requestedHours: value.reduce(
            (previousValue, currentValue) => previousValue + currentValue.hours,
            0,
          ),
        },
        'create',
        true,
      );
    } else {
      this.resourceRequestService.onChange(
        {
          from: null,
          to: null,
          requestedHours: null,
        },
        'create',
        true,
      );
    }

    this.cdr.markForCheck();
  }

  private initFormLinesSubscriber(): void {
    if (this.formLinesSubscription) {
      this.formLinesSubscription.unsubscribe();
    }

    this.formLinesSubscription = this.formLines.valueChanges
      .pipe(
        startWith(this.formLines.getRawValue()),
        debounceTime(100),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.calcTotals();

        if (this.mode !== 'assistant') {
          this.calcRequestDatesAndHours(
            this.resourceRequestCalendarService.requestDateHours,
          );
        }

        if (this.mode === 'create' || this.mode === 'menuCreate') {
          this.resourceRequestService.onChange(
            {
              scale: this.resourceRequestCalendarService.planningScale,
              requirementEntries:
                this.resourceRequestCalendarService.requestDateHours,
            },
            this.mode,
            true,
          );
        }

        if (this.requestWithResourcePlan) {
          this.isDeviation =
            this.resourceRequestCalendarService.checkIfHasDeviation();
        }

        this.cdr.markForCheck();
      });
  }

  private calcTotals(): void {
    const assistantResultLine = [];

    this.resourceRequestCalendarService.slots.forEach((slot, index) => {
      const slotEntry = this.requestFormLine.value[index];
      const entry = {
        date: slot.date.toISODate(),
        hours: this.mode === 'assistant' ? (-slotEntry?.hours ?? 0) : 0,
        nonWorking: slotEntry?.nonWorking,
        fteHours: slotEntry?.fteHours,
        scheduleHours: slotEntry?.scheduleHours,
      };

      this.bookingDataService.bookingsResourceRequest.forEach((booking) => {
        entry.hours +=
          booking.detailEntries.find((el) => el.date === entry.date)?.hours ??
          0;
      });

      assistantResultLine.push({ ...entry, hours: +entry.hours.toFixed(2) });
    });

    if (this.bookingEntries.length) {
      this.resultFormLine.patchValue(assistantResultLine, { emitEvent: false });
    }

    this.lines.forEach((line) => {
      if (line.id === 'request') {
        line.total =
          this.resourceRequestCalendarService.requestDateHours.reduce(
            (prev, curr) => prev + curr.hours,
            0,
          );
      }

      if (line.id === 'result') {
        line.total = this.resourceRequestCalendarService.getResultTotal(
          this.mode,
        );
      }
    });
  }

  private toggleFormEnable(): void {
    this.requestFormLine = this.formLines.at(
      this.lines.findIndex((value) => value.id === 'request'),
    ) as UntypedFormArray;
    this.resultFormLine = this.formLines.at(
      this.lines.findIndex((value) => value.id === 'result'),
    ) as UntypedFormArray;

    if (this.mode === 'result' || this.mode === 'assistant') {
      this.formLines.disable({ emitEvent: false });
      return;
    }

    if (this.readonly) {
      this.requestFormLine.disable({ emitEvent: false });
    } else {
      this.requestFormLine.enable({ emitEvent: false });
    }
  }

  private async createBookingEntry(resource: NamedEntity): Promise<void> {
    try {
      const result = await firstValueFrom(
        this.bookingDataService.createBookingEntry(
          resource.id,
          this.resourceRequestService.request.project.id,
          this.resourceRequestCalendarService.planningScale,
        ),
      );

      if (result) {
        result.resource = resource;
        result.project = this.resourceRequestService.request.project;
        this.bookingService.addNewBookingEntry(result);
      }
    } catch (error) {
      this.bookingService.errorHandlerOnSave(
        error,
        resource,
        this.resourceRequestService.request.project,
      );
    }
  }

  private setBookingDataToCalendar(resetResourceIds = false): void {
    this.resourceRequestCalendarService.setBookingEntries(
      this.bookingDataService.bookingsResourceRequest,
      resetResourceIds,
    );
    this.resourceRequestCalendarService.initResourceIds(
      this.bookingDataService.resources.map((r) => r.id),
    );

    if (this.mode !== 'assistant') {
      this.bookingDataService.extraBookings =
        this.switcherControl.value === 'current'
          ? null
          : this.resourceRequestCalendarService.bookingEntries;
    }
  }

  private initFreezeTableOptions(): void {
    this.freezeTableOptions = this.defaultFreezeTableOptions;

    this.bookingFreezeTableOptions = {
      name: 'booking',
      syncWith: ['booking', 'planner', 'resources'],
    };
  }

  private initControlSubscribers(): void {
    this.switcherControl.valueChanges
      .pipe(startWith(this.switcherControl.value), takeUntil(this.destroyed$))
      .subscribe((value) => {
        if (value === 'current') {
          this.bookingDataService.extraBookings = null;
          this.bookingService.emitComponentChanges(null);
        } else {
          this.bookingDataService.extraBookings =
            this.resourceRequestCalendarService.bookingEntries;

          this.resourceRequestCalendarService.bookingEntries.forEach(
            (bookingEntry) => {
              this.bookingService.emitComponentChanges(
                bookingEntry.resource?.id ?? bookingEntry.resourceId,
              );
            },
          );
        }
      });

    if (
      this.resourceRequestService.lifecycleInfo?.currentState.id ===
      ResourceRequest.requestCompletedStateId
    ) {
      this.switcherControl.setValue('current');
      this.switcherControl.disable();
    }
  }

  private initRequestSubscribers(): void {
    this.resourceRequestService.request$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((request) => {
        if (request.id) {
          const requestResource = request as ResourceRequest;
          this.request = requestResource;
          this.readonly = this.resourceRequestService.readonly;
        } else {
          const requestTemplate = request as ResourceRequestTemplate;
          this.request = requestTemplate;
        }

        this.cdr.markForCheck();
      });

    this.resourceRequestService.event$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((event: EventItem) => {
        switch (event.name) {
          case ResourceRequestEvents.pendingFrame:
            this.pendingRequest = true;
            this.cdr.markForCheck();
            break;
          case ResourceRequestEvents.reloadFrame:
            this.pendingRequest = true;
            this.resourceRequestCalendarService.init();
            this.bookingService.reload();
            break;
          case ResourceRequestEvents.loadFrame:
            this.pendingRequest = false;
            this.isDeviation =
              this.resourceRequestCalendarService.checkIfHasDeviation();
            this.rebuildLines();
            break;
          case ResourceRequestEvents.updatedRequest:
            this.cdr.markForCheck();
            break;
          case ResourceRequestEvents.addedBookingEntry: {
            const resource = event.data as BookingResource;
            const index = this.bookingDataService.resources.findIndex(
              (el) => el.id === resource.id,
            );

            if (index === -1) {
              this.bookingDataService.resources.push(resource);
              this.bookingDataService.schedules[resource.id] =
                resource.schedule;
              this.bookingService.emitDetectChanges(null);
            }

            this.setBookingDataToCalendar();

            if (
              index === -1 &&
              this.resourceRequestCalendarService.bookingMode ===
                BookingMode.Detailed
            ) {
              this.createBookingEntry(event.data as BookingResource).then(
                () => {
                  this.bookingService.toggleGroup(resource.id, true);
                  this.rebuildLines();
                },
              );
            } else {
              this.bookingService.toggleGroup(resource.id, true);
              this.rebuildLines();
            }

            break;
          }
          case ResourceRequestEvents.removedBookingEntry:
            this.rebuildLines();
            break;
        }
      });
  }

  private initBookingSubscribers(): void {
    this.bookingService.recalculateGroup$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((value) => {
        this.calcTotals();
        this.resourceRequestService.setEvent<string>(
          ResourceRequestEvents.updateBookingEntry,
          value.id,
        );
      });

    this.bookingService.changes$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.rebuildLines();
        this.resourceRequestService.setEvent(
          ResourceRequestEvents.updateBookingEntry,
          null,
        );
      });

    this.bookingService.loading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((loading) => {
        if (!this.isBookingEntryViewAllowed()) {
          this.pendingBooking = false;
          return;
        }

        this.pendingBooking = loading;
        this.bookingService.resourceRequestInterval =
          this.resourceRequestCalendarService.interval;

        if (!loading) {
          this.setBookingDataToCalendar(true);
        }
      });

    if (this.mode !== 'assistant') {
      this.bookingService.loading$.pipe(first((v) => !v)).subscribe(() => {
        this.isBookingHidden = !this.bookingDataService.resources.length;
        this.freezeTableOptions = Object.assign(
          {},
          !this.isBookingHidden
            ? this.defaultFreezeTableOptions
            : {
                disableHorizontalScroller: false,
              },
        );
        this.cdr.markForCheck();

        if (this.hasToolbar) {
          this.initControlSubscribers();
        }
      });
    }
  }
}
