<div [ngStyle]="getIndent()" class="align-self-center">
  <span
    (click)="toggle()"
    class="bi toggle"
    [ngClass]="{
      'cursor-pointer': formGroup.value.isExpanded !== null,
      'bi-chevron-down': formGroup.value.isExpanded === true,
      'bi-chevron-right': formGroup.value.isExpanded === false
    }"
  ></span>
</div>
<div
  class="trim readonly-name"
  [ngClass]="{ 'cross-out': !formGroup.getRawValue().isActive }"
  title="{{ control.value }}"
>
  {{ control.value }}
</div>
