import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ViewChild,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
  signal,
  effect,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { UserActivityToolbarComponent } from './user-activity-toolbar/user-activity-toolbar.component';
import { Observable, Subscription, forkJoin } from 'rxjs';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { Exception } from 'src/app/shared/models/exception';
import { filter } from 'rxjs/operators';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridComponent } from 'src/app/shared-features/grid/grid.component';

@Component({
  selector: 'wp-user-timesheets',
  templateUrl: './user-timesheets.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserTimesheetsComponent implements OnInit, OnDestroy {
  @Input() entityId: string;
  @ViewChild('grid') grid: GridComponent;

  public isSaving = signal(false);
  public isLoading = signal(false);

  public activitiesAreEditable = this.app.checkEntityPermission(
    'UserActivity',
    PermissionType.Modify,
  );
  public activitiesAreVisible = this.app.checkEntityPermission(
    'UserActivity',
    PermissionType.Read,
  );
  public readonly = !this.app.checkEntityPermission(
    'User',
    PermissionType.Modify,
  );

  public form: UntypedFormGroup = this.fb.group({
    timeSheetTemplate: [null, Validators.required],
    restrictActivities: [false],
    activities: this.fb.array([]),
  });

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: UserActivityToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => this.activitiesAreEditable,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          (<UntypedFormArray>this.form.controls.activities).removeAt(index);
          this.form.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'add',
        handlerFn: (activity: NamedEntity) => {
          const group = this.fb.group({
            activityName: [activity.name, Validators.required],
            activityId: [activity.id, Validators.required],
            id: [Guid.generate()],
          });
          (<UntypedFormArray>this.form.controls.activities).push(group);

          group.markAsDirty();
        },
      },
    ],
    view: {
      name: 'userActivities',
      columns: [
        {
          name: 'activityName',
          header: 'shared2.props.name',
          hint: 'shared2.props.name',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  private reloadListener: Subscription;
  private destroyRef = inject(DestroyRef);

  constructor(
    private notification: NotificationService,
    private gridService: GridService,
    private blockUI: BlockUIService,
    private data: DataService,
    private app: AppService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
  ) {
    effect(() => {
      if (this.isSaving()) {
        this.blockUI.start();
        this.actionService.action('save').start();
      } else {
        this.actionService.action('save').stop();
        this.blockUI.stop();
      }
    });
  }

  public ngOnInit(): void {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.save();
      });

    this.reloadListener = this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });
    this.loadProperties();
    this.loadUserActivities();
  }

  public ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
  }

  /** Saves the user timesheet data. */
  public save(): void {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.isSaving.set(true);

      const observables: Observable<any>[] = [];
      if (!this.readonly) {
        observables.push(
          this.data.collection('Users').entity(this.entityId).patch({
            restrictActivities: this.form.value.restrictActivities,
            timeSheetTemplateId: this.form.value.timeSheetTemplate.id,
          }),
        );
      }
      if (this.activitiesAreEditable) {
        const data = { activities: [] };
        this.form.value.activities.forEach((userActivity: any) => {
          data.activities.push({
            id: userActivity.id,
            userId: this.entityId,
            activityId: userActivity.activityId,
          });
        });

        observables.push(
          this.data
            .collection('Users')
            .entity(this.entityId)
            .action('WP.UpdateActivities')
            .execute(data),
        );
      }

      forkJoin(observables).subscribe({
        next: () => {
          this.notification.successLocal('shared.messages.saved');
          this.form.markAsPristine();
          this.form.markAsUntouched();
          this.isSaving.set(false);
        },
        error: (error: any) => {
          this.notification.error(error.message);
          this.isSaving.set(false);
        },
      });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  /** Loads user properties and updates the form accordingly. */
  private loadProperties(): void {
    this.isLoading.set(true);
    this.actionService.action('save').isShown = false;

    const params = {
      select: ['id', 'restrictActivities'],
      expand: ['timeSheetTemplate'],
    };

    this.data
      .collection('Users')
      .entity(this.entityId)
      .get(params)
      .subscribe({
        next: (data: any) => {
          this.form.patchValue(data);
          this.readonly ? this.form.disable() : this.form.enable();

          this.form.markAsPristine();
          this.isLoading.set(false);

          this.actionService.action('save').isShown =
            !this.readonly || this.activitiesAreEditable;
        },
        error: (error: Exception) => {
          this.isLoading.set(false);
          this.notification.error(error.message);
        },
      });
  }

  /** Loads user activities and updates the form accordingly. */
  private loadUserActivities(): void {
    (<UntypedFormArray>this.form.controls.activities).clear();
    this.gridService.setLoadingState(true);

    const query = {
      select: ['id'],
      expand: [{ activity: { select: ['id', 'name'] } }],
      orderBy: ['activity/name'],
    };

    this.data
      .collection('Users')
      .entity(this.entityId)
      .collection('Activities')
      .query(query)
      .subscribe({
        next: (userActivities: any[]) => {
          this.gridService.setLoadingState(false);

          userActivities.forEach((userActivity: any) => {
            const group = this.fb.group({
              activityName: [userActivity.activity.name],
              activityId: [userActivity.activity.id],
              id: [userActivity.id],
            });

            (<UntypedFormArray>this.form.controls.activities).push(group);
          });
        },
        error: (error: any) => {
          this.gridService.setLoadingState(false);
          this.notification.error(error.message);
        },
      });
  }

  /** Reloads data. */
  private reload(): void {
    this.form.enable();
    if (!this.form.dirty) {
      this.loadProperties();
      this.loadUserActivities();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => {
          this.loadProperties();
          this.loadUserActivities();
        },
        () => null,
      );
    }
  }
}
