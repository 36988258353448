import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wp-timesheet-period-creation',
  templateUrl: './timesheet-period-creation.component.html',
  standalone: false,
})
export class TimesheetPeriodCreationComponent {
  isSaving = false;
  creationForm: UntypedFormGroup = this.fb.group({
    type: [null, Validators.required],
    scope: [null, Validators.required],
  });
  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  ok = () => {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = {
      periodScopeId: this.creationForm.value.scope.id,
      periodTypeId: this.creationForm.value.type.id,
    };

    this.data
      .collection('TimeSheetPeriods')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'settings.timesheetPeriods.creation.created',
          );
          this.state.go('settings.timesheetPeriod', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
