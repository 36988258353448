import { Component, DestroyRef, inject, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Guid } from 'src/app/shared/helpers/guid';
import { stateStyles } from 'src/app/shared/models/entities/state.model';
import { Exception } from 'src/app/shared/models/exception';
import { LifecycleCardService } from '../lifecycle-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Диалог редактирования/добавления состояния ЖЦ. */
@Component({
  selector: 'wp-state-modal',
  templateUrl: './state-modal.component.html',
  styleUrls: ['./state-modal.component.scss'],
  standalone: false,
})
export class StateModalComponent implements OnInit {
  @Input() lifecycleId: string;
  @Input() stateId: string | null;

  public stateStyles = stateStyles;
  public header = '';
  public isSaving: boolean;
  public form = this.fb.group({
    id: [Guid.generate(), Validators.required],
    index: null,
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    code: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    style: ['primary', Validators.required],
    isSystem: false,
    isActive: true,
    isEntityProtected: false,
    isEntityDisabled: false,
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    public app: AppService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
    public service: LifecycleCardService,
  ) {}

  ngOnInit(): void {
    this.initState();
  }

  /** Saves state. */
  public save(): void {
    if (this.form.invalid) {
      this.form.markAllAsTouched();
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    /* Включаем все контролы, чтобы получить их значения */
    this.form.enable();
    const data = {
      ...this.form.value,
      lifecycleId: this.lifecycleId,
    };
    delete data.transitions;
    this.disablePropsIsSystem();

    this.isSaving = true;
    if (this.stateId) {
      this.service
        .updateState(data)
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.isSaving = false;
            this.activeModal.close();
          },
          error: (error: Exception) => {
            this.isSaving = false;
            this.notification.error(error.message);
          },
        });
    } else {
      /* Индекс для нового состояния (оптимально просто взять длину массива, т.к. она равна макс. индекс + 1) */
      const index = this.service.lifecycleStates.length;
      this.service
        .createState({ ...data, index })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: () => {
            this.isSaving = false;
            this.activeModal.close();
          },
          error: (error: Exception) => {
            this.isSaving = false;
            this.notification.error(error.message);
          },
        });
    }
  }

  private initState(): void {
    const state = this.service.lifecycleStates.find(
      (state) => state.id === this.stateId,
    );
    this.setHeader(state?.name);
    this.form.patchValue(state);
    this.disablePropsIsSystem();
  }

  public cancel(): void {
    this.activeModal.dismiss();
  }

  public setStyle(style: string): void {
    this.form.controls.style.setValue(style);
  }

  private setHeader(header?: string): void {
    this.header = header
      ? header
      : 'settings.lifecycles.card.props.state.createHeader';
  }

  /* Disables props. */
  private disablePropsIsSystem(): void {
    this.form.controls.isSystem.disable();
    if (this.form.controls.isSystem.value) {
      this.form.controls.name.disable();
      this.form.controls.code.disable();
    }
  }
}
