import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ExpensesRequestService } from 'src/app/expenses-requests/expenses-request.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { TransitionService } from '@uirouter/core';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-expenses-request-set-project',
  templateUrl: './expenses-request-set-project.component.html',
  styleUrls: ['./expenses-request-set-project.component.scss'],
  standalone: false,
})
export class ExpensesRequestSetProjectComponent implements OnInit, OnDestroy {
  @Input() entityId: string;
  @Input() user: NamedEntity;
  @Input() project: NamedEntity;

  public isSaving = false;
  public projectList: NamedEntity[];

  public form = this.fb.group({
    project: [null, Validators.required],
  });

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public expensesService: ExpensesRequestService,
    private fb: UntypedFormBuilder,
    transitionService: TransitionService,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
    private activeModal: NgbActiveModal,
  ) {
    transitionService.onSuccess({}, () => this.cancel());
  }

  ngOnInit() {
    this.expensesService.loadUserInfoProjects(this.user.id);

    this.expensesService.projectList$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((projectList) => {
        this.projectList = projectList.filter((p) => p.id !== this.project.id);
      });
  }

  ngOnDestroy() {
    this.destroyed$.next();
  }

  ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = {
      projectId: this.form.value.project.id,
    };

    this.data
      .collection('ExpenseRequests')
      .entity(this.entityId)
      .action('WP.SetProject')
      .execute(data)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'expenses.card.setProject.messages.set',
          );
          this.expensesService.setProject$.next(true);
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.message.error(error.message);
          this.isSaving = false;
        },
      });
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }
}
