import { Component, Inject, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'tmt-overview-switcher',
  templateUrl: './overview-switcher.component.html',
  standalone: false,
})
export class OverviewSwitcherComponent implements OnInit {
  public control = new FormControl('parameters');

  constructor(
    private state: StateService,
    @Inject('entityType') private entityType: string,
  ) {}

  ngOnInit(): void {
    switch (this.state.current.name) {
      case `${this.entityType}.overview.kpi`:
        this.control.setValue('kpi');
        break;
      case `${this.entityType}.overview.analysis`:
        this.control.setValue('analysis');
        break;
    }

    this.control.valueChanges.subscribe(() => {
      this.state.go(`${this.entityType}.overview.${this.control.value}`, {
        routeMode: RouteMode.keep,
      });
    });
  }
}
