import { Node } from 'prosemirror-model';

/**
 * Checks if node is blockquote type.
 *
 * @param node node for checking.
 * @returns boolean value.
 */
export const isBlockqouteNode = (node: Node): boolean =>
  node && node.type.name === 'blockquote';
