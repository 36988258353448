import { Component } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeOffBalanceEntryService } from '../core/time-off-balance-entry.service';

@Component({
  selector: 'wp-time-off-balance-entry-creation',
  templateUrl: './time-off-balance-entry-creation.component.html',
  providers: [TimeOffBalanceEntryService],
  standalone: false,
})
export class TimeOffBalanceEntryCreationComponent {
  public isSaving = false;

  constructor(
    public service: TimeOffBalanceEntryService,
    private activeModal: NgbActiveModal,
  ) {}

  ok() {
    this.service.save().then(() => {
      this.activeModal.close('reload');
    });
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }
}
