import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
} from '@angular/core';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { WorkflowIndicatorPopupComponent } from 'src/app/shared/components/features/workflow-indicator/workflow-indicator-popup/workflow-indicator-popup.component';

@Component({
  selector: 'tmt-workflow-indicator',
  templateUrl: './workflow-indicator.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class WorkflowIndicatorComponent {
  constructor(
    public lifecycleService: LifecycleService,
    private injector: Injector,
    private popupService: InfoPopupService,
    private el: ElementRef,
  ) {}

  /** Open user notification popup. */
  public openPopup(): void {
    this.popupService.open({
      target: this.el.nativeElement,
      data: {
        component: WorkflowIndicatorPopupComponent,
        params: null,
        injector: this.injector,
      },
      containerStyles: { padding: '0' },
    });
  }
}
