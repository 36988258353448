import { Directive, ElementRef } from '@angular/core';

@Directive({
  selector: '[radioLabel]',
  standalone: false,
})
export class RadioLabelDirective {
  active = false;
  innerInput!: HTMLInputElement;
  value!: string;

  constructor(private element: ElementRef) {}

  initLabel(subject) {
    subject.subscribe((v) => this.onChange(v));
  }

  onChange(value: string) {
    this.value === value ? (this.active = true) : (this.active = false);

    if (this.active) {
      (this.element.nativeElement as HTMLElement).classList.add('active');
      (this.element.nativeElement as HTMLElement).classList.add('focus');
    } else {
      (this.element.nativeElement as HTMLElement).classList.remove('active');
      (this.element.nativeElement as HTMLElement).classList.remove('focus');
    }
  }

  ngOnInit() {
    (this.element.nativeElement as HTMLElement).classList.add('btn');
  }

  ngAfterContentInit() {
    this.innerInput = (this.element.nativeElement as HTMLElement)
      .firstElementChild as HTMLInputElement;

    if (!this.innerInput) {
      throw Error('radioLabel required inner input.');
    }

    this.value = this.innerInput.value;
  }
}
