import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject } from 'rxjs';
import _ from 'lodash';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';

import { Guid } from 'src/app/shared/helpers/guid';
import { Exception } from 'src/app/shared/models/exception';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { uniqByKey } from 'src/app/shared/validators/uniq-by-key';
import { currencyValueRequiredValidator } from 'src/app/shared/validators/currency-value-required';

import {
  ProjectTariff,
  ProjectTariffRate,
} from 'src/app/projects/card/project-tariffs/models/project-tariff.model';
import { ProjectTariffsService } from 'src/app/projects/card/project-tariffs/services/project-tariffs.service';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';

@Component({
  selector: 'tmt-project-tariff-modal',
  templateUrl: './project-tariff-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectTariffModalComponent implements OnInit {
  @Input() public tariff: ProjectTariff;

  public project: Project;
  public currencies: Currency[];
  public isSaving$ = new BehaviorSubject<boolean>(false);
  public isLoading$ = new BehaviorSubject<boolean>(true);
  public isRateDateNotUniq: boolean;

  public form: UntypedFormGroup = this.fb.group({
    id: Guid.generate(),
    name: ['', Validators.required],
    description: null,
    assignments: null,
    isActive: true,
    initialRate: this.fb.group({
      id: Guid.generate(),
      currencyId: [null],
      value: [null],
      effectiveDate: [null],
    }),
    rates: this.fb.array([]),
  });

  public get rates(): UntypedFormArray {
    return this.form.controls['rates'] as UntypedFormArray;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public projectId: string,
    public projectCardService: ProjectCardService,
    public projectTariffsService: ProjectTariffsService,
    public projectVersionCardService: ProjectVersionCardService,
    private notificationService: NotificationService,
    private dataService: DataService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    const ratesForm = this.form.get('rates');
    ratesForm.addValidators(() =>
      uniqByKey(this.form.get('rates') as UntypedFormArray, 'effectiveDate'),
    );
    ratesForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.isRateDateNotUniq = ratesForm.hasError('notUniq');
      });

    this.project = this.projectCardService.project;
    this.currencies = this.projectTariffsService.currencies;

    if (this.tariff) {
      this.form.patchValue(this.tariff);
      this.getProjectTariffRates();
    } else {
      this.form.get('initialRate').patchValue({
        currencyId: this.project.currency.id,
        value: {
          value: 0,
          currencyCode: this.project.currency.alpha3Code,
        },
      });
      this.isLoading$.next(false);
    }

    if (this.projectTariffsService.readonly) {
      this.form.disable();
    }
  }

  /** Accepts of modal window.*/
  public ok(): void {
    this.form.markAllAsTouched();

    if (this.isRateDateNotUniq) {
      this.notificationService.warningLocal(
        'projects.projects.tariffs.messages.dateNotUniq',
      );

      return;
    }

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );

      return;
    }

    this.isSaving$.next(true);

    const projectTariff = this.projectTariffsService.prepareTariffData(
      this.form.getRawValue(),
    );

    if (this.tariff) {
      this.updateTariff(projectTariff);
    } else {
      this.createTariff(projectTariff);
    }
  }

  /** Closes modal window without save. */
  public cancel(): void {
    this.activeModal.dismiss();
  }

  private getProjectTariffRates(): void {
    this.dataService
      .collection('ProjectTariffs')
      .query<ProjectTariff>({
        select: ['id'],
        expand: [
          {
            rates: {
              select: [
                'currencyId',
                'effectiveDate',
                'expiryDate',
                'id',
                'isActive',
                'projectTariffId',
                'value',
              ],
            },
          },
        ],
        filter: [
          {
            id: {
              type: 'guid',
              value: this.tariff.id,
            },
          },
        ],
      })
      .subscribe({
        next: (data) => {
          this.tariff.rates = data[0].rates;
          this.tariff.rates = _.orderBy(this.tariff.rates, ['effectiveDate']);
          this.tariff.rates.forEach((rate) => {
            const code = this.currencies.find(
              (currency) => currency.id === rate.currencyId,
            )?.alpha3Code;

            if (!rate.effectiveDate) {
              this.form.get('initialRate').patchValue({
                ...rate,
                value: {
                  value: rate.value,
                  currencyCode: code,
                },
              });
            } else {
              (this.form.get('rates') as UntypedFormArray).insert(
                0,
                this.getRateFormGroup({
                  ...rate,
                  value: {
                    value: rate.value,
                    currencyCode: code,
                  },
                }),
              );
            }
          });

          this.isLoading$.next(false);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isLoading$.next(false);
        },
      });
  }

  private updateTariff(tariffDTO: Partial<ProjectTariff>): void {
    this.dataService
      .collection('ProjectTariffs')
      .entity(this.form.value.id)
      .update(tariffDTO)
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'projects.projects.tariffs.messages.edited',
          );
          this.activeModal.close(tariffDTO);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving$.next(false);
        },
      });
  }

  private createTariff(tariffDTO: Partial<ProjectTariff>): void {
    this.dataService
      .collection('ProjectTariffs')
      .insert(tariffDTO)
      .subscribe({
        next: (tariff) => {
          this.notificationService.successLocal(
            'projects.projects.tariffs.messages.created',
          );
          this.activeModal.close(tariff);
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving$.next(false);
        },
      });
  }

  private getRateFormGroup(
    rate?: Partial<ProjectTariffRate> | any,
  ): UntypedFormGroup {
    return this.fb.group({
      id: rate?.id ?? Guid.generate(),
      currencyId: rate?.currencyId,
      value: [rate?.value, currencyValueRequiredValidator()],
      effectiveDate: [rate?.effectiveDate, Validators.required],
    });
  }
}
