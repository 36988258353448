import { Component, Input } from '@angular/core';
import { StateService } from '@uirouter/core';
import { BillingContact } from 'src/app/shared/models/entities/settings/multitenant/billing-contact.model';

@Component({
  selector: 'tmt-billing-info',
  templateUrl: './billing-info.component.html',
  styleUrls: ['./billing-info.component.scss'],
  standalone: false,
})
export class BillingInfoComponent {
  @Input() billingContact: BillingContact;

  constructor(private state: StateService) {}

  editBillingInfo() {
    this.state.go('settings.settings.billing');
  }
}
