import { Renderer2, WritableSignal } from '@angular/core';
import { Node } from 'prosemirror-model';
import _ from 'lodash';

export class MentionView {
  public dom: HTMLElement;

  private clickListener: () => void;

  constructor(
    private node: Node,
    private renderer: Renderer2,
    private mentionIds: WritableSignal<string[]>,
  ) {
    this.dom = renderer.createElement('span');
    this.dom.textContent = node.attrs.label;
    renderer.addClass(this.dom, 'mention');

    if (node.attrs.id) {
      setTimeout(() => {
        mentionIds.update((mentions) => _.uniq(mentions.concat(node.attrs.id)));
      });
    }

    this.clickListener = renderer.listen(this.dom, 'click', () => {
      console.log(node.attrs);
    });
  }

  public stopEvent(event: any): boolean {
    return event.type === 'click';
  }

  public destroy(): void {
    if (this.node.attrs.id) {
      this.mentionIds.update((mentions) =>
        mentions.filter((id) => id !== this.node.attrs.id),
      );
    }

    this.clickListener();
  }
}
