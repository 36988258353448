import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { ExpensesAccountFilterSettings } from '../../models/expenses-account-filter.settings';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { ExpensesAccountFilterSettingsForm } from '../model/expenses-account-filter-settings-form.model';
import { filter } from 'rxjs/operators';

/** Provides methods to work with Expense Financial Accounts filter. */
@Injectable()
export class ExpensesAccountFilterService {
  /** Gets Financial Account display settings. */
  public get settings(): ExpensesAccountFilterSettings {
    return this._settings;
  }

  private _changesSubject = new Subject<void>();
  public changes$ = this._changesSubject.asObservable();

  private _accountNamesNotIncludedInBalanceSubject = new BehaviorSubject<
    FinancialAccount[]
  >(null);
  public accountNamesNotIncludedInBalance$ =
    this._accountNamesNotIncludedInBalanceSubject
      .asObservable()
      .pipe(filter((v) => !!v));

  private readonly _settings: ExpensesAccountFilterSettings;

  constructor(private localConfigService: LocalConfigService) {
    this._settings = this.localConfigService.getConfig(
      ExpensesAccountFilterSettings,
    );
  }

  /**
   * Builds OData filter for {@link FinancialAccount} entity.
   *
   * @returns OData filter for {@link FinancialAccount}.
   */
  public buildAccountsFilter(): object[] {
    if (!this._settings || !this._accountNamesNotIncludedInBalanceSubject.value)
      return [];

    const queryFilter: object[] = [];

    const includedInBalanceFilterItem = { ['account/includedInBalance']: true };
    const accountIdsNotIncludedInBalance =
      this._accountNamesNotIncludedInBalanceSubject.value
        .filter((account) =>
          this._settings.includeFinAccountNamesNotIncludedInBalance.includes(
            account.name,
          ),
        )
        .map((account) => account.id);

    if (accountIdsNotIncludedInBalance.length) {
      const accountIdsFilter = {
        accountId: {
          in: { type: 'guid', value: accountIdsNotIncludedInBalance },
        },
      };
      queryFilter.push({
        or: {
          ...accountIdsFilter,
          ...includedInBalanceFilterItem,
        },
      });
    } else {
      queryFilter.push(includedInBalanceFilterItem);
    }

    return queryFilter;
  }

  /**
   * Triggers {@link FinancialAccount.name} (which are not included in balance) list update.
   *
   * @param accountsNotIncludedInBalance List of {@link FinancialAccount.name} (which are not included in balance).
   */
  public updateAccountsNotIncludedInBalance(
    accountsNotIncludedInBalance: FinancialAccount[],
  ): void {
    this._accountNamesNotIncludedInBalanceSubject.next(
      accountsNotIncludedInBalance,
    );
  }

  /**
   * Saves the settings and propagates the change to other components.
   *
   * @param settings Financial Account filter settings form value.
   * @param propagateChange Determines whether to propagate the change to other components or not.
   */
  public saveSettings(
    settings: ExpensesAccountFilterSettingsForm,
    propagateChange = true,
  ) {
    this._settings.includeLaborCost = settings.includeLaborCost;
    const accountItems = settings.accountNamesNotIncludedInBalance;
    this._settings.includeFinAccountNamesNotIncludedInBalance = Object.keys(
      accountItems,
    )
      .filter((key) => accountItems[key])
      .map((key) => key);

    this.localConfigService.setConfig(
      ExpensesAccountFilterSettings,
      this._settings,
    );

    if (propagateChange) {
      this._changesSubject.next();
    }
  }
}
