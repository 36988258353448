import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import _ from 'lodash';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Exception } from 'src/app/shared/models/exception';
import { ListService } from 'src/app/shared/services/list.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { Contact } from 'src/app/contacts/model/contact.model';
import { AddEntitiesModalComponent } from 'src/app/shared/components/add-entities-modal/add-entities-modal.component';
import { DealContact } from 'src/app/deals/model/deal.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ContactListFilterService } from 'src/app/contacts/list/contact-list-filter/contact-list-filter.service';
// eslint-disable-next-line max-len
import { DealAddContactsModalToolbarComponent } from 'src/app/deals/card/deal-contacts/deal-add-contacts-modal/deal-add-contacts-modal-toolbar/deal-add-contacts-modal-toolbar.component';
import { DEAL_CONTACTS } from 'src/app/deals/card/deal-contacts/model/deal-contacts.list';

/** Adding contacts modal window. */
@Component({
  selector: 'tmt-deal-add-contacts-modal',
  templateUrl:
    '../../../../shared/components/add-entities-modal/add-entities-modal.component.html',
  styleUrls: [
    '../../../../shared/components/add-entities-modal/add-entities-modal.component.scss',
  ],
  providers: [
    { provide: FilterService, useClass: ContactListFilterService },
    GridService,
    ListService,
    { provide: VIEW_NAME, useValue: 'addContactsModal' },
    { provide: LIST, useValue: DEAL_CONTACTS },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DealAddContactsModalComponent extends AddEntitiesModalComponent {
  protected override fieldToSortOnClient = 'name';
  protected override selectionLimitWarning =
    'components.dealAddContactsModalComponent.props.30limit';
  protected override modalWindowHeader =
    'components.dealAddContactsModalComponent.props.header';
  protected override noDisplayDataMessage =
    'components.dealAddContactsModalComponent.props.noDisplayData';
  protected override hasAvatar = false;

  private readonly filterServiceInstance = this
    .filterService as ContactListFilterService;
  private readonly customFieldService = inject(CustomFieldService);

  constructor() {
    super();

    this.filterServiceInstance.hasClient = false;
    this.filterServiceInstance.placeholder =
      'components.dealAddContactsModalToolbarComponent.filter.placeholder';
    this.filterServiceInstance.filterFields = [
      'name',
      'position',
      'role/name',
      'email',
      'mobilePhone',
      'phone',
    ];

    this.gridOptions.toolbar = DealAddContactsModalToolbarComponent;
  }

  /** Saves data. */
  protected override ok(): void {
    this.isSaving.set(true);

    const team = [];
    this.selectedEntities.forEach((contact: Contact) => {
      const member = {
        contactId: contact.id,
      };

      team.push(member);
    });

    this.isSaving.set(false);
    this.activeModal.close(team);
  }

  /** Loads data. */
  protected override load(): void {
    this.availableEntities = [];
    this.isLoading.set(true);
    this.loadedPartly.set(false);

    const listQuery = this.listService.getODataQuery(0, 50);

    const oDataParams = {
      select: [],
      expand: [{ role: { select: ['id', 'name'] } }],
      filter: [],
      orderBy: ['firstName'],
      top: this.loadingLimit,
    };

    oDataParams.select.push(...listQuery.select);
    oDataParams.filter.push(this.filterService.getODataFilter());

    if (this.parentEntityId) {
      oDataParams.filter.push({
        organizationId: { type: 'guid', value: this.parentEntityId },
      });
    }

    if (this.selectedEntities?.length) {
      oDataParams.filter.push([
        `not (id in (${this.selectedEntities.map((r) => r.id).join(',')}))`,
      ]);
    }

    if (this.existedEntities?.length) {
      oDataParams.filter.push([
        `not (id in (${this.existedEntities.map((r) => (r as DealContact)?.contactId).join(',')}))`,
      ]);
    }

    this.dataService
      .collection('Contacts')
      .query(oDataParams)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (contacts: Contact[]) => {
          this.formArray.clear();

          this.availableEntities = contacts.filter(
            (contact) =>
              !this.selectedEntities.some((r) => r.id === contact.id),
          );

          this.availableEntities.forEach((contact) => {
            const formGroup = this.getEntityFormGroup(contact);
            this.formArray.push(formGroup);
          });

          this.isLoading.set(false);
          this.loadedPartly.set(
            this.availableEntities.length === this.loadingLimit,
          );
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading.set(false);
        },
      });
  }

  /**
   * Returns contact form group.
   *
   * @returns contact form group.
   */
  protected override getEntityFormGroup(entity: NamedEntity): UntypedFormGroup {
    const contact = entity as Contact;
    const group = this.listService.getFormGroupForRow(contact);
    this.customFieldService.enrichFormGroup(group, 'Contact');
    group.disable();

    return group;
  }
}
