import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Injector,
} from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';
import { BehaviorSubject, merge } from 'rxjs';
import { RateMatrix } from 'src/app/settings-app/rate-matrix/model/rate-matrix.model';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { RateMatrixService } from 'src/app/settings-app/rate-matrix/card/rate-matrix.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Analytics } from 'src/app/settings-app/rate-matrix/card/structure-change-modal/rate-matrix-structure.model';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { RateMatrixStructureChangeModalComponent } from '../structure-change-modal/rate-matrix-structure-change-modal.component';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';

@Component({
  selector: 'tmt-main-matrix', // TODO: rename selector & files.
  templateUrl: './main-matrix.component.html',
  styleUrl: './main-matrix.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class MainRateMatrixComponent implements OnInit {
  @Input() public entityId: string;

  public activeTab: string;
  public readonly: boolean;
  public rateMatrixForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    effectiveDate: null,
    expiryDate: null,
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: false,
    rateMatrixStructure: [null],
    type: [null],
  });
  public rateMatrixStructureTitle?: string;
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public readonly commentedEntityCollectionType = CommentedEntityCollectionType;

  private destroyRef = inject(DestroyRef);

  public get rateMatrixStructure(): Analytics[] {
    return this.rateMatrixForm.getRawValue().rateMatrixStructure;
  }

  constructor(
    public actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private rateMatrixService: RateMatrixService,
    private modalService: NgbModal,
    private translate: TranslateService,
    private cdr: ChangeDetectorRef,
    private injector: Injector,
    private lifecycleService: LifecycleService,
  ) {}

  public ngOnInit(): void {
    this.actionService.action('save').show();
    this.actionService.action('buildLines').hide();
    this.actionService.run$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.save();
      });
    this.actionService.setHasAutosave(false);

    this.load();

    merge(this.lifecycleService.reload$, this.actionService.reload$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
        this.lifecycleService.reloadLifecycle();
      });

    FormHelper.controlDatePair(
      this.rateMatrixForm,
      'effectiveDate',
      'expiryDate',
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /** Saves rate Matrix. */
  public async save(): Promise<void> {
    this.rateMatrixForm.markAllAsTouched();

    if (this.rateMatrixForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    if (
      this.rateMatrixService.changeStructureMessage &&
      !(await this.messageService
        .confirm(this.rateMatrixService.changeStructureMessage)
        .then(() => true)
        .catch(() => null))
    ) {
      return;
    }

    this.actionService.action('save').start();

    this.rateMatrixService
      .patchRateMatrix(this.entityId, this.rateMatrixForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.rateMatrixService.rateMatrixStructure = this.rateMatrixForm
            .getRawValue()
            .rateMatrixStructure.map((key) => _.camelCase(key) as Analytics);
          this.rateMatrixService.changeStructureMessage = null;
          this.rateMatrixForm.markAsPristine();
          this.actionService.action('save').stop();
          this.rateMatrixService.updateName(this.rateMatrixForm.value.name);
          this.cdr.markForCheck();
          this.notificationService.successLocal(
            'settings.rateMatrices.card.messages.saved',
          );
        },
        error: (error: any) => {
          this.actionService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /** Opens matrix rates settings. */
  public openMatrixRatesSettings(): void {
    const modalRef = this.modalService.open(
      RateMatrixStructureChangeModalComponent,
      {
        injector: this.injector,
      },
    );
    const component =
      modalRef.componentInstance as RateMatrixStructureChangeModalComponent;
    component.rateMatrixStructure = this.rateMatrixStructure;
    component.readonly = this.readonly;

    modalRef.result.then(
      (result) => {
        this.rateMatrixForm.patchValue({
          rateMatrixStructure: result,
        });
        this.rateMatrixForm.markAsDirty();
        this.initRateMatrixStructureTitle();
        this.cdr.markForCheck();
      },
      () => {
        this.rateMatrixService.changeStructureMessage = null;
      },
    );
  }

  private load(): void {
    this.state$.next(CardState.Loading);

    this.rateMatrixForm.markAsPristine();
    this.rateMatrixForm.markAsUntouched();

    this.rateMatrixService.getRateMatrices(this.entityId).subscribe({
      next: (matrixRate: RateMatrix) => {
        this.rateMatrixService.changeStructureMessage = null;
        this.rateMatrixForm.patchValue(matrixRate);
        this.initRateMatrixStructureTitle();
        this.readonly = !matrixRate.editAllowed;
        this.actionService.action('save').isShown = !this.readonly;
        this.readonly
          ? this.rateMatrixForm.disable()
          : this.rateMatrixForm.enable();

        this.rateMatrixForm.controls.expiryDate.disable();
        this.rateMatrixForm.controls.type.disable();
        this.rateMatrixForm.controls.isActive.disable();

        this.state$.next(CardState.Ready);

        this.navigationService.addRouteSegment({
          id: matrixRate.id,
          title: matrixRate.name,
        });
      },
      error: (error: any) => {
        this.state$.next(CardState.Error);
        this.notificationService.error(error.message);
      },
    });
  }

  /* Reloads view. */
  private reload(): void {
    if (!this.rateMatrixForm.dirty) {
      this.load();
    } else {
      this.messageService.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private initRateMatrixStructureTitle(): void {
    if (!this.rateMatrixStructure?.length) {
      this.rateMatrixStructureTitle = this.translate.instant(
        'settings.rateMatrices.changeStructure.placeholder',
      );

      return;
    }

    this.rateMatrixStructureTitle = this.rateMatrixStructure
      .map((analytic) =>
        this.translate.instant(`shared.rateMatrices.${_.camelCase(analytic)}`),
      )
      .join(' > ');
  }
}
