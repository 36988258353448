import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs';

import { LocalDragDropService } from 'src/app/shared/services/drag-drop';

import { BoardColumnView } from 'src/app/boards/models/board.interface';
import { BoardService } from 'src/app/boards/services/board.service';

@Component({
  selector: 'tmt-board-column-header',
  templateUrl: './board-column-header.component.html',
  styleUrl: './board-column-header.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BoardColumnHeaderComponent implements OnInit {
  @Input() public column: BoardColumnView;

  private destroyRef = inject(DestroyRef);

  constructor(
    public boardService: BoardService,
    public el: ElementRef<HTMLElement>,
    private dragDropService: LocalDragDropService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.dragDropService.completed$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    this.boardService.event$
      .pipe(
        filter(
          (event) => event.target === 'column' && event.id === this.column.id,
        ),
      )
      .subscribe((event) => {
        switch (event.action) {
          case 'updated':
            this.cdr.markForCheck();
        }
      });
  }
}
