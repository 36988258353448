import { Inject, Injectable, Injector } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { PlannerCommandService } from 'src/app/shared-features/planner/core/planner-command.service';
import { Command } from 'src/app/shared-features/planner/models/command.model';
import { projectTaskWarnings } from 'src/app/shared/models/entities/projects/project-task.model';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import {
  ResourceViewGroup,
  ResourceViewGroupLine,
} from 'src/app/projects/card/project-resources/models/project-resources-view.model';
import { MovePlanFormComponent } from 'src/app/projects/card/project-resources/move-plan-form/move-plan-form.component';

@Injectable()
export class ProjectResourcesCalendarCommandService {
  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  constructor(
    @Inject('entityId') public projectId,
    private modalService: NgbModal,
    private message: MessageService,
    private translate: TranslateService,
    private blockUI: BlockUIService,
    private notification: NotificationService,
    private autosave: SavingQueueService,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
    private plannerCommandService: PlannerCommandService,
    private notificationService: NotificationService,
    private dataService: ProjectResourceDataService,
  ) {
    plannerCommandService.command$.subscribe((command) => {
      this.execute(command);
    });
  }

  public getGroupCommands(group: ResourceViewGroup): Command[] {
    const commands: Command[] = [];
    return commands;
  }

  public getLineCommands(
    group: ResourceViewGroup,
    line: ResourceViewGroupLine,
  ): Command[] {
    const commands: Command[] = [];

    if (!this.dataService.readonly) {
      commands.push({
        title: this.translate.instant(
          'projects.projects.card.resources.actions.removeAll',
        ),
        name: 'removeResourcePlan',
        line,
        group,
      });
    }

    return commands;
  }

  public getRequestCommands(): Command[] {
    const commands: Command[] = [];
    return commands;
  }

  /** Выполнить команду. */
  public execute(command: Command) {
    this.autosave.save().then(() => {
      switch (command.name) {
        case 'removeResourcePlan':
          this.removeResourcePlan(command['group'], command['line']);
          break;
      }
    });
  }

  /** Удалить все данные распределения плана с члена команды. */
  public removeResourcePlan(
    group: ResourceViewGroup,
    line: ResourceViewGroupLine,
  ) {
    this.message
      .confirmLocal(
        'components.projectResourcesCalendarCommandService.messages.deletionConfirm',
      )
      .then(() => {
        this.blockUI.start();

        const data = {
          taskId: line.taskId,
          teamMemberId: group.teamMember.id,
        };

        return this.versionDataService
          .projectCollectionEntity(
            this.versionCardService.projectVersion,
            this.projectId,
          )
          .action('ClearResourcePlan')
          .execute(data, undefined, {
            undoRedoSessionId: this.dataService.undoRedoSessionId,
          })
          .subscribe({
            next: () => {
              this.notification.successLocal(
                'components.projectResourcesCalendarCommandService.messages.entriesWereDeleted',
              );
              this.blockUI.stop();
              group.extraTotal -= line.extraTotal;
              group.totalHours -= line.totalHours;
              group.totalCost -= line.totalCost;
              line.extraTotal = 0;
              line.totalHours = 0;
              line.totalCost = 0;
              line.entries.forEach((entry) => {
                entry.hours = 0;
                entry.cost = 0;
              });
              this.changesSubject.next();
            },
            error: (error: Exception) => {
              this.notification.error(error.message);
              this.blockUI.stop();
            },
          });
      });
  }

  /** Сместить план. */
  public movePlan(
    projectId: string,
    teamMembers: ProjectTeamMember[],
  ): Promise<void> {
    const injector = Injector.create({
      providers: [
        {
          provide: ProjectVersionCardService,
          useValue: this.versionCardService,
        },
      ],
    });
    const ref = this.modalService.open(MovePlanFormComponent, {
      size: 'lg',
      injector,
    });
    const instance = ref.componentInstance as MovePlanFormComponent;
    instance.teamMembers = teamMembers;
    instance.projectId = projectId;
    return ref.result;
  }

  /** Очистить план. */
  public clearPlan() {
    this.message
      .confirmLocal('projects.actions.clearDataConfirmationUndoAble')
      .then(() => {
        this.blockUI.start();

        return this.versionDataService
          .projectCollectionEntity(
            this.versionCardService.projectVersion,
            this.projectId,
          )
          .action('ClearResourcePlan')
          .execute({}, undefined, {
            undoRedoSessionId: this.dataService.undoRedoSessionId,
          })
          .subscribe({
            next: () => {
              this.notification.successLocal(
                'shared2.messages.dataSuccessfullyDeleted',
              );
              this.blockUI.stop();
              this.changesSubject.next();
            },
            error: (error: Exception) => {
              this.notification.error(error.message);
              this.blockUI.stop();
            },
          });
      });
  }

  /** Shows warnings.
   *
   * @param warnings warnings after saving.
   */
  public showWarnings(warnings: { [key: string]: boolean }): void {
    projectTaskWarnings.forEach((warning) => {
      if (warnings[warning]) {
        this.notificationService.warningLocal(
          `components.projectTaskWarnings.${warning}`,
        );
      }
    });
  }
}
