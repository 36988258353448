import { Component, Input } from '@angular/core';
import { GridNavigationColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-user-cell',
  templateUrl: './user-cell.component.html',
  styleUrls: ['./user-cell.component.scss'],
  standalone: false,
})
export class UserCellComponent {
  @Input() row: any;
  @Input() column: GridNavigationColumn;
}
