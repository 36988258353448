import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { TimeOffBalanceEntryModes } from 'src/app/shared/models/entities/team/time-off-balance-entry-mode.enum';
import { TimeOffBalanceEntryTypes } from 'src/app/shared/models/entities/team/time-off-balance-entry-type.enum';

@Component({
  selector: 'wp-time-off-balance-entry-filter',
  templateUrl: './time-off-balance-entry-filter.component.html',
  standalone: false,
})
export class TimeOffBalanceEntryFilterComponent
  implements OnInit, OnDestroy, FilterDetails
{
  @Input() values: any;

  public timeOffBalanceEntryTypes = TimeOffBalanceEntryTypes;

  public timeOffBalanceEntryModes = TimeOffBalanceEntryModes;

  private destroyed$ = new Subject<void>();

  detailsForm = this.fb.group({
    period: null,
    timeOffType: null,
    user: null,
    type: null,
    mode: null,
  });

  constructor(
    private fb: UntypedFormBuilder,
    public service: FilterService,
    private translateService: TranslateService,
  ) {}

  ngOnInit() {
    this.timeOffBalanceEntryTypes.forEach((type) => {
      type.name = this.translateService.instant(type.name);
    });

    this.timeOffBalanceEntryModes.forEach((mode) => {
      mode.name = this.translateService.instant(mode.name);
    });

    this.service.resetValues$.subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const values = Object.assign(
          this.service.values,
          this.detailsForm.value,
        );
        this.service.changeValues(values);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
