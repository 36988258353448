import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'wp-permission-set-administrator',
  templateUrl: './permission-set-administrator.component.html',
  standalone: false,
})
export class PermissionSetAdministratorComponent {
  @Input() form: UntypedFormGroup;
  public onprem = !!environment.onprem;
}
