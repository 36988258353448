import { wrappingInputRule } from 'prosemirror-inputrules';
import type { NodeType } from 'prosemirror-model';

/**
 * Given a list node type, returns an input rule that turns a number
 * followed by a dot or parenthesis at the start of a textblock into an ordered list.
 */
export function orderedListRule(nodeType: NodeType) {
  return wrappingInputRule(
    /^(\d+)([.)])\s$/,
    nodeType,
    (match) => ({
      order: Number(match[1]),
      markup: match[2],
    }),
    (match, node) => node.childCount + node.attrs.order === Number(match[1]),
  );
}

interface BulletListInputRuleConfig {
  /** @default true */
  dash?: boolean;
  /** @default true */
  asterisk?: boolean;
}

/**
 * Given a list node type, returns an input rule that turns a bullet
 * (dash or asterisk) at the start of a textblock into a
 * bullet list.
 */
export function bulletListRule(
  nodeType: NodeType,
  config?: BulletListInputRuleConfig,
) {
  const bullets: string[] = [];
  if (config?.dash !== false) bullets.push('-');
  if (config?.asterisk !== false) bullets.push('*');
  if (bullets.length === 0) return null;

  const regexp = new RegExp(`^\\s*([${bullets.join('')}])\\s$`); // same as /^\s*([-+*])\s$/
  return wrappingInputRule(regexp, nodeType, (match) => ({ bullet: match[1] }));
}
