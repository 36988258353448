import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { DealContactsService } from 'src/app/deals/card/deal-contacts/core/deal-contacts.service';
import { DEAL_CONTACTS } from 'src/app/deals/card/deal-contacts/model/deal-contacts.list';

@Component({
  selector: 'tmt-deal-contacts',
  templateUrl: './deal-contacts.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    DealContactsService,
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: DEAL_CONTACTS },
    ListService,
    GridService,
  ],
  standalone: false,
})
export class DealContactsComponent {
  public readonly dealContactsService = inject(DealContactsService);

  constructor() {
    this.dealContactsService.load();
  }
}
