<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-7">
      <div class="form-group">
        <label>{{ 'timesheets.list.filter.properties.period.label' | translate }}</label>
        <wp-date-period-box
          formControlName="period"
          placeholder="{{ 'timesheets.list.filter.properties.period.placeholder' | translate }}"
        >
        </wp-date-period-box>
      </div>

      <div
        class="form-group"
        *ngIf="viewName == 'team-all' || viewName == 'team-expired' || viewName == 'with-my-task'"
      >
        <label>{{ 'timesheets.list.filter.properties.user.label' | translate }}</label>
        <wp-user-box
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'timesheets.list.filter.properties.user.placeholder' | translate }}"
          formControlName="user"
        ></wp-user-box>
      </div>

      <div class="form-group" *ngIf="viewName == 'team-all' || viewName == 'team-expired'">
        <label>{{ 'timesheets.list.filter.properties.department.label' | translate }}</label>
        <tmt-hierarchical-box
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'timesheets.list.filter.properties.department.placeholder' | translate }}"
          collection="Departments"
          formControlName="department"
          parentIdKey="leadDepartmentId"
        />
      </div>
    </div>
    <div class="col-5">
      <tmt-state-select
        *ngIf="viewName == 'team-all' || viewName == 'my-all'"
        formControlName="states"
        collection="TimeSheets"
      />

      <div *ngIf="viewName == 'team-all' || viewName == 'team-expired'">
        <hr *ngIf="viewName == 'team-all'" />
        <div class="form-check">
          <input
            formControlName="onlySubordinates"
            type="checkbox"
            class="form-check-input"
            id="onlySubordinates"
          />
          <label class="form-check-label" for="onlySubordinates">
            {{ 'shared2.filters.onlySubordinates' | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>
</form>
