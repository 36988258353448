<ng-container [formGroup]="form">
  <span class="text-body-secondary">{{
    'settings.validationRules.card.props.scheduleWorkDayDurationHelp' | translate
  }}</span>
  <br />
  <br />

  <div class="form-group">
    <label>{{ 'settings.validationRules.card.props.limitationType' | translate }}</label>
    <br />
    <div
      class="btn-group btn-group-toggle"
      radioGroup
      name="radioBasic"
      formControlName="LimitationType"
    >
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" [value]="limitationType.ByDays" />{{
          'enums.limitationType.byDays' | translate
        }}
      </label>
      <label radioLabel class="btn-default trim fw-normal">
        <input radioButton type="radio" [value]="limitationType.ForEntireTimesheet" />{{
          'enums.limitationType.forEntireTimesheet' | translate
        }}
      </label>
    </div>
  </div>

  <div class="form-group">
    <label>{{ 'shared2.props.durationShortening' | translate }}</label>

    <wp-number-box type="work" [allowNull]="false" [min]="0" [max]="24" formControlName="LagDown">
    </wp-number-box>
  </div>

  <div class="form-group">
    <label>{{ 'shared2.props.durationExcess' | translate }}</label>
    <wp-number-box type="work" formControlName="LagUp" [allowNull]="false" [min]="0" [max]="24">
    </wp-number-box>
  </div>
</ng-container>
