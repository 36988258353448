import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  LOCALE_ID,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
  ViewRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import {
  DecimalPipe,
  getLocaleNumberSymbol,
  NumberSymbol,
  PercentPipe,
} from '@angular/common';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { round } from 'lodash';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { fromEvent, Subscription } from 'rxjs';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';

export type NumberBoxType =
  | 'decimal'
  | 'integer'
  | 'currency'
  | 'work'
  | 'percent';

/**
 * Represents number box control.
 * */
@Component({
  selector: 'wp-number-box',
  templateUrl: './number-box.component.html',
  styleUrls: ['./number-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => NumberBoxComponent),
      multi: true,
    },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NumberBoxComponent
  implements OnInit, AfterViewInit, OnChanges, ControlValueAccessor, OnDestroy
{
  /** Empty value placeholder. */
  @Input() placeholder: string;

  /**
   * Currency code.
   *
   * @remarks Only applicable for {@link type 'currency'}. Uses Base currency if not assigned.
   * */
  @Input() currencyCode: string;

  /** Determines whether <code>null</code> values are allowed or not. */
  @Input() allowNull = true;

  /** Value type. */
  @Input() type: NumberBoxType;

  /** Determines whether to select the input value after rendering or not. */
  @Input() autofocus?: boolean;

  /** Value precision. */
  @Input() set precision(value: number) {
    if (!isNaN(value)) {
      this._precision = value;
    }
  }

  get precision(): number {
    return this._precision;
  }

  /** Value alignment. */
  @Input() align: 'left' | 'right' = 'right';

  /** Determines whether input is readonly or not. */
  @Input() readonly = false;

  /** Value to render (without focus). */
  @Input() set viewValue(value: number) {
    this._viewValue = value;
    this._displayValue = this.getDisplayValue();
    if (!this.isFocused) {
      this.setInputValue();
    }
  }
  get viewValue(): number {
    return this._viewValue;
  }

  private _displayValue: string;
  public get displayValue(): string {
    return this._displayValue;
  }

  /** Min value restriction. */
  @Input() get min(): number {
    return this._min;
  }
  set min(value: number) {
    if (!isNaN(value)) {
      this._min = value;
    }
  }

  /** Max value restriction. */
  @Input() get max(): number {
    return this._max;
  }
  set max(value: number) {
    if (!isNaN(value)) {
      this._max = value;
    }
  }

  @Input() propagationMode: PropagationMode = PropagationMode.onInput;

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  /** Angular abstract control for binding to form outside of template. */
  @Input() control?: AbstractControl;

  private keyboardSubscription: Subscription;

  @ViewChild('editor')
  set editor(value: ElementRef) {
    this._editor = value;
    this.setInputValue();
  }
  get editor(): ElementRef {
    return this._editor;
  }

  private get input() {
    if (this.disabled) {
      return null;
    }
    return this.editor.nativeElement as HTMLInputElement;
  }

  public value: number = null;
  public disabled = false;

  private _viewValue: number;
  private previousViewValue: string;

  private _min = 0;
  private _max = 9999999999;
  private _precision = 2;

  private _editor: ElementRef;

  private readonly decimalSeparator: string;
  private isFocused: boolean;

  private selectInputValueOnFocus = true;

  private readonly percentValue = 100;

  // Uses with 'onExitFromEditing' propagationMode
  private lastEmittedValue: number;

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private app: AppService,
    private wpCurrencyPipe: WpCurrencyPipe,
    private numberPipe: DecimalPipe,
    private workPipe: WorkPipe,
    private percentPipe: PercentPipe,
    private ref: ChangeDetectorRef,
  ) {
    this.decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
  }

  ngOnInit(): void {
    if (this.type === 'integer') {
      this.max = 999999;
    }

    if (this.type === 'currency' && !this.currencyCode) {
      this.currencyCode = this.app.session.configuration.baseCurrencyCode;
    }

    if (this.placeholder == null) {
      this.placeholder = this.getFormattedValue(0);
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus && this.input) {
      this.input.focus();
    }
    this.applyInitialValue();
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['readonly']) {
      this.disabled = this.readonly;
      this.ref.markForCheck();
    }
  }

  public ngOnDestroy(): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.onBlur();
    }
    this.keyboardSubscription?.unsubscribe();
  }

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  writeValue(value: any): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      if (this.lastEmittedValue === value) {
        return;
      }
    }
    // eslint-disable-next-line eqeqeq
    this.value = value == undefined || value === '' ? null : value;
    this._displayValue = this.getDisplayValue();
    this.lastEmittedValue = this.value;
    this.setInputValue();
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (!this.readonly) {
      this.disabled = isDisabled;
    }

    this.ref.detectChanges();
  }

  setInputValue() {
    if (!this.editor || !this.input) {
      return;
    }

    if (this.isFocused) {
      this.input.value = this.getStringForEditing();
    } else {
      this.input.value = this._displayValue;
    }
  }

  /** Input onBlur logic. */
  public onBlur(): void {
    this.propagateTouch();
    if (this.input) {
      this.input.value = this._displayValue;
    }
    this.isFocused = false;
    if (
      this.propagationMode === PropagationMode.onExitFromEditing &&
      this.lastEmittedValue !== this.value
    ) {
      this.propagateChange(this.value);
      this.lastEmittedValue = this.value;
      this.keyboardSubscription?.unsubscribe();
    }
  }

  /** Input onFocus logic. */
  public onFocus(): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.initKeysSubscribes();
    }
    this.isFocused = true;
    this.input.value = this.getStringForEditing();
    this.previousViewValue = this.input.value;
    if (this.selectInputValueOnFocus) {
      this.input.select();
    }
    this.selectInputValueOnFocus = true;
  }

  handleInput(event: any) {
    const selectionStart = this.input.selectionStart;
    const selectionEnd = this.input.selectionEnd;
    let value = this.input.value;
    value = this.parseCurrencyString(value);

    const separators = [' ', '.', ','];

    separators
      .filter((x) => x !== this.decimalSeparator)
      .forEach((separator: string) => {
        const newValue =
          value.indexOf(this.decimalSeparator) === -1
            ? this.decimalSeparator
            : '';
        value = value.replace(separator, newValue);
      });

    const normalizedString: string = String(value).replace(
      this.decimalSeparator,
      '.',
    );
    if (isNaN(Number(normalizedString))) {
      value = this.previousViewValue;
    }

    if (value !== this.input.value) {
      this.input.value = value;
      this.input.setSelectionRange(selectionStart, selectionEnd);
    }

    if (normalizedString === '-') {
      value = '';
      this.input.value = normalizedString;
      this.input.setSelectionRange(selectionStart, selectionEnd);
    }

    this.previousViewValue = this.input.value;

    this.updateValueFromDisplayValue();
  }

  /** Apply initial value after rendering. */
  private applyInitialValue() {
    if (this.initialValue === undefined) {
      return;
    }
    if (this.input) {
      const event = new Event('input');
      if (typeof this.initialValue === 'string') {
        this.input.value = this.initialValue;
        this.input.dispatchEvent(event);
        this.selectInputValueOnFocus = false;
        this.input.focus();
      } else if (this.initialValue === null) {
        this.input.value = '';
        this.input.dispatchEvent(event);
        this.selectInputValueOnFocus = false;
        this.input.focus();
      }
    }
    this.initialValue = undefined;
  }

  /**
   * Gets displayed control value.
   *
   * @returns Displayed control value.
   * */
  getDisplayValue = (): string => {
    const value = this.viewValue == null ? this.value : this.viewValue;
    return this.getFormattedValue(value);
  };

  /**
   * Gets string value for user to edit inside input.
   *
   * @returns String value.
   * */
  private getStringForEditing(): string {
    const template = this.getDigitInfo();

    let valueStr = '';
    if (this.value != null) {
      valueStr =
        this.type === 'percent'
          ? this.numberPipe.transform(this.value * this.percentValue, template) // Show user percent value.
          : this.numberPipe.transform(this.value, template);
      valueStr = valueStr.replace(/\s+/g, '');
    }
    return valueStr;
  }

  private getDigitInfo(): string {
    let template = '';

    switch (this.type) {
      case 'percent':
        template = `0.0-${this.precision}`;
        break;
      case 'currency':
        template = `1.0-${this.precision}`;
        break;
      case 'integer':
        template = `1.0-0`;
        break;
      default:
        template = `1.0-${this.precision}`;
        break;
    }
    return template;
  }

  /**
   * Updates control value by displayed one.
   * */
  private updateValueFromDisplayValue(): void {
    let value = 0;
    let displayValue: string = this.input.value;
    const roundPrecision = this.type === 'integer' ? 0 : this.precision;

    if (displayValue || displayValue === '0') {
      displayValue = String(displayValue).replace(this.decimalSeparator, '.');

      if (displayValue.indexOf('.') === displayValue.length - 1) {
        displayValue = displayValue.substring(0, displayValue.length - 1);
      }

      // Get numeric value.
      const floatValue = parseFloat(displayValue);
      if (!isNaN(floatValue)) {
        value = round(floatValue, roundPrecision);

        if (value > this.max) {
          value = this.max;
        }

        if (value < this.min) {
          value = this.min;
        }

        // Save math percent value.
        if (this.type === 'percent') {
          value /= this.percentValue;
        }
      }
    } else {
      if (this.allowNull) {
        value = null;
      } else {
        value = this.min;
      }
    }

    if (this.value !== value) {
      this.value = value;
      this._displayValue = this.getDisplayValue();
      if (this.propagationMode === PropagationMode.onInput) {
        this.propagateChange(this.value);
        this.lastEmittedValue = this.value;
      }
    }
  }

  private getFormattedValue(value: number): string {
    if (!value && value !== 0) {
      return '';
    }
    if (this.type === 'currency') {
      return this.wpCurrencyPipe.transform(value, this.currencyCode);
    }

    if (this.type === 'work') {
      return this.workPipe.transform(value);
    }

    if (this.type === 'integer') {
      return this.numberPipe.transform(value, this.getDigitInfo());
    }

    if (this.type === 'percent') {
      return this.percentPipe.transform(value, this.getDigitInfo());
    }

    return this.numberPipe.transform(value, this.getDigitInfo());
  }

  private parseCurrencyString(value: string): string {
    // AXT-338. Convert to number.
    const trimmedValue = value.replace(/[^-,.\d]/gm, '');
    return trimmedValue;
  }

  /** Initializes keyboard listener. */
  private initKeysSubscribes() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
    this.keyboardSubscription = fromEvent(window, 'keydown').subscribe(
      (event: KeyboardEvent) => {
        if (!event.repeat) {
          switch (event.code) {
            case 'Enter':
            case 'NumpadEnter':
              if (this.lastEmittedValue !== this.value) {
                this.propagateChange(this.value);
                this.lastEmittedValue = this.value;
              }
              break;
          }
        }
        return;
      },
    );
  }
}
