import { Component, Input } from '@angular/core';
import { PlannerCommandService } from '../core/planner-command.service';
import { Command } from '../models/command.model';

@Component({
  selector: 'wp-planner-row-commands',
  templateUrl: './planner-row-commands.component.html',
  styleUrls: ['./planner-row-commands.component.scss'],
  standalone: false,
})
export class PlannerRowCommandsComponent {
  @Input() rowState: any;

  constructor(private commandService: PlannerCommandService) {}

  public openRowMenu(opened: boolean) {
    this.rowState.isMenuOpened = opened;
  }

  /** Команда от строки. */
  public execute(command: Command) {
    this.commandService.execute(command);
  }
}
