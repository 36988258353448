<tmt-form-header [name]="departmentForm.value.name" />
<wp-loading-indicator [state]="state$ | async" />
<tmt-not-found [state]="state$ | async" />
<wp-data-not-saved-indicator [form]="departmentForm" />

<form
  [formGroup]="departmentForm"
  [hidden]="(state$ | async) === 'Loading'"
  class="form mt-3"
  style="width: 600px"
>
  <div class="form-group">
    <label>{{ 'shared.props.name' | translate }}</label>
    <wp-text-box formControlName="name" placeholder="{{ 'shared.props.name' | translate }}" />
  </div>

  <div class="form-group" style="width: 150px">
    <label>{{ 'shared.props.code' | translate }}</label>
    <wp-text-box formControlName="code" placeholder="{{ 'shared.props.code' | translate }}" />
  </div>

  <div class="form-group">
    <label>{{ 'settings.departments.card.props.leadDepartment.label' | translate }}</label>
    <tmt-hierarchical-box
      formControlName="leadDepartment"
      placeholder="{{ 'settings.departments.card.props.leadDepartment.ph' | translate }}"
      collection="Departments"
      parentIdKey="leadDepartmentId"
    />
  </div>

  <div class="form-group">
    <label>{{ 'settings.departments.card.props.role.label' | translate }}</label>
    <wp-select-box
      collection="Roles"
      formControlName="role"
      placeholder="{{ 'settings.departments.card.props.role.ph' | translate }}"
    />
  </div>

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>

  @if (!readonly) {
    <div class="actions">
      <button
        type="button"
        class="btn btn-primary"
        wpButtonSpinner
        [isBusy]="isSaving$ | async"
        (click)="save()"
      >
        {{ 'shared.actions.save' | translate }}
      </button>
    </div>
  }
</form>
