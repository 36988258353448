import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { DimensionItem } from 'src/app/shared/models/enums/dimension-item.enum';

@Component({
  selector: 'tmt-duration-box-test',
  templateUrl: './duration-box-test.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DurationBoxTestComponent implements OnInit, OnDestroy {
  public dimensionItems = DimensionItem;

  private formChangeSubscription: Subscription;

  form = this.fb.group({
    duration: [null, Validators.required],
    duration2: [null, Validators.required],
    durationMonths: [null, Validators.required],
    durationWeeks: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.formChangeSubscription = this.form.valueChanges.subscribe(() =>
      this.cdr.detectChanges(),
    );
  }
  ngOnDestroy(): void {
    this.formChangeSubscription.unsubscribe();
  }

  /** Set value to the control. */
  setValue(value: number) {
    this.form.setValue({
      duration: value,
      duration2: value,
      durationMonths: value,
      durationWeeks: value,
    });
  }
}
