import { Component, Input, ChangeDetectorRef } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormGroup,
  UntypedFormBuilder,
} from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'wp-custom-fields-section',
  templateUrl: './custom-fields-section.component.html',
  providers: [GridService],
  standalone: false,
})
export class CustomFieldsSectionComponent {
  @Input() formArray: UntypedFormArray;
  @Input() readonly: boolean;

  public customFields: NamedEntity[] = [];
  public loading = false;

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'add',
        handlerFn: (customField: NamedEntity) =>
          this.addCustomField(customField),
        allowedFn: () => !this.readonly,
      },
      {
        name: 'delete',
        handlerFn: (id) => null,
        allowedFn: (id) => id && !this.readonly,
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.formArray.removeAt(index);
          this.formArray.parent.markAsDirty();
        },
      },
    ],
    view: {
      name: 'validationRules',
      columns: [
        {
          name: 'name',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  constructor(
    private fb: UntypedFormBuilder,
    private ref: ChangeDetectorRef,
    public service: GridService,
    public notification: NotificationService,
    private data: DataService,
  ) {}

  openChange($event: boolean) {
    if (!$event) {
      return;
    }

    this.loading = true;
    this.customFields = [];
    const params = {
      filter: [
        { isActive: true },
        {
          or: [
            { entityType: 'TimeSheetLine' },
            { entityType: 'TimeAllocation' },
          ],
        },
      ],
      select: ['name', 'id'],
      orderBy: ['name'],
    };

    this.data
      .collection('CustomFields')
      .query(params)
      .subscribe({
        next: (customFields: NamedEntity[]) => {
          const currentFields = this.formArray.value as NamedEntity[];

          customFields.forEach((field: NamedEntity) => {
            if (
              currentFields.every(
                (currentRule: any) => currentRule.id !== field.id,
              )
            ) {
              this.customFields.push(field);
            }
          });

          this.loading = false;
          this.ref.detectChanges();
        },
        error: (error: any) => {
          this.loading = false;
          this.notification.error(error.message);
          this.ref.detectChanges();
        },
      });
  }

  public addCustomField(customField: NamedEntity): void {
    const group = this.fb.group({
      id: customField.id,
      name: customField.name,
    });
    this.formArray.push(group);
    group.markAsDirty();
  }
}
