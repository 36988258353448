import { Pipe, PipeTransform } from '@angular/core';
import _ from 'lodash';

@Pipe({
  name: 'path',
  standalone: false,
})
export class PathPipe implements PipeTransform {
  transform(value: any, path: string): any {
    if (!path) {
      return value;
    }
    return _.get(value, path);
  }
}
