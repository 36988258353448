import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserFilterService } from './user-filter/user-filter.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { UserCreationComponent } from '../creation/user-creation.component';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { UsersEditingComponent } from './users-editing/users-editing.component';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-user-list',
  template: '<wp-entity-list></wp-entity-list>',
  providers: [
    { provide: FilterService, useClass: UserFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  standalone: false,
})
export class UserListComponent implements OnInit, OnDestroy {
  selectedRowListener: Subscription;
  constructor(
    private filterService: FilterService,
    private listService: EntityListService,
    private state: StateService,
    private app: AppService,
    private actions: ActionPanelService,
    private gridService: GridService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'settings.users.list.actions.create',
        isVisible: this.app.checkEntityPermission(
          'User',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('settings.user', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.listService.delete,
      }),
      {
        title: 'shared.groupEditing.actions.change.title',
        hint: 'shared.groupEditing.actions.change.hint',
        name: 'edit',
        isDropDown: false,
        isVisible: true,
        isBusy: false,
        handler: this.edit,
      },
    ]);

    this.selectedRowListener = this.gridService.selectedGroups$.subscribe(
      (rows: any[]) => {
        this.actions.action('card').isShown = rows?.length === 1;

        this.actions.action('edit').isShown =
          rows?.length > 0 &&
          this.app.checkEntityPermission('User', PermissionType.Modify);
      },
    );
  }

  ngOnDestroy(): void {
    this.selectedRowListener.unsubscribe();
  }

  private create = () => {
    this.modalService.open(UserCreationComponent);
  };

  private edit = () => {
    const ref = this.modalService.open(UsersEditingComponent);
    const instance = ref.componentInstance as UsersEditingComponent;

    if (this.gridService.isAllSelected) {
      instance.filter = this.filterService.getODataFilter();
    } else {
      instance.rows = this.gridService.selectedGroupsValue.map((g) => ({
        id: g.id,
        name: g.name,
      }));
    }

    ref.result.then(
      () => this.listService.reload(),
      () => null,
    );
  };
}
