import { setBlockType } from 'prosemirror-commands';
import { Command, EditorState } from 'prosemirror-state';
import { toParagraph } from 'src/app/shared/components/controls/rich-editor-box/markdown/heading/consts';
import { MenuItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';

/**
 * Resets heading block type (uses on Backspace key click).
 *
 * @returns editor view state command.
 */
export function resetHeading(): Command {
  return (state, dispatch, view) => {
    const { selection } = state;
    if (
      selection.empty &&
      selection.$from.parent.type === state.schema.nodes.heading &&
      view?.endOfTextblock('backward', state)
    ) {
      return toParagraph(state, dispatch, view);
    }
    return false;
  };
}

/**
 * Sets heading or paragraph block type depending on level.
 *
 * @param level tag h's level.
 * @returns editor view state command.
 */
export function heading(level: number): Command {
  return (state, dispatch) => {
    if (level) {
      return setBlockType(state.schema.nodes.heading, { level })(
        state,
        dispatch,
      );
    } else {
      return setBlockType(state.schema.nodes.paragraph)(state, dispatch);
    }
  };
}

/**
 * Toggles heading menu item isActive.
 *
 * @param item selected item.
 * @param state editor view state.
 */
export function toggleHeadingActive(item: MenuItem, state: EditorState): void {
  item.subItems.forEach((item) => (item.isActive = false));
  if (state.selection.$head.parent.type.name === 'heading') {
    item.isActive = true;
    item.subItems.find(
      (subItem) =>
        subItem.id === `heading${state.selection.$head.parent.attrs.level}`,
    ).isActive = true;
  } else {
    item.isActive = false;
    item.subItems[0].isActive = true;
  }
}
