<form class="modal-form" novalidate ngDraggable [formGroup]="form">
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'components.duplicatingModalComponent.groups.duplicating' | translate }}
    </h3>
    <button
      [disabled]="saving()"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group">
      <label>{{ 'components.duplicatingModalComponent.props.teamMember' | translate }}</label>
      <p class="pt-1 trim">{{ resource.name }}</p>
    </div>
    <div class="form-group" tmtIndicator>
      <label>{{ 'shared2.props.role' | translate }}</label>
      <wp-select-box
        formControlName="role"
        [values]="roles$"
        placeholder="{{ 'shared2.props.role' | translate }}"
      />
    </div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="save()"
      wpButtonSpinner
      [isBusy]="saving()"
    >
      {{ 'components.duplicatingModalComponent.actions.duplicate' | translate }}
    </button>

    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="saving()">
      {{ 'shared2.actions.cancel' | translate }}
    </button>
  </div>
</form>
