import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';

@Component({
  selector: 'tmt-task-box-test',
  templateUrl: './task-box-test.component.html',
  providers: [ProjectTasksService],
  standalone: false,
})
export class TaskBoxTestComponent {
  form = this.fb.group({
    department: [null, Validators.required],
    departmentWithSub: [null, Validators.required],
  });

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.setValue({
      department: <NamedEntity>{ id: Guid.generate(), name: 'Test 1' },
      departmentWithSub: {
        value: <NamedEntity>{ id: Guid.generate(), name: 'Test 2' },
        includeSubordinates: true,
      },
    });
  }
}
