import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'tmt-competence-creation',
  templateUrl: './competence-creation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompetenceCreationComponent {
  public competenceForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    roleId: ['', Validators.required],
  });
  public isSaving$ = new BehaviorSubject<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  /* Creates competence. */
  public create(): void {
    this.competenceForm.markAllAsTouched();

    if (this.competenceForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);

    this.dataService
      .collection('Competences')
      .insert(this.competenceForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'settings.competences.creation.messages.created',
          );
          this.stateService.go('settings.competence', {
            entityId: response.id,
          });
          this.isSaving$.next(false);
          this.activeModal.close();
        },
        error: (error) => {
          this.notificationService.error(error.message);
          this.isSaving$.next(false);
        },
      });
  }

  /* Closes competence's creating modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
