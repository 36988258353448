import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { FilterOperator } from '../../models/filter/filter-operator.enum';
import { Criterion } from '../report-filters/criterion.model';
import { TranslateService } from '@ngx-translate/core';

import { FilterCondition } from '../../models/filter/filter-condition.model';
import { ReportFieldType } from '../../models/report-field-type.enum';
import { DataService } from 'src/app/core/data.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { cloneDeep } from 'lodash';
import { LogService } from 'src/app/core/log.service';
import { ENTITY_TYPE_TO_COLLECTION } from 'src/app/shared/models/entities/entity-type-description.model';

@Component({
  selector: 'wp-report-filter-criterion',
  templateUrl: './report-filter-criterion.component.html',
  styleUrls: ['./report-filter-criterion.component.scss'],
  standalone: false,
})
export class ReportFilterCriterionComponent implements OnInit {
  @Input() allowInactive: boolean;

  private _criterion: Criterion;
  @Input() set criterion(value: Criterion) {
    this._criterion = cloneDeep(value);
  }
  get criterion(): Criterion {
    return this._criterion;
  }

  @Output() onRemove = new EventEmitter<void>();
  @Output() onChange = new EventEmitter<Criterion>();

  public operators: FilterOperator[];
  public collectionName: string;

  public form = this.fb.array([]);

  constructor(
    private changeDetector: ChangeDetectorRef,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private translate: TranslateService,
    private log: LogService,
  ) {}

  public getOperatorLocalTitle(operator: FilterOperator) {
    return this.translate.instant(`enums.filterOperator.${operator}`);
  }

  public remove() {
    this.onRemove.emit();
  }

  private propagateChange() {
    this._criterion.conditions = [];

    this.form.controls.forEach((group: UntypedFormGroup) => {
      const condition: FilterCondition = {
        operator: group.value.operator,
        value: group.value.value,
      };

      if (this._criterion.field.type === ReportFieldType.EntityId) {
        condition.value = group.value.entityValue?.id;
      }

      if (
        this._criterion.field.type === ReportFieldType.Enum ||
        this._criterion.field.type === ReportFieldType.State
      ) {
        condition.value = group.value.entityValue?.id;
      }

      if (condition.operator === FilterOperator.Between) {
        condition.value = [group.value.valueLeft, group.value.valueRight];

        if (this.criterion.field.type === ReportFieldType.Percent) {
          condition.value = [
            group.value.valueLeft === null ? null : group.value.valueLeft / 100,
            group.value.valueRight === null
              ? null
              : group.value.valueRight / 100,
          ];
        }
      }

      if (
        this.criterion.field.type === ReportFieldType.Percent &&
        condition.operator !== FilterOperator.Between
      ) {
        condition.value =
          group.value.value === null ? null : group.value.value / 100;
      }

      if (
        condition.operator === FilterOperator.IsNotNull ||
        condition.operator === FilterOperator.IsNull
      ) {
        condition.value = undefined;
      }

      this._criterion.conditions.push(condition);
    });

    this.onChange.emit(this._criterion);
  }

  public addCondition() {
    this.log.debug(
      `Criterion ${this.criterion.name}. Condition has been added.`,
    );
    const group = this.createConditionGroup();
    group.controls['operator'].setValue(this.criterion.field.operators[0]);
    this.form.push(group);
    this.propagateChange();
  }

  public removeCondition(index: number) {
    this.log.debug(
      'Criterion ' + this.criterion.name + '. Condition has removed.',
    );
    this.form.removeAt(index);
    this.propagateChange();
  }

  public setOperator(group: UntypedFormGroup, operator: FilterOperator) {
    this.log.debug(
      'Criterion ' +
        this.criterion.name +
        '. Condition operator has been changed to ' +
        operator +
        '.',
    );

    if (
      operator === FilterOperator.IsNull ||
      operator === FilterOperator.IsNotNull
    ) {
      group.controls['value'].setValue(null);
    }

    if (
      (operator === FilterOperator.InPeriod &&
        group.value.operator !== FilterOperator.InPeriod) ||
      (operator !== FilterOperator.InPeriod &&
        group.value.operator === FilterOperator.InPeriod)
    ) {
      group.controls['value'].setValue(null);
    }

    group.controls['operator'].setValue(operator);
  }

  private createConditionGroup(): UntypedFormGroup {
    const group = this.fb.group({
      entityValue: null,
      value: null,
      percentValue: null,
      operator: null,
      valueLeft: null,
      valueRight: null,
    });
    return group;
  }

  ngOnInit(): void {
    this.criterion.conditions.forEach((condition) => {
      const group = this.createConditionGroup();
      group.controls['operator'].setValue(condition.operator);
      group.controls['value'].setValue(condition.value);

      this.form.push(group);

      // Разбор значений для оператора Between
      if (condition.operator === FilterOperator.Between && condition.value) {
        group.controls['valueLeft'].setValue(condition.value[0]);
        group.controls['valueRight'].setValue(condition.value[1]);

        // И типа поля Percent
        if (this.criterion.field.type === ReportFieldType.Percent) {
          if (condition.value[0] !== null) {
            group.controls['valueLeft'].setValue(condition.value[0] * 100);
          }

          if (condition.value[1] !== null) {
            group.controls['valueRight'].setValue(condition.value[1] * 100);
          }
        }
      }

      // Разбор значений для Percent - Value
      if (
        this.criterion.field.type === ReportFieldType.Percent &&
        condition.operator !== FilterOperator.Between
      ) {
        if (condition.value !== null) {
          group.controls['percentValue'].setValue(condition.value * 100);
        }
      }
    });

    if (this.criterion.conditions.length === 0) {
      this.addCondition();
    }

    // Загрузка сущностей.
    if (this.criterion.field.type === ReportFieldType.EntityId) {
      this.collectionName = ENTITY_TYPE_TO_COLLECTION.get(
        this.criterion.field.entityTypeName,
      );

      this.criterion.conditions.forEach((condition, index) => {
        if (condition.value) {
          this.data
            .collection(this.collectionName)
            .entity(condition.value)
            .get()
            .subscribe({
              next: (data) => {
                (this.form.at(index) as UntypedFormGroup).controls[
                  'entityValue'
                ].setValue(data, { emitEvent: false });
                this.changeDetector.detectChanges();
              },
              error: (error: Exception) => {
                if (error.code === Exception.BtEntityNotFoundException.code) {
                  (this.form.at(index) as UntypedFormGroup).controls[
                    'entityValue'
                  ].setValue(
                    {
                      id: condition.value,
                      name: this.translate.instant(
                        'shared.entityNotFound.title',
                      ),
                    },
                    { emitEvent: false },
                  );
                } else {
                  this.notification.error(error.message);
                }
                this.changeDetector.detectChanges();
              },
            });
        }
      });
    }

    // Загрузить перечисления.
    if (
      this.criterion.field.type === ReportFieldType.Enum ||
      this.criterion.field.type === ReportFieldType.State
    ) {
      this.criterion.conditions.forEach((condition, index) => {
        if (condition.value) {
          const value = this.criterion.field.values.find(
            (v) => v.id === condition.value,
          );

          if (value) {
            (this.form.at(index) as UntypedFormGroup).controls[
              'entityValue'
            ].setValue(value, { emitEvent: false });
          }
        }
      });
    }

    this.form.valueChanges.subscribe(() => {
      this.propagateChange();
    });
  }
}
