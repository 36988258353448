import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { Slot } from 'src/app/shared-features/schedule-navigation/models/slot.model';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { ResourceSummaryDataService } from 'src/app/resource-summary/core/resource-summary-data.service';
import { ResourceSummaryService } from 'src/app/resource-summary/core/resource-summary.service';
import { ResourceSummaryViewSettingsService } from 'src/app/resource-summary/shared/resource-summary-view-settings/core/resource-summary-view-settings.service';
import { ResourceSummaryTotal } from 'src/app/resource-summary/models/resource-summary-view.model';
import {
  ResourceSummaryPageDto,
  ResourceSummaryResourceEntryDto,
} from 'src/app/resource-summary/models/resource-summary-data.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import _ from 'lodash';

/** Represents Resource Summary Right Grid content. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmtResourceSummaryRightGroup]',
  templateUrl: './resource-summary-right-group.component.html',
  styleUrls: ['./resource-summary-right-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceSummaryRightGroupComponent implements OnInit {
  @Input() summaryPageDto: ResourceSummaryPageDto;

  /**
   * Indicates whether row is expanded or collapsed.
   *
   * @returns `true` if row is expanded, `false` otherwise.
   * */
  public get isExpanded(): boolean {
    return this._isExpanded;
  }

  /** Gets Selected KPI Types. */
  public get selectedKpiTypes(): KpiType[] {
    return this._selectedKpiTypes;
  }

  /** Gets Selected KPI Types without Schedule data. */
  public get selectedKpiTypesWithoutSchedule(): KpiType[] {
    return this._selectedKpiTypesWithoutSchedule;
  }

  /**
   * Indicates whether Actual KPI type is selected or not.
   *
   * @returns `true` if selected, `false` otherwise.
   * */
  public get isActualKpiTypeSelected(): boolean {
    return this._isActualKpiTypeSelected;
  }

  /** Gets Summary Totals. */
  public get totals(): ResourceSummaryTotal[] {
    return this._totals;
  }

  /**
   * Indicates whether Summary Project lines are empty or not.
   *
   * @returns `true` if Project lines are empty, `false` otherwise.
   * */
  public get isProjectLinesEmpty(): boolean {
    const isEmptyLines = !this.summaryPageDto.lines.length;
    const isOnlyTimeOffAndActualTypeNotSelected =
      this.summaryPageDto.lines.length === 1 &&
      !this.summaryPageDto.lines[0].id &&
      this.summaryPageDto.hasTimeOff &&
      !this._isActualKpiTypeSelected;
    return isEmptyLines || isOnlyTimeOffAndActualTypeNotSelected;
  }

  protected kpiType = KpiType;
  protected valueMode = ValueMode;

  private readonly timeColors = {
    common: 'rgba(72, 169, 166, 0.8)',
    overtime: 'rgba(193, 102, 107, 0.8)',
  };

  private _isExpanded: boolean;
  private _selectedKpiTypes: KpiType[];
  private _selectedKpiTypesWithoutSchedule: KpiType[];
  private _isActualKpiTypeSelected = false;
  private _totals: ResourceSummaryTotal[];
  private _nonWorkingDays: string[];

  private destroyRef = inject(DestroyRef);

  constructor(
    public summaryService: ResourceSummaryService,
    private summaryDataService: ResourceSummaryDataService,
    private summaryViewSettingsService: ResourceSummaryViewSettingsService,
    private cdRef: ChangeDetectorRef,
  ) {
    this._selectedKpiTypes = this.summaryService.selectedKpiTypes;
    this._selectedKpiTypesWithoutSchedule =
      this.summaryService.selectedKpiTypesWithoutSchedule;
    this._isActualKpiTypeSelected = this.summaryService.isActualKpiTypeSelected;
  }

  ngOnInit(): void {
    this.initSubscriptions();
    this.initTotals();
    this.recalculateTotals();
  }

  /**
   * Tracks Row by its fields for control flow.
   *
   * @param index Row index.
   * @param entry Row.
   * @returns Row ID.
   * */
  public trackByEntry(
    index: number,
    entry: ResourceSummaryResourceEntryDto,
  ): string {
    return entry.projectId ?? index.toString();
  }

  /**
   * Gets Summary Totals by KPI Type.
   *
   * @param kpiType KPI Type.
   * @returns Totals by KPI Type.
   * */
  public getTotalsByKpiType(kpiType: KpiType): ResourceSummaryTotal[] {
    return this._totals.filter((t) => t.kpiType === kpiType);
  }

  /**
   * Determines whether Slot Date is weekend or not.
   *
   * @param slot Slot.
   * @returns `true` if weekend, `false` otherwise.
   * */
  public isWeekend(slot: Slot): boolean {
    return !!this._nonWorkingDays.find((d) => d === slot.date.toISODate());
  }

  /**
   * Gets Summary Entries by Project ID.
   *
   * @param [projectId] Project ID.
   * @param selectedKpiType Selected KPI Type.
   * @param slot Slot.
   * @returns Resource Summary Entries.
   * */
  public getSummaryEntriesByProjectId(
    projectId: string | null,
    selectedKpiType: KpiType,
    slot: Slot,
  ): ResourceSummaryResourceEntryDto[] {
    const entries = this.summaryDataService.getResourceSummaryEntries(
      this.summaryPageDto.id,
      projectId,
    );

    return entries.filter(
      (entry) =>
        entry.kpiType === selectedKpiType &&
        slot.date.toISODate() === entry.date,
    );
  }

  /**
   * Gets Summary Total Hours Indicator NG style.
   * @param total Summary Total.
   * @returns Indicator NG style.
   */
  public getSummaryTotalIndicatorStyle(
    total: ResourceSummaryTotal,
  ): Dictionary<string> {
    if (
      !this.summaryViewSettingsService.settings.overtime ||
      total.kpiType === KpiType.Scheduled
    ) {
      return;
    }

    let percent = 0;
    total.overtime = false;

    if (total.scheduleHours > 0) {
      percent = _.round((total.hours / total.scheduleHours) * 100, 2);
    } else if (total.hours > 0) {
      percent = 100;
    }

    if (percent > 100) {
      total.overtime = true;
    }

    const color = total.overtime
      ? this.timeColors.overtime
      : this.timeColors.common;
    return {
      background: `linear-gradient(to top, ${color} ${percent}%, var(--bs-total-cell-bg) 0%)`,
    };
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.summaryViewSettingsService.settings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this._selectedKpiTypes = this.summaryService.selectedKpiTypes;
        this._selectedKpiTypesWithoutSchedule =
          this.summaryService.selectedKpiTypesWithoutSchedule;
        this._isActualKpiTypeSelected =
          this.summaryService.isActualKpiTypeSelected;
        this.initTotals();
        this.recalculateTotals();
        this.cdRef.detectChanges();
      });

    this.summaryService.detectChanges$
      .pipe(
        filter((id) => !id || id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdRef.detectChanges();
      });

    this.summaryService.recalculateGroup$
      .pipe(
        filter((e) => e.id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((e) => {
        if (e.rebuild) {
          this.initTotals();
        }
        this.recalculateTotals();
      });

    this.summaryService.toggleGroup$
      .pipe(
        filter((event) => event?.id === this.summaryPageDto.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        this._isExpanded = event.state;
      });
  }

  /** Inits Summary Totals. */
  private initTotals(): void {
    this._totals = [];
    this._nonWorkingDays = [];

    const entries =
      this.summaryDataService.entries[this.summaryPageDto.id] ?? {};

    const entryTypes = this.summaryDataService.entryTypes;
    const planningScale = this.summaryService.settings.planningScale;
    const valueMode = this.summaryService.settings.valueMode;

    for (const slot of this.summaryService.slots) {
      const projectEntry = entries[entryTypes.schedule]?.find(
        (e) => e.date === slot.date.toISODate(),
      );
      const projectEntryData = {
        hours: 0,
        value: 0,
      };

      switch (valueMode) {
        case ValueMode.Hours:
          projectEntryData.value = projectEntry?.hours ?? 0;
          projectEntryData.hours = projectEntry?.hours ?? 0;
          break;
        case ValueMode.Cost:
          projectEntryData.value = projectEntry?.cost ?? 0;
          projectEntryData.hours = projectEntry?.hours ?? 0;
          break;
      }

      const isNonWorking = projectEntry ? projectEntryData.hours === 0 : false;

      if (planningScale === PlanningScale.Day && isNonWorking) {
        this._nonWorkingDays.push(slot.date.toISODate());
      }

      for (const selectedKpiType of this.selectedKpiTypes) {
        this.totals.push({
          id: slot.id,
          date: slot.date.toISODate(),
          hours: projectEntryData.value,
          scheduleHours: 0,
          nonWorking: isNonWorking,
          kpiType: selectedKpiType,
          overtime: false,
        });
      }
    }
  }

  /** Recalculates Summary Totals. */
  private recalculateTotals(): void {
    const resourceEntries =
      this.summaryDataService.entries[this.summaryPageDto.id];
    if (!resourceEntries) return;

    this.totals.forEach((total) => {
      total.hours = 0;
      total.scheduleHours = 0;
    });

    const allEntries: ResourceSummaryResourceEntryDto[] = [];
    const allResources = Object.values(resourceEntries);
    const valueMode = this.summaryService.settings.valueMode;

    allResources.forEach((currentResourceEntries: []) => {
      currentResourceEntries.forEach((resourceEntry) => {
        allEntries.push(resourceEntry);
      });
    });

    allEntries.forEach((entry) => {
      const total = this.totals.find(
        (t) => t.date === entry.date && t.kpiType === entry.kpiType,
      );
      if (total) {
        if (entry.kpiType !== KpiType.Scheduled) {
          const scheduleEntry = allEntries.find(
            (x) =>
              x.resourceId === entry.resourceId &&
              x.date === entry.date &&
              x.kpiType === KpiType.Scheduled,
          );

          switch (valueMode) {
            case ValueMode.Hours:
              total.scheduleHours = scheduleEntry?.hours ?? 0;
              break;
            case ValueMode.Cost:
              total.scheduleHours = scheduleEntry?.cost ?? 0;
              break;
          }
        }
        switch (valueMode) {
          case ValueMode.Hours:
            total.hours += entry.hours;
            break;
          case ValueMode.Cost:
            total.hours += entry.cost;
            break;
        }
      }
    });
  }
}
