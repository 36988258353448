import { ChangeDetectionStrategy, Component } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

import { WorkLogService } from './work-log.service';
import { WorkLogFilterService } from './work-log-filter.service';
import { WorkLogItemComponent } from './item/work-log-item.component';

@Component({
  imports: [GridModule, TranslateModule, WorkLogItemComponent, SharedModule],
  providers: [
    WorkLogService,
    SavingQueueService,
    { provide: FilterService, useClass: WorkLogFilterService },
  ],
  selector: 'tmt-work-log',
  templateUrl: './work-log.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    class: 'feed feed--work-log',
  },
})
export class WorkLogComponent {
  constructor(protected workLogService: WorkLogService) {
    this.workLogService.load();
  }
}
