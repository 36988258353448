import { Injectable } from '@angular/core';
import {
  BehaviorSubject,
  Observable,
  Subject,
  Subscription,
  throwError,
} from 'rxjs';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { catchError } from 'rxjs/operators';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { AddingResourceGroupModalComponent } from 'src/app/settings-app/users/card/users-groups/adding-resource-group-modal/adding-resource-group-modal.component';
import { EntityType } from 'src/app/shared-features/resource-roles/enums/entity-type.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import { ResourceGroupAssignment } from 'src/app/shared/models/entities/settings/resource-group-assignment.model';
import { Exception } from 'src/app/shared/models/exception';
import { Group } from 'src/app/shared/models/entities/settings/group.model';

@Injectable()
export class ResourceGroupsService {
  public resourceId: string;

  private changesSubject = new Subject<void>();
  public changes$ = this.changesSubject.asObservable();

  private isLoadingSubject = new BehaviorSubject<boolean>(true);
  public isLoading$ = this.isLoadingSubject.asObservable();

  public groups = this.fb.array([]);
  private loadingSubscription: Subscription;

  constructor(
    private data: DataService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private modal: NgbModal,
  ) {}

  /** Method that reload data. */
  public reload() {
    this.load();
  }

  /** Method that open modal, where we can select groups for user. */
  public addGroups(resourceType: EntityType) {
    const modalRef = this.modal.open(AddingResourceGroupModalComponent, {
      size: 'lg',
    });
    const instance =
      modalRef.componentInstance as AddingResourceGroupModalComponent;
    instance.entityType = resourceType;
    instance.entityId = this.resourceId;
    instance.alreadyAddedIds = (
      this.groups.value as ResourceGroupAssignment[]
    ).map((g) => g.groupId);

    modalRef.result.then(
      (newGroups: Group[]) => {
        newGroups.forEach((newGroup) => {
          const group = this.getGroup();
          group.controls.name.setValue(newGroup.name);
          group.controls.description.setValue(newGroup.description);
          group.controls.groupId.setValue(newGroup.id);
          this.groups.push(group);
        });

        this.changesSubject.next();
      },
      () => null,
    );
  }

  /** Method that delete group by index. */
  public delete(index: number) {
    this.groups.removeAt(index);
    this.changesSubject.next();
  }
  /** Method that loads groups for current user and renders it. */
  public load() {
    this.groups.clear();
    this.isLoadingSubject.next(true);

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    this.loadingSubscription = this.data
      .collection('Users')
      .entity(this.resourceId)
      .collection('UserGroups')
      .query({
        expand: {
          group: {},
        },
        orderBy: ['group/name'],
      })
      .subscribe({
        next: (groups: { group: Group }[]) => {
          groups.forEach(({ group }) => {
            const newGroup = this.getGroup();
            newGroup.controls.name.setValue(group.name);
            newGroup.controls.description.setValue(group.description);
            newGroup.controls.groupId.setValue(group.id);
            this.groups.push(newGroup);
          });
          this.isLoadingSubject.next(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoadingSubject.next(false);
        },
      });
  }

  /** Method that saves resource groups and return observable with response. */
  public save(): Observable<object> {
    this.groups.markAllAsTouched();

    if (this.groups.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return throwError(() => new Exception());
    }
    const data = {
      userGroups: (this.groups.value as ResourceGroupAssignment[]).map(
        (group) => ({
          groupId: group.groupId,
          userId: this.resourceId,
        }),
      ),
    };
    return this.data
      .collection('Users')
      .entity(this.resourceId)
      .action('UpdateUserGroups')
      .execute<object>(data)
      .pipe(
        catchError((error: Exception) => {
          this.notification.error(error.message);
          return throwError(() => error);
        }),
      );
  }

  private getGroup(): UntypedFormGroup {
    const group = this.fb.group({
      id: Guid.generate(),
      description: null,
      name: null,
      groupId: null,
    });

    return group;
  }
}
