import { Component, OnInit } from '@angular/core';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { Subscription, timer } from 'rxjs';

/** Индикатор счетчика времени на панели действий. */
@Component({
  selector: 'wp-stopwatch-indicator',
  templateUrl: './stopwatch-indicator.component.html',
  styleUrls: ['./stopwatch-indicator.component.scss'],
  standalone: false,
})
export class StopwatchIndicatorComponent implements OnInit {
  public isVisible: boolean;
  public isPaused: boolean;
  public swHours: string;
  public swMinutes: string;

  private intervalSubscription: Subscription;

  constructor(public stopwatchService: StopwatchService) {}

  public ngOnInit(): void {
    this.stopwatchService.stopwatch$.subscribe((stopwatch) => {
      this.isPaused = stopwatch?.state === 'Paused';

      this.intervalSubscription?.unsubscribe();
      this.updateViewValue();

      if (stopwatch && !this.isPaused) {
        // TODO: get rid from subscribe in subscribe?
        this.intervalSubscription = timer(0, 1000).subscribe(() => {
          this.updateViewValue();
        });
      }
    });
  }

  /** Opens stopwatch popup. */
  public onClick(): void {
    this.stopwatchService.openPopup();
  }

  /** Updates time in UI. */
  private updateViewValue(): void {
    [this.swHours, this.swMinutes] =
      this.stopwatchService.getFormattedDuration();
  }
}
