<wp-loading-indicator [state]="clientCardService.state()" />
<wp-data-not-saved-indicator
  class="mt-3"
  [hidden]="!clientCardService.isInvalid()"
  [form]="clientCardService.clientForm"
  [isDirty]="savingQueueService.isSaving"
/>

<form
  class="form-container"
  [hidden]="clientCardService.state() !== 'Ready'"
  [formGroup]="clientCardService.clientForm"
  novalidate
  autocomplete="off"
>
  <div ngbAccordion class="accordion--custom">
    <div ngbAccordionItem [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.groups.mainProperties' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid form-grid__main-properties">
            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.name.label' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.name.ph' | translate }}"
                formControlName="name"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.site' | translate }}</label>
              <wp-text-box
                typeName="url"
                placeholder="{{ 'projects.clients.settings.props.site' | translate }}"
                formControlName="site"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'shared.props.code' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'shared.props.code' | translate }}"
                class="form-group__code"
                formControlName="code"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.phone' | translate }}</label>
              <wp-text-box
                typeName="phone"
                placeholder="{{ 'projects.clients.settings.props.phone' | translate }}"
                formControlName="phone"
                [propagationMode]="propagationMode.onExitFromEditing"
                #phone
              />
            </div>
            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.manager.label' | translate }}</label>
              <wp-user-box
                placeholder="{{ 'projects.clients.settings.props.manager.ph' | translate }}"
                formControlName="manager"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.contact' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.contact' | translate }}"
                formControlName="contact"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.contactEmail' | translate }}</label>
              <wp-text-box
                formControlName="contactEmail"
                placeholder="{{ 'projects.clients.settings.props.contactEmail' | translate }}"
                typeName="email"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.props.description' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid">
            <div class="form-group full-width" tmtIndicator>
              <wp-multiline-text-box
                formControlName="description"
                placeholder="{{ 'shared2.props.description' | translate }}"
                [propagationMode]="propagationMode.onExitFromEditing"
                [rows]="7"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'projects.clients.settings.props.postAddress' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid" formGroupName="postAddress">
            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.state' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.state' | translate }}"
                formControlName="state"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.index' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.index' | translate }}"
                class="form-group__post-index"
                formControlName="postIndex"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.city' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.city' | translate }}"
                formControlName="city"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.address' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.address' | translate }}"
                formControlName="address"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'projects.clients.settings.props.invoiceAddress' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid" formGroupName="billingAddress">
            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.state' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.state' | translate }}"
                formControlName="state"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.index' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.index' | translate }}"
                class="form-group__post-index"
                formControlName="postIndex"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.city' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.city' | translate }}"
                formControlName="city"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{ 'projects.clients.settings.props.address' | translate }}</label>
              <wp-text-box
                placeholder="{{ 'projects.clients.settings.props.address' | translate }}"
                formControlName="address"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>
          </div>
          <div class="row pt-2">
            <div class="col-6">
              <div class="form-check" style="height: 20px">
                <input
                  formControlName="billingEqualPost"
                  type="checkbox"
                  class="form-check-input"
                  id="billingEqualPost"
                />
                <label class="form-check-label" for="billingEqualPost">
                  {{ 'projects.clients.settings.props.coincidesWithPost' | translate }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    @if (this.appService.getCustomFields('Organization').length) {
      <div ngbAccordionItem [collapsed]="false">
        <h2 ngbAccordionHeader>
          <button class="group" ngbAccordionButton>
            {{ 'shared2.groups.advanced' | translate }}
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <wp-custom-fields
              [formGroup]="clientCardService.clientForm"
              [isVisibleHr]="false"
              [entityType]="'Organization'"
              [propagationMode]="propagationMode.onExitFromEditing"
            />
          </div>
        </div>
      </div>
    }
  </div>
</form>
