import { AfterViewInit, Component } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'tmt-rich-editor-box-test',
  templateUrl: './rich-editor-box-test.component.html',
  standalone: false,
})
export class RichEditorBoxTestComponent implements AfterViewInit {
  public form: FormGroup = this.fb.group({
    editByButtonControl: '',
    alwaysEditableModeControl: '',
  });
  public mentionsFirst: string[] = [];
  public mentionsSecond: string[] = [];

  public get mentionsFirstText(): string {
    return this.mentionsFirst.join(', ');
  }

  public get mentionsSecondText(): string {
    return this.mentionsSecond.join(', ');
  }

  constructor(private fb: UntypedFormBuilder) {}

  ngAfterViewInit(): void {
    this.form.setControl('alwaysEditableModeControl', new FormControl(''));
  }

  public setValue(): void {
    this.form.patchValue({
      editByButtonControl:
        '# Heading 1\n\n' +
        '> Quote\n\n' +
        '**Bold** *Italic* ~~Strike~~ ++Underline++ ##Monospace## [Link](https://timetta.com/) `Inline code`\n\n' +
        '---\n\n' +
        'Simple text\n\n' +
        '* Bullet list item 1\n\n* Bullet list item 2\n\n' +
        '---\n\n' +
        '1. Ordered list item 1\n\n2. Ordered list item 2',
      alwaysEditableModeControl:
        '# Heading 1\n\n' +
        '> Quote\n\n' +
        '**Bold** *Italic* ~~Strike~~ ++Underline++ ##Monospace## [Link](https://timetta.com/) `Inline code`\n\n' +
        '---\n\n' +
        'Simple text\n\n' +
        '* Bullet list item 1\n\n* Bullet list item 2\n\n' +
        '---\n\n' +
        '1. Ordered list item 1\n\n2. Ordered list item 2',
    });
  }

  public mentionedUserIdsFirst(userIds: string[]): void {
    this.mentionsFirst = this.mentionsFirst.concat(
      userIds.filter((id) => !this.mentionsFirst.includes(id)),
    );
  }

  public mentionedUserIdsSecond(userIds: string[]): void {
    this.mentionsSecond = this.mentionsSecond.concat(
      userIds.filter((id) => !this.mentionsSecond.includes(id)),
    );
  }
}
