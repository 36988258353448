import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  OnDestroy,
  ElementRef,
  ChangeDetectionStrategy,
  Injector,
  Optional,
} from '@angular/core';
import { RawParams, StateService } from '@uirouter/core';

import { Subject, merge } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import _ from 'lodash';

import { NavigationService } from 'src/app/core/navigation.service';
import { MessageService } from 'src/app/core/message.service';

import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { SubAction } from 'src/app/shared/models/inner/menu-action';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { ProjectInfoComponent } from 'src/app/shared/components/features/project-info/project-info.component';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';

import { ResourceRequestCalendarService } from 'src/app/resource-requests/shared/calendar/resource-request-calendar.service';
import { BookingService } from 'src/app/booking/booking/core/booking.service';
import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { BookingManageService } from 'src/app/booking/booking/core/booking-manage.service';
import { ProjectRow } from 'src/app/shared/models/entities/resources/booking-entry.model';
import { BookingViewSettingsService } from 'src/app/booking/booking/shared/booking-view-settings/booking-view-settings.service';

@Component({
  selector: 'tmt-booking-detailed-line-left',
  templateUrl: './booking-detailed-line-left.component.html',
  styleUrls: ['./booking-detailed-line-left.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BookingDetailedLineLeftComponent implements OnInit, OnDestroy {
  @Input() public resource: NamedEntity;

  public projectRows: ProjectRow[] = [];
  public lineActions: Partial<SubAction>[] = [];
  public resourceRequestShown: boolean;
  public stateParams: RawParams = {
    navigation: this.navigationService.selectedNavigationItem?.name,
    routeMode: RouteMode.continue,
  };

  private destroyed$ = new Subject<void>();

  public get totalHours(): number {
    return this.bookingDataService.getTotalsByResource(this.resource.id);
  }

  constructor(
    public bookingService: BookingService,
    public bookingDataService: BookingDataService,
    public el: ElementRef<HTMLElement>,
    private bookingManagerService: BookingManageService,
    private bookingViewSettingService: BookingViewSettingsService,
    private navigationService: NavigationService,
    private infoPopupService: InfoPopupService,
    private messageService: MessageService,
    private stateService: StateService,
    private cdr: ChangeDetectorRef,
    private injector: Injector,
    @Optional()
    private resourceRequestCalendarService: ResourceRequestCalendarService,
  ) {}

  public ngOnInit(): void {
    this.resourceRequestShown =
      this.bookingViewSettingService.settings.resourceRequestShown;
    this.initLineActions();

    this.bookingService.toggleRow$
      .pipe(
        filter((v) => v?.id === this.resource.id),
        takeUntil(this.destroyed$),
      )
      .subscribe((toggleState) => {
        this.projectRows.forEach((row) => {
          row.isExpanded = toggleState.projectIds.has(row.id);
        });

        this.cdr.markForCheck();
      });

    merge(
      this.bookingService.detectChanges$,
      this.bookingService.toggleGroup$,
      this.bookingService.recalculateGroup$,
    )
      .pipe(
        filter((v) => (_.isObject(v) ? v.id : v) === this.resource.id),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.initProjectRows();
      });

    merge(this.bookingManagerService.reload$, this.bookingService.changes$)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.initProjectRows();
      });
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Automatically replace result with request when `BookingMode` is detailed
   * (works if `ResourceRequestMode` is enabled without generic).
   *
   * @param project project row.
   */
  public replaceBookingEntry(row: ProjectRow): void {
    this.bookingService.replaceBookingEntry(
      row.bookingEntryId,
      this.resource.id,
      this.resourceRequestCalendarService.requestDateHours,
    );
  }

  /** Automatically enrich result before request for the bookingEntry when `BookingMode` is detailed.
   *
   * @param project project row.
   */
  public fillBookingEntry(row: ProjectRow): void {
    this.bookingService.fillBookingEntry(
      row.bookingEntryId,
      this.resource.id,
      this.resourceRequestCalendarService.requestDateHours,
    );
  }

  /**
   * Deletes booking entry.
   *
   * @param item ProjectRow.
   *
   * */
  public deleteBookingEntry(item: ProjectRow): void {
    this.messageService
      .confirmLocal('resources.booking.entryContextMenu.deleteConfirmation')
      .then(() => {
        this.bookingService.deleteBooking(
          this.bookingDataService.bookings.find(
            (el) => el.id === item.bookingEntryId,
          ),
        );

        if (this.bookingDataService.resourceRequestMode) {
          _.remove(
            this.bookingDataService.resources,
            (el) => el.id === this.resource.id,
          );

          this.resourceRequestCalendarService?.removeBookingEntry(
            item.bookingEntryId,
            this.resource.id,
          );
        }
      });
  }

  /**
   * Remove all details of booking entry.
   *
   * @param item ProjectRow.
   *
   * */
  public clearBookingEntry(item: ProjectRow): void {
    this.bookingService.clearBooking(
      this.bookingDataService.bookings.find(
        (el) => el.id === item.bookingEntryId,
      ),
    );
  }

  /**
   * Opens project info popup
   *
   * @param projectId Project id.
   *
   * */
  public openProjectInfo(projectId: string): void {
    this.infoPopupService.open({
      target: this.el.nativeElement.querySelector(`#project${projectId}`),
      data: {
        component: ProjectInfoComponent,
        params: {
          projectId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Opens project info popup
   *
   * @param userId Resource id.
   *
   * */
  public openUserInfo(userId: string): void {
    this.infoPopupService.open({
      target: this.el.nativeElement.querySelector(`#user${userId}`),
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Opens resource request. */
  public openResourceRequest(requestId: string): void {
    this.stateService.go('resourceRequest', {
      navigation: this.stateParams.navigation,
      routeMode: this.stateParams.routeMode,
      entityId: requestId,
    });
  }

  private initProjectRows(): void {
    this.projectRows.length = 0;
    this.projectRows = this.bookingService.getsProjectRows(this.resource.id);
    this.cdr.markForCheck();
  }

  private initLineActions(): void {
    this.lineActions = [
      {
        name: 'fillByRequest',
        title: 'resources.actions.fillByRequest',
        isVisible:
          this.bookingDataService.resourceRequestMode &&
          !this.bookingService.isActualizationResourceRequest,
        handler: (p) => this.fillBookingEntry(p),
      },
      {
        name: 'replaceWithRequest',
        title: 'resources.actions.replaceWithRequest',
        isVisible:
          this.bookingDataService.resourceRequestMode &&
          this.bookingService.isActualizationResourceRequest,
        handler: (p) => this.replaceBookingEntry(p),
      },
      {
        name: 'clear',
        title: 'shared.actions.clear',
        isVisible: true,
        handler: (p) => this.clearBookingEntry(p),
      },
      {
        name: 'delete',
        title: 'shared.actions.delete',
        isVisible: !this.bookingService.isActualizationResourceRequest,
        handler: (p) => this.deleteBookingEntry(p),
      },
    ];
  }
}
