<wp-loading-indicator [loading]="isLoading()" />

@if (!isLoading()) {
  <form class="modal-form" [formGroup]="form" novalidate autocomplete="off" ngDraggable>
    <div class="modal-header">
      <h3 class="modal-title">
        {{
          (timeEntry()
            ? 'components.workLogCardComponent.props.title'
            : 'components.workLogCardComponent.props.titleNew'
          ) | translate
        }}
      </h3>
      <button
        [disabled]="isSaving()"
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
      ></button>
    </div>

    <div class="modal-body">
      <div class="main-content">
        <div class="form-group form-group--user" tmtIndicator>
          <label>{{ 'shared2.props.user' | translate }}</label>
          <wp-user-box
            formControlName="user"
            placeholder="{{ 'shared2.props.user' | translate }}"
            [allowNull]="false"
          />
        </div>

        <div class="form-group" tmtIndicator>
          <label>{{ 'shared2.props.issue' | translate }}</label>
          @if (issueNameIsLink()) {
            <a
              class="form-control-plaintext link-primary"
              uiSref="issue"
              [uiParams]="{
                entityId: timeEntry().issue.id,
                navigation: navigationService.selectedNavigationItem?.name,
                routeMode: routeMode.continue,
                route: navigationService.storedRoute,
              }"
            >
              {{ issue().name }}</a
            >
          } @else {
            <div class="form-control-plaintext" data-test="resolution">
              {{ issue().name }}
            </div>
          }
        </div>

        <div class="form-group">
          <label>{{ 'shared2.props.date' | translate }}</label>
          <wp-date-box formControlName="date" [allowNull]="false" />
        </div>

        <div class="form-group" tmtIndicator>
          <label>{{ 'shared2.props.hours' | translate }}</label>
          <wp-number-box formControlName="hours" type="work" [min]="0" [max]="24" />
        </div>

        <div class="form-group form-group--project" tmtIndicator>
          <label>{{ 'shared2.props.project' | translate }}</label>
          <wp-select-box
            [formControlName]="'project'"
            [placeholder]="'shared2.props.project' | translate"
            [values]="
              workLogService.getValues(
                'project',
                formValues.user?.id,
                formValues.project?.id,
                templateId
              )
            "
          />
        </div>

        <div class="form-group form-group--projectTask" tmtIndicator>
          <label>{{ 'shared2.props.projectTask' | translate }}</label>
          <tmt-hierarchical-box
            [formControlName]="'projectTask'"
            [placeholder]="'shared2.props.projectTask' | translate"
            [parentIdKey]="'leadTaskId'"
            [items]="
              workLogService.getValues(
                'projectTask',
                formValues.user?.id,
                formValues.project?.id,
                templateId
              )
            "
          />
        </div>

        @for (property of availableFields(); track property) {
          <ng-container
            [ngTemplateOutlet]="selectBox"
            [ngTemplateOutletContext]="{ $implicit: property }"
          />
        }

        <div class="form-group form-group--description">
          <label>{{ 'shared2.props.comment' | translate }}</label>
          <wp-multiline-text-box
            formControlName="description"
            placeholder="{{ 'shared2.props.comment' | translate }}"
          />
        </div>
      </div>

      @if (availableCustomFields()?.length) {
        <wp-custom-fields
          class="d-block custom-fields-container"
          [formGroup]="form"
          [fields]="availableCustomFields()"
        />
      }
    </div>

    <div class="modal-footer">
      @if (!this.readonly() && !workLogService.readonly && timeEntry()) {
        <button
          type="button"
          class="btn btn-default me-auto"
          [ngbTooltip]="'shared.actions.delete' | translate"
          (click)="delete()"
        >
          <i class="bi bi-trash3"></i>
        </button>
      }

      @if (!this.readonly() && !workLogService.readonly) {
        <button
          type="button"
          class="btn btn-primary"
          (click)="ok()"
          wpButtonSpinner
          [isBusy]="isSaving()"
        >
          {{ (timeEntry() ? 'shared.actions.save' : 'shared.actions.create') | translate }}
        </button>
      }

      <button type="button" class="btn btn-default" (click)="cancel()">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>

    <ng-template #selectBox let-property>
      <div class="form-group form-group--{{ property }}" tmtIndicator>
        <label>{{ 'shared2.props.' + property | translate }}</label>
        <wp-select-box
          [formControlName]="property"
          [placeholder]="'shared2.props.' + property | translate"
          [values]="workLogService.getValues(property, formValues.user?.id, formValues.project?.id)"
        />
      </div>
    </ng-template>
  </form>
}
