import { Component, OnInit, OnDestroy } from '@angular/core';
import { UserSettingsModalService } from '../user-settings-modal.service';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { BehaviorSubject, Subscription } from 'rxjs';
import { TenantSettingService } from 'src/app/shared/services/tenant-setting.service';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-user-settings-security',
  templateUrl: './user-settings-security.component.html',
  standalone: false,
})
export class UserSettingsSecurityComponent implements OnInit, OnDestroy {
  savingSubscription: Subscription;
  public form: UntypedFormGroup = this.fb.group({
    oldPassword: ['', [Validators.required]],
    newPassword: ['', [Validators.required]],
    newPasswordRepeat: ['', [Validators.required]],
  });

  public passwordRequirements$$ = new BehaviorSubject<string>('');

  constructor(
    private service: UserSettingsModalService,
    private notification: NotificationService,
    private data: DataService,
    private tenantSetting: TenantSettingService,
    private fb: UntypedFormBuilder,
  ) {}

  apply() {
    this.form.markAllAsTouched();

    if (this.form.value.newPassword !== this.form.value.newPasswordRepeat) {
      this.notification.warningLocal(
        'shared.userSettings.security.password.notMatch',
      );
      return;
    }

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.service.savingState$.next(true);

    const data = {
      newPassword: this.form.value.newPassword,
      oldPassword: this.form.value.oldPassword,
    };

    this.data.model
      .action('ChangePassword')
      .execute(data)
      .subscribe({
        next: () => {
          this.service.savingState$.next(false);
          this.notification.successLocal(
            'shared.userSettings.security.password.wasChanged',
          );
          this.service.close();
        },
        error: (error: any) => {
          this.notification.error(error.message);
          this.service.savingState$.next(false);
        },
      });
  }

  ngOnInit() {
    this.savingSubscription = this.service.save$.subscribe(() => this.apply());
    this.tenantSetting.getPasswordRequirements().subscribe({
      next: (data) => {
        this.passwordRequirements$$.next(data);
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
      },
    });
  }

  ngOnDestroy(): void {
    this.savingSubscription.unsubscribe();
  }
}
