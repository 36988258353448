import { Component, Input, OnInit } from '@angular/core';
import { WorkflowFunctionNotification } from '../../model/workflow-function.model';
import { WorkflowFunctionType } from '../../model/workflow.enum';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { WorkflowCardService } from '../workflow-card.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridOptions } from 'src/app/shared-features/grid/models/grid-options.model';

@Component({
  selector: 'wp-workflow-notification',
  templateUrl: './workflow-notification.component.html',
  styleUrls: ['./workflow-notification.component.scss'],
  providers: [GridService],
  standalone: false,
})
export class WorkflowNotificationComponent implements OnInit {
  @Input() workflowFunction?: WorkflowFunctionNotification;
  @Input() lifecycleId: string;

  public isLoading: boolean;
  public isSaving: boolean;
  public readonly: boolean;
  public functionType = WorkflowFunctionType;
  public functionId: string;

  public form = this.fb.group({
    id: null,
    name: ['', Validators.required],
    nextFunctionId: null,
    labelStrings: null,
    type: '',
    recipients: this.fb.array([]),
  });

  private workflowFunctions = [];

  public get labelStrings(): UntypedFormControl {
    return this.form.controls['labelStrings'] as UntypedFormControl;
  }

  public get nextFunctionIdControl(): UntypedFormControl {
    return this.form.controls['nextFunctionId'] as UntypedFormControl;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    private notification: NotificationService,
    public workflowCardService: WorkflowCardService,
  ) {}

  public ngOnInit(): void {
    this.load();

    this.workflowCardService.workflow?.functions.forEach((func) => {
      if (func.type !== WorkflowFunctionType.start) {
        this.workflowFunctions.push({ id: func.id, name: func.name });
      }
    });
  }

  /**
   * Gets header for transitionFormGroup.
   *
   * @returns header.
   */
  public getHeader(): string {
    return this.workflowFunction.name
      ? this.workflowFunction.name
      : 'settings.workflows.card.function.createNotification';
  }

  /** Closes modal and sends data to service. */
  public ok(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const newWorkflowFunction = this.form.getRawValue();
    this.activeModal.close(newWorkflowFunction);
  }

  /** Closes modal and dismisses changes. */
  public cancel = (): void => {
    this.activeModal.dismiss('cancel');
  };

  private load(): void {
    if (!this.workflowFunction) {
      return;
    }

    this.form.patchValue(this.workflowFunction);

    this.workflowFunction.recipients?.map((recipient) => {
      if (recipient.userId) {
        recipient.id = recipient.userId;
        recipient.type = 'user';
      } else if (recipient.groupId) {
        recipient.id = recipient.groupId;
        recipient.type = 'group';
      } else if (recipient.role) {
        recipient.id = recipient.role;
        recipient.name = this.workflowCardService.getRoleName(recipient.role);
        recipient.type = 'role';
      }

      const performerGroup = this.workflowCardService.getPerformerFormGroup();

      performerGroup.controls.performer.patchValue(recipient);
      const performersArray = this.form.controls.recipients as UntypedFormArray;
      performersArray.push(performerGroup);
    });
  }
}
