import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { CustomFieldCardService } from 'src/app/settings-app/custom-fields/card/custom-field-card.service';
import {
  MetaEntityPropertyType,
  metaEntityStringPropertyTypes,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-field-configuration',
  templateUrl: './field-configuration.component.html',
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FieldConfigurationComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() readonly: boolean;
  @Input() fieldType: MetaEntityPropertyType;
  @Input() fieldEntityType: string;
  @Input() public formColumns: NamedEntity[];

  public get isStringType(): boolean {
    return metaEntityStringPropertyTypes.includes(this.fieldType);
  }

  public readonly customFieldType = MetaEntityPropertyType;

  private destroyRef = inject(DestroyRef);

  constructor(
    public cardService: CustomFieldCardService,
    private gridService: GridService,
  ) {}

  public ngOnInit(): void {
    this.cardService.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.gridService.detectChanges();
      });
  }
}
