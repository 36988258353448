import {
  Component,
  ChangeDetectionStrategy,
  Input,
  ElementRef,
  AfterViewInit,
  DestroyRef,
  inject,
  viewChild,
  output,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Chart, { CategoryScale, LinearScale } from 'chart.js/auto';
import { FunnelController, TrapezoidElement } from 'chartjs-chart-funnel';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BehaviorSubject, filter } from 'rxjs';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import _ from 'lodash';

@Component({
  selector: 'tmt-funnel-chart',
  template: '<canvas #funnelCanvas></canvas>',
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class WidgetFunnelChartComponent implements AfterViewInit {
  readonly funnelCanvas = viewChild<ElementRef>('funnelCanvas');
  @Input() set data(value: (string & number)[][]) {
    this.dataSubject.next(value || []);
  }
  public dataChanged = output<boolean>();

  private labels: string[] = [];
  private datasets: number[] = [];
  private chart: Chart;
  private dataSubject = new BehaviorSubject<(string & number)[][]>([]);
  private destroyRef = inject(DestroyRef);

  public ngAfterViewInit(): void {
    // register controller in chart.js and ensure the defaults are set
    Chart.register(
      FunnelController,
      TrapezoidElement,
      LinearScale,
      CategoryScale,
    );

    this.dataSubject
      .pipe(
        filter((data) => !!data),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data) => {
        this.processData(data);
        this.createFunnelChart();
        this.dataChanged.emit(true);
      });
  }

  /**
   * Resizes the chart.
   *
   * @param width The new width of the chart.
   * @param height The new height of the chart.
   */
  public resize(width: number, height: number): void {
    this.chart?.resize(width, height);
  }

  private createFunnelChart(): void {
    this.chart?.destroy();

    this.chart = new Chart(this.funnelCanvas().nativeElement.getContext('2d'), {
      type: 'funnel',
      data: {
        labels: this.labels,
        datasets: [
          {
            data: this.datasets,
          },
        ],
      },
      options: {
        plugins: {
          tooltip: {
            enabled: true,
          },
        },
        responsive: true,
        indexAxis: 'y',
      },
      plugins: [ChartDataLabels],
    });
  }

  /**
   * Processes source data to prepare labels and datasets for the chart.
   *
   * @param sourceData Source data to process.
   */
  private processData(sourceData: (string & number)[][]): void {
    this.labels = [];
    this.datasets = [];
    let totalLength = 0;

    // Checks if there is a second array in sourceData to display empty chart.
    if (!sourceData[1]?.length) {
      return;
    }

    const modifiedData = sourceData.map((elem) => ({
      label: elem[0],
      value: elem[1],
    }));
    const sortedData = modifiedData.sort(naturalSort('label'));

    sourceData.forEach((data) => {
      totalLength += data[1];
    });

    sortedData.forEach((data) => {
      if (data.value === 0 || data.label === null) {
        return;
      }
      this.labels.push(data.label);
      this.datasets.push(Math.round((data.value / totalLength) * 100) / 100);
    });

    this.labels = this.labels.sort(naturalSort(this.labels[0]));
  }
}
