import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  LOCALE_ID,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  ViewRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import {
  CurrencyPipe,
  DecimalPipe,
  getCurrencySymbol,
  getLocaleNumberSymbol,
  NumberSymbol,
} from '@angular/common';
import { round } from 'lodash';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { CurrencyValue } from 'src/app/shared/models/entities/settings/currency-value.model';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { createPopper, Instance } from '@popperjs/core';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';

/** Контрол ввода валюты. */
@Component({
  selector: 'wp-currency-box',
  templateUrl: './currency-box.component.html',
  styleUrls: ['./currency-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CurrencyBoxComponent),
      multi: true,
    },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CurrencyBoxComponent
  implements OnInit, AfterViewInit, ControlValueAccessor, OnDestroy
{
  /** Заменитель пустого текста. */
  @Input() placeholder: string;

  /** Допускать пустые значения. */
  @Input() allowNull = true;

  /** Символьный код валюты. */
  @Input() currencyCode?;

  /** Заблокировать изменение валюты. */
  @Input() isCurrencyEditable = true;

  /** Только чтение. */
  @Input() readonly = false;

  /** Select the input value after rendering. */
  @Input() autofocus?: boolean;

  /** Значение для отображение (без фокуса если). */
  @Input() set viewValue(value: number) {
    this._viewValue = value;
    if (!this.isFocused) {
      this.setInputValue();
    }
  }
  get viewValue(): number {
    return this._viewValue;
  }

  /** Минимально допустимое значение. */
  @Input() get min(): number {
    return this._min;
  }
  set min(value: number) {
    if (!isNaN(value)) {
      this._min = value;
    }
  }

  /** Максимально допустимое значение. */
  @Input() get max(): number {
    return this._max;
  }
  set max(value: number) {
    if (!isNaN(value)) {
      this._max = value;
    }
  }

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  /** Angular abstract control for binding to form outside of template. */
  @Input() control?: AbstractControl;

  @ViewChild('expandingArea') expandingArea: ElementRef;
  @ViewChild('currencyBoxContainer') currencyBoxContainer: ElementRef;
  @ViewChild('currency') currencyEl: ElementRef;

  private popperInstance: Instance;
  private _editor: ElementRef;
  @ViewChild('editor')
  set editor(value: ElementRef) {
    if (!this._editor) {
      this._editor = value;
      this.input.value = this.getDisplayValue();
    } else {
      this._editor = value;
    }
  }
  get editor(): ElementRef {
    return this._editor;
  }

  private get input() {
    if (this.readonly) {
      return null;
    }
    return this.editor.nativeElement as HTMLInputElement;
  }

  constructor(
    private app: AppService,
    @Inject(LOCALE_ID) public locale: string,
    public wpCurrencyPipe: WpCurrencyPipe,
    private numberPipe: DecimalPipe,
    private ref: ChangeDetectorRef,
    private currencyPipe: CurrencyPipe,
    private service: CurrenciesService,
    private notification: NotificationService,
    private renderer: Renderer2,
  ) {
    this.decimalSeparator = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
  }

  ngAfterViewInit(): void {
    if (this.autofocus && this.input) {
      this.input.select();
    }

    this.applyInitialValue();
  }

  /** Apply initial value after rendering. */
  private applyInitialValue() {
    if (this.initialValue === undefined) {
      return;
    }
    if (this.input) {
      const event = new Event('input');
      if (typeof this.initialValue === 'string') {
        this.input.value = this.initialValue;
        this.input.focus();
        this.input.dispatchEvent(event);
      } else if (this.initialValue === null) {
        this.input.value = '';
        this.input.focus();
        this.input.dispatchEvent(event);
      }
    }
    this.initialValue = undefined;
  }

  private destroyed$ = new Subject<void>();

  /**Доступные валюты. */
  currencies: Currency[] = [];

  private _viewValue: number | null = null;
  previousViewValue: string;
  listOpened = false;
  isLoading = false;

  private _min = 0;

  private _max = 9999999999;

  /** Дефолтное значение контрола. */
  private defaultValue: CurrencyValue = {
    value: null,
    currencyCode: this.app.session.configuration.baseCurrencyCode,
  };
  private _value: CurrencyValue = this.defaultValue;
  set value(obj: CurrencyValue) {
    this._value = obj ? obj : this.defaultValue;
  }
  get value(): CurrencyValue {
    return this._value;
  }

  private decimalSeparator: string;
  private isFocused: boolean;

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  writeValue(value: CurrencyValue): void {
    this.value = value;
    this.ref.detectChanges();
    this.setInputValue();
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  /** Вернуть строку, которую пользователь будет редактировать в контроле. */
  private getStringForEditing() {
    const template = '1.2-2';
    return this.value.value != null
      ? this.numberPipe
          .transform(this.value.value, template)
          .replace(/\s+/g, '')
      : '';
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.ref.detectChanges();
  }

  setInputValue() {
    if (!this.editor || !this.input) {
      return;
    }
    if (this.isFocused) {
      this.input.value = this.getStringForEditing();
    } else {
      this.input.value = this.getDisplayValue();
    }
    this.ref.detectChanges();
  }

  onBlur() {
    this.propagateTouch();
    this.input.value = this.getDisplayValue();
    this.isFocused = false;
  }

  onFocus() {
    this.isFocused = true;
    this.input.value = this.getStringForEditing();
    this.previousViewValue = this.input.value;
    this.input.select();
    this.setInputValue();
  }

  /** Обновить значение разобрав отображаемое значение. */
  updateValueFromDisplayValue() {
    const roundPrecision = 2;

    let value = 0;
    let displayValue: string = this.input.value;

    if (displayValue || displayValue === '0') {
      displayValue = String(displayValue).replace(this.decimalSeparator, '.');

      if (displayValue.indexOf('.') === displayValue.length - 1) {
        displayValue = displayValue.substring(0, displayValue.length - 1);
      }

      // Получаем число.
      const floatValue = parseFloat(displayValue);
      if (!isNaN(floatValue)) {
        value = round(floatValue, roundPrecision);

        if (value > this.max) {
          value = this.max;
        }

        if (value < this.min) {
          value = this.min;
        }
      }
    } else {
      if (this.allowNull) {
        value = null;
      } else {
        // Sets value nearest to zero.
        if (this.min < 0) {
          if (this.max > 0) {
            value = 0;
          } else {
            value = this.max;
          }
        } else {
          value = this.min;
        }
      }
    }

    if (this.value.value !== value) {
      this.value.value = value;
      this.propagateChange(this.value);
    }
  }

  /** Возвращает отображаемый текст. */
  getDisplayValue = () => {
    const value = this.viewValue === null ? this.value.value : this.viewValue;
    return this.getFormattedValue(value);
  };

  private getFormattedValue(value: number): string {
    if (!value && value !== 0) {
      return '';
    }
    if (this.readonly) {
      return this.currencyPipe.transform(
        value,
        this.value.currencyCode,
        'symbol-narrow',
      );
    } else {
      return this.numberPipe.transform(value, '1.2-2');
    }
  }

  private parseCurrencyString(value: string): string {
    // AXT-338. Convert to number.
    const trimmedValue = value.replace(/[^-,.\d]/gm, '');
    return trimmedValue;
  }

  handleInput() {
    const selectionStart = this.input.selectionStart;
    const selectionEnd = this.input.selectionEnd;
    let value = this.input.value;
    value = this.parseCurrencyString(value);

    const separators = [' ', '.', ','];

    separators
      .filter((x) => x !== this.decimalSeparator)
      .forEach((separator: string) => {
        const newValue =
          value.indexOf(this.decimalSeparator) === -1
            ? this.decimalSeparator
            : '';
        value = value.replace(separator, newValue);
      });

    const normalizedString: string = String(value).replace(
      this.decimalSeparator,
      '.',
    );
    if (isNaN(Number(normalizedString))) {
      value = this.previousViewValue;
    }

    if (value !== this.input.value) {
      this.input.value = value;
      this.input.setSelectionRange(selectionStart, selectionEnd);
    }

    if (normalizedString === '-') {
      value = '';
      this.input.value = normalizedString;
      this.input.setSelectionRange(selectionStart, selectionEnd);
    }

    this.previousViewValue = this.input.value;

    this.updateValueFromDisplayValue();
  }

  /** Обновление отступа в интпуте в зависимости от ширины символа валюты. */
  getInputRightPadding() {
    return this.currencyEl?.nativeElement.offsetWidth > 0
      ? this.currencyEl?.nativeElement.offsetWidth * 0.06 + 0.982
      : 1.462;
  }

  mousedownOnRow(event) {
    event.preventDefault();
  }

  clickRow(row: Currency) {
    this.changeCurrency(row);
    this.closeList();
    this.ref.detectChanges();
  }

  closeList() {
    this.listOpened = false;
    this.propagateTouch();
  }

  /** Изменить значение и если оно отличается - передать в форму. */
  private changeCurrency(currency: Currency) {
    if (this._value.currencyCode !== currency.alpha3Code) {
      this.value.currencyCode = currency.alpha3Code;
      this.propagateChange(this.value);
      this.ref.detectChanges();
    }
  }

  openList() {
    if (!this.isCurrencyEditable) {
      return;
    }
    if (this.currencies.length === 0) {
      this.getCurrencies();
    }
    if (this.listOpened) {
      this.closeList();
      return;
    }
    this.listOpened = true;
    this.ref.detectChanges();
    this.popperInstance = createPopper(
      this.currencyBoxContainer.nativeElement,
      this.expandingArea.nativeElement,
      {
        strategy: 'fixed',
        placement: 'bottom-end',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 5],
            },
          },
        ],
      },
    );

    this.show();
  }

  private show() {
    // Make the popper visible
    this.renderer.setAttribute(
      this.expandingArea.nativeElement,
      'data-show',
      '',
    );

    // Update its position
    this.popperInstance.update();
  }

  private getCurrencies() {
    this.isLoading = true;
    this.service.currencies$.pipe(takeUntil(this.destroyed$)).subscribe({
      next: (res: Currency[]) => {
        this.currencies = res.filter((currency) => currency.isActive);
        this.isLoading = false;
        this.ref.detectChanges();
      },
      error: (error: Exception) => {
        this.isLoading = false;
        this.ref.detectChanges();
        this.notification.error(error.message);
      },
    });
  }

  /** Возвращает символ по коду валюты. */
  getNarrowCurrencySymbol = (code: string) =>
    getCurrencySymbol(code, 'narrow', this.locale);

  /** Возвращает символ по коду валюты. */
  getWideCurrencySymbol = (code: string) =>
    getCurrencySymbol(code, 'wide', this.locale);

  ngOnInit(): void {
    this.value.currencyCode =
      this.currencyCode ?? this.app.session.configuration.baseCurrencyCode;
    if (this.placeholder == null) {
      this.placeholder = this.getFormattedValue(0);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
