import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Observable } from 'rxjs';
import { ProjectTeamService } from 'src/app/projects/card/project-team/project-team.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Resource } from 'src/app/shared/models/entities/settings/resource.model';
import { SharedModule } from 'src/app/shared/shared.module';
import _ from 'lodash';
import { NotificationService } from 'src/app/core/notification.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { Exception } from 'src/app/shared/models/exception';
import { DataService } from 'src/app/core/data.service';

@Component({
  selector: 'tmt-duplicate-member-modal',
  imports: [SharedModule],
  templateUrl: './duplicate-member-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DuplicateMemberModalComponent implements OnInit {
  @Input() projectId: string;
  @Input() resource: Resource;
  @Input() existingRoleIds: string[];

  public saving = signal<boolean>(false);

  public roles$: Observable<NamedEntity[]>;

  public form: UntypedFormGroup = new FormGroup({
    role: new FormControl(null, [Validators.required]),
  });

  constructor(
    public projectTeamService: ProjectTeamService,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
    private projectVersionCardService: ProjectVersionCardService,
    private data: DataService,
  ) {}

  public ngOnInit(): void {
    this.roles$ = this.projectTeamService.getTeamMemberRolesObservable(
      this.resource.id,
      this.projectId,
    );
  }

  /** Closes the modal window. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /** Saves the data and closes the modal window. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.saving.set(true);

    const member = {
      resourceId: this.resource.id,
      roleId: this.form.value.role.id,
    };

    ProjectVersionUtil.setEntityRootPropertyId(
      this.projectVersionCardService.projectVersion,
      member,
      this.projectId,
    );

    this.data
      .collection('ProjectTeamMembers')
      .insert(member)
      .subscribe({
        next: () => {
          this.saving.set(false);
          this.notification.successLocal(
            'projects.projects.card.team.messages.membersWereAdded',
          );
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.saving.set(false);
        },
      });
  }
}
