import { Pipe, PipeTransform } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

/** Отображение булевого значения в формате Да/Нет. */
@Pipe({
  name: 'wpBoolean',
  standalone: false,
})
export class BooleanPipe implements PipeTransform {
  constructor(private translate: TranslateService) {}

  transform(value: any): any {
    return value === true
      ? this.translate.instant('shared.yes')
      : this.translate.instant('shared.no');
  }
}
