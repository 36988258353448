@if (!disabled) {
  <div
    class="input-group"
    (clickOutside)="cancel()"
    [excludeBeforeClick]="true"
    [attachOutsideOnClick]="true"
  >
    <input
      #input
      class="form-control"
      (blur)="onBlur()"
      [formControl]="textControl"
      (keydown)="onKeyDown($event)"
      type="text"
      title="{{ title }}"
      placeholder="{{ placeholder }}"
      (click)="onInputClick()"
    />

    <span
      class="input-clear"
      title="{{ 'shared.hintClearControlButton' | translate }}"
      (click)="clear()"
      [hidden]="!textControl.value"
      >&times;</span
    >

    @if (listOpened) {
      <div id="{{ areaId }}" class="expanding-area expanding-area-vertical-scroll">
        @if (!rows.length) {
          <div class="center-block" style="text-align: center; padding: 3px">
            <p class="text-body-secondary">{{ 'shared.noOptions' | translate }}</p>
          </div>
        }
        <table class="selecting-list">
          <tbody>
            @for (row of rows; track $index) {
              <tr
                (mouseenter)="selectRow(row)"
                (click)="clickRow(row)"
                id="{{ row.id }}"
                title="{{ row.name }}"
                [ngClass]="{ 'selected-row': row.id == selectedRow?.id }"
              >
                <td class="trim">{{ row.name }}</td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }

    @if (customPeriodOpened) {
      <div name="area" class="expanding-area custom-period-form" [formGroup]="datesForm">
        <div>
          <wp-date-box formControlName="from"></wp-date-box>
          <wp-date-box formControlName="to"></wp-date-box>
        </div>

        <div>
          @if (datesForm.valid) {
            <button type="button" class="btn btn-primary" (click)="selectCustomPeriod()">
              {{ 'shared.actions.ok' | translate }}
            </button>
          }
          <button type="button" class="btn btn-default" (click)="cancel()">
            {{ 'shared.actions.cancel' | translate }}
          </button>
        </div>
      </div>
    }

    <button
      tabindex="-1"
      type="button"
      class="btn btn-default input-group-text"
      (click)="openList()"
    >
      <i class="bi bi-chevron-down" aria-hidden="true"></i>
    </button>
  </div>
}

@if (disabled && value) {
  <p class="trim" title="{{ title }}">
    {{ textControl.value }}
  </p>
}

@if (disabled && !value) {
  <p class="text-body-secondary text-uppercase">
    {{ 'shared.empty' | translate }}
  </p>
}
