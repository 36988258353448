import { Component, DestroyRef, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { DataService } from 'src/app/core/data.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-copy-dashboard-modal',
  templateUrl: './copy-dashboard-modal.component.html',
  standalone: false,
})
export class CopyDashboardModalComponent {
  @Input() dashboardId: string;

  public form = this.fb.group({
    name: [
      null,
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
  });
  public isSaving: boolean;

  private createdId: string;
  private destroyRef = inject(DestroyRef);

  constructor(
    private state: StateService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private navigationService: NavigationService,
  ) {}

  /** Copies dashboard. */
  public copy(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    this.data
      .collection('Dashboards')
      .entity(this.dashboardId)
      .action('WP.Copy')
      .execute(this.form.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: async (createdId: string) => {
          this.createdId = createdId;

          await this.navigationService.reloadCustomNavigationItems();

          this.notification.successLocal(
            'analytics.dashboards.copyingDashboard.messages.wasCopied',
          );
          this.isSaving = false;
          this.activeModal.close();
          this.state.go('dashboard', {
            entityId: this.createdId,
            navigation: `analytics.${this.createdId}`,
          });
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  /** Closes modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
