@if (issuesForm.controls.length) {
  @for (timeAllocation of issueTimeAllocations; track $index) {
    <div class="issue-entry" [formGroup]="issuesForm.at($index)">
      <div class="issue-entry__header">
        <button class="btn btn-link" (click)="openTimeEntry(timeAllocation)">
          {{ timeAllocation.issue.name }}
        </button>
        <div>
          <span class="text-nowrap">{{ timeAllocation.hours | wpWork }}</span>
        </div>
      </div>

      <div class="issue-entry__body">
        <div class="issue-entry__description" [title]="timeAllocation.description">
          {{ timeAllocation.description | truncate: 125 }}
        </div>
        <!-- <wp-custom-fields
          [formGroup]="issuesForm.at($index)"
          [fields]="timesheetCardService.allocationCustomFields"
          [isVisibleHr]="false"
          class="feed-item__custom-fields"
        /> -->
      </div>
    </div>
  }
} @else {
  <p class="text-body-secondary text-uppercase text-center mt-1 mb-1">
    {{ 'shared.empty' | translate }}
  </p>
}
