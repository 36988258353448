import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { NavigationService } from 'src/app/core/navigation.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { EntityNavigation } from 'src/app/shared/models/navigation/navigation';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { AsyncPipe } from '@angular/common';
import {
  GridSelectControlColumn,
  GridColumnType,
} from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
    selector: 'tmt-navigation-items',
    templateUrl: './navigation-items.component.html',
    providers: [GridService],
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [GridModule, TranslateModule, AsyncPipe]
})
export class NavigationItemsComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() navigations: FormArray;
  @Input() navigationResponse: EntityNavigation[];

  public gridOptions: GridOptions;
  public areas: Record<string, string>[] = [];

  private destroyRef = inject(DestroyRef);
  private groupsObject: Record<string, Record<string, string>[]> = {};

  constructor(
    public gridService: GridService,
    private fb: FormBuilder,
    private translateService: TranslateService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit(): void {
    this.initAreasGroupsOptions();
    this.initNavigationArray();

    this.gridOptions = {
      css: 'wp-nested-table stop-context',
      selectionType: SelectionType.row,
      commands: [
        {
          name: 'add',
          handlerFn: () => this.addNavigationItem(),
          allowedFn: () => !this.readonly,
        },
      ],
      rowCommands: [
        {
          name: 'delete',
          label: 'shared2.actions.delete',
          handlerFn: (_, index: number) => {
            this.navigations.removeAt(index);
            this.navigations.markAsDirty();
          },
          allowedFn: () => !this.readonly,
        },
      ],
      view: {
        name: 'navigation',
        columns: [
          <GridSelectControlColumn>{
            name: 'area',
            header: 'components.boardCardNavigationsComponent.props.area.value',
            hint: 'components.boardCardNavigationsComponent.props.area.value',
            placeholder: this.translateService.instant(
              'components.boardCardNavigationsComponent.props.area.ph',
            ),
            type: GridColumnType.SelectControl,
            values: () =>
              this.areas.filter(
                (item) =>
                  !this.navigations.value?.some(
                    (nav) => nav.area?.key === item.key,
                  ),
              ),
            allowNull: false,
          },
          <GridSelectControlColumn>{
            name: 'group',
            header:
              'components.boardCardNavigationsComponent.props.group.value',
            hint: 'components.boardCardNavigationsComponent.props.group.value',
            placeholder: this.translateService.instant(
              'components.boardCardNavigationsComponent.props.group.ph',
            ),
            type: GridColumnType.SelectControl,
            values: () =>
              this.groupsObject[
                this.gridService.selectedGroup$.getValue()?.get('area')?.value
                  ?.key
              ] ?? [],
            allowNull: false,
          },
        ],
      },
    };
  }

  /** Adds navigation item. */
  public addNavigationItem(): void {
    this.navigations.push(
      this.fb.group({
        area: [null, Validators.required],
        group: [{ value: null, disabled: true }, Validators.required],
        id: Guid.generate(),
      }),
    );

    const addingGroup = this.navigations.at(
      this.navigations.length - 1,
    ) as FormGroup;
    this.initNavigationRowSubscription(addingGroup);
    this.gridService.selectGroup(addingGroup);
    addingGroup.markAsDirty();
  }

  /** Removes navigation item. */
  public removeNavigationItem(): void {
    this.navigations.removeAt(
      this.navigations.controls.findIndex(
        (control) =>
          control.value.id === this.gridService.selectedGroupValue.id,
      ),
    );
    this.navigations.markAsDirty();
  }

  /**
   * Inits row area value change subscription.
   *
   * @param row form array item.
   */
  private initNavigationRowSubscription(row: FormGroup): void {
    row
      .get('area')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        value ? row.get('group').enable() : row.get('group').disable();
        if (
          !this.groupsObject[value?.key]?.find(
            (group) => group.key === row.get('group').value?.key,
          )
        ) {
          row.get('group').setValue(null);
        }
      });
  }

  /** Inits navigations array values. */
  private initNavigationArray(): void {
    this.navigations.clear();
    for (const nav of this.navigationResponse) {
      const group = this.fb.group({
        area: [
          this.areas.find((area) => area.key === nav.area),
          Validators.required,
        ],
        group: [
          this.groupsObject[nav.area].find((group) => group.key === nav.group),
          Validators.required,
        ],
        id: Guid.generate(),
      });
      this.navigations.push(group);
      this.initNavigationRowSubscription(group);
      if (this.readonly) {
        this.navigations.disable({ emitEvent: false });
      }
    }
  }

  /** Inits areas and groups select options. */
  private initAreasGroupsOptions(): void {
    this.navigationService.appToNavigationMap.forEach((navigation) => {
      this.areas.push({
        id: Guid.generate(),
        name: this.translateService.instant(navigation.applicationTitle),
        key: navigation.name,
      });

      this.groupsObject[navigation.name] = [];
      navigation.groups.map((group) =>
        this.groupsObject[navigation.name].push({
          id: Guid.generate(),
          name: this.translateService.instant(group.header),
          key: group.name,
        }),
      );
    });

    this.areas.sort(naturalSort('name'));
  }
}
