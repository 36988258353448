import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { CurrencyBoxComponent } from 'src/app/shared/components/controls/currency-box/currency-box.component';

import { Wrapper } from './wrapper.interface';

@Component({
  selector: 'wp-currency-box-wrapper',
  template: `<wp-currency-box
    [formControl]="control"
    [min]="min"
    [max]="max"
    [allowNull]="allowNull"
    [isCurrencyEditable]="isCurrencyEditable"
    [currencyCode]="currencyCode"
    [autofocus]="autofocus"
    [readonly]="readonly"
    #component
  ></wp-currency-box>`,
  standalone: false,
})
export class CurrencyBoxWrapperComponent implements Wrapper, OnInit {
  @Input() control: UntypedFormControl;

  @Input() values: NamedEntity[];
  @Input() min: number;
  @Input() max: number;
  @Input() allowNull: boolean;
  @Input() isCurrencyEditable: boolean;
  @Input() currencyCode: string;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;

  @ViewChild('component') component: CurrencyBoxComponent;

  public ngOnInit(): void {
    if (this.allowNull === undefined) {
      this.allowNull = true;
    }
  }
}
