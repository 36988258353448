import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Observable } from 'rxjs';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { Issue } from 'src/app/issues/models/issue.model';
import { IssueType } from 'src/app/settings-app/issue-types/model/issue-type.model';

@Component({
  selector: 'tmt-changing-issue-type-modal',
  templateUrl: './changing-issue-type-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ChangingIssueTypeModalComponent implements OnInit {
  @Input() issue: Issue;

  public form = this.fb.group({
    type: [null, Validators.required],
  });
  public isSaving = signal<boolean>(false);
  public types$: Observable<IssueType[]>;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
  ) {}

  public ngOnInit(): void {
    this.types$ = this.dataService
      .collection('IssueTypes')
      .function('GetEditableTypes')
      .query<IssueType[]>(null, {
        select: ['id', 'name', 'iconClass'],
        filter: {
          id: {
            ne: { type: 'guid', value: this.issue.type.id },
          },
          isActive: true,
        },
      });
  }

  /** Changes issue type. */
  public changeType(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);

    this.dataService
      .collection('Issues')
      .entity(this.issue.id)
      .action('SetType')
      .execute({
        typeId: this.form.value.type.id,
      })
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'components.changingIssueTypeModalComponent.messages.issueTypeWasChanged',
          );
          this.isSaving.set(false);
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /** Closes modal. */
  public closeModal(): void {
    this.activeModal.dismiss();
  }
}
