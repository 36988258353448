import { Component, Input } from '@angular/core';
import { NamedEntity } from '../../../../../shared/models/entities/named-entity.model';
import { AccrualPolicyService } from '../accrual-policy.service';
import { TimeOffTypeCardService } from '../../time-off-type-card.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wp-accrual-policy-section',
  templateUrl: './accrual-policy-section.component.html',
  styleUrls: ['./accrual-policy-section.component.scss'],
  providers: [AccrualPolicyService],
  standalone: false,
})
export class AccrualPolicySectionComponent {
  @Input() timeOffTypeUnit: NamedEntity;
  @Input() entityId: string;
  public accrualPolicyName: string;
  constructor(
    public service: AccrualPolicyService,
    public timeOffTypeService: TimeOffTypeCardService,
    private translate: TranslateService,
  ) {
    timeOffTypeService.timeOffType$.subscribe((value) => {
      this.accrualPolicyName = this.translate.instant(
        `enums.accrualPolicyType.${value.accrualPolicy?.accrualType}`,
      );
    });
  }
}
