<wp-loading-indicator [state]="issueCardService.state$ | async" />
<tmt-not-found [state]="issueCardService.state$ | async" [urlMustContain]="entityId" />
<wp-data-not-saved-indicator
  class="d-none"
  [form]="issueCardService.issueForm"
  [isDirty]="issueCardService.isEditorOpened"
/>

@if ((issueCardService.state$ | async) === 'Ready') {
  <tmt-form-header
    [name]="issueCardService.formValue?.name"
    [isEditable]="(issueCardService.issue$ | async)?.editAllowed"
    [saveFn]="saveName"
  />

  <div class="subheader mt-3">
    <div class="subheader__props">
      <div class="subheader__prop">
        <wp-state-indicator />
      </div>

      <div class="subheader__prop">
        <label class="subheader__label"> {{ 'shared2.props.code' | translate }}: </label>
        <div class="subheader__value" data-test="code">
          <a uiSref="issue" [uiParams]="{ entityId: entityId }">{{
            issueCardService.issue?.code
          }}</a>
        </div>
      </div>

      <div class="subheader__prop">
        <label class="subheader__label"> {{ 'shared2.props.created.value' | translate }}: </label>
        <div class="subheader__value" data-test="created">
          {{
            issueCardService.issue?.created | date: 'short' : this.appService.session.timeZoneOffset
          }}
        </div>
      </div>

      <div class="subheader__prop">
        <label class="subheader__label"> {{ 'shared2.props.createdBy.value' | translate }}: </label>

        <div class="subheader__value" data-test="createdBy" #createdBy>
          <button
            type="button"
            (click)="openCreatedByUserInfo(createdBy)"
            class="btn btn-link btn-link-content"
          >
            {{ issueCardService.issue.createdBy?.name }}
          </button>
        </div>
      </div>
    </div>
  </div>

  <form class="content form" [formGroup]="issueCardService.issueForm">
    <div class="description">
      <tmt-rich-editor-box
        formControlName="description"
        (mentionedUserIds$)="onMentionIds($event)"
        (editing$)="issueCardService.isEditorOpened = $event"
        [emptyText]="
          issueCardService.issueForm.get('description').disabled
            ? ''
            : 'components.issueCardComponent.props.descriptionPlaceholder'
        "
        placeholder="{{ 'shared2.props.description' | translate }}"
      />
    </div>

    <div class="attachments form-group" tmtIndicator>
      <label>{{ 'shared2.props.attachments' | translate }}</label>
      <tmt-file-box
        [entityType]="entityType"
        [entityId]="entityId"
        [editAllowed]="(issueCardService.issue$ | async).editAllowed"
      />
    </div>

    <div class="parameters" ngbAccordion>
      <div
        ngbAccordionItem
        [disabled]="!isInOffCanvas"
        [collapsed]="settings.parametersCollapsed"
        (hidden)="updateSettings('parametersCollapsed', true)"
        (shown)="updateSettings('parametersCollapsed', false)"
      >
        <div ngbAccordionHeader>
          <button ngbAccordionButton class="text-uppercase">
            {{ 'components.issueCardComponent.groups.parameters' | translate }}
          </button>
        </div>

        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <ng-template>
              <div class="form-group">
                <label>{{ 'shared2.props.type' | translate }}</label>
                <div class="form-control-plaintext d-flex" data-test="type">
                  @if ((issueCardService.issue$ | async).type.iconClass) {
                    <i class="me-1 bi {{ (issueCardService.issue$ | async).type.iconClass }}"></i>
                  }

                  {{ (issueCardService.issue$ | async).type.name }}
                </div>
              </div>

              @if ((issueCardService.issue$ | async)?.resolution) {
                <div class="form-group">
                  <label>{{ 'components.issueCardComponent.props.resolution' | translate }}</label>
                  <div class="form-control-plaintext" data-test="resolution">
                    {{ (issueCardService.issue$ | async).resolution.name }}
                  </div>
                </div>
              }

              @if ((issueCardService.issue$ | async)?.resolution) {
                <div class="form-group">
                  <label>{{
                    'components.issueCardComponent.props.resolutionComment' | translate
                  }}</label>
                  <div class="form-control-plaintext" data-test="resolutionComment">
                    @if ((issueCardService.issue$ | async).resolutionComment) {
                      {{ (issueCardService.issue$ | async).resolutionComment }}
                    } @else {
                      <span class="text-body-secondary text-uppercase">
                        {{ 'shared.valueNotSelected' | translate }}
                      </span>
                    }
                  </div>
                </div>
              }

              <div class="form-group">
                <label>{{ 'components.issueCardComponent.props.priority' | translate }}</label>
                <wp-select-box
                  formControlName="priority"
                  collection="IssuePriorities"
                  placeholder="{{ 'components.issueCardComponent.props.priority' | translate }}"
                  [allowNull]="false"
                />
              </div>

              <div class="form-group">
                <label>{{ 'components.issueCardComponent.props.dueDate' | translate }}</label>
                <wp-date-box formControlName="dueDate" />
              </div>

              <div class="form-group">
                <label>{{ 'components.issueCardComponent.props.initiator' | translate }}</label>
                <wp-user-box
                  placeholder="{{ 'components.issueCardComponent.props.initiator' | translate }}"
                  formControlName="initiator"
                  [allowNull]="false"
                />
              </div>

              <div class="form-group">
                <label>{{ 'shared2.props.assigned' | translate }}</label>
                <wp-user-box
                  placeholder="{{ 'shared2.props.assigned' | translate }}"
                  formControlName="assigned"
                />
              </div>

              <div class="form-group">
                <label>{{ 'components.issueCardComponent.props.followers' | translate }}</label>
                <tmt-multiselect-box
                  formControlName="followers"
                  collection="Users"
                  placeholder="{{ 'components.issueCardComponent.props.followers' | translate }}"
                />
              </div>

              <div class="form-group">
                <label>{{ 'shared2.props.project' | translate }}</label>
                <wp-select-box
                  formControlName="project"
                  collection="Projects"
                  placeholder="{{ 'shared2.props.project' | translate }}"
                />
              </div>

              <div class="form-group mb-4">
                <label>{{ 'components.issueCardComponent.props.projectTask' | translate }}</label>
                <tmt-task-box
                  formControlName="projectTask"
                  [projectId]="issueCardService.issueForm.getRawValue().project?.id"
                  placeholder="{{ 'components.issueCardComponent.props.projectTask' | translate }}"
                  [indent]="5"
                />
              </div>

              <wp-custom-fields
                [formGroup]="issueCardService.issueForm"
                [entityType]="'Issue'"
                columnClasses="col-12"
              />
            </ng-template>
          </div>
        </div>
      </div>
    </div>

    <div class="comments">
      <div class="d-flex">
        <ul
          ngbNav
          #nav="ngbNav"
          [(activeId)]="activeTab"
          class="nav nav-underline w-100 text-nowrap flex-nowrap overflow-hidden"
          tmtTruncateDirective
        >
          <li [ngbNavItem]="'activity'">
            <a ngbNavLink>
              {{ 'shared.comments.tabs.activity' | translate }}
            </a>
            <div *ngbNavContent class="wide-tab-container mt-3">
              <wp-comments-tab
                [entityId]="entityId"
                [collection]="commentedEntityCollectionType.Issues"
                (queueLength$)="issueCardService.isCommentOpened = !!$event"
              />
            </div>
          </li>
          <li [ngbNavItem]="'attachments'">
            <a ngbNavLink>
              {{ 'shared.comments.tabs.attachments' | translate }}
            </a>
            <div *ngbNavContent class="wide-tab-container mt-3">
              <wp-attachments-tab
                [entityId]="entityId"
                [collection]="commentedEntityCollectionType.Issues"
              />
            </div>
          </li>
          <li [ngbNavItem]="'linkedTasks'">
            <a ngbNavLink>
              {{ 'base.workflow.tab' | translate }}
            </a>
            <div *ngbNavContent class="scroll-tab-container mt-3">
              <tmt-entity-workflow-tasks [entityId]="entityId" />
            </div>
          </li>
          <li [ngbNavItem]="'stateHistory'">
            <a ngbNavLink>
              {{ 'base.stateHistory.tab' | translate }}
            </a>
            <div *ngbNavContent class="scroll-tab-container mt-3">
              <tmt-state-history [entityId]="entityId" />
            </div>
          </li>
          <li [ngbNavItem]="'workLog'">
            <a ngbNavLink>
              {{ 'shared2.props.workLog' | translate }}
            </a>
            <div *ngbNavContent class="mt-3">
              <tmt-work-log />
            </div>
          </li>
        </ul>

        <wp-sort class="ms-auto text-nowrap flex-nowrap" />
      </div>
      <div [ngbNavOutlet]="nav"></div>
    </div>
  </form>
}
