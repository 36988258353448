import { InjectionToken } from '@angular/core';

export class ProjectTaskConstants {
  public static indentWidth = 18;
}

export const TASKS_VIEW = new InjectionToken<string>('tasksView');

/** Max available project task level for client application. */
export const taskMaxLevel = 7;
