import { Component, DestroyRef, OnInit, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { StateService } from '@uirouter/core';
import { DateTime } from 'luxon';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-accounting-period',
  templateUrl: './accounting-period-creation.component.html',
  standalone: false,
})
export class AccountingPeriodCreationComponent implements OnInit {
  public isSaving = false;
  public form: UntypedFormGroup = this.fb.group({
    start: [DateTime.now().startOf('month').toISODate(), Validators.required],
    end: [DateTime.now().endOf('month').toISODate(), Validators.required],
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    const data = this.form.value;

    this.data
      .collection('AccountingPeriods')
      .insert({ ...data, name: 'period' })
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'finance.periods.creation.messages.created',
          );
          this.state.go('accountingPeriod', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit() {
    FormHelper.controlDatePair(
      this.form,
      'start',
      'end',
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
