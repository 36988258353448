import { Injectable, Renderer2 } from '@angular/core';
import { ViewSettings } from '../models/view-settings/view-settings.model';
import { PivotDataService } from './pivot-data.service';
import { UserSettings, SortOrder } from '../models/user-settings.model';
import { ReportFieldType } from '../models/report-field-type.enum';
import { TranslateService } from '@ngx-translate/core';
import { DecimalPipe, DatePipe, PercentPipe } from '@angular/common';
import { TotalFunction } from '../models/total-function.enum';
import { LogService } from 'src/app/core/log.service';
import { PivotRow } from './model/pivot-row.model';
import { PivotGroupRow } from './model/pivot-group-row.model';
import { PivotColumn } from './model/pivot-column.model';
import { ReportField } from '../models/source-description/report-field.model';
import { Subject } from 'rxjs';
import { AppService } from 'src/app/core/app.service';

/** Сервис формирования тела сводной таблицы отчета. */
@Injectable()
export class PivotRenderService {
  private addTotalColumn = true;

  private yesLabel = this.translate.instant('shared.yes');
  private noLabel = this.translate.instant('shared.no');

  totalWidth = 0;
  groupStep = 20;
  pageSize = 50;

  public sort$ = new Subject<string>();
  public menu$ = new Subject<[string, any]>();

  private pageRenderingCycleIndex = 0;
  private displayRowsCount = 0;

  private alignRightTypes: ReportFieldType[] = [
    ReportFieldType.Decimal,
    ReportFieldType.Integer,
    ReportFieldType.Percent,
  ];
  private sortAscentClass = 'wp-sort-ascent';
  private sortDescentClass = 'wp-sort-descent';

  componentRef: HTMLElement;

  tableHeader: HTMLTableElement;
  tableBody: HTMLTableElement;
  tableFooter: HTMLTableElement;

  private viewSettings: ViewSettings;
  public userSettings: UserSettings;

  private hasValueGrouping: boolean;
  private hasRowGrouping: boolean;
  private flatColumnNames: string[];
  private emptyValueSpaceWasFilled = false;
  totalCols: number;

  constructor(
    private app: AppService,
    private log: LogService,
    private numberPipe: DecimalPipe,
    private datePipe: DatePipe,
    private percentPipe: PercentPipe,
    private renderer: Renderer2,
    private dataService: PivotDataService,
    private translate: TranslateService,
  ) {}

  /** Инициализировать сервис. */
  public init(viewSettings: ViewSettings, userSettings: UserSettings) {
    this.viewSettings = viewSettings;
    this.userSettings = userSettings;

    this.tableHeader = this.componentRef.querySelector('[name=header]');
    this.tableBody = this.componentRef.querySelector('[name=body]');
    this.tableFooter = this.componentRef.querySelector('[name=footer]');

    this.hasValueGrouping = this.viewSettings.columnGroupFields?.length > 0;
    this.hasRowGrouping = this.viewSettings.rowGroupFields?.length > 0;

    this.flatColumnNames = [];
    viewSettings.columnFields.forEach((field) => {
      this.flatColumnNames.push(field.name);
    });

    if (!this.hasValueGrouping) {
      viewSettings.valueFields.forEach((field) => {
        this.flatColumnNames.push(field.name);
      });
    }

    if (this.flatColumnNames.length === 0 && this.hasRowGrouping) {
      this.flatColumnNames.push(viewSettings.rowGroupFields[0].name);
    }
  }

  /** Перерисовать таблицу. */
  public redraw(onlySort?: boolean) {
    this.dataService.sort();
    if (onlySort) {
      this.renderBody();
    } else {
      this.renderHeader();
      this.renderBody();
      this.renderFooter();
    }
    this.updateSortIndicator();
  }

  /**  Рендеринг THEAD. */
  public renderHeader() {
    const thead = this.tableHeader.querySelector('thead');

    this.clearNode(thead);
    this.renderColumnsDefinition(this.tableHeader);

    let line = this.renderer.createElement('tr');

    const groupsCount = this.viewSettings.columnGroupFields.length;

    let columnIndex = 0;
    this.getFlatColumnNamesForHeader().forEach((columnName) => {
      const fieldDescription = this.dataService.fieldsDescriptions.find(
        (f) => f.name === columnName,
      );

      const cell = this.renderer.createElement('th');
      if (this.hasValueGrouping) {
        this.renderer.setAttribute(
          cell,
          'rowspan',
          (groupsCount + 1).toString(),
        );
      }
      this.renderer.setAttribute(cell, 'name', columnName);
      this.renderer.setAttribute(cell, 'index', columnIndex.toString());
      this.renderer.addClass(cell, 'analytic');
      this.renderer.addClass(cell, 'disable-user-select');
      columnIndex++;

      const title = this.renderer.createElement('div');
      this.renderer.setAttribute(cell, 'title', fieldDescription.title);

      this.renderer.appendChild(
        title,
        this.renderer.createText(fieldDescription.title),
      );

      const menu = this.renderer.createElement('i');
      this.renderer.addClass(menu, 'bi');
      this.renderer.addClass(menu, 'bi-list');

      this.renderer.listen(title, 'click', () =>
        this.sort$.next(fieldDescription.name),
      );
      this.renderer.listen(menu, 'click', (event) =>
        this.menu$.next([fieldDescription.name, event]),
      );

      this.renderer.appendChild(cell, title);
      this.renderer.appendChild(cell, menu);

      this.renderer.appendChild(line, cell);
    });

    // Дальше сводные колонки
    for (let i = 0; i <= groupsCount && this.hasValueGrouping; i++) {
      const columns = this.getColumnsLevel(i, 0, null);

      columns.forEach((column) => {
        if (i < groupsCount && column.isGroup) {
          const colspan = this.getColumnGroupCellsCount(column);

          const fieldDescription = this.dataService.fieldsDescriptions.find(
            (f) => f.name === column.groupFieldName,
          );

          let cellValue;
          if (column.value === null) {
            cellValue = this.translate.instant('analytics.pivotTable.empty');
          } else {
            cellValue = this.getFormattingCellValue(
              fieldDescription.type,
              column.value,
            );
          }

          const cell = this.renderer.createElement('th');
          this.renderer.addClass(cell, 'group');
          this.renderer.setAttribute(cell, 'title', cellValue);
          this.renderer.setAttribute(cell, 'colspan', colspan.toString());

          // Добавление коллапсера
          if (i < groupsCount - 1) {
            let hint = column.isExpanded
              ? 'analytics.pivotTable.collapse'
              : 'analytics.pivotTable.expand';
            hint = this.translate.instant(hint);

            const collapser = this.renderer.createElement('i');
            this.renderer.setAttribute(collapser, 'title', hint);
            this.renderer.addClass(collapser, 'bi');

            this.renderer.addClass(
              collapser,
              column.isExpanded ? 'bi-minus-square' : 'bi-plus-square',
            );
            this.renderer.listen(collapser, 'click', () =>
              this.valueGroupCollapserHandler(column),
            );
            this.renderer.appendChild(cell, collapser);
          }

          this.renderer.appendChild(cell, this.renderer.createText(cellValue));
          this.renderer.appendChild(line, cell);
        }

        if (i === groupsCount) {
          const cell = this.renderer.createElement('th');
          this.renderer.addClass(cell, 'value');
          this.renderer.setAttribute(cell, 'title', column.title);
          this.renderer.appendChild(
            cell,
            this.renderer.createText(column.title),
          );
          this.renderer.appendChild(line, cell);
        }

        if (column.isSummarize) {
          const cell = this.renderer.createElement('th');
          this.renderer.addClass(cell, 'group');
          this.renderer.setAttribute(
            cell,
            'colspan',
            this.dataService.valueFieldDescriptions.length.toString(),
          );
          this.renderer.appendChild(cell, this.renderer.createText('Σ'));
          this.renderer.appendChild(line, cell);
        }
      });

      if (i === 0 && this.addTotalColumn) {
        const name = this.translate.instant('analytics.pivotTable.total');

        const cell = this.renderer.createElement('th');
        this.renderer.addClass(cell, 'group-total');
        this.renderer.setAttribute(
          cell,
          'colspan',
          this.dataService.valueFieldDescriptions.length.toString(),
        );
        this.renderer.setAttribute(cell, 'rowspan', groupsCount.toString());
        this.renderer.setAttribute(cell, 'title', name);
        this.renderer.appendChild(cell, this.renderer.createText(name));
        this.renderer.appendChild(line, cell);
      }

      if (i === groupsCount && this.addTotalColumn) {
        this.dataService.valueFieldDescriptions.forEach((fieldDescription) => {
          const cell = this.renderer.createElement('th');
          this.renderer.addClass(cell, 'value');
          this.renderer.setAttribute(cell, 'title', fieldDescription.title);
          this.renderer.appendChild(
            cell,
            this.renderer.createText(fieldDescription.title),
          );
          this.renderer.appendChild(line, cell);
        });
      }

      this.renderer.appendChild(thead, line);
      line = this.renderer.createElement('tr');
    }

    this.renderer.appendChild(thead, line);
  }

  /** Рендеринг TBODY. */
  public renderBody() {
    const tbody = this.tableBody.querySelector('tbody');
    this.clearNode(tbody);
    this.renderColumnsDefinition(this.tableBody);

    this.displayRowsCount = 0;

    this.renderPage();

    if (this.dataService.pivotReport.sourceRecordCount === 0) {
      const row = this.renderer.createElement('tr');
      const cell = this.renderer.createElement('td');
      this.renderer.addClass(cell, 'text-body-secondary');
      this.renderer.addClass(cell, 'text-center');
      this.renderer.setAttribute(cell, 'colspan', this.totalCols.toString());
      this.renderer.appendChild(
        cell,
        this.renderer.createText(
          this.translate.instant('shared.noDisplayData'),
        ),
      );
      this.renderer.appendChild(row, cell);
      this.renderer.appendChild(tbody, row);
    }
  }

  /**
   * Рендеринг очередной страницы.
   * @returns - признак отображения всех данных.
   */
  public renderPage(): boolean {
    const tbody = this.tableBody.querySelector('tbody');

    const start = new Date().getTime();
    this.log.debug('Page rendering has been started...');

    this.displayRowsCount += this.pageSize;
    this.pageRenderingCycleIndex = 0;

    if (!this.hasRowGrouping) {
      this.renderRows(tbody, this.dataService.pivotReport.pivotRows, null);
    } else {
      this.renderGroups(
        tbody,
        this.dataService.pivotReport.pivotRows as PivotGroupRow[],
        null,
        false,
      );
    }

    const duration = new Date().getTime() - start;
    this.log.debug('Page rendering has been completed in ' + duration + 'ms.');

    return this.pageRenderingCycleIndex <= this.displayRowsCount;
  }

  /** Рендеринг строки итогов. */
  public renderFooter() {
    const tfoot = this.tableFooter.querySelector('tfoot');

    this.clearNode(tfoot);
    this.renderColumnsDefinition(this.tableFooter);

    const line = this.renderer.createElement('tr');

    this.flatColumnNames.forEach((columnName) => {
      const columnDescription =
        this.dataService.viewFieldDescriptions[columnName];
      this.renderCell(
        line,
        columnDescription,
        this.dataService.pivotReport.values[columnName],
        true,
        true,
      );
    });

    if (this.hasValueGrouping) {
      if (this.dataService.valueFieldDescriptions.length === 0) {
        this.renderEmptyValueSpace(line, true);
      } else {
        // Рендер сводных колонок
        const values = this.getPivotRowValues(
          this.dataService.pivotReport.pivotColumns,
        );
        values.forEach((value) => {
          this.renderCell(line, value.fieldDescription, value.value, true);
        });

        // Рендер итоговой сводной колонки
        if (this.addTotalColumn) {
          this.dataService.valueFieldDescriptions.forEach(
            (fieldDescription) => {
              this.renderCell(
                line,
                fieldDescription,
                this.dataService.pivotReport.values[fieldDescription.name],
                true,
              );
            },
          );
        }
      }
    }
    this.renderer.appendChild(tfoot, line);
  }

  /** Свернуть группы строк. */
  public collapseAllRowGroups(columnName: string) {
    this.collapseOrExpandAllRowGroups(columnName);
  }

  /**  Развернуть группы строк. */
  public expandAllRowGroups(columnName: string) {
    this.collapseOrExpandAllRowGroups(columnName, true);
  }

  /** Обновить определения колонок. */
  public updateColumnsDefinition() {
    const update = (table: HTMLElement) => {
      this.totalWidth = 0;
      const columns = table.querySelector('colgroup').querySelectorAll('col');

      this.getFlatColumnNamesForHeader().forEach((columnName, index) => {
        const fieldDescription =
          this.dataService.viewFieldDescriptions[columnName];
        let width = 0;
        if (
          this.userSettings.columns &&
          this.userSettings.columns[columnName]
        ) {
          width = this.userSettings.columns[columnName];
        } else {
          width = this.getDefaultColumnWidth(fieldDescription.type);
        }
        const col = columns[index] as HTMLElement;
        // eslint-disable-next-line radix
        const currentWidth = parseInt(col.style.width);

        if (width !== currentWidth) {
          this.renderer.setStyle(col, 'width', width + 'px');
        }

        this.totalWidth += width;
      });
    };

    update(this.tableBody);
    update(this.tableFooter);
  }

  // Возвращает имена плоских (не сводных) колонок таблицы.
  private getFlatColumnNamesForHeader() {
    let columnNames: string[] = [];
    if (this.hasRowGrouping) {
      columnNames.push(this.viewSettings.rowGroupFields[0].name);
      for (let i = 1; i < this.flatColumnNames.length; i++) {
        columnNames.push(this.flatColumnNames[i]);
      }
    } else {
      columnNames = this.flatColumnNames;
    }

    return columnNames;
  }

  // Возвращает уровень сводных колонок.
  private getColumnsLevel(level, currentLevel, children: any[]) {
    const groupsCount = this.viewSettings.columnGroupFields.length;
    if (!children) {
      children = this.dataService.pivotReport.pivotColumns;
    }
    let columns = [];

    children.forEach((column) => {
      if (level === currentLevel) {
        columns.push(column);
        return;
      }

      if (
        column.isExpanded &&
        column.pivotColumns &&
        column.pivotColumns.length > 0
      ) {
        columns = columns.concat(
          this.getColumnsLevel(level, currentLevel + 1, column.pivotColumns),
        );
        return;
      }

      if (level === groupsCount) {
        this.dataService.valueFieldDescriptions.forEach((fieldDescription) => {
          columns.push(fieldDescription);
        });
        return;
      }

      if (!column.isExpanded && column.pivotColumns && level <= groupsCount) {
        columns.push({
          isSummarize: true,
        });
        return;
      }
    });

    return columns;
  }

  // Очищает DOM-элемент.
  private clearNode(e: HTMLElement) {
    let child = e.lastElementChild;
    while (child) {
      e.removeChild(child);
      child = e.lastElementChild;
    }
  }

  // Возвращает ширину колонок по умолчанию.
  private getDefaultColumnWidth(type: ReportFieldType): number {
    switch (type) {
      case ReportFieldType.Date:
        return 100;
      case ReportFieldType.DateTimeOffset:
        return 125;
      case ReportFieldType.Decimal:
        return 125;
      case ReportFieldType.Integer:
        return 125;
      case ReportFieldType.Percent:
        return 140;
      case ReportFieldType.Bool:
        return 100;
      default:
        return 250;
    }
  }

  // Возвращает число элементов нижнего уровня.
  private getColumnGroupCellsCount(topColumn) {
    const valuesCount =
      this.dataService.valueFieldDescriptions.length === 0
        ? 1
        : this.dataService.valueFieldDescriptions.length;

    let count = 0;

    if (!topColumn.isGroup) {
      return 1;
    }

    const loop = (column) => {
      if (!column.isExpanded) {
        count += valuesCount;
        return;
      }
      if (column.pivotColumns && column.pivotColumns.length > 0) {
        column.pivotColumns.forEach((iterateColumn) => {
          loop(iterateColumn);
        });
      } else {
        count += valuesCount;
      }
    };
    loop(topColumn);
    return count;
  }

  // Рендеринг пространства для индикации пустой таблицы.
  private renderEmptyValueSpace(line: HTMLElement, forFooter: boolean) {
    if (!this.emptyValueSpaceWasFilled || forFooter) {
      let rowCount = forFooter ? 1 : 0;
      let text = '';

      if (!forFooter) {
        const procLevel = (rows) => {
          rowCount += rows.length;
          if (!this.hasRowGrouping) {
            return;
          }
          rows.forEach((row) => {
            if (row.pivotRows) {
              procLevel(row.pivotRows);
            }
          });
        };

        text = this.translate.instant(
          'analytics.pivotTable.valuesWereNotSelected',
        );
      }

      this.emptyValueSpaceWasFilled = true;

      const cell = this.renderer.createElement('td');
      this.renderer.addClass(cell, 'empty-space');
      this.renderer.setAttribute(cell, 'colspan', '500');
      this.renderer.setAttribute(cell, 'rowCount', rowCount.toString());
      this.renderer.appendChild(cell, this.renderer.createText(text));

      this.renderer.appendChild(line, cell);
    }
  }

  // Возвращает значения для сводной строки.
  private getPivotRowValues(
    pivotColumns: PivotColumn[],
  ): { fieldDescription: ReportField; value: any }[] {
    const values = [];

    const addValues = (rowPivotColumn) => {
      this.dataService.valueFieldDescriptions.forEach((fieldDescription) => {
        const value = rowPivotColumn
          ? rowPivotColumn.values[fieldDescription.name]
          : null;
        values.push({
          fieldDescription,
          value,
        });
      });
    };

    const process = (
      pivotColumn: PivotColumn,
      rowPivotColumns: PivotColumn[],
    ) => {
      const rowPivotColumn = rowPivotColumns
        ? rowPivotColumns.find((c) => c.value === pivotColumn.value)
        : null;

      if (
        !pivotColumn.isExpanded ||
        !pivotColumn.pivotColumns ||
        pivotColumn.pivotColumns.length === 0
      ) {
        addValues(rowPivotColumn);
      } else {
        pivotColumn.pivotColumns.forEach((iterateCurrentPivotColumn) => {
          process(
            iterateCurrentPivotColumn,
            rowPivotColumn ? rowPivotColumn.pivotColumns : null,
          );
        });
      }
    };

    this.dataService.pivotReport.pivotColumns.forEach((pivotColumn) => {
      process(pivotColumn, pivotColumns);
    });

    return values;
  }

  // Рендеринг COLGROUP
  private renderColumnsDefinition(table: HTMLTableElement) {
    this.totalCols = 0;
    this.totalWidth = 0;
    const tableColgroup = table.querySelector('colgroup');

    this.clearNode(tableColgroup);

    this.getFlatColumnNamesForHeader().forEach((columnName) => {
      this.totalCols++;
      const fieldDescription =
        this.dataService.viewFieldDescriptions[columnName];

      let width = 0;
      if (this.userSettings.columns && this.userSettings.columns[columnName]) {
        width = this.userSettings.columns[columnName];
      } else {
        width = this.getDefaultColumnWidth(fieldDescription.type);
      }

      const col = this.renderer.createElement('col');
      this.renderer.setStyle(col, 'width', width + 'px');
      this.renderer.appendChild(tableColgroup, col);

      this.totalWidth += width;
    });

    if (this.hasValueGrouping) {
      const groupsCount = this.viewSettings.columnGroupFields.length;
      let columnsCount = this.getColumnsLevel(groupsCount, 0, null).length;

      if (this.addTotalColumn) {
        columnsCount += this.viewSettings.valueFields.length;
      }

      for (let columnIndex = 0; columnIndex < columnsCount; columnIndex++) {
        const col = this.renderer.createElement('col');
        this.totalCols++;
        this.renderer.setStyle(col, 'width', '110px');
        this.renderer.appendChild(tableColgroup, col);

        this.totalWidth += 110;
      }
    }
  }

  // Возвращает форматированное значение.
  private getFormattingCellValue(type: ReportFieldType, value: any) {
    // eslint-disable-next-line eqeqeq
    if (value == undefined || value === '' || value === null || value === 0) {
      return '';
    }

    switch (type) {
      case ReportFieldType.Date:
        return this.datePipe.transform(value, 'shortDate');
      case ReportFieldType.DateTimeOffset:
        return this.datePipe.transform(
          value,
          'short',
          this.app.session.timeZoneOffset,
        );
      case ReportFieldType.Decimal:
        return this.numberPipe.transform(value, '1.2-2');
      case ReportFieldType.Integer:
        return this.numberPipe.transform(value, '1.0-0');
      case ReportFieldType.Percent:
        return this.percentPipe.transform(value, '1.0-0');
      case ReportFieldType.Bool:
        return value ? this.yesLabel : this.noLabel;

      default:
        return value;
    }
  }

  // Рендеринг итоговой ячейки  в строке итогов.
  // private renderFooterTotalCell(
  //   line: HTMLElement,
  //   reportField: ReportField,
  //   value: any
  // ) {
  //   const toRight = this.alignRightTypes.indexOf(reportField.type) !== -1;

  //   const cellValue = this.getFormattingCellValue(reportField.type, value);

  //   const cell = this.renderer.createElement('td');
  //   this.renderer.setAttribute(cell, 'title', cellValue);
  //   if (toRight) {
  //     this.renderer.addClass(cell, 'right');
  //   }
  //   this.renderer.appendChild(cell, this.renderer.createText(cellValue));
  //   this.renderer.appendChild(line, cell);
  // }

  // Рендеринг ячейки.
  private renderCell(
    line: HTMLElement,
    reportField: ReportField,
    value: any,
    isTotal: boolean,
    isFooterTotal = false,
  ) {
    let type = reportField.type;

    const valueViewFiled = this.viewSettings.valueFields.find(
      (f) => f.name === reportField.name,
    );

    if (isTotal) {
      if (valueViewFiled?.totalFunction === TotalFunction.Count) {
        type = ReportFieldType.Integer;
      }
    } else {
      if (
        valueViewFiled &&
        reportField.type !== ReportFieldType.Decimal &&
        reportField.type !== ReportFieldType.Percent
      ) {
        type = ReportFieldType.Integer;
      }
    }

    const toRight = this.alignRightTypes.indexOf(type) !== -1;

    if (
      this.dataService.columnGroupFieldsDictionary[reportField.name] &&
      !isFooterTotal
    ) {
      value = '';
    } else {
      value = this.getFormattingCellValue(type, value);
    }

    const cell = this.renderer.createElement('td');
    this.renderer.setAttribute(cell, 'title', value);

    if (
      this.hasRowGrouping &&
      this.flatColumnNames.indexOf(reportField.name) === 0 &&
      !isFooterTotal
    ) {
      this.renderer.setStyle(
        cell,
        'padding-left',
        this.viewSettings.rowGroupFields.length * this.groupStep + 5 + 'px',
      );
    }

    if (toRight) {
      this.renderer.addClass(cell, 'right');
    }

    this.renderer.appendChild(cell, this.renderer.createText(value));
    this.renderer.appendChild(line, cell);
  }

  /** Рендеринг строки-группы */
  private renderRowOfGroup(
    tbody: HTMLElement,
    group: PivotGroupRow,
    parentGroup: PivotGroupRow,
  ) {
    const line = this.renderer.createElement('tr');
    this.renderer.addClass(line, 'group');

    if (parentGroup) {
      this.renderer.setAttribute(line, 'data-group', parentGroup.id);
    }
    this.renderer.setAttribute(line, 'data-group-name', group.id);

    // Значение группы вывести в первую колонку.
    const groupInViewSettings = this.viewSettings.rowGroupFields.find(
      (f) => f.name === group.groupFieldName,
    );

    const groupIndex =
      this.viewSettings.rowGroupFields.indexOf(groupInViewSettings);

    const hint = this.translate.instant(
      group.isExpanded
        ? 'analytics.pivotTable.collapse'
        : 'analytics.pivotTable.expand',
    );

    const collapser = this.renderer.createElement('i');
    this.renderer.listen(collapser, 'click', () => {
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      group.isExpanded
        ? this.collapseRowGroup(group)
        : this.expandRowGroup(group);
    });

    this.renderer.setAttribute(collapser, 'title', hint);
    this.renderer.addClass(collapser, 'bi');

    this.renderer.addClass(
      collapser,
      group.isExpanded ? 'bi-dash-square' : 'bi-plus-square',
    );

    let cellValue;
    const reportField =
      this.dataService.viewFieldDescriptions[group.groupFieldName];

    if (group.value === null) {
      cellValue = this.translate.instant('analytics.pivotTable.empty');
    } else {
      cellValue = this.getFormattingCellValue(reportField.type, group.value);
    }

    const toRight = this.alignRightTypes.indexOf(reportField.type) !== -1;
    const paddingValue = this.groupStep * groupIndex + 5;

    const cell = this.renderer.createElement('td');
    this.renderer.setStyle(cell, 'padding-left', paddingValue + 'px');
    this.renderer.setAttribute(cell, 'title', cellValue);

    if (toRight) {
      this.renderer.addClass(cell, 'right');
    }

    this.renderer.appendChild(cell, collapser);
    this.renderer.appendChild(cell, this.renderer.createText(cellValue));

    this.renderer.appendChild(line, cell);

    // Пропустить число полей-строк - 1.
    for (
      let index = this.viewSettings.columnFields.length - 1;
      index > 0;
      index--
    ) {
      this.renderer.appendChild(line, this.renderer.createElement('td'));
    }

    if (!this.hasValueGrouping) {
      // Добавить поля значения.
      this.viewSettings.valueFields.forEach((valueField) => {
        this.renderCell(
          line,
          this.dataService.viewFieldDescriptions[valueField.name],
          group.values[valueField.name],
          true,
        );
      });
    } else {
      if (this.viewSettings.valueFields.length === 0) {
        this.renderEmptyValueSpace(line, false);
      } else {
        // Рендер сводных колонок
        const values = this.getPivotRowValues(group.pivotColumns);
        values.forEach((value) => {
          this.renderCell(line, value.fieldDescription, value.value, true);
        });

        // Рендер итоговой сводной колонки
        if (this.addTotalColumn) {
          this.dataService.valueFieldDescriptions.forEach(
            (fieldDescription) => {
              this.renderCell(
                line,
                fieldDescription,
                group.values[fieldDescription.name],
                true,
              );
            },
          );
        }
      }
    }

    this.renderer.appendChild(tbody, line);
  }

  /** Рендеринг строк грида. */
  private renderRows(
    tbody: HTMLElement,
    rows: PivotRow[],
    group: PivotGroupRow,
  ) {
    let html = '';

    rows.forEach((row) => {
      if (
        !(
          this.pageRenderingCycleIndex >= this.displayRowsCount ||
          this.pageRenderingCycleIndex < this.displayRowsCount - this.pageSize
        )
      ) {
        const line = this.renderer.createElement('tr');

        this.flatColumnNames.forEach((columnName) => {
          this.renderCell(
            line,
            this.dataService.viewFieldDescriptions[columnName],
            row.values[columnName],
            false,
          );
        });

        if (this.hasValueGrouping) {
          if (this.dataService.valueFieldDescriptions.length === 0) {
            this.renderEmptyValueSpace(line, false);
          } else {
            // Рендер сводных колонок
            const values = this.getPivotRowValues(row.pivotColumns);
            values.forEach((value) => {
              this.renderCell(line, value.fieldDescription, value.value, false);
            });

            // Рендер итоговой сводной колонки
            if (this.addTotalColumn) {
              this.dataService.valueFieldDescriptions.forEach(
                (fieldDescription) => {
                  this.renderCell(
                    line,
                    fieldDescription,
                    row.values[fieldDescription.name],
                    true,
                  );
                },
              );
            }
          }
        }

        this.renderer.appendChild(tbody, line);
        html += line;
      }

      this.pageRenderingCycleIndex++;
    });

    return html;
  }

  /** Рендеринг группы строк. */
  private renderGroups(
    tbody: HTMLElement,
    rows: PivotGroupRow[],
    parentRow: PivotGroupRow,
    fromExpander: boolean,
  ) {
    let data = '';

    rows.forEach((row) => {
      if (!fromExpander) {
        if (
          !(
            this.pageRenderingCycleIndex >= this.displayRowsCount ||
            this.pageRenderingCycleIndex < this.displayRowsCount - this.pageSize
          )
        ) {
          data += this.renderRowOfGroup(tbody, row as PivotGroupRow, parentRow);
        }

        this.pageRenderingCycleIndex++;
      }

      if (!row.isExpanded) {
        return;
      }

      if (row.isGroup && row.pivotRows[0].isGroup) {
        data += this.renderGroups(
          tbody,
          row.pivotRows as PivotGroupRow[],
          row,
          false,
        );
      } else {
        data += this.renderRows(tbody, row.pivotRows, row);
      }
    });
    return data;
  }

  private updateCollapser(tbody: HTMLElement, group: PivotGroupRow) {
    const el = tbody
      .querySelector(`[data-group-name='${group.id}']`)
      .querySelector('.bi');

    this.renderer.removeClass(el, 'bi-dash-square');
    this.renderer.removeClass(el, 'bi-plus-square');

    const hint = this.translate.instant(
      group.isExpanded
        ? 'analytics.pivotTable.collapse'
        : 'analytics.pivotTable.expand',
    );
    this.renderer.setAttribute(el, 'title', hint);

    if (group.isExpanded) {
      this.renderer.addClass(el, 'bi-dash-square');
    } else {
      this.renderer.addClass(el, 'bi-plus-square');
    }
  }

  private valueGroupCollapserHandler(column: any) {
    if (column.isExpanded) {
      this.collapseColumnGroup(column);
    } else {
      this.expandColumnGroup(column);
    }
  }

  /** Обновление группы строк и данных после изменения состояния группировки. */
  private updatePageAfterRowGroupingChange(group: PivotGroupRow) {
    const tbody = this.tableBody.querySelector('tbody');
    this.updateCollapser(tbody, group);

    const groupEl = tbody.querySelector(`[data-group-name='${group.id}']`);

    let el = groupEl;
    let index = 0;
    // eslint-disable-next-line no-cond-assign
    while ((el = el.previousSibling as HTMLElement) != null) {
      index++;
    }

    this.displayRowsCount = index + 1;

    el = groupEl;
    // eslint-disable-next-line no-cond-assign
    while ((el = this.renderer.nextSibling(el)) != null) {
      this.renderer.removeChild(tbody, el);
    }

    this.renderPage();
  }

  /** Свертывание группы строк. */
  private collapseRowGroup(group: PivotGroupRow) {
    group.isExpanded = false;
    this.updatePageAfterRowGroupingChange(group);
  }

  /** Развертывание группы строк. */
  private expandRowGroup(group: PivotGroupRow) {
    group.isExpanded = true;
    this.updatePageAfterRowGroupingChange(group);
  }

  /**  Свертывание группы колонок. */
  private collapseColumnGroup(group: PivotColumn) {
    if (!group.isExpanded) {
      return;
    }

    group.isExpanded = false;
    this.redraw();
  }

  /**  Раскрытие группы колонок. */
  private expandColumnGroup(group: PivotColumn) {
    group.isExpanded = true;
    this.redraw();
  }

  private updateSortIndicator() {
    const thead = this.tableHeader.querySelector('thead');

    // Снимаем классы со всего.
    for (const name of Object.keys(this.dataService.viewFieldDescriptions)) {
      const el = thead.querySelector(`th[name='${name}'] > div`);
      if (el) {
        this.renderer.removeClass(el, this.sortAscentClass);
        this.renderer.removeClass(el, this.sortDescentClass);
      }
    }

    const sorting = this.userSettings.sorting;

    if (sorting && Object.keys(sorting).length > 0) {
      const fieldName = Object.keys(sorting)[0];
      const el = thead.querySelector(`th[name='${fieldName}'] > div`);
      this.renderer.addClass(
        el,
        sorting[fieldName] === SortOrder.Asc
          ? this.sortAscentClass
          : this.sortDescentClass,
      );
    }
  }

  private collapseOrExpandAllRowGroups(
    columnName: string,
    expandAll?: boolean,
  ) {
    const levelHandle = (groups: PivotGroupRow[]) => {
      groups.forEach((group) => {
        if (group.isGroup) {
          if (group.groupFieldName === columnName) {
            group.isExpanded = expandAll === true;
          }

          if (group.pivotRows) {
            levelHandle(group.pivotRows as PivotGroupRow[]);
          }
        }
      });
    };
    levelHandle(this.dataService.pivotReport.pivotRows as PivotGroupRow[]);

    this.renderBody();
  }
}
