import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { ProjectFilterService } from 'src/app/projects/list/project-filter/project-filter.service';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { PROJECT_LIST } from 'src/app/shared/lists/project.list';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProjectSettingsComponent } from 'src/app/projects/settings/project-settings.component';
import { ProgramCardService } from '../program-card.service';
import { Program } from 'src/app/shared/models/entities/projects/program.model';
import { IncludingProjectModalComponent } from './including-project-modal/including-project-modal.component';
import { UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-program-projects',
  templateUrl: './program-projects.component.html',
  styleUrls: ['./program-projects.component.scss'],
  providers: [
    { provide: VIEW_NAME, useValue: 'program' },
    { provide: LIST, useValue: PROJECT_LIST },
    { provide: FilterService, useClass: ProjectFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  standalone: false,
})
export class ProgramProjectsComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  @Input() entityId: string;
  program: Program;
  reloadListener: any;

  constructor(
    private notification: NotificationService,
    private data: DataService,
    private app: AppService,
    private service: ProgramCardService,
    private actionService: ActionPanelService,
    private entityListService: EntityListService,
    private modal: NgbModal,
    private gridService: GridService,
  ) {}

  public create() {
    const ref = this.modal.open(ProjectSettingsComponent, { size: 'lg' });
    (ref.componentInstance as ProjectSettingsComponent).program = this.program;
  }

  private include() {
    const ref = this.modal.open(IncludingProjectModalComponent);
    (ref.componentInstance as IncludingProjectModalComponent).program =
      this.program;

    ref.result.then(
      () => this.entityListService.reload(),
      () => null,
    );
  }

  exclude(id: any): void {
    this.data
      .collection('Programs')
      .entity(this.program.id)
      .action('WP.ExcludeProject')
      .execute({ projectId: id })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'projects.programs.card.projects.messages.excluded',
          );
          this.entityListService.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit() {
    this.entityListService.rowCommands = [
      {
        name: 'delete',
        label: 'projects.programs.card.projects.actions.excludeProject',
        allowedFn: () => this.program.editAllowed,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.exclude(formGroup.value.id),
      },
    ];

    this.entityListService.contextFilter = [
      {
        project: { programId: { type: 'guid', value: this.entityId } },
      },
    ];

    // Установка главного меню.
    let actions = [this.service.settingsAction];
    actions = actions.concat([
      {
        title: 'projects.projects.list.actions.create',
        hint: 'projects.projects.list.actions.create',
        name: 'create',
        isDropDown: false,
        iconClass: 'bi bi-plus-lg bi-15',
        isBusy: false,
        isVisible: this.app.checkEntityPermission(
          'Project',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      },
      {
        title: 'projects.programs.card.projects.actions.includeProject',
        hint: 'projects.programs.card.projects.actions.includeProject',
        name: 'include',
        isDropDown: false,
        isBusy: false,
        isVisible: this.app.checkEntityPermission(
          'Project',
          PermissionType.Modify,
        ),
        handler: () => this.include(),
      },
      {
        title: 'projects.programs.card.projects.actions.excludeProject',
        hint: 'projects.programs.card.projects.actions.excludeProject',
        name: 'exclude',
        isDropDown: false,
        isBusy: false,
        isVisible: this.app.checkEntityPermission(
          'Project',
          PermissionType.Modify,
        ),
        handler: () => this.exclude(this.gridService.selectedGroupValue.id),
      },
    ]);

    this.actionService.set(actions);

    this.gridService.selectedGroup$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((row: UntypedFormGroup) => {
        this.actionService.action('exclude').isShown =
          row &&
          this.app.checkEntityPermission('Project', PermissionType.Modify);
      });

    this.reloadListener = this.service.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.entityListService.reload());

    this.service.program$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((program) => (this.program = program));
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
