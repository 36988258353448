import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { UntypedFormBuilder } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeOffType } from 'src/app/shared/models/entities/settings/time-off-type.model';
import { TimeOffTypeUnits } from 'src/app/shared/models/enums/time-off-type-unit.enum';
import { NotificationService } from 'src/app/core/notification.service';

@Component({
  selector: 'wp-using-policy-modal',
  templateUrl: './using-policy-modal.component.html',
  standalone: false,
})
export class UsingPolicyModalComponent implements OnInit, AfterViewInit {
  @Input() mode: 'create' | 'edit' = 'create';
  @Input() timeOffType: TimeOffType;

  public state: CardState;
  public isSaving = false;

  public form = this.fb.group({
    id: null,
    excludeFromCalendarDuration: [false],
    unit: [null],
  });

  public readonly: boolean;
  public timeOffTypeUnits: NamedEntity[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
  ) {}

  ngOnInit(): void {
    this.timeOffTypeUnits = TimeOffTypeUnits.map((unit) => ({
      id: unit.id,
      name: this.translate.instant(`enums.timeOffTypeUnit.${unit.code}`),
    }));
  }

  public ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const data = this.form.getRawValue();

    this.activeModal.close(data);
  }

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  private load() {
    if (this.mode === 'edit') {
      this.form.patchValue(this.timeOffType.usingPolicy);
    }

    const currentTimeOffTypeUnit = this.timeOffTypeUnits.find(
      (u) => u.id === this.timeOffType.unitId,
    );

    if (currentTimeOffTypeUnit) {
      this.form.controls.unit.setValue(currentTimeOffTypeUnit);
    }
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.load();
    });
  }
}
