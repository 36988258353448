import {
  AfterViewInit,
  Directive,
  ElementRef,
  Input,
  NgZone,
  OnDestroy,
  Renderer2,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { GridOrchestratorService } from 'src/app/shared-features/grid/core/grid-orchestrator.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { SelectionType } from 'src/app/shared-features/grid/models/grid-options.model';

@Directive({
  selector: '[tmtGridSelectionCell]',
  standalone: false,
})
export class GridSelectionCellDirective implements AfterViewInit, OnDestroy {
  @Input() column: GridColumn;
  @Input('group') formGroup: UntypedFormGroup;
  @Input() selectionType: SelectionType;

  private mouseEnterListener = () => null;
  private mouseDownListener = () => null;
  private dblclickListener = () => null;

  private get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  constructor(
    public service: GridService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone,
    private cellOrchestratorService: GridOrchestratorService,
  ) {}

  public ngAfterViewInit(): void {
    this.initListeners();
  }

  public ngOnDestroy(): void {
    this.mouseEnterListener();
    this.mouseDownListener();
    this.dblclickListener();
  }

  /** Inits listeners. */
  private initListeners(): void {
    if (!this.selectionType) {
      return;
    }

    this.zone.runOutsideAngular(() => {
      this.mouseDownListener = this.renderer.listen(
        this.elRef.nativeElement,
        'mousedown',
        (event) => this.onCellMouseDown(event),
      );

      if (this.selectionType !== SelectionType.range) {
        return;
      }

      this.mouseEnterListener = this.renderer.listen(
        this.elRef.nativeElement,
        'mouseenter',
        (event) => {
          this.onCellMouseEnter(event, this.formGroup, this.column);
        },
      );

      this.dblclickListener = this.renderer.listen(
        this.elRef.nativeElement,
        'dblclick',
        () => this.setEditingControl(),
      );
    });
  }

  /**
   * Mousedown logic. Uses for management of grid cells/rows selection.
   *
   * @param event Mouse event.
   */
  private onCellMouseDown(event: MouseEvent): void {
    if (
      this.control === this.cellOrchestratorService.editingControl ||
      (event.button === 2 &&
        this.cellOrchestratorService.options?.rowContextMenu?.length)
    ) {
      return;
    }

    // Adds all group from last selected to current cell group to selected groups.
    const selectToGroup = () => {
      const lastSelectedGroupIndex =
        this.cellOrchestratorService.formArray.controls.findIndex(
          (group) => group === this.cellOrchestratorService.selectedGroup,
        );
      const targetGroupIndex =
        this.cellOrchestratorService.formArray.controls.findIndex(
          (group) => group === this.formGroup,
        );
      if (targetGroupIndex < lastSelectedGroupIndex) {
        for (let i = lastSelectedGroupIndex - 1; i >= targetGroupIndex; i--) {
          const groupToSelection = this.cellOrchestratorService.formArray
            .controls[i] as UntypedFormGroup;
          this.cellOrchestratorService.addGroupToSelected(groupToSelection);
        }
      } else {
        for (let i = lastSelectedGroupIndex + 1; i <= targetGroupIndex; i++) {
          const groupToSelection = this.cellOrchestratorService.formArray
            .controls[i] as UntypedFormGroup;
          this.cellOrchestratorService.addGroupToSelected(groupToSelection);
        }
      }
      this.service.detectChanges();
    };

    if (this.selectionType === SelectionType.range) {
      if (event.shiftKey) {
        if (this.cellOrchestratorService.activeControl) {
          this.cellOrchestratorService.setEditingControl(null);
          this.cellOrchestratorService.setNodalSelectedControl(this.control);
          return;
        } else {
          selectToGroup();
          return;
        }
      }

      if (event.ctrlKey || event.metaKey) {
        this.cellOrchestratorService.switchGroupSelection(this.formGroup);
      } else {
        this.cellOrchestratorService.setActiveControl(this.control);
        this.cellOrchestratorService.setEditingControl(null);
      }
      this.service.detectChanges();
      return;
    }

    if (this.selectionType === SelectionType.row) {
      if (this.cellOrchestratorService.selectedGroup === this.formGroup) {
        return;
      }
      this.cellOrchestratorService.selectGroup(this.formGroup);

      this.service.detectChanges();
    }

    if (this.selectionType === SelectionType.rows) {
      if (event.shiftKey) {
        selectToGroup();
        return;
      }

      if (event.ctrlKey || event.metaKey) {
        this.cellOrchestratorService.switchGroupSelection(this.formGroup);
        this.service.detectChanges();
        return;
      }
      if (
        this.cellOrchestratorService.selectedGroups.length === 1 &&
        this.cellOrchestratorService.selectedGroup === this.formGroup
      ) {
        return;
      }
      this.cellOrchestratorService.selectGroup(this.formGroup);
      this.service.detectChanges();
    }
  }

  /** Enables editing mode for clicked cell if possible. */
  private setEditingControl(): void {
    if (
      this.service.readonly ||
      this.control.disabled ||
      this.selectionType !== SelectionType.range
    ) {
      return;
    }
    this.cellOrchestratorService.setEditingControl(this.control);
    this.service.detectChanges();
  }

  /**
   * Sets new nodalSelectedControl if possible.
   *
   * @param formGroup Data.
   * @param event Event.
   * @param column Grid column.
   */
  private onCellMouseEnter(
    event: MouseEvent,
    formGroup: UntypedFormGroup,
    column: GridColumn,
  ): void {
    //event.buttons === 1 is equivalent of pressed left mouse button
    if (event.buttons !== 1 || this.cellOrchestratorService.editingControl) {
      return;
    }

    const control = column ? formGroup.controls[column.name] : undefined;
    this.cellOrchestratorService.setNodalSelectedControl(control);
  }
}
