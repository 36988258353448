import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  Input,
  OnInit,
  Type,
  ViewChild,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter } from 'rxjs/operators';

import { BookingRenderingService } from '../../core/booking-rendering.service';
import { BookingDataService } from '../../core/booking-data.service';
import { BookingService } from '../../core/booking.service';
import { BookingBasicLineRightComponent } from './booking-basic-line/booking-basic-line.component';

@Component({
  selector: 'tmt-booking-basic',
  templateUrl: './booking-basic.component.html',
  styleUrls: ['booking-basic.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BookingBasicComponent implements OnInit, AfterViewInit {
  @ViewChild('board') private boardEl: ElementRef<HTMLDivElement>;

  @Input() public hasHeader = true;
  @Input() public leftTableWidth = 300;

  public componentLine: Type<unknown> = BookingBasicLineRightComponent;
  public noDataMessage: string;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public bookingRenderingService: BookingRenderingService | null,
    public service: BookingService,
    public dataService: BookingDataService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.noDataMessage = !this.dataService.resourceRequestMode
      ? 'shared.noDisplayData'
      : 'resources.requests.assistant.messages.noDisplayData';

    this.service.detectChanges$
      .pipe(
        filter((id) => !id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }

  public ngAfterViewInit(): void {
    this.bookingRenderingService?.initBoard(this.boardEl.nativeElement);
  }

  public trackById(index: number, row: any): string {
    return row.id;
  }
}
