import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/core/app.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { ProjectSummaryFilterService } from 'src/app/project-summary/shared/project-summary-filter/core/project-summary-filter.service';
import { ProjectSummaryFilterSettings } from 'src/app/project-summary/shared/project-summary-filter/models/project-summary-filter.settings';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateControlItem } from 'src/app/shared/components/features/state-filter/state-select.interface';

/** Represents Project Summary Filter Modal Window content. */
@Component({
  selector: 'tmt-project-summary-filter-modal',
  templateUrl: './project-summary-filter-modal.component.html',
  styleUrls: ['./project-summary-filter-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectSummaryFilterModalComponent implements OnInit {
  @Input() filter: ProjectSummaryFilterSettings;

  public form = this.fb.group({
    project: null,
    user: null,
    program: null,
    client: null,
    projectStates: null,
  });

  protected feature = Feature;

  private destroyRef = inject(DestroyRef);

  constructor(
    public app: AppService,
    public service: ProjectSummaryFilterService,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit(): void {
    if (this.filter) {
      this.form.patchValue(this.filter);
    }

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.filter = value;

        if (value.projectStates) {
          this.filter.projectStates = Object.values(
            value.projectStates as Record<string, StateControlItem>,
          ).filter((state) => state.selected);
        }
      });
  }

  /** Resets filter values. */
  public clearValues(): void {
    this.form.reset();
  }

  /**
   * Modal Ok button click event handler.
   * Closes the active modal.
   * */
  public ok = (): void => {
    this.activeModal.close(this.filter);
  };

  /**
   * Modal Cancel/Cross button click event handler.
   * Closes the active modal.
   * */
  public cancel = (): void => {
    this.activeModal.dismiss('cancel');
  };
}
