import {
  Component,
  DestroyRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  inject,
} from '@angular/core';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { PROJECT_TEAM_MEMBER_LIST } from 'src/app/shared/lists/project-team-member.list';
import { TeamToolbarComponent } from './team-toolbar/team-toolbar.component';
import { DataService } from 'src/app/core/data.service';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { Exception } from 'src/app/shared/models/exception';
import { Subscription } from 'rxjs';
import { MessageService } from 'src/app/core/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { MemberModalComponent } from './member-modal/member-modal.component';
import { UserAssessmentModalComponent } from './user-assessment-modal/user-assessment-modal.component';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectCardService } from '../core/project-card.service';
import { GenericModalComponent } from 'src/app/projects/card/project-team/generic-modal/generic-modal.component';
import { GenericAssignmentModalComponent } from './generic-assignment-modal/generic-assignment-modal.component';
import { ProjectTeamService } from './project-team.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import _ from 'lodash';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { AddResourcesModalComponent } from 'src/app/projects/card/project-team/add-resources-modal/add-resources-modal.component';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { DuplicateMemberModalComponent } from 'src/app/projects/card/project-team/duplicate-member-modal/duplicate-member-modal.component';

@Component({
  selector: 'tmt-project-team',
  templateUrl: './project-team.component.html',
  providers: [
    SavingQueueService,
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: PROJECT_TEAM_MEMBER_LIST },
    ListService,
    GridService,
    ProjectTeamService,
  ],
  standalone: false,
})
export class ProjectTeamComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public readonly = false;
  private reloadListener: Subscription;

  public gridOptions: GridOptions = {
    resizableColumns: true,
    selectionType: SelectionType.row,
    toolbar: TeamToolbarComponent,
    clientTotals: true,
    commands: [
      {
        name: 'assignUser',
        handlerFn: (group: UntypedFormGroup) => this.assignUser(group.value),
        allowedFn: (row: any) =>
          !this.readonly &&
          row?.resource?.resourceType === ResourceType.generic,
      },
      {
        name: 'delete',
        handlerFn: (memberId: string) => this.delete(memberId),
        allowedFn: (id) => id !== null,
      },
      {
        name: 'edit',
        handlerFn: (formGroup: UntypedFormGroup) => this.edit(formGroup),
        allowedFn: (id) => id !== null,
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
      {
        name: 'addResources',
        handlerFn: () => this.addResources(),
      },
      {
        name: 'createGeneric',
        handlerFn: () => this.createGeneric(),
      },
      {
        name: 'estimate',
        handlerFn: (member: any) => this.estimate(member),
        allowedFn: () =>
          !!this.project && this.versionCardService.isWorkProjectVersion(),
      },
      {
        name: 'createGenericFromUser',
        handlerFn: (group: UntypedFormGroup) =>
          this.createGenericFromUser(group.value),
        allowedFn: (row: any) =>
          !this.readonly && row?.resource?.resourceType === ResourceType.user,
      },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: () => true,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.edit(formGroup);
        },
      },
      {
        name: 'duplicate',
        label: 'components.projectTeamComponent.actions.duplicate',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly &&
          formGroup.value?.resource?.resourceType === ResourceType.user,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.duplicateTeamMember(formGroup);
        },
      },
      {
        name: 'assignUser',
        label: 'projects.projects.card.team.actions.assignUser',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly &&
          formGroup.value?.resource?.resourceType === ResourceType.generic,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.assignUser(formGroup.value);
        },
      },
      {
        name: 'disable',
        label: 'projects.projects.card.team.actions.disable',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly && formGroup.value.isActive,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.setStatus(formGroup, false),
      },
      {
        name: 'enable',
        label: 'projects.projects.card.team.actions.enable',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly && !formGroup.value.isActive,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.setStatus(formGroup, true),
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.delete(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  project: Project;

  private loadingSubscription: Subscription;
  private filterSubscription: Subscription;

  private destroyRef = inject(DestroyRef);

  private getTeamMembersCollection = () =>
    this.data.collection('ProjectTeamMembers');

  constructor(
    public projectTeamService: ProjectTeamService,
    private injector: Injector,
    private autosave: SavingQueueService,
    private projectCardService: ProjectCardService,
    private listService: ListService,
    private gridService: GridService,
    private data: DataService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private message: MessageService,
    private modal: NgbModal,
    private versionCardService: ProjectVersionCardService,
    private versionDataService: ProjectVersionDataService,
  ) {}

  public ngOnInit(): void {
    this.load();
    this.projectCardService.project$.subscribe((project: Project) => {
      this.project = project;

      this.readonly =
        !project.teamEditAllowed ||
        !this.versionCardService.projectVersion.editAllowed;

      this.gridService.detectChanges();
    });

    this.reloadListener = this.projectCardService.reloadTab$.subscribe(() =>
      this.load(),
    );

    this.autosave.error$.subscribe(() => this.load());

    this.filterSubscription = this.projectTeamService.settings$.subscribe(() =>
      this.load(),
    );

    this.projectCardService.project$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((project) => {
        this.project = project;
      });
  }

  public ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
    this.filterSubscription.unsubscribe();
  }

  /**
   * Opens a modal for user assessment and reloads data upon successful operation.
   *
   * @param member The member object for which the assessment is to be performed.
   */
  public estimate(member: ProjectTeamMember): void {
    const modalRef = this.modal.open(UserAssessmentModalComponent);
    const instance = modalRef.componentInstance;
    instance.readonly = !this.project.userSkillEstimationEditAllowed;
    instance.member = member;
    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /**
   * Edits a team member's details based on the provided form group.
   * Depending on the resource type of the team member, it either opens a `MemberModalComponent`
   * or a `GenericModalComponent` for editing. After the modal operation, it reloads the team data.
   *
   * @param formGroup The form group containing the team member's data.
   */
  public edit(formGroup: UntypedFormGroup): void {
    this.autosave.save().then(() => {
      if (formGroup.value.resource.resourceType !== ResourceType.generic) {
        const modalRef = this.modal.open(MemberModalComponent, {
          injector: this.injector,
        });

        const instance = modalRef.componentInstance;
        instance.memberId = formGroup.value.id;
        instance.resourceId = formGroup.value.resource.id;
        instance.selectedRoleId = formGroup.value.role?.id;
        instance.currencyCode = this.project.currency.alpha3Code;
        instance.existingRoleIds = this.projectTeamService.getRestrictedRoleIds(
          formGroup.value,
        );
        modalRef.result.then(
          () => {
            this.load();
          },
          () => null,
        );
      } else {
        const modalRef = this.modal.open(GenericModalComponent, {
          injector: this.injector,
          size: 'lg',
        });
        const instance = modalRef.componentInstance as GenericModalComponent;
        instance.mode = 'edit';
        instance.teamMemberId = formGroup.value.id;
        instance.resourceId = formGroup.value.resource.id;
        instance.editAllowed =
          this.project.teamEditAllowed &&
          this.versionCardService.projectVersion.editAllowed;
        instance.existingNames = this.projectTeamService.formArray.value.map(
          (teamMember) => teamMember.resource.name,
        );
        modalRef.result.then(
          () => {
            this.load();
          },
          () => null,
        );
      }
    });
  }

  /**
   * Opens a modal to duplicate a team member by role.
   *
   * @param formGroup The form group containing the team member's data.
   */
  public duplicateTeamMember(formGroup: UntypedFormGroup): void {
    this.autosave.save().then(() => {
      const modalRef = this.modal.open(DuplicateMemberModalComponent, {
        injector: this.injector,
      });
      const instance =
        modalRef.componentInstance as DuplicateMemberModalComponent;

      instance.projectId = this.entityId;
      instance.resource = formGroup.value.resource;
      instance.existingRoleIds = this.projectTeamService.getRestrictedRoleIds(
        formGroup.value,
        true,
      );

      modalRef.result.then(
        () => {
          this.load();
        },
        () => null,
      );
    });
  }

  /** Loads the project team members. */
  private load(): void {
    this.autosave.save().then(() => {
      this.gridService.setLoadingState(true);
      this.projectTeamService.formArray.clear();

      if (this.loadingSubscription) {
        this.loadingSubscription.unsubscribe();
      }

      const query: any = {
        select: ['id', 'costRate', 'isActive'],
        filter: [],
        expand: {
          resource: {
            select: [
              'id',
              'name',
              'resourceType',
              'description',
              'levelId',
              'resourceType',
            ],
            expand: {
              resourcePool: {
                select: ['id', 'name'],
              },
              legalEntity: {
                select: ['id', 'name'],
              },
            },
          },
          role: {
            select: ['id', 'name'],
          },
          primaryTariff: {
            select: ['id', 'name'],
          },
          currentRate: {
            select: ['valuePC'],
          },
        },
      };

      if (this.projectTeamService.settings.onlyActive) {
        query.filter.push({ isActive: true });
      }

      this.loadingSubscription = this.versionDataService
        .projectCollectionEntity(
          this.versionCardService.projectVersion,
          this.entityId,
        )
        .collection('ProjectTeamMembers')
        .query<ProjectTeamMember[]>(query)
        .subscribe({
          next: (data) => {
            data.forEach((row) => {
              (row as any).resourcePool = row.resource.resourcePool;
              row.name = row.resource.name;
            });

            const genericRows = data
              .filter(
                (row) => row.resource.resourceType === ResourceType.generic,
              )
              .sort(naturalSort('name'));
            const resourceRows = data
              .filter(
                (row) => row.resource.resourceType !== ResourceType.generic,
              )
              .sort(naturalSort('name'));
            data = [...genericRows, ...resourceRows];

            data.forEach((row) => {
              const group = this.fb.group({
                id: row.id,
                resourcePool: [row.resource.resourcePool],
                description: [row.resource.description],
                currentRate: [row.currentRate?.valuePC ?? null],
                role: row.role,
                isActive: [row.isActive],
                resource: [row.resource],

                //Translated type for view.
                localType: [
                  this.translate.instant(
                    'enums.teamMemberType.' +
                      _.upperFirst(row.resource.resourceType),
                  ),
                ],

                name: [row.resource.name],
                levelId: [row.resource.levelId],
                projectCurrency: [this.project.currency],
                legalEntity: [row.resource.legalEntity],
                primaryTariff: [row.primaryTariff],
              });

              group.valueChanges
                .pipe(takeUntilDestroyed(this.destroyRef))
                .subscribe(() => {
                  const groupValue = group.value;

                  const member: any = {
                    id: groupValue.id,
                    description: groupValue.description,
                    primaryTariffId: groupValue.primaryTariff?.id,
                    resourceId: groupValue.resource?.id,
                    isActive: groupValue.isActive,
                    roleId: groupValue.role?.id,
                  };
                  ProjectVersionUtil.setEntityRootPropertyId(
                    this.versionCardService.projectVersion,
                    member,
                    this.entityId,
                  );

                  if (groupValue.resource) {
                    this.autosave.addToQueue(
                      groupValue.id,
                      this.getTeamMembersCollection()
                        .entity(groupValue.id)
                        .update(member),
                    );
                  } else {
                    delete member.id;

                    this.autosave.addToQueue(
                      groupValue.id,
                      this.getTeamMembersCollection()
                        .entity(groupValue.id)
                        .patch(member),
                    );
                  }
                });

              this.projectTeamService.formArray.push(group);
            });

            this.gridService.setLoadingState(false);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.gridService.setLoadingState(false);
          },
        });
    });
  }

  /** Sets the user view for the grid and reloads the data. */
  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.load();
      },
      () => null,
    );
  }

  /**
   * Deletes a team member by their ID and reloads the team data.
   *
   * @param memberId The ID of the team member to be deleted.
   */
  private delete(memberId: string): void {
    this.message
      .confirmLocal('projects.projects.card.team.messages.deletionConfirm')
      .then(
        () => {
          this.getTeamMembersCollection()
            .entity(memberId)
            .delete()
            .subscribe({
              next: () => {
                this.load();
                this.notification.successLocal('shared.messages.deleted');
              },
              error: (error: Exception) =>
                this.notification.error(error.message),
            });
        },
        () => null,
      );
  }

  /**
   * Updates the status of a team member and refreshes the grid.
   *
   * @param formGroup The form group containing the team member's data.
   * @param status The new status to be set for the team member.
   */
  private setStatus(formGroup: UntypedFormGroup, status: boolean): void {
    this.getTeamMembersCollection()
      .entity(formGroup.value.id)
      .patch({ isActive: status })
      .subscribe({
        error: (error: Exception) => this.notification.error(error.message),
      });

    formGroup.get('isActive').setValue(status);
    this.gridService.detectChanges();
  }

  /** Opens a modal for adding resources to the project team and reloads the team data after the operation. */
  private addResources(): void {
    const modalRef = this.modal.open(AddResourcesModalComponent, {
      size: 'xxl',
      injector: this.injector,
    });
    const instance = modalRef.componentInstance as AddResourcesModalComponent;
    instance.parentEntityId = this.entityId;
    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /** Opens a modal for creating a generic team member and reloads the team data after the operation. */
  private createGeneric(): void {
    const modalRef = this.modal.open(GenericModalComponent, {
      injector: this.injector,
      size: 'lg',
    });
    const instance = modalRef.componentInstance as GenericModalComponent;
    instance.mode = 'create';
    instance.projectId = this.entityId;
    instance.existingNames = this.projectTeamService.formArray.value.map(
      (teamMember) => teamMember.resource.name,
    );

    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /**
   * Opens a modal for assigning a user to a generic team member and reloads the team data after the operation.
   *
   * @param member The team member object to which the user is to be assigned.
   */
  private assignUser(member: ProjectTeamMember): void {
    const modalRef = this.modal.open(GenericAssignmentModalComponent, {
      injector: this.injector,
    });
    const instance =
      modalRef.componentInstance as GenericAssignmentModalComponent;
    instance.memberId = member.id;
    instance.projectId = this.entityId;

    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }

  /**
   * Opens a modal for creating a generic team member from a user and reloads the team data after the operation.
   *
   * @param member The team member object from which the generic team member is to be created.
   */
  private createGenericFromUser(member: ProjectTeamMember): void {
    const modalRef = this.modal.open(GenericModalComponent, {
      injector: this.injector,
      size: 'lg',
    });
    const instance = modalRef.componentInstance as GenericModalComponent;
    instance.mode = 'createFromUser';
    instance.projectId = this.entityId;
    instance.existingNames = this.projectTeamService.formArray.value.map(
      (teamMember) => teamMember.resource.name,
    );
    instance.teamMemberId = member.id;

    modalRef.result.then(
      () => this.load(),
      () => null,
    );
  }
}
