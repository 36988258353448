import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  QueryList,
  ViewChildren,
  ElementRef,
} from '@angular/core';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';

import _isFunction from 'lodash/isFunction';

import { NotificationService } from 'src/app/core/notification.service';
import {
  Notification,
  NotificationType,
} from '../../../models/inner/notification.model';

/** Отображение уведомления о событии. */
@Component({
  selector: 'wp-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class NotificationComponent {
  @ViewChildren(NgbToast)
  private toasts: QueryList<NgbToast>;
  @ViewChildren(NgbToast, { read: ElementRef })
  private toastsRef: QueryList<ElementRef<HTMLElement>>;

  public notifications: Notification[] = [];
  public defaultByType: Record<NotificationType, Partial<Notification>> = {
    info: {
      delay: 10000,
      icon: 'bi-info-circle',
    },
    error: {
      delay: 10000,
      icon: 'bi-dash-circle',
      keepShowOnHover: true,
    },
    success: {
      delay: 2000,
      icon: 'bi-check-circle',
      keepShowOnHover: true,
    },
    warning: {
      delay: 6000,
      icon: 'bi-exclamation-circle',
      keepShowOnHover: true,
    },
  };

  private listGap = 15;

  constructor(
    private cdr: ChangeDetectorRef,
    notificationService: NotificationService,
  ) {
    notificationService.created$.subscribe((notification: Notification) => {
      this.show(notification);
    });
  }

  /**
   * Show notification.
   *
   * @param notification Notification params.
   *
   * */
  public show(notification: Notification): void {
    for (const key in this.defaultByType[notification.type]) {
      if (notification[key] === undefined) {
        notification[key] = this.defaultByType[notification.type][key];
      }
    }

    this.notifications.push(notification);
    this.cdr.markForCheck();
  }

  /**
   * Close notification.
   *
   * @param notification Notification params.
   *
   * */
  public close(index: number): void {
    this.toasts.get(index)?.hide();
    this.cdr.markForCheck();
  }

  /**
   * Handler for the notification callback on a click event.
   *
   * @param notification Notification params.
   *
   * */
  public clickHandler(notification: Notification): void {
    if (_isFunction(notification.onClick)) {
      notification.onClick();
      this.close(this.notifications.indexOf(notification));
    }
  }

  /**
   * Set height of the notification on `shown` toast event
   *
   * @param index notification index.
   *
   * */
  public setHeight(index: number): void {
    this.notifications[index].height = this.toastsRef
      .get(index)
      .nativeElement.getBoundingClientRect().height;

    if (index) {
      this.notifications[index].position = this.notifications
        .slice(0, index)
        .reduce((result, item) => result + item.height + this.listGap, 0);
    } else {
      this.notifications[index].position = 0;
    }
  }

  /**
   * Remove the notification on `hidden` toast event
   *
   * @param index notification index.
   *
   * */
  public remove(index: number): void {
    const removedNotification = this.notifications[index];
    this.notifications.splice(index, 1);

    if (_isFunction(removedNotification.onClose)) {
      removedNotification.onClose();
    }

    for (let i = index; i < this.notifications.length; i++) {
      this.notifications[i].position -=
        removedNotification.height + this.listGap;
    }

    this.cdr.markForCheck();
  }

  /**
   * Notification does not close if user hover over it.
   *
   * @param event Mouse event.
   * @param index Notification index.
   *
   * */
  public hoverHandler(event: MouseEvent, index: number): void {
    if (this.notifications[index].keepShowOnHover) {
      this.notifications[index].autohide = event.type !== 'mouseenter';
    }
  }
}
