import {
  Component,
  ElementRef,
  Input,
  OnInit,
  OnDestroy,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { WidgetService } from 'src/app/analytics/dashboards/dashboard/widget/widget.service';
import {
  SpeedometerConfig,
  Tick,
  SpeedometerNumber,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-speedometer';
import { Dictionary } from 'src/app/shared/models/dictionary';

@Component({
  selector: 'tmt-gauge-custom',
  templateUrl: 'widget-gauge-custom.component.html',
  styleUrl: 'widget-gauge-custom.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GaugeCustomComponent implements OnInit, OnDestroy {
  @Input() config: SpeedometerConfig;

  public ticks: Tick[] = [];
  public numbers: SpeedometerNumber[] = [];
  public radius = 200;
  public get tickHeight(): number {
    return this.radius / 12;
  }
  public totalTicks = 11;
  public innerRadiusRatio = 0.9;

  private resizeObserver: ResizeObserver;
  private destroyRef = inject(DestroyRef);
  private numbersShiftX = -10;
  private numbersShiftY = -3;
  private widgetTitleHeight = 40;

  /**
   * Returns the inner radius of the gauge.
   *
   * @returns Inner radius value.
   */
  public get innerRadius(): number {
    return this.radius * this.innerRadiusRatio;
  }

  /** Styles for .gauge container */
  public get gaugeStyle(): Dictionary<string | number> {
    return {
      width: `${this.radius * 2}px`,
      height: `${this.radius}px`,
      borderTopLeftRadius: `${this.radius}px`,
      borderTopRightRadius: `${this.radius}px`,
      background: this.gaugeBackground(),
    };
  }

  /** Styles for .gauge__inner container */
  public get gaugeInnerStyle(): Dictionary<string | number> {
    return {
      width: `${this.innerRadius * 2}px`,
      height: `${this.innerRadius}px`,
      borderTopLeftRadius: `${this.innerRadius}px`,
      borderTopRightRadius: `${this.innerRadius}px`,
    };
  }

  /**
   * Styles for .gauge__needle
   */
  public get gaugeNeedleStyle(): Dictionary<string | number> {
    return {
      height: `${this.radius - 10}px`,
      transform: `rotate(${this.getNeedleAngle()}deg)`,
    };
  }

  /** Styles for .gauge__value container */
  public get gaugeValueStyle(): Dictionary<string | number> {
    return {
      left: `${this.innerRadius}px`,
      cursor: 'default',
    };
  }

  /** Styles for .gauge__tick */
  public getTickStyle(tick: Tick): Dictionary<string | number> {
    return {
      left: `${tick.left}px`,
      bottom: `${tick.bottom}px`,
      transform: `rotate(${tick.angle}deg)`,
      height: `${this.tickHeight}px`,
    };
  }

  /** Styles for .gauge__number */
  public getNumberStyle(
    number: SpeedometerNumber,
  ): Dictionary<string | number> {
    return {
      left: `${number.left}px`,
      bottom: `${number.bottom}px`,
      fontSize: this.radius < 200 ? `${this.radius / 15}px` : '',
    };
  }

  constructor(
    private elementRef: ElementRef,
    private widgetService: WidgetService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.config.value ||= '0';
    this.ticks = this.generateMarkup(this.totalTicks, this.radius);

    this.resizeObserver = new ResizeObserver(() => {
      this.rebuild();
    });

    this.resizeObserver.observe(this.elementRef.nativeElement.parentElement);
    this.widgetService.rebuild$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.rebuild();
      });
  }

  public ngOnDestroy(): void {
    this.resizeObserver?.disconnect();
  }

  /**
   * Calculates the angle for the needle.
   *
   * @returns Needle angle in degrees.
   */
  public getNeedleAngle(): number {
    if (this.config.value === undefined || this.config.value === null) return;
    const range = this.config.max - this.config.min;
    const value = this.parseToNumber(this.config.value);

    if (value > this.config.max) {
      return 90;
    }

    if (value < this.config.min) {
      return -90;
    }

    return ((value - this.config.min) * 180) / range - 90;
  }

  /**
   * Generates the background for the gauge using a conic gradient.
   *
   * @returns CSS string for the background.
   */
  public gaugeBackground(): string {
    if (!this.config.segments) return;

    const totalRange = this.config.max - this.config.min;
    const conversionFactor = 180 / totalRange;
    const segmentsSorted = [...this.config.segments].sort(
      (a, b) => a.from - b.from,
    );

    if (!segmentsSorted.length) return;

    const gradientParts: string[] = [];
    const firstSegment = segmentsSorted[0];

    if (firstSegment.from > this.config.min) {
      const whiteStart =
        (firstSegment.from - this.config.min) * conversionFactor;
      gradientParts.push(`white 0deg ${whiteStart}deg`);
    }

    for (let i = 0; i < segmentsSorted.length; i++) {
      const { from, to, color } = segmentsSorted[i];
      const fromAngle = (from - this.config.min) * conversionFactor;
      const toAngle = (to - this.config.min) * conversionFactor;

      gradientParts.push(`${color} ${fromAngle}deg ${toAngle}deg`);

      if (i < segmentsSorted.length - 1) {
        const nextSegment = segmentsSorted[i + 1];
        if (to < nextSegment.from) {
          const gapStartAngle = toAngle;
          const gapEndAngle =
            (nextSegment.from - this.config.min) * conversionFactor;
          gradientParts.push(`white ${gapStartAngle}deg ${gapEndAngle}deg`);
        }
      }
    }

    const lastSegment = segmentsSorted[segmentsSorted.length - 1];
    if (lastSegment.to < this.config.max) {
      const lastAngle = (lastSegment.to - this.config.min) * conversionFactor;
      gradientParts.push(`white ${lastAngle}deg 180deg`);
    }

    return `conic-gradient(from -90deg at 50% 100%, ${gradientParts.join(', ')})`;
  }

  /**
   * Generates tick marks for the gauge.
   *
   * @param totalTicks Total number of ticks.
   * @param radius Radius of the gauge.
   * @returns Array of tick mark positions and angles.
   */
  private generateMarkup(
    totalTicks: number,
    radius: number,
  ): { left: number; bottom: number; angle: number }[] {
    this.numbers = [];

    const angleStep = 180 / (totalTicks - 1);

    return Array.from({ length: totalTicks }, (_, i) => {
      const angleDeg = i * angleStep;
      const angleRad = (angleDeg * Math.PI) / 180;
      const halfTick = Math.floor(this.tickHeight / 2);
      const value = this.formatNumber(
        this.config.min +
          (i * (this.config.max - this.config.min)) / (this.totalTicks - 1),
      );
      const shiftX =
        angleDeg > 90
          ? (`${value}`.length * Math.cos(angleRad) * 4 * this.radius) / 200
          : this.numbersShiftX;

      this.numbers.push({
        left: radius - radius * 0.8 * Math.cos(angleRad) + shiftX,
        bottom: radius * 0.8 * Math.sin(angleRad) + this.numbersShiftY,
        value,
      });

      return {
        left: radius - (radius - halfTick) * Math.cos(angleRad),
        bottom: (radius - halfTick) * Math.sin(angleRad) - halfTick,
        angle: angleDeg + 90,
      };
    });
  }

  /** Rebuilds the gauge configuration and redraws the ticks. */
  private rebuild(): void {
    this.config.value ||= '0';

    const parentElement =
      this.elementRef.nativeElement.parentElement.parentElement;

    this.radius =
      Math.min(
        parentElement.offsetWidth / 1.5,
        parentElement.offsetHeight - this.widgetTitleHeight,
      ) / 1.5;

    this.ticks = [];
    this.ticks = this.generateMarkup(this.totalTicks, this.radius);
    this.cdr.detectChanges();
  }

  /**
   * Converts a value to a number.
   *
   * @param value The value to be converted. Can be a number or a string.
   * @returns The parsed number.
   */
  private parseToNumber(value: string | number): number {
    if (typeof value === 'number') {
      return value;
    }

    /** Replaces '%', whitespace, and ',' in the input string. */
    const numericString = value.replace(/[%\s,]/g, (match) =>
      match === ',' ? '.' : '',
    );

    return +numericString;
  }

  /**
   * Formats a number to a string with 'k' suffix for numbers >= 10000.
   *
   * @param value The number to format.
   * @returns The formatted string.
   */
  private formatNumber(value: number): string {
    if (value >= 10000) {
      return (value / 1000).toFixed(0) + 'k';
    }
    return value % 1 === 0
      ? value.toFixed(0).toString()
      : value.toFixed(1).toString();
  }
}
