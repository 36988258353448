import { Component, OnInit, Input, Inject } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'wp-program-filter',
  templateUrl: './program-filter.component.html',
  standalone: false,
})
export class ProgramFilterComponent implements OnInit, FilterDetails {
  @Input() values: any;

  detailsForm = this.fb.group({
    period: [null],
    manager: [null],
  });

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(VIEW_NAME) public viewName: string,
    public service: FilterService,
  ) {}

  ngOnInit() {
    this.service.resetValues$.subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges.subscribe(() => {
      const values = Object.assign(this.service.values, this.detailsForm.value);
      this.service.changeValues(values);
    });
  }
}
