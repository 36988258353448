import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  ReactiveFormsModule,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import _ from 'lodash';
import { RateMatrixStructureCollection } from 'src/app/settings-app/rate-matrix/model/rate-matrix-structure.enum';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { SharedModule } from 'src/app/shared/shared.module';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
    selector: 'tmt-rate-matrices-lines-filter',
    templateUrl: './rate-matrices-lines-filter.component.html',
    imports: [CommonModule, ReactiveFormsModule, TranslateModule, SharedModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class RateMatricesLinesFilterComponent implements OnInit, FilterDetails {
  @Input() values: any;

  public detailsForm: UntypedFormGroup;
  public rateMatrixStructureCollection = RateMatrixStructureCollection;
  public dependentAnalytics: Record<string, any> = {
    competence: {
      dependent: 'role',
      query: null,
    },
    grade: {
      dependent: 'level',
      query: null,
    },
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(VIEW_NAME) public viewName: string,
    public filterService: FilterService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    const form = {
      rateMatrix: null,
    };
    for (const key of Object.keys(this.rateMatrixStructureCollection)) {
      form[key] = null;
    }
    this.detailsForm = this.fb.group(form);
    this.detailsForm.patchValue(this.filterService.values);

    this.filterService.values$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((values) => {
        for (const key of Object.keys(this.rateMatrixStructureCollection)) {
          this.detailsForm.get(key).setValue(null, { emitEvent: false });
        }
        this.detailsForm.patchValue(values, { emitEvent: false });
        this.cdr.markForCheck();
      });

    this.detailsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const values = Object.assign(
          this.filterService.values,
          this.detailsForm.value,
        );
        this.filterService.changeValues(values);
        this.cdr.markForCheck();
      });

    this.initDependentAnalyticsSubscriptions();
  }

  /* Inits dependent analytics form control changes subscriptions. */
  private initDependentAnalyticsSubscriptions(): void {
    for (const key of Object.keys(this.dependentAnalytics)) {
      this.detailsForm
        .get(this.dependentAnalytics[key].dependent)
        .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((value) => {
          if (value) {
            this.dependentAnalytics[key].query = {
              filter: {
                [this.dependentAnalytics[key].dependent + 'Id']: {
                  type: 'guid',
                  value: value.id,
                },
              },
            };
          } else {
            this.dependentAnalytics[key].query = null;
          }
          this.detailsForm.get(key).setValue(null, { emitEvent: false });
        });
    }
  }
}
