import { MarkItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';

export const tagMark = (tag: string): any => ({
  [tag]: {
    parseDOM: [{ tag }],
    toDOM: () => [tag, 0],
  },
});

export const additionalMarks: Record<string, MarkItem> = {
  // Strikethrough
  strike: {
    open: '~~',
    close: '~~',
    markerCode: 0x7e,
  },
  // Underline
  ins: {
    open: '++',
    close: '++',
    markerCode: 0x2b,
  },
  // Monospaced
  samp: {
    open: '##',
    close: '##',
    markerCode: 0x23,
  },
};
