import {
  Component,
  OnInit,
  Input,
  OnChanges,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  DestroyRef,
  inject,
} from '@angular/core';
import { ViewSettingsType } from './view-settings.type';
import { ViewSettings } from '../models/view-settings/view-settings.model';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {
  UntypedFormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { AnalyticsService } from 'src/app/core/analytics.service';
import { ReportSourceDescription } from '../models/source-description/report-source-description.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { ReportField } from '../models/source-description/report-field.model';
import { TotalFunction } from '../models/total-function.enum';
import { auditTime } from 'rxjs/operators';
import { Options } from 'sortablejs';
import { TranslateService } from '@ngx-translate/core';
import { BaseViewField } from '../models/view-settings/base-view-field.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { NotificationService } from 'src/app/core/notification.service';
import { FieldPropertiesModalComponent } from './field-properties-modal/field-properties-modal.component';
import { Exception } from 'src/app/shared/models/exception';
import { Report } from 'src/app/shared/models/entities/analytics/report.model';
import { LogService } from 'src/app/core/log.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { merge } from 'rxjs';

/** Компонента настройки представления отчета. */
@Component({
  selector: 'wp-report-view-settings',
  templateUrl: './report-view-settings.component.html',
  styleUrls: ['./report-view-settings.component.scss'],
  standalone: false,
})
export class ReportViewSettingsComponent implements OnInit, OnChanges {
  @Input() type: ViewSettingsType;
  @Input() viewSettings: ViewSettings;

  @Output() changes = new EventEmitter<[ViewSettings, any]>();
  @Output() typeChanges = new EventEmitter<NamedEntity>();

  // Имена контейнеров колонок представления, они же идентификаторы.
  public sourceFieldsContainer = 'source-fields-container';
  public rowFieldsContainer = 'row-fields-container';
  public valueFieldsContainer = 'value-fields-container';
  public valueGroupFieldsContainer = 'value-group-fields-container';
  public rowGroupFieldsContainer = 'row-group-fields-container';

  public isLoading = false;
  public isTemplatesLoading = false;
  public form: UntypedFormGroup;
  public sourceDescription: ReportSourceDescription;

  // Все поля, определенные схемой типа отчета.
  public sourceFields: ReportField[] = [];

  // Отфильтрованные колонки схемы.
  public sourceFieldsFiltered: ReportField[] = [];

  // Строки грида для отображения фильтрованных колонок схемы.
  public sourceFieldRows: UntypedFormArray = this.fb.array([]);

  public rowFields: UntypedFormArray = new UntypedFormArray([]);
  public valueFields: UntypedFormArray = new UntypedFormArray([]);
  public rowGroupFields: UntypedFormArray = new UntypedFormArray([]);
  public valueGroupFields: UntypedFormArray = new UntypedFormArray([]);

  public hasRows: boolean;
  hasValues: boolean;
  hasValueGroups: boolean;
  hasRowGroups: boolean;
  valuesContainerLabel: string;
  rowsContainerLabel: string;
  rowGroupsContainerLabel: string;
  valueGroupsContainerLabel: string;

  totalFunctionsAreAllowed: boolean;
  public templatesAreAllowed = true;

  public sourceSortableOptions: Options = {
    group: {
      name: 'source',
      pull: 'clone',
      put: false,
    },

    sort: false,
    handle: '.handle',
    animation: 0,

    onMove: (event: any) => this.onMoveHandler(event),
  };

  public fieldSortableOptions: Options = {
    group: {
      name: 'fields',
      pull: true,
      put: true,
    },
    handle: '.handle',
    ghostClass: 'fields-sortable-ghost',
    chosenClass: 'fields-sortable-chosen',
    dragClass: 'fields-sortable-drag',
    onAdd: (event: any) => {
      const fieldName = event.item.getAttribute('name');
      const toId = event.to.id;

      const remove = (containerId: string) => {
        if (toId !== containerId) {
          const formArray = this.getFormArrayById(containerId);
          const index = (formArray.value as BaseViewField[]).findIndex(
            (f) => f.name === fieldName,
          );
          if (index > -1) {
            formArray.removeAt(index);
          }
        }
      };

      remove(this.rowFieldsContainer);
      remove(this.valueFieldsContainer);
      remove(this.valueGroupFieldsContainer);
      remove(this.rowGroupFieldsContainer);

      // Установить состояние в списке полей источника.
      this.setSourceFieldSelected(fieldName, true);
    },
    onMove: (event: any) => this.onMoveHandler(event),
  };

  templates: NamedEntity[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private translate: TranslateService,
    private modal: NgbModal,
    private notification: NotificationService,
    private log: LogService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private changeDetector: ChangeDetectorRef,
    private analyticsService: AnalyticsService,
  ) {}

  rowGroupFieldsLimit: () => number = () => 0;
  valueFieldsLimit: () => number = () => 0;
  rowFieldsLimit: () => number = () => 0;
  valueGroupFieldsLimit: () => number = () => 0;

  public cloneFieldHandler = (
    sourceFieldRow: UntypedFormGroup,
  ): UntypedFormGroup => {
    const sourceField = sourceFieldRow.value.field as ReportField;
    const group = this.fb.group({
      name: sourceField.name,
      customTitle: null,
      sourceField,
      total: null,
    });

    return group;
  };

  public getViewFieldTitle(field: UntypedFormGroup): string {
    if (!field) {
      return null;
    }
    return field.value.customTitle ?? field.value.sourceField.title;
  }

  public getTotalOptions(fieldName: string): NamedEntity[] {
    const totalValues: NamedEntity[] = [];
    const sourceField = this.getSourceField(fieldName);

    sourceField.totals.forEach((total) => {
      totalValues.push({
        id: total,
        name: this.getTotalFnTitle(total),
      });
    });

    return totalValues;
  }

  public getSourceField(name: string) {
    return this.sourceFields.find((f) => f.name === name);
  }

  private setSourceFieldSelected(name: string, selected: boolean) {
    const index = (this.sourceFieldRows.value as any[]).findIndex(
      (f) => f.name === name,
    );
    if (index > -1) {
      (this.sourceFieldRows.at(index) as UntypedFormGroup).controls[
        'selected'
      ].setValue(selected, { emitEvent: false });
    }
  }

  private parseViewSettings() {
    const createFormGroup = (field: BaseViewField): UntypedFormGroup => {
      const sourceField = this.getSourceField(field.name);
      if (!sourceField) {
        return null;
      }

      const group = this.fb.group({
        name: sourceField.name,
        customTitle: field.customTitle,
        sourceField,
        total: null,
      });

      return group;
    };

    this.rowFields.clear();
    this.valueFields.clear();
    this.rowGroupFields.clear();
    this.valueGroupFields.clear();

    if (this.type === 'Pivot' || this.type === 'Table') {
      this.viewSettings.columnFields.forEach((field) => {
        const group = createFormGroup(field);
        if (group) {
          this.rowFields.push(group);
        }
      });

      this.viewSettings.valueFields.forEach((field) => {
        const group = createFormGroup(field);
        if (group) {
          group.controls['total'].setValue(
            field.totalFunction
              ? this.getTotalOptions(field.name).find(
                  (f) => f.id === field.totalFunction,
                )
              : null,
          );

          this.valueFields.push(group);
        }
      });

      this.viewSettings.rowGroupFields.forEach((field) => {
        const group = createFormGroup(field);
        if (group) {
          this.rowGroupFields.push(group);
        }
      });

      this.viewSettings.columnGroupFields.forEach((field) => {
        const group = createFormGroup(field);
        if (group) {
          this.valueGroupFields.push(group);
        }
      });
    }

    if (
      ['Column', 'StackedColumns', 'StackedPercentageColumns', 'Line'].includes(
        this.type,
      )
    ) {
      // Добавить поле-категорию.
      if (this.viewSettings.categoryField) {
        const column = createFormGroup(this.viewSettings.categoryField);
        if (column) {
          this.rowFields.push(column);
        }
      }

      // Добавить поле-легенду.
      if (this.viewSettings.legendField) {
        const column = createFormGroup(this.viewSettings.legendField);
        if (column) {
          this.valueGroupFields.push(column);
        }
      }

      // Добавить поля-значения.
      this.viewSettings.valueFields?.forEach((field) => {
        const column = createFormGroup(field);
        if (column) {
          this.valueFields.push(column);
        }
      });
    }

    if (this.type === 'Pie') {
      // Добавить поле-значение.
      if (this.viewSettings.valueField) {
        const column = createFormGroup(this.viewSettings.valueField);
        if (column) {
          this.valueFields.push(column);
        }
      }

      // Добавить поле-категорию.
      if (this.viewSettings.categoryField) {
        const column = createFormGroup(this.viewSettings.categoryField);
        if (column) {
          this.rowFields.push(column);
        }
      }
    }

    if (['Value', 'Speedometer'].indexOf(this.type) !== -1) {
      // Добавить поле-значение.
      if (this.viewSettings.valueField) {
        const column = createFormGroup(this.viewSettings.valueField);
        if (column) {
          this.valueFields.push(column);
        }
      }
    }

    if (this.type === 'DoubleValue') {
      // Добавить поля-значения.
      this.viewSettings.valueFields?.forEach((field) => {
        const column = createFormGroup(field);
        if (column) {
          this.valueFields.push(column);
        }
      });
    }

    if (this.type === 'Funnel') {
      this.viewSettings.valueFields?.forEach((field) => {
        const column = createFormGroup(field);
        if (column) {
          this.valueFields.push(column);
        }
      });

      if (this.viewSettings.categoryField) {
        const column = createFormGroup(this.viewSettings.categoryField);
        if (column) {
          this.rowFields.push(column);
        }
      }
    }

    this.updateSourceFieldRows();
    this.updateViewSettings();
  }

  private updateViewSettings() {
    this.log.debug('ViewSettings has been updated.');

    const viewSettings: ViewSettings = {
      sourceName: this.viewSettings.sourceName,
    };

    const datasetFields: any[] = [];

    const valueFields = this.valueFields.value as any[];
    const rowFields = this.rowFields.value as any[];
    const rowGroupFields = this.rowGroupFields.value as any[];
    const valueGroupFields = this.valueGroupFields.value as any[];

    if (this.type === 'Pivot' || this.type === 'Table') {
      viewSettings.columnFields = [];
      viewSettings.valueFields = [];
      viewSettings.rowGroupFields = [];
      viewSettings.columnGroupFields = [];

      rowFields.forEach((field) => {
        viewSettings.columnFields.push({
          name: field.name,
          customTitle: field.customTitle,
        });
      });

      valueFields.forEach((field) => {
        viewSettings.valueFields.push({
          name: field.name,
          customTitle: field.customTitle,
          totalFunction: field.total?.id,
        });
      });

      rowGroupFields.forEach((field) => {
        viewSettings.rowGroupFields.push({
          name: field.name,
          customTitle: field.customTitle,
        });
      });

      valueGroupFields.forEach((field) => {
        viewSettings.columnGroupFields.push({
          name: field.name,
          customTitle: field.customTitle,
        });
      });
    }

    if (
      ['Column', 'StackedColumns', 'StackedPercentageColumns', 'Line'].includes(
        this.type,
      )
    ) {
      viewSettings.categoryField = null;
      viewSettings.valueFields = [];
      viewSettings.legendField = null;

      if (rowFields[0]) {
        viewSettings.categoryField = {
          name: rowFields[0].name,
          customTitle: rowFields[0].customTitle,
        };

        datasetFields.push({
          fieldName: rowFields[0].name,
          usedAsValue: false,
        });
      }

      valueFields.forEach((column) => {
        viewSettings.valueFields.push({
          name: column.name,
          customTitle: column.customTitle,
        });

        datasetFields.push({
          fieldName: column.name,
          usedAsValue: true,
        });
      });

      if (valueGroupFields[0]) {
        viewSettings.legendField = {
          name: valueGroupFields[0].name,
          customTitle: valueGroupFields[0].customTitle,
        };

        datasetFields.push({
          fieldName: valueGroupFields[0].name,
          usedAsValue: false,
        });
      }
    }

    if (this.type === 'Pie') {
      if (rowFields[0]) {
        viewSettings.categoryField = {
          name: rowFields[0].name,
          customTitle: rowFields[0].customTitle,
        };

        datasetFields.push({
          fieldName: rowFields[0].name,
          usedAsValue: false,
        });
      }

      if (valueFields[0]) {
        viewSettings.valueField = {
          name: valueFields[0].name,
          customTitle: valueFields[0].customTitle,
        };

        datasetFields.push({
          fieldName: valueFields[0].name,
          usedAsValue: true,
        });
      }
    }

    if (['Value', 'Speedometer'].indexOf(this.type) !== -1) {
      viewSettings.valueField = null;

      if (valueFields[0]) {
        viewSettings.valueField = {
          name: valueFields[0].name,
          customTitle: valueFields[0].customTitle,
        };

        datasetFields.push({
          fieldName: valueFields[0].name,
          usedAsValue: true,
        });
      }
    }

    if (this.type === 'DoubleValue') {
      viewSettings.valueFields = [];
      valueFields.forEach((column) => {
        viewSettings.valueFields.push({
          name: column.name,
          customTitle: column.customTitle,
        });

        datasetFields.push({
          fieldName: column.name,
          usedAsValue: true,
        });
      });
    }

    if (this.type === 'Funnel') {
      viewSettings.columnFields = [];
      viewSettings.valueFields = [];

      if (rowFields[0]) {
        viewSettings.categoryField = {
          name: rowFields[0].name,
          customTitle: rowFields[0].customTitle,
        };

        datasetFields.push({
          fieldName: rowFields[0].name,
          usedAsValue: false,
        });
      }

      valueFields.forEach((field) => {
        viewSettings.valueFields.push({
          name: field.name,
          customTitle: field.customTitle,
          totalFunction: field.total?.id,
        });

        datasetFields.push({
          fieldName: field.name,
          usedAsValue: true,
        });
      });
    }

    this.changes.emit([viewSettings, datasetFields]);
  }

  loadDescription() {
    this.isLoading = true;

    return this.analyticsService
      .getSourceDescription(this.viewSettings.sourceName)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((description) => {
        this.sourceDescription = description;
        this.sourceFields = [];

        this.sourceDescription.allFields.forEach((field) => {
          if (field.type === 'EntityId') {
            return;
          }
          this.sourceFields.push(field);
        });

        this.updateSourceFieldRows();
        this.parseViewSettings();
        this.isLoading = false;

        this.changeDetector.detectChanges();
      });
  }

  public removeRowField(index: number) {
    this.setSourceFieldSelected(this.rowFields.at(index).value.name, false);
    this.rowFields.removeAt(index);
  }

  public removeValueField(index: number) {
    this.setSourceFieldSelected(this.valueFields.at(index).value.name, false);
    this.valueFields.removeAt(index);
  }

  public removeValueGroupField(index: number) {
    this.setSourceFieldSelected(
      this.valueGroupFields.at(index).value.name,
      false,
    );
    this.valueGroupFields.removeAt(index);
  }

  public removeRowGroupField(index: number) {
    this.setSourceFieldSelected(
      this.rowGroupFields.at(index).value.name,
      false,
    );
    this.rowGroupFields.removeAt(index);
  }

  public editField(fieldGroup: UntypedFormGroup) {
    const ref = this.modal.open(FieldPropertiesModalComponent);
    const instance = ref.componentInstance as FieldPropertiesModalComponent;

    instance.field = fieldGroup.value as BaseViewField;
    instance.sourceField = instance.field.sourceField;

    ref.result.then(
      (title: string) => {
        fieldGroup.controls['customTitle'].setValue(title);

        // Сохранить изменение.
        this.updateViewSettings();
      },
      () => null,
    );
  }

  public trackByName = (index: number, item: any) => item.value.name;

  /** Проверка использования полья в настройках. */
  private checkSourceFieldSelected(name: string): boolean {
    if (
      (this.rowFields.value as BaseViewField[]).find((f) => f.name === name)
    ) {
      return true;
    }
    if (
      (this.valueFields.value as BaseViewField[]).find((f) => f.name === name)
    ) {
      return true;
    }
    if (
      (this.rowGroupFields.value as BaseViewField[]).find(
        (f) => f.name === name,
      )
    ) {
      return true;
    }
    if (
      (this.valueGroupFields.value as BaseViewField[]).find(
        (f) => f.name === name,
      )
    ) {
      return true;
    }

    return false;
  }

  // Обновление строк полей источника.
  private updateSourceFieldRows() {
    this.sourceFieldsFiltered = this.form.value.sourceFilter
      ? this.sourceFields.filter((sf: ReportField) =>
          sf.title
            .toLowerCase()
            .includes(this.form.value.sourceFilter.toLowerCase()),
        )
      : this.sourceFields;

    const groupBy = (xs, key) =>
      xs.reduce((rv, x) => {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});

    this.sourceFieldRows.clear();
    const groups = groupBy(this.sourceFieldsFiltered, 'groupTitle');

    for (const key of Object.keys(groups)) {
      const group = groups[key];

      // Добавить строку с заголовком группы полей.
      this.sourceFieldRows.push(
        this.fb.group({
          name: Guid.generate(),
          title: key,
          isGroup: true,
          selected: false,
        }),
      );

      // Добавить все поля из группы.
      group.forEach((field: ReportField) => {
        const formGroup = this.fb.group({
          name: field.name,
          selected: this.checkSourceFieldSelected(field.name),
          title: field.title,
          field,
        });
        this.sourceFieldRows.push(formGroup);

        formGroup.valueChanges
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe(() => {
            const sourceField = formGroup.value.field as ReportField;
            const viewFieldFormGroup = this.cloneFieldHandler(formGroup);

            if (formGroup.controls['selected'].value as boolean) {
              if (sourceField.isValueField) {
                if (this.valueFields.length >= this.valueFieldsLimit()) {
                  this.notification.warningLocal(
                    'analytics.reportViewSettings.messages.fieldsLimit',
                  );
                  formGroup.controls['selected'].setValue(false, {
                    emitEvent: false,
                  });
                  return;
                } else {
                  this.valueFields.push(viewFieldFormGroup);
                }
              } else {
                if (this.rowFields.length >= this.rowFieldsLimit()) {
                  this.notification.warningLocal(
                    'analytics.reportViewSettings.messages.fieldsLimit',
                  );
                  formGroup.controls['selected'].setValue(false, {
                    emitEvent: false,
                  });
                  return;
                } else {
                  this.rowFields.push(viewFieldFormGroup);
                }
              }
            } else {
              this.removeFieldFromView(sourceField.name);
            }
          });
      });
    }
  }

  public getTotalFnTitle(totalFn: TotalFunction) {
    return this.translate.instant(`enums.totalFunction.${totalFn}`);
  }

  public loadTemplates(open: boolean) {
    if (!open) {
      return;
    }

    this.templates = [];
    this.isTemplatesLoading = true;

    this.data
      .collection('Reports')
      .query<NamedEntity[]>({
        select: ['id', 'name'],
        filter: {
          isTemplate: true,
          isActive: true,
          reportTypeId: {
            type: 'guid',
            value: this.form.value.reportType?.id,
          },
        },
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          this.templates = data;
          this.isTemplatesLoading = false;
        },
        error: (error: Exception) => {
          this.isTemplatesLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  public applyTemplate(templateId: string) {
    this.data
      .collection('Reports')
      .entity(templateId)
      .get<Report>()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (template) => {
          this.viewSettings = JSON.parse(template.viewSettings);
          this.parseViewSettings();
          this.updateSourceFieldRows();
          this.updateViewSettings();
          this.notification.successLocal(
            'analytics.reportViewSettings.messages.templateHasBeenApplied',
          );
        },
        error: (error: Exception) => {
          this.isTemplatesLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  private getFormArrayById(id: string): UntypedFormArray {
    switch (id) {
      case this.rowFieldsContainer:
        return this.rowFields;
      case this.valueFieldsContainer:
        return this.valueFields;
      case this.valueGroupFieldsContainer:
        return this.valueGroupFields;
      case this.rowGroupFieldsContainer:
        return this.rowGroupFields;
    }
  }

  private onMoveHandler = (event: any) => {
    const columnName = event.dragged.getAttribute('name');
    const toId = event.to.id;
    const fromId = event.from.id;
    const formArray = this.getFormArrayById(toId);

    if (fromId === toId) {
      return true;
    }

    if (
      (formArray.value as BaseViewField[]).find((f) => f.name === columnName)
    ) {
      return false;
    }

    if (
      toId === this.rowFieldsContainer &&
      this.rowFields.length >= this.rowFieldsLimit()
    ) {
      return false;
    }

    if (
      toId === this.valueFieldsContainer &&
      this.valueFields.length >= this.valueFieldsLimit()
    ) {
      return false;
    }

    if (
      toId === this.rowGroupFieldsContainer &&
      this.rowGroupFields.length >= this.rowGroupFieldsLimit()
    ) {
      return false;
    }

    if (
      toId === this.valueGroupFieldsContainer &&
      this.valueGroupFields.length >= this.valueGroupFieldsLimit()
    ) {
      return false;
    }

    return true;
  };

  private removeFieldFromView(fieldName: string) {
    const remove = (formArray: UntypedFormArray) => {
      const index = (formArray.value as BaseViewField[]).findIndex(
        (f) => f.name === fieldName,
      );
      if (index > -1) {
        formArray.removeAt(index);
      }
    };

    remove(this.rowFields);
    remove(this.valueFields);
    remove(this.valueGroupFields);
    remove(this.rowGroupFields);
  }

  ngOnChanges() {
    if (this.type === 'Pivot' || this.type === 'Table') {
      this.rowsContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.rows',
      );
      this.valuesContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.values',
      );
      this.valueGroupsContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.valueGroups',
      );
      this.rowGroupsContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.rowGroups',
      );

      this.rowGroupFieldsLimit = () => 5;
      this.valueGroupFieldsLimit = () => 5;
      this.valueFieldsLimit = () => 10;
      this.rowFieldsLimit = () => 15;

      this.hasRows =
        this.hasValues =
        this.hasRowGroups =
        this.hasValueGroups =
          true;
      this.templatesAreAllowed = true;
      this.totalFunctionsAreAllowed = true;
    }

    if (
      ['Column', 'StackedColumns', 'StackedPercentageColumns', 'Line'].indexOf(
        this.type,
      ) !== -1
    ) {
      this.rowsContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.category',
      );
      this.valuesContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.value',
      );
      this.valueGroupsContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.legend',
      );

      this.hasRows = this.hasValues = this.hasValueGroups = true;
      this.hasRowGroups = false;

      this.templatesAreAllowed = false;
      this.totalFunctionsAreAllowed = false;

      this.valueGroupFieldsLimit = function () {
        return this.valueFields && this.valueFields.length > 1 ? 0 : 1;
      };
      this.valueFieldsLimit = function () {
        return this.valueGroupFields && this.valueGroupFields.length > 0
          ? 1
          : 5;
      };
      this.rowFieldsLimit = () => 1;
    }

    if (this.type === 'Pie') {
      this.valuesContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.value',
      );
      this.valueGroupsContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.legend',
      );
      this.rowsContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.category',
      );

      this.hasRows = this.hasValues = true;
      this.hasValueGroups = false;
      this.hasRowGroups = false;

      this.templatesAreAllowed = false;
      this.totalFunctionsAreAllowed = false;

      this.valueFieldsLimit = () => 1;
      this.valueGroupFieldsLimit = () => 0;
      this.rowFieldsLimit = () => 1;
    }

    if (['Value', 'Speedometer'].indexOf(this.type) !== -1) {
      this.valuesContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.value',
      );

      this.hasValues = true;
      this.hasRows = this.hasRowGroups = this.hasValueGroups = false;

      this.templatesAreAllowed = false;
      this.totalFunctionsAreAllowed = false;

      this.valueFieldsLimit = () => 1;
      this.rowFieldsLimit = () => 0;
    }

    if (['DoubleValue'].indexOf(this.type) !== -1) {
      this.valuesContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.values',
      );

      this.hasValues = true;
      this.hasRows = this.hasValueGroups = this.hasRowGroups = false;

      this.templatesAreAllowed = false;
      this.totalFunctionsAreAllowed = false;

      this.valueFieldsLimit = () => 2;
    }

    if (this.type === 'Funnel') {
      this.valuesContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.value',
      );
      this.rowsContainerLabel = this.translate.instant(
        'analytics.reportViewSettings.labels.category',
      );

      this.hasRows = this.hasValues = true;

      this.valueFieldsLimit = () => 1;
      this.rowFieldsLimit = () => 1;
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      reportType: null,
      sourceFilter: '',
    });
    if (this.viewSettings?.sourceName) {
      this.loadDescription();

      this.data
        .collection('ReportTypes')
        .query({ filter: { code: this.viewSettings.sourceName } })
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((data) => {
          this.form.get('reportType').setValue(data[0], { emitEvent: false });
        });
    }

    this.form
      .get('sourceFilter')
      .valueChanges.pipe(auditTime(250), takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateSourceFieldRows();
        this.changeDetector.detectChanges();
      });

    merge(
      this.rowFields.valueChanges,
      this.rowGroupFields.valueChanges,
      this.valueFields.valueChanges,
      this.valueGroupFields.valueChanges,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateViewSettings());

    this.form
      .get('reportType')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.typeChanges.emit(value);

        this.viewSettings = {
          sourceName: value?.code,
          columnFields: [],
          columnGroupFields: [],
          valueFields: [],
          rowGroupFields: [],
        };

        this.loadDescription();
      });
  }
}
