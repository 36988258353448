import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  Signal,
  viewChild,
} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Chart from 'chart.js/auto';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { WidgetConfig } from 'src/app/analytics/dashboards/shared/widget-config.model';
import {
  ChartDataset,
  SortProperty,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';
import {
  Dataset,
  SortedData,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';
import _ from 'lodash';
import { WidgetDataService } from 'src/app/analytics/dashboards/dashboard/widget/widget-data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'tmt-pie-chart',
  template: '<div class="chart"><canvas #pieCanvas></canvas></div>',
  styleUrls: ['./widget-pie-chart.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WidgetDataService],
})
export class WidgetPieChartComponent {
  public pieCanvas = viewChild<ElementRef>('pieCanvas');
  public data = input<Dataset>();
  public widget = input<WidgetConfig>();

  public labels: string[] = [];
  public datasets: ChartDataset[] = [];

  private chart: Chart;

  constructor(
    private widgetDataService: WidgetDataService,
    private translateService: TranslateService,
  ) {
    effect(() => {
      if (!this.data()?.length) return;
      this.processData(this.data());
      this.pieChartMethod();
    });
  }

  /**
   * Processes raw data to prepare labels and datasets for the chart.
   *
   * @param data Raw data to process.
   */
  private processData(data: Dataset): void {
    const sortedData = this.sortData(data, this.widget);
    this.labels = this.setLabels(sortedData);
    this.datasets = this.widgetDataService.getChartDataset(
      sortedData as SortedData[],
    );
  }

  /**
   * Sorts the provided data based on the widget's configuration.
   *
   * @param data Data to sort.
   * @returns Sorted data.
   */
  public sortData(data: Dataset, widget: Signal<WidgetConfig>): SortedData[] {
    const sortProperty =
      widget().properties.sortBy === 'Value'
        ? SortProperty.value
        : SortProperty.category;

    let sortOrder: 'asc' | 'desc';
    if (widget().properties.sortReverse) {
      sortOrder = Constants.sortOrder.asc;
    } else {
      sortOrder = Constants.sortOrder.desc;
    }

    const hasLegend = widget().viewSettings.legendField != null;
    const map = new Map();

    data.forEach((arr) => {
      let category: string | number;
      let value: string | number;
      let legend: string | number;

      if (!hasLegend) {
        [category, value] = arr;
        legend = this.translateService.instant('shared2.props.value');
      } else {
        [category, legend, value] = arr;
      }

      if (!map.has(category)) {
        map.set(category, {
          category,
          value: 0,
          detailed: {},
        });
      }

      const item = map.get(category);
      item.value += value;
      item.detailed[legend] = value;
    });

    const aggregated = Array.from(map.values());
    let sorted = _.orderBy(aggregated, [sortProperty], sortOrder);

    if (widget().properties.showTop) {
      sorted = sorted.slice(0, widget().properties.showTop);
    }

    return sorted;
  }

  /**
   * Sets value of labels array.
   *
   * @param sortedData sorted chart data.
   * @returns Labels array.
   */
  public setLabels(sortedData: SortedData[]): string[] {
    return sortedData.map((item) =>
      typeof item.category === 'number'
        ? item.category.toString()
        : item.category,
    );
  }

  /** Creates and configures the pie chart instance. */
  private pieChartMethod(): void {
    this.chart?.destroy();

    this.chart = new Chart(this.pieCanvas()?.nativeElement, {
      plugins: [ChartDataLabels],
      type: 'pie',
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: {
        plugins: {
          legend: {
            display: this.widget().properties.hasLegend,
            position: 'bottom',
            labels: {
              font: {
                size: 16,
              },
            },
          },
          datalabels: {
            display: this.widget().properties.hasDataLabels,
            anchor: 'center',
            align: 'end',
            labels: {
              value: {
                color: '#000000',
                font: {
                  size: 20,
                },
              },
            },
            formatter(value, context): string[] {
              let totalLength = 0;
              context.dataset.data.forEach((el) => {
                totalLength += el as number;
              });
              return [`${Math.round((value / totalLength) * 100)}%`];
            },
          },
        },
      },
    });
  }
}
