import { Component, signal } from '@angular/core';

import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BoardDataService } from 'src/app/boards/services/board-data.service';

import { BoardService } from 'src/app/boards/services/board.service';
import { MetaEntityBaseProperty } from 'src/app/shared/models/entities/settings/metamodel.model';

@Component({
  selector: 'tmt-board-mini-card-builder-modal',
  templateUrl: './board-mini-card-builder-modal.component.html',
  standalone: false,
})
export class BoardMiniCardBuilderModalComponent {
  public result: MetaEntityBaseProperty[];
  public saving = signal(false);

  constructor(
    public boardService: BoardService,
    private boardDataService: BoardDataService,
    private activeModal: NgbActiveModal,
  ) {}

  /** Sends new card structure and closes modal. */
  public ok(): void {
    if (!this.result) {
      this.activeModal.close(this.result);
      return;
    }

    this.saving.set(true);
    this.boardDataService
      .updateBoard({
        cardProperties: this.result.map((property) => property.name),
      })
      .subscribe({
        next: () => {
          this.saving.set(false);
          this.activeModal.close(this.result);
        },
        error: () => {
          this.saving.set(false);
        },
      });
  }

  /** Closes modal. */
  public closeModal(): void {
    this.activeModal.dismiss(null);
  }
}
