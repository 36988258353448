import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EntityType } from '../../../../../shared-features/resource-roles/enums/entity-type.enum';
import { Dictionary } from '../../../../../shared/models/dictionary';
import { Group } from '../../../../../shared/models/entities/settings/group.model';
import { Observable, Subscription } from 'rxjs';
import { AppService } from '../../../../../core/app.service';
import { UntypedFormBuilder } from '@angular/forms';
import { NotificationService } from '../../../../../core/notification.service';
import { DataService } from '../../../../../core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { sortBy } from 'lodash';
import { Exception } from '../../../../../shared/models/exception';
import { Constants } from '../../../../../shared/globals/constants';
import { User } from '../../../../../shared/models/entities/settings/user.model';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'tmt-app-add-user-to-group-modal',
  templateUrl: './add-user-to-group-modal.component.html',
  styleUrls: ['./add-user-to-group-modal.component.scss'],
  standalone: false,
})
export class AddUserToGroupModalComponent implements OnInit {
  public loadLimit = 250;
  /** Entity for which groups are selected. */
  @Input() entityType: EntityType;
  /** Id of the entity for which the groups are selected. */
  @Input() entityId: string;

  /** IDs of previously added roles. */
  @Input() alreadyAddedIds: string[] = [];
  @ViewChild('leftTbl') leftTbl: ElementRef;

  public isSaving: boolean;
  public isLoading: boolean;
  public loadedPartly: boolean;

  public leftTableStyles: Dictionary<string> = {};

  public availableUsers: User[] = [];
  public selectedUsers: User[] = [];

  public filterForm = this.fb.group({
    name: [''],
  });
  private loadingSubscription: Subscription;

  constructor(
    public app: AppService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.load();
    this.filterForm.valueChanges.subscribe(() => this.load());
  }

  /** Method that close modal on cancel button. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /** Method that resize left table. */
  public resizeLeftTbl() {
    this.leftTableStyles['display'] = 'table';
    this.leftTableStyles['width'] =
      (<HTMLElement>this.leftTbl.nativeElement).getBoundingClientRect().width +
      'px';
  }

  /** Method that select user. */
  public selectUser(user: User, index: number) {
    this.selectedUsers.push(user);
    this.selectedUsers = sortBy(this.selectedUsers, ['name']);
    this.availableUsers.splice(index, 1);
  }

  /** Method that remove group. */
  public removeUser(user: User, index: number) {
    this.availableUsers.push(user);
    this.selectedUsers.splice(index, 1);
    this.availableUsers = sortBy(this.availableUsers, ['name']);
  }

  /** Method that close modal on ok button. */
  public ok() {
    this.activeModal.close(this.selectedUsers);
  }

  private load() {
    this.availableUsers = [];
    this.isLoading = true;
    this.loadedPartly = false;

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.loadUsers().subscribe({
      next: (data) => {
        data = data.filter(
          (user) =>
            this.selectedUsers.find((u) => u.id === user.id) == null &&
            this.alreadyAddedIds.find((id) => id === user.id) == null,
        );
        this.availableUsers = data;
        this.isLoading = false;
        this.loadedPartly = this.availableUsers.length === this.loadLimit;
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isLoading = false;
      },
    });
  }

  private loadUsers(): Observable<User[]> {
    const usersQuery = {
      orderBy: ['name'],
      expand: {
        department: {
          select: ['name'],
        },
        resourcePool: {
          select: ['name'],
        },
      },
      filter: <any[]>[{ isActive: true }],
    };
    const defaultFilter = this.filterForm.value;
    if (defaultFilter.name) {
      const text = (defaultFilter.name as string)
        .substring(0, Constants.filterInputLengthLimit)
        .toLowerCase();
      usersQuery.filter.push({
        or: [
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(name)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(code)': { contains: text } },
          // eslint-disable-next-line @typescript-eslint/naming-convention
          { 'tolower(email)': { contains: text } },
        ],
      });
    }
    return this.data
      .collection('Groups')
      .entity(this.entityId)
      .get<Group>()
      .pipe(
        switchMap(() =>
          this.data.collection('Users').query<User[]>(usersQuery),
        ),
      );
  }
}
