import { Component, OnInit, Input } from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { ProjectFilterService } from 'src/app/projects/list/project-filter/project-filter.service';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { PROJECT_LIST } from 'src/app/shared/lists/project.list';
import { ClientCardService } from '../client-card.service';
import { Organization } from 'src/app/shared/models/entities/projects/organization.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-client-projects',
  templateUrl: './client-projects.component.html',
  styleUrls: ['./client-projects.component.scss'],
  providers: [
    { provide: VIEW_NAME, useValue: 'client' },
    { provide: LIST, useValue: PROJECT_LIST },
    { provide: FilterService, useClass: ProjectFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  standalone: false,
})
export class ClientProjectsComponent implements OnInit {
  @Input() entityId: string;
  client: Organization;
  reloadListener: any;

  constructor(
    private service: ClientCardService,
    private entityListService: EntityListService,
  ) {}

  ngOnInit() {
    this.entityListService.contextFilter = [
      {
        project: {
          organizationId: { type: 'guid', value: this.entityId },
        },
      },
    ];

    this.reloadListener = this.service.reloadTab$.subscribe(() =>
      this.entityListService.reload(),
    );

    this.service.client$.subscribe((client) => (this.client = client));
  }
}
