<tmt-form-header [name]="client()?.name" [isEditable]="client()?.editAllowed" [saveFn]="saveName" />

<tmt-not-found [state]="clientCardService.state()" />

<wp-loading-indicator [state]="clientCardService.state()" />

@if (clientCardService.state() === 'Ready') {
  <div class="subheader">
    <div class="subheader__props">
      <div class="subheader__prop">
        <span class="subheader__label"
          >{{ 'projects.clients.card.props.manager' | translate }}:</span
        >
        <div class="subheader__value" id="manager">
          <button type="button" (click)="openUserInfo()" class="btn btn-link btn-link-content">
            {{ client()?.manager?.name }}
          </button>
        </div>
      </div>
      @if (client()?.site) {
        <div class="subheader__prop">
          <span class="subheader__label"
            >{{ 'projects.clients.settings.props.site' | translate }}:</span
          >

          <div class="subheader__value">
            <a href="{{ client()?.site }}" title="{{ client()?.site }}" target="_blank">{{
              client()?.site
            }}</a>
          </div>
        </div>
      }
    </div>
    <div class="subheader__controls">
      <div class="btn-group" role="group">
        @if (client()?.editAllowed) {
          <div
            title="{{ 'projects.clients.card.states.hint' | translate }}"
            ngbDropdown
            class="btn-group"
            placement="bottom-left"
            style="z-index: 1"
          >
            <button type="button" class="btn btn-default" ngbDropdownToggle>
              <ng-container *ngTemplateOutlet="activity" />
            </button>
            <div ngbDropdownMenu>
              <button ngbDropdownItem (click)="selectState(true)">
                {{ 'projects.clients.card.states.active' | translate }}
              </button>
              <button ngbDropdownItem (click)="selectState(false)">
                {{ 'projects.clients.card.states.closed' | translate }}
              </button>
            </div>
          </div>
        } @else {
          <button
            type="button"
            title="{{ 'projects.clients.card.states.hint' | translate }}"
            class="btn btn-default bt-info"
          >
            <ng-container *ngTemplateOutlet="activity" />
          </button>
        }

        <button
          type="button"
          class="btn btn-default btn-indicator"
          title="{{ 'projects.clients.card.props.actualHours' | translate }}"
        >
          <i class="bi bi-clock" aria-hidden="true"></i>&nbsp;
          <span
            [wpLoadingValue]="
              (clientCardService.clientTotal$ | async)?.actualHours | wpWork: true : true
            "
            [loading]="!(clientCardService.clientTotal$ | async)"
          ></span>
        </button>
      </div>
    </div>
  </div>

  <div class="position-relative">
    <ul class="nav nav-tabs">
      <li class="nav-item" *ngFor="let tab of tabs">
        <a
          class="nav-link"
          [ngClass]="{ active: (uiRouterGlobals.current?.name ?? '').indexOf(tab.state) > -1 }"
          [uiSref]="tab.state"
          [uiParams]="{ routeMode: 'keep' }"
          [attr.data-test]="tab.state"
          >{{ tab.header | translate }}</a
        >
      </li>
    </ul>
    <ui-view></ui-view>
  </div>
}

<ng-template #activity>
  @if (client() && client().isActive) {
    {{ 'projects.clients.card.states.active' | translate }}
  } @else {
    {{ 'projects.clients.card.states.closed' | translate }}
  }
</ng-template>
