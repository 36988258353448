import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  input,
  viewChild,
} from '@angular/core';
// eslint-disable-next-line @typescript-eslint/naming-convention
import Chart from 'chart.js/auto';
// eslint-disable-next-line @typescript-eslint/naming-convention
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { BehaviorSubject } from 'rxjs';
import { WidgetService } from 'src/app/analytics/dashboards/dashboard/widget/widget.service';
import { WidgetConfig } from 'src/app/analytics/dashboards/shared/widget-config.model';
import { ChartDataset } from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';
import {
  Dataset,
  SortedData,
  SortProperty,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { TranslateService } from '@ngx-translate/core';
import _ from 'lodash';
import { WidgetDataService } from 'src/app/analytics/dashboards/dashboard/widget/widget-data.service';

@Component({
  selector: 'tmt-stacked-columns-chart',
  templateUrl: './widget-stacked-column.component.html',
  styleUrls: ['./widget-stacked-column.component.scss'],
  standalone: false,
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [WidgetDataService],
})
export class StackedColumnsChartComponent implements AfterViewInit {
  @Input() set data(value: Dataset) {
    this.dataSubject.next(value || []);
  }
  widget = input<WidgetConfig>();
  barCanvas = viewChild<ElementRef>('barCanvas');

  public labels: string[] = [];
  public datasets: ChartDataset[] = [];

  private chart: Chart;
  private dataSubject = new BehaviorSubject([]);
  private destroyRef = inject(DestroyRef);

  constructor(
    public widgetService: WidgetService,
    private translate: TranslateService,
    private widgetDataService: WidgetDataService,
  ) {}

  public ngAfterViewInit(): void {
    this.dataSubject
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((data: Dataset) => {
        if (!data.length) return;
        this.processData(data);
        this.barChartMethod();
      });
  }

  /**
   * Resizes the chart.
   *
   * @param width The new width of the chart.
   * @param height The new height of the chart.
   */
  public resize(width: number, height: number): void {
    if (this.chart) this.chart.resize(width, height);
  }

  /** Creates and configures the bar chart instance. */
  private barChartMethod(): void {
    this.chart?.destroy();

    this.chart = new Chart(this.barCanvas()?.nativeElement, {
      plugins: [ChartDataLabels],
      type: 'bar',
      data: {
        labels: this.labels,
        datasets: this.datasets,
      },
      options: {
        plugins: {
          tooltip: {
            enabled: true,
          },
          legend: {
            display: this.widget().properties.hasLegend,
            position: 'bottom',
          },
          datalabels: {
            display: this.widget().properties.hasDataLabels,
          },
        },
        responsive: true,
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true,
            beginAtZero: true,
          },
        },
      },
    });
  }

  /**
   * Processes raw data to prepare labels and datasets for the chart.
   *
   * @param data Raw data to process.
   */
  private processData(data: Dataset): void {
    const sortedData = this.sortData(data);
    this.datasets = this.widgetDataService.getChartDataset(
      sortedData as SortedData[],
    );
  }

  /**
   * Sorts the provided data based on the widget's configuration.
   *
   * @param data Data to sort.
   * @returns Sorted data.
   */
  private sortData(data: Dataset): SortedData[] {
    const sortProperty =
      this.widget().properties.sortBy === 'Value'
        ? SortProperty.value
        : SortProperty.category;
    const sortOrder = Constants.sortOrder[this.widget().properties.sortReverse];
    const hasLegend = this.widget().viewSettings.legendField != null;
    const map = new Map();

    data.forEach((arr) => {
      let category: string | number;
      let value: string | number;
      let legend: string | number;

      if (!hasLegend) {
        [category, value] = arr;
        legend = this.translate.instant('components.histogram.props.value');
      } else {
        [category, legend, value] = arr;
      }

      if (!map.has(category)) {
        map.set(category, {
          category,
          value: 0,
          detailed: {},
        });
      }

      const item = map.get(category);
      item.value += value;
      item.detailed[legend] = value;
    });

    const aggregated = Array.from(map.values());
    let sorted = _.orderBy(aggregated, [sortProperty], [sortOrder]);

    this.labels = sorted.map((item) => item.category);

    if (this.widget().properties.showTop) {
      sorted = sorted.slice(0, this.widget().properties.showTop);
    }

    return sorted;
  }
}
