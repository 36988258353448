import { Component, OnInit, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Report } from 'src/app/shared/models/entities/analytics/report.model';
import { Constants } from 'src/app/shared/globals/constants';
import { StateService } from '@uirouter/core';
import { Exception } from 'src/app/shared/models/exception';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';

/** Диалог сохранения отчета. */
@Component({
  selector: 'wp-report-save-as',
  templateUrl: './report-save-as.component.html',
  styleUrls: ['./report-save-as.component.scss'],
  standalone: false,
})
export class ReportSaveAsComponent implements OnInit {
  /** Сущность отчета. */
  @Input() report: Report;

  /** Признак режима Нового отчета. */
  @Input() buildMode: boolean;

  public isSaving: boolean;
  public typesIsAllowed: boolean;
  public templatesIsAllowed: boolean;
  public commonReportsIsAllowed: boolean;

  public form = this.fb.group({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(Constants.formNameMaxLength),
    ]),
    type: 'my',
  });

  constructor(
    private app: AppService,
    private fb: UntypedFormBuilder,
    private stateService: StateService,
    private data: DataService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
  ) {}

  /** Выполнить сохранение. */
  public ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.isSaving = true;

    const postUpdate = (id) => {
      this.notification.successLocal(
        'analytics.reports.reportSettings.messages.reportSaved',
      );

      if (this.form.value.type === 'template') {
        this.stateService.go('analytics.reportTemplate', { entityId: id });
      } else {
        this.stateService.go('report', { entityId: id });
      }
      this.isSaving = false;
      this.activeModal.close();
    };

    if (this.buildMode) {
      this.data
        .collection('Reports')
        .entity(this.report.id)
        .patch({
          isNew: false,
          isTemplate: this.form.value.type === 'template',
          isCommon: this.form.value.type === 'common',
          name: this.form.value.name,
        })
        .subscribe({
          next: () => {
            postUpdate(this.report.id);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.isSaving = false;
          },
        });
    } else {
      const report: any = {
        viewSettings: this.report.viewSettings,
        reportTypeId: this.report.reportType.id,
        isTemplate: this.form.value.type === 'template',
        isCommon: this.form.value.type === 'common',
        name: this.form.value.name,
      };

      this.data
        .collection('Reports')
        .insert(report)
        .subscribe({
          next: (data) => {
            // Получить настройки пользователя для их создания
            this.data
              .collection('Reports')
              .entity(data.id)
              .function('WP.GetUserSettings')
              .get()
              .subscribe({
                next: (userSettings: any) => {
                  this.data
                    .collection('Reports')
                    .entity(data.id)
                    .action('WP.UpdateUserSettings')
                    .execute({ userSettings })
                    .subscribe({
                      next: () => {
                        postUpdate(data.id);
                      },
                      error: (error: Exception) => {
                        this.notification.error(error.message);
                        this.isSaving = false;
                      },
                    });
                },
                error: (error: Exception) => {
                  this.notification.error(error.message);
                  this.isSaving = false;
                },
              });
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.isSaving = false;
          },
        });
    }
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit(): void {
    this.templatesIsAllowed = this.app.checkPermission(
      'Report',
      'Templates',
      PermissionType.Modify,
    );
    this.commonReportsIsAllowed = this.app.checkPermission(
      'Report',
      'Common',
      PermissionType.Modify,
    );
    this.typesIsAllowed =
      this.templatesIsAllowed || this.commonReportsIsAllowed;
  }
}
