import {
  ChangeDetectionStrategy,
  Component,
  effect,
  ElementRef,
  input,
  OnChanges,
  OnInit,
  output,
  signal,
  SimpleChanges,
  TemplateRef,
  viewChild,
} from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { LocalStorageService } from 'ngx-webstorage';

import { debounceTime } from 'rxjs';

import { Constants } from 'src/app/shared/globals/constants';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';

import { SortItem } from './board-sort-button.interface';

@Component({
  selector: 'tmt-board-sort-button',
  templateUrl: './board-sort-button.component.html',
  styleUrl: './board-sort-button.component.scss',
  host: {
    class: 'btn-group',
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BoardSortButtonComponent implements OnInit, OnChanges {
  private listRef = viewChild<TemplateRef<HTMLElement>>('list');
  /** Entity properties as SortItems. */
  public properties = input.required<SortItem[]>();
  /** Local storage key. */
  protected storageName = input<string>();

  public orderQuery = output<string>();

  public form = this.fb.group({
    search: null,
  });
  public focusedProperty = signal<SortItem | null>(null);
  public filteredProperties = signal<SortItem[]>([]);
  public sortTarget = signal<SortItem | null>(null);
  public sortDirection = signal<'asc' | 'desc'>('asc');

  private isListOpened: boolean;
  private popupId: string;

  constructor(
    private infoPopupService: InfoPopupService,
    private el: ElementRef<HTMLElement>,
    private fb: UntypedFormBuilder,
    private localStorageService: LocalStorageService,
    private scrollToService: ScrollToService,
  ) {
    effect(() => {
      const target = this.sortTarget();
      const direction = this.sortDirection();
      const result = target
        ? `${target.isNavigation ? target.key + '/name' : target.key} ${direction}`
        : '';

      if (this.storageName) {
        this.localStorageService.store(this.storageName(), {
          target,
          direction,
        });
      }

      this.orderQuery.emit(result);
    });

    this.form.controls['search'].valueChanges
      .pipe(
        debounceTime(Constants.textInputClientDebounce),
        takeUntilDestroyed(),
      )
      .subscribe((value: string) => {
        this.filteredProperties.set(this.getFiltered(value));
      });
  }

  public ngOnInit(): void {
    if (this.storageName) {
      const value = this.localStorageService.retrieve(this.storageName());

      if (value?.target) {
        this.sortTarget.set(value.target);
        this.sortDirection.set(value.direction);
      }
    }
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['properties']) {
      this.filteredProperties.set(this.getFiltered(this.form.value['search']));
    }
  }

  /** Toggles popup visibility with list of properties. */
  public toggleList(): void {
    this.isListOpened = !!this.infoPopupService.getById(this.popupId);

    if (this.isListOpened) {
      this.infoPopupService.close(this.popupId);
      return;
    }

    this.popupId = this.infoPopupService.open({
      target: this.el.nativeElement,
      data: {
        templateRef: this.listRef(),
      },
      isHideArrow: true,
      containerStyles: {
        padding: 0,
      },
      onDestroy: () => this.form.patchValue({ search: '' }),
    });
  }

  /**
   * Changes sorting target.
   *
   * @param item Sort item.
   */
  public changeTarget(item: SortItem): void {
    this.toggleList();
    this.sortTarget.set(item.isDefault ? null : item);
  }

  /** Toggles sorting direction. */
  public toggleDirection(): void {
    this.sortDirection.update((value) => (value === 'asc' ? 'desc' : 'asc'));
  }

  /**
   * Keyboard handler.
   *
   * @param event
   */
  public onKeyDown(event: KeyboardEvent): void {
    const enterCode = 'Enter';
    const downCode = 'ArrowDown';
    const upCode = 'ArrowUp';
    const escCode = 'Escape';

    switch (event.code) {
      case downCode: {
        if (!this.focusedProperty()) {
          this.focusedProperty.set(this.filteredProperties()[0]);
        } else {
          const index = this.filteredProperties().findIndex(
            (v) => v.key === this.focusedProperty().key,
          );

          this.focusedProperty.set(
            this.filteredProperties()[index + 1] ?? this.focusedProperty(),
          );
        }
        break;
      }
      case upCode:
        {
          if (!this.focusedProperty()) {
            this.focusedProperty.set(
              this.filteredProperties[this.properties.length - 1],
            );
          } else {
            const index = this.filteredProperties().findIndex(
              (v) => v.key === this.focusedProperty().key,
            );

            this.focusedProperty.set(
              this.filteredProperties()[index - 1] ?? this.focusedProperty(),
            );
          }
        }
        break;
      case enterCode: {
        this.sortTarget.set(this.focusedProperty());
        this.toggleList();
        break;
      }
      case escCode: {
        this.toggleList();
        break;
      }
    }

    if ([downCode, upCode].includes(event.code)) {
      setTimeout(() => {
        this.scrollToFocusedItem();
      });
    }

    if ([downCode, upCode, escCode, enterCode].includes(event.code)) {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private scrollToFocusedItem(): void {
    if (this.focusedProperty()) {
      this.scrollToService.scrollTo(
        `${this.focusedProperty().key}`,
        'selecting-list',
      );
    }
  }

  private getFiltered(value?: string): SortItem[] {
    return !value?.length
      ? this.properties().slice(0)
      : this.properties()
          .slice(0)
          .filter((property) =>
            property.name.toLowerCase().includes(value.toLowerCase()),
          );
  }
}
