import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import _ from 'lodash';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { ResourceRolesService } from '../../resource-roles.service';
import { RoleCompetence } from 'src/app/shared/models/entities/settings/role.model';

@Component({
  selector: 'tmt-competences-cell',
  templateUrl: './competences-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CompetencesCellComponent implements GridComponentCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public roleCompetences: RoleCompetence[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private gridService: GridService,
    private cdr: ChangeDetectorRef,
    private resourceRolesService: ResourceRolesService,
  ) {}

  public ngOnInit(): void {
    this.roleCompetences = this.formGroup.getRawValue().role.competences;

    this.gridService.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    this.formGroup
      .get('competences')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.resourceRolesService.detectChanges();
      });
  }
}
