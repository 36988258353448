import { Component } from '@angular/core';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { BlockUIService } from 'src/app/core/block-ui.service';

@Component({
  selector: 'wp-settings-data-deletion',
  templateUrl: './settings-data-deletion.component.html',
  standalone: false,
})
export class SettingsDataDeletionComponent {
  public isDeleting: boolean;

  public confirmation: string;

  constructor(
    private data: DataService,
    private message: MessageService,
    private notification: NotificationService,
    private blockUI: BlockUIService,
  ) {}

  public delete() {
    this.message.confirmLocal('settings.settings.dad.confirmation').then(
      () => {
        this.isDeleting = true;
        this.blockUI.start();

        this.data.model
          .action('ClearAllData')
          .execute()
          .subscribe({
            next: () => {
              this.isDeleting = false;
              this.blockUI.stop();
              this.notification.successLocal('settings.settings.dad.completed');
            },
            error: (error: Exception) => {
              this.isDeleting = false;
              this.blockUI.stop();
              this.notification.error(error.message);
            },
          });
      },
      () => null,
    );
  }
}
