import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import {
  FormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { bcp47Normalize } from 'bcp-47-normalize';
import { forkJoin } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { SettingsCardService } from 'src/app/settings-app/settings/settings-card.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  CapitalChargeRates,
  TenantSetting,
  TenantSettingPayload,
} from 'src/app/shared/models/entities/settings/multitenant/tenant-settings.model';
import { ForecastPeriod } from 'src/app/shared/models/enums/forecast-period.enum';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { BookingMode } from 'src/app/shared/models/enums/booking-mode.enum';
import {
  AllPlanningScales,
  PlanningScale,
} from 'src/app/shared/models/enums/planning-scale.enum';
import { BookingModeChangeDialogComponent } from 'src/app/settings-app/settings/settings-applications/booking-mode-change-dialog/booking-mode-change-dialog.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridDateControlColumn,
  GridStringControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { DateTime } from 'luxon';
import { CapitalChargesToolbarComponent } from 'src/app/settings-app/settings/settings-applications/capital-charges-toolbar/capital-charges-toolbar.component';

@Component({
  selector: 'tmt-settings-applications',
  templateUrl: './settings-applications.component.html',
  styleUrls: ['./settings-applications.component.scss'],
  providers: [GridService],
  standalone: false,
})
export class SettingsApplicationsComponent implements OnInit {
  public readonly = false;
  public isSaving = false;

  public state: CardState;

  tenantSettings: TenantSetting;
  cultures: NamedEntity[];
  languages: NamedEntity[];
  forecastPeriods: NamedEntity[] = Object.keys(ForecastPeriod).map(
    (k) =>
      ({
        id: k,
        name: this.translate.instant(`enums.forecastPeriod.${k}`),
      }) as NamedEntity,
  );
  bookingModes: NamedEntity[] = Object.keys(BookingMode).map(
    (bm) =>
      ({
        id: bm,
        name: this.translate.instant(`enums.bookingMode.${bm}`),
      }) as NamedEntity,
  );
  bookingScales: NamedEntity[] = Object.keys(PlanningScale)
    .filter(
      (_, index) => index <= AllPlanningScales.indexOf(PlanningScale.Month),
    )
    .map(
      (bs) =>
        ({
          id: bs,
          name: this.translate.instant(`enums.planningScale.${bs}`),
        }) as NamedEntity,
    );

  public form = this.fb.group({
    id: null,
    timeZone: [null, Validators.required],
    language: [null, Validators.required],
    culture: [null, Validators.required],
    currency: [null, Validators.required],
    useVat: [false],
    forecastPeriod: [null, Validators.required],
    bookingMode: [null],
    isBookingScaleFixed: [false],
    bookingScale: [null],
    capitalChargeRates: this.fb.group({
      initialValue: this.fb.group({
        effectiveDate: null,
        value: [null, Validators.required],
        id: null,
      }),
      values: this.fb.array([]),
    }),
    capitalCharge: this.fb.group({
      isAccrueCostOnWorkingCapital: false,
      isAccrueCostOnOutstandingExpenses: false,
    }),
    showExtendedPnl: [false],
  });

  public gridOptions: GridOptions = {
    toolbar: CapitalChargesToolbarComponent,
    selectionType: SelectionType.row,
    commands: [
      {
        name: 'addLine',
        handlerFn: () => {
          this.addCapitalChargeRateLine(this.capitalChargeRatesFormValues);
          this.form.markAsDirty();
        },
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.removeCapitalChargeRateLine(
            this.capitalChargeRatesFormValues,
            index,
          );
          this.form.markAsDirty();
        },
      },
    ],
    view: {
      name: 'capital-charges-rates',
      columns: [
        {
          name: 'effectiveDate',
          header: 'shared2.props.effectiveDate',
          hint: 'shared2.props.effectiveDate',
          type: GridColumnType.DateControl,
          property: 'effectiveDate',
          width: '150px',
          allowNull: false,
        } as GridDateControlColumn,
        {
          name: 'value',
          header: 'components.settingsApplicationsComponent.props.value',
          hint: 'components.settingsApplicationsComponent.props.value',
          type: GridColumnType.NumberControl,
          controlType: 'percent',
          contentType: GridColumnType.Percent,
          propagationMode: PropagationMode.onExitFromEditing,
          min: 0,
          max: 1000,
          allowNull: false,
          width: '180px',
        } as GridStringControlColumn,
      ],
    },
  };

  public get capitalChargeFormGroup(): UntypedFormGroup {
    return this.form.controls.capitalCharge as UntypedFormGroup;
  }

  public get capitalChargeRatesFormGroup(): UntypedFormGroup {
    return this.form.controls.capitalChargeRates as UntypedFormGroup;
  }
  public get capitalChargeRatesFormValues(): UntypedFormArray {
    return this.capitalChargeRatesFormGroup.controls.values as UntypedFormArray;
  }
  private destroyRef = inject(DestroyRef);

  constructor(
    private service: SettingsCardService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private gridService: GridService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: true,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.form.controls.isBookingScaleFixed.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (value) {
          this.form.controls.bookingScale.addValidators(Validators.required);
          this.form.controls.bookingScale.updateValueAndValidity();
        } else {
          this.form.controls.bookingScale.removeValidators(Validators.required);
          this.form.controls.bookingScale.updateValueAndValidity();
        }
      });

    this.service.reloadTab$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Changes booking mode. */
  public changeBookingMode(): void {
    const modalRef = this.modalService.open(BookingModeChangeDialogComponent);
    const instance =
      modalRef.componentInstance as BookingModeChangeDialogComponent;
    instance.currentBookingMode = this.form.value.bookingMode.name;
    instance.newBookingMode = this.bookingModes.find(
      (x) => x.id !== this.form.value.bookingMode.id,
    ).name;
    modalRef.result.then(
      () => {
        this.load();
      },
      () => null,
    );
  }

  public onSave(): void {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.save();
  }

  private save(): void {
    this.isSaving = true;
    this.actionService.action('save').start();

    const tenantSetting = this.form.getRawValue() as TenantSetting;

    const setting: TenantSettingPayload = {
      id: tenantSetting.id,
      languageCode: this.tenantSettings.languageCode,
      cultureCode: this.tenantSettings.cultureCode,
      forecastPeriod: this.form.value.forecastPeriod.id,
      timeZoneId: this.tenantSettings.timeZone.id,
      currencyId: this.tenantSettings.currency.id,
      useVat: tenantSetting.useVat,
      clientsSettings: this.tenantSettings.clientsSettings,
      bookingScale:
        this.form.value.isBookingScaleFixed && this.form.value.bookingScale?.id
          ? this.form.value.bookingScale.id
          : null,
      isAccrueCostOnOutstandingExpenses:
        this.capitalChargeFormGroup.controls.isAccrueCostOnOutstandingExpenses
          .value,
      isAccrueCostOnWorkingCapital:
        this.capitalChargeFormGroup.controls.isAccrueCostOnWorkingCapital.value,
      showExtendedPnl: tenantSetting.showExtendedPnl,
    };

    const initialValue = this.capitalChargeRatesFormGroup.value.initialValue;

    const capitalChargeRateValues = this.capitalChargeRatesFormValues.value;

    capitalChargeRateValues.forEach(
      (rate) => (rate.tenantSettingId = tenantSetting.id),
    );

    capitalChargeRateValues.push({
      effectiveDate: null,
      id: initialValue.id ?? Guid.generate(),
      value: initialValue.value,
      tenantSettingId: tenantSetting.id,
    });

    forkJoin([
      this.data
        .collection('TenantSettings')
        .action('UpdateCapitalChargeRateValues')
        .execute({ capitalChargeRateValues }),
      this.data
        .collection('TenantSettings')
        .action('UpdateSingle')
        .execute({ setting }),
    ]).subscribe({
      next: () => {
        this.form.markAsPristine();
        this.isSaving = false;
        this.actionService.action('save').stop();
        this.notification.successLocal(
          'settings.settings.messages.settingSaved',
        );
        location.reload();
      },
      error: (error: Exception) => {
        this.isSaving = false;
        this.actionService.action('save').stop();
        this.notification.error(error.message);
      },
    });
  }

  private load(): void {
    this.capitalChargeRatesFormValues.clear();
    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    forkJoin({
      cultures: this.data.model.function('GetCultures').get<NamedEntity[]>(),
      languages: this.data.model.function('GetLanguages').get<NamedEntity[]>(),
      settings: this.data
        .collection('TenantSettings')
        .function('GetSingle')
        .get<TenantSetting>(null, {
          select: '*',
          expand: {
            timeZone: { select: ['id', 'name'] },
            currency: { select: ['id', 'name'] },
            capitalChargeRateValues: {
              select: ['id', 'effectiveDate', 'value'],
              orderBy: 'effectiveDate desc',
            },
          },
        }),
    })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (data) => {
          this.languages = data.languages;
          this.cultures = data.cultures;

          this.tenantSettings = data.settings;
          this.form.patchValue(data.settings);

          this.form.controls['language'].setValue(
            data.languages.find((l) => l.id === data.settings.languageCode),
          );

          this.form.controls['culture'].setValue(
            data.cultures.find(
              (l) => l.id === bcp47Normalize(data.settings.cultureCode),
            ),
          );

          this.form.controls['forecastPeriod'].setValue(
            this.forecastPeriods.find(
              (r) => r.id === data.settings.forecastPeriod,
            ),
          );

          this.form.controls['bookingMode'].setValue(
            this.bookingModes.find((r) => r.id === data.settings.bookingMode),
          );

          if (data.settings.bookingScale) {
            this.form.controls['isBookingScaleFixed'].setValue(true);
            this.form.controls['bookingScale'].setValue(
              this.bookingScales.find(
                (r) => r.id === data.settings.bookingScale,
              ),
            );
          }
          this.form.controls['currency'].disable();

          let initialValue = data.settings.capitalChargeRateValues.find(
            (rate: CapitalChargeRates) => !rate.effectiveDate,
          );

          if (initialValue) {
            const index =
              data.settings.capitalChargeRateValues.indexOf(initialValue);
            data.settings.capitalChargeRateValues.splice(index, 1);
          } else {
            initialValue = {
              effectiveDate: null,
              id: Guid.generate(),
              value: null,
            };
          }

          const initValueGroup = this.capitalChargeRatesFormGroup.get(
            'initialValue',
          ) as FormGroup;
          initValueGroup?.patchValue(initialValue, { emitEvent: false });

          data.settings.capitalChargeRateValues.forEach(
            (rate: CapitalChargeRates) => {
              const group = this.getCapitalChargeRateLineFormGroup(rate);
              this.capitalChargeRatesFormValues.insert(0, group);
            },
          );

          this.capitalChargeFormGroup.controls.isAccrueCostOnWorkingCapital.setValue(
            data.settings.isAccrueCostOnWorkingCapital,
            { emitEvent: false },
          );

          this.capitalChargeFormGroup.controls.isAccrueCostOnOutstandingExpenses.setValue(
            data.settings.isAccrueCostOnOutstandingExpenses,
            { emitEvent: false },
          );

          this.state = CardState.Ready;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state = CardState.Error;
        },
      });
  }

  /** Reloads data. */
  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  /** Adds line of capital charge rate. */
  private addCapitalChargeRateLine(
    capitalChargeRatesFormValues: UntypedFormArray,
  ): void {
    const group = this.getCapitalChargeRateLineFormGroup();
    capitalChargeRatesFormValues.insert(0, group);

    group.controls.effectiveDate.setValue(DateTime.now().toISODate());

    this.gridService.selectGroup(group);
  }

  /**
   * Removes line of capital charge rate.
   *
   * @param index index of row.
   */
  private removeCapitalChargeRateLine(
    capitalChargeRatesFormValues: UntypedFormArray,
    index: number,
  ): void {
    capitalChargeRatesFormValues.removeAt(index);
  }

  /**
   * Gets capital charge line form group.
   *
   * @param row selected row.
   * @returns FormGroup of CapitalChargeRates.
   */
  private getCapitalChargeRateLineFormGroup(
    row?: CapitalChargeRates,
  ): FormGroup {
    const group = this.fb.group({
      effectiveDate: [row?.effectiveDate ?? null, Validators.required],
      value: [row?.value ?? 0, Validators.required],
      id: row?.id ?? Guid.generate(),
    });

    group.controls.effectiveDate.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((val) => {
        group.controls.effectiveDate.patchValue(
          DateTime.fromISO(val).set({ day: 1 }).toISODate(),
          { emitEvent: false },
        );
      });
    return group;
  }
}
