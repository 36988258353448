import { Component, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';

@Component({
  selector: 'wp-widget-speedometer-properties',
  templateUrl: './widget-speedometer-properties.component.html',
  standalone: false,
})
export class WidgetSpeedometerPropertiesComponent {
  @Input() form: UntypedFormGroup;
  public get segments(): UntypedFormArray {
    return this.form.controls['segments'] as UntypedFormArray;
  }

  public availableColors = [
    '#A0522D',
    '#CD5C5C',
    '#FF4500',
    '#008B8B',
    '#B8860B',
    '#32CD32',
    '#FFD700',
    '#48D1CC',
    '#87CEEB',
    '#FF69B4',
    '#CD5C5C',
    '#87CEFA',
    '#6495ED',
    '#DC143C',
    '#FF8C00',
    '#C71585',
    '#000000',
  ];

  constructor(private fb: UntypedFormBuilder) {}

  public addSpeedometerSegment() {
    this.segments.push(
      this.fb.group({
        from: [null],
        to: [null],
        color: ['#32CD32'],
      }),
    );
  }

  public removeSpeedometerSegment(index: number) {
    this.segments.removeAt(index);
  }

  public colorPickerChange(segment: UntypedFormGroup, value: string) {
    segment.controls['color'].setValue(value);
  }
}
