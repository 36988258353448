import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-grade-creation',
  templateUrl: './grade-creation.component.html',
  standalone: false,
})
export class GradeCreationComponent {
  isSaving = false;
  gradeForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: '',
    level: [null, Validators.required],
  });
  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  ok = () => {
    this.gradeForm.markAllAsTouched();

    if (this.gradeForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    const grade = this.gradeForm.value;

    const data = {
      name: grade.name,
      code: grade.code,
      levelId: grade.level.id,
    };

    this.data
      .collection('Grades')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'settings.grades.creation.messages.created',
          );
          this.state.go('settings.grade', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
