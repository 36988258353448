import { ChangeDetectorRef, Component } from '@angular/core';
import { ProjectBillingService } from '../project-billing.service';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-project-billing-toolbar',
  templateUrl: './project-billing-toolbar.component.html',
  standalone: false,
})
export class ProjectBillingToolbarComponent extends AbstractToolbar {
  constructor(
    public projectBillingService: ProjectBillingService,
    gridService: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  public addEntry() {
    this.gridService.execute('addEntry');
  }

  public recognize() {
    this.gridService.execute('recognize');
  }
}
