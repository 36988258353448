import { Component, Inject, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

import { ProjectTeamService } from 'src/app/projects/card/project-team/project-team.service';
import { GridComponentColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-project-tariffs-cell',
  template: `<wp-select-box
    [formControl]="formGroup.controls[column.name]"
    [query]="query"
    collection="ProjectTariffs"
    [allowNull]="column.allowNull"
    [readonly]="readonly"
  />`,
  standalone: false,
})
export class ProjectTariffCellComponent implements GridComponentCell {
  @Input() column: GridComponentColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public query: Record<string, any>;
  public readonly: boolean;

  constructor(
    @Inject('entityId') public projectId: string,
    private projectTeamService: ProjectTeamService,
  ) {}

  public ngOnInit(): void {
    this.readonly =
      this.formGroup.getRawValue().resource?.resourceType === 'Department';
    this.query = this.projectTeamService.getProjectTariffQuery(
      this.projectId,
      this.formGroup.getRawValue().id,
    );
  }
}
