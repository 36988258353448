import { ChangeDetectorRef, Input } from '@angular/core';
import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormControl } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

/** Checkbox wrapper is necessary for initialize re-render of the cell. */
@Component({
  selector: 'tmt-boolean-cell',
  template: `<tmt-checkbox
    [control]="control"
    [readonly]="readonly"
    [reversed]="reversed"
  />`,
  standalone: false,
})
export class BooleanReadonlyCellComponent {
  @Input() control: UntypedFormControl;
  @Input() readonly = false;
  @Input() reversed = false;

  constructor(
    gridService: GridService,
    private cdr: ChangeDetectorRef,
  ) {
    gridService.detectChanges$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.cdr.detectChanges();
    });
  }
}
