import { Injectable } from '@angular/core';
import { forkJoin, map, Observable, Subject } from 'rxjs';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { TeamSettings } from './team-settings.model';
import { StateService } from '@uirouter/core';
import { NavigationService } from 'src/app/core/navigation.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DataService } from 'src/app/core/data.service';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { UntypedFormBuilder } from '@angular/forms';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';

@Injectable()
export class ProjectTeamService {
  public settings$ = new Subject<TeamSettings>();

  public settings: TeamSettings;

  public formArray = this.fb.array([]);

  constructor(
    private projectVersionCardService: ProjectVersionCardService,
    private projectVersionDataService: ProjectVersionDataService,
    private localConfigService: LocalConfigService,
    private navigationService: NavigationService,
    private state: StateService,
    private dataService: DataService,
    private fb: UntypedFormBuilder,
  ) {
    this.settings = this.localConfigService.getConfig(TeamSettings);
  }

  public setFilter(onlyActive: boolean) {
    this.settings.onlyActive = onlyActive;
    this.settings$.next(this.settings);
    this.localConfigService.setConfig(TeamSettings, this.settings);
  }

  /** Open resource request. */
  public openResourceRequest(requestId: string) {
    this.state.go('resourceRequest', {
      navigation: this.navigationService.selectedNavigationItem?.name,
      routeMode: RouteMode.continue,
      entityId: requestId,
    });
  }

  /**
   * Gets query with filters for `ProjectTariff` select-box.
   *
   * @param projectId project id.
   * @param teamMemberId team member id.
   * @returns ODataQuery.
   */
  public getProjectTariffQuery(
    projectId: string,
    teamMemberId: string,
  ): Record<string, any> {
    const query = {
      select: ['id', 'name'],
      filter: [],
    };

    if (teamMemberId) {
      query.filter.push({
        assignments: {
          any: {
            or: [
              {
                projectTeamMemberId: {
                  type: 'guid',
                  value: teamMemberId,
                },
              },
              {
                isAllTeamRole: true,
              },
            ],
          },
        },
      });
    }

    ProjectVersionUtil.addProjectEntityIdFilter(
      query,
      this.projectVersionCardService.projectVersion,
      projectId,
    );

    return query;
  }

  /**
   * Retrieves an observable of team member roles for a given resource.
   *
   * @param resourceId - The ID of the resource for which to retrieve roles.
   * @param projectId - The ID of the project to which the resource belongs.
   * @param selectedRoleId - An optional ID of a role to include in the result, even if it's an existing role.
   * @returns An observable that emits an array of NamedEntity objects representing the roles.
   */
  public getTeamMemberRolesObservable(
    resourceId: string,
    projectId: string,
    selectedRoleId?: string,
  ): Observable<NamedEntity[]> {
    const existedRoleIds = this.projectVersionDataService
      .projectCollectionEntity(
        this.projectVersionCardService.projectVersion,
        projectId,
      )
      .collection('ProjectTeamMembers')
      .query<ProjectTeamMember[]>({
        select: ['roleId'],
        filter: [{ resourceId: { type: 'guid', value: resourceId } }],
      });

    const userRoles = this.dataService
      .collection('Users')
      .entity(resourceId)
      .get<User>({
        select: 'id',
        expand: {
          additionalUserRoles: {
            expand: {
              role: { select: ['id', 'name'], filter: [{ isActive: true }] },
            },
          },
          role: { select: ['id', 'name'] },
        },
      });

    return forkJoin({ existedRoleIds, userRoles }).pipe(
      map((data) => {
        const result = data.userRoles.additionalUserRoles.map((userRole) => ({
          id: userRole.role.id,
          name: userRole.role.name,
        }));

        // Add the user's primary role if it's not already included in the additional roles
        if (
          data.userRoles.role &&
          !result.some((role) => role.id === data.userRoles.role.id)
        ) {
          result.push(data.userRoles.role);
        }

        // Filter out roles that are already assigned to project team members, unless the selectedRoleId is specified
        return result.filter(
          (role) =>
            !data.existedRoleIds
              .map((tm) => tm.roleId)
              .filter((x) => !!x)
              ?.find((id) => id === role.id) ||
            (selectedRoleId && role.id === selectedRoleId),
        );
      }),
    );
  }

  /**
   * Return list of restricted to choosing role IDs for target team member.
   * Existing roles are forbidden for the target team member resource.
   *
   * @param teamMember The team member to find role IDs for.
   * @param isCurrentRestricted Whether to include the current role ID in the result.
   * @returns An array of role IDs associated with the specified team member.
   */
  public getRestrictedRoleIds(
    teamMember: ProjectTeamMember,
    isCurrentRestricted = false,
  ): string[] {
    if (teamMember.resource?.resourceType !== ResourceType.user) return [];

    const res = this.formArray.value
      .filter((tm) => teamMember.resource.id === tm.resource.id)
      .map((tm) => tm.role?.id)
      .filter((x) => !!x);

    if (isCurrentRestricted) return res;

    return res.filter((id) => id !== teamMember.role?.id);
  }
}
