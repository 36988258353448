import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ContactCardService } from 'src/app/contacts/card/contact-card.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { AppService } from 'src/app/core/app.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SystemDirectory } from 'src/app/shared/models/enums/system-directory.enum';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

@Component({
  selector: 'tmt-contact-profile',
  templateUrl: './contact-profile.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  styleUrls: ['./contact-profile.component.scss'],
  standalone: false,
})
export class ContactProfileComponent implements OnInit {
  @ViewChild('phone') phone;
  @ViewChild('mobilePhone') mobilePhone;

  public readonly systemDirectory = SystemDirectory;
  public readonly propagationMode = PropagationMode;

  private destroyRef = inject(DestroyRef);

  constructor(
    public appService: AppService,
    public service: ContactCardService,
    private messageService: MessageService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!this.service.contactForm.dirty) {
          this.service.load();
        } else {
          this.messageService.confirmLocal('shared.leavePageMessage').then(
            () => this.service.load(),
            () => null,
          );
        }
      });
  }
}
