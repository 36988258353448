import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { Program } from 'src/app/shared/models/entities/projects/program.model';

@Component({
  selector: 'wp-including-project-modal',
  templateUrl: './including-project-modal.component.html',
  standalone: false,
})
export class IncludingProjectModalComponent implements OnInit {
  @Input() program: Program;

  public query: any = {};

  public form = this.fb.group({
    project: [null, Validators.required],
  });
  isSaving: boolean;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
  ) {}

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    const data = { projectId: this.form.value.project.id };

    this.data
      .collection('Programs')
      .entity(this.program.id)
      .action('WP.IncludeProject')
      .execute(data)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'projects.programs.card.projects.messages.included',
          );
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit(): void {
    this.query.filter = [
      { programId: { type: 'guid', value: null }, isActive: true },
    ];
  }
}
