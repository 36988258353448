import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { UIRouterModule } from '@uirouter/angular';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectExpensesSharedModule } from '../shared/project-expenses-shared.module';
import { ProjectExpensesCalendarComponent } from './project-expenses-calendar.component';
import { ProjectExpensesCalendarGroupFilterComponent } from './shared/project-expenses-calendar-group-filter/project-expenses-calendar-group-filter.component';
import { ProjectExpensesCalendarLeftGroupComponent } from './shared/project-expenses-calendar-left-group/project-expenses-calendar-left-group.component';
import { ProjectExpensesCalendarRightGroupComponent } from './shared/project-expenses-calendar-right-group/project-expenses-calendar-right-group.component';
import { ProjectExpensesCalendarSlotInfoComponent } from './shared/project-expenses-calendar-slot-info/project-expenses-calendar-slot-info.component';
import { ProjectExpensesCalendarToolbarComponent } from './shared/project-expenses-calendar-toolbar/project-expenses-calendar-toolbar.component';

@NgModule({
  declarations: [
    ProjectExpensesCalendarComponent,
    ProjectExpensesCalendarToolbarComponent,
    ProjectExpensesCalendarLeftGroupComponent,
    ProjectExpensesCalendarRightGroupComponent,
    ProjectExpensesCalendarSlotInfoComponent,
    ProjectExpensesCalendarGroupFilterComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ScheduleNavigationModule,
    ProjectExpensesSharedModule,
    UIRouterModule.forChild({
      states: [
        {
          name: 'project.expenses.calendar',
          url: '/calendar',
          component: ProjectExpensesCalendarComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
  exports: [ProjectExpensesCalendarComponent],
})
export class ProjectExpensesCalendarModule {}
