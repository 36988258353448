export const ITEMS_IDS = [
  'strong',
  'em',
  'strike',
  'ins',
  'samp',
  'link',
  'code',
  'heading',
  'heading0',
  'heading1',
  'heading2',
  'heading3',
  'heading4',
  'heading5',
  'heading6',
  'lists',
  'bullet_list',
  'ordered_list',
  'sink_item',
  'lift_item',
  'blockquote',
  'code',
  'inlineCode',
  'additional',
  'hr',
] as const;

export type NodeItem = (typeof ITEMS_IDS)[number];
