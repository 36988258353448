import { FromTo } from 'prosemirror-autocomplete';
import { MarkdownSerializerState } from 'prosemirror-markdown';
import { Node } from 'prosemirror-model';
import { EditorView } from 'prosemirror-view';

export function insertMention(
  view: EditorView,
  label: string,
  id: string,
  range?: FromTo,
): void {
  const { from, to } = range ?? view.state.selection;

  const { schema, tr } = view.state;
  const mention = schema.nodes.mention.create({ id, label });

  view.dispatch(tr.deleteRange(from, to).replaceSelectionWith(mention));
}

export const MentionSerializer = (
  state: MarkdownSerializerState,
  node: Node,
  parent: Node,
) => {
  let spaceBefore = ' ';
  let spaceAfter = ' ';

  parent.descendants((n, pos) => {
    if (n.eq(node)) {
      const childBefore = parent.childBefore(pos);
      const childAfter = parent.childAfter(pos + 1);

      if (childBefore?.node?.text && childBefore.node.text.endsWith(' ')) {
        spaceBefore = '';
      }

      if (childAfter?.node?.text && childAfter.node.text.startsWith(' ')) {
        spaceAfter = '';
      }

      return false;
    }

    return true;
  });

  state.write(`${spaceBefore}${node.attrs.label}${spaceAfter}`);
};
