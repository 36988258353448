import {
  Directive,
  ElementRef,
  OnInit,
  Renderer2,
  EventEmitter,
  Output,
  Input,
  OnDestroy,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';

import { Subject } from 'rxjs';
import { auditTime, takeUntil } from 'rxjs/operators';

@Directive({
  selector: '[tmtAreaResizing]',
  standalone: false,
})
export class AreaResizingDirective implements OnInit, OnDestroy {
  @Output() public areaResized = new EventEmitter<number>();
  @Input() public maxHeight = 600;
  @Input() public minHeight = 150;

  private isResizing: boolean;
  private startPageY: number;
  private startHeight: number;
  private areaEl: HTMLElement;
  private areaMouseDownListener: () => void;
  private documentMouseUpListener: () => void;
  private documentMouseDownListener: () => void;
  private readonly resized$ = new Subject<MouseEvent>();
  private readonly destroyed$ = new Subject<void>();

  constructor(
    private area: ElementRef<HTMLElement>,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {}

  public ngOnInit(): void {
    this.init();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
    this.areaMouseDownListener();
    this.documentMouseDownListener();
    this.documentMouseUpListener();
  }

  private init(): void {
    this.areaEl = this.area.nativeElement as HTMLElement;

    this.resized$
      .pipe(auditTime(16), takeUntil(this.destroyed$))
      .subscribe((event: MouseEvent) => {
        const diffY = event.pageY - this.startPageY;
        const newHeight = this.startHeight + diffY;

        if (newHeight <= this.maxHeight && newHeight >= this.minHeight) {
          this.areaResized.emit(newHeight);
        }
      });

    this.areaMouseDownListener = this.renderer.listen(
      this.document.querySelector('.area-resizing'),
      'mousedown',
      (event: MouseEvent) => {
        this.start(event);
      },
    );

    this.documentMouseUpListener = this.renderer.listen(
      this.document,
      'mouseup',
      () => this.stop(),
    );

    this.documentMouseDownListener = this.renderer.listen(
      this.document,
      'mousemove',
      (event: any) => {
        if (!this.isResizing) {
          return;
        }

        this.resized$.next(event);
      },
    );
  }

  private start(event: MouseEvent): void {
    this.isResizing = true;
    this.startPageY = event.pageY;
    this.startHeight = this.areaEl.getBoundingClientRect().height;
  }

  private stop(): void {
    this.isResizing = false;
  }
}
