import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class ClientContactsSettings implements BaseSettings {
  public readonly name = 'ClientContactsSettings';
  version: number;
  onlyActive: boolean;

  getDefault(): ClientContactsSettings {
    return {
      version: 1,
      onlyActive: true,
    } as ClientContactsSettings;
  }
}
