import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { ProjectRbcCalendarService } from 'src/app/projects/card/project-rbc/project-rbc-calendar/core/project-rbc-calendar.service';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';

@Component({
  selector: 'wp-project-rbc-calendar-toolbar',
  templateUrl: './project-rbc-calendar-toolbar.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectRbcCalendarToolbarComponent {
  @Input() disabled = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly PlanningScale = PlanningScale;

  constructor(public projectRbcCalendarService: ProjectRbcCalendarService) {}
}
