import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { WorkflowFunctionType } from 'src/app/settings-app/workflows/model/workflow.enum';
import { WorkflowCardService } from '../../workflow-card.service';

@Component({
  selector: 'wp-workflow-next-function',
  templateUrl: './workflow-next-function.component.html',
  standalone: false,
})
export class WorkflowNextFunctionComponent implements OnInit {
  @Input() readonly: boolean;
  @Input() functionId: string;
  @Input() nextFunctionId: UntypedFormControl;
  @Input() allowNull = false;
  public workflowFunctionsOptions = [];

  constructor(public service: WorkflowCardService) {}

  ngOnInit() {
    this.service.workflow?.functions.forEach((func) => {
      if (func.type !== WorkflowFunctionType.start) {
        this.workflowFunctionsOptions = [
          ...this.workflowFunctionsOptions,
          { id: func.id, name: func.name },
        ];
      }
    });
  }
}
