import {
  Directive,
  Input,
  ElementRef,
  AfterViewInit,
  booleanAttribute,
} from '@angular/core';

@Directive({
  selector: '[tmtAutofocus]',
  standalone: false,
})
export class AutofocusDirective implements AfterViewInit {
  @Input({ transform: booleanAttribute, alias: 'tmtAutofocus' })
  public autoFocus: boolean;

  constructor(private el: ElementRef<HTMLElement>) {}

  public ngAfterViewInit(): void {
    if (this.autoFocus) {
      setTimeout(() => {
        this.el.nativeElement.focus();
      }, 25);
    }
  }
}
