import { isNodeSelection } from 'prosemirror-utils';
import { Selection } from 'prosemirror-state';

/**
 * Checks if selection is horizontal_rule type.
 *
 * @param selection current selection.
 * @returns boolean value.
 */
export const isHrSelection = (selection: Selection): boolean =>
  isNodeSelection(selection) && selection.node.type.name === 'horizontal_rule';
