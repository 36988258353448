import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';
import { ExpensesGroupType, ExpensesSectionType } from './expenses-view.model';

export class ExpensesCalendarSettings implements BaseSettings {
  public readonly name = 'ExpensesCalendarSettings';
  public version: number;

  /**
   * Calendar planning scale
   */
  public planningScale: PlanningScale;

  /**
   * Calendar displayed groups.
   * @example
   * ['estimate', 'plan']
   */
  public displayedGroups: ExpensesGroupType[];

  /**
   * Calendar displayed others in each section and group.
   * Calendar displayed others.
   * @example
   * ['expenses': ['estimate', 'plan']]
   */
  public displayedOthers: Record<ExpensesSectionType, ExpensesGroupType[]>;

  public getDefault(): ExpensesCalendarSettings {
    const defaultDisplayedOthers = {} as Record<
      ExpensesSectionType,
      ExpensesGroupType[]
    >;
    Object.values(ExpensesSectionType).forEach((sectionType) => {
      defaultDisplayedOthers[sectionType] = Object.values(ExpensesGroupType);
    });

    return {
      version: 6,
      planningScale: PlanningScale.Week,
      displayedGroups: Object.values(ExpensesGroupType),
      displayedOthers: defaultDisplayedOthers,
    } as ExpensesCalendarSettings;
  }
}
