import {
  AfterViewInit,
  DestroyRef,
  Directive,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { NgbAccordionItem } from '@ng-bootstrap/ng-bootstrap';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { AccordionStateSettings } from 'src/app/shared/directives/accordion-state/accordion-state.settings';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/**  Directive to save accordion state (open/closed) in local configuration. */
@Directive({
  selector: '[tmtAccordionState]',
  standalone: true,
})
export class AccordionStateDirective implements OnInit {
  @Input('tmtAccordionState') accordionId: string;

  public settings = this.localConfigService.getConfig(AccordionStateSettings);

  private destroyRef = inject(DestroyRef);

  constructor(
    private accordion: NgbAccordionItem,
    private localConfigService: LocalConfigService,
  ) {}

  public ngOnInit(): void {
    this.initializeAccordionState();
    this.listenToAccordionEvents();
  }

  /**  Initializes the accordion state based on saved settings. */
  private initializeAccordionState(): void {
    const shown =
      this.settings.accordionItems.find(
        (item) => item.name === this.accordionId,
      )?.expanded ?? true;

    this.accordion.collapsed = !shown;
  }

  /** Listens to accordion shown/hidden events and updates the state. */
  private listenToAccordionEvents(): void {
    this.accordion.shown
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.saveAccordionState(true));

    this.accordion.hidden
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.saveAccordionState(false));
  }

  /**
   * Saves the accordion state to local configuration.
   *
   * @param isShown Indicates whether the accordion is shown or hidden.
   */
  private saveAccordionState(isShown: boolean): void {
    const item = this.settings.accordionItems.find(
      (item) => item.name === this.accordionId,
    );

    if (item) {
      item.expanded = isShown;
    } else {
      this.settings.accordionItems.push({
        name: this.accordionId,
        expanded: isShown,
      });
    }

    this.localConfigService.setConfig(AccordionStateSettings, this.settings);
  }
}
