import { Component } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';

@Component({
  selector: 'wp-domain-adding',
  templateUrl: './domain-adding.component.html',
  standalone: false,
})
export class DomainAddingComponent {
  isSaving = false;
  form: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    this.data
      .collection('TenantDomains')
      .insert({
        domainName: this.form.value.name,
      })
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.settings.domains.messages.domainAdded',
          );
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: any) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
