import { Injectable, Optional } from '@angular/core';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { ListService } from 'src/app/shared/services/list.service';
import { SelectionCoordinates } from 'src/app/shared-features/grid/models/grid-cells-orchestrator.interface';

/** Service for collective management of cells. */
@Injectable()
export class GridOrchestratorService {
  /** Displayed data. */
  public formArray: UntypedFormArray;

  /** Based columns list. */
  public baseColumns: GridColumn[];

  /** Grid options. */
  public options: GridOptions;

  public leftTable: HTMLElement;

  public initKeyboardEventsSubject = new Subject<void>();

  /** Groups selection. */
  private _selectedGroups: Set<UntypedFormGroup> = new Set();
  public get selectedGroups(): UntypedFormGroup[] {
    return Array.from(this._selectedGroups);
  }

  public get selectedGroup(): UntypedFormGroup | null {
    if (this.options.selectionType === SelectionType.range) {
      return (
        (this.activeControl?.parent as UntypedFormGroup) ??
        this.selectedGroups[this.selectedGroups.length - 1] ??
        null
      );
    }
    return this.selectedGroups[this.selectedGroups.length - 1] ?? null;
  }

  private activeControlSubject = new BehaviorSubject<AbstractControl | null>(
    null,
  );
  private nodalSelectedControlSubject =
    new BehaviorSubject<AbstractControl | null>(null);
  private editingControlSubject = new BehaviorSubject<AbstractControl | null>(
    null,
  );
  private selectedGroupSubject: BehaviorSubject<UntypedFormGroup | null> =
    new BehaviorSubject(null);
  private selectedGroupsSubject: BehaviorSubject<UntypedFormGroup[]> =
    new BehaviorSubject([]);

  /** Grid view rows. */
  private _selectionCoordinates: SelectionCoordinates | null;
  public get selectionCoordinates(): SelectionCoordinates | null {
    return this._selectionCoordinates;
  }

  /** Initial value of initializing editing control. */
  private _initialControlValue: unknown | undefined = undefined;
  public get initialControlValue(): unknown | undefined {
    return this._initialControlValue;
  }

  public get formGroups(): UntypedFormGroup[] {
    return this.formArray.controls as UntypedFormGroup[];
  }
  public get columns(): GridColumn[] {
    return this.listService
      ? this.listService.getGridView().columns
      : this.baseColumns;
  }
  public get activeControl(): AbstractControl | null {
    return this.activeControlSubject.getValue();
  }
  public get editingControl(): AbstractControl | null {
    return this.editingControlSubject.getValue();
  }
  public get nodalSelectedControl(): AbstractControl | null {
    return this.nodalSelectedControlSubject.getValue();
  }
  public get selectedGroup$(): Observable<UntypedFormGroup | null> {
    return this.selectedGroupSubject.asObservable();
  }
  public get selectedGroups$(): Observable<UntypedFormGroup[]> {
    return this.selectedGroupsSubject.asObservable();
  }
  public get activeControlColumnIndex(): number | null {
    return this.activeControl
      ? this.columns.findIndex(
          (col) =>
            this.activeControl.parent.controls[col.name] === this.activeControl,
        )
      : null;
  }
  public get activeControlRowIndex(): number | null {
    return this.activeControl
      ? this.formArray.controls.findIndex(
          (c) => c === this.activeControl.parent,
        )
      : null;
  }
  public get nodalSelectedControlColumnIndex(): number | null {
    return this.nodalSelectedControl
      ? this.columns.findIndex(
          (col) =>
            this.nodalSelectedControl.parent.controls[col.name] ===
            this.nodalSelectedControl,
        )
      : null;
  }
  public get nodalSelectedControlRowIndex(): number | null {
    return this.nodalSelectedControl
      ? this.formArray.controls.findIndex(
          (c) => c === this.nodalSelectedControl.parent,
        )
      : null;
  }

  constructor(
    private gridService: GridService,
    @Optional() private listService: ListService,
  ) {}

  /**
   * Selects one formGroup.
   *
   * @param group group to selection.
   */
  public selectGroup(group: UntypedFormGroup | null): void {
    if (!this.options.selectionType) {
      return;
    }

    switch (this.options.selectionType) {
      case SelectionType.range: {
        if (!group) {
          this.setActiveControl(null);
          this.gridService.detectChanges();
          return;
        }
        const index = this.activeControlColumnIndex ?? 0;
        this.setActiveControl(group.controls[this.columns[index].name]);
        break;
      }
      case SelectionType.row:
      case SelectionType.rows: {
        this.clearSelectedGroups();
        if (group) {
          this.addGroupToSelected(group);
        }
        this.gridService.detectChanges();
        break;
      }
    }
  }

  /**
   * Adds formGroup to selection.
   *
   * @param group group to add in selection.
   * @param emitChanges changes selectedGroup subjects and detectChanges in the grid.
   */
  public addGroupToSelected(group: UntypedFormGroup, emitChanges = true): void {
    if (!this.options.selectionType) {
      return;
    }
    const addGroup = (group) => {
      if (this._selectedGroups.has(group)) {
        // First delete group for guaranteeing of selected group last position.
        this._selectedGroups.delete(group);
      }
      this._selectedGroups.add(group);
      if (!emitChanges) return;
      this.updateSelectedGroupsSubjects();
      this.gridService.detectChanges();
    };

    switch (this.options.selectionType) {
      case SelectionType.row:
      case SelectionType.rows: {
        if (
          this.selectedGroups.length &&
          this.options.selectionType === SelectionType.row
        ) {
          return;
        }
        addGroup(group);
        break;
      }
      case SelectionType.range:
        if (this.activeControl) {
          this.setActiveControl(null, false);
        }
        addGroup(group);
        break;
    }
  }

  /**
   * Removes formGroup from selection.
   *
   * @param group group to add in selection.
   */
  public removeGroupFromSelected(group: UntypedFormGroup): void {
    if (!this.options.selectionType) {
      return;
    }

    switch (this.options.selectionType) {
      case SelectionType.row:
      case SelectionType.rows: {
        if (!this._selectedGroups.has(group)) {
          return;
        }
        this._selectedGroups.delete(group);
        this.updateSelectedGroupsSubjects();
        break;
      }
      case SelectionType.range:
        if (this.activeControl) {
          this.setActiveControl(null, false);
        }
        this._selectedGroups.delete(group);
        this.updateSelectedGroupsSubjects();
        break;
    }

    this._selectedGroups.delete(group);
    this.updateSelectedGroupsSubjects();
  }

  /**
   * Adds od removes group from selection.
   *
   * @param group group to switch in selection.
   */
  public switchGroupSelection(group: UntypedFormGroup): void {
    if (!this.options.selectionType) {
      return;
    }

    if (this._selectedGroups.has(group)) {
      this._selectedGroups.delete(group);
    } else {
      this._selectedGroups.add(group);
    }
    this.updateSelectedGroupsSubjects();

    if (this.checkIsGridInCellEditingMode()) {
      this.setEditingControl(null);
      this.setActiveControl(null, false);
      this.gridService.detectChanges();
    }
  }

  /** Clears group selection. */
  public clearSelectedGroups(): void {
    if (!this._selectedGroups.size) return;
    this._selectedGroups.clear();
    this.updateSelectedGroupsSubjects();
  }

  /**
   * Sets active control(cell).
   *
   * @param control control to set as active.
   * @param updateSelectedGroups is update selected groups based on active and nodal selected cells.
   */
  public setActiveControl(
    control: AbstractControl | null,
    updateSelectedGroups = true,
  ): void {
    if (!this.checkIsGridInCellEditingMode()) {
      return;
    }

    this.activeControlSubject.next(control);
    this.initKeyboardEventsSubject.next();
    this.setNodalSelectedControl(control, updateSelectedGroups);

    if (this.editingControl) {
      this.setEditingControl(null);
    }
  }

  /**
   * Sets nodal selected control(cell).
   *
   * @param control control to set as nodal selected.
   * @param updateSelectedGroups is update selected groups based on active and nodal selected cells.
   */
  public setNodalSelectedControl(
    nodalControl: AbstractControl | null,
    updateSelectedGroups = true,
  ): void {
    if (!this.checkIsGridInCellEditingMode()) {
      return;
    }

    this.nodalSelectedControlSubject.next(nodalControl);
    this.updateSelectionCoordinates();

    if (updateSelectedGroups) {
      this.updateSelectedGroups();
    }

    this.gridService.detectChanges();
  }

  /** Set editing control.
   *
   * @param control editing control.
   * @param initialValue initial value for writing into control after it rendering.
   */
  public setEditingControl(
    control: AbstractControl | null,
    initialValue?: unknown,
  ): void {
    if (!this.checkIsGridInCellEditingMode() || !this.activeControl) {
      return;
    }
    if (control) {
      this.setNodalSelectedControl(this.activeControl);
    }
    if (this.gridService.readonly) {
      return;
    }
    this._initialControlValue = initialValue;
    this.editingControlSubject.next(control);
  }

  /** Returns grid cell classes.
   *
   * @param formGroup cell formGroup.
   * @param column cell Column.
   * @param rowIndex cell row index.
   * @param colIndex cell column index.
   * @returns list of the classes.
   */
  public getGridCellClasses(
    formGroup: UntypedFormGroup,
    column: GridColumn,
    rowIndex: number,
    colIndex: number,
  ): string[] {
    const result = [];

    if (this.options.selectionType === SelectionType.range) {
      result.push('disable-selecting');
    }

    if (this._selectedGroups.has(formGroup)) {
      result.push('selected-cell');
    }
    if (formGroup.controls[column.name].invalid) {
      result.push('invalid');
    }

    if (this.checkIsGridInRowEditingMode()) return result;

    if (this.activeControl === formGroup.controls[column.name]) {
      result.push('active-cell');
    }
    if (
      rowIndex === this.selectionCoordinates?.rowStart &&
      colIndex >= this.selectionCoordinates?.colStart &&
      colIndex <= this.selectionCoordinates?.colEnd
    ) {
      result.push('selected-border_top');
    }
    if (
      rowIndex === this.selectionCoordinates?.rowEnd &&
      colIndex >= this.selectionCoordinates?.colStart &&
      colIndex <= this.selectionCoordinates?.colEnd
    ) {
      result.push('selected-border_bottom');
    }
    if (
      colIndex === this.selectionCoordinates?.colStart &&
      rowIndex >= this.selectionCoordinates?.rowStart &&
      rowIndex <= this.selectionCoordinates?.rowEnd
    ) {
      result.push('selected-border_left');
    }
    if (
      colIndex === this.selectionCoordinates?.colEnd &&
      rowIndex >= this.selectionCoordinates?.rowStart &&
      rowIndex <= this.selectionCoordinates?.rowEnd
    ) {
      result.push('selected-border_right');
    }

    return result;
  }

  /** Updates selection coordinates by current selected and active cells. */
  public updateSelectionCoordinates(): void {
    if (this.nodalSelectedControl && this.activeControl) {
      const nodalSelectedControlColumnIndex = this.columns.findIndex(
        (col) =>
          this.nodalSelectedControl.parent.controls[col.name] ===
          this.nodalSelectedControl,
      );
      const activeControlColumnIndex = this.activeControl
        ? this.columns.findIndex(
            (col) =>
              this.activeControl.parent.controls[col.name] ===
              this.activeControl,
          )
        : null;
      const nodalSelectedControlRowIndex = this.formArray.controls.findIndex(
        (c) => c === this.nodalSelectedControl.parent,
      );
      const activeControlRowIndex = this.activeControlRowIndex;
      this._selectionCoordinates = {
        rowStart: Math.min(nodalSelectedControlRowIndex, activeControlRowIndex),
        rowEnd: Math.max(nodalSelectedControlRowIndex, activeControlRowIndex),
        colStart: Math.min(
          nodalSelectedControlColumnIndex,
          activeControlColumnIndex,
        ),
        colEnd: Math.max(
          nodalSelectedControlColumnIndex,
          activeControlColumnIndex,
        ),
      };
    } else {
      this._selectionCoordinates = null;
    }
  }

  /** Inits methods for public grid service. */
  public initGridManagementService(): void {
    this.gridService.gridOptions = this.options;
    this.gridService.selectGroup = (group: UntypedFormGroup) =>
      this.selectGroup(group);
    this.gridService.clearSelectedGroups = () => this.clearSelectedGroups();
    this.gridService.addGroupToSelected = (group: UntypedFormGroup) =>
      this.addGroupToSelected(group);
    this.gridService.removeGroupFromSelected = (group: UntypedFormGroup) =>
      this.removeGroupFromSelected(group);
    this.gridService.setActiveControl = (control: UntypedFormControl | null) =>
      this.setActiveControl(control);
    this.gridService.setNodalSelectedControl = (
      control: UntypedFormControl | null,
    ) => this.setNodalSelectedControl(control);
    this.gridService.formArray = this.formArray;
  }

  /** Updates selected group by current selection coordinates. */
  private updateSelectedGroups(): void {
    this._selectedGroups.clear();
    for (
      let i = this.selectionCoordinates?.rowStart;
      i <= this.selectionCoordinates?.rowEnd;
      i++
    ) {
      this._selectedGroups.add(this.formArray.controls[i] as UntypedFormGroup);
    }
    this.updateSelectedGroupsSubjects();
  }

  private updateSelectedGroupsSubjects(): void {
    this.selectedGroupSubject.next(this.selectedGroup);
    this.gridService.selectedGroup$.next(this.selectedGroup);
    this.selectedGroupsSubject.next(this.selectedGroups);
    this.gridService.selectedGroups$.next(this.selectedGroups);
  }

  /** Checks is grid in cell editing mode. */
  private checkIsGridInCellEditingMode(): boolean {
    return this.options.selectionType === SelectionType.range;
  }

  /** Checks is grid in row editing mode. */
  private checkIsGridInRowEditingMode(): boolean {
    return (
      this.options.selectionType === SelectionType.row ||
      this.options.selectionType === SelectionType.rows
    );
  }
}
