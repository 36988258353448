import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
  effect,
  inject,
  input,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { filter, switchMap } from 'rxjs';

import {
  DragAndDropData,
  DragAndDropOptions,
} from 'src/app/shared/directives/drag-and-drop/drag-and-drop.model';
import { DragAndDropDirective } from 'src/app/shared/directives/drag-and-drop/drag-and-drop.directive';
import { LocalDragDropService } from 'src/app/shared/services/drag-drop';

import {
  BoardCardView,
  BoardColumn,
  BoardEvent,
} from 'src/app/boards/models/board.interface';
import { BoardService } from 'src/app/boards/services/board.service';

@Component({
  selector: 'tmt-board-track',
  templateUrl: './board-track.component.html',
  styleUrl: './board-track.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BoardTrackComponent implements OnInit, OnDestroy {
  @ViewChild(DragAndDropDirective) private dndDirective: DragAndDropDirective;

  public cards = input.required<BoardCardView[]>();
  public column = input.required<BoardColumn>();

  public isShowAlert = false;
  public ddOptions: DragAndDropOptions;
  public dragDisabled: boolean;
  public dropDisabled: boolean;
  public pending = signal<boolean>(false);

  private endOfTrackObserver: IntersectionObserver;
  private destroyRef = inject(DestroyRef);

  constructor(
    public boardService: BoardService,
    private dragDropService: LocalDragDropService,
    private cdr: ChangeDetectorRef,
    private el: ElementRef<HTMLElement>,
    private renderer: Renderer2,
  ) {
    effect(() => {
      this.cards();
      this.dndDirective.initStyles();
    });
  }

  public ngOnInit(): void {
    this.ddOptions = {
      group: {
        name: this.column().id,
        put: this.boardService.columnsDependencies[this.column().id],
        key: 'cards',
      },
      draggableClass: 'draggable',
      listDirection: 'vertical',
      listGap: 8,
    };

    this.dragDropService.onItemUpdate$
      .pipe(
        filter((v) => !!v && v.fromGroupName === this.column().id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((data: DragAndDropData<BoardCardView>) => {
        this.boardService.updateCard(data.item.id, data);
      });

    this.boardService.event$
      .pipe(
        filter(
          (event) =>
            event.target === 'track' &&
            (event.id === this.column().id || !event.id),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => this.handleEvent(event));

    this.initIntersectionObserver();
  }

  public ngOnDestroy(): void {
    this.endOfTrackObserver.disconnect();
  }

  private handleEvent(event: BoardEvent): void {
    switch (event.action) {
      case 'rollBackFrom':
      case 'moveThroughCardMenu':
      case 'rollBackTo':
        this.runAnimation(event);
        break;
      case 'disableDrag':
        this.dragDisabled = !!event.data;
        this.cdr.markForCheck();
        break;
      case 'updated':
        this.ddOptions = {
          ...this.ddOptions,
          group: {
            ...this.ddOptions.group,
            put: this.boardService.columnsDependencies[this.column().id],
          },
        };
        this.pending.set(false);
        this.cdr.markForCheck();
        break;
      case 'restrictTransition':
        if (this.isShowAlert && !event.data) {
          // NOTE: In case when user dragged card before the alert was shown
          this.dndDirective.restoreTransform(null, null, false);
        }

        this.isShowAlert = event.data;
        this.dropDisabled = event.data;
        this.dragDisabled = event.data;
        this.cdr.markForCheck();
        break;
    }
  }

  private runAnimation(
    event: BoardEvent<DragAndDropData<BoardCardView>>,
  ): void {
    if (event.action === 'rollBackFrom') {
      const element = this.el.nativeElement
        .querySelectorAll<HTMLElement>(`.${this.ddOptions.draggableClass}`)
        .item(event.data.newIndex);

      this.dragDropService.data.clone.element =
        this.dndDirective.makeClone(element);

      this.dndDirective
        .destroyElement(element, event.data.newIndex)
        .pipe(
          switchMap(() => this.dndDirective.restoreTransform(0, null, true)),
        )
        .subscribe(() => {
          this.boardService.event$.next({
            target: 'track',
            id: null,
            action: 'disableDrag',
            data: false,
          });
          this.pending.set(false);
          this.cdr.markForCheck();
        });
    }

    if (event.action === 'rollBackTo') {
      this.dragDropService.setOnEnd();
    }

    if (event.action === 'moveThroughCardMenu') {
      const element = this.el.nativeElement
        .querySelectorAll(`.${this.ddOptions.draggableClass}`)
        .item(event.data.oldIndex) as HTMLElement;

      this.dragDropService.data = {
        item: event.data.item,
        oldIndex: event.data.oldIndex,
        newIndex: event.data.newIndex,
        fromGroupName: event.id,
        toGroupName: event.data.toGroupName ?? event.id,
        fromGroupKey: 'cards',
        clone: {
          element: this.dndDirective.makeClone(element),
          offsetX: 0,
          offsetY: 0,
          lastDragX: 0,
          lastDragY: 0,
          oldPosition: {
            x:
              element.getBoundingClientRect().x -
              this.el.nativeElement.getBoundingClientRect().left,
            y:
              element.getBoundingClientRect().y -
              this.el.nativeElement.getBoundingClientRect().top,
          },
        },
      };

      this.renderer.setStyle(
        this.dragDropService.data.clone.element,
        'z-index',
        '0',
      );

      this.dndDirective.makePlaceholder();
      this.dndDirective
        .destroyElement(element, event.data.oldIndex)
        .subscribe(() => {
          this.dndDirective.restoreTransform(
            Math.min(event.data.oldIndex, event.data.newIndex),
            null,
            event.data.newIndex > event.data.oldIndex,
          );
          this.dragDropService.setOnDrop();
          this.cdr.markForCheck();
        });
    }
  }

  private loadCards(): void {
    this.pending.set(
      this.boardService.loadCardsByColumn(this.column()) !== false,
    );
  }

  private initIntersectionObserver(): void {
    this.endOfTrackObserver = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            this.loadCards();
          }
        });
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 1.0,
      },
    );

    this.endOfTrackObserver.observe(
      this.el.nativeElement.querySelector<HTMLElement>('.end-of-track'),
    );
  }
}
