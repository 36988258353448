import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  ViewRef,
} from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ExpenseRequestCardService } from '../../expenses-request-card.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-expense-task-cell',
  templateUrl: './expense-task-cell.component.html',
  standalone: false,
})
export class ExpenseTaskCellComponent
  implements OnInit, OnDestroy, GridComponentCell
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() public initialValue: unknown;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: GridService,
    private ref: ChangeDetectorRef,
    public cardService: ExpenseRequestCardService,
  ) {}

  ngOnInit(): void {
    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
