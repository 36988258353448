import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  QueryList,
  ViewChildren,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { DateTime } from 'luxon';

import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';

@Component({
  selector: 'tmt-form-helper-test',
  templateUrl: './form-helper-test.component.html',
  standalone: false,
})
export class FormHelperTestComponent implements AfterViewInit {
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;

  public form: UntypedFormGroup = this.fb.group({
    dateFrom: [null, Validators.required],
    dateTo: [null, Validators.required],
    client: [null, Validators.required],
    project: [null, Validators.required],
    level: [null, Validators.required],
    grade: [null, Validators.required],
    role: [null, Validators.required],
    competence: [null, Validators.required],
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngAfterViewInit(): void {
    FormHelper.controlDatePair(
      this.form,
      'dateFrom',
      'dateTo',
      takeUntilDestroyed(this.destroyRef),
    );
    FormHelper.cascadeDependency(
      this.form,
      this.cascadeControls,
      [
        [
          {
            controlName: 'client',
          },
          {
            dependedProperty: 'organizationId',
            controlName: 'project',
          },
        ],
        [
          {
            controlName: 'role',
            isDisableDepended: true,
          },
          {
            controlName: 'competence',
            dependedProperty: 'roleId',
          },
        ],
        [
          {
            controlName: 'level',
          },
          { controlName: 'grade', dependedProperty: 'levelId' },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );

    this.cdr.detectChanges();
  }

  public setValue(): void {
    const date = DateTime.now();

    this.form.setValue({
      dateFrom: date.toISODate(),
      dateTo: date.plus({ month: 1 }).toISODate(),
      client: null,
      project: null,
      level: null,
      grade: null,
      role: null,
      competence: null,
    });
  }
}
