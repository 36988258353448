import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { State } from 'src/app/shared/models/entities/state.model';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-state-changing-cell',
  styleUrls: ['./state-changing-cell.component.scss'],
  templateUrl: './state-changing-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StateChangingCellComponent implements GridComponentCell, OnInit {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public value: { fromState: State; toState: State };

  ngOnInit(): void {
    this.value = this.formGroup.controls.changing.getRawValue();
  }
}
