import { DecimalPipe, DOCUMENT } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  forwardRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { fromEvent, Subscription } from 'rxjs';
import { DimensionItem } from 'src/app/shared/models/enums/dimension-item.enum';
import { DurationMode } from 'src/app/shared/models/enums/duration-mode.enum';

@Component({
  selector: 'tmt-duration-box',
  templateUrl: './duration-box.component.html',
  styleUrls: ['./duration-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DurationBoxComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class DurationBoxComponent
  implements OnChanges, OnInit, AfterViewInit, OnDestroy, ControlValueAccessor
{
  /** Indicates can the value be deleted. */
  @Input() allowNull = true;

  /** Placeholder. */
  @Input() placeholder: string;

  /** Indicates is control in readonly mode. */
  @Input() readonly: boolean;

  /** Set focus to the input after rendering. */
  @Input() autofocus = false;

  /** Minimum count of days. */
  @Input() min = 1;

  /** Maximum count of days. */
  @Input() max = 2000;

  @Input() dimensionItem = DimensionItem.day;
  @Input() durationMode = DurationMode.work;

  @ViewChild('editor') editor: ElementRef;

  /** Value of control. */
  public value: number | null;
  /** Value for view in the input. */
  public viewValue: string;

  public disabled = false;

  /** Indicates input has focus. */
  private isFocused = false;

  /** Returns input element. */
  private get input() {
    if (this.disabled) {
      return null;
    }
    return this.editor.nativeElement as HTMLInputElement;
  }

  private keyboardSubscription: Subscription;

  private dayWords = [
    'д',
    'д.',
    'дн',
    'дн.',
    'дней',
    'дня',
    'день',
    'd',
    'd.',
    'day',
    'days',
  ];
  private weekWords = [
    'н',
    'н.',
    'нед',
    'нед.',
    'недель',
    'недели',
    'неделя',
    'w',
    'w.',
    'week',
    'weeks',
  ];
  private monthWords = [
    'м',
    'м.',
    'мес',
    'мес.',
    'месяцев',
    'месяца',
    'месяц',
    'месяцы',
    'm',
    'm.',
    'month',
    'months',
  ];

  constructor(
    @Inject(DOCUMENT) private document: Document,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private numberPipe: DecimalPipe,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['readonly']) {
      this.disabled = this.readonly;
    }
  }

  ngOnInit(): void {
    if (!this.placeholder) {
      this.placeholder = `${this.translateService.instant(
        'shared.duration.ph',
      )} "2 ${this.translateService.instant(
        'shared.duration.' + this.dimensionItem,
      )}"`;
    }
  }

  ngAfterViewInit(): void {
    if (this.autofocus && this.input) {
      this.input.focus();
    }
    if (this.readonly) {
      this.disabled = this.readonly;
    }
  }

  ngOnDestroy(): void {
    this.onBlur();
    this.keyboardSubscription?.unsubscribe();
  }

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  writeValue(value: number): void {
    if (this.viewValue) {
      const parsedViewValue = this.parseValue(this.viewValue);
      if (parsedViewValue) {
        if (this.value !== parsedViewValue) {
          this.value = value;
          return;
        }
      }
    }

    const dayCount = typeof value === 'string' ? this.parseValue(value) : value;
    if (this.value !== dayCount) {
      this.value = dayCount;
      this.cdr.detectChanges();
      this.setInputValue();
    }
  }
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (!this.readonly) {
      this.disabled = isDisabled;
    }
    this.cdr.detectChanges();

    if (this.viewValue) {
      const parsedViewValue = this.parseValue(this.viewValue);
      if (parsedViewValue) {
        if (this.value !== parsedViewValue) {
          return;
        }
      }
    }
    this.setInputValue();
  }

  /** Update input after blur. */
  public onBlur() {
    this.isFocused = false;
    this.keyboardSubscription?.unsubscribe();
    const parsedViewValue = this.parseValue(this.viewValue);
    if (
      parsedViewValue === 0 ||
      parsedViewValue ||
      (this.allowNull && this.viewValue === '')
    ) {
      if (this.value !== parsedViewValue) {
        this.value = parsedViewValue;
        this.propagateChange(this.value);
      }
    }
    this.setInputValue();
    this.propagateTouch();
  }

  /** Update input after focus. */
  public onFocus() {
    this.isFocused = true;
    if (this.value) {
      this.setInputValue();
    }
    this.input.select();
    this.initKeysSubscribes();
  }

  /** Clear control value. */
  public clear() {
    if (!this.allowNull) {
      return;
    }
    this.value = null;
    this.viewValue = '';
    this.propagateChange(null);
    this.setInputValue();
  }

  /** Set user value into the input if possible. */
  public setUserValue(value: unknown) {
    if (!this.disabled && this.input) {
      const event = new Event('input');
      if (typeof value === 'string') {
        this.input.value = '';
        this.input.value = value;
        this.input.dispatchEvent(event);
      } else if (value === null) {
        this.input.value = '';
        this.viewValue = '';
        this.input.dispatchEvent(event);
      }
    }
  }

  /** Listens of input changes. */
  public handleInput() {
    this.viewValue = this.input?.value;
  }

  /** Returns days count if possible. */
  private parseValue(value: string | null): number | null {
    const number = parseInt(value, 10);
    const text = value.replace(/ /g, '').substring(number.toString().length);
    if (Number.isNaN(number)) {
      return null;
    }

    let weekLength = 5;
    let monthLength = 20;

    if (this.durationMode === DurationMode.calendar) {
      weekLength = 7;
      monthLength = 28;
    }

    switch (this.dimensionItem) {
      case DimensionItem.day:
        if (text === '' || this.dayWords.includes(text)) {
          return this.checkLimits(number);
        }
        if (this.weekWords.includes(text)) {
          return this.checkLimits(number * weekLength);
        }
        if (this.monthWords.includes(text)) {
          return this.checkLimits(number * monthLength);
        }
        break;

      case DimensionItem.week:
        if (this.dayWords.includes(text)) {
          return this.checkLimits(Math.round(number / weekLength));
        }
        if (text === '' || this.weekWords.includes(text)) {
          return this.checkLimits(number);
        }
        if (this.monthWords.includes(text)) {
          return this.checkLimits(Math.round(number * 4));
        }
        break;

      case DimensionItem.month:
        if (this.dayWords.includes(text)) {
          return this.checkLimits(Math.round(number / monthLength));
        }
        if (this.weekWords.includes(text)) {
          return this.checkLimits(Math.round(number / 4));
        }
        if (text === '' || this.monthWords.includes(text)) {
          return this.checkLimits(number);
        }
        break;

      default:
        break;
    }
    return null;
  }

  /** Check current limits of duration
   *
   * @param value checking value
   * @returns corrected value
   */
  private checkLimits(value: number): number {
    if (value < this.min) {
      return this.min;
    }
    if (value > this.max) {
      return this.max;
    }
    return value;
  }

  /** Sets input value. */
  private setInputValue() {
    if (!this.editor || !this.input) {
      this.viewValue =
        this.value || this.value === 0
          ? this.numberPipe.transform(this.value, '1.0-0') +
            ' ' +
            this.translateService.instant(
              'shared.duration.' + this.dimensionItem,
            )
          : '';
      this.cdr.detectChanges();
      return;
    }

    if (this.isFocused) {
      this.viewValue =
        this.value || this.value === 0 ? this.value.toString() : '';
      this.input.value = this.viewValue;
    } else {
      this.setFullStringValue();
    }

    this.cdr.detectChanges();
  }

  /** Initializes keyboard listener. */
  private initKeysSubscribes() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
    this.keyboardSubscription = fromEvent(this.document, 'keydown').subscribe(
      (event: KeyboardEvent) => {
        if (!event.repeat) {
          if (this.viewValue) {
            switch (event.code) {
              case 'Enter':
              case 'NumpadEnter': {
                const parsedViewValue = this.parseValue(this.viewValue);
                if (parsedViewValue) {
                  if (this.value !== parsedViewValue) {
                    this.value = parsedViewValue;
                    this.propagateChange(this.value);
                  }
                  this.setFullStringValue();
                }
                break;
              }
              case 'Escape':
                this.setInputValue();
                this.onBlur();
                break;
            }
          }
        }
        return;
      },
    );
  }

  /** Set full string value. */
  private setFullStringValue() {
    const formattedNumber = this.numberPipe.transform(this.value, '1.0-0');
    this.viewValue =
      this.value || this.value === 0
        ? formattedNumber +
          ' ' +
          this.translateService.instant('shared.duration.' + this.dimensionItem)
        : '';
    this.input.value = this.viewValue;
  }
}
