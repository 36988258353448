import { Injectable } from '@angular/core';

import { Observable } from 'rxjs';
import { map, shareReplay } from 'rxjs/operators';

import { DataService } from 'src/app/core/data.service';

import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { FinancialAccountType } from 'src/app/shared/models/entities/finance/financial-account-type.enum';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';

/** Provides methods to work with Financial Accounts. */
@Injectable({ providedIn: 'root' })
export class FinancialAccountsService {
  constructor(private data: DataService) {}

  /** Cached {@link FinancialAccount} list. */
  private accounts: ReadonlyArray<FinancialAccount>;

  /** Shared observable of {@link FinancialAccount} list. */
  public accounts$ = this.getAvailableAccounts().pipe(shareReplay(1));
  /** Shared observable of {@link FinancialAccount} list with type = {@link FinancialAccountType.expenses}. */
  public projectExpensesAccounts$ = this.accounts$.pipe(
    map((accounts) =>
      accounts.filter(
        (account) =>
          (!account.isSystem ||
            account.id === FinancialAccount.corporateTaxId) &&
          account.type.id === FinancialAccountType.expenses.id,
      ),
    ),
    map((accounts) => accounts.sort(naturalSort('name'))),
  );
  /**
   * Shared observable of {@link FinancialAccount} list with
   * type = {@link FinancialAccountType.expenses}
   * and {@link FinancialAccount.includedInBalance} = <code>false</code>.
   * */
  public projectExpensesAccountsNotIncludedInBalance$ =
    this.projectExpensesAccounts$.pipe(
      map((accounts) =>
        accounts.filter((account) => !account.includedInBalance),
      ),
    );
  /** Shared observable of {@link FinancialAccount} list with type = {@link FinancialAccountType.expenses}. */
  public expensesRequestAccounts$ = this.accounts$.pipe(
    map((accounts) =>
      accounts.filter(
        (account) =>
          !account.isSystem &&
          account.type.id === FinancialAccountType.expenses.id,
      ),
    ),
  );

  /**
   * Gets {@link FinancialAccount} list from either cached {@link accounts} or server.
   *
   * @returns Observable of {@link FinancialAccount} list.
   * */
  private getAvailableAccounts(): Observable<ReadonlyArray<FinancialAccount>> {
    return new Observable((subscriber) => {
      if (this.accounts) {
        subscriber.next(this.accounts);
      }

      this.data
        .collection('FinancialAccounts')
        .query<FinancialAccount[]>({
          select: ['id', 'name', 'isSystem', 'includedInBalance'],
          filter: { isActive: true },
          expand: {
            type: {
              select: ['id', 'code', 'name'],
            },
          },
        })
        .subscribe((accounts) => {
          this.accounts = accounts;
          subscriber.next(this.accounts);
        });
    });
  }
}
