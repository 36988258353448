import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subject } from 'rxjs';
import { ProjectVersionsMergeModalService } from 'src/app/projects/project-versions/project-versions-merge-modal/core/project-versions-merge-modal.service';
import { ProjVersionMergeMode } from 'src/app/projects/project-versions/project-versions-merge-modal/models/proj-version-merge-mode.enum';
import { AppConfigService } from 'src/app/core/app-config.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { HttpClient } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { MessageService } from 'src/app/core/message.service';
import { takeUntil } from 'rxjs/operators';
import { StateService } from '@uirouter/core';
import { ProjectVersionMergeOption } from './models/project-version-merge-option.model';

/**
 * Represents the Project versions Merge tool modal.
 * */
@Component({
  selector: 'wp-project-versions-merge-form',
  templateUrl: './project-versions-merge-modal.component.html',
  styleUrls: ['./project-versions-merge-modal.component.scss'],
  providers: [ProjectVersionsMergeModalService],
  standalone: false,
})
export class ProjectVersionsMergeModalComponent implements OnInit, OnDestroy {
  @Input() projectId: string;
  @Input() currencyCode: string;

  /** Flag indicating whether the MergeUpdateDetails model has conflicts or not. */
  public hasConflicts = false;

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public mergeModalService: ProjectVersionsMergeModalService,
    private state: StateService,
    private blockUI: BlockUIService,
    private message: MessageService,
    private activeModal: NgbActiveModal,
    private httpClient: HttpClient,
  ) {}

  ngOnInit(): void {
    this.mergeModalService.currencyCode = this.currencyCode;
    // Subscribe to ParamSelection Merge tool external submit event.
    this.mergeModalService.paramSelectionStepSubmitting$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.mergeModalService.merge(ProjVersionMergeMode.showDiff);
      });
    // Subscribe to MergeUpdateDetails Merge tool step model observable.
    this.mergeModalService.mergeUpdateDetails$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((details) => {
        this.hasConflicts = details.hasConflicts;
      });
    // Subscribe to Merge tool operation finished event.
    this.mergeModalService.mergeFinished$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.onMergeFinished());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.mergeModalService.dispose();
  }

  /**
   * The Download report button click handler.
   * Calls the report generation and download.
   * */
  public downloadReport() {
    const url = `${AppConfigService.config.api.url}/ProjectVersionMergeDetailsReport/GetMergeDetailsExcel`;

    this.blockUI.start();

    this.httpClient
      .post(url, this.mergeModalService.paramSelectionValue, {
        responseType: 'blob',
      })
      .subscribe({
        next: (data) => {
          const timestamp = Date.now();
          saveAs(
            data,
            `Project Version merge details Report_${timestamp}.xlsx`,
          );
          this.blockUI.stop();
        },
        error: async (error) => {
          const isPromise = !!error && typeof error.then === 'function';
          if (isPromise) {
            const errorText = await error;
            const errorObj = JSON.parse(errorText);
            this.message.error(errorObj.error.message);
            this.blockUI.stop();
          } else {
            this.message.error(error.message);
            this.blockUI.stop();
          }
        },
      });
  }

  /**
   * The modal Cancel button click handler.
   * Closes the active modal.
   * */
  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  /**
   * The modal Merge button click handler.
   * Calls the Merge operation.
   * */
  public merge() {
    this.mergeModalService.merge(ProjVersionMergeMode.apply);
  }

  /**
   * The modal Merge finished handler.
   * Closes the active modal.
   * If the Combine merge option was selected, moves to the Project card.
   * */
  private onMergeFinished() {
    const paramSelectionValue = this.mergeModalService.paramSelectionValue;
    const isCombineToVersion =
      ProjectVersionMergeOption.combine.code === paramSelectionValue.option &&
      paramSelectionValue.target.entityType === 'ProjectVersion';
    this.activeModal.close();
    if (isCombineToVersion) {
      this.state.go('project', {
        entityId: this.projectId,
        openVersionId: paramSelectionValue.target.id,
      });
    }
  }
}
