import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { filter, merge } from 'rxjs';
import { ProjectCardService } from 'src/app/projects/card/core/project-card.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';
import { AbstractRightSide } from 'src/app/shared-features/grid/abstract-right-side.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';

@Component({
  selector: 'tmt-project-resource-right-side',
  styleUrl: './project-resource-right-side.component.scss',
  templateUrl: './project-resource-right-side.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectResourceRightSideComponent extends AbstractRightSide {
  public isShowTaskDuration: boolean;

  constructor(
    container: ElementRef,
    gridService: GridService,
    freezeTableService: FreezeTableService,
    renderer: Renderer2,
    public projectResourceService: ProjectResourceService,
    public projectResourceDataService: ProjectResourceDataService,
    public projectCardService: ProjectCardService,
    public cdr: ChangeDetectorRef,
  ) {
    super(container, gridService, freezeTableService, renderer);
    merge(gridService.detectChanges$, projectResourceService.changes$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.cdr.detectChanges());

    projectResourceService.isShowTaskDuration$
      .pipe(
        filter((isShow) => isShow !== this.isShowTaskDuration),
        takeUntilDestroyed(),
      )
      .subscribe((value) => {
        this.isShowTaskDuration = value;
        this.cdr.markForCheck();
      });
  }
}
