export interface ChartDataset {
  label: string;
  data: number[];
}

export type Dataset = (string | number)[][];

export interface SortedData {
  category: string | number;
  value: string | number;
  detailed: Record<string, string | number>;
}

export enum SortProperty {
  category = 'category',
  value = 'value',
}
