import { wrapIn } from 'prosemirror-commands';
import { redo, undo } from 'prosemirror-history';
import { splitListItem } from 'prosemirror-schema-list';
import {
  heading,
  resetHeading,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/heading/commands';
import {
  link,
  makeLinksClickable,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/links/commands';
import {
  liftLiItem,
  list,
  sinkLiItem,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/lists/commands';
import {
  joinPrevList,
  ListNode,
  liType,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/lists/consts';
import { markItem } from 'src/app/shared/components/controls/rich-editor-box/markdown/marks/commands';

export const customKeymap = {
  'Mod-z': undo,
  'Mod-y': redo,
  'Shift-Enter': (state, dispatch) => {
    const { schema, tr } = state;
    dispatch(tr.replaceSelectionWith(schema.nodes.hard_break.create()));

    return true;
  },
  // Menu items hot keys.
  'Ctrl-b': (state, dispatch) => markItem('strong')(state, dispatch),
  'Ctrl-i': (state, dispatch) => markItem('em')(state, dispatch),
  'Ctrl-Shift-s': (state, dispatch) => markItem('strike')(state, dispatch),
  'Ctrl-u': (state, dispatch) => markItem('ins')(state, dispatch),
  'Ctrl-Shift-k': (state, dispatch) =>
    link(menuComponent.menubar)(state, dispatch),
  'Ctrl-Shift-0': (state, dispatch) => heading(0)(state, dispatch),
  'Ctrl-Shift-1': (state, dispatch) => heading(1)(state, dispatch),
  'Ctrl-Shift-2': (state, dispatch) => heading(2)(state, dispatch),
  'Ctrl-Shift-3': (state, dispatch) => heading(3)(state, dispatch),
  'Ctrl-Shift-4': (state, dispatch) => heading(4)(state, dispatch),
  'Ctrl-Shift-5': (state, dispatch) => heading(5)(state, dispatch),
  'Ctrl-Shift-6': (state, dispatch) => heading(6)(state, dispatch),
  'Ctrl-Shift-.': (state, dispatch) => {
    wrapIn(state.schema.nodes.blockquote)(state, dispatch);
    makeLinksClickable(menuComponent.menubar);
    return false;
  },
  'Ctrl-e': (state, dispatch) => markItem('code')(state, dispatch),
  Enter: (state, dispatch) =>
    splitListItem(liType(state.schema))(state, dispatch),
  Backspace: (state, dispatch, view) => {
    joinPrevList(state, dispatch);
    resetHeading()(state, dispatch, view);
    return false;
  },
  Tab: (state, dispatch, view) => {
    sinkLiItem()(state, dispatch);
    makeLinksClickable(menuComponent.menubar);
    view.focus();
    return true;
  },
  'Shift-Tab': (state, dispatch, view) => {
    liftLiItem()(state, dispatch);
    makeLinksClickable(menuComponent.menubar);
    view.focus();
    return true;
  },
  'Ctrl-Shift-l': (state, dispatch, view) => {
    list(ListNode.BulletList)(state, dispatch);
    makeLinksClickable(menuComponent.menubar);
    view.focus();
    return true;
  },
  'Ctrl-Shift-m': (state, dispatch, view) => {
    list(ListNode.OrderedList)(state, dispatch);
    makeLinksClickable(menuComponent.menubar);
    view.focus();
    return true;
  },
};

export const menuComponent = {
  menubar: null,
};
