import { Component } from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'wp-date-period-box-test',
  templateUrl: './date-period-box-test.component.html',
  standalone: false,
})
export class DatePeriodBoxTestComponent {
  form = this.fb.group({
    period: [null, Validators.required],
  });

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.setValue({
      period: { periodType: 'PrevWeek', from: '2020-01-20', to: '2020-01-26' },
    });
  }
}
