import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
} from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Observable, Subject } from 'rxjs';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { HistoryService } from 'src/app/core/history.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { takeUntil } from 'rxjs/operators';
import { NavigationService } from 'src/app/core/navigation.service';

/** Заголовок странницы с формой. */
@Component({
  selector: 'wp-form-header',
  templateUrl: './form-header.component.html',
  styleUrls: ['./form-header.component.scss'],
  standalone: false,
})
export class FormHeaderComponent implements OnInit, OnDestroy {
  private _name: string;

  private destroyed$ = new Subject<void>();

  /** Признак доступности редактирования наименования. */
  @Input() public isEditable: boolean;

  /** Имя страницы (формы). */
  @Input()
  get name(): string {
    return this._name;
  }
  set name(value: string) {
    this._name = value;
    this.title.setTitle(value);
    this.cardName = this.name ? this.name : ' ';
    if (value) {
      this.history.addHistory([value], this.navigation.getAppName());
      return;
    }
  }

  /** Признак наличия имени формы. */
  @Input() hasName: boolean;

  /** Признак наличия индикатора автосохранения. */
  @Input() hasAutosave: boolean;

  /** DEPRECATED - do nothing. */
  @Input() isSaving: boolean;

  /** Событие на перезагрузку данных. */
  @Output() reloaded = new EventEmitter<void>();

  /** Событие после изменения наименования. */
  @Output() nameSaved = new EventEmitter<string>();

  /** Функция для выполнения сохранения наименования. */
  @Input() saveFn: (name: string) => Observable<void>;

  public textControl = new UntypedFormControl('', [
    Validators.required,
    Validators.maxLength(Constants.formNameMaxLength),
  ]);
  public isNameSaving: boolean;

  private originalName: string;
  public cardName: string;
  public isEditMode: boolean;

  constructor(
    private notification: NotificationService,
    private title: Title,
    private navigation: NavigationService,
    private history: HistoryService,
    public panelService: ActionPanelService,
  ) {}

  /** Переход в режим редактирования наименования. */
  onClickHeader = () => {
    if (!this.isEditable) {
      return;
    }

    this.textControl.setValue(this.name);
    this.originalName = this.name;
    this.isEditMode = true;
  };

  /** Выход из режима редактирования наименования. */
  cancel = () => {
    this.isEditMode = false;
    this.name = this.originalName;
  };

  /** Сохранить наименование. */
  public save = () => {
    const observable = this.saveFn(this.textControl.value);

    if (observable) {
      this.isNameSaving = true;
      observable.subscribe({
        next: () => {
          this.isNameSaving = false;
          this.isEditMode = false;
          this.name = this.textControl.value;
          this.nameSaved.emit(this.name);
        },
        error: (error: Exception) => {
          this.isNameSaving = false;
          this.notification.error(error.message);
        },
      });
    } else {
      this.isEditMode = false;
      this.name = this.textControl.value;
    }
  };

  /** Перезагружает данные формы. */
  reload() {
    this.reloaded.emit();
  }

  ngOnInit() {
    if (this.hasName == null) {
      this.hasName = true;
    }

    this.panelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());

    this.panelService.setHasAutosave(this.hasAutosave);
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
    this.panelService.setHasAutosave(false);
  }
}
