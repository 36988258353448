import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { ResourceSummaryViewSettingsService } from 'src/app/resource-summary/shared/resource-summary-view-settings/core/resource-summary-view-settings.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Resource Summary View Settings content. */
@Component({
  selector: 'tmt-resource-summary-view-settings',
  templateUrl: './resource-summary-view-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceSummaryViewSettingsComponent implements OnInit {
  /** Gets KPI controls. */
  public get kpiControls(): KpiType[] {
    return Object.values(this.orderedKpi);
  }

  public form: UntypedFormGroup = this.fb.group({
    [KpiType.Plan]: false,
    [KpiType.Estimate]: false,
    [KpiType.Actual]: false,
    [KpiType.Booked]: false,
    [KpiType.Scheduled]: false,
    overtime: false,
  });

  protected kpiType = KpiType;

  private orderedKpi = [
    KpiType.Plan,
    KpiType.Estimate,
    KpiType.Actual,
    KpiType.Booked,
    KpiType.Scheduled,
  ];

  private destroyRef = inject(DestroyRef);

  constructor(
    private service: ResourceSummaryViewSettingsService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.service.settings.show);
    this.form.controls.overtime.patchValue(this.service.settings.overtime);
    this.initSubscriptions();
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const settings = this.service.settings;
        const formValue = this.form.value;
        this.orderedKpi.forEach((kpi) => (settings.show[kpi] = formValue[kpi]));
        settings.overtime = formValue.overtime;
        this.service.propagateSettingsChange(settings);
      });
  }
}
