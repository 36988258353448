import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
  ViewChildren,
  QueryList,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Department } from 'src/app/shared/models/entities/settings/department.model';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
  selector: 'wp-department-card',
  templateUrl: './department-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DepartmentCardComponent implements OnInit {
  @Input() entityId: string;

  public departmentForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: ['', Validators.maxLength(Constants.formCodeMaxLength)],
    leadDepartment: [null],
    isActive: [false],
    role: null,
  });
  public readonly = !this.app.checkEntityPermission(
    'Department',
    PermissionType.Modify,
  );
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public isSaving$ = new BehaviorSubject<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    public app: AppService,
    private notification: NotificationService,
    private data: DataService,
    private message: MessageService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  private reload(): void {
    if (!this.departmentForm.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  /** Saves department. */
  public save(): void {
    this.departmentForm.markAllAsTouched();

    if (this.departmentForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const department = <Department>this.departmentForm.value;

    this.isSaving$.next(true);
    this.actionService.action('save').start();

    const data: any = {
      name: department.name,
      code: department.code,
      isActive: department.isActive,
      leadDepartmentId: department.leadDepartment
        ? department.leadDepartment.id
        : null,
      roleId: department.role?.id ?? null,
    };

    this.data
      .collection('Departments')
      .entity(this.entityId)
      .patch(data)
      .subscribe({
        next: () => {
          this.departmentForm.markAsPristine();
          this.isSaving$.next(false);
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.departments.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving$.next(false);
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  /** Loads Data. */
  public load(): void {
    this.departmentForm.markAsPristine();
    this.departmentForm.markAsUntouched();
    this.state$.next(CardState.Loading);

    this.data
      .collection('Departments')
      .entity(this.entityId)
      .get<Department>({
        expand: ['leadDepartment', { role: { select: ['name', 'id'] } }],
      })
      .subscribe((department: Department) => {
        this.departmentForm.patchValue(department);

        this.readonly = !department.editAllowed;
        this.actionService.action('save').isShown = !this.readonly;
        this.readonly
          ? this.departmentForm.disable()
          : this.departmentForm.enable();
        this.state$.next(CardState.Ready);
        this.navigationService.addRouteSegment({
          id: department.id,
          title: department.name,
        });
      });
  }
}
