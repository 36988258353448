import { Component, Input } from '@angular/core';
import { FormArray, UntypedFormGroup } from '@angular/forms';
import { EntityNavigation } from 'src/app/shared/models/navigation/navigation';

@Component({
  selector: 'wp-dashboard-properties',
  templateUrl: './dashboard-properties.component.html',
  standalone: false,
})
export class DashboardPropertiesComponent {
  @Input() form: UntypedFormGroup;
  @Input() navigationResponse: EntityNavigation[];
  @Input() navigations: FormArray;
}
