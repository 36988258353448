import { Component, OnInit, Input } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { TimeOffBalanceEntryService } from '../core/time-off-balance-entry.service';

@Component({
  selector: 'wp-time-off-balance-entry-card',
  templateUrl: './time-off-balance-entry-card.component.html',
  providers: [TimeOffBalanceEntryService],
  standalone: false,
})
export class TimeOffBalanceEntryCardComponent implements OnInit {
  @Input() entityId: string;

  constructor(
    public service: TimeOffBalanceEntryService,
    private actionService: ActionPanelService,
  ) {
    service.mode = 'edit';
  }

  ngOnInit() {
    this.service.saving$.subscribe((state) => {
      this.actionService.action('save').isBusy = state;
    });

    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.service.save(),
      },
    ]);
  }
}
