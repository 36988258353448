import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UndoRedoService } from 'src/app/shared/services/undo-redo/undo-redo.service';
import { TranslateModule } from '@ngx-translate/core';
import { HotkeyPipe } from 'src/app/shared/pipes/hotkey/hotkey.pipe';
import { Hotkey } from 'src/app/shared/pipes/hotkey/hotkey';

@Component({
    selector: 'tmt-undo-redo-buttons',
    templateUrl: './undo-redo-buttons.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    imports: [TranslateModule, HotkeyPipe]
})
export class UndoRedoButtonsComponent {
  public readonly hotkey = Hotkey;
  constructor(public service: UndoRedoService) {}
}
