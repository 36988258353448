import { Injectable } from '@angular/core';
import { DataService } from '../../core/data.service';
import { TenantSetting } from 'src/app/shared/models/entities/settings/multitenant/tenant-settings.model';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class TenantSettingService {
  constructor(private data: DataService) {}

  /**
   * Gets Password requirements plain text.
   *
   * @returns Password requirements text.
   * */
  public getPasswordRequirements(): Observable<string> {
    return this.data.model
      .function('GetPasswordRequirementsText')
      .get<string>();
  }

  /**
   * Gets {@link TenantSetting} by specified OData params.
   *
   * @returns Observable of {@link TenantSetting}.
   * */
  public getSetting(oDataParams?: object): Observable<TenantSetting> {
    return this.data
      .collection('TenantSettings')
      .function('GetSingle')
      .get<TenantSetting>(null, oDataParams);
  }
}
