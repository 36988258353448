import { Component } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-schedule-exception-creation',
  templateUrl: './schedule-exception-creation.component.html',
  standalone: false,
})
export class ScheduleExceptionCreationComponent {
  isSaving = false;
  form: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  public ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = this.form.value;

    this.data
      .collection('ScheduleExceptions')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'settings.scheduleExceptions.creation.messages.created',
          );
          this.state.go('settings.scheduleException', {
            entityId: response.id,
          });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }
}
