import { toggleMark } from 'prosemirror-commands';
import { Command } from 'prosemirror-state';
import { RichEditorBoxMenuComponent } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu';

/**
 * Performs link menu item click event.
 *
 * @param component editor view parent component.
 * @returns editor view command.
 */
export function link(component: RichEditorBoxMenuComponent): Command {
  return (state, dispatch) => {
    if (state.selection.empty) {
      component.richEditorBoxMenuService.openLinkPopup(component.linkItem, {
        editorView: component.editorView,
      });
    } else if (!component.infoPopupService.popups.length) {
      if (component.items.find((item) => item.id === 'link').isActive) {
        toggleMark(state.schema.marks.link)(state, dispatch);
      }
      toggleMark(state.schema.marks.link, {
        href: '',
      })(state, dispatch);
      const currentElement = component.editorView.dom.querySelectorAll(
        `a[href=""]`,
      )[0] as HTMLElement;
      component.richEditorBoxMenuService.linkClickEventSubscriber(
        currentElement,
      );
      currentElement.click();
      return true;
    }
  };
}

/**
 * Adds click event listener to editor doc's links.
 *
 * @param component editor view parent component.
 */
export function makeLinksClickable(
  component: RichEditorBoxMenuComponent,
): void {
  if (!component) {
    return;
  }
  component.editorView.dom.querySelectorAll('a').forEach((link) => {
    component.richEditorBoxMenuService.linkClickEventSubscriber(link);
  });
}
