import { CustomizableEntity } from 'src/app/shared/models/entities/customizable-entity.interface';
import { CodedEntity } from '../coded-entity.model';
import { FinancialAccountType } from './financial-account-type.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

export class FinancialAccount
  extends CodedEntity
  implements CustomizableEntity
{
  public static get revenueId(): string {
    return 'd0e23321-fd5d-4982-8a72-23747b035026';
  }

  public static get laborCostId(): string {
    return '15476461-f0e2-4b70-990a-b4a3ccb5c87f';
  }

  public static get timeOffCostId(): string {
    return '13e9a026-b80f-4a08-8fd4-6a269f698004';
  }

  public static readonly corporateTaxId =
    'eedaa83c-5559-4319-806f-30ca66d9f11b';

  public static readonly laborCostIds: string[] = [
    this.laborCostId,
    this.timeOffCostId,
  ];

  /** Expense or revenue. */
  type: FinancialAccountType;

  typeId: string;

  description: string;

  /** Indicates that a financial account was created by system and is not allowed for editing. */
  isSystem: boolean;

  /** Indicates whether to calculate a financial account in the PnL. */
  includedInBalance: boolean;

  /**  Customizable properties. */
  stringValue1?: string;
  stringValue2?: string;
  stringValue3?: string;
  stringValue4?: string;
  stringValue5?: string;

  decimalValue1?: number;
  decimalValue2?: number;
  decimalValue3?: number;
  decimalValue4?: number;
  decimalValue5?: number;

  dateValue1?: string;
  dateValue2?: string;
  dateValue3?: string;
  dateValue4?: string;
  dateValue5?: string;

  lookupValue1?: NamedEntity;
  lookupValue2?: NamedEntity;
  lookupValue3?: NamedEntity;
  lookupValue4?: NamedEntity;
  lookupValue5?: NamedEntity;
  lookupValue6?: NamedEntity;
  lookupValue7?: NamedEntity;
  lookupValue8?: NamedEntity;
  lookupValue9?: NamedEntity;
  lookupValue10?: NamedEntity;

  integerValue1?: number;
  integerValue2?: number;
  integerValue3?: number;
  integerValue4?: number;
  integerValue5?: number;
}
