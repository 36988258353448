import { Schema } from 'prosemirror-model';
import { addListNodes } from 'prosemirror-schema-list';
import { schema as basicSchema } from 'prosemirror-schema-basic';
import { tagMark } from 'src/app/shared/components/controls/rich-editor-box/markdown/marks/consts';

const nodes = basicSchema.spec.nodes.remove('image').addToEnd('mention', {
  inline: true,
  group: 'inline',
  selectable: false,
  atom: true,
  attrs: {
    id: {},
    label: {},
  },
  toDOM: (node) => [
    'span',
    { class: 'mention', 'data-id': node.attrs.id },
    node.attrs.label,
  ],
  parseDOM: [
    {
      tag: 'span.mention',
      getAttrs: (dom) => ({
        id: dom.getAttribute('data-id'),
        label: dom.textContent,
      }),
    },
  ],
});

export const newSchema = new Schema({
  nodes: addListNodes(nodes, '(paragraph | block) block*', 'block'),
  marks: basicSchema.spec.marks
    .remove('code')
    .append(tagMark('strike'))
    .append(tagMark('ins'))
    .append(tagMark('samp'))
    .append(tagMark('code')), // Code rank should be higher than other marks rank.
});
