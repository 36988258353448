import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { StateService } from '@uirouter/core';
import { Subscription } from 'rxjs';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { TimeOffBalanceEntryCreationComponent } from '../creation/time-off-balance-entry-creation.component';
import { TimeOffBalanceEntryService } from '../core/time-off-balance-entry.service';
import { TimeOffBalanceEntryFilterService } from './filter/time-off-balance-entry-filter.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-time-off-balance-entries-list',
  template: '<wp-entity-list></wp-entity-list>',
  providers: [
    { provide: FilterService, useClass: TimeOffBalanceEntryFilterService },
    EntityListService,
    TimeOffBalanceEntryService,
    ListService,
    GridService,
  ],
  standalone: false,
})
export class TimeOffBalanceEntriesListComponent implements OnInit, OnDestroy {
  selectedRowListener: Subscription;

  constructor(
    private app: AppService,
    private state: StateService,
    private service: EntityListService,
    private gridService: GridService,
    private actions: ActionPanelService,
    private modalService: NgbModal,
  ) {}

  ngOnInit() {
    this.actions.set([
      this.actions.getDefaultAction('create', {
        title: 'team.timeOffBalanceEntries.list.actions.create',
        isVisible: this.app.checkEntityPermission(
          'TimeOffBalanceEntry',
          PermissionType.Modify,
        ),
        handler: this.create,
      }),
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go('timeOffBalanceEntry', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: this.service.delete,
      }),
    ]);

    this.selectedRowListener = this.gridService.selectedGroup$.subscribe(
      (row: any) => {
        this.actions.action('card').isShown = !!row;
        this.actions.action('delete').isShown =
          row &&
          this.app.checkEntityPermission(
            'TimeOffBalanceEntry',
            PermissionType.Modify,
          );
      },
    );
  }

  private create = () => {
    const ref = this.modalService.open(TimeOffBalanceEntryCreationComponent);
    ref.result.then(
      (result) => {
        if (result === 'reload') {
          this.service.reload();
        }
      },
      () => null,
    );
  };

  ngOnDestroy(): void {
    this.selectedRowListener.unsubscribe();
  }
}
