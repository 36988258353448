import {
  Component,
  OnInit,
  Input,
  ElementRef,
  Injector,
  inject,
  DestroyRef,
  NgZone,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { fromEvent } from 'rxjs';
import {
  BookingRenderingService,
  BookingStyle,
} from '../../core/booking-rendering.service';
import { filter } from 'rxjs/operators';
import { round } from 'lodash';
import { BookingEntry } from 'src/app/shared/models/entities/resources/booking-entry.model';
import { Position } from 'src/app/shared/models/inner/position.model';
import { BookingService } from '../../core/booking.service';
import { BookingType } from 'src/app/shared/models/enums/booking-type.enum';
import { MessageService } from 'src/app/core/message.service';
import { Constants } from 'src/app/shared/globals/constants';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { BookingEntryInfoComponent } from '../booking-entry-info/booking-entry-info.component';
import { DragHandler } from '../../models/drag-handler.model';
import { DefaultPopperModifier } from 'src/app/shared/components/features/info-popup/info-popup.interface';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { MenuService, MenuItem } from 'src/app/core/menu.service';

@Component({
  selector: 'wp-booking-entry',
  templateUrl: './booking-entry.component.html',
  styleUrls: ['./booking-entry.component.scss'],
  standalone: false,
})
export class BookingEntryComponent implements OnInit {
  @Input() public booking: BookingEntry;

  public startDragPosition: Position;
  public el: HTMLDivElement;
  public labelEl: HTMLDivElement;
  public isPseudoBooking = false;

  private dragHandler = new DragHandler();
  private destroyRef = inject(DestroyRef);

  constructor(
    private message: MessageService,
    public bookingService: BookingService,
    private bookingDataService: BookingDataService,
    private renderingService: BookingRenderingService,
    private elementRef: ElementRef,
    private menuService: MenuService,
    private freezeTablesService: FreezeTableService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private zone: NgZone,
  ) {}

  public openProjectPopup(bookingId: string): void {
    const bookingProjectDiv: HTMLElement = this.el.querySelector(
      `#project-${bookingId}`,
    );
    const bookingProjectNameSpan: HTMLElement = this.el.querySelector(
      `#project-name-${bookingId}`,
    );

    const target =
      bookingProjectDiv.offsetWidth < bookingProjectNameSpan.offsetWidth
        ? bookingProjectDiv
        : bookingProjectNameSpan;

    const popperModifiers: DefaultPopperModifier[] = [
      {
        name: 'offset',
        options: {
          offset: ({ placement }) => {
            if (placement === 'bottom') {
              return [0, 31];
            } else {
              return [0, 12];
            }
          },
        },
      },
    ];
    if (!this.isPseudoBooking) {
      this.infoPopupService.open({
        target,
        data: {
          component: BookingEntryInfoComponent,
          params: {
            bookingId,
          },
          injector: this.injector,
        },
        popperModifiers,
      });
    }
  }

  private updateRender(renderParams: BookingStyle) {
    this.el.style.left = renderParams.position.x + 'px';
    this.el.style.top = renderParams.position.y + 'px';
    this.el.style.width = renderParams.width + 'px';

    if (renderParams.position.x < 0) {
      this.labelEl.style.paddingLeft = -1 * renderParams.position.x + 'px';
    } else {
      this.labelEl.style.paddingLeft = null;
    }

    this.el.className = '';
    renderParams.css.forEach((cl) => this.el.classList.add(cl));
  }

  private startResize(side: 'left' | 'right', event: MouseEvent) {
    this.dragHandler.isChanging = true;
    this.startDragPosition = { x: event.clientX, y: event.clientY };
    const rect = this.el.getBoundingClientRect();

    const xGap =
      side === 'left'
        ? round(event.clientX - rect.x)
        : round(rect.x + rect.width - event.clientX);

    this.renderingService.startResize(
      side,
      this.booking,
      this.el,
      event,
      xGap,
      round(event.clientY - rect.y),
    );
  }

  ngOnInit(): void {
    this.isPseudoBooking = this.booking.isOther || this.booking.isTimeOff;
    this.el = this.elementRef.nativeElement;
    this.labelEl = this.el.querySelector('.label') as HTMLDivElement;

    const leftHandle = this.el.querySelector('.resize-handle-left');
    const rightHandle = this.el.querySelector('.resize-handle-right');

    this.renderingService.stopChange$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dragHandler.reset();
      });

    this.renderingService.bookingStyle$
      .pipe(
        filter((s) => s.id === this.booking.id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((style) => {
        this.updateRender(style);
      });

    this.freezeTablesService.scroll$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.menuService.close());

    if (this.booking.editAllowed && !this.bookingDataService.isReadonlyMode()) {
      fromEvent(leftHandle, 'mousedown')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((event: MouseEvent) => {
          this.startResize('left', event);
        });

      fromEvent(rightHandle, 'mousedown')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((event: MouseEvent) => {
          this.startResize('right', event);
        });
    }

    fromEvent(this.el, 'contextmenu')
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: MouseEvent) => {
        const items: MenuItem[] = [
          {
            name: 'open',
            label: 'resources.booking.entryContextMenu.open',
            handlerFn: () =>
              this.bookingService.openBookingForm(this.booking, 'edit'),
          },
        ];

        if (
          !this.isPseudoBooking &&
          !this.bookingDataService.isReadonlyMode()
        ) {
          items.push({
            name: 'setType',
            label: `resources.booking.entryContextMenu.${
              this.booking.type === BookingType.Hard ? 'toSoft' : 'toHard'
            }`,
            handlerFn: () => this.bookingService.setType(this.booking),
          });
        }

        if (
          !this.isPseudoBooking &&
          !this.bookingDataService.isReadonlyMode()
        ) {
          items.push({
            name: 'notify',
            label: 'resources.booking.entryContextMenu.notify',
            handlerFn: () => this.bookingService.notify(this.booking),
          });
        }

        if (
          this.booking.editAllowed &&
          !this.bookingDataService.isReadonlyMode()
        ) {
          items.push({
            name: 'delete',
            label: 'resources.booking.entryContextMenu.delete',
            handlerFn: () =>
              this.message
                .confirmLocal(
                  'resources.booking.entryContextMenu.deleteConfirmation',
                )
                .then(
                  () => {
                    this.bookingService.deleteBooking(this.booking);
                  },
                  () => null,
                ),
          });
        }

        this.menuService.open(event, items, this.booking);
        event.preventDefault();
        event.stopPropagation();
      });

    this.renderingService.registerBooking(this.booking);
  }

  public ngAfterViewInit(): void {
    const entryEl = this.el.querySelector(`#entry-${this.booking.id}`);

    this.zone.runOutsideAngular(() => {
      fromEvent(entryEl, 'mousemove')
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe((event: MouseEvent) => {
          this.dragEvent(event);
        });
    });
  }

  dragEvent(event: MouseEvent) {
    event.preventDefault();
    switch (event.type) {
      case 'mousemove':
        if (this.dragHandler.isChanging) {
          return;
        }
        event.stopPropagation();
        if (this.dragHandler.mouseDown) {
          if (
            Math.abs(event.x - this.dragHandler.mouseX) >
              Constants.beginEventMouseGap ||
            Math.abs(event.y - this.dragHandler.mouseY) >
              Constants.beginEventMouseGap
          ) {
            this.dragHandler.isChanging = true;
            this.startDrag(event);
          }
        } else {
          this.dragHandler.mouseX = event.x;
          this.dragHandler.mouseY = event.y;
        }
        break;
      case 'mousedown':
        event.stopPropagation();
        this.dragHandler.mouseDown = true;
        break;
      case 'mouseup':
        this.dragHandler.isChanging = false;
        this.dragHandler.mouseDown = false;
        break;
      case 'mouseleave':
        if (this.dragHandler.isChanging && !this.dragHandler.mouseDown) {
          return;
        }
        event.stopPropagation();
        this.dragHandler.reset();
        break;
    }
  }

  startDrag(event) {
    if (this.booking.editAllowed && !this.bookingDataService.isReadonlyMode()) {
      if (event.button === 0) {
        this.infoPopupService.close();
        this.startDragPosition = { x: event.clientX, y: event.clientY };
        const rect = this.el.getBoundingClientRect();
        this.renderingService.startDrag(
          this.booking,
          round(event.clientX - rect.x),
          round(event.clientY - rect.y),
          this.el,
          event,
        );
      }
    }
  }
}
