<wp-loading-indicator [state]="state"></wp-loading-indicator>
<wp-data-not-saved-indicator [form]="form"></wp-data-not-saved-indicator>

<form [formGroup]="form" [hidden]="state !== 'Ready'" class="form form-container">
  <div class="container-fluid">
    <div class="row">
      <div class="col">
        <label class="group">
          {{ 'settings.settings.tenantSettings.finance.header' | translate }}
        </label>

        <div class="form-group" tmtIndicator>
          <label class="control-label">{{
            'settings.settings.tenantSettings.finance.props.currency' | translate
          }}</label>
          <wp-select-box collection="Currencies" formControlName="currency" [allowNull]="false">
          </wp-select-box>
        </div>

        <div class="form-group">
          <label class="control-label mb-2">{{
            'settings.settings.tenantSettings.finance.props.pnl' | translate
          }}</label>

          <div class="form-check">
            <input
              formControlName="showExtendedPnl"
              type="checkbox"
              class="form-check-input"
              id="showExtendedPnl"
            />
            <label class="form-check-label" for="showExtendedPnl">
              {{ 'settings.settings.tenantSettings.finance.props.showExtendedPnl' | translate }}
            </label>
          </div>
        </div>

        <label class="group">
          {{ 'components.settingsApplicationsComponent.props.header.capitalCharges' | translate }}
        </label>

        <form class="form" [formGroup]="capitalChargeFormGroup">
          <div class="form-check">
            <input
              formControlName="isAccrueCostOnWorkingCapital"
              type="checkbox"
              class="form-check-input"
              id="isAccrueCostOnWorkingCapital"
            />
            <label class="form-check-label" for="isAccrueCostOnWorkingCapital">
              {{
                'components.settingsApplicationsComponent.props.isAccrueCostOnWorkingCapital'
                  | translate
              }}
            </label>
          </div>
          <div class="form-check">
            <input
              formControlName="isAccrueCostOnOutstandingExpenses"
              type="checkbox"
              class="form-check-input"
              id="isAccrueCostOnOutstandingExpenses"
            />
            <label class="form-check-label" for="isAccrueCostOnOutstandingExpenses">
              {{
                'components.settingsApplicationsComponent.props.isAccrueCostOnOutstandingExpenses'
                  | translate
              }}
            </label>
          </div>
        </form>

        <form class="form" [formGroup]="capitalChargeRatesFormGroup">
          <div class="form-group mt-3" formGroupName="initialValue">
            <label>{{ 'components.settingsApplicationsComponent.props.value' | translate }}</label>
            <wp-number-box
              type="percent"
              [max]="1000"
              [allowNull]="false"
              formControlName="value"
            ></wp-number-box>
          </div>
          <span class="form-text text-body-secondary">{{
            'components.settingsApplicationsComponent.messages.capitalChargeInfo' | translate
          }}</span>

          <tmt-grid [formArray]="capitalChargeRatesFormValues" [options]="gridOptions" />
        </form>
      </div>
      <div class="col">
        <label class="group">
          {{ 'settings.settings.tenantSettings.booking.header' | translate }}
        </label>

        <div class="form-group">
          <label
            >{{ 'settings.settings.tenantSettings.booking.mode' | translate }}
            <i
              class="bi bi-question-circle help-action"
              tmtDocsArticle="resources/components/booking-mode"
            ></i
          ></label>
          <div class="align-items-center d-flex form-control-plaintext">
            {{ form.value.bookingMode?.name
            }}<button (click)="changeBookingMode()" class="btn btn-link p-0 ms-3">
              {{ 'settings.settings.tenantSettings.booking.changeMode' | translate }}
            </button>
          </div>
        </div>
        <div class="form-group" tmtIndicator>
          <div class="form-check">
            <input
              formControlName="isBookingScaleFixed"
              type="checkbox"
              class="form-check-input"
              id="isBookingScaleFixed"
            />
            <label class="form-check-label" for="isBookingScaleFixed">
              {{ 'settings.settings.tenantSettings.booking.fixedBookingScale' | translate }}
            </label>
          </div>
        </div>
        <div
          class="form-group"
          tmtIndicator
          *ngIf="form.controls.isBookingScaleFixed.valueChanges | async"
        >
          <label class="control-label"
            >{{ 'settings.settings.tenantSettings.booking.bookingScale' | translate }}
            <i
              class="bi bi-question-circle help-action"
              tmtDocsArticle="resources/components/booking-scale"
            ></i
          ></label>
          <wp-select-box
            [values]="bookingScales"
            formControlName="bookingScale"
            [allowNull]="false"
            placeholder="{{ 'settings.settings.tenantSettings.booking.bookingScale' | translate }}"
          >
          </wp-select-box>
        </div>
        <label class="group mt-3">
          {{ 'settings.settings.tenantSettings.forecast.header' | translate }}
        </label>

        <div class="form-group">
          <label>{{
            'settings.settings.tenantSettings.forecast.forecastPeriod.label' | translate
          }}</label>
          <wp-select-box
            [allowNull]="false"
            [values]="forecastPeriods"
            [filterOnServer]="false"
            formControlName="forecastPeriod"
          ></wp-select-box>
        </div>

        <label class="group mt-3">
          {{ 'settings.settings.tenantSettings.billing.header' | translate }}
        </label>

        <div class="form-group" tmtIndicator>
          <label class="control-label">{{
            'settings.settings.tenantSettings.billing.vatSupportLabel' | translate
          }}</label>
          <div class="pt-2 form-check">
            <input formControlName="useVat" type="checkbox" class="form-check-input" id="useVat" />
            <label class="form-check-label" for="useVat">
              {{ 'settings.settings.tenantSettings.billing.vatSupport' | translate }}
            </label>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="onSave()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
