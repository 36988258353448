import {
  GridColumn,
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { DataColumn, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const DATE_TIME_COLUMN_WIDTH: number = 130;
export const IS_ACTIVE_COLUMN_WIDTH: number = 115;

/**
 * Constructs a data field string by optionally prefixing the field name with a navigation prefix.
 *
 * @param {string} name - The name of the data field.
 * @param {string} [navigationPrefix=''] - The optional navigation prefix to be added before the field name.
 * @returns {string} The constructed data field string.
 */
function getDataField(name, navigationPrefix = ''): string {
  if (!navigationPrefix) {
    return name;
  }

  return `${navigationPrefix}.${name}`;
}

/**
 * Returns an array of GridColumn objects for named entities.
 *
 * @param {string} state - The state to be used for the navigation column.
 * @returns {GridColumn[]} An array of GridColumn objects.
 */
export function getNamedEntityGridColumns(state = ''): GridColumn[] {
  const result: GridColumn[] = [
    {
      name: 'created',
      header: 'shared.columns.created.title',
      hint: 'shared.columns.created.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'createdBy',
      header: 'shared.columns.createdBy.title',
      hint: 'shared.columns.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modified',
      header: 'shared.columns.modified.title',
      hint: 'shared.columns.modified.hint',
      fixedWidth: true,
      type: GridColumnType.DateTime,
    },
    {
      name: 'modifiedBy',
      header: 'shared.columns.modifiedBy.title',
      hint: 'shared.columns.modifiedBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'isActive',
      fixedWidth: true,
      type: GridColumnType.Boolean,
      header: 'settings.users.list.columns.isActive',
      hint: 'settings.users.list.columns.isActive',
    },
  ];

  if (state) {
    result.push(<GridNavigationColumn>{
      name: 'name',
      type: GridColumnType.Navigation,
      state,
      header: 'shared.columns.name',
      hint: 'shared.columns.name',
      availableTotals: [TotalType.Count],
    });
  }

  return result;
}

/**
 * Returns an array of GridColumn objects for coded entities.
 *
 * @param {string} state - The state to be used for the navigation column.
 * @returns {GridColumn[]} An array of GridColumn objects.
 */

export function getCodedEntityGridColumns(state = ''): GridColumn[] {
  const result: GridColumn[] = [
    ...getNamedEntityGridColumns(state),
    {
      name: 'code',
      type: GridColumnType.String,
      header: 'shared.columns.code',
      hint: 'shared.columns.code',
    },
  ];
  return result;
}

/**
 * Returns an array of DataColumn objects for named entities.
 *
 * @param {string} navigationPrefix - The prefix to be used for the data fields.
 * @returns {DataColumn[]} An array of DataColumn objects.
 */
export function getNamedEntityDataColumns(navigationPrefix = ''): DataColumn[] {
  return [
    {
      column: 'name',
      field: getDataField('name', navigationPrefix),
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: getDataField('isActive', navigationPrefix),
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: getDataField('created', navigationPrefix),
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modified',
      field: getDataField('modified', navigationPrefix),
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: getDataField('createdBy.name', navigationPrefix),
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: getDataField('modifiedBy.name', navigationPrefix),
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ];
}

/**
 * Returns an array of DataColumn objects for coded entities.
 *
 * @param {string} navigationPrefix - The prefix to be used for the data fields.
 * @returns {DataColumn[]} An array of DataColumn objects.
 */
export function getCodedEntityDataColumns(navigationPrefix = ''): DataColumn[] {
  return [
    ...getNamedEntityDataColumns(navigationPrefix),
    {
      column: 'code',
      field: getDataField('code', navigationPrefix),
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ];
}
