/* eslint-disable @typescript-eslint/naming-convention */
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'wp-acts-filter',
  templateUrl: './acts-filter.component.html',
  standalone: false,
})
export class ActsFilterComponent implements OnInit, OnDestroy, FilterDetails {
  @Input() values: any;

  detailsForm = this.fb.group({
    period: [null],
    client: [null],
    project: [null],
    states: [null],
  });

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(VIEW_NAME) public viewName: string,
    public service: FilterService,
  ) {}

  ngOnInit() {
    this.service.resetValues$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const values = Object.assign(
          this.service.values,
          this.detailsForm.value,
        );
        this.service.changeValues(values);
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
