import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { orderBy } from 'lodash';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'src/app/core/notification.service';
import { AccountDocumentLinkComponent } from './account-document-link/account-document-link.component';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { Exception } from 'src/app/shared/models/exception';
import { AccountDocument } from 'src/app/shared/models/entities/settings/multitenant/account-document.model';
import { AccountDocumentStatus } from 'src/app/shared/models/enums/account-document-status';
import { EAEUCountries } from 'src/app/shared/helpers/globalization.helper';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridOptions } from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumn,
  GridColumnType,
  GridComponentColumn,
  GridCurrencyColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'wp-account-documents',
  templateUrl: './account-documents.component.html',
  styleUrls: ['./account-documents.component.scss'],
  providers: [GridService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AccountDocumentsComponent implements OnInit {
  @Input() currencyCode: string;
  @Input() countryCode: string;

  public gridOptions: GridOptions;

  public formArray = this.fb.array([]);

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private gridService: GridService,
    private data: DataService,
    private translate: TranslateService,
  ) {}

  public ngOnInit(): void {
    this.gridOptions = {
      rowCommands: [
        {
          name: 'cancel',
          handlerFn: (formGroup: UntypedFormGroup) => {
            this.cancel(formGroup.value.id);
          },

          allowedFn: (formGroup: UntypedFormGroup) => {
            const doc = formGroup.getRawValue() as any;
            return doc.status.id === AccountDocumentStatus.Issued;
          },
          label: 'settings.account.documents.actions.cancel',
        },
      ],
      view: {
        name: 'documents',
        columns: [
          {
            name: 'date',
            header: 'settings.account.documents.columns.date',
            hint: 'settings.account.documents.columns.date',
            type: GridColumnType.Date,
            width: '120px',
          },
          {
            name: 'number',
            header: 'settings.account.documents.columns.number',
            hint: 'settings.account.documents.columns.number',
            type: GridColumnType.String,
            width: '120px',
          },
          <GridComponentColumn>{
            name: 'invoice',
            header: 'settings.account.documents.columns.invoice',
            hint: 'settings.account.documents.columns.invoice',
            type: GridColumnType.Component,
            component: AccountDocumentLinkComponent,
            property: 'number',
            width: '150px',
          },
          <GridComponentColumn>{
            name: 'closingDocument',
            header: 'settings.account.documents.columns.closingDocument',
            hint: 'settings.account.documents.columns.closingDocument',
            type: GridColumnType.Component,
            component: AccountDocumentLinkComponent,
            property: 'number',
            width: '150px',
          },
          <GridCurrencyColumn>{
            name: 'amount',
            header: 'settings.account.documents.columns.amount',
            hint: 'settings.account.documents.columns.amount',
            type: GridColumnType.Currency,
            width: '120px',
            currencyCode: this.currencyCode,
          },
          {
            name: 'description',
            header: 'settings.account.documents.columns.description',
            hint: 'settings.account.documents.columns.description',
            type: GridColumnType.String,
            width: '100%',
          },
          {
            name: 'status',
            header: 'settings.account.documents.columns.status',
            hint: 'settings.account.documents.columns.status',
            type: GridColumnType.Entity,
            width: '150px',
          },
        ],
      },
    };

    if (EAEUCountries.indexOf(this.countryCode) === -1) {
      this.gridOptions.view.columns.splice(3, 1);
    }

    this.reload();
  }

  /**
   * Cancels a license order with the specified document ID.
   *
   * @param id The ID of the document to cancel.
   */
  public cancel(id: string): void {
    this.data
      .singleton('Tenant')
      .action('WP.CancelLicenseOrder')
      .execute({ documentId: id })
      .subscribe({
        next: () => {
          this.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  /** Reloads the account documents data and updates the form array. */
  private reload(): void {
    this.gridService.setLoadingState(true);

    this.formArray.clear();

    this.data
      .singleton('Tenant')
      .function('WP.GetAccountDocuments')
      .query<AccountDocument[]>()
      .subscribe({
        next: (data: AccountDocument[]) => {
          const documents = orderBy(data, ['number'], ['desc']);

          documents.forEach((document: any) => {
            document.status = {
              id: document.status,
              name: this.translate.instant(
                `enums.accountDocumentStatus.${document.status}`,
              ),
            };

            document.type = {
              id: document.type,
              name: this.translate.instant(
                `enums.accountDocumentType.${document.type}`,
              ),
            };

            this.formArray.push(this.getDocumentFormGroup(document));
          });
          this.gridService.setLoadingState(false);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.gridService.setLoadingState(false);
        },
      });
  }

  /**
   * Creates a form group for a given account document row.
   *
   * @param doc The account document to create the form group for.
   * @returns An UntypedFormGroup representing the account document.
   */
  private getDocumentFormGroup(doc: AccountDocument): UntypedFormGroup {
    const formGroup = new UntypedFormGroup({
      id: new UntypedFormControl(doc.id),
    });

    this.gridOptions.view.columns
      .concat([
        {
          name: 'type',
          type: null,
          header: null,
          hint: null,
        },
        {
          name: 'hasLicense',
          type: null,
          header: null,
          hint: null,
        },
      ])
      .forEach((column: GridColumn) => {
        formGroup.addControl(
          column.name,
          new UntypedFormControl(doc[column.name]),
        );
      });

    return formGroup;
  }
}
