import { Component, Input, OnInit } from '@angular/core';
import { EntityType } from '../../../../../shared-features/resource-roles/enums/entity-type.enum';
import { UntypedFormGroup } from '@angular/forms';
import { AppService } from '../../../../../core/app.service';
import { ResourceGroupsService } from './core/resource-groups.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-resource-groups',
  templateUrl: './resource-groups.component.html',
  styleUrls: ['./resource-groups.component.scss'],
  standalone: false,
})
export class ResourceGroupsComponent implements OnInit {
  @Input() resourceId: string;
  @Input() resourceType: EntityType;
  @Input() readonly: string;

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.userGroupsService.delete(index);
        },
      },
    ],
    view: {
      name: 'resourceRoles',
      columns: [
        {
          name: 'name',
          header: 'shared.resourceGroups.columns.name',
          hint: 'shared.resourceGroups.columns.name',
          type: GridColumnType.String,
          width: '100%',
        },
        {
          name: 'description',
          header: 'shared.resourceGroups.columns.description',
          hint: 'shared.resourceGroups.columns.description',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  constructor(
    public app: AppService,
    public userGroupsService: ResourceGroupsService,
  ) {}

  ngOnInit() {
    this.userGroupsService.resourceId = this.resourceId;
    this.userGroupsService.load();
  }

  /** Method that trigger open modal where we can select new groups. */
  public addGroup() {
    this.userGroupsService.addGroups(this.resourceType);
  }
}
