import { TranslateService } from '@ngx-translate/core';
import { Component, ChangeDetectorRef, OnInit, Input } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { BookingType } from 'src/app/shared/models/enums/booking-type.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { DatePipe } from '@angular/common';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { ChromeService } from 'src/app/core/chrome.service';

interface BookingEntryInfo {
  project: NamedEntity;
  projectManager: NamedEntity;
  client: NamedEntity;
  projectState: NamedEntity;
  bookingMethod: NamedEntity;
  bookingType: BookingType;
  bookingPeriod: string;
}

@Component({
  selector: 'wp-booking-entry-info',
  templateUrl: './booking-entry-info.component.html',
  styleUrls: ['./booking-entry-info.component.scss'],
  standalone: false,
})
export class BookingEntryInfoComponent implements OnInit {
  @Input() params: any;

  public bookingEntryInfo: BookingEntryInfo;
  public isShown: boolean;
  public isLoading: boolean;
  public notFound: boolean;

  constructor(
    public chrome: ChromeService,
    private changeDetector: ChangeDetectorRef,

    private data: DataService,
    private notification: NotificationService,
    private datePipe: DatePipe,
    private translate: TranslateService,
    private infoPopupService: InfoPopupService,
  ) {}

  private open(bookingId: string) {
    this.load(bookingId);
    this.isShown = true;
    this.notFound = false;
    this.changeDetector.detectChanges();
  }

  close() {
    this.infoPopupService.close();
  }

  private load(bookingId: string) {
    this.isLoading = true;
    const query = {
      expand: [
        {
          project: {
            select: ['name', 'id'],
            expand: {
              manager: { select: 'name' },
              organization: { select: 'name' },
              state: { select: 'name' },
            },
          },
        },
      ],
    };

    this.data
      .collection('BookingEntries')
      .entity(bookingId)
      .get<any>(query)
      .subscribe({
        next: (data) => {
          this.bookingEntryInfo = {
            project: data.project,
            projectManager: data.project.manager,
            client: data.project.organization,
            projectState: data.project.state,
            bookingMethod: this.translate.instant(
              `enums.planningMethod.${data.planningMethod}`,
            ),
            bookingType: data.type,
            bookingPeriod: `
            ${this.datePipe.transform(data.from, 'shortDate')} -
            ${this.datePipe.transform(data.to, 'shortDate')}`,
          };

          this.isLoading = false;
          this.changeDetector.detectChanges();
          this.infoPopupService.update();
        },
        error: (error: Exception) => {
          this.isLoading = false;
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.notFound = true;
          } else {
            this.notification.error(error.message);
          }
          this.changeDetector.detectChanges();
          this.infoPopupService.update();
        },
      });
  }

  ngOnInit() {
    this.open(this.params.bookingId);
  }
}
