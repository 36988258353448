import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { VisibleParts } from 'src/app/shared-features/schedule-navigation';

@Component({
  selector: 'tmt-project-resource-toolbar',
  templateUrl: './project-resource-toolbar.component.html',
  styleUrl: './project-resource-toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectResourceToolbarComponent extends AbstractToolbar {
  public get scheduleNavigationParts(): VisibleParts[] {
    return this.defaultScheduleNavigationParts.filter(
      (part) =>
        !this.projectResourceDataService.isForecastMode ||
        part !== VisibleParts.planningScaleSelector,
    );
  }
  private readonly defaultScheduleNavigationParts: VisibleParts[] = [
    VisibleParts.calendar,
    VisibleParts.valueModeSelector,
    VisibleParts.planningScaleSelector,
  ];

  constructor(
    gridService: GridService,
    cdr: ChangeDetectorRef,
    public projectResourceService: ProjectResourceService,
    public projectResourceDataService: ProjectResourceDataService,
  ) {
    super(gridService, cdr);
  }
}
