import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { Timesheet } from 'src/app/shared/models/entities/base/timesheet.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { TranslateService } from '@ngx-translate/core';
import { sortBy } from 'lodash';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { StateService } from '@uirouter/core';
import { MessageService } from 'src/app/core/message.service';
import { NgbDropdown } from '@ng-bootstrap/ng-bootstrap';
import { TimesheetCardService } from '../core/timesheet-card.service';
import { Title } from '@angular/platform-browser';
import { NavigationService } from 'src/app/core/navigation.service';
import { DateTime, Interval } from 'luxon';
import { HistoryService } from 'src/app/core/history.service';

@Component({
  selector: 'wp-timesheet-header',
  templateUrl: './timesheet-header.component.html',
  styleUrls: ['./timesheet-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimesheetHeaderComponent implements OnChanges {
  @Input() timesheet: Timesheet;

  @Input()
  set name(value: string) {
    if (!value) {
      return;
    }
    this._name = value;
    this.title.setTitle(value);
    this.history.addHistory([this.name], this.navigation.getAppName());
  }

  get name(): string {
    return this._name;
  }

  public header = '';
  private _name: string;

  @ViewChild('dropdown') dropdown: NgbDropdown;

  /** Список загруженных периодов для выбора через меню. */
  public periods: any[];

  /** Признак загрузки списка периодов. */
  public loading: boolean;

  constructor(
    private changeDetector: ChangeDetectorRef,
    private navigation: NavigationService,
    private state: StateService,
    private data: DataService,
    public blockUI: BlockUIService,
    private translate: TranslateService,
    private notification: NotificationService,
    private title: Title,
    private history: HistoryService,
    private message: MessageService,
    public timesheetCardService: TimesheetCardService,
  ) {}

  public previous() {
    this.blockUI.start();

    // Получаем ИД последующего таймшита.
    this.data
      .collection('TimeSheets')
      .entity(this.timesheet.id)
      .function('WP.GetPreviousTimeSheetId')
      .get<string>()
      .subscribe({
        next: (id) => {
          this.blockUI.stop();
          this.state.go('timesheet', { entityId: id });
        },
        error: (error: Exception) => {
          this.blockUI.stop();
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.message.errorLocal(
              'timesheets.card.header.previousPeriodNotFound',
            );
          } else {
            this.message.error(error.message);
          }
        },
      });
  }

  public next() {
    this.blockUI.start();

    // Получаем ИД последующего таймшита.
    this.data
      .collection('TimeSheets')
      .entity(this.timesheet.id)
      .function('WP.GetNextTimeSheetId')
      .get<string>()
      .subscribe({
        next: (id) => {
          this.blockUI.stop();
          this.state.go('timesheet', { entityId: id });
        },
        error: (error: Exception) => {
          this.blockUI.stop();
          if (error.code === Exception.BtEntityNotFoundException.code) {
            this.message.errorLocal(
              'timesheets.card.header.nextPeriodNotFound',
            );
          } else {
            this.message.error(error.message);
          }
        },
      });
  }

  public setPeriod(period: any) {
    if (period.isCurrent) {
      return;
    }

    // Получаем ИД таймшита по дате.
    this.data
      .collection('TimeSheets')
      .function('WP.GetIdForPeriod')
      .get<string>({
        date: period.from,
        userId: this.timesheet.user.id,
      })
      .subscribe({
        next: (id) => {
          this.blockUI.stop();
          this.state.go('timesheet', {
            entityId: id,
            navigation: 'my.timesheetsAll',
          });
        },
        error: (error: Exception) => {
          this.blockUI.stop();
          this.message.error(error.message);
        },
      });
  }

  public openPeriodSelector() {
    if (!this.periods) {
      this.loading = true;
      this.periods = [];

      this.data
        .collection('TimeSheets')
        .entity(this.timesheet.id)
        .function('WP.GetSurroundingDatePeriods')
        .query()
        .subscribe({
          next: (data: any[]) => {
            this.periods = data;

            this.periods.push({
              from: this.timesheet.dateFrom,
              to: this.timesheet.dateTo,
              isCurrent: true,
              hint: this.translate.instant(
                'timesheet.card.header.periodCurrentHint',
              ),
              css: ['period-selector-current-timesheet'],
            });

            this.periods = sortBy(this.periods, 'from');

            this.periods.forEach((period) => {
              const interval = Interval.fromDateTimes(
                DateTime.fromISO(period.from).startOf('day'),
                DateTime.fromISO(period.to).endOf('day'),
              );
              if (interval.contains(DateTime.now())) {
                if (!period.css) {
                  period.css = [];
                }
                period.hint = this.translate.instant(
                  'timesheet.card.header.periodCurrentTimeSheetHint',
                );
                period.css.push('period-selector-current-period');
              }
            });
            this.changeDetector.markForCheck();
            this.loading = false;
          },
          error: (error: Exception) => {
            this.changeDetector.markForCheck();
            this.dropdown.close();
            this.periods = null;
            this.loading = false;
            this.notification.error(error.message);
          },
        });
    }
  }

  ngOnChanges(): void {
    this.periods = null;
    if (!this.timesheet) {
      return;
    }
    this.header = `${DateTime.fromISO(this.timesheet.dateFrom).toLocaleString(
      DateTime.DATE_FULL,
    )} – ${DateTime.fromISO(this.timesheet.dateTo).toLocaleString(
      DateTime.DATE_FULL,
    )}`;
  }
}
