import {
  Component,
  OnInit,
  forwardRef,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
  OnDestroy,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { Observable, isObservable, Subscription } from 'rxjs';
import { ProjectVersionsService } from 'src/app/projects/project-versions/project-versions.service';
import { debounceTime } from 'rxjs/operators';
import { Guid } from 'src/app/shared/helpers/guid';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { isFunction } from 'lodash';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';

/** Represents the Project version select control. */
@Component({
  selector: 'wp-project-versions-box',
  templateUrl: './project-versions-box.component.html',
  styleUrls: ['./project-versions-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => ProjectVersionsBoxComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class ProjectVersionsBoxComponent
  implements OnInit, OnDestroy, ControlValueAccessor
{
  @ViewChild('input') inputEl: ElementRef;

  /** Determines whether null values are allowed or not. */
  @Input() allowNull = true;

  /** The text displayed when the control value is not populated. */
  @Input() placeholder: string;

  /** Determines whether the control value is readonly or not. */
  @Input() readonly: boolean;

  /** The control values collection, function or Observable. The collection priority is the lowest. */
  @Input() values?: ProjectVersion[] = null;

  /** The "Work" default Project version to show in control values. */
  @Input() workProjectVersion: ProjectVersion;

  /** Determines whether the Create version button is disabled or not. */
  @Input() createVersionButtonDisabled?: boolean = false;

  private _value: ProjectVersion = null;
  set value(obj: ProjectVersion) {
    this._value = obj;
    this.setTextControlValue(obj);
    this.textControl.markAsPristine();
  }
  get value(): ProjectVersion {
    return this._value;
  }

  rows: ProjectVersion[] = [];
  listOpened = false;
  isLoading = false;
  loadedPartly = false;
  projectVisible = true;
  projectVersionLabel = '';
  selectedRow: ProjectVersion;
  textControl = new UntypedFormControl('');
  private searchListener: Subscription;
  storedRows: ProjectVersion[] = null;

  areaId: string = Guid.generate();
  excludeOutsideClick = `#${StringHelper.toUnicode(this.areaId)}`;

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  constructor(
    private scrollToService: ScrollToService,
    private element: ElementRef,
    private ref: ChangeDetectorRef,
    private translate: TranslateService,
    public versionService: ProjectVersionsService,
  ) {
    this.projectVersionLabel = this.translate.instant(
      'acts.card.mainProps.project',
    );
  }

  ngOnInit() {
    this.searchListener = this.textControl.valueChanges
      .pipe(debounceTime(500))
      .subscribe(() => {
        if (!this.listOpened) {
          this.openList();
        }
        this.refreshRows();
      });
  }

  ngOnDestroy(): void {
    this.searchListener.unsubscribe();
  }

  /**
   * Gets the state circle class.
   *
   * @param value The control value.
   * */
  public getStateCircleClass(value: ProjectVersion) {
    if (!value?.state) {
      return 'bi-house-door';
    }

    let circleClass = !value.masterBaseline
      ? 'bi-circle-fill'
      : 'bi-check-circle-fill';
    circleClass += ' text-' + value.state.style;

    return circleClass;
  }

  /**
   * Gets the state badge class.
   *
   * @param row The control values row.
   * */
  public getStateBadgeClass(row: ProjectVersion) {
    return row?.state ? 'text-bg-' + row.state?.style : '';
  }

  /**
   * Opens the modal dialog to create the Project version with the selected source.
   *
   * @param value The Project version selected in control values.
   * */
  createProjectVersion(value: ProjectVersion): void {
    this.versionService.createVersion(value);
    this.listOpened = false;
  }

  /**
   * Writes the value to the control.
   *
   * @param value The control value.
   * */
  writeValue(value: ProjectVersion): void {
    this.value = value;
    this.ref.detectChanges();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /**
   * Sets the control state to readonly/editable depending on the state passed.
   *
   * @param isDisabled The new control state.
   * */
  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.ref.detectChanges();
  }

  /**
   * Loads the control values using the input passed.
   * If input is a function, calls the respective one.
   * If input is an Observable, subscribes to the respective one.
   *
   * @returns The Observable of the control values.
   * */
  loadStoredRows(): Observable<ProjectVersion[]> {
    return new Observable<ProjectVersion[]>((subscribe) => {
      // Use local values, loading is not needed.
      if (this.values && !isObservable(this.values)) {
        const values = isFunction(this.values) ? this.values() : this.values;
        subscribe.next(values);
        return;
      }

      if (this.values && isObservable(this.values)) {
        this.isLoading = true;
        this.ref.detectChanges();
        (this.values as Observable<any>).subscribe((data) => {
          this.isLoading = false;
          this.loadedPartly = false;
          subscribe.next(data);
        });
        return;
      }
    });
  }

  /**
   * Refreshes the control values.
   * */
  refreshRows() {
    this.loadStoredRows().subscribe((data: ProjectVersion[]) => {
      data.sort((a, b) => {
        if (a.masterBaseline) {
          return -1;
        }
        if (b.masterBaseline) {
          return 1;
        }

        const dateA = new Date(a.created);
        const dateB = new Date(b.created);

        return dateA.getTime() - dateB.getTime();
      });
      this.storedRows = data;

      if (this.textControl.dirty && this.textControl.value.trim()) {
        const filteringValue = this.textControl.value.toLowerCase().trim();
        this.rows = this.storedRows.filter(
          (row: ProjectVersion) =>
            row.name.toLowerCase().indexOf(filteringValue) !== -1,
        );
        this.projectVisible =
          this.projectVersionLabel.toLowerCase().indexOf(filteringValue) !== -1;
      } else {
        this.projectVisible = true;
        this.rows = Object.assign([], this.storedRows);
      }

      if (this.value) {
        this.selectRow(this.rows.find((row) => row.id === this.value.id));
      }

      this.ref.detectChanges();

      this.scrollToSelectRow();
    });
  }

  /**
   * The control values row select handler.
   *
   * @param row The control values row.
   * */
  selectRow(row: ProjectVersion) {
    this.selectedRow = row;
  }

  /**
   * The control values row click handler.
   *
   * @param row The control values row.
   * */
  clickRow(row: ProjectVersion) {
    this.changeValue(row);
    this.closeList();
    this.ref.detectChanges();
  }

  /**
   * The control input keydown handler.
   *
   * @param event The keyboard event.
   * */
  onKeyDown(event: KeyboardEvent) {
    const escCode = 27;
    const enterCode = 13;
    const downCode = 40;
    const upCode = 38;

    if (event.keyCode === escCode) {
      this.cancel();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.keyCode === enterCode) {
      // Clear the selection if the text has been cleared, but there is no active row.
      if (!this.textControl.value && !this.selectedRow) {
        if (this.allowNull) {
          this.changeValue(null);
        }
        this.cancel();
        return;
      }

      if (this.selectedRow) {
        this.clickRow(this.selectedRow);
      }
    }

    if (event.keyCode === downCode) {
      this.selectNext();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.keyCode === upCode) {
      this.selectPrevious();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  /**
   * Selects the next control values row.
   * */
  selectNext() {
    if (!this.listOpened) {
      this.openList();
    }
    const index = this.rows.indexOf(this.selectedRow);

    if (
      index >= this.rows.length ||
      this.rows.length === 0 ||
      !this.selectedRow
    ) {
      this.selectedRow = this.workProjectVersion;
      return;
    }

    if (index < this.rows.length - 1) {
      this.selectedRow = this.rows[index + 1];
      this.scrollToSelectRow();
    }
  }

  /**
   * Selects the previous control values row.
   * */
  selectPrevious() {
    if (!this.listOpened) {
      this.openList();
    }

    const index = this.rows.indexOf(this.selectedRow);

    if (index <= 0 || this.rows.length === 0 || !this.selectedRow) {
      this.selectedRow = this.workProjectVersion;
      return;
    }

    this.selectedRow = this.rows[index - 1];
    this.scrollToSelectRow();
  }

  /**
   * Scrolls to the selected control values row.
   * */
  scrollToSelectRow() {
    if (this.selectedRow) {
      this.scrollToService.scrollTo(
        this.selectedRow.id,
        this.element.nativeElement,
      );
    }
  }

  /**
   * The control input click handler.
   * */
  onInputClick() {
    if (!this.listOpened) {
      this.openList();
    }
  }

  /**
   * The control input clear handler.
   * */
  clear() {
    this.changeValue(null);
    this.closeList();
  }

  /**
   * The control values list close handler.
   * */
  closeList() {
    this.listOpened = false;
    this.selectedRow = null;
  }

  /**
   * The control values list open handler.
   * */
  openList() {
    if (this.listOpened) {
      this.cancel();
      return;
    }

    this.selectedRow = null;
    this.inputEl.nativeElement.select();
    this.propagateTouch();
    this.listOpened = true;
    this.refreshRows();
  }

  /**
   * The control values list row selection cancel handler.
   * */
  cancel() {
    this.closeList();
    this.textControl.markAsPristine();

    if (this.textControl.value && this.value) {
      this.setTextControlValue(this.value);
    } else {
      if (this.allowNull) {
        this.changeValue(null);
      } else {
        // Do nothing if null values are forbidden and value is not selected yet.
        if (this.value) {
          this.setTextControlValue(this.value);
        }
      }
    }
  }

  /**
   * Sets the control input value.
   *
   * @param value The control value.
   * */
  private setTextControlValue(value: ProjectVersion) {
    this.textControl.setValue(
      value.state ? value.name : this.projectVersionLabel,
      { emitEvent: false },
    );
  }

  /**
   * Changes the control input value.
   *
   * @param value The control value.
   * */
  private changeValue(value: ProjectVersion) {
    if (
      !(
        this._value === value ||
        (this._value && value && this._value.id === value.id)
      )
    ) {
      this.propagateChange(value);
    }
    this.value = value;
  }
}
