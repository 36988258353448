import {
  Component,
  OnInit,
  Inject,
  DestroyRef,
  inject,
  signal,
} from '@angular/core';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { ProjectFilterService } from 'src/app/projects/list/project-filter/project-filter.service';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { PROJECT_LIST } from 'src/app/shared/lists/project.list';
import { ClientCardService } from '../client-card.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, Validators } from '@angular/forms';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { tap } from 'rxjs';

@Component({
  selector: 'tmt-client-legal',
  templateUrl: './client-legal.component.html',
  styleUrls: ['./client-legal.component.scss'],
  providers: [
    { provide: VIEW_NAME, useValue: 'client' },
    { provide: LIST, useValue: PROJECT_LIST },
    { provide: FilterService, useClass: ProjectFilterService },
    EntityListService,
    ListService,
    GridService,
    SavingQueueService,
  ],
  standalone: false,
})
export class ClientLegalComponent implements OnInit {
  private destroyRef = inject(DestroyRef);
  public loading = signal(false);
  public isNotSavedIndicator = signal(false);

  public readonly propagationMode = PropagationMode;

  public form = this.fb.group({
    name: null,
    taxNumber: [
      null,
      [
        Validators.minLength(10),
        Validators.maxLength(10),
        Validators.pattern('^[0-9]*$'),
      ],
    ],
    registrationNumber: null,
    headcount: null,
    income: null,
    lastAutoUpdated: null,
    additionalInformation: null,
  });
  isNewEntity: boolean;
  collection = this.dataService.collection('OrganizationLegal');

  constructor(
    @Inject('entityId') public entityId: string,
    private savingQueueService: SavingQueueService,
    private dataService: DataService,
    private fb: FormBuilder,
    private service: ClientCardService,
    private notification: NotificationService,
  ) {
    this.load();
  }

  ngOnInit() {
    this.service.reloadTab$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.load());

    const taxNumberControl = this.form.controls['taxNumber'];
    this.form.controls['taxNumber'].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.savingQueueService.addToQueue(
          this.entityId,
          this.collection
            .entity(this.entityId)
            .patch({ taxNumber: taxNumberControl.value })
            .pipe(
              tap(() => {
                if (this.form.valid) {
                  this.form.markAsPristine();
                }
              }),
            ),
        );
      });
  }

  /** Updates legal information via Dadata service by Tax number. */
  public updateViaDadata() {
    if (this.form.invalid) {
      return;
    }

    this.savingQueueService.save().then(() => {
      this.collection
        .entity(this.entityId)
        .action('UpdateViaDadata')
        .execute()
        .subscribe({
          next: () => {
            this.load();
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
          },
        });
    });
  }

  private load() {
    this.loading.set(true);
    this.savingQueueService.save().then(() => {
      if (this.form.invalid) {
        this.isNotSavedIndicator.set(true);
        return;
      }
      this.isNotSavedIndicator.set(false);

      this.collection
        .entity(this.entityId)
        .get()
        .pipe(takeUntilDestroyed(this.destroyRef))
        .subscribe({
          next: (legal: any) => {
            this.form.patchValue(legal as any, { emitEvent: false });
            this.loading.set(false);
          },
          error: (error: Exception) => {
            if (error.code == Exception.BtEntityNotFoundException.code) {
              this.isNewEntity = true;
            } else {
              this.notification.error(error.message);
            }
            this.loading.set(false);
          },
        });
    });
  }
}
