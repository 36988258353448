<div
  wpFreezeTable
  [afterViewInitInitialization]="!!options.rightSideComponent || !!options.toolbar"
  [leftWidthStrategy]="options.resizableColumns ? 'byTable' : 'byContainer'"
  [ngClass]="{ 'resizable-columns': options.resizableColumns }"
>
  <ng-template *ngComponentOutlet="options.toolbar; inputs: toolbarInputs"></ng-template>

  <div name="left" [ngClass]="{ 'position-absolute': options.rightSideComponent }" #leftTable>
    <div
      name="scroll-table-header"
      [ngClass]="{ 'overflow-visible': options.rightSideComponent }"
      tabindex="0"
    >
      <table
        class="table"
        name="header"
        [ngClass]="options.css ?? 'wp-nested-table'"
        wpResizing
        [selector]="'th[data-resizable-column]'"
        (columnResized)="onColumnResized($event)"
      >
        <colgroup>
          @if (isShowMultiSelectColumn) {
            <col class="selector" />
          }
          @for (column of options.view.columns; track column.name) {
            <col [ngStyle]="{ width: column.width }" />
          }
          @if (options.rowCommands) {
            <col class="menu-cell" />
          }
        </colgroup>
        <thead name="scroll-header">
          <tr>
            @if (isShowMultiSelectColumn) {
              <th
                class="checkbox overall-cell"
                [ngClass]="{
                  'selected-cell': service.isAllSelected
                }"
                (click)="switchAllSelection()"
              >
                <div class="wp-checkbox">
                  <label>
                    <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                  </label>
                </div>
              </th>
            }

            @for (column of options.view.columns; track column.name) {
              <th
                data-resizable-column="true"
                class="trim"
                [ngClass]="getHeaderCellClasses(column)"
                [attr.name]="column.name"
                (click)="sort(column)"
                title="{{ column.hint | translate }}"
              >
                @if (column.icon) {
                  <span [ngClass]="column.icon"></span>
                } @else {
                  {{ column.header | translate }}
                }
              </th>
            }

            @if (options.rowCommands) {
              <th class="menu-cell"></th>
            }
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body" (clickOutside)="onTableOutsideClick()">
      <table name="body" class="table" [ngClass]="options.css ?? 'wp-nested-table'">
        @if (service.loading$ | async) {
          <tr>
            <td class="p-0" [attr.colspan]="getColumnsCount()">
              <wp-loading-indicator class="h-0 p-0" [loading]="service.loading$ | async" />
            </td>
          </tr>
        }

        <colgroup>
          @if (isShowMultiSelectColumn) {
            <col class="selector" />
          }
          @for (column of options.view.columns; track column.name) {
            <col [ngStyle]="{ width: column.width }" />
          }
          @if (options.rowCommands) {
            <col class="menu-cell" />
          }
        </colgroup>

        <tbody>
          @for (formGroup of formArray?.controls; track formGroup.value.id; let rowIndex = $index) {
            <tr id="{{ formGroup.value.id }}" name="grid-row" tabindex="1">
              @if (isShowMultiSelectColumn) {
                <td
                  class="checkbox"
                  [ngClass]="{
                    'selected-cell': cellOrchestratorService.selectedGroups.includes(formGroup)
                  }"
                >
                  <div
                    class="wp-checkbox"
                    (click)="cellOrchestratorService.switchGroupSelection(formGroup)"
                  >
                    <label>
                      <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                    </label>
                  </div>
                </td>
              }

              @for (column of options.view.columns; track column.name; let colIndex = $index) {
                <td
                  tmt-grid-cell
                  tmtGridSelectionCell
                  [attr.column-name]="column.name"
                  [column]="column"
                  [group]="formGroup"
                  [selectionType]="options.selectionType"
                  [editing]="isEditing(column, formGroup)"
                  (contextmenu)="onCellRightBtnClick(formGroup, $event, column)"
                  [ngClass]="
                    cellOrchestratorService.getGridCellClasses(
                      formGroup,
                      column,
                      rowIndex,
                      colIndex
                    )
                  "
                  [ngbTooltip]="
                    validationService.getErrorTooltip(formGroup.controls[column.name].errors)
                  "
                  placement="left auto"
                  container="body"
                  tooltipClass="error-tooltip"
                ></td>
              }

              @if (options.rowCommands) {
                <td
                  class="menu-cell control-cell"
                  #dropDown="ngbDropdown"
                  ngbDropdown
                  [popperOptions]="popperOptions"
                  (onIntersect)="closeDropdown(dropDown)"
                  (click)="service.selectGroup(formGroup)"
                  [ngClass]="{
                    'selected-cell': cellOrchestratorService.selectedGroups.includes(formGroup)
                  }"
                >
                  @if (rowHasCommands(formGroup, rowIndex)) {
                    <button
                      type="button"
                      class="btn btn-default"
                      ngbDropdownAnchor
                      (click)="openMenu(formGroup.value.id, dropDown)"
                    >
                      <i class="bi bi-three-dots" aria-hidden="true"></i>
                    </button>
                    @if (rowsWithCommands[formGroup.value.id]) {
                      <div ngbDropdownMenu>
                        @for (command of options.rowCommands; track command.name) {
                          <button
                            type="button"
                            ngbDropdownItem
                            (click)="command.handlerFn(formGroup, rowIndex)"
                            [hidden]="command.allowedFn && !command.allowedFn(formGroup, rowIndex)"
                            [attr.data-test]="command.name"
                          >
                            {{ command.label | translate }}
                          </button>
                        }
                      </div>
                    }
                  }
                </td>
              }
            </tr>
          }
          @if (!(service.loading$ | async) && !formArray?.controls.length) {
            <tr>
              <td
                [attr.colspan]="getColumnsCount()"
                style="text-align: center"
                class="text-body-secondary"
              >
                {{ 'shared.noDisplayData' | translate }}
              </td>
            </tr>
          }
        </tbody>
      </table>
      <div class="end-of-track"></div>
    </div>
    <div name="scroll-table-footer" [ngClass]="{ 'overflow-visible': options.rightSideComponent }">
      @if (totals) {
        <table name="footer" class="table" [ngClass]="options.css ?? 'wp-nested-table'">
          <colgroup>
            @if (isShowMultiSelectColumn) {
              <col class="selector" />
            }
            @for (column of options.view.columns; track column.name) {
              <col [ngStyle]="{ width: column.width }" />
            }
            @if (options.rowCommands) {
              <col class="menu-cell" />
            }
          </colgroup>
          <tfoot name="scroll-footer">
            <tr>
              @if (isShowMultiSelectColumn) {
                <td></td>
              }
              @for (column of options.view.columns; track column.name) {
                @if (column.total) {
                  <td tmt-grid-total-cell [column]="column" [value]="totals[column.name]"></td>
                } @else {
                  <td></td>
                }
              }
              @if (options.rowCommands) {
                <td class="menu-cell"></td>
              }
            </tr>
          </tfoot>
        </table>
      }
    </div>
  </div>

  <ng-template *ngComponentOutlet="options.rightSideComponent"></ng-template>
</div>
