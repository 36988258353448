import { Injectable } from '@angular/core';
import {
  UserSettings,
  SortOrder,
} from 'src/app/analytics/shared/models/user-settings.model';
import { BehaviorSubject, Subject, Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { Filter } from 'src/app/analytics/shared/models/filter/filter-model';
import { DataService } from 'src/app/core/data.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import _ from 'lodash';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { cloneDeep, isEqual } from 'lodash';
import { ReportPeriodService } from 'src/app/analytics/shared/report-period/report-period.service';

@Injectable()
export class ReportUserSettingsService {
  public userSettings: UserSettings;
  reportId: string;

  private userSettingsSubject = new BehaviorSubject<UserSettings>(null);
  public userSettings$ = this.userSettingsSubject
    .asObservable()
    .pipe(filter((p) => !!p));

  public changes$ = new Subject<void>();
  public openPeriodArea$ = new Subject<void>();
  periodSubscription: Subscription;

  constructor(
    private autosave: AutosaveService,
    private data: DataService,
    private reportPeriodService: ReportPeriodService,
  ) {
    this.autosave.delayForSave = 1000;

    this.autosave.builderFn = () => ({
      userSettings: JSON.stringify(this.userSettings),
    });

    this.autosave.savingFn = (obj: any) =>
      this.data
        .collection('Reports')
        .entity(this.reportId)
        .action('UpdateUserSettings')
        .execute(obj);
  }

  public save(): Promise<void> {
    return this.autosave.save();
  }

  public init(reportId: string, userSettings: UserSettings) {
    this.userSettings = userSettings;

    if (!this.userSettings.filters) {
      this.userSettings.filters = [];
    }

    if (this.userSettings.period) {
      const camelCasePeriod = {};
      Object.keys(this.userSettings.period).forEach((key) => {
        camelCasePeriod[_.camelCase(key)] = this.userSettings.period[key];
      });
      this.userSettings.period = camelCasePeriod;
    } else {
      this.userSettings.period = null;
    }

    this.userSettingsSubject.next(userSettings);
    this.reportId = reportId;

    if (this.periodSubscription) {
      this.periodSubscription.unsubscribe();
    }

    this.reportPeriodService.setPeriod(this.userSettings.period);

    this.periodSubscription = this.reportPeriodService.period$.subscribe(
      (period) => {
        this.saveDatePeriod(period);
      },
    );
  }

  /** Сохранить новый период. */
  public saveDatePeriod(period: DatePeriod) {
    if (isEqual(period, this.userSettings.period)) {
      return;
    }

    this.userSettings.period = period
      ? {
          // eslint-disable-next-line @typescript-eslint/naming-convention
          PeriodType: period.periodType,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          From: period.from,
          // eslint-disable-next-line @typescript-eslint/naming-convention
          To: period.to,
        }
      : null;
    this.autosave.saveLazy();

    this.changes$.next();
  }

  /** Сохранить новый период. */
  public saveSorting(sorting: Dictionary<SortOrder>) {
    this.userSettings.sorting = cloneDeep(sorting);
    this.autosave.saveLazy();
  }

  /** Сохранить новые фильтры. */
  public saveFilters(filters: Filter[]) {
    this.userSettings.filters = filters;
    this.autosave.saveLazy();
    this.changes$.next();
  }

  public resizeColumn(name: string, width: number) {
    if (!this.userSettings.columns) {
      this.userSettings.columns = {};
    }
    this.userSettings.columns[name] = width;
    this.autosave.saveLazy();
  }

  public openPeriodArea() {
    this.openPeriodArea$.next();
  }
}
