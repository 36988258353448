import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { EntityType } from '../../../../../shared-features/resource-roles/enums/entity-type.enum';
import { Dictionary } from '../../../../../shared/models/dictionary';
import { Observable, Subscription } from 'rxjs';
import { AppService } from '../../../../../core/app.service';
import { UntypedFormBuilder } from '@angular/forms';
import { NotificationService } from '../../../../../core/notification.service';
import { DataService } from '../../../../../core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { sortBy } from 'lodash';
import { Exception } from '../../../../../shared/models/exception';
import { User } from '../../../../../shared/models/entities/settings/user.model';
import { switchMap } from 'rxjs/operators';
import { Group } from '../../../../../shared/models/entities/settings/group.model';
import { Constants } from '../../../../../shared/globals/constants';

@Component({
  selector: 'tmt-adding-resource-group-modal',
  templateUrl: './adding-resource-group-modal.component.html',
  styleUrls: ['./adding-resource-group-modal.component.scss'],
  standalone: false,
})
export class AddingResourceGroupModalComponent implements OnInit {
  public loadLimit = 250;
  /** Entity for which groups are selected. */
  @Input() entityType: EntityType;
  /** Id of the entity for which the groups are selected. */
  @Input() entityId: string;

  /** IDs of previously added roles. */
  @Input() alreadyAddedIds: string[];
  @ViewChild('leftTbl') leftTbl: ElementRef;

  public isSaving: boolean;
  public isLoading: boolean;
  public loadedPartly: boolean;

  public leftTableStyles: Dictionary<string> = {};

  public availableGroups: Group[] = [];
  public selectedGroups: Group[] = [];

  public filterForm = this.fb.group({
    name: [''],
  });
  private loadingSubscription: Subscription;

  constructor(
    public app: AppService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.load();
    this.filterForm.valueChanges.subscribe(() => this.load());
  }

  /** Method that close modal on cancel button. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  /** Method that resize left table. */
  public resizeLeftTbl() {
    this.leftTableStyles['display'] = 'table';
    this.leftTableStyles['width'] =
      (<HTMLElement>this.leftTbl.nativeElement).getBoundingClientRect().width +
      'px';
  }

  /** Method that select group. */
  public selectGroup(group: Group, index: number) {
    this.selectedGroups.push(group);
    this.selectedGroups = sortBy(this.selectedGroups, ['name']);
    this.availableGroups.splice(index, 1);
  }

  /** Method that remove group. */
  public removeGroup(group: Group, index: number) {
    this.availableGroups.push(group);
    this.selectedGroups.splice(index, 1);
    this.availableGroups = sortBy(this.availableGroups, ['name']);
  }

  /** Method that close modal on ok button. */
  public ok() {
    this.activeModal.close(this.selectedGroups);
  }

  private load() {
    this.availableGroups = [];
    this.isLoading = true;
    this.loadedPartly = false;

    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }

    this.loadingSubscription = this.loadGroups().subscribe({
      next: (data) => {
        data = data.filter(
          (group) =>
            this.selectedGroups.find((g) => g.id === group.id) == null &&
            this.alreadyAddedIds.find((id) => id === group.id) == null,
        );
        this.availableGroups = data;
        this.isLoading = false;
        this.loadedPartly = this.availableGroups.length === this.loadLimit;
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isLoading = false;
      },
    });
  }

  private loadGroups(): Observable<Group[]> {
    const groupsQuery = {
      orderBy: ['name'],
      filter: <any[]>[{ isActive: true }],
    };
    const defaultFilter = this.filterForm.value;
    if (defaultFilter.name) {
      const name = (defaultFilter.name as string)
        .substring(0, Constants.filterInputLengthLimit)
        .toLowerCase();
      groupsQuery.filter.push(
        // eslint-disable-next-line @typescript-eslint/naming-convention
        { 'tolower(name)': { contains: name } },
      );
    }
    return this.data
      .collection('Users')
      .entity(this.entityId)
      .get<User>()
      .pipe(
        switchMap(() =>
          this.data.collection('Groups').query<Group[]>(groupsQuery),
        ),
      );
  }
}
