import {
  Component,
  Input,
  Inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { SystemDirectory } from 'src/app/shared/models/enums/system-directory.enum';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-deal-list-filter',
  templateUrl: './deal-list-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DealListFilterComponent implements FilterDetails {
  @Input() public values: any;

  public detailsForm = this.fb.group({
    manager: null,
    organization: null,
    source: null,
    resolution: null,
    states: null,
  });

  protected readonly systemDirectory = SystemDirectory;

  constructor(
    @Inject(VIEW_NAME) public viewName: string,
    public readonly filterService: FilterService,
    private readonly fb: UntypedFormBuilder,
  ) {
    this.filterService.resetValues$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.filterService.values);
    this.detailsForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      const values = Object.assign(
        this.filterService.values,
        this.detailsForm.value,
      );
      this.filterService.changeValues(values);
    });
  }
}
