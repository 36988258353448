import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { SharedModule } from 'src/app/shared/shared.module';
import { ProjectCardSharedModule } from '../../shared/project-card-shared.module';
import { TaskCardModalComponent } from './task-card-modal/task-card-modal.component';
import { TasksViewSwitcherComponent } from './tasks-view-switcher/tasks-view-switcher.component';
import { TaskAssignmentsComponent } from './task-card-modal/task-assignments/task-assignments.component';
import { AssignmentTableComponent } from './task-card-modal/task-assignments/assignments-table/assignments-table.component';
import { TasksViewFormComponent } from 'src/app/projects/card/project-tasks/shared/tasks-view-form/tasks-view-form.component';
import { TaskCollapsibleCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-collapsible-cell/task-collapsible-cell.component';
import { TaskCollapsibleReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-collapsible-cell/task-collapsible-readonly-cell.component';
import { TaskDateCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-date-cell/task-date-cell.component';
import { TaskDateReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-date-cell/task-date-readonly-cell.component';
import { TaskDurationCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-duration-cell/task-duration-cell.component';
import { TaskDurationReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-duration-cell/task-duration-readonly-cell.component';
import { TaskNameCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-name-cell/task-name-cell.component';
import { TaskNameReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-name-cell/task-name-readonly-cell.component';
import { TaskPredecessorCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-predecessor-cell/task-predecessor-cell.component';
import { TaskPredecessorReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-predecessor-cell/task-predecessor-readonly-cell.component';
import { TasksGridComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/tasks-grid.component';
import { TasksReportModalComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/tasks-report-modal/tasks-report-modal.component';
import { TasksToolbarComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/tasks-toolbar/tasks-toolbar.component';
import { TaskTimelineRightGroupComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/task-timeline-right-group/task-timeline-right-group.component';
import { TimelineEntryComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/task-timeline-right-group/timeline-entry/timeline-entry.component';
import { TimelineRightSideComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/timeline-right-side.component';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { ScheduleNavigationModule } from 'src/app/shared-features/schedule-navigation/schedule-navigation.module';
import { UndoRedoButtonsComponent } from 'src/app/shared/services/undo-redo/undo-redo-buttons/undo-redo-buttons.component';
import { UIRouterModule } from '@uirouter/angular';
import { CheckboxComponent } from 'src/app/shared/components/controls/checkbox/checkbox.component';

@NgModule({
  declarations: [
    TasksViewSwitcherComponent,
    TaskCardModalComponent,
    TaskAssignmentsComponent,
    AssignmentTableComponent,
    TasksViewFormComponent,
    TasksGridComponent,
    TasksToolbarComponent,
    TaskNameCellComponent,
    TaskNameReadonlyCellComponent,
    TaskDateCellComponent,
    TaskDateReadonlyCellComponent,
    TaskCollapsibleCellComponent,
    TaskCollapsibleReadonlyCellComponent,
    TasksReportModalComponent,
    TaskDurationCellComponent,
    TaskDurationReadonlyCellComponent,
    TaskPredecessorCellComponent,
    TaskPredecessorReadonlyCellComponent,
    TimelineRightSideComponent,
    TaskTimelineRightGroupComponent,
    TimelineEntryComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    ProjectCardSharedModule,
    GridModule,
    UndoRedoButtonsComponent,
    ScheduleNavigationModule,
    UIRouterModule,
    CheckboxComponent,
  ],
  providers: [],
  exports: [
    TasksViewSwitcherComponent,
    TaskCardModalComponent,
    TasksViewFormComponent,
  ],
})
export class ProjectTaskSharedModule {}
