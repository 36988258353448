import {
  Directive,
  ElementRef,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { debounce } from 'lodash';

@Directive({
  selector: '[wpIntersectable]',
  standalone: false,
})
export class IntersectableDirective implements OnInit {
  @Output() onIntersect = new EventEmitter();
  private intersectionObservable: IntersectionObserver;
  private target;
  private scrollTableHeader;
  private scrollTableFooter;

  public initObservable() {
    if (this.intersectionObservable) {
      this.intersectionObservable.disconnect();
    }

    if (!this.scrollTableHeader && !this.scrollTableFooter) {
      return;
    }

    const topMargin = this.scrollTableHeader
      ? this.scrollTableHeader.getBoundingClientRect().top +
        this.scrollTableHeader.getBoundingClientRect().height
      : 0;

    const bottomMargin = this.scrollTableFooter
      ? this.scrollTableFooter.getBoundingClientRect().height
      : 0;
    const options = {
      threshold: 0.5,
      rootMargin: `${-topMargin}px 0px ${-bottomMargin}px 0px`,
    };

    const callback = (entries) => {
      if (!entries[0].isIntersecting) {
        this.onIntersect.emit();
      }
    };
    const debounceFn = debounce(callback, 40);
    this.intersectionObservable = new IntersectionObserver(debounceFn, options);
    this.intersectionObservable.observe(this.target);
  }
  constructor(private elementRef: ElementRef) {}

  ngOnInit(): void {
    this.target = this.elementRef.nativeElement;
    const currentGrid =
      this.elementRef.nativeElement.closest('[wpfreezetable]');
    if (!currentGrid) {
      return;
    }
    this.scrollTableHeader = currentGrid.querySelector(
      '[name="scroll-header"]',
    );
    this.scrollTableFooter = currentGrid.querySelector(
      '[name="scroll-table-footer"]',
    );
    this.initObservable();
  }
}
