import {
  Component,
  OnInit,
  DestroyRef,
  inject,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { IssueResolutionCardService } from 'src/app/settings-app/issue-resolutions/card/issue-resolution-card.service';
import { IssueResolution } from 'src/app/settings-app/issue-resolutions/model/issue-resolution.model';

@Component({
  selector: 'tmt-issue-resolution-card',
  templateUrl: './issue-resolution-card.component.html',
  styleUrl: './issue-resolution-card.component.scss',
  providers: [IssueResolutionCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IssueResolutionCardComponent implements OnInit {
  public readonly = !this.appService.checkEntityPermission(
    'IssueResolutions',
    PermissionType.Modify,
  );

  private destroyRef = inject(DestroyRef);

  constructor(
    public issueResolutionCardService: IssueResolutionCardService,
    private appService: AppService,
    private actionPanelService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.issueResolutionCardService.reload();
    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: !this.readonly,
        handler: () => this.issueResolutionCardService.save(),
      },
    ]);

    this.issueResolutionCardService.form.controls['name'].valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.issueResolutionCardService.updateName(value);
      });

    this.actionPanelService.action('save').isShown = !this.readonly;

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.issueResolutionCardService.reload());

    this.issueResolutionCardService.issueResolution$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((issueResolution: IssueResolution) => {
        this.issueResolutionCardService.form.patchValue(issueResolution);
        this.readonly = !issueResolution.editAllowed;
        this.readonly
          ? this.issueResolutionCardService.form.disable()
          : this.issueResolutionCardService.form.enable();
      });
  }
}
