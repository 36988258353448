import { Component, Input } from '@angular/core';
import { OverviewKpiService } from '../core/overview-kpi.service';
import { KpiCardModel } from './kpi-card.model';
import { SpinnerParams } from 'src/app/shared/components/features/loading-indicator/loading-indicator.model';

@Component({
  selector: 'wp-kpi-card',
  templateUrl: './kpi-card.component.html',
  styleUrls: ['./kpi-card.component.scss'],
  standalone: false,
})
export class KpiCardComponent {
  @Input() model: KpiCardModel;

  public readonly spinnerParams: SpinnerParams = {
    spinnerBackgroundColor: '--bs-light',
    spinnerBorderRadius: '--bs-border-radius',
  };

  constructor(public overviewKpiService: OverviewKpiService) {}
}
