import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ClientCardService } from '../client-card.service';
import { PnlService } from 'src/app/shared-features/pnl/pnl.service';

@Component({
  selector: 'wp-client-pnl',
  templateUrl: './client-pnl.component.html',
  providers: [PnlService],
  standalone: false,
})
export class ClientPnlComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  private reloadListener: Subscription;

  constructor(
    private pnlService: PnlService,
    private service: ClientCardService,
  ) {}

  ngOnInit(): void {
    this.reloadListener = this.service.reloadTab$.subscribe(() => {
      this.pnlService.reload();
    });
  }

  ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
  }
}
