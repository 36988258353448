import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export interface AccordionItem {
  name: string;
  expanded: boolean;
}

export class AccordionStateSettings implements BaseSettings {
  public readonly name = 'AccordionStateSettings';
  version: number;
  accordionItems: AccordionItem[];

  getDefault(): AccordionStateSettings {
    return {
      version: 1,
      accordionItems: [],
    } as AccordionStateSettings;
  }
}
