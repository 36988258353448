import { Component } from '@angular/core';
import { ExpensesGroupType } from '../../../models/expenses-view.model';
import { ProjectExpensesCalendarGroupFilterService } from '../../core/project-expenses-calendar-group-filter.service';

@Component({
  selector: 'wp-project-expenses-calendar-group-filter',
  templateUrl: './project-expenses-calendar-group-filter.component.html',
  standalone: false,
})
export class ProjectExpensesCalendarGroupFilterComponent {
  constructor(public service: ProjectExpensesCalendarGroupFilterService) {}

  public onToggle(group: ExpensesGroupType) {
    this.service.toggleGroup(group);
  }
}
