import { Fragment, Node } from 'prosemirror-model';

export interface NodeChild {
  node: Node;
  offset: number;
  index: number;
}

/**
 * Gets node's children.
 * @param node parent node.
 * @returns children array.
 */
export function getChildrenOfNode(node: Node | Fragment): NodeChild[] {
  const children: NodeChild[] = [];
  node.forEach((node, offset, index) => children.push({ node, offset, index }));
  return children;
}

/**
 * Gets node's last child.
 * @param node parent node.
 * @returns child node.
 */
export function getLastChildOfNode(node: Node | Fragment): NodeChild {
  const children = getChildrenOfNode(node);
  return children[children.length - 1];
}
