import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { ListService } from 'src/app/shared/services/list.service';
import { TimesheetFilterService } from './filter/timesheet-filter.service';
import { StateService } from '@uirouter/core';
import { LifecycleListService } from 'src/app/core/lifecycle-list.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { firstValueFrom, map, tap } from 'rxjs';
import { DataService } from 'src/app/core/data.service';
import { LIST } from 'src/app/shared/tokens';
import { List } from 'src/app/shared/models/inner/list';
import { MassOperationComponent } from 'src/app/shared/components/mass-operation/mass-operation.component';
import _ from 'lodash';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { MenuAction } from 'src/app/shared/models/inner/menu-action';

@Component({
  selector: 'wp-timesheet-list',
  template: '<wp-entity-list></wp-entity-list>',
  providers: [
    { provide: FilterService, useClass: TimesheetFilterService },
    EntityListService,
    ListService,
    GridService,
    LifecycleListService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimesheetListComponent implements OnInit {
  private destroyRef = inject(DestroyRef);

  constructor(
    private app: AppService,
    private state: StateService,
    private gridService: GridService,
    private actions: ActionPanelService,
    private listService: EntityListService,
    private modalService: NgbModal,
    private filterService: FilterService,
    private data: DataService,
    @Inject(LIST) private list: List,

    // Really need.
    private lifecycleListService: LifecycleListService,
  ) {}

  public ngOnInit(): void {
    const actions: MenuAction[] = [
      this.actions.getDefaultAction('card', {
        handler: () =>
          this.state.go(`timesheet`, {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actions.getDefaultAction('delete', {
        handler: () => {
          this.listService.delete();
        },
      }),
    ];

    if (this.app.checkPermission('ReAccount', null, PermissionType.Execute)) {
      actions.push({
        name: 'reAccount',
        title: 'components.timeSheetListComponent.actions.reAccount',
        hint: 'components.timeSheetListComponent.actions.reAccount',
        iconClass: '',
        isBusy: false,
        isVisible: false,
        handler: () => this.reAccount(),
      });
    }

    this.actions.set(actions);

    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((groups: UntypedFormGroup[]) => {
        this.actions.action('card').isShown = groups?.length === 1;

        this.actions.action('reAccount').isShown = !!groups.length;
      });
  }

  /**
   * Recreates accounting entry for approved timesheets.
   *
   * @description Used to re-account cost and revenue.
   */
  private async reAccount(): Promise<void> {
    const actionButton = this.actions.action('reAccount');

    const items: any[] = this.gridService.selectedGroupsValue;
    let ids = items.map((r) => r.id);

    const filter = this.filterService.getODataFilter();

    const query = {
      select: ['id'],
    };

    if (this.gridService.isAllSelected) {
      actionButton.isBusy = true;
      ids = await firstValueFrom(
        this.data
          .collection(this.list.dataCollection)
          .query<any[]>({
            ...query,
            filter: [...filter, ...this.listService.contextFilter],
          })
          .pipe(
            tap((entities) => entities.forEach((el) => items.push(el))),
            map((entities) => _.uniq(entities.map((entity) => entity.id))),
          ),
      );
      actionButton.isBusy = false;
    }

    const ref = this.modalService.open(MassOperationComponent);
    const instance = ref.componentInstance as MassOperationComponent;

    instance.massOperationType = 'reAccount';
    instance.entityIds = ids;
    instance.items = _.uniqBy(items, 'id');
    instance.collection = 'TimeSheets';

    ref.result.then(
      () => {
        this.gridService.selectGroup(null);
        this.listService.reload();
      },
      () => null,
    );
  }
}
