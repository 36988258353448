import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  signal,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Constants } from 'src/app/shared/globals/constants';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { CostNormalizationRule } from 'src/app/shared/models/entities/settings/cost-normalization-rule.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { CostNormalizationMethod } from 'src/app/shared/models/enums/cost-normalization-method.enum';
import { NormalizationRuleTimeOffToolbarComponent } from './normalization-rule-time-off-toolbar/normalization-rule-time-off-toolbar.component';
import { Guid } from 'src/app/shared/helpers/guid';
import { CostNormalizationRuleExcludedTimeOffType } from 'src/app/shared/models/entities/settings/cost-normalization-rule-excluded-time-off-type.model';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'wp-cost-normalization-rule-card',
  templateUrl: './cost-normalization-rule-card.component.html',
  providers: [GridService],
  standalone: false,
})
export class CostNormalizationRuleCardComponent implements OnInit {
  @Input() private entityId: string;

  public state = signal<CardState>(CardState.Loading);
  public isSaving = signal<boolean>(false);
  public readonly = signal<boolean>(false);

  public form = this.fb.group({
    id: null,
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    method: [null, Validators.required],
    isActive: [false],
    isDefault: [false],
    excludedTimeOffTypes: this.fb.array([]),
  });

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: NormalizationRuleTimeOffToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly(),
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          (<UntypedFormArray>this.form.controls.excludedTimeOffTypes).removeAt(
            index,
          );
          this.form.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'add',
        handlerFn: (timeOffType: NamedEntity) => {
          const group = this.fb.group({
            timeOffTypeName: [timeOffType.name, Validators.required],
            timeOffTypeId: [timeOffType.id, Validators.required],
            id: [Guid.generate()],
          });
          (<UntypedFormArray>this.form.controls.excludedTimeOffTypes).push(
            group,
          );
          group.markAsDirty();
        },
      },
    ],
    view: {
      name: 'excludedTimeOffTypes',
      columns: [
        {
          name: 'timeOffTypeName',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  public methods: NamedEntity[] = [];

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {
    actionService.reload$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.reload());
  }

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.setAsDefault(),
      },
    ]);

    for (const key in CostNormalizationMethod) {
      if (Object.prototype.hasOwnProperty.call(CostNormalizationMethod, key)) {
        this.methods.push({
          id: key,
          name: this.translate.instant(
            `enums.costNormalizationMethod.${CostNormalizationMethod[key]}`,
          ),
        });
      }
    }

    this.load();
  }

  /** Saves the cost normalization rule data. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving.set(true);
    this.actionService.action('save').start();

    const rule = this.form.value;

    const data: any = {
      id: rule.id,
      name: rule.name,
      isActive: rule.isActive,
      method: rule.method.id,
      excludedTimeOffTypes: [],
    };

    data.excludedTimeOffTypes = (
      this.form.value.excludedTimeOffTypes as any[]
    ).map((e) => ({ id: e.id, timeOffTypeId: e.timeOffTypeId }));

    this.data
      .collection('CostNormalizationRules')
      .entity(this.entityId)
      .update(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving.set(false);
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.costNormalizationRules.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  /** Loads the cost normalization rule data. */
  private load(): void {
    this.state.set(CardState.Loading);
    (<UntypedFormArray>this.form.controls.excludedTimeOffTypes).clear();
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.data
      .collection('CostNormalizationRules')
      .entity(this.entityId)
      .get<CostNormalizationRule>({
        expand: { excludedTimeOffTypes: { expand: 'timeOffType' } },
      })
      .subscribe({
        next: (rule: CostNormalizationRule) => {
          this.form.patchValue(rule);

          this.form.controls.method.setValue(
            this.methods.find((p) => p.id === rule.method),
          );

          rule.excludedTimeOffTypes.forEach(
            (timeOffType: CostNormalizationRuleExcludedTimeOffType) => {
              const group = this.fb.group({
                timeOffTypeName: [timeOffType.timeOffType.name],
                timeOffTypeId: [timeOffType.timeOffType.id],
                id: [timeOffType.id],
              });

              (<UntypedFormArray>this.form.controls.excludedTimeOffTypes).push(
                group,
              );
            },
          );

          this.readonly.set(!rule.editAllowed);

          if (this.readonly()) {
            this.form.disable();
          } else {
            this.form.enable();
          }

          this.actionService.action('save').isShown = !this.readonly();
          this.actionService.action('setAsDefault').isShown =
            !this.readonly() && !rule.isDefault;

          this.state.set(CardState.Ready);

          this.navigationService.addRouteSegment({
            id: rule.id,
            title: rule.name,
          });
        },
        error: (error: Exception) => {
          this.state.set(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  /** Reloads the component data. */
  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  /** Sets the current cost normalization rule as the default rule. */
  private setAsDefault(): void {
    this.data
      .collection('CostNormalizationRules')
      .entity(this.entityId)
      .action('WP.SetAsDefault')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.costNormalizationRules.card.messages.setAsDefault',
          );

          this.actionService.action('setAsDefault').isShown = false;
          this.form.controls['isDefault'].setValue(true, { emitEvent: false });
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }
}
