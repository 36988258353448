import { Component, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';

import { DateTime } from 'luxon';

import { AppService } from 'src/app/core/app.service';

import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridColumnType,
  GridCurrencyControlColumn,
  GridDateControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { Guid } from 'src/app/shared/helpers/guid';
import { currencyValueRequiredValidator } from 'src/app/shared/validators/currency-value-required';

import { TariffRate } from './helpers/tariff-rates.helper';
import { TariffRatesToolbarComponent } from './tariff-rates-toolbar/tariff-rates-toolbar.component';

// TODO: git mv to ./components
@Component({
  selector: 'tmt-tariff-rates',
  templateUrl: './tariff-rates.component.html',
  providers: [GridService],
  standalone: false,
})
export class TariffRatesComponent {
  @Input() readonly = false;
  @Input() rates: UntypedFormArray;

  public gridOptions: GridOptions = {
    sorting: false,
    clientTotals: false,
    selectionType: SelectionType.row,
    toolbar: TariffRatesToolbarComponent,
    commands: [
      {
        name: 'add',
        handlerFn: () => this.addLine(),
      },
      {
        name: 'delete',
        handlerFn: (formGroup: UntypedFormGroup) => this.deleteLine(formGroup),
        allowedFn: (id) => id,
      },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.deleteLine(formGroup);
        },
        allowedFn: () => !this.readonly,
      },
    ],
    view: {
      name: 'user-costs',
      columns: [
        <GridDateControlColumn>{
          name: 'effectiveDate',
          header: 'shared.columns.effectiveDate.title',
          hint: 'shared.columns.effectiveDate.hint',
          type: GridColumnType.DateControl,
          property: 'effectiveDate',
          width: '150px',
        },
        <GridCurrencyControlColumn>{
          name: 'value',
          header: 'projects.clients.card.tariffs.modal.columns.newRate',
          hint: 'projects.clients.card.tariffs.modal.columns.newRate',
          type: GridColumnType.CurrencyControl,
          isCurrencyEditable: true,
          property: 'value',
          width: '100%',
        },
      ],
    },
  };

  constructor(
    private gridService: GridService,
    private fb: UntypedFormBuilder,
    private app: AppService,
  ) {}

  /** Adds new tariff line. */
  private addLine(): void {
    const group = this.getTariffLinesFormGroup({
      value: {
        value: 0,
        currencyCode: this.app.session.configuration.baseCurrencyCode,
      },
      effectiveDate: DateTime.now().toISODate(),
    });
    this.rates.insert(0, group);
    this.gridService.selectGroup(group);
  }

  /**
   * Deletes tariff line.
   *
   * @param formGroup TariffLineFormGroup.
   */
  private deleteLine(formGroup: UntypedFormGroup): void {
    const index = this.rates.value.findIndex(
      (rate) => rate.id === formGroup.value.id,
    );
    this.rates.removeAt(index);
  }

  private getTariffLinesFormGroup(rate?: TariffRate | any): UntypedFormGroup {
    return this.fb.group({
      id: rate?.id ?? Guid.generate(),
      value: [
        rate?.value ?? {
          value: 0,
          currencyCode: this.app.session.configuration.baseCurrencyCode,
        },
        currencyValueRequiredValidator(),
      ],
      effectiveDate: [rate?.effectiveDate, Validators.required],
    });
  }
}
