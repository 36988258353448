export enum WidgetType {
  Column = 'Column',
  StackedColumns = 'StackedColumns',
  StackedPercentageColumns = 'StackedPercentageColumns',
  Line = 'Line',
  Pie = 'Pie',
  Speedometer = 'Speedometer',
  Value = 'Value',
  DoubleValue = 'DoubleValue',
  Table = 'Table',
  Frame = 'Frame',
  Funnel = 'Funnel',
}
