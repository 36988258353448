import { Component, OnInit, OnDestroy } from '@angular/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { TranslateService } from '@ngx-translate/core';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { TimeInputType } from 'src/app/shared/models/enums/time-input-type';
import { UserSettingsModalService } from '../user-settings-modal.service';
import { forkJoin, Subscription } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';
import { bcp47Normalize } from 'bcp-47-normalize';

@Component({
  selector: 'wp-user-settings-properties',
  templateUrl: './user-settings-properties.component.html',
  standalone: false,
})
export class UserSettingsPropertiesComponent implements OnInit, OnDestroy {
  loading: boolean;
  timeInputTypes: NamedEntity[];
  savingSubscription: Subscription;

  form: UntypedFormGroup = this.fb.group({
    timeZone: [null, Validators.required],
    language: [null, Validators.required],
    culture: [null, Validators.required],
    timeInputType: [null, Validators.required],
    useStopwatch: [false],
  });
  cultures: NamedEntity[];
  languages: NamedEntity[];

  constructor(
    private service: UserSettingsModalService,
    private notification: NotificationService,
    private translate: TranslateService,
    private data: DataService,
    private app: AppService,
    private fb: UntypedFormBuilder,
  ) {}

  load() {
    this.loading = true;
    const query = {
      select: ['timeInputType', 'languageCode', 'cultureCode', 'useStopwatch'],
      expand: ['timeZone'],
    };

    forkJoin({
      cultures: this.data.model.function('GetCultures').get<NamedEntity[]>(),
      languages: this.data.model.function('GetLanguages').get<NamedEntity[]>(),
      settings: this.data
        .collection('UserSettings')
        .entity(this.app.session.user.id)
        .get<any>(query),
    }).subscribe({
      next: (data) => {
        this.languages = data.languages;
        this.cultures = data.cultures;

        this.form.patchValue(data.settings);

        // Выбор типа ввода времени.
        const timeInputType = this.timeInputTypes.find(
          (x: NamedEntity) => x.id === data.settings.timeInputType,
        );
        this.form.controls['timeInputType'].setValue(timeInputType);

        this.form.controls['language'].setValue(
          data.languages.find((l) => l.id === data.settings.languageCode),
        );

        this.form.controls['culture'].setValue(
          data.cultures.find(
            (l) => l.id === bcp47Normalize(data.settings.cultureCode),
          ),
        );

        this.form.markAsPristine();
        this.form.markAsUntouched();
        this.loading = false;
      },
      error: (error: Exception) => {
        this.loading = false;
        this.notification.error(error.message);
      },
    });
  }

  save() {
    this.form.markAllAsTouched();
    if (this.form.valid) {
      this.service.savingState$.next(true);

      const value = this.form.value;

      const data = {
        timeZoneId: value.timeZone.id,
        languageCode: value.language.id,
        cultureCode: value.culture.id,
        timeInputType: value.timeInputType.id,
        useStopwatch: value.useStopwatch,
      };

      this.data
        .collection('UserSettings')
        .entity(this.app.session.user.id)
        .patch(data)
        .subscribe({
          next: () => {
            this.notification.successLocal('shared.messages.saved');
            this.form.markAsPristine();
            location.reload();
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.service.savingState$.next(false);
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  ngOnInit() {
    this.timeInputTypes = [];
    for (const timeInputType of Object.keys(TimeInputType)) {
      this.timeInputTypes.push({
        id: timeInputType,
        name: this.translate.instant(`enums.timeInputType.${timeInputType}`),
      });
    }

    this.load();
    this.savingSubscription = this.service.save$.subscribe(() => this.save());
  }

  ngOnDestroy(): void {
    this.savingSubscription.unsubscribe();
  }
}
