import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  Renderer2,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { ProjectResourceService } from 'src/app/projects/card/project-resources/core/project-resources.service';
import { ProjectResourceDataService } from 'src/app/projects/card/project-resources/core/project-resources-data.service';
import { merge } from 'rxjs';

@Component({
  selector: 'tmt-resource-total-hours-cell',
  styleUrls: ['./resource-total-hours-cell.component.scss'],
  templateUrl: './resource-total-hours-cell.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceTotalHoursCellComponent implements GridComponentCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public readonly resourceType = ResourceType;

  public readonly: boolean;

  public get controlValue(): number {
    return this.formGroup.controls[this.column.name].value;
  }

  constructor(
    public renderer: Renderer2,
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
    public elRef: ElementRef,
    public projectResourceService: ProjectResourceService,
    public projectResourceDataService: ProjectResourceDataService,
  ) {
    merge(this.projectResourceService.changes$, this.gridService.detectChanges$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.cdr.detectChanges();
      });
  }
}
