import {
  Component,
  OnInit,
  Input,
  Inject,
  inject,
  DestroyRef,
  ViewChildren,
  QueryList,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  AfterViewInit,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-employee-filter',
  templateUrl: './employee-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class EmployeeFilterComponent
  implements OnInit, AfterViewInit, FilterDetails
{
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;
  @Input() values: any;

  public detailsForm = this.fb.group({
    department: null,
    role: null,
    resourcePool: null,
    legalEntity: null,
    competence: null,
    level: null,
    grade: null,
    location: null,
    skills: null,
    supervisor: null,
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    @Inject(VIEW_NAME) public viewName: string,
    public service: FilterService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit() {
    this.service.resetValues$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.detailsForm.reset();
      });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const values = Object.assign(
          this.service.values,
          this.detailsForm.value,
        );
        this.service.changeValues(values);
      });
  }

  public ngAfterViewInit(): void {
    FormHelper.cascadeDependency(
      this.detailsForm,
      this.cascadeControls,
      [
        [
          {
            controlName: 'role',
          },
          {
            controlName: 'competence',
            dependedProperty: 'roleId',
          },
        ],
        [
          {
            controlName: 'level',
          },
          { controlName: 'grade', dependedProperty: 'levelId' },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );
    this.cdr.detectChanges();
  }
}
