import { Command } from 'prosemirror-state';
import { NodeItem } from './consts';
export interface MenuItem {
  /** ID. */
  id: NodeItem;
  /** Command on click. */
  command: Command;
  /** Shows if active or not. */
  isActive?: boolean;
  /** Toggles isActive property on state change. */
  toggleActive?: (...args) => void;
  /** Title. */
  title: string;
  /** Icon class name. */
  bootstrapClassName?: string;
  /** Hot key for toggling item's activation. */
  hotKey?: string;
  /** Text for using instead of icon. */
  innerText?: string;
  /** Sub items for selecting in dropdown. */
  subItems?: Omit<MenuItem[], 'subItems'>;
  /** Shows if hide dropdown arrow or not. Uses with subItems. */
  hideDropdownArrow?: boolean;
  /** Shows if item disabled or not. */
  disabled?: boolean;
}

export interface MarkItem {
  /** Mark open symbols. */
  open: string;
  /** Mark close symbols. */
  close: string;
  /** Mark char code. */
  markerCode: number;
}
