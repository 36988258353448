import { isNodeSelection } from 'prosemirror-utils';
import { Command, EditorState, TextSelection } from 'prosemirror-state';
import { get$Cursor } from 'src/app/shared/components/controls/rich-editor-box/utils/selection';
import { MenuItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';
import { isHrSelection } from 'src/app/shared/components/controls/rich-editor-box/markdown/additional/horizontal-rule/consts';

/**
 * Adds horizontal rule and empty paragraph after it.
 *
 * @returns editor view state command.
 */
export function addHr(): Command {
  return (state, dispatch) => {
    if (isHrSelection(state.selection)) return true;

    if (isNodeSelection(state.selection)) {
      dispatch?.(
        state.tr
          .replaceSelectionWith(state.schema.nodes.horizontal_rule.create())
          .scrollIntoView(),
      );
      return true;
    }

    const $cursor = get$Cursor(state.selection);
    if (!$cursor || $cursor.parent.type.spec.complex) return false;

    if (!dispatch) return true;

    const { tr } = state;
    const isEmptyParagraph =
      $cursor.parent.type === state.schema.nodes.paragraph &&
      $cursor.parent.nodeSize === 2;

    if (isEmptyParagraph) {
      const pos = $cursor.before();
      tr.insert(pos, state.schema.nodes.horizontal_rule.create());
    } else {
      const pos = $cursor.after();
      tr.insert(pos, [
        state.schema.nodes.horizontal_rule.create(),
        state.schema.nodes.paragraph.create(),
      ]);
      tr.setSelection(TextSelection.create(tr.doc, pos + 2)); // set cursor to paragraph after hr
    }

    dispatch(tr.scrollIntoView());

    return true;
  };
}

/**
 * Changes horizontal rule (tag hr) disabled property.
 *
 * @param item additional menu item.
 * @param state editor view current state.
 */
export function disableHorizontalRule(
  item: MenuItem,
  state: EditorState,
): void {
  item.subItems.find((item) => item.id === 'hr').disabled = !addHr()(
    state,
    null,
  );
}
