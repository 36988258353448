import {
  computed,
  DestroyRef,
  inject,
  Injectable,
  signal,
} from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Collection, DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { filter } from 'rxjs/operators';
import {
  Organization,
  OrganizationTotal,
} from 'src/app/shared/models/entities/projects/organization.model';
import { NotificationService } from 'src/app/core/notification.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { NavigationService } from 'src/app/core/navigation.service';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { urlValidator } from 'src/app/shared/validators/url';
import { emailValidator } from 'src/app/shared/validators/email';

@Injectable()
export class ClientCardService {
  private readonly _state = signal<CardState>(CardState.Loading);
  public readonly state = computed(this._state);
  private readonly _isInvalid = signal<boolean>(false);
  public readonly isInvalid = computed(this._isInvalid);
  private readonly reloadTabSubject = new Subject<void>();
  public readonly reloadTab$ = this.reloadTabSubject.asObservable();
  private readonly clientTotalSubject = new BehaviorSubject<OrganizationTotal>(
    null,
  );
  public readonly clientTotal$ = this.clientTotalSubject
    .asObservable()
    .pipe(filter((p) => !!p));
  private readonly clientSubject = new BehaviorSubject<Organization>(null);
  public readonly client$ = this.clientSubject
    .asObservable()
    .pipe(filter((p) => !!p));

  public clientId: string;
  public creatingMode = false;
  public clientForm: UntypedFormGroup;
  public clientCollection: Collection;

  private readonly = false;

  public get currentClient(): Organization {
    return this.clientSubject.getValue();
  }
  public set currentClient(client: Organization) {
    this.clientSubject.next(client);
  }

  private readonly actionPanelService = inject(ActionPanelService);
  private readonly customFieldService = inject(CustomFieldService);
  private readonly dataService = inject(DataService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly fb = inject(UntypedFormBuilder);
  private readonly navigationService = inject(NavigationService);
  private readonly notificationService = inject(NotificationService);
  private readonly savingQueueService = inject(SavingQueueService);

  constructor() {
    this.clientForm = this.fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      code: ['', [Validators.maxLength(Constants.formCodeMaxLength)]],
      description: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      manager: [null, [Validators.required]],
      billingEqualPost: [false],
      billingAddress: this.fb.group({
        address: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        city: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        postIndex: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        state: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      }),
      postAddress: this.fb.group({
        address: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        city: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        postIndex: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
        state: [null, [Validators.maxLength(Constants.formTextMaxLength)]],
      }),
      contact: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
      contactEmail: ['', emailValidator()],
      phone: [
        '',
        [
          Validators.minLength(Constants.minPhoneLength),
          Validators.maxLength(Constants.maxPhoneLength),
        ],
      ],
      site: ['', urlValidator()],
    });

    this.clientCollection = this.dataService.collection('Organizations');

    this.actionPanelService.setHasAutosave(true);

    this.customFieldService.enrichFormGroup(this.clientForm, 'Organization');

    this.clientForm
      .get('billingEqualPost')
      .valueChanges.pipe(takeUntilDestroyed())
      .subscribe(() => this.setAddressesControlsState());

    this.savingQueueService.save$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.clientForm.markAsPristine();
      this._isInvalid.set(false);
    });
    this.savingQueueService.error$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.load(this.clientId);
    });

    this.subscribeToFormChanges();
  }

  /** Reloads tab. */
  public reloadTab() {
    this.reloadTabSubject.next();
  }

  /** Loads all client's data. */
  public load(clientId: string): void {
    this.clientId = clientId;
    this._state.set(CardState.Loading);

    this.loadClient();
    this.loadClientTotal();
  }

  /** Loads client's totals. */
  private loadClientTotal(): void {
    const query = {
      select: ['actualHours'],
    };

    this.dataService
      .collection('OrganizationTotals')
      .entity(this.clientId)
      .get(query)
      .subscribe({
        next: (clientTotal: OrganizationTotal) => {
          this.clientTotalSubject.next(clientTotal);
        },
      });
  }

  /** Loads client's data. */
  private async loadClient(): Promise<void> {
    await this.savingQueueService.save();
    this.clientForm.markAsPristine();
    this.clientForm.markAsUntouched();

    const query = {
      select: [
        'id',
        'name',
        'isActive',
        'phone',
        'site',
        'code',
        'contact',
        'contactEmail',
        'description',
        'postAddress',
        'billingAddress',
        'billingEqualPost',
        'editAllowed',
        'tariffsEditAllowed',
        'tariffsViewAllowed',
        'contactsViewAllowed',
      ],
      expand: [{ manager: { select: ['name', 'id'] } }],
    };

    this.customFieldService.enrichQuery(query, 'Organization');

    this.clientCollection
      .entity(this.clientId)
      .get(query)
      .subscribe({
        next: (client: Organization) => {
          this.clientSubject.next(client);
          this.currentClient = client;
          this.readonly = !client.editAllowed;
          if (this.readonly) {
            this.clientForm.disable({ emitEvent: false });
          } else {
            this.clientForm.enable({ emitEvent: false });
          }
          this.clientForm.patchValue(client, { emitEvent: false });
          this.setAddressesControlsState();
          this.navigationService.addRouteSegment({
            id: client.id,
            title: client.name,
          });
          this._state.set(CardState.Ready);
        },
        error: (error: Exception) => {
          this._state.set(CardState.Error);
          if (error.code !== Exception.BtEntityNotFoundException.code) {
            this.notificationService.error(error.message);
          }
        },
      });
  }

  /** Saving when changing the form. */
  private subscribeToFormChanges(): void {
    this.clientForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (this.clientForm.invalid || this.creatingMode) {
          this._isInvalid.set(true);
          return;
        }

        const clientFormValue = this.clientForm.getRawValue();
        const clientData = Object.assign({}, clientFormValue, {
          managerId: clientFormValue.manager?.id || null,
        });
        delete clientData.manager;

        this.currentClient = Object.assign(
          {},
          this.currentClient,
          clientFormValue,
        );

        this.savingQueueService.addToQueue(
          this.clientId,
          this.clientCollection.entity(this.clientId).patch(clientData),
        );
      });
  }

  /** Disable or enable addresses controls depending on billingEqualPost value. */
  private setAddressesControlsState(): void {
    if (this.clientForm.get('billingEqualPost').value) {
      this.clientForm.get('billingAddress').disable({ emitEvent: false });

      this.clientForm
        .get('billingAddress')
        .patchValue(this.clientForm.get('postAddress').value, {
          emitEvent: false,
        });
    } else {
      this.clientForm.get('billingAddress').enable({ emitEvent: false });
    }
  }
}
