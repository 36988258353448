import { Component, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { StateService, TransitionService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DayPart } from 'src/app/shared/models/enums/day-part.enum';
import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { TimeOffRequestService } from '../shared/time-off-request.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { forkJoin } from 'rxjs';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { DateTime } from 'luxon';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'wp-time-off-creation',
  templateUrl: './time-off-creation.component.html',
  styleUrls: ['./time-off-creation.component.scss'],
  providers: [TimeOffRequestService],
  standalone: false,
})
export class TimeOffCreationComponent implements OnInit {
  public currentBalance = null;
  public currentDuration: any;

  public isSaving = false;
  public userIsAllowed: boolean;
  public userQuery: any;

  public timeOffTypeQuery = {
    select: ['id', 'name', 'code'],
    expand: { unit: { select: ['id', 'name', 'code'] } },
  };

  public form = this.fb.group({
    timeOffType: [null, Validators.required],
    startDate: [
      DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd'),
      Validators.required,
    ],
    finishDate: [
      DateTime.now().plus({ days: 1 }).toFormat('yyyy-MM-dd'),
      Validators.required,
    ],
    startDayIntervalValue: [
      this.service.getDayParts().find((d) => d.id === DayPart.FullDay),
      Validators.required,
    ],
    finishDayIntervalValue: [
      this.service.getDayParts().find((d) => d.id === DayPart.FullDay),
      Validators.required,
    ],
    startDayHours: 1,
    finishDayHours: 1,
    user: [this.app.session.user, Validators.required],
  });

  constructor(
    private service: TimeOffRequestService,
    private fb: UntypedFormBuilder,
    transitionService: TransitionService,
    private app: AppService,
    private notification: NotificationService,
    private message: MessageService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private customFieldService: CustomFieldService,
  ) {
    transitionService.onSuccess({}, () => this.cancel());
    this.customFieldService.enrichFormGroup(this.form, 'TimeOffRequest');
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.form,
      'TimeOffRequest',
    );
  }

  ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    if (
      this.form.value.startDate === this.form.value.finishDate &&
      this.form.value.startDayIntervalValue.id === DayPart.Hours &&
      this.form.value.startDayHours === 0
    ) {
      this.notification.warningLocal(
        'timeOff.period.periodHasToBeGreaterThanZero',
      );
      return;
    }

    this.isSaving = true;
    const formValue = this.form.value;
    const data = {
      name: 'name',
      userId: formValue.user?.id,
      timeOffTypeId: formValue.timeOffType.id,
      startDate: formValue.startDate,
      finishDate: formValue.finishDate,
      startDayInterval: formValue.startDayIntervalValue.id,
      finishDayInterval: formValue.finishDayIntervalValue.id,
      finishDayHours: formValue.finishDayHours,
      startDayHours: formValue.startDayHours,
    };

    this.customFieldService.assignValues(
      data,
      this.form.getRawValue(),
      'TimeOffRequest',
    );

    this.data
      .collection('TimeOffRequests')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal('timeOff.creation.created');
          this.state.go(`timeOffRequest`, {
            entityId: response.id,
            routeMode: RouteMode.continue,
          });
          this.isSaving = false;
          this.activeModal.close(response);
        },
        error: (error: Exception) => {
          this.message.error(error.message);
          this.isSaving = false;
        },
      });
  };

  // Проверить доступна ли установка заявителя, отличного от текущего пользователя.
  private setUserAllowed() {
    if (
      this.app.checkPermission('TimeOffRequest', 'All', PermissionType.Modify)
    ) {
      this.userIsAllowed = true;
    } else {
      if (
        this.app.checkPermission(
          'TimeOffRequest',
          'MySubordinates',
          PermissionType.Modify,
        )
      ) {
        this.userIsAllowed = true;
        this.userQuery = {
          filter: [
            {
              supervisors: {
                any: {
                  id: { type: 'guid', value: this.app.session.user.id },
                },
              },
            },
          ],
        };
      }
    }
  }

  private reloadInfo() {
    if (
      !this.form.value.startDate ||
      !this.form.value.finishDate ||
      !this.form.value.user ||
      !this.form.value?.timeOffType?.unit
    ) {
      this.currentDuration = null;
      this.currentBalance = null;
      return;
    }

    forkJoin({
      balance: this.data
        .collection('TimeOffRequests')
        .function('GetTimeOffBalance')
        .query({
          userId: this.form.value.user.id,
          timeOffTypeId: this.form.value?.timeOffType?.id || null,
        }),
      duration: this.data
        .collection('TimeOffRequests')
        .function('GetTimeOffDuration')
        .query({
          startDate: this.form.value.startDate,
          finishDate: this.form.value.finishDate,
          startDayInterval: `WP.DayPart'${this.form.value.startDayIntervalValue.id}'`,
          finishDayInterval: `WP.DayPart'${this.form.value.finishDayIntervalValue.id}'`,
          startDayHours: this.form.value.startDayHours,
          finishDayHours: this.form.value.finishDayHours,
          userId: this.form.value.user.id,
          timeOffTypeId: this.form.value?.timeOffType?.id || null,
        }),
    }).subscribe({
      next: (response) => {
        this.currentBalance = response.balance;
        this.currentDuration = response.duration;
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
      },
    });
  }

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  ngOnInit(): void {
    this.setUserAllowed();
    this.form.valueChanges.subscribe(() => this.reloadInfo());
    this.reloadInfo();
  }
}
