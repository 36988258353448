import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';

@Component({
  selector: 'tmt-project-tariffs-client-tariff-filter',
  template: '',
  standalone: false,
})
export class ProjectTariffsClientTariffFilterComponent
  implements FilterDetails
{
  @Input() values: any;
  public detailsForm: UntypedFormGroup = null;
}
