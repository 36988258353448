import {
  Component,
  OnInit,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  DestroyRef,
  inject,
  input,
} from '@angular/core';
import {
  TimeAllocation,
  TimesheetLine,
} from 'src/app/shared/models/entities/base/timesheet.model';
import { AppService } from 'src/app/core/app.service';
import { TimeInputType } from 'src/app/shared/models/enums/time-input-type';
import { TranslateService } from '@ngx-translate/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { TimesheetCardService } from '../../core/timesheet-card.service';
import { round } from 'lodash';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { NotificationService } from 'src/app/core/notification.service';
import { StopwatchState } from 'src/app/shared/models/entities/base/stopwatch.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

@Component({
  selector: 'tmt-allocation-info',
  templateUrl: './allocation-info.component.html',
  styleUrls: ['./allocation-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AllocationInfoComponent implements OnInit {
  public allocation = input.required<TimeAllocation>();
  public line = input.required<TimesheetLine>();
  public readonly = input.required<boolean>();
  public dayScheduleDuration = input.required<number>();
  public propagationMode = input<PropagationMode>(
    PropagationMode.onExitFromEditing,
  );

  public showStartButton: boolean;
  public showStopButton: boolean;
  public swExecuted: boolean;
  public isPause: boolean;
  public element = null;
  public buttonTitles: string[];
  public buttonHints: string[];
  public form: UntypedFormGroup;

  private destroyRef = inject(DestroyRef);

  constructor(
    public timesheetCardService: TimesheetCardService,
    private blockUI: BlockUIService,
    private stopwatchService: StopwatchService,
    private changeDetector: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private app: AppService,
    private translate: TranslateService,
    private customFieldService: CustomFieldService,
    private notification: NotificationService,
  ) {}

  public ngOnInit(): void {
    this.form = this.fb.group({
      hours: null,
      description: '',
    });
    this.customFieldService.enrichFormGroup(this.form, 'TimeAllocation');
    this.form.patchValue(this.allocation(), { emitEvent: false });

    if (this.readonly()) {
      this.form.disable();
    } else {
      this.form.enable();
    }

    // Локализация кнопочек.
    switch (this.app.session.timeInputType) {
      case TimeInputType.HoursAndMinutes:
        this.buttonTitles = ['0:15', '0:30', '1:00', '1:30', '2:00', '4:00'];
        break;

      case TimeInputType.Decimal: {
        const m = this.translate.instant(
          'timesheets.card.entryInfo.hotKeys.minutes',
        );
        const h = this.translate.instant(
          'timesheets.card.entryInfo.hotKeys.hours',
        );
        this.buttonTitles = [
          '15' + m,
          '30' + m,
          '1' + h,
          '1.5' + h,
          '2' + h,
          '4' + h,
        ];
        break;
      }
      case TimeInputType.SchedulePercent:
        this.buttonTitles = ['5%', '10%', '25%', '50%', '75%', '100%'];
        break;
    }

    switch (this.app.session.timeInputType) {
      case TimeInputType.SchedulePercent:
        this.buttonHints = this.buttonTitles;
        break;
      default:
        this.buttonHints = [
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint0_25'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint0_5'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint1'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint1_5'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint2'),
          this.translate.instant('timesheets.card.entryInfo.hotKeys.hint4'),
        ];
    }

    this.updateStopwatchState();

    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const patch: Partial<TimeAllocation> = {
          description: this.form.value.description,
        };
        this.customFieldService.assignValues(
          patch,
          this.form.value,
          'TimeAllocation',
        );
        this.timesheetCardService.patchAllocation(
          this.line().id,
          this.allocation().id,
          patch,
        );
        this.changeDetector.markForCheck();
      });

    this.stopwatchService.stopwatch$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateStopwatchState();
      });
  }

  public updateStopwatchState(): void {
    if (!this.allocation) {
      return;
    }

    // Решаем показывать ли блок секундомера.
    this.showStopButton =
      this.stopwatchService.stopwatch?.timeSheetLineId === this.line().id &&
      this.stopwatchService.stopwatch.date === this.allocation().date;

    // Показываем кнопку запуска секундомера только на текущий день.
    this.showStartButton =
      this.app.session.useStopwatch &&
      DateTime.fromISO(this.allocation().date).equals(
        DateTime.now().startOf('day'),
      ) &&
      !this.stopwatchService.stopwatch;

    this.isPause =
      this.stopwatchService.stopwatch?.state === StopwatchState.Paused;

    this.changeDetector.markForCheck();
  }

  // Установить время.
  public setHours(option: number): void {
    const isPercent =
      this.app.session.timeInputType === TimeInputType.SchedulePercent;
    let hours = 0;

    switch (option) {
      case 1:
        hours = isPercent ? 5 : 0.25;
        break;
      case 2:
        hours = isPercent ? 10 : 0.5;
        break;
      case 3:
        hours = isPercent ? 25 : 1;
        break;
      case 4:
        hours = isPercent ? 50 : 1.5;
        break;
      case 5:
        hours = isPercent ? 75 : 2;
        break;
      case 6:
        hours = isPercent ? 100 : 4;
        break;
    }

    if (isPercent) {
      hours = round((this.dayScheduleDuration() * hours) / 100, 4);
    }

    this.timesheetCardService.patchAllocation(
      this.line().id,
      this.allocation().id,
      {
        hours,
      },
    );
  }

  /*КОМАНДЫ СЕКУНДОМЕРА*/

  /** Запуск счетчика времени. */
  public start(): void {
    this.swExecuted = true;

    this.stopwatchService.start(this.line().id).then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Приостановка счетчика времени. */
  public pause(): void {
    this.swExecuted = true;
    this.stopwatchService.pause().then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Возобновление счетчика времени. */
  public resume(): void {
    this.swExecuted = true;

    this.stopwatchService.resume().then(
      () => {
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }

  /** Завершение счетчика времени. */
  public stop(type: any): void {
    this.swExecuted = true;
    this.blockUI.start();

    this.stopwatchService.stop(type).then(
      () => {
        this.blockUI.stop();
        this.swExecuted = false;
        this.updateStopwatchState();
        this.changeDetector.markForCheck();
      },
      (message: string) => {
        this.blockUI.stop();
        this.swExecuted = false;
        this.notification.warning(message);
      },
    );
  }
}
