import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { ChromeService } from 'src/app/core/chrome.service';
import { DateTimeLabelFormats } from 'src/app/shared/globals/hightstock.cfg';
import { KpiKind } from 'src/app/shared/models/enums/kpi-kind.model';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { TrendsKpiService } from './core/trends-kpi.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { HighchartsService } from 'src/app/core/highcharts.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-trends-kpi',
  templateUrl: './trends-kpi.component.html',
  styleUrls: ['./trends-kpi.component.scss'],
  standalone: false,
})
export class TrendsKpiComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() entityCollection: 'Projects' | 'Programs' | 'Organizations';
  @Input() projectVersion: ProjectVersion;
  @Input() financeViewAllowed = false;
  @Input() nonBillable: boolean;

  public isEmpty = false;
  public planningScale = PlanningScale;
  public kpiKind = KpiKind;
  public highcharts = this.highchartsService.getHighcharts();
  public chart: Highcharts.Chart;

  public chartOptions: Highcharts.Options = {
    chart: {
      alignTicks: false,
      width: null,
      events: {},
    },
    legend: {
      enabled: false,
    },
    loading: {
      labelStyle: {
        fontSize: '20px',
        fontFamily: 'Segoe UI',
        fontWeight: 'light',
      },
    },
    credits: {
      enabled: false,
    },
    rangeSelector: {
      enabled: false,
    },
    navigator: {
      enabled: true,
    },
    title: {
      text: '',
    },
    yAxis: {
      title: null,
    },
    xAxis: {
      type: 'datetime',
      ordinal: false,
      events: {},
    },
    series: [
      {
        type: 'column',
        name: this.translate.instant('projects.overview.type.actual'),
        tooltip: {
          valueDecimals: 2,
        },
        dataGrouping: {
          enabled: true,
          forced: true,
          units: [['month', [3]]],
          dateTimeLabelFormats: DateTimeLabelFormats,
        },
        data: [[]],
      },
      {
        type: 'column',
        name: this.translate.instant('projects.overview.type.plan'),
        tooltip: {
          valueDecimals: 2,
        },
        dataGrouping: {
          forced: true,
          units: [['month', [3]]],
          dateTimeLabelFormats: DateTimeLabelFormats,
        },
        data: [],
      },
    ],
  };

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: TrendsKpiService,
    private translate: TranslateService,
    private highchartsService: HighchartsService,
    private chrome: ChromeService,
  ) {}

  public getScaleName(scale: PlanningScale): string {
    switch (scale) {
      case PlanningScale.Day:
        return this.translate.instant('projects.overview.scale.periodDay');
      case PlanningScale.Week:
        return this.translate.instant('projects.overview.scale.periodWeek');
      case PlanningScale.Month:
        return this.translate.instant('projects.overview.scale.periodMonth');
    }
  }

  public setScale(scale: PlanningScale) {
    this.service.settings.scale = scale;
    this.service.saveSettings();
    let period = [];
    switch (scale) {
      case PlanningScale.Day:
        period = [['day', [1]]];
        break;
      case PlanningScale.Week:
        period = [['week', [1]]];
        break;
      case PlanningScale.Month:
        period = [['month', [1]]];
        break;
    }

    this.chart.series.forEach((series) => {
      series.xAxis.setDataGrouping(
        {
          units: period,
        },
        false,
      );
    });

    this.chart.redraw();
  }

  public getKindName(kind: KpiKind): string {
    return this.translate.instant(
      `projects.overview.kpi.${kind.toLowerCase()}`,
    );
  }

  ngOnInit(): void {
    this.service.entityCollection = this.entityCollection;
    this.service.financeViewAllowed = this.financeViewAllowed;
    this.service.projectVersion = this.projectVersion;

    this.service.loading$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((loading) => {
        if (loading) {
          this.chart?.showLoading();
        } else {
          this.chart?.hideLoading();
        }
      });

    this.service.reload();
  }

  ngAfterViewInit(): void {
    this.chart = this.highcharts.stockChart('highcharts', this.chartOptions);

    this.chart.reflow();

    this.service.kpiTrends$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((kpiTrends) => {
        this.chart.series[0].setData(kpiTrends.actual.map((e) => [e.d, e.v]));
        this.chart.series[1].setData(kpiTrends.plan.map((e) => [e.d, e.v]));

        this.isEmpty =
          kpiTrends.actual.length === 0 && kpiTrends.plan.length === 0;

        this.setScale(this.service.settings.scale);

        const ax0 = this.chart.series[0].xAxis as any;
        const ax1 = this.chart.series[1].xAxis as any;

        ax0.userMin = Math.min(ax0.dataMin, ax1.dataMin);
        ax0.userMax = Math.max(ax0.dataMax, ax1.dataMax);

        this.chart.redraw();
        this.chart.reflow();
      });

    this.chrome.mainAreaSize$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.chart?.reflow();
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
