<div class="menubar">
  @for (item of items; track item.id) {
    @if (item.subItems) {
      <div
        ngbDropdown
        [popperOptions]="popperOptions"
        class="menubar-item"
        [class]="item.id"
        [ngClass]="{
          active: item.isActive,
          disabled: item.disabled,
        }"
      >
        <div
          [ngbTooltip]="popContent"
          [tooltipContext]="{ item: item }"
          placement="bottom"
          tooltipClass="menubar-item-tooltip"
        >
          <button
            type="button"
            class="menubar-item-icon"
            ngbDropdownToggle
            [ngClass]="{
              'hidden-dropdown-icon': item.hideDropdownArrow,
              active: item.isActive,
            }"
          >
            @if (item.bootstrapClassName) {
              <i class="bi" [class]="item.bootstrapClassName"></i>
            } @else {
              <span [class]="item.id">{{ item?.innerText }}</span>
            }
          </button>
          <div ngbDropdownMenu class="dropdown-menu">
            @for (subItem of item.subItems; track subItem.id) {
              <button
                ngbDropdownItem
                type="button"
                (click)="onItemClick(subItem)"
                [ngClass]="{ active: subItem.isActive }"
                [disabled]="subItem.disabled"
                [attr.data-test]="subItem.id"
              >
                <span>
                  @if (subItem.bootstrapClassName) {
                    <i class="bi subitem-icon" [class]="subItem.bootstrapClassName"></i>
                  } @else {
                    <span [class]="subItem.id" class="subitem-icon">{{ subItem?.innerText }}</span>
                  }
                  <span class="subitem-title">{{ subItem.title | translate }}</span>
                </span>
                @if (subItem.hotKey) {
                  <span class="subitem-hot-key">{{ subItem.hotKey }}</span>
                }
              </button>
            }
          </div>
        </div>
      </div>
    } @else {
      <div
        class="menubar-item"
        [class]="item.id"
        [ngClass]="{ active: item.isActive, disabled: item.disabled }"
        (click)="onItemClick(item)"
        [ngbTooltip]="popContent"
        [tooltipContext]="{ item: item }"
        placement="bottom"
        container="body"
        tooltipClass="menubar-item-tooltip"
      >
        @if (item.bootstrapClassName) {
          <i class="bi" [class]="item.bootstrapClassName"></i>
        } @else {
          <span [class]="item.id">{{ item?.innerText }}</span>
        }
      </div>
    }
  }
</div>

<ng-template #popContent let-item="item">
  {{ item.title | translate }}
  @if (item.hotKey) {
    <span class="hot-key">{{ item.hotKey }}</span>
  }
</ng-template>
