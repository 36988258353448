import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Invoice } from 'src/app/shared/models/entities/billing/invoice.model';
import { InvoiceLine } from 'src/app/shared/models/entities/billing/invoice-line.model';
import { InvoiceLineType } from 'src/app/shared/models/entities/billing/invoice-line-type.enum';
import { VatRate } from 'src/app/shared/models/entities/billing/vat-rate.model';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddingLinesModalComponent } from './adding-lines-modal/adding-lines-modal.component';
import { InvoiceCardService } from './invoice-card.service';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { AppService } from 'src/app/core/app.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { sortBy } from 'lodash';
import { GroupingField } from './shared/grouping-field.enum';
import { Subject } from 'rxjs';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ProjectInfoComponent } from 'src/app/shared/components/features/project-info/project-info.component';
import { filter, takeUntil } from 'rxjs/operators';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { CurrenciesService } from 'src/app/shared/services/currencies.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NonCustomInvoiceLineType } from 'src/app/shared/models/entities/billing/invoice-card-types.type';
import { Currency } from 'src/app/shared/models/entities/settings/currency.model';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';

/**
 * Represents Invoice Card content.
 * */
@Component({
  selector: 'wp-invoice-card',
  templateUrl: './invoice-card.component.html',
  styleUrls: ['./invoice-card.component.scss'],
  providers: [
    AutosaveService,
    InvoiceCardService,
    FormHeaderService,
    { provide: META_ENTITY_TYPE, useValue: 'Invoice' },
    LifecycleService,
  ],
  standalone: false,
})
export class InvoiceCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public activeTab: string;
  public isSaving = false;
  public readonly: boolean;
  public invoice: Invoice;
  public paidStateId = Invoice.paidStateId;

  public billingType = ProjectBillingType;

  public totalAmount: number;
  public vatLabel: string;
  public vatAmount: number;

  /** Invoice Line types. */
  public get invoiceLineTypes() {
    return {
      // Note: keep this type cast to use in HTML templates.
      time: <NonCustomInvoiceLineType>InvoiceLineType.Time,
      expense: <NonCustomInvoiceLineType>InvoiceLineType.Expense,
      custom: InvoiceLineType.Custom,
    };
  }

  /** Determines whether VAT is filled and not applied or not. */
  public get hasVat(): boolean {
    return (
      this.form.controls.invoiceTemplate.value?.vatRate &&
      this.form.controls.invoiceTemplate.value.vatRate.code !==
        VatRate.notApply.code
    );
  }

  /** Determines whether VAT is applied or not. */
  public get useVat(): boolean {
    return !!this.form.controls.invoiceTemplate.value?.vatRate;
  }

  /** Invoice Time lines. */
  public get timeLines(): UntypedFormArray {
    return this.form.controls.timeLines as UntypedFormArray;
  }

  /** Invoice Expense lines. */
  public get expenseLines(): UntypedFormArray {
    return this.form.controls.expenseLines as UntypedFormArray;
  }

  /** Invoice Custom lines. */
  public get customLines(): UntypedFormArray {
    return this.form.controls.customLines as UntypedFormArray;
  }

  /** Determines whether Invoice has lines or not. */
  public get hasLines(): boolean {
    return (
      this.timeLines.length > 0 ||
      this.customLines.length > 0 ||
      this.expenseLines.length > 0
    );
  }

  /** Invoice Template OData query. */
  public get templateQuery(): any {
    return {
      select: ['id', 'name'],
      expand: { vatRate: { select: ['id', 'code', 'name', 'rate'] } },
    };
  }

  /**
   * Currency code.
   * Can be the Base or the Project one.
   * */
  public get currencyCode(): string {
    return this._currencyCode;
  }

  public form = this.fb.group({
    invoiceTemplate: [null, Validators.required],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    billingAddress: ['', Validators.maxLength(Constants.formTextMaxLength)],
    number: ['', Validators.maxLength(50)],
    issueDate: null,
    paymentDueDate: null,
    paymentDate: null,
    outerNotes: ['', Validators.maxLength(Constants.formTextMaxLength)],
    innerNotes: ['', Validators.maxLength(Constants.formTextMaxLength)],
    timeLines: this.fb.array([]),
    customLines: this.fb.array([]),
    expenseLines: this.fb.array([]),
  });

  private _currencyCode: string;
  private _projectCurrency: Currency;
  private _baseCurrencyCode: string;
  private _isCustomCurrency = false;
  private _isProgramValueChange = false;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();
  private invoiceReloaded$ = new Subject<void>();

  constructor(
    public service: InvoiceCardService,
    public translate: TranslateService,
    public autosave: AutosaveService,
    private app: AppService,
    private formHeaderService: FormHeaderService,
    private currenciesService: CurrenciesService,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    private actionService: ActionPanelService,
    private data: DataService,
    private modalService: NgbModal,
    private infoPopupService: InfoPopupService,
    private wpCurrency: WpCurrencyPipe,
    private injector: Injector,
  ) {}

  ngOnInit() {
    this._baseCurrencyCode = this.app.session.configuration.baseCurrencyCode;
    this._currencyCode = this._baseCurrencyCode;

    this.initMainMenu();
    this.initCardSubscriptions();
    this.service.entityId = this.entityId;
    this.service.load();
  }

  ngOnDestroy(): void {
    this.service.dispose();
    this.destroyed$.next();
    this.invoiceReloaded$.next();
  }

  /**
   * Opens Invoice author properties popup.
   * */
  public openUserInfo() {
    const userId = this.invoice.user.id;
    const target = document.getElementById('author');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Opens Invoice project properties popup.
   * */
  public openProjectInfo() {
    const projectId = this.invoice.project.id;
    const target = document.getElementById('project');
    this.infoPopupService.open({
      target,
      data: {
        component: ProjectInfoComponent,
        params: {
          projectId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Add Time/Expense lines button click event handler.
   * Opens Modal window to view and add Invoice Time/Expense lines.
   * */
  public addLines(lineType: NonCustomInvoiceLineType) {
    this.autosave.save().then(
      () => {
        const ref = this.modalService.open(AddingLinesModalComponent, {
          size: 'full',
          injector: this.injector,
        });
        const instance = ref.componentInstance as AddingLinesModalComponent;
        instance.type = lineType;
        switch (lineType) {
          case this.invoiceLineTypes.time:
            instance.grouping = this.invoice.timeLinesGrouping;
            break;
          case this.invoiceLineTypes.expense:
            instance.grouping = this.invoice.expenseLinesGrouping;
            break;
        }
        instance.organizationId = this.invoice.organization.id;
        instance.projectId = this.invoice.project?.id;
        instance.currencyCode = this._currencyCode;

        ref.result.then(
          (lines: []) => {
            lines.forEach((line) => {
              const group = this.getLineGroup(line);
              switch (lineType) {
                case this.invoiceLineTypes.time:
                  this.timeLines.push(group);
                  break;
                case this.invoiceLineTypes.expense:
                  this.expenseLines.push(group);
                  break;
              }
            });

            this.service.reload();
          },
          () => null,
        );
      },
      () => null,
    );
  }

  /**
   * Creates New Invoice custom line.
   * */
  public createCustomLine() {
    const group = this.getLineGroup();
    this.customLines.push(group);
    this.service.onCustomLineAdded(group);
    if (this._isCustomCurrency) {
      this.refreshXr(group);
    }
  }

  /**
   * Inits Main menu.
   * */
  private initMainMenu() {
    this.actionService.set([
      {
        name: 'view',
        title: 'billing.invoices.card.actions.view.label',
        hint: 'billing.invoices.card.actions.view.hint',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        iconClass: 'bi bi-file-pdf',
        handler: () => this.service.downloadInvoice(this.invoice),
      },
      {
        name: 'delete',
        title: 'billing.invoices.card.actions.delete.label',
        hint: 'billing.invoices.card.actions.delete.hint',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.deleteInvoice(),
      },
    ]);

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.reload();
      });
    this.actionService.setHasAutosave(true);
  }

  /**
   * Inits Card level subscriptions.
   * */
  private initCardSubscriptions() {
    this.service.invoice$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((invoice) => this.onInvoiceLoaded(invoice));

    this.autosave.builderFn = this.autoSaveBuilderFn;

    this.autosave.savingFn = (data: any) =>
      this.service.collection
        .entity(this.entityId)
        .update(data, { withResponse: true });

    this.autosave.onSave
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        this.invoice.rowVersion = data.rowVersion;
      });

    this.autosave.onError
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.service.load());

    this.service.resetGrouping$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((resetGrouping) => {
        switch (resetGrouping.type) {
          case this.invoiceLineTypes.time:
            this.invoice.timeLinesGrouping = resetGrouping.grouping;
            this.timeLines.clear();
            break;
          case this.invoiceLineTypes.expense:
            this.invoice.expenseLinesGrouping = resetGrouping.grouping;
            this.expenseLines.clear();
            break;
        }

        this.autosave.saveLazy();
      });

    this.service.calculateTotals$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.calculateTotals();
      });
  }

  /**
   * Builds entity for Auto Save service.
   *
   * @returns Entity to save.
   * */
  private autoSaveBuilderFn = () => {
    const formValue = this.form.getRawValue() as Invoice;
    const invoice = {
      id: this.invoice.id,
      rowVersion: this.invoice.rowVersion,
      name: this.invoice.name,
      organizationId: this.invoice.organization.id,
      projectId: this.invoice.project?.id,
      description: formValue.description,
      billingAddress: formValue.billingAddress,
      innerNotes: formValue.innerNotes,
      outerNotes: formValue.outerNotes,
      number: formValue.number,
      paymentDueDate: formValue.paymentDueDate,
      issueDate: formValue.issueDate,
      expenseLinesGrouping: this.invoice.expenseLinesGrouping,
      timeLinesGrouping: this.invoice.timeLinesGrouping,
      invoiceTemplateId: this.form.controls.invoiceTemplate.value.id,
      lines: [] as any[],
    };

    const getBaseLine = (formLine: any): any => ({
      id: formLine.id,
      invoiceId: this.invoice.id,
      description: formLine.description,
      projectId: formLine.project?.id ?? null,
      periodStart: formLine.periodStart,
      periodFinish: formLine.periodFinish,
      period: formLine.period,
      date: formLine.date,
      projectTaskId: formLine.projectTask?.id ?? null,
      projectCostCenterId: formLine.projectCostCenter?.id ?? null,
      projectTariffId: formLine.projectTariff?.id ?? null,
      roleId: formLine.role?.id ?? null,
      userId: formLine.user?.id ?? null,
      quantity: formLine.quantity,
      unit: formLine.unit,
      rate: formLine.rate.value,
      amount: formLine.amount.value,
      exchangeRate: formLine.exchangeRate,
      amountBC: formLine.amountBC.value,
      accountId: formLine.account?.id ?? null,
    });

    this.timeLines.getRawValue().forEach((formLine) => {
      const line = getBaseLine(formLine);
      line.lineType = this.invoiceLineTypes.time;
      invoice.lines.push(line);
    });

    this.expenseLines.getRawValue().forEach((formLine) => {
      const line = getBaseLine(formLine);
      line.lineType = this.invoiceLineTypes.expense;
      invoice.lines.push(line);
    });

    this.customLines.getRawValue().forEach((formLine) => {
      const line = getBaseLine(formLine);
      line.lineType = this.invoiceLineTypes.custom;
      invoice.lines.push(line);
    });

    return invoice;
  };

  /**
   * Invoice load event handler.
   *
   * @param invoice Invoice.
   * */
  private onInvoiceLoaded(invoice: Invoice) {
    this.invoiceReloaded$.next();
    this.invoice = invoice;

    this.autosave.disabled = true;

    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.form.patchValue(invoice);

    this.readonly = !invoice.editAllowed;
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    invoice.editAllowed
      ? this.form.enable({ emitEvent: false })
      : this.form.disable({ emitEvent: false });

    this.timeLines.clear();
    this.expenseLines.clear();
    this.customLines.clear();

    this._projectCurrency = this.invoice.project?.currency;
    this._currencyCode =
      this._projectCurrency?.alpha3Code ?? this._baseCurrencyCode;
    this._isCustomCurrency = this._currencyCode !== this._baseCurrencyCode;

    this.getSortedLines(
      invoice.timeLinesGrouping,
      invoice.lines,
      this.invoiceLineTypes.time,
    ).forEach((line) => {
      const group = this.getLineGroup(line);
      this.timeLines.push(group);
    });

    this.getSortedLines(
      invoice.expenseLinesGrouping,
      invoice.lines,
      this.invoiceLineTypes.expense,
    ).forEach((line) => {
      const group = this.getLineGroup(line);
      this.expenseLines.push(group);
    });

    this.getSortedLines(
      invoice.grouping,
      invoice.lines,
      this.invoiceLineTypes.custom,
    ).forEach((line) => {
      const group = this.getLineGroup(line);
      this.customLines.push(group);
    });

    if (this._isCustomCurrency) {
      this.form.controls.issueDate.valueChanges
        .pipe(takeUntil(this.invoiceReloaded$))
        .subscribe(() => {
          this.refreshXr();
        });
    }

    this.form.valueChanges
      .pipe(takeUntil(this.invoiceReloaded$))
      .subscribe(() => this.autosave.saveLazy());

    this.form.controls.number.valueChanges
      .pipe(takeUntil(this.invoiceReloaded$))
      .subscribe(() => {
        this.invoice.name = this.translate.instant(
          'billing.invoices.card.nameTemplate',
          {
            number: this.form.controls.number.value,
            project:
              this.invoice.project?.name ?? this.invoice.organization?.name,
          },
        );
      });

    this.form.controls.invoiceTemplate.valueChanges
      .pipe(takeUntil(this.invoiceReloaded$))
      .subscribe(() => {
        this.calculateTotals();
        this.toggleActions();
      });

    this.autosave.disabled = false;
    this.toggleActions();
    this.calculateTotals();
  }

  /**
   * Gets Invoice line form group for Time/Expense lines.
   *
   * @param line Invoice line.
   * @returns Invoice line form group.
   * */
  private getLineGroup(line?: InvoiceLine): UntypedFormGroup {
    const group = this.fb.group({
      id: Guid.generate(),
      lineType: this.invoiceLineTypes.custom,
      amount: [
        {
          value: 0,
          currencyCode: this._currencyCode,
        },
        Validators.required,
      ],
      exchangeRate: [1, Validators.required],
      amountBC: [
        {
          value: 0,
          currencyCode: this._baseCurrencyCode,
        },
      ],
      rate: [
        {
          value: 0,
          currencyCode: this._currencyCode,
        },
        Validators.required,
      ],
      quantity: [0, Validators.required],
      originalAmount: [
        {
          value: 0,
          currencyCode: this._currencyCode,
        },
      ],
      originalRate: [
        {
          value: 0,
          currencyCode: this._currencyCode,
        },
      ],
      originalQuantity: 0,
      unit: [''],
      project: null,
      projectTask: null,
      projectCostCenter: null,
      projectTariff: null,
      user: null,
      role: null,
      account: null,
      description: '',
      originalDescription: '',
      date: null,
      periodStart: null,
      periodFinish: null,
      empty: '', // Dummy for filler cell.
    });

    group.controls.amount.disable();
    group.controls.amountBC.disable();

    if (line) {
      group.patchValue(line);
      group.controls.amount.setValue({
        value: line.amount,
        currencyCode: this._currencyCode,
      });
      group.controls.amountBC.setValue({
        value: line.amountBC,
        currencyCode: this._baseCurrencyCode,
      });
      group.controls.rate.setValue({
        value: line.rate,
        currencyCode: this._currencyCode,
      });
      group.controls.originalAmount.setValue({
        value: line.originalAmount,
        currencyCode: this._currencyCode,
      });
      group.controls.originalRate.setValue({
        value: line.originalRate,
        currencyCode: this._currencyCode,
      });
    }

    group.controls.rate.valueChanges
      .pipe(takeUntil(this.invoiceReloaded$))
      .subscribe(() => {
        this.setAmounts(group);
        this.calculateTotals();
      });

    group.controls.quantity.valueChanges
      .pipe(takeUntil(this.invoiceReloaded$))
      .subscribe(() => {
        this.setAmounts(group);
        this.calculateTotals();
      });

    if (this._isCustomCurrency) {
      group.controls.exchangeRate.enable();
      group.controls.exchangeRate.valueChanges
        .pipe(
          filter(() => !this._isProgramValueChange),
          takeUntil(this.invoiceReloaded$),
        )
        .subscribe(() => this.setAmounts(group));
    } else {
      group.controls.exchangeRate.disable();
    }

    return group;
  }

  /**
   * Updates Actions visibility state.
   * */
  private toggleActions() {
    this.actionService.actions.forEach((a) =>
      this.actionService.action(a.name).hide(),
    );

    this.actionService.action('view').isShown =
      this.form.getRawValue().invoiceTemplate;
  }

  /**
   * Calculates Invoice total sum values.
   * */
  private calculateTotals() {
    const formValue = this.form.getRawValue();

    const timeLineTotal = this.timeLines
      .getRawValue()
      .reduce((acc, line) => (acc += line.amount.value), 0);

    const expenseLineTotal = this.expenseLines
      .getRawValue()
      .reduce((acc, line) => (acc += line.amount.value), 0);

    const customLineTotal = this.customLines
      .getRawValue()
      .reduce((acc, line) => (acc += line.amount.value), 0);

    this.totalAmount = timeLineTotal + expenseLineTotal + customLineTotal;
    this.invoice.totalAmount = this.totalAmount;

    if (this.hasVat) {
      const rate = formValue.invoiceTemplate.vatRate.rate;
      this.vatLabel = `${this.translate.instant(
        'billing.invoices.card.props.vat',
      )} ${formValue.invoiceTemplate.vatRate.name}`;
      this.vatAmount = this.totalAmount * rate;
      this.totalAmount += this.vatAmount;
    } else {
      this.vatLabel = this.translate.instant(
        'billing.invoices.card.props.withoutVat',
      );
    }

    this.formHeaderService.updateIndicators([
      {
        description: 'billing.invoices.card.mainProps.totalAmount',
        value: this.wpCurrency.transform(
          this.invoice.totalAmount,
          this._currencyCode,
        ),
      },
    ]);
  }

  /**
   * Gets Invoice lines of specific type sorted by grouping fields.
   *
   * @param grouping Grouping fields string.
   * @param lines Invoice lines.
   * @param lineType Invoice line type.
   * @returns Sorted Invoice lines.
   * */
  private getSortedLines(
    grouping: string,
    lines: InvoiceLine[],
    lineType: InvoiceLineType,
  ): InvoiceLine[] {
    const fields = (grouping ? grouping.split(',') : []) as GroupingField[];
    const sortExpression: string[] = [];

    fields.forEach((field) => {
      switch (field) {
        case GroupingField.role:
          sortExpression.push('role.name');
          break;
        case GroupingField.project:
          sortExpression.push('project.name');
          break;
        case GroupingField.projectTask:
          sortExpression.push('projectTask.name');
          break;
        case GroupingField.projectTariff:
          sortExpression.push('projectTariff.name');
          break;
        case GroupingField.user:
          sortExpression.push('user.name');
          break;
        case GroupingField.account:
          sortExpression.push('account.name');
          break;
        case GroupingField.date:
          sortExpression.push('date');
          break;
        case GroupingField.description:
          sortExpression.push('description');
          break;
      }
    });

    let result = lines.filter((line) => line.lineType === lineType);
    result = sortBy(result, sortExpression);
    return result;
  }

  /**
   * Refreshes Invoice lines with Currency Exchange rate by Invoice issue date.
   *
   * @param lineGroup Invoice line to update. If not passed, all the Custom lines will be updated.
   * */
  private refreshXr(lineGroup?: UntypedFormGroup) {
    if (!this._projectCurrency) {
      console.warn('Project Currency is not defined.');
      return;
    }
    const customLines = lineGroup
      ? [lineGroup]
      : (this.customLines.controls as Array<UntypedFormGroup>);
    this.currenciesService
      .getExchangeRate(
        this._projectCurrency.id,
        this.form.controls.issueDate.value,
      )
      .subscribe({
        next: (xr) => {
          this.setXr(customLines, xr);
        },
        error: (error: Exception) => {
          this.setXr(customLines, 1);
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Sets Invoice lines Exchange rate.
   *
   * @param lines Invoice lines to update.
   * @param xr New Exchange rate.
   * */
  private setXr(lines: Array<UntypedFormGroup>, xr: number) {
    lines.forEach((line) => {
      this.setValueProgrammatically(line.controls.exchangeRate, xr);
      this.setAmounts(line);
    });
  }

  private setAmounts(lineGroup: UntypedFormGroup) {
    lineGroup.controls.amount.setValue({
      value:
        lineGroup.controls.rate.value.value * lineGroup.controls.quantity.value,
      currencyCode: this._currencyCode,
    });
    lineGroup.controls.amountBC.setValue({
      value:
        lineGroup.controls.exchangeRate.value *
        lineGroup.controls.amount.value.value,
      currencyCode: this._baseCurrencyCode,
    });
  }

  // TODO: move to separate service and provide at component level.
  private setValueProgrammatically(ctrl: AbstractControl, value: any) {
    this._isProgramValueChange = true;
    ctrl.setValue(value);
    this._isProgramValueChange = false;
  }

  protected readonly CommentedEntityCollectionType =
    CommentedEntityCollectionType;
}
