import {
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import { RbcGroupType, RbcViewGroup } from '../../../models/rbc-view.model';
import { ProjectRbcCalendarDataService } from '../../core/project-rbc-calendar-data.service';
import { ProjectRbcCalendarService } from '../../core/project-rbc-calendar.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpProjectRbcCalendarLeftGroup]',
  templateUrl: './project-rbc-calendar-left-group.component.html',
  styleUrls: ['./project-rbc-calendar-left-group.component.scss'],
  standalone: false,
})
export class ProjectRbcCalendarLeftGroupComponent implements OnInit, OnDestroy {
  @Input() group: RbcViewGroup;
  @Input() showOther = true;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public readonly RbcGroupType = RbcGroupType;

  private subscriptions: Subscription[] = [];

  public get forecastPlanDelta(): number {
    if (this.group.type !== RbcGroupType.forecast) {
      return;
    }
    return this.dataService.getGroupSignedDelta(this.group);
  }
  public get hasLines(): boolean {
    const hasTaskLines = this.group.taskLines.length !== 0;
    const hasOtherLine =
      !!this.group.otherLine && this.group.otherLine.total !== 0;
    return hasTaskLines || hasOtherLine;
  }

  constructor(
    public service: ProjectRbcCalendarService,
    private dataService: ProjectRbcCalendarDataService,
    private changeDetectorRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.subscriptions.push(
      this.service.toggle$
        .pipe(filter((id) => id === this.group.id))
        .subscribe(() => {
          this.changeDetectorRef.detectChanges();
        }),
      this.service.changes$.subscribe(() => {
        this.recalculateTotals();
        this.changeDetectorRef.detectChanges();
      }),
    );
    this.recalculateTotals();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }

  trackById = (index: number, row: any) => row.task.id;

  public onExpand() {
    this.group.isExpanded = !this.group.isExpanded;
    this.service.toggleGroup(this.group.id);
  }

  public onToggleGroupOther() {
    this.service.toggleGroupOther(this.group);
  }

  public getGroupHintKey(group: RbcViewGroup) {
    const currSection = this.dataService.getSectionByGroup(group);
    return `projects.projects.card.rbc.calendar.groupHints.${currSection.type}.${group.type}`;
  }

  /**
   * Recalculates group and task lines' totals
   *
   * @private
   */
  private recalculateTotals() {
    this.group.total = 0;
    this.group.taskLines.forEach((line) => {
      this.group.total += line.total;
    });
    if (this.showOther) {
      this.group.total += this.group.otherLine?.total ?? 0;
    }
  }
}
