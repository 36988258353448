import {
  Component,
  OnInit,
  OnDestroy,
  ViewChild,
  ElementRef,
  Renderer2,
  Input,
  Inject,
  LOCALE_ID,
  ChangeDetectorRef,
  forwardRef,
  booleanAttribute,
  NgZone,
  AfterViewInit,
} from '@angular/core';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { Subscription } from 'rxjs';
import { getLocaleNumberSymbol, NumberSymbol } from '@angular/common';
import { round } from 'lodash';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { hoursInDay, ValueMode } from '../models/value-mode.enum';
import { PlannerFormatPipe } from '../core/planner-format.pipe';
import { PlanningScale } from '../../../shared/models/enums/planning-scale.enum';
import { BaseEntry } from './base-entry.model';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

/** Ячейка планировщика. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpPlannerCell]',
  templateUrl: './planner-cell.component.html',
  styleUrls: ['./planner-cell.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => PlannerCellComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class PlannerCellComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  @ViewChild('editor') private editor: ElementRef;
  @ViewChild('frame') private frame: ElementRef<HTMLElement>;

  @Input() dayIndex: number;
  @Input() lineIndex: number;
  @Input() valueMode: ValueMode;
  @Input() scale: PlanningScale;
  @Input() minValue = 0;
  @Input() propagationMode: PropagationMode = PropagationMode.onInput;
  @Input({ transform: booleanAttribute }) allowClearInReadonly = false;

  readonly: boolean;

  public entry: BaseEntry;

  public viewValue: string;
  private previousViewValue: string;
  private oldValue: any;

  private enterCode = 13;
  private escCode = 27;

  public id = Guid.generate();
  public serviceId: string;

  private delimiter: string;

  private get input() {
    if (this.readonly) {
      return null;
    }
    return this.editor.nativeElement as HTMLInputElement;
  }

  public mode = {
    active: false,
    selected: false,
    spreading: false,
    editing: false,
    left: false,
    top: false,
    right: false,
    bottom: false,
    spreader: false,
    spreadBottom: false,
  };
  contentElement: HTMLElement;
  valueElement: HTMLElement;

  eventEntrySelectedSubscription: Subscription;
  eventStartEditingSubscription: Subscription;
  eventStopEditingSubscription: Subscription;
  eventValueChangedSubscription: Subscription;
  eventEntryActivatedSubscription: Subscription;
  eventEntryDeactivatedSubscription: Subscription;
  eventEntryDespreadedSubscription: Subscription;
  eventEntryDeselectedSubscription: Subscription;
  eventEntrySpreadedSubscription: Subscription;
  eventResetSubscription: Subscription;
  allocationChangeSubscription: Subscription;
  public mouseEnterListener: () => void;

  /** Планирование в относительных значения и база для вычисления == 0 */
  get isRelativeWithZeroBase(): boolean {
    return (
      (this.valueMode === ValueMode.Percentage &&
        this.entry.scheduleHours === 0) ||
      (this.valueMode === ValueMode.FTE && this.entry.fteHours === 0)
    );
  }

  constructor(
    @Inject(LOCALE_ID) locale: string,
    private plannerFormat: PlannerFormatPipe,
    public entriesOrchestrator: CellsOrchestratorService,
    private renderer: Renderer2,
    private elRef: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private zone: NgZone,
  ) {
    this.delimiter = getLocaleNumberSymbol(locale, NumberSymbol.Decimal);
  }

  public propagateChange = (_: BaseEntry) => null;
  public registerOnTouched = (fn: any) => null;

  writeValue(obj: any): void {
    this.entry = obj;
    this.entriesOrchestrator.setValue(
      { x: this.dayIndex, y: this.lineIndex },
      this.getViewValue(),
    );

    if (this.entry?.scheduleHours === 0) {
      this.renderer.addClass(this.elRef.nativeElement, 'non-working');
    } else {
      this.renderer.removeClass(this.elRef.nativeElement, 'non-working');
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.changeDetector.detectChanges();
  }

  /** Возвращает значение ячейки в отображаемых единицах измерения (часах, %% или FTE). */
  private getViewValue(): number {
    let value = this.entry.hours;
    if (value == null || value === 0) {
      return value;
    } else {
      switch (this.valueMode) {
        case ValueMode.Percentage:
          value = (value / this.entry.scheduleHours) * 100;
          break;
        case ValueMode.FTE:
          value = value / this.entry.fteHours;
          break;
        case ValueMode.Days:
          value = value / hoursInDay;
          break;
      }
    }
    return value;
  }

  /** Возвращает значение ячейки в часах конвертируя его из значения в отображаемых единицах измерения (часах, %% или FTE). */
  private getHoursFromViewValue(value: number): number {
    if (value == null || value === 0) {
      return 0;
    } else {
      switch (this.valueMode) {
        case ValueMode.Percentage:
          value = (value * this.entry.scheduleHours) / 100;
          break;
        case ValueMode.FTE:
          value = value * this.entry.fteHours;
          break;
        case ValueMode.Days:
          value = value * hoursInDay;
          break;
      }
    }
    return value;
  }

  private changeValue(entry: BaseEntry) {
    if (!this.entriesOrchestrator.singleCellMode) {
      // Обновление значения в сервисе, для последующего использования.
      this.entriesOrchestrator.setValue(
        { x: this.dayIndex, y: this.lineIndex },
        this.getViewValue(),
      );
    }
    this.propagateChange(entry);
  }

  private getValueElement() {
    if (!this.valueElement) {
      this.valueElement = (
        this.elRef.nativeElement as HTMLElement
      ).querySelector('.value');
    }
    return this.valueElement;
  }

  private getContentElement() {
    if (!this.contentElement) {
      this.contentElement = (
        this.elRef.nativeElement as HTMLElement
      ).querySelector('.content');
    }
    return this.contentElement;
  }

  // Разбор введенного текста.
  private parseViewHours() {
    if (this.readonly) {
      return;
    }

    let viewValue = this.input.value;

    let duration;

    if (!viewValue) {
      if (this.entry.hours !== null && this.entry.hours !== 0) {
        this.entry.hours = null;
        this.changeValue(this.entry);
      }
      return;
    }

    // Clears spaces
    viewValue = viewValue.replace(/\s/g, '');
    const parts = viewValue.split(this.delimiter);

    // Если вставили строку и там много разделителей
    if (parts.length > 2) {
      duration = 0;
    } else {
      let wholePart = '';
      let decimalPart = '';

      if (parts.length === 1) {
        wholePart = parts[0];
        decimalPart = '0';
      } else {
        wholePart = parts[0];
        decimalPart = parts[1];
      }

      if (isNaN(Number(wholePart)) || wholePart.length === 0) {
        wholePart = '0';
      }
      if (isNaN(Number(decimalPart)) || decimalPart.length === 0) {
        decimalPart = '0';
      }

      duration = round(Number(wholePart + '.' + decimalPart), 2);

      switch (this.valueMode) {
        case ValueMode.Percentage:
          duration = round(this.entry.scheduleHours * (duration / 100), 2);
          break;
        case ValueMode.FTE:
          duration = round(this.entry.fteHours * duration, 2);
          break;
        case ValueMode.Days:
          duration = round(hoursInDay * duration, 2);
          break;
      }
    }

    switch (this.scale) {
      case PlanningScale.Day:
        if (duration > this.entry.limitHours) {
          duration = this.entry.limitHours;
        }
        break;

      case PlanningScale.Week:
        if (duration > this.entry.limitHours * 7) {
          duration = this.entry.limitHours * 7;
        }
        break;

      case PlanningScale.Month:
        if (duration > this.entry.limitHours * 31) {
          {
            duration = this.entry.limitHours * 31;
          }
        }
        break;
    }

    if (duration < this.minValue) {
      duration = this.minValue;
    }

    if (this.entry.hours !== duration) {
      this.entry.hours = duration;
      this.changeValue(this.entry);
    }
  }

  // Обновление отображаемого значения.
  private updateViewValue() {
    const hours = this.entry.hours;

    // Формирование ViewDuration (значения для редактирования).

    if (hours === null) {
      this.viewValue = '';
    } else {
      this.viewValue = this.plannerFormat.transform(
        hours,
        this.valueMode,
        this.entry.scheduleHours,
        this.entry.fteHours,
      );
    }
  }

  public startEditing() {
    // Запрет редактирования на выходной день в режиме %% от расписания.
    if (this.readonly || this.isRelativeWithZeroBase) {
      return;
    }

    this.oldValue = this.entry.hours;

    this.mode.editing = true;
    this.updateViewValue();
    this.previousViewValue = this.viewValue;

    setTimeout(() => {
      this.input.focus();
    });
    this.renderer.addClass(this.getValueElement(), 'hidden');
    this.renderer.addClass(this.getContentElement(), 'glow');
  }

  public stopEditing() {
    if (this.mode.editing) {
      this.parseViewHours();
      this.mode.editing = false;
      if (!this.entriesOrchestrator.singleCellMode) {
        this.getContentElement().parentElement.focus();
      }
      this.renderer.removeClass(this.getValueElement(), 'hidden');
      this.renderer.removeClass(this.getContentElement(), 'glow');
    }
  }

  public onKeydown(event: KeyboardEvent) {
    if (this.readonly) {
      return;
    }
    if (event.keyCode === this.enterCode) {
      this.stopEditing();
    }
    if (event.keyCode === this.escCode || event.charCode === this.enterCode) {
      this.input.value = this.oldValue;
      this.handleInput();
      this.entry.hours = this.oldValue;
      this.stopEditing();
    }
    event.stopPropagation();
  }

  /** Handle input changes. */
  public handleInput(): void {
    const selectionStart = this.input.selectionStart;
    const selectionEnd = this.input.selectionEnd;
    let value = this.input.value;

    const separators = [' ', '.', ',', ':'];

    separators
      .filter((x) => x !== this.delimiter)
      .forEach((separator: string) => {
        const newValue =
          value.indexOf(this.delimiter) === -1 ? this.delimiter : '';
        value = value.replace(separator, newValue);
      });

    const normalizedString: string = String(value).replace(this.delimiter, '.');
    if (
      value !== '-' &&
      (value.length > 6 || isNaN(Number(normalizedString)))
    ) {
      value = this.previousViewValue;
    }

    if (value !== this.input.value) {
      this.input.value = value;
      this.input.setSelectionRange(selectionStart, selectionEnd);
    }

    if (this.propagationMode === PropagationMode.onInput) {
      this.parseViewHours();
    }
    this.previousViewValue = value;
  }

  public onDblClick() {
    if (!this.entriesOrchestrator.singleCellMode) {
      this.startEditing();
    }
  }

  public onMouseDown() {
    this.entriesOrchestrator.mouseDown({ x: this.dayIndex, y: this.lineIndex });
  }

  public onMouseEnter() {
    this.entriesOrchestrator.mouseEnter({
      x: this.dayIndex,
      y: this.lineIndex,
    });
  }

  public onInputMouseDown(event) {
    event.stopPropagation();
  }

  public onSpreaderMouseDown(event) {
    if (this.readonly) {
      return;
    }
    this.entriesOrchestrator.spreaderMouseDown({
      x: this.dayIndex,
      y: this.lineIndex,
    });
    event.stopPropagation();
  }

  public onSpreaderDblClick(event) {
    if (this.readonly) {
      return;
    }
    this.entriesOrchestrator.spreaderDblClick({
      x: this.dayIndex,
      y: this.lineIndex,
    });
    event.stopPropagation();
  }

  private initEvents() {
    this.eventEntrySelectedSubscription =
      this.entriesOrchestrator.entrySelected$.subscribe((param) => {
        if (param.pos.x !== this.dayIndex || param.pos.y !== this.lineIndex) {
          return;
        }
        this.mode.selected = true;
        this.mode.top = param.top;
        this.mode.right = param.right;
        this.mode.left = param.left;
        this.mode.bottom = param.bottom;
        if (this.mode.bottom && this.mode.right) {
          this.mode.spreader = true;
        }

        this.changeDetector.detectChanges();
      });

    this.eventEntrySpreadedSubscription =
      this.entriesOrchestrator.entrySpreaded$.subscribe((param) => {
        if (param.pos.x !== this.dayIndex || param.pos.y !== this.lineIndex) {
          return;
        }
        this.mode.spreading = true;
        this.mode.top = param.top;
        this.mode.right = param.right;
        this.mode.left = param.left;
        this.mode.bottom = param.bottom;

        this.changeDetector.detectChanges();
      });

    this.eventEntryDeselectedSubscription =
      this.entriesOrchestrator.entryDeselected$.subscribe((param) => {
        if (!this.mode.selected) {
          return;
        }
        this.mode.selected = false;
        this.mode.top = false;
        this.mode.right = false;
        this.mode.left = false;
        this.mode.bottom = false;
        this.mode.spreader = false;

        this.changeDetector.detectChanges();
      });

    this.eventEntryDespreadedSubscription =
      this.entriesOrchestrator.entryDespreaded$.subscribe(() => {
        if (!this.mode.spreading) {
          return;
        }
        this.mode.spreading = false;
        this.mode.top = false;
        this.mode.right = false;
        this.mode.left = false;
        this.mode.bottom = false;
        this.mode.spreadBottom = false;

        this.changeDetector.detectChanges();
      });

    this.eventEntryDeactivatedSubscription =
      this.entriesOrchestrator.entryDeactivated$.subscribe((exceptEntry) => {
        if (
          exceptEntry &&
          exceptEntry.x === this.dayIndex &&
          exceptEntry.y === this.lineIndex
        ) {
          return;
        }
        this.stopEditing();
        this.mode.active = false;

        this.changeDetector.detectChanges();
      });

    this.eventEntryActivatedSubscription =
      this.entriesOrchestrator.entryActivated$.subscribe((pos) => {
        if (pos.x !== this.dayIndex || pos.y !== this.lineIndex) {
          return;
        }
        this.mode.active = true;
        this.changeDetector.detectChanges();
      });

    // Событие массового редактирования: растягивание или стирание значения.
    this.eventValueChangedSubscription =
      this.entriesOrchestrator.valueChanged$.subscribe((param) => {
        if (param.pos.x !== this.dayIndex || param.pos.y !== this.lineIndex) {
          return;
        }
        if (this.readonly) {
          if (
            !this.allowClearInReadonly ||
            (this.allowClearInReadonly && param.value)
          ) {
            return;
          }
        }
        if (this.isRelativeWithZeroBase && param.value !== null) {
          return;
        }
        if (
          param.onlyForWorkDay &&
          (this.entry.hours === 0 || this.entry.hours === null) &&
          this.entry.scheduleHours === 0
        ) {
          return;
        }
        const hours = this.getHoursFromViewValue(param.value);
        if (this.entry.hours === hours) {
          return;
        }
        this.entry.hours = hours;
        this.updateViewValue();

        this.changeValue(this.entry);
        this.changeDetector.detectChanges();
      });

    this.eventResetSubscription = this.entriesOrchestrator.reset$.subscribe(
      () => {
        this.entriesOrchestrator.setValue(
          { x: this.dayIndex, y: this.lineIndex },
          this.getViewValue(),
        );
        this.changeDetector.detectChanges();
      },
    );

    this.eventStopEditingSubscription =
      this.entriesOrchestrator.stopEditing$.subscribe(() => {
        this.stopEditing();
        this.changeDetector.detectChanges();
      });

    this.eventStartEditingSubscription =
      this.entriesOrchestrator.startEditing$.subscribe((value: string) => {
        if (this.isRelativeWithZeroBase) {
          return;
        }

        if (this.mode.active) {
          this.startEditing();
          if (value) {
            this.viewValue = value;
            if (this.propagationMode !== PropagationMode.onExitFromEditing) {
              setTimeout(() => {
                this.parseViewHours();
                this.changeDetector.detectChanges();
              });
            }
          }
        }
        this.changeDetector.detectChanges();
      });
  }

  ngOnInit(): void {
    this.serviceId = this.entriesOrchestrator.id;
    this.initEvents();
  }

  public ngAfterViewInit(): void {
    this.zone.runOutsideAngular(() => {
      this.mouseEnterListener = this.renderer.listen(
        this.frame.nativeElement,
        'mouseenter',
        () => {
          this.onMouseEnter();
        },
      );
    });
  }

  ngOnDestroy() {
    this.stopEditing();

    if (this.eventEntrySelectedSubscription) {
      this.eventEntrySelectedSubscription.unsubscribe();
    }

    if (this.eventStopEditingSubscription) {
      this.eventStopEditingSubscription.unsubscribe();
    }

    if (this.eventEntryActivatedSubscription) {
      this.eventEntryActivatedSubscription.unsubscribe();
    }

    if (this.eventEntryDeactivatedSubscription) {
      this.eventEntryDeactivatedSubscription.unsubscribe();
    }

    if (this.eventEntryDespreadedSubscription) {
      this.eventEntryDespreadedSubscription.unsubscribe();
    }

    if (this.eventEntryDeselectedSubscription) {
      this.eventEntryDeselectedSubscription.unsubscribe();
    }

    if (this.eventStartEditingSubscription) {
      this.eventStartEditingSubscription.unsubscribe();
    }

    if (this.eventValueChangedSubscription) {
      this.eventValueChangedSubscription.unsubscribe();
    }

    if (this.eventEntrySpreadedSubscription) {
      this.eventEntrySpreadedSubscription.unsubscribe();
    }

    if (this.eventResetSubscription) {
      this.eventResetSubscription.unsubscribe();
    }

    if (this.mouseEnterListener) {
      this.mouseEnterListener();
    }
  }
}
