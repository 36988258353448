import { Component, OnInit, Input, inject, DestroyRef } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { TimesheetPeriod } from 'src/app/shared/models/entities/settings/timesheet-period.model';
import { TranslateService } from '@ngx-translate/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DayOfWeek } from 'src/app/shared/models/enums/day-of-week.enum';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { DateTime } from 'luxon';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-timesheet-period-card',
  templateUrl: './timesheet-period-card.component.html',
  standalone: false,
})
export class TimesheetPeriodCardComponent implements OnInit {
  @Input() entityId: string;

  public readonly: boolean;
  public name: string;
  public state: CardState;

  public isSaving = false;

  public daysOfWeek: NamedEntity[] = [];

  public cardForm = this.fb.group({
    periodType: [null],
    periodScope: [null],
    department: [null, Validators.required],
    effectiveDate: [null, Validators.required],
    expiryDate: [null, Validators.required],
    monthInitialDay: [
      1,
      [Validators.min(1), Validators.max(28), Validators.required],
    ],
    monthHalfDay: [
      1,
      [Validators.min(1), Validators.max(28), Validators.required],
    ],
    isActive: [false],
    dayOfWeekValue: [null, Validators.required],
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private translate: TranslateService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.cardForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.updateName());

    FormHelper.controlDatePair(
      this.cardForm,
      'effectiveDate',
      'expiryDate',
      takeUntilDestroyed(this.destroyRef),
    );

    for (const key of Object.keys(DayOfWeek)) {
      this.daysOfWeek.push({
        id: key,
        name: this.translate.instant(`enums.dayOfWeek.${key}`),
      });
    }
    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves Data. */
  public save = (): void => {
    this.cardForm.markAllAsTouched();

    if (this.cardForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const period = <TimesheetPeriod>this.cardForm.value;

    const data: any = {
      effectiveDate: period.effectiveDate,
      isActive: period.isActive,
      expiryDate: period.expiryDate,
      monthInitialDay: period.monthInitialDay,
      dayOfWeek: this.cardForm.value.dayOfWeekValue?.id,
      monthHalfDay: period.monthHalfDay,
      departmentId: period.department?.id,
    };

    this.data
      .collection('TimeSheetPeriods')
      .entity(this.entityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.cardForm.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.timesheetPeriods.card.messages.saved',
          );
        },
        error: (error: any) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  private reload(): void {
    if (!this.cardForm.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  private load = (): void => {
    this.state = CardState.Loading;
    this.cardForm.markAsPristine();
    this.cardForm.markAsUntouched();
    this.data
      .collection('TimeSheetPeriods')
      .entity(this.entityId)
      .get<TimesheetPeriod>({
        expand: [
          'periodScope',
          'periodType',
          { department: { select: ['id', 'name'] } },
        ],
      })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (period) => {
          this.navigationService.addRouteSegment({
            id: period.id,
            title: period.periodType.name,
          });

          this.cardForm.patchValue(period);
          this.readonly = !period.editAllowed;

          this.actionService.action('save').isShown = !this.readonly;
          this.actionService.action('setAsDefault').isShown =
            !period.isDefault && !this.readonly;

          this.readonly ? this.cardForm.disable() : this.cardForm.enable();
          this.cardForm.controls['periodScope'].disable();
          this.cardForm.controls['periodType'].disable();

          if (period.periodType.code !== 'Custom') {
            this.cardForm.controls['expiryDate'].disable();
          }
          if (
            period.periodType.code !== 'Monthly' &&
            period.periodType.code !== 'SemiMonthly'
          ) {
            this.cardForm.controls['monthInitialDay'].disable();
          }

          if (period.periodScope.code !== 'Department') {
            this.cardForm.controls['department'].disable();
          }

          if (period.periodType.code !== 'SemiMonthly') {
            this.cardForm.controls['monthHalfDay'].disable();
          }

          if (
            period.periodType.code !== 'WeeklyEndOfMonth' &&
            period.periodType.code !== 'Weekly'
          ) {
            this.cardForm.controls['dayOfWeekValue'].disable();
          }

          if (period.dayOfWeek) {
            this.cardForm.controls['dayOfWeekValue'].setValue(
              this.daysOfWeek.find((x) => x.id === period.dayOfWeek),
            );
          }
          this.state = CardState.Ready;
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  };

  private updateName(): void {
    this.name = '';
    const period = <TimesheetPeriod>this.cardForm.getRawValue();

    if (period.periodType) {
      this.name += period.periodType.name + ' ';
    }
    this.name +=
      this.translate.instant('settings.timesheetPeriods.card.nameTemplate') +
      ' ';

    this.name += period.effectiveDate
      ? DateTime.fromISO(period.effectiveDate).toLocaleString(
          DateTime.DATE_SHORT,
        )
      : ' ';
    this.name += ' (';
    if (period.periodScope) {
      this.name += period.periodScope.name;
    }
    this.name += ')';
  }
}
