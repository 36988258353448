import {
  Component,
  OnInit,
  DestroyRef,
  inject,
  signal,
  ChangeDetectionStrategy,
  Input,
} from '@angular/core';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { MessageService } from 'src/app/core/message.service';

import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { FinancialAccountType } from 'src/app/shared/models/entities/finance/financial-account-type.enum';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

@Component({
  selector: 'tmt-financial-account-card',
  templateUrl: './financial-account-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FinancialAccountCardComponent implements OnInit {
  @Input() protected entityId: string;

  public cardState = signal<CardState>(CardState.Loading);
  public isSaving = signal<boolean>(false);
  public readonly = signal<boolean>(false);
  public includeInBalanceShown = signal<boolean>(true);
  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: [false],
    isSystem: false,
    includedInBalance: false,
  });

  private readonly systemFields: Array<keyof FinancialAccount> = [
    'name',
    'code',
    'description',
    'isActive',
    'isSystem',
  ];
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private actionPanelService: ActionPanelService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private navigationService: NavigationService,
    private customFieldService: CustomFieldService,
  ) {
    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());

    this.customFieldService.enrichFormGroup(this.form, 'FinancialAccount');
  }

  public ngOnInit(): void {
    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.load();
  }

  /** Saves Data. */
  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);
    this.actionPanelService.action('save').start();

    const account = this.form.value as FinancialAccount;

    const data: Partial<FinancialAccount> = {
      name: account.name,
      code: account.code,
      description: account.description,
      isActive: account.isActive,
      includedInBalance: account.includedInBalance,
    };

    this.customFieldService.assignValues(data, account, 'FinancialAccount');

    this.dataService
      .collection('FinancialAccounts')
      .entity(this.entityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving.set(false);
          this.actionPanelService.action('save').stop();
          this.notificationService.successLocal(
            'components.financialAccountCardComponent.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.actionPanelService.action('save').stop();
          this.notificationService.error(error.message);
        },
      });
  }

  private load(): void {
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.dataService
      .collection('FinancialAccounts')
      .entity(this.entityId)
      .get<FinancialAccount>()
      .subscribe({
        next: (account: FinancialAccount) => {
          this.readonly.set(
            !account.editAllowed ||
              this.entityId === FinancialAccount.revenueId,
          );
          this.readonly() ? this.form.disable() : this.form.enable();

          if (this.readonly()) {
            this.form.disable();
          } else {
            this.form.enable();

            if (account.isSystem) {
              this.systemFields.forEach((key) => {
                this.form.get(key).disable();
              });
            }
          }

          if (account.typeId === FinancialAccountType.revenue.id) {
            this.includeInBalanceShown.set(false);
          }

          this.form.patchValue(account);
          this.actionPanelService.action('save').isShown = !this.readonly();
          this.cardState.set(CardState.Ready);
          this.navigationService.addRouteSegment({
            id: account.id,
            title: account.name,
          });
        },
        error: (error: Exception) => {
          this.cardState.set(CardState.Error);

          if (error.code === Exception.BtEntityNotFoundException.code) {
            return;
          }

          this.notificationService.error(error.message);
        },
      });
  }

  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.messageService
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }
}
