import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  Input,
  AfterViewInit,
  OnDestroy,
} from '@angular/core';
import { WidgetConfig } from '../../../shared/widget-config.model';
import { WidgetService } from '../widget.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'wp-widget-frame',
  templateUrl: './widget-frame.component.html',
  styleUrls: ['./widget-frame.component.scss'],
  standalone: false,
})
export class WidgetFrameComponent implements OnInit, AfterViewInit, OnDestroy {
  @Input() widget: WidgetConfig;

  @ViewChild('frame') editor: ElementRef;
  get iframe(): HTMLIFrameElement {
    return this.editor.nativeElement as HTMLIFrameElement;
  }

  rebuildSubscription: Subscription;

  constructor(private widgetService: WidgetService) {}

  private load() {
    if (this.widget.properties.url) {
      this.iframe.src = this.widget.properties.url;
    } else {
      this.iframe.src = 'about:blank';
      if (this.widget.properties.content) {
        this.iframe.onload = () => {
          const doc = this.iframe.contentDocument;

          const style = doc.createElement('style');
          style.appendChild(doc.createTextNode(''));
          doc.head.appendChild(style);

          const body = doc.body;
          body.innerHTML = '';
          body.innerHTML = this.widget.properties.content;

          style.innerHTML =
            "body {font-family: 'Segoe UI','Segoe UI Web Regular','Segoe UI Symbol','Helvetica Neue','BBAlpha Sans','S60 Sans',Arial,sans-serif;  font-size: 14px }";
        };
      }
    }
  }

  ngOnInit(): void {
    this.rebuildSubscription = this.widgetService.rebuild$.subscribe(() =>
      this.load(),
    );
  }

  ngAfterViewInit(): void {
    this.load();
  }

  ngOnDestroy(): void {
    this.rebuildSubscription.unsubscribe();
  }
}
