import { Component, OnDestroy, OnInit } from '@angular/core';
import { ProgramCardService } from 'src/app/programs/card/program-card.service';
import { OverviewKpiService } from 'src/app/shared/components/features/overview/overview-kpi/core/overview-kpi.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'tmt-program-overview-kpi',
  templateUrl: './program-overview-kpi.component.html',
  standalone: false,
})
export class ProgramOverviewKpiComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    public service: ProgramCardService,
    private overviewKpiService: OverviewKpiService,
  ) {}

  private reload() {
    this.overviewKpiService.reload();
  }

  ngOnInit() {
    this.service.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
