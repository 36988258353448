<div class="actions d-flex">
  @if (!(gridService.readonly$ | async)) {
    <div ngbDropdown>
      <button type="button" class="btn btn-default mb-3 me-2" ngbDropdownToggle data-test="mode">
        <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
        {{ 'shared.actions.create' | translate }}
      </button>
      <div ngbDropdownMenu>
        <button
          type="button"
          ngbDropdownItem
          title="{{
            'components.settingsAuthCreateProviderModelComponent.props.local' | translate
          }}"
          (click)="gridService.execute('createLocal')"
        >
          {{ 'components.settingsAuthCreateProviderModelComponent.props.local' | translate }}
        </button>
        <button
          type="button"
          ngbDropdownItem
          title="{{
            'components.settingsAuthCreateProviderModelComponent.props.openId' | translate
          }}"
          (click)="gridService.execute('createOpenId')"
        >
          {{ 'components.settingsAuthCreateProviderModelComponent.props.openId' | translate }}
        </button>
      </div>
    </div>
  }

  <button
    type="button"
    class="btn btn-default mb-3"
    title="{{ 'shared.actions.edit' | translate }}"
    (click)="gridService.execute('edit')"
    [disabled]="!gridService.canBeExecuted('edit', gridService.selectedGroup$.getValue())"
  >
    {{ 'shared.actions.edit' | translate }}
  </button>
</div>
