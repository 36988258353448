import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';

export const CONTACTS_LIST: List = {
  version: 3,
  name: 'contact',
  customFieldEntityType: 'Contact',
  views: [
    {
      name: 'default',
      columns: [
        {
          column: 'name',
          visibleByDefault: true,
          width: 2,
          totalByDefault: TotalType.Count,
          isRequired: true,
        },
        {
          column: 'created',
          visibleByDefault: true,
          width: 160,
        },
        {
          column: 'modified',
          visibleByDefault: true,
          width: 160,
        },
        {
          column: 'description',
          visibleByDefault: true,
          width: 3,
        },
        {
          column: 'isActive',
          visibleByDefault: true,
          width: 125,
        },
        {
          column: 'createdBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'modifiedBy',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'email',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'mobilePhone',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'phone',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'organization',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'role',
          visibleByDefault: false,
          width: 1,
        },
        {
          column: 'position',
          visibleByDefault: false,
          width: 1,
        },
      ],
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    <GridNavigationColumn>{
      name: 'name',
      state: 'contact.profile',
      header: 'shared2.props.name',
      hint: 'shared2.props.name',
      type: GridColumnType.Navigation,
    },
    {
      name: 'created',
      header: 'shared2.props.created.value',
      hint: 'shared2.props.created.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'modified',
      header: 'shared2.props.modified.value',
      hint: 'shared2.props.modified.hint',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'isActive',
      header: 'shared2.props.isActive.short',
      hint: 'shared2.props.isActive.value',
      type: GridColumnType.Boolean,
      fixedWidth: true,
    },
    {
      name: 'createdBy',
      header: 'shared2.props.createdBy.value',
      hint: 'shared2.props.createdBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'modifiedBy',
      header: 'shared2.props.modifiedBy.value',
      hint: 'shared2.props.modifiedBy.hint',
      type: GridColumnType.String,
    },
    {
      name: 'email',
      header: 'shared2.props.email',
      hint: 'shared2.props.email',
      type: GridColumnType.Email,
    },
    {
      name: 'mobilePhone',
      header: 'shared2.props.mobilePhone',
      hint: 'shared2.props.mobilePhone',
      type: GridColumnType.Phone,
    },
    {
      name: 'phone',
      header: 'shared2.props.phone',
      hint: 'shared2.props.phone',
      type: GridColumnType.Phone,
    },
    {
      name: 'organization',
      header: 'shared2.props.organization',
      hint: 'shared2.props.organization',
      type: GridColumnType.String,
    },
    {
      name: 'role',
      header: 'shared2.props.role',
      hint: 'shared2.props.role',
      type: GridColumnType.String,
    },
    {
      name: 'position',
      header: 'shared2.props.position',
      hint: 'shared2.props.position',
      type: GridColumnType.String,
    },
    {
      name: 'description',
      header: 'shared2.props.description',
      hint: 'shared2.props.description',
      type: GridColumnType.String,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isActive',
      field: 'isActive',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'created',
      field: 'created',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modified',
      field: 'modified',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'createdBy',
      field: 'createdBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'modifiedBy',
      field: 'modifiedBy.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'firstName',
      field: 'firstName',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'lastName',
      field: 'lastName',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'patronymic',
      field: 'patronymic',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'email',
      field: 'email',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'mobilePhone',
      field: 'mobilePhone',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'phone',
      field: 'phone',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'organization',
      field: 'organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'role',
      field: 'role.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'position',
      field: 'position',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
  dataCollection: 'Contacts',
};
