import { Injectable, Type } from '@angular/core';
import { DealListFilterComponent } from 'src/app/deals/list/deal-list-filter/deal-list-filter.component';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class DealListFilterService extends FilterService {
  public override component: Type<FilterDetails> = DealListFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = true;
  public override placeholder =
    'components.dealListFilterService.filter.placeholder';
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
    states: () => (this.stateConditionExist() ? 1 : 0),
  };

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push(
        this.getTextFilter(
          ['name', 'organization/name'],
          this.getClearText(text),
        ),
      );
    }

    if (this.values.manager) {
      result.push({
        managerId: { type: 'guid', value: this.values.manager.id },
      });
    }

    if (this.values.organization) {
      result.push({
        organizationId: {
          type: 'guid',
          value: this.values.organization.id,
        },
      });
    }

    if (this.values.source) {
      result.push({
        sourceId: { type: 'guid', value: this.values.source.id },
      });
    }

    if (this.values.resolution) {
      result.push({
        resolutionId: { type: 'guid', value: this.values.resolution.id },
      });
    }

    if (this.values.states) {
      const conditions = [];

      Object.keys(this.values.states).forEach((code) => {
        if (this.values.states[code].selected) {
          conditions.push({
            stateId: { type: 'guid', value: this.values.states[code].id },
          });
        }
      });

      result.push({ or: conditions });
    }

    if (
      this.values.view &&
      this.values.view.code === 'active' &&
      this.hasViewSelector
    ) {
      result.push({ isActive: true });
    }

    return result;
  }

  protected override getDefaultValues(): any {
    return {
      text: '',
      manager: null,
      organization: null,
      source: null,
      resolution: null,
      states: null,
      view: this.views[0],
    };
  }
}
