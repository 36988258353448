import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { Transition, UIRouterModule } from '@uirouter/angular';
import { SharedModule } from 'src/app/shared/shared.module';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import { EntityListComponent } from 'src/app/shared/components/entity-list/entity-list.component';
import { ROUTE_DEFAULT_PARAMS } from 'src/app/app.states';
import {
  LIST,
  MASS_OPERATION_PARAMETERS,
  MULTI_SELECT_LIST,
  VIEW_NAME,
} from 'src/app/shared/tokens';
import { BaseSharedModule } from 'src/app/shared-features/shared/base-shared.module';
import { CommentsModule } from 'src/app/shared-features/comments/comments.module';
import { CONTACTS_LIST } from 'src/app/contacts/model/contacts.list';
import { ContactCardComponent } from 'src/app/contacts/card/contact-card.component';
import { StateHelper } from 'src/app/shared/helpers/state.helper';
import { ContactProfileComponent } from 'src/app/contacts/card/contact-profile/contact-profile.component';
import { MassOperationParameters } from 'src/app/shared/components/mass-operation/model/mass-operation-parameters.model';
import { InteractionsComponent } from 'src/app/shared/modules/interactions/interactions.component';
import { InteractionsModule } from 'src/app/shared/modules/interactions/interactions.module';
import { ContactCreateModalComponent } from 'src/app/contacts/contact-create/contact-create.component';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';
import { ContactListFilterComponent } from 'src/app/contacts/list/contact-list-filter/contact-list-filter.component';
import { ContactListComponent } from 'src/app/contacts/list/contact-list.component';
import { AccordionStateDirective } from 'src/app/shared/directives/accordion-state/accordion-state.directive';

@NgModule({
  declarations: [
    ContactListComponent,
    ContactListFilterComponent,
    ContactCardComponent,
    ContactProfileComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    GridModule,
    BaseSharedModule,
    CommentsModule,
    EntityListComponent,
    InteractionsModule,
    ContactCreateModalComponent,
    PhonePipe,
    AccordionStateDirective,
    UIRouterModule.forChild({
      states: [
        {
          name: 'contacts',
          url: '/contacts?{navigation}&{route}',
          component: ContactListComponent,
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          resolve: [
            { provide: VIEW_NAME, useValue: 'default' },
            { provide: LIST, useValue: CONTACTS_LIST },
            { provide: MULTI_SELECT_LIST, useValue: true },
            {
              provide: MASS_OPERATION_PARAMETERS,
              useValue: {
                state: 'contact',
              } as MassOperationParameters,
            },
          ],
        },

        {
          name: 'contact',
          url: '/contacts/{entityId:guid}?{navigation}&{route}',
          dynamic: true,
          params: {
            navigation: null,
            routeMode: null,
            route: ROUTE_DEFAULT_PARAMS,
          },
          component: ContactCardComponent,
          redirectTo: 'contact.profile',
          resolve: [
            {
              token: 'entityId',
              deps: [Transition],
              resolveFn: StateHelper.resolveEntityId,
            },
          ],
        },

        {
          name: 'contact.profile',
          url: '/profile',
          component: ContactProfileComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
        {
          name: 'contact.interactions',
          url: '/interactions',
          component: InteractionsComponent,
          params: {
            keepMainActions: true,
            routeMode: null,
          },
        },
      ],
    }),
  ],
})
export class ContactsModule {}
