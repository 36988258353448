import { INTERACTIONS_LIST } from 'src/app/shared/lists/interactions.list';
import { Navigation } from '../models/navigation/navigation';

export const CLIENTS_APP_NAVIGATION: Navigation = {
  name: 'clients',
  applicationTitle: 'shared.apps.clients',
  groups: [
    {
      name: 'clients',
      header: 'components.navigation.groups.clients',
      items: [
        {
          name: 'clients.myClients',
          state: 'clients',
          header: 'components.navigation.groups.myClients.header',
          hint: 'components.navigation.groups.myClients.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'my',
          },
        },
        {
          name: 'clients.allClients',
          state: 'clients',
          header: 'components.navigation.groups.allClients.header',
          hint: 'components.navigation.groups.allClients.hint',
          entityType: 'Organization',
          stateParams: {
            view: 'all',
          },
        },
        {
          name: 'clients.allContacts',
          state: 'contacts',
          header: 'components.navigation.groups.allContacts.header',
          hint: 'components.navigation.groups.allContacts.hint',
        },
      ],
    },

    {
      name: 'sales',
      header: 'components.navigation.groups.sales',
      items: [
        {
          name: 'clients.myDeals',
          state: 'deals',
          header: 'components.navigation.groups.myDeals.header',
          hint: 'components.navigation.groups.myDeals.hint',
          entityType: 'Deal',
          stateParams: {
            view: 'my',
          },
        },
        {
          name: 'clients.allDeals',
          state: 'deals',
          header: 'components.navigation.groups.allDeals.header',
          hint: 'components.navigation.groups.allDeals.hint',
          entityType: 'Deal',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
    {
      name: 'sales',
      header: 'components.navigation.groups.interactions',
      items: [
        {
          name: 'clients.myOverdueInteractions',
          state: 'interactions',
          header: 'components.navigation.groups.myOverdueInteractions.header',
          hint: 'components.navigation.groups.myOverdueInteractions.hint',
          entityType: 'Interaction',
          stateParams: {
            view: 'my-overdue',
          },
          indicator: {
            hint: 'components.navigation.groups.myOverdueInteractions.hint',
            list: INTERACTIONS_LIST,
            viewName: 'my-overdue',
          },
        },
        {
          name: 'clients.allOverdueInteractions',
          state: 'interactions',
          header: 'components.navigation.groups.allOverdueInteractions.header',
          hint: 'components.navigation.groups.allOverdueInteractions.hint',
          entityType: 'Interaction',
          indicator: {
            hint: 'components.navigation.groups.allOverdueInteractions.hint',
            list: INTERACTIONS_LIST,
            viewName: 'all-overdue',
          },
          stateParams: {
            view: 'all-overdue',
          },
        },
        {
          name: 'clients.allInteractions',
          state: 'interactions',
          header: 'components.navigation.groups.allInteractions.header',
          hint: 'components.navigation.groups.allInteractions.hint',
          entityType: 'Interaction',
          stateParams: {
            view: 'all',
          },
        },
      ],
    },
  ],
};
