import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

export class DealContactsSettings implements BaseSettings {
  public readonly name = 'DealContactsSettings';
  version: number;
  onlyActive: boolean;

  getDefault(): DealContactsSettings {
    return {
      version: 1,
      onlyActive: true,
    } as DealContactsSettings;
  }
}
