import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-project-tariffs-cell-readonly',
  template: `<div
    class="trim"
    [title]="formGroup.getRawValue()[this.column.name]?.name ?? ''"
    [style.padding]="'6px 12px'"
  >
    {{ formGroup.getRawValue()[this.column.name]?.name ?? '' }}
  </div> `,
  standalone: false,
})
export class ProjectTariffCellReadonlyComponent implements GridComponentCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;
}
