<wp-loading-indicator [loading]="dealCardService.isLoading()" />
<wp-data-not-saved-indicator
  [form]="dealCardService.dealForm"
  [hidden]="!dealCardService.isInvalid()"
  [isDirty]="savingQueueService.isSaving || dealCardService.isEditorOpened"
/>

<form
  class="form-container"
  [hidden]="dealCardService.isLoading()"
  [formGroup]="dealCardService.dealForm"
  novalidate
  autocomplete="off"
>
  <div ngbAccordion class="accordion--custom">
    <div ngbAccordionItem [tmtAccordionState]="'deal-main-properties'">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.groups.mainProperties' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid">
            <div class="form-group">
              <label class="control-label">{{ 'shared2.props.client' | translate }}</label>
              <wp-select-box
                formControlName="organization"
                collection="Organizations"
                placeholder="{{ 'shared2.props.client' | translate }}"
              />
            </div>
            <div class="form-group">
              <label class="control-label">{{ 'shared2.props.manager' | translate }}</label>
              <wp-user-box
                formControlName="manager"
                placeholder="{{ 'shared2.props.manager' | translate }}"
              />
            </div>
            <div class="form-group">
              <label>
                {{ 'shared2.props.amount' | translate }}
              </label>
              <wp-number-box
                formControlName="amount"
                type="currency"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>
            <div class="form-group">
              <label class="control-label">{{ 'shared2.props.source' | translate }}</label>
              <wp-select-box
                formControlName="source"
                [directoryId]="systemDirectory.dealSource"
                placeholder="{{ 'shared2.props.source' | translate }}"
              />
            </div>
            <div class="form-group form-group--probability">
              <label>
                {{ 'shared2.props.probability' | translate }}
              </label>
              <wp-number-box
                formControlName="probability"
                type="percent"
                [max]="100"
                min="0"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div ngbAccordionItem [tmtAccordionState]="'deal-description'">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.props.description' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-group mb-0">
            <tmt-rich-editor-box
              formControlName="description"
              [emptyText]="'components.dealProfileComponent.props.descriptionPlaceholder'"
              (editing$)="dealCardService.isEditorOpened = $event"
              [hasMentions]="false"
              placeholder="{{ 'shared2.props.description' | translate }}"
            />
          </div>
        </div>
      </div>
    </div>
    @if (this.appService.getCustomFields('Deal').length) {
      <div ngbAccordionItem [tmtAccordionState]="'deal-advanced'">
        <h2 ngbAccordionHeader>
          <button class="group" ngbAccordionButton>
            {{ 'shared2.groups.advanced' | translate }}
          </button>
        </h2>
        <div ngbAccordionCollapse>
          <div ngbAccordionBody>
            <wp-custom-fields
              [formGroup]="dealCardService.dealForm"
              [isVisibleHr]="false"
              [entityType]="'Deal'"
              [propagationMode]="propagationMode.onExitFromEditing"
              class="full-width"
              columnClasses="col-4"
            />
          </div>
        </div>
      </div>
    }

    <div ngbAccordionItem [tmtAccordionState]="'deal-resolution'">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'components.dealProfileComponent.groups.resolution' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid">
            <div class="form-group form-group--resolution">
              <label class="control-label">{{ 'shared2.props.resolution' | translate }}</label>
              <wp-select-box
                formControlName="resolution"
                [directoryId]="systemDirectory.dealResolution"
                placeholder="{{ 'shared2.props.resolution' | translate }}"
              />
            </div>
            <div class="form-group form-group--resolutionComment">
              <label>{{ 'shared2.props.resolutionComment' | translate }}</label>
              <wp-multiline-text-box
                formControlName="resolutionComment"
                placeholder="{{ 'shared2.props.resolutionComment' | translate }}"
                [rows]="2"
                [propagationMode]="propagationMode.onExitFromEditing"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div ngbAccordionItem [tmtAccordionState]="'deal-organization'">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.groups.contacts' | translate }}
        </button>
      </h2>

      <div ngbAccordionCollapse>
        @if (dealCardService.deal.organization?.id) {
          <div ngbAccordionBody>
            <tmt-deal-contacts />
          </div>
        } @else {
          <div class="text-center text-body-secondary" ngbAccordionBody>
            {{ 'components.dealProfileComponent.props.noDisplayContacts' | translate }}
          </div>
        }
      </div>
    </div>
  </div>
</form>

@if (dealCardService.deal) {
  <ul ngbNav #nav="ngbNav" [(activeId)]="activeTab" class="nav nav-underline mt-3">
    <li [ngbNavItem]="'activity'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.activity' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-comments-tab
          [entityId]="dealCardService.deal.id"
          [collection]="commentedEntityCollectionType.Deals"
        ></wp-comments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'attachments'">
      <a ngbNavLink>
        {{ 'shared.comments.tabs.attachments' | translate }}
      </a>
      <div *ngbNavContent class="wide-tab-container mt-3">
        <wp-attachments-tab
          [entityId]="dealCardService.deal.id"
          [collection]="commentedEntityCollectionType.Deals"
        ></wp-attachments-tab>
      </div>
    </li>
    <li [ngbNavItem]="'linkedTasks'">
      <a ngbNavLink>
        {{ 'base.workflow.tab' | translate }}
      </a>
      <tmt-entity-workflow-tasks
        *ngbNavContent
        [entityId]="dealCardService.deal.id"
        class="mt-2 d-block"
      ></tmt-entity-workflow-tasks>
    </li>
    <li [ngbNavItem]="'stateHistory'">
      <a ngbNavLink>
        {{ 'base.stateHistory.tab' | translate }}
      </a>
      <tmt-state-history
        *ngbNavContent
        [entityId]="dealCardService.deal.id"
        class="mt-2 d-block"
      ></tmt-state-history>
    </li>
    <wp-sort class="ms-auto"></wp-sort>
  </ul>

  <div [ngbNavOutlet]="nav"></div>
}
