import { Component, OnInit } from '@angular/core';
import { Filter } from 'src/app/analytics/shared/models/filter/filter-model';
import { ReportSourceDescription } from 'src/app/analytics/shared/models/source-description/report-source-description.model';
import { AnalyticsService } from 'src/app/core/analytics.service';
import { ReportUserSettingsService } from './report-user-settings.service';
import { UserSettings } from 'src/app/analytics/shared/models/user-settings.model';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { ReportCardService } from '../report-card.service';
import { ReportPeriodService } from 'src/app/analytics/shared/report-period/report-period.service';

@Component({
  selector: 'wp-report-user-settings',
  templateUrl: './report-user-settings.component.html',
  styleUrls: ['./report-user-settings.component.scss'],
  standalone: false,
})
export class ReportUserSettingsComponent implements OnInit {
  reportTypeCode: string;
  filters: Filter[];
  period: DatePeriod;

  public sourceDescription: ReportSourceDescription;
  public filterAreaExpanded: boolean;
  public periodAreaExpanded: boolean;

  public filterTitle = '';

  constructor(
    public reportPeriodService: ReportPeriodService,
    private analyticsService: AnalyticsService,
    private reportUserSettings: ReportUserSettingsService,
    private reportCardService: ReportCardService,
  ) {}

  private loadSourceDescription(reportTypeCode: string) {
    this.analyticsService
      .getSourceDescription(reportTypeCode)
      .subscribe((description) => (this.sourceDescription = description));
  }

  public toggleFilterArea() {
    this.filterAreaExpanded = !this.filterAreaExpanded;

    if (this.filterAreaExpanded) {
      this.periodAreaExpanded = false;
    }
  }

  public togglePeriodArea() {
    this.periodAreaExpanded = !this.periodAreaExpanded;
    if (this.periodAreaExpanded) {
      this.filterAreaExpanded = false;
    }
  }

  public openPeriodArea() {
    this.periodAreaExpanded = true;
    if (this.periodAreaExpanded) {
      this.filterAreaExpanded = false;
    }
  }

  public filtersChanged(filters: Filter[]) {
    this.reportUserSettings.saveFilters(filters);
  }

  // public periodChanged(period: DatePeriod) {
  //   this.reportUserSettings.saveDatePeriod(period);
  // }

  public onFilterTitleChanged(title: string) {
    this.filterTitle = title;
  }

  ngOnInit(): void {
    this.reportUserSettings.openPeriodArea$.subscribe(() =>
      this.openPeriodArea(),
    );

    this.reportUserSettings.userSettings$.subscribe(
      (userSettings: UserSettings) => {
        this.period = userSettings.period as DatePeriod;
        this.filters = userSettings.filters;
      },
    );

    this.reportCardService.report$.subscribe((report) => {
      this.filterAreaExpanded = false;
      this.periodAreaExpanded = false;

      if (report.reportType) {
        this.reportTypeCode = report.reportType.code;
        this.loadSourceDescription(report.reportType.code);
      }
    });
  }
}
