import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-calculated-field-creation',
  templateUrl: './calculated-field-creation.component.html',
  standalone: false,
})
export class CalculatedFieldCreationComponent {
  isSaving = false;
  form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    reportType: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    const data = {
      name: this.form.value.name,
      reportTypeId: this.form.value.reportType.id,
    };

    this.data
      .collection('CalculatedFields')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal(
            'analytics.calculatedFields.creation.messages.fieldIsCreated',
          );
          this.state.go('calculatedField', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
