import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  DestroyRef,
  inject,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Constants } from 'src/app/shared/globals/constants';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import {
  InvoiceTemplate,
  PageSize,
  PageOrientation,
} from 'src/app/shared/models/entities/settings/invoice-template.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { TenantSetting } from 'src/app/shared/models/entities/settings/multitenant/tenant-settings.model';
import { Subject } from 'rxjs';
import { DomSanitizer } from '@angular/platform-browser';
import { HttpClient } from '@angular/common/http';
import { AppConfigService } from 'src/app/core/app-config.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
  selector: 'wp-invoice-template-card',
  templateUrl: './invoice-template-card.component.html',
  standalone: false,
})
export class InvoiceTemplateCardComponent implements OnInit, OnDestroy {
  @Input() private entityId: string;

  public state: CardState;
  public isSaving = false;
  public readonly: boolean;
  public useVat: boolean;

  public pageOrientations: NamedEntity[];
  public pageSizes: NamedEntity[];
  public logoIsLoading: boolean;
  public hasLogo: boolean;
  public logoSubject = new Subject<any>();
  public logo$ = this.logoSubject.asObservable();

  public invoiceTemplateForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    companyName: ['', Validators.maxLength(150)],
    companyAddress: ['', Validators.maxLength(250)],
    companyPhone: ['', Validators.maxLength(150)],
    paymentRequisites: ['', Validators.maxLength(1000)],
    signaturePosts: ['', Validators.maxLength(500)],
    signatureNames: ['', Validators.maxLength(500)],
    isDefault: false,
    isActive: false,
    pageSize: ['', Validators.required],
    pageOrientation: ['', Validators.required],
    vatRate: null,
  });
  private objectUrl: any;
  private destroyRef = inject(DestroyRef);

  constructor(
    private sanitizer: DomSanitizer,
    private http: HttpClient,
    private data: DataService,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  public ngOnInit(): void {
    this.pageSizes = [
      { id: PageSize.A4, name: PageSize.A4 },
      { id: PageSize.Letter, name: PageSize.Letter },
    ];

    this.pageOrientations = [
      {
        id: PageOrientation.Portrait,
        name: this.translate.instant(
          `enums.pageOrientation.${PageOrientation.Portrait}`,
        ),
      },
      {
        id: PageOrientation.Landscape,
        name: this.translate.instant(
          `enums.pageOrientation.${PageOrientation.Landscape}`,
        ),
      },
    ];

    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: this.setAsDefault,
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  public ngOnDestroy(): void {
    URL.revokeObjectURL(this.objectUrl);
    this.objectUrl = null;
  }

  /** Saves Data. */
  public save = (): void => {
    this.invoiceTemplateForm.markAllAsTouched();

    if (this.invoiceTemplateForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const formValue = this.invoiceTemplateForm.value;

    const data: any = {
      name: formValue.name,
      isActive: formValue.isActive,
      companyName: formValue.companyName,
      companyAddress: formValue.companyAddress,
      companyPhone: formValue.companyPhone,
      paymentRequisites: formValue.paymentRequisites,
      signaturePosts: formValue.signaturePosts,
      signatureNames: formValue.signatureNames,
      pageSize: formValue.pageSize.id,
      pageOrientation: formValue.pageOrientation.id,
      vatRateId: formValue.vatRate?.id ?? null,
    };

    this.data
      .collection('InvoiceTemplates')
      .entity(this.entityId)
      .patch(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.invoiceTemplateForm.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.invoiceTemplates.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  };

  /**
   * Event listener on adding file.
   * @param event
   */
  public onFileAdded(event: any): void {
    const file = event.addedFiles[0];

    if (!file) {
      this.notification.warningLocal(
        'settings.invoiceTemplates.card.messages.logoIsWrong',
      );
      return;
    }

    const formData: FormData = new FormData();
    this.logoIsLoading = true;
    formData.append('logo', file, file.name);

    this.data
      .collection('InvoiceTemplates')
      .entity(this.entityId)
      .action('WP.UploadLogo')
      .execute(formData)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.logoIsLoading = false;
          this.notification.successLocal(
            'settings.invoiceTemplates.card.messages.logotypeSaved',
          );
          this.hasLogo = true;
          this.loadLogo();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.logoIsLoading = false;
        },
      });
  }

  /** Removes Logo. */
  public removeLogo(): void {
    this.logoIsLoading = false;
    this.data
      .collection('InvoiceTemplates')
      .entity(this.entityId)
      .action('WP.DeleteLogo')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.logoIsLoading = false;
          this.hasLogo = false;
          this.notification.successLocal(
            'settings.invoiceTemplates.card.messages.logoRemoved',
          );
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.logoIsLoading = false;
        },
      });
  }

  private load = (): void => {
    this.state = CardState.Loading;
    this.invoiceTemplateForm.markAsPristine();
    this.invoiceTemplateForm.markAsUntouched();

    this.data
      .collection('InvoiceTemplates')
      .entity(this.entityId)
      .get<InvoiceTemplate>({
        expand: { vatRate: { select: ['id', 'name'] } },
      })
      .subscribe({
        next: (invoiceTemplate: InvoiceTemplate) => {
          this.invoiceTemplateForm.patchValue(invoiceTemplate);

          this.invoiceTemplateForm.controls.pageSize.setValue(
            this.pageSizes.find((pz) => pz.id === invoiceTemplate.pageSize),
          );

          this.invoiceTemplateForm.controls.pageOrientation.setValue(
            this.pageOrientations.find(
              (po) => po.id === invoiceTemplate.pageOrientation,
            ),
          );

          this.readonly = !invoiceTemplate.editAllowed;
          this.readonly
            ? this.invoiceTemplateForm.disable()
            : this.invoiceTemplateForm.enable();
          this.actionService.action('save').isShown = !this.readonly;

          this.actionService.action('setAsDefault').isShown =
            !invoiceTemplate.isDefault && !this.readonly;

          this.hasLogo = invoiceTemplate.hasLogo;
          if (invoiceTemplate.hasLogo) {
            this.loadLogo();
          }

          this.state = CardState.Ready;

          this.navigationService.addRouteSegment({
            id: invoiceTemplate.id,
            title: invoiceTemplate.name,
          });
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });

    this.data
      .collection('TenantSettings')
      .function('WP.GetSingle')
      .get<TenantSetting>(null, {
        select: ['id', 'useVat'],
      })
      .subscribe({
        next: (data) => {
          this.useVat = data.useVat;
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state = CardState.Error;
        },
      });
  };

  private reload(): void {
    if (!this.invoiceTemplateForm.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  private setAsDefault = (): void => {
    this.actionService.action('setAsDefault').start();
    this.data

      .collection('InvoiceTemplates')
      .entity(this.entityId)
      .action('WP.SetAsDefault')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.invoiceTemplates.card.messages.hasBeenSetByDefault',
          );

          this.actionService.action('setAsDefault').stop();
          this.actionService.action('setAsDefault').isShown = false;
          this.invoiceTemplateForm.get('isDefault').setValue(true);
        },
        error: (error: Exception) => {
          this.actionService.action('setAsDefault').stop();
          this.notification.error(error.message);
        },
      });
  };

  private loadLogo(): void {
    this.objectUrl = null;

    const url = `${AppConfigService.config.api.url}/OData/InvoiceTemplates(${this.entityId})/WP.GetLogo`;
    this.http.get(url, { responseType: 'blob' }).subscribe((m) => {
      this.objectUrl = URL.createObjectURL(m);
      this.logoSubject.next(
        this.sanitizer.bypassSecurityTrustUrl(this.objectUrl),
      );
    });
  }
}
