import {
  Component,
  forwardRef,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TimesheetCardService } from '../../core/timesheet-card.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpTimesheetActivity]',
  templateUrl: './timesheet-activity.component.html',
  styleUrls: ['./timesheet-activity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimesheetActivityComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimesheetActivityComponent implements ControlValueAccessor {
  constructor(
    private service: TimesheetCardService,
    private ref: ChangeDetectorRef,
  ) {}
  public activity: NamedEntity;
  public readonly: boolean;
  public areaExpanded = false;

  public isLoading: boolean;
  public activities: readonly NamedEntity[];
  public selectedRow: NamedEntity;
  public propagateChange = (_: any) => null;

  writeValue(obj: any): void {
    this.activity = obj;
    this.ref.markForCheck();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched = (fn: any) => null;

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  /** Открыть область выбора. */
  public openArea() {
    if (this.readonly) {
      return;
    }
    this.areaExpanded = !this.areaExpanded;

    this.isLoading = true;
    this.service.activities$.subscribe((activities) => {
      this.activities = activities;
      this.isLoading = false;
      this.ref.markForCheck();
    });
  }

  public cancel() {
    if (this.areaExpanded) {
      this.areaExpanded = false;
    }
  }

  selectRow(row: NamedEntity) {
    this.selectedRow = row;
  }

  public clickRow(activity: NamedEntity) {
    this.activity = activity;
    this.propagateChange(activity);
    this.cancel();
  }
}
