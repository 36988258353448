import {
  Component,
  OnInit,
  Input,
  Optional,
  Inject,
  OnDestroy,
} from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormArray,
} from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { Exception } from 'src/app/shared/models/exception';
import { BookingEntry } from 'src/app/shared/models/entities/resources/booking-entry.model';
import { BookingService } from '../../core/booking.service';
import { PlanningMethod } from 'src/app/shared/models/enums/planning-method.enum';
import { BookingDataService } from '../../core/booking-data.service';
import { BookingDetailEntry } from 'src/app/shared/models/entities/resources/booking-detail-entry.model';
import { LocalConfigService } from 'src/app/core/local-config.service';
import { BookingSettings } from '../../models/booking.settings';
import { sumBy } from 'lodash';
import { BookingEntryFormService } from './booking-entry-form.service';
import { ASSISTANT_TEAM_MEMBER_ID } from 'src/app/booking/booking-assistant/models/booking-assistant-settings.model';

@Component({
  selector: 'wp-booking-entry-form',
  templateUrl: './booking-entry-form.component.html',
  providers: [BookingEntryFormService],
  standalone: false,
})
export class BookingEntryFormComponent implements OnInit, OnDestroy {
  @Input() bookingEntry: BookingEntry;
  @Input() resource: NamedEntity;
  @Input() mode: 'create' | 'edit' = 'create';
  @Input() readonly: boolean;

  public projectsQuery: any = {
    filter: [],
  };

  planningMethods: NamedEntity[] = [];
  planningMethod = PlanningMethod;

  isSaving = false;

  form = this.fb.group({
    from: [null, Validators.required],
    to: [null, Validators.required],
    project: [null, Validators.required],
    entries: this.fb.array([]),
    planningMethod: [null],
    requiredHours: [0, Validators.required],
    requiredSchedulePercent: [0, Validators.required],
    bookedHours: [0],
    type: [null, Validators.required],
  });

  public actionsAllowed = {
    switchToHardAllowed: false,
    switchToSoftAllowed: false,
  };

  private destroyed$ = new Subject<void>();

  get entries(): UntypedFormArray {
    return this.form.controls.entries as UntypedFormArray;
  }

  get updatedBookingEntry(): BookingEntry {
    const updatedEntry = this.form.getRawValue() as BookingEntry;
    updatedEntry.planningMethod = this.form.value.planningMethod?.id ?? null;
    updatedEntry.resourceId = this.bookingEntry.resourceId;
    return updatedEntry;
  }

  constructor(
    @Optional() @Inject('assistantRequestId') public resourceRequestId,
    @Optional() @Inject(ASSISTANT_TEAM_MEMBER_ID) public teamMemberId, // TODO check it after DEV-657
    public bookingDataService: BookingDataService,
    public bookingEntryFormService: BookingEntryFormService,
    private bookingService: BookingService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private localConfigService: LocalConfigService,
  ) {}

  public ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = {
      bookingEntry: {
        id: this.bookingEntry.id,
        rowVersion: this.bookingEntry.rowVersion,
        from: this.form.value.from,
        to: this.form.getRawValue().to,
        projectId: this.form.getRawValue().project.id,
        resourceId: this.resource.id,
        type: this.form.value.type,
        requiredHours: this.form.value.requiredHours,
        requiredSchedulePercent: this.form.value.requiredSchedulePercent,
        planningMethod: this.form.value.planningMethod?.id ?? null,
        bookedHours: 0,
        resourceRequestId: this.resourceRequestId,
        detailEntries: (this.entries.value as BookingDetailEntry[])
          .filter((e) => e.hours > 0)
          .map((e) => ({
            id: e.id,
            hours: e.hours,
            date: e.date,
          })),
      },

      planningScale: this.bookingService.settings.planningScale,
    };

    const collection = this.data.collection('BookingEntries');

    const action =
      this.mode === 'create'
        ? collection.action('Create')
        : collection.entity(this.bookingEntry.id).action('UpdateWithScaling');

    action.execute(data, this.bookingDataService.bookingQuery).subscribe({
      next: (savedEntry: BookingEntry) => {
        this.isSaving = false;

        const updatedEntry = this.updatedBookingEntry;

        // На сервере может обновиться только период и детальный  раздел.
        updatedEntry.rowVersion = savedEntry.rowVersion;
        updatedEntry.bookedHours = savedEntry.bookedHours;
        updatedEntry.detailEntries = savedEntry.detailEntries;
        updatedEntry.from = savedEntry.from;
        updatedEntry.to = savedEntry.to;
        updatedEntry.resourceRequest = this.bookingEntry.resourceRequest;

        const settings = this.localConfigService.getConfig(BookingSettings);
        settings.defaultPlanningMethod = updatedEntry.planningMethod;
        settings.defaultBookingType = updatedEntry.type;
        this.localConfigService.setConfig(BookingSettings, settings);

        this.activeModal.close(updatedEntry);
      },
      error: (error: Exception) => {
        this.isSaving = false;
        this.bookingService.errorHandlerOnSave(
          error,
          this.resource,
          this.form.controls['project'].getRawValue(),
          () => this.ok(),
        );
      },
    });
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  public closeForm() {
    this.activeModal.dismiss('escape');
  }

  private updateProjectsQuery() {
    this.projectsQuery.filter = [];
  }

  private loadAllowedActions() {
    if (this.bookingDataService.isReadonlyMode()) {
      this.actionsAllowed = {
        switchToHardAllowed: false,
        switchToSoftAllowed: false,
      };
      return;
    }

    if (this.mode === 'create') {
      this.actionsAllowed = {
        switchToHardAllowed: true,
        switchToSoftAllowed: true,
      };
      this.form.controls.type.enable();
    } else {
      this.data
        .collection('BookingEntries')
        .entity(this.bookingEntry.id)
        .function('GetAllowedActions')
        .get()
        .subscribe(
          (response: {
            switchToHardAllowed: boolean;
            switchToSoftAllowed: boolean;
          }) => {
            this.actionsAllowed = response;

            if (
              this.actionsAllowed.switchToHardAllowed ||
              this.actionsAllowed.switchToSoftAllowed
            ) {
              this.form.controls.type.enable();
            }
          },
        );
    }
  }

  ngOnInit(): void {
    if (this.readonly) {
      this.form.disable();
    } else {
      this.loadAllowedActions();
      this.updateProjectsQuery();

      this.form.controls.planningMethod.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          if (
            this.form.controls.planningMethod.value?.id ===
              PlanningMethod.FrontLoad ||
            this.bookingDataService.isReadonlyMode()
          ) {
            this.form.controls.to.disable({ emitEvent: false });
          } else {
            this.form.controls.to.enable({ emitEvent: false });
          }
        });
    }

    for (const planningMethod of Object.keys(PlanningMethod)) {
      this.planningMethods.push({
        id: planningMethod,
        name: this.translate.instant(`enums.planningMethod.${planningMethod}`),
      });
    }

    this.bookingEntry.from = this.bookingEntry.fromLx.toISODate();
    this.bookingEntry.to = this.bookingEntry.toLx.toISODate();

    this.form.patchValue(this.bookingEntry);

    let planningMethodId = this.bookingEntry.planningMethod;
    if (this.mode === 'create') {
      const settings = this.localConfigService.getConfig(BookingSettings);
      planningMethodId = settings.defaultPlanningMethod;
      this.form.controls.type.setValue(settings.defaultBookingType);
    }

    let currentPlanningMethod = this.planningMethods.find(
      (x) => x.id === planningMethodId,
    );

    this.form.controls.planningMethod.setValue(currentPlanningMethod);
    this.entries.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      const sum = sumBy(
        this.entries.value as BookingDetailEntry[],
        (e) => e.hours ?? 0,
      );

      this.form.controls.bookedHours.patchValue(sum, { emitEvent: false });
    });

    this.form.controls.bookedHours.disable();

    if (this.bookingEntry.resourceRequest) {
      this.form.controls.project.disable();
    }

    if (this.resourceRequestId && this.mode === 'create') {
      this.form.controls.project.disable();

      currentPlanningMethod = this.planningMethods.find(
        (x) => x.id === PlanningMethod.Manual,
      );
      this.form.controls.planningMethod.setValue(currentPlanningMethod);

      this.data
        .collection('ResourceRequests')
        .entity(this.resourceRequestId)
        .get({
          select: ['id', 'name'],
          expand: { project: { select: ['id', 'name'] } },
        })
        .subscribe((response) => {
          this.form.controls.project.setValue((response as any).project);
          this.bookingEntry.resourceRequest = response as NamedEntity;
        });
    }

    if (this.teamMemberId && this.mode === 'create') {
      currentPlanningMethod = this.planningMethods.find(
        (x) => x.id === PlanningMethod.Manual,
      );
      this.form.controls.planningMethod.setValue(currentPlanningMethod);

      this.data
        .collection('ProjectTeamMembers')
        .entity(this.teamMemberId)
        .get({
          select: ['id', 'name'],
          expand: { project: { select: ['id', 'name'] } },
        })
        .subscribe((response) => {
          this.form.controls.project.setValue((response as any).project);
          this.bookingEntry.resourceRequest = response as NamedEntity;
        });
    }

    if (this.bookingDataService.isReadonlyMode()) {
      this.form.disable();
      return;
    }

    FormHelper.controlDatePair(
      this.form,
      'from',
      'to',
      takeUntil(this.destroyed$),
      365,
    );
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
