import { Component, OnInit, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';

/** Контрол ввода эл. почты. */
@Component({
  selector: 'wp-email-box',
  templateUrl: './email-box.component.html',
  styleUrls: ['./email-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EmailBoxComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class EmailBoxComponent implements OnInit, ControlValueAccessor {
  constructor(
    private app: AppService,
    private data: DataService,
  ) {
    this.domains = [];
  }
  @Input() placeholder: string;
  @Input() readonly: boolean;

  domains: string[] = [];

  domain = '';
  value = '';

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  writeValue(value: any): void {
    if (!value) {
      this.value = '';
      this.domain = this.app.session.configuration.primaryDomain;
    } else {
      const atIndex = value.indexOf('@');
      this.value = value.substring(0, atIndex);
      this.domain = value.substring(atIndex + 1);
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  onBlur() {
    this.propagateTouch();
  }

  onChange(newValue: string) {
    this.value = newValue;
    this.setValue();
  }

  setValue() {
    this.propagateChange(`${this.value}@${this.domain}`);
  }

  setDomain(domain: string) {
    this.domain = domain;
    this.setValue();
  }

  ngOnInit() {
    this.data.model
      .function('GetActiveDomains')
      .query()
      .subscribe((domains: string[]) => {
        this.domains = domains;
      });
  }
}
