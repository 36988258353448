import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { ProgramCardService } from '../program-card.service';
import { PnlService } from 'src/app/shared-features/pnl/pnl.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';

@Component({
  selector: 'wp-program-pnl',
  templateUrl: './program-pnl.component.html',
  providers: [PnlService],
  standalone: false,
})
export class ProgramPnlComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  private reloadListener: Subscription;

  constructor(
    private pnlService: PnlService,
    private service: ProgramCardService,
    private actionService: ActionPanelService,
  ) {}

  ngOnInit(): void {
    this.actionService.set([this.service.settingsAction]);
    this.reloadListener = this.service.reloadTab$.subscribe(() => {
      this.pnlService.reload();
    });
  }

  ngOnDestroy(): void {
    this.reloadListener.unsubscribe();
  }
}
