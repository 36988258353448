<tmt-form-header [name]="name"></tmt-form-header>
<wp-loading-indicator [state]="state"></wp-loading-indicator>
<tmt-not-found [state]="state"></tmt-not-found
><wp-data-not-saved-indicator [form]="cardForm"></wp-data-not-saved-indicator>

<form [formGroup]="cardForm" [hidden]="state !== 'Ready'" class="form mt-3" style="width: 600px">
  <div class="form-group">
    <label>{{ 'settings.timesheetPeriods.card.props.type.label' | translate }}</label>
    <wp-select-box
      formControlName="periodType"
      collection="TimeSheetPeriodTypes"
      placeholder="{{ 'settings.timesheetPeriods.card.props.type.ph' | translate }}"
    >
    </wp-select-box>
  </div>

  <div class="form-group">
    <label>{{ 'settings.timesheetPeriods.card.props.scope.label' | translate }}</label>
    <wp-select-box
      formControlName="periodScope"
      collection="TimeSheetPeriodScopes"
      placeholder="{{ 'settings.timesheetPeriods.card.props.scope.ph' | translate }}"
    >
    </wp-select-box>
  </div>

  <div class="form-group">
    <label>{{ 'settings.timesheetPeriods.card.props.effectiveDate' | translate }}</label>
    <wp-date-box formControlName="effectiveDate"></wp-date-box>
  </div>

  <div class="form-group" *ngIf="cardForm.getRawValue().periodType?.code === 'Custom'">
    <label>{{ 'settings.timesheetPeriods.card.props.expiryDate' | translate }}</label>
    <wp-date-box formControlName="expiryDate"></wp-date-box>
  </div>

  <div class="form-group" *ngIf="cardForm.getRawValue().periodType?.code === 'Monthly'">
    <label>{{ 'settings.timesheetPeriods.card.props.monthInitialDay' | translate }}</label>
    <wp-number-box formControlName="monthInitialDay" type="integer"></wp-number-box>
  </div>

  <div class="form-group" *ngIf="cardForm.getRawValue().periodType?.code == 'SemiMonthly'">
    <label>{{ 'settings.timesheetPeriods.card.props.firstDayInSemiMonthly' | translate }}</label>
    <wp-number-box formControlName="monthInitialDay" type="integer"></wp-number-box>
  </div>

  <div class="form-group" *ngIf="cardForm.getRawValue().periodType?.code === 'SemiMonthly'">
    <label>{{ 'settings.timesheetPeriods.card.props.secondDayInSemiMonthly' | translate }}</label>
    <wp-number-box formControlName="monthHalfDay" type="integer"></wp-number-box>
  </div>

  <div
    class="form-group"
    *ngIf="
      cardForm.getRawValue().periodType?.code === 'Weekly' ||
      cardForm.getRawValue().periodType?.code === 'WeeklyEndOfMonth'
    "
  >
    <label>{{ 'settings.timesheetPeriods.card.props.dayOfWeek' | translate }}</label>

    <wp-select-box
      [values]="daysOfWeek"
      formControlName="dayOfWeekValue"
      placeholder="{{ 'settings.timesheetPeriods.card.props.dayOfWeek' | translate }}"
    ></wp-select-box>
  </div>

  <div class="form-group" *ngIf="cardForm.getRawValue().periodScope?.code === 'Department'">
    <label>{{ 'settings.timesheetPeriods.card.props.department' | translate }}</label>
    <tmt-hierarchical-box
      placeholder="{{ 'settings.timesheetPeriods.card.props.department' | translate }}"
      formControlName="department"
      collection="Departments"
      parentIdKey="leadDepartmentId"
    />
  </div>

  <div class="form-check" title="{{ 'shared.props.isActive.hint' | translate }}">
    <input formControlName="isActive" type="checkbox" class="form-check-input" id="isActive" />
    <label class="form-check-label" for="isActive">
      {{ 'shared.props.isActive.label' | translate }}
    </label>
  </div>
  <div class="actions" *ngIf="!readonly">
    <button
      type="button"
      class="btn btn-primary"
      wpButtonSpinner
      [isBusy]="isSaving"
      (click)="save()"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
  </div>
</form>
