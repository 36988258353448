<div
  wpFreezeTable
  class="disable-selecting"
  leftWidthStrategy="byTable"
  rightWidthStrategy="byTable"
>
  <div name="toolbar">
    <div class="toolbar-container pt-0">
      @if (!readonly) {
        <div class="group">
          @if (navigationService.getAppName() !== 'my') {
            <button
              type="button"
              class="btn btn-default"
              title="{{ 'timesheets.card.actions.addLine' | translate }}"
              (click)="createLine()"
              data-test="addLine"
            >
              <i class="bi bi-plus-lg bi-15"></i>&nbsp;{{
                'timesheets.card.actions.addLine' | translate
              }}
            </button>
          } @else {
            <div class="btn-group">
              <button
                type="button"
                class="btn btn-default"
                (click)="createLine()"
                data-test="addLine"
              >
                <span class="bi bi-plus-lg bi-15"></span>&nbsp;{{
                  'timesheets.card.actions.addLine' | translate
                }}
              </button>
              <div class="btn-group" ngbDropdown role="group">
                <div>
                  <div class="dropdown-menu" ngbDropdownMenu>
                    @for (command of addingCommands; track command) {
                      <button
                        ngbDropdownItem
                        type="button"
                        (click)="command.handlerFn()"
                        [attr.data-test]="command.name"
                      >
                        {{ command.label | translate }}
                      </button>
                    }
                  </div>
                </div>
                <button
                  type="button"
                  class="btn btn-default dropdown-toggle-split"
                  ngbDropdownToggle
                ></button>
              </div>
            </div>
          }
        </div>
      }

      @if (hasSelectedLines() && !readonly) {
        <div class="group icons">
          <button
            type="button"
            class="btn btn-default"
            title="{{ 'timesheets.card.actions.removeLines' | translate }}"
            (click)="removeLines()"
            data-test="removeLines"
          >
            <span class="bi bi-trash" aria-hidden="true"></span>
          </button>
        </div>

        <div class="group icons">
          <button
            type="button"
            class="btn btn-default"
            title="{{ 'timesheets.card.actions.moveLinesUp' | translate }}"
            (click)="moveLines('Up')"
            data-test="moveLinesUp"
          >
            <i class="bi bi-arrow-bar-up" aria-hidden="true"></i>
          </button>

          <button
            type="button"
            class="btn btn-default"
            title="{{ 'timesheets.card.actions.moveLinesDown' | translate }}"
            (click)="moveLines('Down')"
            data-test="moveLinesDown"
          >
            <i class="bi bi-arrow-bar-down" aria-hidden="true"></i>
          </button>
        </div>
      }

      <div class="group">
        @if (hasSelectedLines()) {
          <button
            type="button"
            class="btn btn-default"
            title="{{ 'timesheets.card.actions.copyLinesToClipboard' | translate }}"
            (click)="copyLinesToStorage()"
            data-test="copy"
          >
            {{ 'shared.actions.copy' | translate }}
          </button>
        }

        @if (hasLinesInStorage() && !readonly) {
          <button
            type="button"
            class="btn btn-default icon"
            title="{{ 'timesheets.card.actions.pasteLinesToClipboard' | translate }}"
            (click)="pasteLinesFromStorage()"
            data-test="paste"
          >
            <i class="bi bi-clipboard" aria-hidden="true"></i>
          </button>
        }
      </div>
    </div>
  </div>

  <div name="left">
    <div name="scroll-table-header">
      <table class="table table-bordered" [ngStyle]="{ width: fixTableWidth + 'px' }">
        <ng-container *ngTemplateOutlet="colGroup" />

        <thead>
          <tr>
            <th title="{{ 'timesheets.card.actions.selectAllLines' | translate }}" class="checkbox">
              <div class="wp-checkbox">
                <label>
                  <input type="checkbox" [formControl]="service.selectAllControl" />
                  <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                </label>
              </div>
            </th>

            @if (template?.showClient) {
              <th class="trim" title="{{ 'timesheets.card.columns.task.hint' | translate }}">
                {{ 'timesheets.card.columns.task.header' | translate }}
              </th>
            }

            @if (!template?.showClient) {
              <th
                class="trim"
                title="{{ 'timesheets.card.columns.taskWithoutClient.hint' | translate }}"
              >
                {{ 'timesheets.card.columns.taskWithoutClient.header' | translate }}
              </th>
            }

            @if (template?.showActivity) {
              <th title="{{ 'timesheets.card.columns.activity.hint' | translate }}">
                {{ 'timesheets.card.columns.activity.header' | translate }}
              </th>
            }

            @if (template?.showRole) {
              <th title="{{ 'timesheets.card.columns.role.hint' | translate }}">
                {{ 'timesheets.card.columns.role.header' | translate }}
              </th>
            }

            @if (template?.showProjectCostCenter) {
              <th title="{{ 'timesheets.card.columns.projectCostCenter.hint' | translate }}">
                {{ 'timesheets.card.columns.projectCostCenter.header' | translate }}
              </th>
            }

            @if (template?.showTariff) {
              <th title="{{ 'timesheets.card.columns.projectTariff.hint' | translate }}">
                {{ 'timesheets.card.columns.projectTariff.header' | translate }}
              </th>
            }

            @for (field of service.lineCustomFields; track field) {
              <th title="{{ field.viewConfiguration.label }}" class="trim">
                {{ field.viewConfiguration.label }}
              </th>
            }
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table class="table table-bordered" [ngStyle]="{ width: fixTableWidth + 'px' }">
        <ng-container *ngTemplateOutlet="colGroup" />

        <tbody>
          @if (!service.dataLines.controls.length) {
            <tr>
              <td [attr.colspan]="fixTableColumnCount" class="empty-information">
                @if (readonly) {
                  <p>
                    {{ 'timesheets.card.empty.emptyInReadonly' | translate }}
                  </p>
                } @else {
                  <p>
                    {{ 'timesheets.card.empty.empty' | translate | translateCut: 0 }}
                    <br />

                    <button class="btn btn-link" (click)="createLine()">
                      <span class="fw-semibold">
                        {{ 'timesheets.card.empty.empty' | translate | translateCut: 1 }}
                      </span>
                    </button>

                    {{ 'timesheets.card.empty.empty' | translate | translateCut: 2 }}

                    <button class="btn btn-link" (click)="copyLines()">
                      <span class="fw-semibold">
                        {{ 'timesheets.card.empty.empty' | translate | translateCut: 3 }}
                      </span>
                    </button>

                    {{ 'timesheets.card.empty.empty' | translate | translateCut: 4 }}
                  </p>
                }
              </td>
            </tr>
          }

          @for (formGroup of service.dataLines.controls; track $index) {
            <tr [formGroup]="formGroup">
              <td class="checkbox">
                <div class="wp-checkbox">
                  <label>
                    <input
                      type="checkbox"
                      [formControl]="service.selectControls.controls[$index]"
                    />
                    <span class="cr"><i class="cr-icon bi bi-check-lg"></i></span>
                  </label>
                </div>
              </td>

              <td
                wpTimesheetTask
                class="control"
                formControlName="task"
                [showClient]="template?.showClient"
                [userId]="service.timesheet?.user.id"
                [timesheetTemplateId]="service.timesheet?.templateId"
              ></td>

              @if (template?.showActivity) {
                <td class="control" wpTimesheetActivity formControlName="activity"></td>
              }

              @if (service.timesheet?.template.showRole) {
                <td
                  wpTimesheetRole
                  class="control"
                  formControlName="role"
                  [userId]="service.timesheet.user.id"
                  [task]="formGroup.get('task').value"
                ></td>
              }

              @if (service.timesheet?.template.showProjectCostCenter) {
                <td
                  tmtTimesheetCostCenter
                  class="control"
                  [formControl]="formGroup.get('projectCostCenter')"
                  [userId]="service.timesheet.user.id"
                  [projectId]="formGroup.get('task').value?.project?.id"
                ></td>
              }

              @if (service.timesheet?.template.showTariff) {
                <td
                  tmtTimesheetLineTariff
                  class="control"
                  [formControl]="formGroup.get('projectTariff')"
                  [userId]="service.timesheet.user.id"
                  [projectId]="formGroup.get('task').value?.project?.id"
                ></td>
              }

              @for (field of service.lineCustomFields; track field) {
                <td class="control control--textarea">
                  <wp-text-box
                    [formControlName]="field.name"
                    [propagationMode]="propagationMode"
                    [readonly]="field.isOnlyForApi"
                    [placeholder]="field.viewConfiguration.placeholder"
                  />
                </td>
              }
            </tr>
          }

          @if (service.issueLines.controls?.length > 0) {
            <tr>
              <th [attr.colspan]="fixTableColumnCount" class="time-off-header text-uppercase">
                {{ 'timesheets.card.issuesSection' | translate }}
              </th>
            </tr>
          }

          @for (issueGroup of service.issueLines.controls; track issueGroup) {
            <tr [formGroup]="issueGroup" class="issue-line">
              <td class="checkbox"></td>

              @if (issueGroup.getRawValue().task.project) {
                <td
                  wpTimesheetTask
                  class="control"
                  formControlName="task"
                  [showClient]="template?.showClient"
                  [userId]="service.timesheet.user?.id"
                  [timesheetTemplateId]="service.timesheet?.templateId"
                ></td>
              } @else {
                <td class="line-title p-1">
                  {{ 'components.tableViewComponent.props.noProject' | translate }}
                </td>
              }

              @if (template?.showActivity) {
                <td class="control" wpTimesheetActivity formControlName="activity"></td>
              }

              @if (service.timesheet?.template.showRole) {
                <td
                  wpTimesheetRole
                  class="control"
                  formControlName="role"
                  [userId]="service.timesheet.user.id"
                  [task]="issueGroup.get('task').value"
                ></td>
              }

              @if (service.timesheet?.template.showProjectCostCenter) {
                <td
                  tmtTimesheetCostCenter
                  class="control"
                  formControlName="projectCostCenter"
                  [userId]="service.timesheet.user.id"
                  [projectId]="issueGroup.get('task').value?.project?.id"
                ></td>
              }

              @if (service.timesheet?.template.showTariff) {
                <td
                  tmtTimesheetLineTariff
                  class="control"
                  [attr.colspan]="service.lineCustomFields.length + 1"
                  formControlName="projectTariff"
                  [userId]="service.timesheet.user.id"
                  [projectId]="issueGroup.get('task').value?.project?.id"
                ></td>
              }
            </tr>
          }

          @if (timeOffRequests?.length > 0) {
            <tr>
              <th [attr.colspan]="fixTableColumnCount" class="time-off-header">
                {{ 'timesheets.card.timeOfRequestsSection' | translate }}
              </th>
            </tr>
          }

          @for (request of timeOffRequests; track request.id) {
            <tr>
              <td [attr.colspan]="fixTableColumnCount" class="trim line-title">
                <a
                  uiSref="timeOffRequest"
                  [uiParams]="{
                    entityId: request.id,
                    routeMode: routeMode.continue,
                    navigation: navigationService.selectedNavigationItem?.name,
                    route: navigationService.storedRoute,
                  }"
                >
                  {{ request.timeOffType.name }}
                </a>
                <tmt-state-badge class="ms-2" [state]="request.state" />
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer">
      <table class="table table-bordered" [ngStyle]="{ width: fixTableWidth + 'px' }">
        <tfoot>
          <tr>
            <td [attr.colspan]="fixTableColumnCount" style="vertical-align: middle"></td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>

  <div
    name="right"
    [ngStyle]="{ 'margin-left': fixTableWidth - 1 + 'px' }"
    style="width: fit-content"
  >
    <div name="scroll-table-header">
      <table [ngStyle]="{ width: dataTableWidth + 'px' }" class="table table-bordered">
        <thead>
          <tr>
            @for (day of days; track day) {
              <th
                class="day"
                [ngClass]="{
                  'nonworking-day': day.isException,
                  'current-day': day.isToday,
                }"
                title="{{ day.hint }}"
              >
                {{ day.header }}
              </th>
            }

            <th class="total">
              {{ 'timesheets.total' | translate }}
            </th>
          </tr>
        </thead>
      </table>
    </div>

    <div name="scroll-table-body">
      <table [ngStyle]="{ width: dataTableWidth + 'px' }" class="table table-bordered">
        <colgroup>
          @for (day of days; track day) {
            <col style="width: 47px" />
          }
          <col />
        </colgroup>

        <tbody>
          @if (!service.dataLines.controls.length) {
            <tr>
              <td [attr.colspan]="days.length + 1" style="height: 150px"></td>
            </tr>
          }

          @for (formGroup of service.dataLines.controls; track lineIndex; let lineIndex = $index) {
            <tr [formGroup]="formGroup">
              <ng-container formArrayName="allocations">
                @for (
                  allocation of $any(formGroup).controls.allocations.controls;
                  track dayIndex;
                  let dayIndex = $index
                ) {
                  <td
                    class="entry"
                    [ngClass]="{ 'nonworking-day': days[dayIndex].isException }"
                    wpTimesheetCell
                    [formControlName]="dayIndex"
                    [lineIndex]="lineIndex"
                    [dayIndex]="dayIndex"
                    [dayScheduleDuration]="days[dayIndex].schedule"
                    [line]="formGroup.value"
                    [propagationMode]="propagationMode"
                    [attr.data-test-date]="days[dayIndex].date"
                  ></td>
                }
              </ng-container>

              <td class="total">
                {{ formGroup.value.totalHours | wpTimeDuration: totalSchedule : false }}
              </td>
            </tr>
          }

          @if (service.issueLines.controls?.length > 0) {
            <tr style="height: 45px">
              <td [attr.colspan]="days.length + 1" class="time-off-header"></td>
            </tr>
          }

          @for (
            issueGroup of service.issueLines.controls;
            track lineIndex;
            let lineIndex = $index
          ) {
            <tr [formGroup]="issueGroup">
              <ng-container formArrayName="allocations">
                @for (
                  allocation of $any(issueGroup).controls.allocations.controls;
                  track dayIndex;
                  let dayIndex = $index
                ) {
                  <td
                    wpTimesheetCell
                    class="entry"
                    [ngClass]="{ 'nonworking-day': days[dayIndex].isException }"
                    [formControlName]="dayIndex"
                    [lineIndex]="lineIndex + service.dataLines.controls.length"
                    [dayIndex]="dayIndex"
                    [dayScheduleDuration]="days[dayIndex].schedule"
                    [line]="issueGroup.getRawValue()"
                    [issueMode]="true"
                    [propagationMode]="propagationMode"
                    [attr.data-test-date]="days[dayIndex].date"
                  ></td>
                }
              </ng-container>

              <td class="total">
                {{ issueGroup.getRawValue().totalHours | wpTimeDuration: totalSchedule : false }}
              </td>
            </tr>
          }

          @if (timeOffRequests?.length > 0) {
            <tr style="height: 45px">
              <td [attr.colspan]="days.length + 1" class="time-off-header"></td>
            </tr>
          }

          @for (request of timeOffRequests; track request.id) {
            <tr>
              @for (day of days; track $index) {
                <td class="day-off" [ngClass]="{ 'nonworking-day': day.isException }">
                  {{ getDayOffHours(request, day) | wpTimeDuration: day.schedule : false }}
                </td>
              }

              <td class="day-off total">
                {{ geTimeOffRequestHours(request) | wpTimeDuration: totalSchedule : false }}
              </td>
            </tr>
          }
        </tbody>
      </table>
    </div>

    <div name="scroll-table-footer">
      <table [ngStyle]="{ width: dataTableWidth + 'px' }" class="table table-bordered">
        <tfoot>
          <tr>
            @for (day of days; track $index) {
              <td class="entry">
                {{ day.totalHours | wpTimeDuration: day.schedule : false }}
              </td>
            }

            <td class="total">
              {{ totalHours | wpTimeDuration: totalSchedule : false }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
  </div>
</div>

<ng-template #colGroup>
  <colgroup>
    <col />
    <col [ngStyle]="{ width: columnsWidths.taskColumnWidth + 'px' }" />

    @if (template?.showActivity) {
      <col [ngStyle]="{ width: columnsWidths.activityColumnWidth + 'px' }" />
    }

    @if (template?.showRole) {
      <col [ngStyle]="{ width: columnsWidths.roleRateColumnWidth + 'px' }" />
    }

    @if (template?.showProjectCostCenter) {
      <col [ngStyle]="{ width: columnsWidths.projectCostCenterColumnWidth + 'px' }" />
    }

    @if (template?.showTariff) {
      <col [ngStyle]="{ width: columnsWidths.projectTariffColumnWidth + 'px' }" />
    }

    @for (field of service.lineCustomFields; track field) {
      <col [ngStyle]="{ width: columnsWidths.customColumnWidth + 'px' }" />
    }
  </colgroup>
</ng-template>
