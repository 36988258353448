import {
  GridColumnType,
  GridNavigationColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { List, LoadingStrategy } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';
import { ViewColumn } from 'src/app/shared/models/inner/view';
import { DateTime } from 'luxon';

const DEFAULT_VIEW_COLUMNS: ViewColumn[] = [
  {
    column: 'type',
    visibleByDefault: true,
    totalByDefault: TotalType.Count,
    width: 1,
  },
  {
    column: 'date',
    visibleByDefault: true,
    width: 160,
  },
  {
    column: 'isPlanned',
    visibleByDefault: true,
    width: 100,
  },
  {
    column: 'performer',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'client',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'deal',
    visibleByDefault: true,
    width: 1,
  },

  {
    column: 'contacts',
    visibleByDefault: true,
    width: 1,
  },
];

export const INTERACTIONS_LIST: List = {
  version: 1,
  name: 'interactions',
  views: [
    {
      name: 'all',
      columns: DEFAULT_VIEW_COLUMNS,
      order: { column: 'date', reverse: true },
    },
    {
      name: 'all-overdue',
      columns: DEFAULT_VIEW_COLUMNS,
      order: { column: 'date', reverse: true },
      contextFilter: [
        {
          isPlanned: true,
          date: {
            le: { type: 'raw', value: DateTime.now().toISODate() },
          },
        },
      ],
    },
    {
      name: 'my-overdue',
      columns: DEFAULT_VIEW_COLUMNS,
      order: { column: 'date', reverse: true },
      contextFilter: [
        {
          performerId: { type: 'guid', value: '#user' },
          date: {
            le: { type: 'raw', value: DateTime.now().toISODate() },
          },
          isPlanned: true,
        },
      ],
    },
  ],
  columns: [
    {
      name: 'type',
      header: 'components.interactionFormComponent.props.interactionType',
      hint: 'components.interactionFormComponent.props.interactionType',
      type: GridColumnType.String,
      availableTotals: [TotalType.Count],
    },

    <GridNavigationColumn>{
      name: 'client',
      type: GridColumnType.Navigation,
      state: 'client',
      stateParams: {
        fromRow: {
          entityId: 'client.id',
        },
      },
      header: 'shared2.props.client',
      hint: 'shared2.props.client',
    },
    <GridNavigationColumn>{
      name: 'deal',
      type: GridColumnType.Navigation,
      state: 'deal',
      stateParams: {
        fromRow: {
          entityId: 'deal.id',
        },
      },
      header: 'shared2.props.deal',
      hint: 'shared2.props.deal',
    },
    {
      name: 'performer',
      type: GridColumnType.User,
      header: 'shared2.props.performer',
      hint: 'shared2.props.performer',
    },
    {
      name: 'date',
      header: 'shared2.props.dateTime',
      hint: 'shared2.props.dateTime',
      type: GridColumnType.DateTime,
      fixedWidth: true,
    },
    {
      name: 'isPlanned',
      header: 'components.interactionFormComponent.props.isPlanned',
      hint: 'components.interactionFormComponent.props.isPlanned',
      type: GridColumnType.Boolean,
      fixedWidth: true,
    },
  ],

  dataColumns: [
    {
      column: 'name',
      field: 'name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'date',
      field: 'date',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'isPlanned',
      field: 'isPlanned',
      loadingStrategy: LoadingStrategy.Always,
    },
    {
      column: 'type',
      field: 'type.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'client',
      field: ['organization.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'deal',
      field: ['deal.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'performer',
      field: ['performer.name'],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],

  dataCollection: 'Interactions',
};
