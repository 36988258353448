import { Directive, ViewContainerRef } from '@angular/core';

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[wp-filter-details-host]',
  standalone: false,
})
export class FilterDetailsHostDirective {
  constructor(public viewContainerRef: ViewContainerRef) {}
}
