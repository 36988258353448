import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-project-cost-centers-toolbar',
  templateUrl: './project-cost-centers-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectCostCentersToolbarComponent
  extends AbstractToolbar
  implements OnInit
{
  constructor(gridService: GridService, cdr: ChangeDetectorRef) {
    super(gridService, cdr);
  }
}
