import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { META_ENTITY_TYPE, LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { AppName } from 'src/app/shared/globals/app-name';
import { NavigationService } from 'src/app/core/navigation.service';
import { ExpensesRequest } from 'src/app/shared/models/entities/base/expenses-request.model';
import { NotificationService } from 'src/app/core/notification.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { ListService } from 'src/app/shared/services/list.service';
import { EXPENSE_REQUEST_LINE_LIST } from 'src/app/shared/lists/expenses-request-line.list';
import { ExpenseRequestCardService } from './expenses-request-card.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ExpensesRequestSetProjectComponent } from 'src/app/expenses-requests/card/set-project/expenses-request-set-project.component';
import { Subject } from 'rxjs';
import { ExpensesRequestService } from 'src/app/expenses-requests/expenses-request.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

import { takeUntil } from 'rxjs/operators';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { SortService } from 'src/app/shared/components/features/sort/core/sort.service';
import { Exception } from 'src/app/shared/models/exception';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ProjectInfoComponent } from 'src/app/shared/components/features/project-info/project-info.component';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

/**
 * Represents Expense request Card content.
 * */
@Component({
  selector: 'tmt-expenses-request-card',
  templateUrl: './expenses-request-card.component.html',
  styleUrls: ['./expenses-request-card.component.scss'],
  providers: [
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: EXPENSE_REQUEST_LINE_LIST },
    { provide: META_ENTITY_TYPE, useValue: 'ExpenseRequest' },
    LifecycleService,
    SavingQueueService,
    ExpenseRequestCardService,
    ListService,
    GridService,
    FormHeaderService,
  ],
  standalone: false,
})
export class ExpensesRequestCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public request: ExpensesRequest;
  public readonly: boolean;
  public isOwnRequest: boolean;
  public isSetProjectShown: boolean;

  public activeTab: string;

  /** Expense request lines. */
  public get linesArray(): UntypedFormArray {
    return this.form.controls.lines as UntypedFormArray;
  }

  public form = this.fb.group({
    date: [null, Validators.required],
    projectCostCenter: null,
    reimbursementCurrency: [null, Validators.required],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    lines: this.fb.array([]),
  });

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public expensesService: ExpensesRequestService,
    public service: ExpenseRequestCardService,
    public navigation: NavigationService,
    public actionService: ActionPanelService,
    public blockUI: BlockUIService,
    public sortService: SortService,
    private app: AppService,
    private formHeaderService: FormHeaderService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private gridService: GridService,
    private modalService: NgbModal,
    private customFieldService: CustomFieldService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private wpCurrency: WpCurrencyPipe,
    private savingQueueService: SavingQueueService,
  ) {
    this.customFieldService.enrichFormGroup(this.form, 'ExpenseRequest');
  }

  ngOnInit(): void {
    this.service
      .initBaseCurrency()
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: () => {
          this.initMainMenu();
          this.initCardSubscriptions();
          this.service.entityId = this.entityId;
          this.service.load();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  ngOnDestroy() {
    this.service.dispose();
    this.destroyed$.next();
  }

  /**
   * Open user info button click event handler.
   * Opens Modal window view Expense request applicant info.
   * */
  public openUserInfo() {
    const userId = this.request.user.id;
    const target = document.getElementById('request-user');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Opens Modal window view Expense request Project info.
   * */
  public openProjectInfo() {
    const projectId = this.request.project.id;
    const target = document.getElementById('request-project');
    this.infoPopupService.open({
      target,
      data: {
        component: ProjectInfoComponent,
        params: {
          projectId,
        },
        injector: this.injector,
      },
    });
  }

  /**
   * Set project button click event handler.
   * Opens Modal window to set Expense request Project.
   * */
  public setProject() {
    const ref = this.modalService.open(ExpensesRequestSetProjectComponent);
    (ref.componentInstance as ExpensesRequestSetProjectComponent).entityId =
      this.entityId;
    (ref.componentInstance as ExpensesRequestSetProjectComponent).user =
      this.request.user;
    (ref.componentInstance as ExpensesRequestSetProjectComponent).project =
      this.request.project;
  }

  /**
   * Inits Main menu.
   * */
  private initMainMenu() {
    this.actionService.setAdditional([
      {
        title: 'shared.actions.delete',
        hint: 'expenses.card.actions.delete.hint',
        name: 'delete',
        isBusy: false,
        isVisible: false,
        handler: () => this.service.deleteEntity(),
      },

      {
        title: 'finance.entries.actions.openEntries.title',
        hint: 'finance.entries.actions.openEntries.hint',
        name: 'openEntries',
        isBusy: false,
        isVisible: this.app.checkAppAccess(AppName.Finance),
        handler: () => this.service.goToAccountingEntry(),
      },
    ]);

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.reload();
        this.gridService.selectGroup(null);
      });
    this.actionService.setHasAutosave(true);
  }

  /**
   * Inits Card level subscriptions.
   * */
  private initCardSubscriptions() {
    this.service.request$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((request) => this.onExpenseRequestLoaded(request));

    this.expensesService.setProject$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.reload();
        this.gridService.selectGroup(null);
      });

    this.service.calculateTotals$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.calculateTotals();
      });

    this.service.reimbursementCurrencyChanged$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((currency) => {
        this.updateFormHeader();
      });
  }

  /**
   * Builds entity for Auto Save service.
   *
   * @returns Entity to save.
   * */
  private getRequestToSave = () => {
    const formData = this.form.getRawValue() as ExpensesRequest;

    const data = {
      id: this.request.id,
      projectId: this.request.project.id,
      projectCostCenterId: formData.projectCostCenter?.id,
      reimbursementCurrencyId: formData.reimbursementCurrency?.id,
      rowVersion: this.request.rowVersion,
      userId: this.request.user.id,
      name: this.request.name,
      date: formData.date,
      description: formData.description,
      lines: [] as any,
    };

    formData.lines.forEach((formLine: any) => {
      const line = this.service.lineBuilderFn(formLine);

      this.customFieldService.assignValues(
        line,
        formLine,
        'ExpenseRequestLine',
      );

      data.lines.push(line);
    });

    this.customFieldService.assignValues(data, formData, 'ExpenseRequest');

    return data;
  };

  /**
   * Expense request load event handler.
   *
   * @param expenseRequest Expense request.
   * */
  private onExpenseRequestLoaded(expenseRequest: ExpensesRequest) {
    this.form.enable({ emitEvent: false });
    this.request = expenseRequest;

    this.savingQueueService.disabled = true;

    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.form.patchValue(expenseRequest);

    this.readonly = !this.request.editAllowed;

    this.linesArray.clear();

    expenseRequest.lines.forEach((line) => {
      const group = this.service.getLineGroup(line);
      this.linesArray.push(group);
    });

    if (!expenseRequest.editAllowed) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.valueChanges
        .pipe(takeUntil(this.service.entityReloaded$))
        .subscribe(() => {
          this.savingQueueService.addToQueue(this.entityId, () =>
            this.service.collection
              .entity(this.entityId)
              .update(this.getRequestToSave(), { withResponse: true }),
          );
        });

      this.form.controls.reimbursementCurrency.valueChanges
        .pipe(takeUntil(this.service.entityReloaded$))
        .subscribe((currency) => {
          this.service.refreshReimbursementAmount(currency.id);
        });

      this.savingQueueService.error$
        .pipe(takeUntil(this.service.entityReloaded$))
        .subscribe(() => this.service.reload());
    }

    this.savingQueueService.disabled = false;
    this.toggleActions();
    this.calculateTotals();
  }

  /**
   * Updates Actions visibility state.
   * */
  private toggleActions() {
    // Set Own request flag.
    this.isOwnRequest = this.app.session.user.id === this.request.user.id;

    this.isSetProjectShown = this.request.editAllowed;

    const deleteItem = this.actionService.action('delete');
    if (deleteItem) {
      deleteItem.isShown = this.request.deleteAllowed;
    }
  }

  /**
   * Calculates Expense request total sum values.
   * */
  private calculateTotals() {
    let totalAmount = 0;
    let totalReimbursement = 0;
    let totalBillable = 0;

    const lines = this.linesArray.getRawValue();

    lines.forEach((line) => {
      if (line.amountPC) {
        totalAmount += line.amountPC.value;
        if (line.billable) {
          totalBillable += line.amountPC.value;
        }
      }
      if (line.reimburse) {
        totalReimbursement += line.amountRC.value;
      }
    });

    this.request.totalAmount = totalAmount;
    this.request.totalReimbursement = totalReimbursement;
    this.request.totalBillable = totalBillable;

    this.updateFormHeader();
  }

  private updateFormHeader() {
    this.formHeaderService.updateIndicators([
      {
        description: 'expenses.card.info.total',
        value: this.wpCurrency.transform(
          this.request.totalAmount,
          this.request.project.currency.alpha3Code,
        ),
      },
      {
        description: 'expenses.card.info.totalReimbursement',
        value: this.wpCurrency.transform(
          this.request.totalReimbursement,
          this.service.reimbursementCurrency.alpha3Code,
        ),
      },
      {
        description: 'expenses.card.info.totalBillable',
        value: this.wpCurrency.transform(
          this.request.totalBillable,
          this.request.project.currency.alpha3Code,
        ),
      },
    ]);
  }
}
