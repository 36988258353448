import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { ProgramCardService } from 'src/app/programs/card/program-card.service';
import { TrendsKpiService } from 'src/app/shared/components/features/overview/trends-kpi/core/trends-kpi.service';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'wp-program-overview-analysis',
  templateUrl: './program-overview-analysis.component.html',
  providers: [TrendsKpiService],
  standalone: false,
})
export class ProgramOverviewAnalysisComponent implements OnInit, OnDestroy {
  private destroyed$ = new Subject<void>();

  constructor(
    public service: ProgramCardService,
    private trendsKpiService: TrendsKpiService,
  ) {}

  private reload() {
    this.trendsKpiService.reload();
  }

  ngOnInit() {
    this.service.reloadTab$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.reload());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
