import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { RepresentationType } from 'src/app/resource-summary/shared/resource-summary-representation-settings/models/representation-type.enum';
import { ResourceSummaryRepresentationService } from 'src/app/resource-summary/shared/resource-summary-representation-settings/core/resource-summary-representation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Resource Summary View Settings content. */
@Component({
  selector: 'tmt-resource-summary-representation-settings',
  templateUrl: './resource-summary-representation-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceSummaryRepresentationSettingsComponent implements OnInit {
  /** Gets Form controls. */
  public get formControls(): RepresentationType[] {
    const orderedRepresentationTypes = [
      RepresentationType.inactiveResources,
      RepresentationType.inactiveProjects,
    ];
    return Object.values(orderedRepresentationTypes);
  }

  public form: UntypedFormGroup = this.fb.group({
    [RepresentationType.inactiveResources]: false,
    [RepresentationType.inactiveProjects]: false,
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private service: ResourceSummaryRepresentationService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.service.settings.show);
    this.initSubscriptions();
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const settings = this.service.settings;
        settings.show = this.form.value;
        this.service.propagateSettingsChange(settings);
      });
  }
}
