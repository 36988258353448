import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  HostBinding,
  Input,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';
import { Guid } from 'src/app/shared/helpers/guid';

@Component({
  selector: 'tmt-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: ['./checkbox.component.scss'],
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CheckboxComponent),
      multi: true,
    },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CheckboxComponent implements ControlValueAccessor, AfterViewInit {
  @Input() control: UntypedFormControl;
  @Input() label = '';
  @Input() reversed = false;
  @Input() readonly = false;
  @Input() autofocus?: boolean;
  @Input() initialValue?: unknown;

  @ViewChild('input') input;
  @HostBinding('class') get hostClasses(): string {
    return this.label ? 'form-check' : 'no-label';
  }

  public id = Guid.generate();
  public value = false;
  public disabled = false;

  constructor(private cdr: ChangeDetectorRef) {}

  /** Handles the change event for the checkbox and propagates the updated value. */
  public onChange(event: Event): void {
    const target = event.target as HTMLInputElement;
    this.value = target.checked;
    this.propagateChange(this.checkReversed(this.value));
  }

  public ngAfterViewInit(): void {
    if (this.autofocus && this.input) {
      this.input.nativeElement?.focus();
    }
    if (this.initialValue === ' ') {
      this.control.setValue(!this.control.value);
      this.cdr.detectChanges();
    }
  }

  public propagateChange = (value: boolean) => null;
  public propagateTouch = () => null;

  public writeValue(value: boolean): void {
    this.value = this.checkReversed(value);
    this.cdr.markForCheck();
  }

  public registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  public registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  /** Updates the disabled state of the form control without emitting events. */
  public setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  /** Adjusts the value based on the 'reversed' flag. */
  private checkReversed(value: boolean): boolean {
    return this.reversed ? !value : value;
  }
}
