import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';
import {
  DATE_TIME_COLUMN_WIDTH,
  getNamedEntityDataColumns,
  getNamedEntityGridColumns,
} from 'src/app/shared/helpers/list.helper';
import { List, LoadingStrategy } from 'src/app/shared/models/inner/list';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { ViewColumn } from 'src/app/shared/models/inner/view';

const DEFAULT_VIEW_COLUMNS: ViewColumn[] = [
  {
    column: 'name',
    visibleByDefault: true,
    width: 2,
    totalByDefault: TotalType.Count,
    isRequired: true,
  },
  {
    column: 'state',
    visibleByDefault: true,
    width: 200,
  },
  {
    column: 'organization',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'manager',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'source',
    visibleByDefault: true,
    width: 1,
  },
  {
    column: 'probability',
    visibleByDefault: true,
    width: 130,
  },
  {
    column: 'amount',
    visibleByDefault: true,
    width: 150,
  },

  {
    column: 'resolution',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'resolutionComment',
    visibleByDefault: false,
    width: 1,
  },

  {
    column: 'description',
    visibleByDefault: false,
    width: 3,
  },

  {
    column: 'created',
    visibleByDefault: false,
    width: DATE_TIME_COLUMN_WIDTH,
  },
  {
    column: 'modified',
    visibleByDefault: false,
    width: DATE_TIME_COLUMN_WIDTH,
  },
  {
    column: 'createdBy',
    visibleByDefault: false,
    width: 1,
  },
  {
    column: 'modifiedBy',
    visibleByDefault: false,
    width: 1,
  },
];

export const DEALS_LIST: List = {
  dataCollection: 'Deals',
  version: 4,
  customFieldEntityType: 'Deal',
  name: 'my',
  views: [
    {
      name: 'my',
      columns: DEFAULT_VIEW_COLUMNS,
      order: { column: 'name', reverse: false },
      contextFilter: [
        {
          managerId: { type: 'guid', value: '#user' },
        },
      ],
    },
    {
      name: 'all',
      columns: DEFAULT_VIEW_COLUMNS,
      order: { column: 'name', reverse: false },
    },
  ],
  columns: [
    ...getNamedEntityGridColumns('deal.profile'),
    {
      name: 'organization',
      header: 'shared2.props.organization',
      hint: 'shared2.props.organization',
      type: GridColumnType.String,
    },
    {
      name: 'manager',
      header: 'shared2.props.manager',
      hint: 'shared2.props.manager',
      type: GridColumnType.String,
    },
    {
      name: 'resolution',
      header: 'shared2.props.resolution',
      hint: 'shared2.props.resolution',
      type: GridColumnType.String,
    },
    {
      name: 'resolutionComment',
      header: 'shared2.props.resolutionComment',
      hint: 'shared2.props.resolutionComment',
      type: GridColumnType.String,
    },
    {
      name: 'source',
      header: 'shared2.props.source',
      hint: 'shared2.props.source',
      type: GridColumnType.String,
    },
    {
      name: 'state',
      header: 'shared2.props.state',
      hint: 'shared2.props.state',
      type: GridColumnType.State,
      fixedWidth: true,
    },
    {
      name: 'probability',
      header: 'shared2.props.probability',
      hint: 'shared2.props.probability',
      fixedWidth: true,
      type: GridColumnType.Percent,
    },
    {
      name: 'amount',
      fixedWidth: true,
      header: 'shared2.props.amount',
      hint: 'shared2.props.amount',
      type: GridColumnType.Currency,
    },
  ],

  dataColumns: [
    ...getNamedEntityDataColumns(),
    {
      column: 'description',
      field: 'description',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },

    {
      column: 'organization',
      field: 'organization.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'manager',
      field: 'manager.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resolution',
      field: 'resolution.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'resolutionComment',
      field: 'resolutionComment',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'source',
      field: 'source.name',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'probability',
      field: 'probability',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'amount',
      field: 'amount',
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
    {
      column: 'state',
      field: [
        'state.name',
        'state.code',
        'state.style',
        'state.isEntityProtected',
      ],
      loadingStrategy: LoadingStrategy.WhenShowing,
    },
  ],
};
