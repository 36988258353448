import {
  Component,
  OnInit,
  Input,
  ChangeDetectionStrategy,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { UserCostRatesToolbarComponent } from './user-cost-rates-toolbar/user-cost-rates-toolbar.component';
import { UserCostRatesService } from './user-cost-rates.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridCurrencyControlColumn,
  GridDateControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-user-cost-rates',
  templateUrl: './user-cost-rates.component.html',
  styleUrls: ['./user-cost-rates.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserCostRatesComponent implements OnInit {
  @Input() readonly: boolean;

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: UserCostRatesToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.userCostRatesService.remove(index);
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          this.userCostRatesService.create();
        },
      },
    ],
    view: {
      name: 'user-costs',
      columns: [
        {
          name: 'effectiveDate',
          header: 'settings.users.card.cost.columns.effectiveDate',
          hint: 'settings.users.card.cost.columns.effectiveDate',
          type: GridColumnType.DateControl,
          property: 'effectiveDate',
          width: '150px',
          required: true,
        } as GridDateControlColumn,
        {
          name: 'value',
          header: 'settings.users.card.cost.columns.cost.header',
          hint: 'settings.users.card.cost.columns.cost.header',
          type: GridColumnType.CurrencyControl,
          controlType: 'currency',
          isCurrencyEditable: true,
          required: true,
          property: 'value',
          width: '100%',
        } as GridCurrencyControlColumn,
      ],
    },
  };

  constructor(public userCostRatesService: UserCostRatesService) {}

  public ngOnInit(): void {
    if (this.readonly) {
      this.userCostRatesService.costForm.disable();
    }
  }
}
