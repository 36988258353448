import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SharedModule } from 'src/app/shared/shared.module';
import { InteractionsComponent } from 'src/app/shared/modules/interactions/interactions.component';
import { InteractionsFilterComponent } from 'src/app/shared/modules/interactions/interactions-filter/interactions-filter.component';
import { InteractionFormComponent } from 'src/app/shared/modules/interactions/interaction-form/interaction-form.component';
import { InteractionItemComponent } from 'src/app/shared/modules/interactions/interaction-item/interaction-item.component';
import { RichEditorBoxComponent } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box.component';

@NgModule({
  declarations: [
    InteractionsComponent,
    InteractionsFilterComponent,
    InteractionFormComponent,
    InteractionItemComponent,
  ],
  imports: [CommonModule, SharedModule, RichEditorBoxComponent],
  exports: [InteractionsComponent],
})
export class InteractionsModule {}
