import {
  MetaEntityBaseProperty,
  MetaEntityDirectoryProperty,
  MetaEntityNavigationProperty,
  MetaEntityPrimitiveProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';

export interface FilterProperty extends Pick<MetaEntityBaseProperty, 'type'> {
  /** Generated ID. */
  id: string;
  /** Translated property title. */
  name: string;
  /** Group name. */
  group: string;
  /** Property key. */
  key: string[];
}

export interface Filter {
  /** Property name. */
  property: string[];
  /** Conditions by `or`. */
  conditions: FilterCondition[];
}

export interface FilterCondition {
  /** Operator. */
  operator: FilterOperator;
  /** Property value. */
  value: any;
}

export const FILTER_OPERATORS = [
  'Equal',
  'NotEqual',
  'Greater',
  'Less',
  'GreaterOrEqual',
  'LessOrEqual',
  'Contains',
  'DoesNotContain',
  'Between',
  'InPeriod',
  'InHierarchy',
  'IsNull',
  'IsNotNull',
  'StartsWith',
  'EndsWith',
] as const;

export type FilterOperator = (typeof FILTER_OPERATORS)[number];

export type MetaEntityData =
  | MetaEntityPrimitiveProperty
  | MetaEntityDirectoryProperty
  | MetaEntityNavigationProperty;

export const OPERATOR_BY_META_ENTITY_TYPE: Record<
  MetaEntityPropertyType,
  readonly FilterOperator[]
> = {
  [MetaEntityPropertyType.string]: [
    'Equal',
    'NotEqual',
    'Contains',
    'DoesNotContain',
    'StartsWith',
    'EndsWith',
  ],
  [MetaEntityPropertyType.text]: [
    'Equal',
    'NotEqual',
    'Contains',
    'DoesNotContain',
    'StartsWith',
    'EndsWith',
  ],
  [MetaEntityPropertyType.decimal]: [
    'Equal',
    'NotEqual',
    'Between',
    'Greater',
    'GreaterOrEqual',
    'Less',
    'LessOrEqual',
  ],
  [MetaEntityPropertyType.percent]: [
    'Equal',
    'NotEqual',
    'Between',
    'Greater',
    'GreaterOrEqual',
    'Less',
    'LessOrEqual',
  ],
  [MetaEntityPropertyType.currency]: [
    'Equal',
    'NotEqual',
    'Between',
    'Greater',
    'GreaterOrEqual',
    'Less',
    'LessOrEqual',
  ],
  [MetaEntityPropertyType.integer]: [
    'Equal',
    'NotEqual',
    'Between',
    'Greater',
    'GreaterOrEqual',
    'Less',
    'LessOrEqual',
  ],
  [MetaEntityPropertyType.dateTime]: [
    'Equal',
    'NotEqual',
    'Greater',
    'GreaterOrEqual',
    'Less',
    'LessOrEqual',
    'InPeriod',
  ],
  [MetaEntityPropertyType.dateOnly]: [
    'Equal',
    'NotEqual',
    'Greater',
    'GreaterOrEqual',
    'Less',
    'LessOrEqual',
    'InPeriod',
  ],
  [MetaEntityPropertyType.directory]: [
    'Equal',
    'NotEqual',
    'IsNull',
    'IsNotNull',
  ],
  [MetaEntityPropertyType.navigation]: [
    'Equal',
    'NotEqual',
    'IsNull',
    'IsNotNull',
  ],
  [MetaEntityPropertyType.boolean]: ['Equal'],
  [MetaEntityPropertyType.phone]: [
    'Equal',
    'NotEqual',
    'Contains',
    'DoesNotContain',
    'StartsWith',
    'EndsWith',
  ],
  [MetaEntityPropertyType.url]: [
    'Equal',
    'NotEqual',
    'Contains',
    'DoesNotContain',
    'StartsWith',
    'EndsWith',
  ],
  [MetaEntityPropertyType.email]: [
    'Equal',
    'NotEqual',
    'Contains',
    'DoesNotContain',
    'StartsWith',
    'EndsWith',
  ],
};
