import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  NgbDropdownModule,
  NgbTooltipModule,
} from '@ng-bootstrap/ng-bootstrap';
import { TranslateModule } from '@ngx-translate/core';
import { Options } from '@popperjs/core';
import { Selection } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import {
  addHr,
  disableHorizontalRule,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/additional/horizontal-rule/commands';
import { MenuItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';
import { RichEditorBoxMenuService } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/services/rich-editor-box-menu.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import {
  link,
  makeLinksClickable,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/links/commands';
import { NodeItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/consts';
import {
  markItem,
  toggleMarkActive,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/marks/commands';
import {
  heading,
  toggleHeadingActive,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/heading/commands';
import {
  toggleBlockquoteActive,
  toggleQuote,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/blockquote/commands';
import {
  liftLiItem,
  list,
  sinkLiItem,
  toggleListActiveAndDisable,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/lists/commands';
import { ListNode } from 'src/app/shared/components/controls/rich-editor-box/markdown/lists/consts';
import { isHrSelection } from 'src/app/shared/components/controls/rich-editor-box/markdown/additional/horizontal-rule/consts';
import { menuComponent } from 'src/app/shared/components/controls/rich-editor-box/markdown/keymap';

@Component({
  selector: 'tmt-rich-editor-box-menu',
  templateUrl: './rich-editor-box-menu.component.html',
  styleUrl: './rich-editor-box-menu.component.scss',
  imports: [NgbTooltipModule, TranslateModule, CommonModule, NgbDropdownModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RichEditorBoxMenuComponent implements OnInit, AfterViewInit {
  @Input() public editorView: EditorView;

  public items: MenuItem[] = [];
  public linkItem: Element;
  public makeLinksClickableAfter: NodeItem[] = [
    'blockquote',
    'bullet_list',
    'ordered_list',
    'sink_item',
    'lift_item',
  ];

  public popperOptions = (options: Partial<Options>) => {
    options.placement = 'bottom';
    options.modifiers.push({
      name: 'flip',
      options: {
        allowedAutoPlacements: ['top', 'bottom'],
        fallbackPlacements: ['top'],
      },
    });
    return options;
  };

  public get selection(): Selection {
    return this.editorView.state.selection;
  }

  constructor(
    public infoPopupService: InfoPopupService,
    public richEditorBoxMenuService: RichEditorBoxMenuService,
    private el: ElementRef<HTMLElement>,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.items = [
      {
        id: 'strong',
        bootstrapClassName: 'bi-type-bold',
        title: 'components.richEditorBoxComponent.props.bold',
        hotKey: 'Ctrl+B',
        command: markItem('strong'),
        toggleActive: (item, state) => toggleMarkActive(item, state),
      },
      {
        id: 'em',
        bootstrapClassName: 'bi-type-italic',
        title: 'components.richEditorBoxComponent.props.italic',
        hotKey: 'Ctrl+I',
        command: markItem('em'),
        toggleActive: (item, state) => toggleMarkActive(item, state),
      },
      {
        id: 'strike',
        bootstrapClassName: 'bi-type-strikethrough',
        title: 'components.richEditorBoxComponent.props.strikethrough',
        hotKey: 'Ctrl+Shift+S',
        command: markItem('strike'),
        toggleActive: (item, state) => toggleMarkActive(item, state),
      },
      {
        id: 'ins',
        bootstrapClassName: 'bi-type-underline',
        title: 'components.richEditorBoxComponent.props.underline',
        hotKey: 'Ctrl+U',
        command: markItem('ins'),
        toggleActive: (item, state) => toggleMarkActive(item, state),
      },
      {
        id: 'samp',
        innerText: 'M',
        title: 'components.richEditorBoxComponent.props.monospace',
        command: markItem('samp'),
        toggleActive: (item, state) => toggleMarkActive(item, state),
      },
      {
        id: 'link',
        bootstrapClassName: 'bi-link-45deg',
        title: 'components.richEditorBoxComponent.props.link',
        hotKey: 'Ctrl+Shift+K',
        command: link(this),
        toggleActive: (item, state) => toggleMarkActive(item, state),
      },
      {
        id: 'heading',
        innerText: 'H',
        title: 'components.richEditorBoxComponent.props.heading',
        command: () => null,
        toggleActive: (item, state) => toggleHeadingActive(item, state),
        subItems: [
          {
            id: 'heading0',
            innerText: 'T',
            title: 'components.richEditorBoxComponent.props.heading0',
            hotKey: 'Ctrl+Shift+0',
            command: heading(0),
          },
          {
            id: 'heading1',
            bootstrapClassName: 'bi-type-h1',
            title: 'components.richEditorBoxComponent.props.heading1',
            hotKey: 'Ctrl+Shift+1',
            command: heading(1),
          },
          {
            id: 'heading2',
            bootstrapClassName: 'bi-type-h2',
            title: 'components.richEditorBoxComponent.props.heading2',
            hotKey: 'Ctrl+Shift+2',
            command: heading(2),
          },
          {
            id: 'heading3',
            bootstrapClassName: 'bi-type-h3',
            title: 'components.richEditorBoxComponent.props.heading3',
            hotKey: 'Ctrl+Shift+3',
            command: heading(3),
          },
          {
            id: 'heading4',
            bootstrapClassName: 'bi-type-h4',
            title: 'components.richEditorBoxComponent.props.heading4',
            hotKey: 'Ctrl+Shift+4',
            command: heading(4),
          },
          {
            id: 'heading5',
            bootstrapClassName: 'bi-type-h5',
            title: 'components.richEditorBoxComponent.props.heading5',
            hotKey: 'Ctrl+Shift+5',
            command: heading(5),
          },
          {
            id: 'heading6',
            bootstrapClassName: 'bi-type-h6',
            title: 'components.richEditorBoxComponent.props.heading6',
            hotKey: 'Ctrl+Shift+6',
            command: heading(6),
          },
        ],
      },
      {
        id: 'lists',
        bootstrapClassName: 'bi-list-ul',
        title: 'components.richEditorBoxComponent.props.list',
        command: () => null,
        toggleActive: (item, state) => toggleListActiveAndDisable(item, state),
        subItems: [
          {
            id: 'bullet_list',
            bootstrapClassName: 'bi-list-ul',
            title: 'components.richEditorBoxComponent.props.bulletList',
            hotKey: 'Ctrl+Shift+L',
            command: list(ListNode.BulletList),
          },
          {
            id: 'ordered_list',
            bootstrapClassName: 'bi-list-ol',
            title: 'components.richEditorBoxComponent.props.orderedList',
            hotKey: 'Ctrl+Shift+M',
            command: list(ListNode.OrderedList),
          },
          {
            id: 'sink_item',
            bootstrapClassName: 'bi-text-indent-left',
            title: 'components.richEditorBoxComponent.props.sinkItem',
            hotKey: 'Tab',
            command: sinkLiItem(),
          },
          {
            id: 'lift_item',
            bootstrapClassName: 'bi-text-indent-right',
            title: 'components.richEditorBoxComponent.props.liftItem',
            hotKey: 'Shift+Tab',
            command: liftLiItem(),
          },
        ],
      },
      {
        id: 'blockquote',
        bootstrapClassName: 'bi-quote',
        title: 'components.richEditorBoxComponent.props.blockquote',
        hotKey: 'Ctrl+Shift+.',
        command: toggleQuote(),
        toggleActive: (item, state) => toggleBlockquoteActive(item, state),
      },
      {
        id: 'code',
        bootstrapClassName: 'bi-code-slash',
        title: 'components.richEditorBoxComponent.props.code',
        command: () => null,
        toggleActive: (item, state) => toggleMarkActive(item, state),
        subItems: [
          {
            id: 'inlineCode',
            bootstrapClassName: 'bi-code-slash',
            title: 'components.richEditorBoxComponent.props.inlineCode',
            hotKey: 'Ctrl+E',
            command: markItem('code'),
          },
        ],
      },
      {
        id: 'additional',
        bootstrapClassName: 'bi-three-dots',
        title: 'components.richEditorBoxComponent.props.additional',
        command: () => null,
        hideDropdownArrow: true,
        toggleActive: () => null,
        subItems: [
          {
            id: 'hr',
            bootstrapClassName: 'bi-dash',
            title: 'components.richEditorBoxComponent.props.hr',
            command: addHr(),
          },
        ],
      },
    ];
  }

  ngAfterViewInit(): void {
    this.toggleActive();
    this.linkItem = this.el.nativeElement.getElementsByClassName('link')[0];
    this.richEditorBoxMenuService.editorView = this.editorView;
  }

  /** Toggles items isActive on editor view state change. */
  public toggleActive(): void {
    this.items.forEach((item) =>
      item.toggleActive(item, this.editorView.state),
    );
    this.cdr.markForCheck();
  }

  /** Performs actions on editor view state change. */
  public onEditorStateChange(): void {
    this.toggleActive();

    if (isHrSelection(this.selection)) {
      this.items
        .filter(
          (item) => !['blockquote', 'lists', 'additional'].includes(item.id),
        )
        .forEach((item) => (item.disabled = true));
    } else {
      this.items.forEach((item) => (item.disabled = false));
    }

    disableHorizontalRule(
      this.items.find((item) => item.id === 'additional'),
      this.editorView.state,
    );
    menuComponent.menubar = this;
  }

  public onItemClick(item: MenuItem): void {
    item.command(this.editorView.state, this.editorView.dispatch);
    if (this.makeLinksClickableAfter.includes(item.id)) {
      makeLinksClickable(this);
    }
    this.editorView.focus();
  }
}
