import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { LicenseProductInfo } from 'src/app/shared/models/entities/settings/license-info.model';
import { UserProductsService } from 'src/app/settings-app/users/card/user-products/user-products.service';
import { LicenseProduct } from 'src/app/shared/models/session/client-session.model';
import { orderBy } from 'lodash';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-user-products-toolbar',
  templateUrl: './user-products-toolbar.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserProductsToolbarComponent extends AbstractToolbar {
  public products: LicenseProductInfo[] = [];

  constructor(
    public service: UserProductsService,
    public notification: NotificationService,
    gridService: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  /**
   *  Event on open/close state for product selector.
   *
   * @param $event new state.
   */
  public openChange($event: boolean): void {
    if (!$event) {
      return;
    }

    this.products = orderBy(
      this.service.licenseInfo.filter(
        (x) =>
          x.limit - x.used > 0 &&
          (this.service.products.value as { product: LicenseProduct }[]).every(
            (up) => up.product !== x.product,
          ),
      ),
      (x) => x.displayName,
    );
  }

  /**
   * Selects product.
   *
   * @param productInfo selected license product.   *
   */
  public selectProduct(productInfo: LicenseProductInfo): void {
    this.gridService.execute('add', productInfo);
  }
}
