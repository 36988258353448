import {
  Component,
  OnInit,
  inject,
  DestroyRef,
  ChangeDetectionStrategy,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ListService } from 'src/app/shared/services/list.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { UntypedFormGroup } from '@angular/forms';
import { ContactCreateModalComponent } from 'src/app/contacts/contact-create/contact-create.component';
import { ContactListFilterService } from 'src/app/contacts/list/contact-list-filter/contact-list-filter.service';

@Component({
  selector: 'tmt-contact-list',
  template: '<wp-entity-list />',
  providers: [
    { provide: FilterService, useClass: ContactListFilterService },
    EntityListService,
    ListService,
    GridService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ContactListComponent implements OnInit {
  private readonly actionPanelService = inject(ActionPanelService);
  private readonly appService = inject(AppService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly entityListService = inject(EntityListService);
  private readonly gridService = inject(GridService);
  private readonly modalService = inject(NgbModal);
  private readonly stateService = inject(StateService);

  public ngOnInit(): void {
    this.actionPanelService.set([
      this.actionPanelService.getDefaultAction('create', {
        title: 'components.contactListComponent.actions.create',
        isVisible: this.appService.checkEntityPermission(
          'Contact',
          PermissionType.Modify,
        ),
        handler: () => this.create(),
      }),
      this.actionPanelService.getDefaultAction('card', {
        handler: () =>
          this.stateService.go('contact', {
            entityId: this.gridService.selectedGroupValue.id,
          }),
      }),
      this.actionPanelService.getDefaultAction('delete', {
        handler: this.entityListService.delete,
      }),
    ]);

    this.gridService.selectedGroups$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((rows: UntypedFormGroup[]) => {
        this.actionPanelService.action('card').isShown = rows?.length === 1;
      });
  }

  private create(): void {
    this.modalService.open(ContactCreateModalComponent);
  }
}
