import { Component, Input, OnInit } from '@angular/core';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { AppService } from 'src/app/core/app.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wp-changing-billing-type-modal',
  templateUrl: './changing-billing-type-modal.component.html',
  standalone: false,
})
export class ChangingBillingTypeModalComponent implements OnInit {
  @Input() project: Project;

  public form = this.fb.group({
    billingType: [null, Validators.required],
  });
  isSaving: boolean;

  public billingTypes: ProjectBillingType[] = [];

  constructor(
    private app: AppService,
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
  ) {}

  public get isWarningShown(): boolean {
    return (
      this.form.value?.billingType?.id === ProjectBillingType.nonBillable.id
    );
  }

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    const data = {
      billingTypeId: this.form.value.billingType.id,
    };

    this.data
      .collection('Projects')
      .entity(this.project.id)
      .action('WP.ChangeBillingType')
      .execute(data)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'projects.projects.changingProjectBillingType.messages.billingTypeWasChanged',
          );
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit(): void {
    if (this.project.billingType.id !== ProjectBillingType.fixedBid.id) {
      this.billingTypes.push(ProjectBillingType.fixedBid);
    }

    if (this.project.billingType.id !== ProjectBillingType.nonBillable.id) {
      this.billingTypes.push(ProjectBillingType.nonBillable);
    }

    if (
      this.project.billingType.id !== ProjectBillingType.tm.id &&
      this.app.checkFeature(Feature.billing)
    ) {
      this.billingTypes.push(ProjectBillingType.tm);
    }

    this.billingTypes.forEach(
      (rate) => (rate.name = this.translate.instant(rate.name)),
    );
  }
}
