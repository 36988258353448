import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { cloneDeep } from 'lodash';
import { LimitationType } from 'src/app/settings-app/validation-rules/card/model/limitation-type.enum';
import { WorkDayData } from 'src/app/settings-app/validation-rules/card/rule-schedule-work-day-percentage/model/work-day-data-interface.model';

@Component({
  selector: 'wp-rule-schedule-work-day-percentage',
  templateUrl: './rule-schedule-work-day-percentage.component.html',
  standalone: false,
})
export class RuleScheduleWorkDayPercentageComponent implements OnInit {
  @Output() changes = new EventEmitter<string>();
  @Input() data: string;
  @Input() readonly: boolean;

  public limitationType = LimitationType;
  public form = this.fb.group({
    MinPercentage: [
      0,
      [Validators.required, Validators.max(1000), Validators.min(0)],
    ],
    MaxPercentage: [
      0,
      [Validators.required, Validators.max(1000), Validators.min(0)],
    ],
    LimitationType: null,
  });

  constructor(private fb: UntypedFormBuilder) {}

  public ngOnInit(): void {
    const parsedData: WorkDayData = JSON.parse(this.data);
    parsedData.MinPercentage = parsedData.MinPercentage / 100;
    parsedData.MaxPercentage = parsedData.MaxPercentage / 100;
    this.form.patchValue(parsedData);
    if (this.readonly) {
      this.form.disable();
    }
    this.form.valueChanges.subscribe(() => {
      const clonedData = cloneDeep(this.form.value);
      clonedData.MinPercentage = clonedData.MinPercentage * 100;
      clonedData.MaxPercentage = clonedData.MaxPercentage * 100;
      const data = JSON.stringify(clonedData);
      this.changes.emit(data);
    });
  }
}
