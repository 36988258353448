<form
  class="modal-form"
  novalidate
  [formGroup]="clientCardService.clientForm"
  ngDraggable
  autocomplete="off"
>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'projects.clients.settings.newClient' | translate }}
    </h3>

    <button
      [disabled]="isSaving()"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" class="nav-tabs">
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'projects.clients.settings.tabs.main' | translate }}</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-6">
              <div class="form-group" tmtIndicator>
                <label>{{ 'projects.clients.settings.props.name.label' | translate }}</label>
                <wp-text-box
                  placeholder="{{ 'projects.clients.settings.props.name.ph' | translate }}"
                  formControlName="name"
                ></wp-text-box>
              </div>

              <div class="form-group" tmtIndicator>
                <label>{{ 'projects.clients.settings.props.manager.label' | translate }}</label>
                <wp-user-box
                  placeholder="{{ 'projects.clients.settings.props.manager.ph' | translate }}"
                  formControlName="manager"
                ></wp-user-box>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group" tmtIndicator>
                <label>{{ 'shared.props.code' | translate }}</label>
                <wp-text-box
                  placeholder="{{ 'shared.props.code' | translate }}"
                  style="width: 150px"
                  formControlName="code"
                ></wp-text-box>
              </div>
              <div class="form-group" tmtIndicator>
                <label>{{ 'shared.props.description' | translate }}</label>
                <wp-multiline-text-box
                  formControlName="description"
                  placeholder="{{ 'shared.props.description' | translate }}"
                  rows="4"
                >
                </wp-multiline-text-box>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6">
              <div class="form-group" tmtIndicator>
                <label>{{ 'projects.clients.settings.props.site' | translate }}</label>
                <wp-text-box
                  typeName="url"
                  placeholder="{{ 'projects.clients.settings.props.site' | translate }}"
                  formControlName="site"
                ></wp-text-box>
              </div>
              <div class="form-group" tmtIndicator>
                <label>{{ 'projects.clients.settings.props.phone' | translate }}</label>
                <wp-text-box
                  typeName="phone"
                  placeholder="{{ 'projects.clients.settings.props.phone' | translate }}"
                  formControlName="phone"
                  #phone
                ></wp-text-box>
              </div>
            </div>
            <div class="col-6">
              <div class="form-group" tmtIndicator>
                <label>{{ 'projects.clients.settings.props.contact' | translate }}</label>
                <wp-text-box
                  placeholder="{{ 'projects.clients.settings.props.contact' | translate }}"
                  formControlName="contact"
                ></wp-text-box>
              </div>

              <div class="form-group" tmtIndicator>
                <label>{{ 'projects.clients.settings.props.contactEmail' | translate }}</label>
                <wp-text-box
                  typeName="email"
                  placeholder="{{ 'projects.clients.settings.props.contactEmail' | translate }}"
                  formControlName="contactEmail"
                ></wp-text-box>
              </div>
            </div>
          </div>

          <wp-custom-fields
            [formGroup]="clientCardService.clientForm"
            [entityType]="'Organization'"
          />
        </ng-template>
      </li>
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'projects.clients.settings.tabs.addresses' | translate }}</a>
        <ng-template ngbNavContent>
          <div class="row">
            <div class="col-6" formGroupName="postAddress">
              <label class="group">
                {{ 'projects.clients.settings.props.postAddress' | translate }}
              </label>
              <div class="row">
                <div class="col-8">
                  <div class="form-group" tmtIndicator>
                    <label>{{ 'projects.clients.settings.props.state' | translate }}</label>
                    <wp-text-box
                      placeholder="{{ 'projects.clients.settings.props.state' | translate }}"
                      formControlName="state"
                    ></wp-text-box>
                  </div>
                  <div class="form-group" tmtIndicator>
                    <label>{{ 'projects.clients.settings.props.city' | translate }}</label>
                    <wp-text-box
                      placeholder="{{ 'projects.clients.settings.props.city' | translate }}"
                      formControlName="city"
                    ></wp-text-box>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group" tmtIndicator>
                    <label>{{ 'projects.clients.settings.props.index' | translate }}</label>
                    <wp-text-box
                      placeholder="{{ 'projects.clients.settings.props.index' | translate }}"
                      formControlName="postIndex"
                    ></wp-text-box>
                  </div>
                </div>
              </div>
              <div class="form-group" tmtIndicator>
                <label>{{ 'projects.clients.settings.props.address' | translate }}</label>
                <wp-text-box
                  placeholder="{{ 'projects.clients.settings.props.address' | translate }}"
                  formControlName="address"
                ></wp-text-box>
              </div>
            </div>
            <div class="col-6" formGroupName="billingAddress">
              <label class="group">
                {{ 'projects.clients.settings.props.invoiceAddress' | translate }}
              </label>

              <div class="row">
                <div class="col-8">
                  <div class="form-group" tmtIndicator>
                    <label>{{ 'projects.clients.settings.props.state' | translate }}</label>
                    <wp-text-box
                      placeholder="{{ 'projects.clients.settings.props.state' | translate }}"
                      formControlName="state"
                    ></wp-text-box>
                  </div>
                  <div class="form-group" tmtIndicator>
                    <label>{{ 'projects.clients.settings.props.city' | translate }}</label>
                    <wp-text-box
                      placeholder="{{ 'projects.clients.settings.props.city' | translate }}"
                      formControlName="city"
                    ></wp-text-box>
                  </div>
                </div>
                <div class="col-4">
                  <div class="form-group" tmtIndicator>
                    <label>{{ 'projects.clients.settings.props.index' | translate }}</label>
                    <wp-text-box
                      placeholder="{{ 'projects.clients.settings.props.index' | translate }}"
                      formControlName="postIndex"
                    ></wp-text-box>
                  </div>
                </div>
              </div>

              <div class="form-group" tmtIndicator>
                <label>{{ 'projects.clients.settings.props.address' | translate }}</label>
                <wp-text-box
                  placeholder="{{ 'projects.clients.settings.props.address' | translate }}"
                  formControlName="address"
                ></wp-text-box>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-6"></div>
            <div class="col-6">
              <div class="form-check" style="height: 20px">
                <input
                  formControlName="billingEqualPost"
                  type="checkbox"
                  class="form-check-input"
                  id="billingEqualPost"
                />
                <label class="form-check-label" for="billingEqualPost">
                  {{ 'projects.clients.settings.props.coincidesWithPost' | translate }}
                </label>
              </div>
            </div>
          </div>
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" style="padding-top: 15px"></div>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="create()"
      [isBusy]="isSaving()"
      wpButtonSpinner
    >
      {{ 'shared.actions.create' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
