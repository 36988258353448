import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { Guid } from 'src/app/shared/helpers/guid';
import { UserProductsToolbarComponent } from 'src/app/settings-app/users/card/user-products/user-products-toolbar/user-products-toolbar.component';
import { LicenseProductInfo } from 'src/app/shared/models/entities/settings/license-info.model';
import { UserProductsService } from 'src/app/settings-app/users/card/user-products/user-products.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'tmt-user-products',
  templateUrl: './user-products.component.html',
  styleUrls: ['./user-products.component.scss'],
  providers: [GridService, UserProductsService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserProductsComponent {
  public readonly = !this.app.checkEntityPermission(
    'UserProduct',
    PermissionType.Modify,
  );

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: UserProductsToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.service.readonly,
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          this.service.products.removeAt(index);
          this.service.products.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'add',
        handlerFn: (productInfo: LicenseProductInfo) => {
          const group = this.fb.group({
            product: [productInfo.product, Validators.required],
            name: [productInfo.displayName, Validators.required],
            id: [Guid.generate()],
          });
          this.service.products.push(group);
          this.cdr.detectChanges();
          this.service.products.markAsDirty();
        },
      },
    ],
    view: {
      name: 'userProducts',
      columns: [
        {
          name: 'name',
          header: 'shared.columns.name',
          hint: 'shared.columns.name',
          type: GridColumnType.String,
          width: '100%',
        },
      ],
    },
  };

  constructor(
    public service: UserProductsService,
    private app: AppService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
  ) {}
}
