import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';

import { DataService } from 'src/app/core/data.service';

import { State } from 'src/app/shared/models/entities/state.model';

import { StateControlItem } from './state-select.interface';

@Component({
  selector: 'tmt-state-select',
  templateUrl: './state-select.component.html',
  styleUrls: ['./state-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StateSelectComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StateSelectComponent implements OnInit, ControlValueAccessor {
  @Input({ required: true }) public collection: string;

  public statesForm: UntypedFormGroup = this.fb.group({});
  public allStates: State[] = [];

  private values: Record<string, Partial<StateControlItem>> = {};

  /* ControlValueAccessor **/
  public propagateChange = (_: any) => null;
  public propagateTouch = () => null;
  public registerOnChange = (fn: any): void => (this.propagateChange = fn);
  public registerOnTouched = (fn: any): void => (this.propagateTouch = fn);

  constructor(
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.loadStates();
  }

  public writeValue(states: any): void {
    Object.keys(this.values).forEach((key) => {
      this.values[key].selected = false;
    });

    if (Array.isArray(states)) {
      states.forEach((state) => {
        state.selected = true;
        this.values[state.code] = state;
      });
    } else if (states) {
      this.values = states;
    }

    this.syncControls();
  }

  /** Handles value change of checkbox. */
  public toggleState(event: InputEvent, code: string): void {
    this.values[code].selected = (
      event.currentTarget as HTMLInputElement
    ).checked;
    this.propagateChange(this.values);
  }

  private loadStates(): void {
    this.dataService
      .collection(this.collection)
      .function('GetStates')
      .query(null, { select: ['id', 'code', 'name', 'index'] })
      .subscribe((states: State[]) => {
        this.allStates = states.sort((a, b) => (a.index < b.index ? -1 : 0));

        this.statesForm = this.fb.group(
          Object.values(states).reduce(
            (curr, next) => ({ ...curr, [next.code]: false }),
            {},
          ),
        );

        states.forEach((state) => {
          if (!this.values[state.code]) {
            this.values[state.code] = state;
            this.values[state.code].selected = false;
          }
        });

        this.syncControls();
      });
  }

  private syncControls(): void {
    const valueToPatch: Record<string, boolean> = {};

    Object.keys(this.values).forEach((key) => {
      valueToPatch[key] = !!this.values[key].selected;
    });

    this.statesForm.patchValue(valueToPatch, { emitEvent: false });
    this.statesForm.markAsPristine();
    this.cdr.markForCheck();
  }
}
