import { Injectable } from '@angular/core';
import { Observable, shareReplay } from 'rxjs';

import { DataService } from 'src/app/core/data.service';

import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

import { ProjectTariff } from 'src/app/projects/card/project-tariffs/models/project-tariff.model';
import { ProjectCostCenter } from 'src/app/projects/card/project-cost-centers/models/project-cost-center.model';

import { TimesheetProjectTask } from 'src/app/timesheets/card/shared/timesheet-task/timesheet-project-task.model';
import { TimesheetProject } from 'src/app/timesheets/card/shared/timesheet-task/timesheet-project.model';

/** Service for timesheet caching. */
@Injectable()
export class TimeSheetCacheService {
  private organizationsCache: Record<
    string, // Key is timesheetTemplateId.
    Observable<NamedEntity[]>
  > = {};
  private projectsCache: Record<
    string, // Key is timesheetTemplateId + organizationId.
    Observable<TimesheetProject[]>
  > = {};
  private projectTasksCache: Record<
    string, // Key is timesheetTemplateId + projectId.
    Observable<TimesheetProjectTask[]>
  > = {};
  private projectTariffsCache: Record<
    string, // Key is timesheetTemplateId + projectId.
    Observable<ProjectTariff[]>
  > = {};
  private projectCostCenterCache: Record<
    string, // Key is projectId.
    Observable<ProjectCostCenter[]>
  > = {};
  private rolesCache: Record<
    string, // Key is projectId
    Observable<NamedEntity[]>
  > = {};

  constructor(private dataService: DataService) {}

  /**
   * Retrieves organizations by its ID from the cache or requests it from the server if not cached.
   *
   * @param timesheetId The ID of the timesheet to retrieve organizations.
   * @returns An Observable of the requested organizations.
   */
  public getOrganizations(
    userId: string,
    timesheetTemplateId: string,
  ): Observable<NamedEntity[]> {
    if (this.organizationsCache[timesheetTemplateId]) {
      return this.organizationsCache[timesheetTemplateId];
    }

    this.organizationsCache[timesheetTemplateId] = this.dataService.model
      .function('GetTimeTrackingOrganizations')
      .get<NamedEntity[]>(
        {
          userId,
          timesheetTemplateId,
        },
        { orderBy: 'name' },
      )
      .pipe(shareReplay(1));

    return this.organizationsCache[timesheetTemplateId];
  }

  /**
   * Retrieves projects by its ID from the cache or requests it from the server if not cached.
   *
   * @param timesheetId The ID of the timesheet to retrieve projects.
   * @param organizationId The ID of the organization to retrieve projects.
   * @returns An Observable of the requested projects.
   */
  public getProjects(
    userId: string,
    timesheetTemplateId: string,
    organizationId: string | null | undefined,
  ): Observable<TimesheetProject[]> {
    const key =
      timesheetTemplateId +
      (organizationId === undefined
        ? 'any'
        : organizationId === null
          ? 'null'
          : organizationId);

    if (this.projectsCache[key]) {
      return this.projectsCache[key];
    }

    const params: Record<string, string> = {
      userId,
    };

    if (organizationId !== undefined) {
      params['organizationId'] = organizationId;
    }

    if (timesheetTemplateId) {
      params['timesheetTemplateId'] = timesheetTemplateId;
    }

    this.projectsCache[key] = this.dataService.model
      .function('GetTimeTrackingProjects')
      .get<TimesheetProject[]>(params, { orderBy: 'name' })
      .pipe(shareReplay(1));

    return this.projectsCache[key];
  }

  /**
   * Retrieves tasks by its ID from the cache or requests it from the server if not cached.
   *
   * @param timesheetId The ID of the timesheet to retrieve tasks.
   * @param projectId The ID of the project to retrieve tasks.
   * @returns An Observable of the requested tasks.
   */
  public getProjectTasks(
    userId: string,
    timesheetTemplateId: string,
    projectId: string,
  ): Observable<TimesheetProjectTask[]> {
    if (this.projectTasksCache[timesheetTemplateId + projectId]) {
      return this.projectTasksCache[timesheetTemplateId + projectId];
    }

    this.projectTasksCache[timesheetTemplateId + projectId] =
      this.dataService.model
        .function('GetTimeTrackingProjectTasks')
        .get<TimesheetProjectTask[]>(
          {
            userId,
            projectId,
            timesheetTemplateId,
          },
          { orderBy: 'name' },
        )
        .pipe(shareReplay(1));

    return this.projectTasksCache[timesheetTemplateId + projectId];
  }

  /**
   * Gets project tariffs from server or cache.
   *
   * @param projectId project's id.
   * @returns An Observable of the requested project tariffs.
   */
  public getProjectTariffs(
    userId: string,
    projectId: string,
  ): Observable<ProjectTariff[]> {
    if (this.projectTariffsCache[projectId]) {
      return this.projectTariffsCache[projectId];
    }

    this.projectTariffsCache[projectId] = this.dataService
      .collection('Projects')
      .entity(projectId)
      .function('GetUserTariffs')
      .get<ProjectTariff[]>({ userId }, { orderBy: 'name' })
      .pipe(shareReplay(1));

    return this.projectTariffsCache[projectId];
  }

  /**
   * Gets project cost centers from server or cache.
   *
   * @param projectId project's id.
   * @returns An Observable of the requested project cost centers.
   */
  public getProjectCostCenters(
    userId: string,
    projectId: string,
  ): Observable<ProjectCostCenter[]> {
    if (this.projectCostCenterCache[projectId]) {
      return this.projectCostCenterCache[projectId];
    }

    this.projectCostCenterCache[projectId] = this.dataService
      .collection('Projects')
      .entity(projectId)
      .function('GetUserCostCenters')
      .get<ProjectCostCenter[]>({ userId }, { orderBy: 'name' })
      .pipe(shareReplay(1));

    return this.projectCostCenterCache[projectId];
  }

  /**
   * Gets roles from server or cache.
   *
   * @param projectId project's id.
   * @returns An Observable of the requested roles.
   */
  public getRoles(
    userId: string,
    projectId: string,
  ): Observable<NamedEntity[]> {
    if (this.rolesCache[projectId]) {
      return this.rolesCache[projectId];
    }

    this.rolesCache[projectId] = this.dataService
      .collection('Projects')
      .entity(projectId)
      .function('GetUserRoles')
      .get<NamedEntity[]>({ userId }, { orderBy: 'name' })
      .pipe(shareReplay(1));

    return this.rolesCache[projectId];
  }
}
