import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { ResourceSummaryDataService } from './core/resource-summary-data.service';
import { ResourceSummaryService } from './core/resource-summary.service';
import { filter } from 'rxjs/operators';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import { ResourceSummaryViewSettingsService } from './shared/resource-summary-view-settings/core/resource-summary-view-settings.service';
import { ResourceSummaryRepresentationService } from './shared/resource-summary-representation-settings/core/resource-summary-representation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Resource Summary tab content. */
@Component({
  selector: 'tmt-resource-summary',
  templateUrl: './resource-summary.component.html',
  styleUrls: ['./resource-summary.component.scss'],
  providers: [
    ScheduleNavigationService,
    FreezeTableService,
    ResourceSummaryDataService,
    ResourceSummaryService,
    ResourceSummaryViewSettingsService,
    ResourceSummaryRepresentationService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceSummaryComponent implements OnInit {
  /** Gets Left Table width numeric value. */
  public get leftTableWidth(): number {
    return this._leftTableWidth;
  }

  /**
   * Indicates whether KPI Type is required to select or not.
   *
   * @returns `true` if KPI Type is required, `false` otherwise.
   * */
  public get isTypeRequired(): boolean {
    return this._isTypeRequired;
  }

  /** Gets NO DATA message localization key. */
  public get noDataMessageKey(): string {
    return this._noDataMessageKey;
  }

  private readonly _leftTableWidth = 400;

  private _isTypeRequired: boolean;
  private _noDataMessageKey: string;

  private destroyRef = inject(DestroyRef);

  constructor(
    public service: ResourceSummaryService,
    public summaryDataService: ResourceSummaryDataService,
    private summaryViewSettingsService: ResourceSummaryViewSettingsService,
    private scheduleNavigationService: ScheduleNavigationService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.scheduleNavigationService.init(
      ScheduleNavigationContext.ResourceSummary,
    );
    this.updateFields();
    this.service.init();
    this.initSubscriptions();
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.service.detectChanges$
      .pipe(
        filter((id) => !id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.updateFields();
        this.cdRef.detectChanges();
      });
  }

  /** Updates template fields. */
  private updateFields(): void {
    this._isTypeRequired = !Object.keys(
      this.summaryViewSettingsService.settings.show,
    ).some((key) => this.summaryViewSettingsService.settings.show[key]);
    this._noDataMessageKey = this._isTypeRequired
      ? 'resources.resourceSummary.noType'
      : 'shared.noDisplayData';
  }
}
