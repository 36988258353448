import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FilterService } from './filter.service';
import { StringHelper } from 'src/app/shared/helpers/string-helper';
import { TranslateService } from '@ngx-translate/core';
import { FilterDetailsHostDirective } from './filter-details-host.directive';
import { UntypedFormBuilder, UntypedFormControl } from '@angular/forms';
import { debounceTime, takeUntil } from 'rxjs/operators';
import { Guid } from 'src/app/shared/helpers/guid';
import { Constants } from 'src/app/shared/globals/constants';
import _isEmpty from 'lodash/isEmpty';
import { Subject } from 'rxjs';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';

/** Компонента фильтра списков. */
@Component({
  selector: 'wp-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
  standalone: false,
})
export class FilterComponent implements OnInit, OnDestroy {
  // Задержка при вводе текста для начала поиска (мс).
  private textInputDebounce = Constants.textInputDebounce;

  @ViewChild(FilterDetailsHostDirective, { static: true })
  wpFilterDetailsHost: FilterDetailsHostDirective;

  placeholder: string;

  id = `id-${Guid.generate()}`;
  excludeClickOutside = `#${this.id}, tmt-popup-container`;

  // Условия фильтра.
  oldText: string;

  textControl = new UntypedFormControl('');
  public periodControl = new UntypedFormControl(null);
  allowInactiveControl = new UntypedFormControl(false);

  extraConditionsCount: number;
  extraConditionTitle: string;
  areaIsExpanded: boolean;
  componentRef: any;

  public customFieldsForm = this.fb.group({});
  public isShowCustomFieldsForm: boolean;

  private destroyed$ = new Subject<void>();

  constructor(
    public service: FilterService,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
  ) {
    this.service.enrichFilterForm(this.customFieldsForm);
    this.isShowCustomFieldsForm = !_isEmpty(this.customFieldsForm.controls);
  }

  public ngOnInit(): void {
    this.textControl.setValue(this.service.values.text);
    this.oldText = this.service.values.text;
    this.periodControl.setValue(this.service.values.period);
    this.service.values$.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      if (this.service.values.text !== this.textControl.value) {
        this.textControl.setValue(this.service.values.text, {
          emitEvent: false,
        });
      }
      if (this.service.values.period !== this.periodControl.value) {
        this.periodControl.setValue(this.service.values.period, {
          emitEvent: false,
        });
      }
      this.updateConditionCount();
    });

    this.updateConditionCount();
    this.placeholder = this.service.placeholder;

    this.textControl.valueChanges
      .pipe(debounceTime(this.textInputDebounce), takeUntil(this.destroyed$))
      .subscribe(() => {
        const text = this.textControl.value;
        if (
          text.length >= this.service.minStringLengthForFilter ||
          (text.length < this.service.minStringLengthForFilter &&
            this.oldText &&
            this.oldText.length >= this.service.minStringLengthForFilter)
        ) {
          this.service.values.text = text;
          this.service.changeValues(this.service.values);
        }
        this.oldText = this.service.values.text;
      });

    this.periodControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe((period: DatePeriod) => {
        this.setPeriod(period);
      });

    this.allowInactiveControl.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.service.setAllowingInactive(this.allowInactiveControl.value);
      });

    if (this.isShowCustomFieldsForm) {
      this.customFieldsForm.patchValue(this.service.values);

      this.service.resetValues$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.customFieldsForm.reset();
        });

      this.customFieldsForm.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.service.changeValues(
            Object.assign(this.service.values, this.customFieldsForm.value),
          );
        });
    }

    this.addDetailsComponent();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  updateConditionCount() {
    this.extraConditionsCount = this.service.getCriteriaCount();
    this.extraConditionTitle = StringHelper.declOfNum(
      this.extraConditionsCount,
      [
        this.translate.instant('shared.filterConditionDeclensions.v1'),
        this.translate.instant('shared.filterConditionDeclensions.v2'),
        this.translate.instant('shared.filterConditionDeclensions.v3'),
      ],
    );
  }

  clear() {
    this.service.values.text = '';
    this.areaIsExpanded = false;
    this.service.changeValues(this.service.values);
  }

  clearValues() {
    this.service.resetValues();
  }
  openDetails() {
    this.areaIsExpanded = !this.areaIsExpanded;
    this.addDetailsComponent();
  }

  cancel() {
    this.areaIsExpanded = false;
  }

  setView(view: any) {
    this.service.values.view = view;
    this.service.changeValues(this.service.values);
  }

  setPeriod(period: DatePeriod) {
    this.service.values.period = period;
    this.service.changeValues(this.service.values);
  }

  addDetailsComponent() {
    if (
      this.service.hasDetails &&
      this.service.component &&
      !this.componentRef
    ) {
      const viewContainerRef = this.wpFilterDetailsHost.viewContainerRef;
      viewContainerRef.clear();
      this.componentRef = viewContainerRef.createComponent(
        this.service.component,
      );
    }
  }
}
