import { setBlockType } from 'prosemirror-commands';
import { Command } from 'prosemirror-state';

/**
 * Sets paragraph block type.
 *
 * @param state editor view state.
 * @param dispatch editor view dispatch.
 * @returns editor view state command.
 */
export const toParagraph: Command = (state, dispatch) =>
  setBlockType(state.schema.nodes.paragraph)(state, dispatch);
