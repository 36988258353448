<div class="toolbar-container">
  <wp-expenses-view-switcher />
  <wp-schedule-navigation [maxScale]="planningScale.Year" [disabled]="disabled" />

  @if (!service.readonly) {
    <button
      type="button"
      class="btn btn-default"
      [disabled]="disabled"
      (click)="projectExpensesService.openExpenseRuleModal()"
      data-test="addExpenseRule"
    >
      {{ 'projects.projects.recurringExpenseRules.actions.addRule' | translate }}
    </button>
  }

  <div class="group d-flex ms-auto">
    <tmt-expenses-account-filter />
    <wp-project-expenses-calendar-group-filter />
  </div>
</div>
