import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { NavigationService } from 'src/app/core/navigation.service';
import { IssueType } from 'src/app/settings-app/issue-types/model/issue-type.model';

@Component({
  selector: 'tmt-issue-creation',
  templateUrl: './issue-creation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IssueCreationComponent implements OnInit {
  @Input() openCardAfterCreation = true;

  public issueForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    typeId: ['', Validators.required],
  });
  public types$ = new BehaviorSubject<Partial<IssueType>[]>([]);
  public isSaving = signal<boolean>(false);
  public isLoading = signal<boolean>(true);

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
    private customFieldService: CustomFieldService,
    private navigationService: NavigationService,
  ) {
    this.customFieldService.enrichFormGroup(this.issueForm, 'Issue', true);
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.issueForm,
      'Issue',
    );
  }

  public async ngOnInit(): Promise<void> {
    const types = await firstValueFrom(
      this.dataService
        .collection('IssueTypes')
        .function('GetEditableTypes')
        .query<Partial<IssueType>[]>(null, {
          select: ['id', 'name', 'isDefault', 'iconClass'],
          filter: { isActive: true },
        }),
    );

    this.isLoading.set(false);
    this.types$.next(types);
    this.issueForm.patchValue({
      typeId: types.find((type) => type.isDefault)?.id,
    });
  }

  /* Creates competence. */
  public create(): void {
    this.issueForm.markAllAsTouched();

    if (this.issueForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);

    const data = this.issueForm.value;
    this.customFieldService.assignValues(
      data,
      this.issueForm.getRawValue(),
      'Issue',
    );

    this.dataService
      .collection('Issues')
      .insert(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'components.issueCreationComponent.messages.created',
          );
          this.activeModal.close(response.id);
          this.navigationService.updateIndicators();

          if (this.openCardAfterCreation) {
            this.isSaving.set(false);
            this.stateService.go('issue', {
              entityId: response.id,
            });
          }
        },
        error: (error) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /* Closes issue creating modal. */
  public cancel(): void {
    this.activeModal.dismiss();
  }
}
