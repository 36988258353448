import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
  signal,
} from '@angular/core';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { TimesheetTemplate } from 'src/app/shared/models/entities/settings/timesheet-template.model';
import { Exception } from 'src/app/shared/models/exception';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
  selector: 'wp-timesheet-template-card',
  templateUrl: './timesheet-template-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimesheetTemplateCardComponent implements OnInit {
  @Input() private entityId: string;

  public state = signal<CardState>(undefined);
  public readonly = signal<boolean>(false);
  public isSaving = signal<boolean>(false);

  public form = this.fb.group({
    id: null,
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    isActive: false,
    isDefault: false,
    createAutomatically: false,
    clearEmptyLinesAutomatically: false,
    showActivity: false,
    showRole: false,
    showProjectCostCenter: false,
    showClient: false,
    showTariff: false,
    timeOffAutoApproval: false,
    emptyAutoApproval: false,
    dueDateLag: [0, [Validators.required, Validators.max(30)]],
    validationRules: this.fb.array([]),
    allowedProjectStates: this.fb.array([]),
    customFields: this.fb.array([]),
  });

  public get template(): TimesheetTemplate {
    return this.form.value as TimesheetTemplate;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private navigationService: NavigationService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: this.setAsDefault,
      },
    ]);

    this.load();

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  /** Saves Data. */
  public save = (): void => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving.set(true);
    this.actionService.action('save').start();

    const template = this.form.value as TimesheetTemplate;

    const data: any = {
      id: template.id,
      name: template.name,
      dueDateLag: template.dueDateLag,
      isActive: template.isActive,
      isDefault: template.isDefault,
      showClient: template.showClient,
      showActivity: template.showActivity,
      showRole: template.showRole,
      showProjectCostCenter: template.showProjectCostCenter,
      showTariff: template.showTariff,
      createAutomatically: template.createAutomatically,
      timeOffAutoApproval: template.timeOffAutoApproval,
      emptyAutoApproval: template.emptyAutoApproval,
      clearEmptyLinesAutomatically: template.clearEmptyLinesAutomatically,
      validationRules: [],
      customFields: [],
      allowedProjectStates: [],
    };

    template.allowedProjectStates.forEach((state: any) => {
      data.allowedProjectStates.push({
        stateId: state.id,
        timeSheetTemplateId: this.entityId,
      });
    });

    template.validationRules.forEach((rule: any) => {
      data.validationRules.push({
        validationRuleId: rule.id,
        timeSheetTemplateId: this.entityId,
      });
    });

    template.customFields.forEach((field: any) => {
      data.customFields.push({
        customFieldId: field.id,
        timeSheetTemplateId: this.entityId,
      });
    });

    this.data
      .collection('TimeSheetTemplates')
      .entity(this.entityId)
      .update(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving.set(false);
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.timesheetTemplates.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving.set(false);
          this.actionService.action('save').stop();
          this.message.errorDetailed(error);
        },
      });
  };

  private load(): void {
    this.state.set(CardState.Loading);
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.form.reset();
    (this.form.controls.validationRules as UntypedFormArray).clear();
    (this.form.controls.customFields as UntypedFormArray).clear();
    (this.form.controls.allowedProjectStates as UntypedFormArray).clear();

    const query = {
      expand: {
        validationRules: {
          expand: { validationRule: { select: ['id', 'name'] } },
        },
        allowedProjectStates: { expand: { state: { select: ['id', 'name'] } } },
        customFields: { expand: { customField: { select: ['id', 'name'] } } },
      },
    };

    // Загрузка данных.
    this.data
      .collection('TimeSheetTemplates')
      .entity(this.entityId)
      .get<TimesheetTemplate>(query)
      .subscribe({
        next: (template: TimesheetTemplate) => {
          this.form.patchValue(template);

          template.allowedProjectStates.forEach((allowedState) => {
            (
              this.form.controls['allowedProjectStates'] as UntypedFormArray
            ).push(
              this.fb.group({
                id: allowedState.state.id,
                name: allowedState.state.name,
              }),
            );
          });

          template.validationRules.forEach((rule) => {
            (this.form.controls['validationRules'] as UntypedFormArray).push(
              this.fb.group({
                id: rule.validationRule.id,
                name: rule.validationRule.name,
              }),
            );
          });

          template.customFields.forEach((field) => {
            (this.form.controls['customFields'] as UntypedFormArray).push(
              this.fb.group({
                id: field.customField.id,
                name: field.customField.name,
              }),
            );
          });

          this.readonly.set(!template.editAllowed);
          this.readonly() ? this.form.disable() : this.form.enable();
          this.actionService.action('save').isShown = !this.readonly();
          this.actionService.action('setAsDefault').isShown =
            !template.isDefault && !this.readonly();

          this.state.set(CardState.Ready);

          this.navigationService.addRouteSegment({
            id: template.id,
            title: template.name,
          });
        },
        error: (error: Exception) => {
          this.state.set(CardState.Error);
          this.notification.error(error.message);
        },
      });
  }

  private reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  private setAsDefault = (): void => {
    this.actionService.action('setAsDefault').start();
    this.data
      .collection('TimeSheetTemplates')
      .entity(this.entityId)
      .action('WP.SetAsDefault')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.timesheetTemplates.card.messages.hasBeenSetByDefault',
          );
          this.actionService.action('setAsDefault').stop();
          this.actionService.action('setAsDefault').isShown = false;
          this.form.get('isDefault').setValue(true);
        },
        error: (error: Exception) => {
          this.actionService.action('setAsDefault').stop();
          this.notification.error(error.message);
        },
      });
  };
}
