import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Injector,
  inject,
} from '@angular/core';
import { StateService } from '@uirouter/core';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { DealCardService } from './deal-card.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { FormHeaderService } from 'src/app/shared/components/chrome/form-header2/form-header.service';
import { DealContactsService } from 'src/app/deals/card/deal-contacts/core/deal-contacts.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
  selector: 'tmt-deal-card',
  templateUrl: './deal-card.component.html',
  providers: [
    DealCardService,
    DealContactsService,
    LifecycleService,
    { provide: META_ENTITY_TYPE, useValue: 'Deal' },
    SavingQueueService,
    FormHeaderService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class DealCardComponent {
  public tabs: TabLink[] = [];
  public readonly dealCardService = inject(DealCardService);
  public readonly stateService = inject(StateService);

  private readonly appService = inject(AppService);
  private readonly elementRef = inject(ElementRef<HTMLElement>);
  private readonly injector = inject(Injector);
  private readonly infoPopupService = inject(InfoPopupService);
  private readonly navigationService = inject(NavigationService);

  constructor() {
    this.dealCardService.load();

    this.tabs.push({
      header: 'shared2.groups.main',
      state: 'deal.profile',
    });

    if (
      this.appService.checkEntityPermission('Interaction', PermissionType.Read)
    ) {
      this.tabs.push({
        header: 'shared2.groups.interactions',
        state: 'deal.interactions',
      });
    }
  }

  /** Open user info. */
  public openUserInfo(userId: string): void {
    const target = this.elementRef.nativeElement.querySelector('#manager');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Changes state to client overview kpi. */
  public openClient(): void {
    this.stateService.go('client.overview.kpi', {
      entityId: this.dealCardService.deal.organization?.id,
      navigation: this.navigationService.selectedNavigationItem?.name,
      routeMode: RouteMode.continue,
    });
  }
}
