<div [formGroup]="form" ngbDropdown class="d-inline-block">
  <button
    type="button"
    class="btn btn-default without-caret"
    ngbDropdownToggle
    [title]="'projects.projects.card.expenses.settings.accountFilter.title' | translate"
  >
    <i class="bi bi-filter" aria-hidden="true"></i>
  </button>

  <div ngbDropdownMenu class="p-3">
    <div class="form-check text-nowrap">
      <input
        id="includeLaborCost"
        formControlName="includeLaborCost"
        type="checkbox"
        class="form-check-input"
        data-test="includeLaborCost"
      />
      <label for="includeLaborCost" class="form-check-label">
        {{
          'projects.projects.card.expenses.settings.accountFilter.props.includeLaborCost'
            | translate
        }}
      </label>
    </div>
    @if (accountNamesNotIncludedInBalance.length) {
      <hr />
    }

    <div [formGroup]="formAccountNamesNotIncludedInBalance">
      @for (controlName of accountNamesNotIncludedInBalance; track controlName) {
        <div class="form-check text-nowrap">
          <input
            [id]="controlName"
            [formControlName]="controlName"
            type="checkbox"
            class="form-check-input"
            [attr.data-test]="controlName"
          />
          <label [for]="controlName" class="form-check-label">
            {{ controlName }}
          </label>
        </div>
      }
    </div>
  </div>
</div>
