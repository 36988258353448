import { BaseSettings } from 'src/app/shared/models/inner/base-settings.interface';

/** Represents a Financial Account filter settings. */
export class ExpensesAccountFilterSettings implements BaseSettings {
  public readonly name = 'ExpensesAccountFilterSettings';
  public version: number;

  /** Determines whether to include Labor Cost or not. */
  public includeLaborCost: boolean;

  /**
   * Financial {@link FinancialAccount.name} (which are not included in balance) list to include.
   * @example
   * ['Corporate tax rate']
   */
  public includeFinAccountNamesNotIncludedInBalance: string[];

  public getDefault(): ExpensesAccountFilterSettings {
    return {
      version: 1,
      includeLaborCost: false,
      includeFinAccountNamesNotIncludedInBalance: [],
    } as ExpensesAccountFilterSettings;
  }
}
