import { ChangeDetectionStrategy, Component, signal } from '@angular/core';
import {
  FormControl,
  FormGroup,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';

import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';

import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { FinancialAccountType } from 'src/app/shared/models/entities/finance/financial-account-type.enum';

@Component({
  selector: 'tmt-financial-account-creation',
  templateUrl: './financial-account-creation.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class FinancialAccountCreationComponent {
  public isSaving = signal<boolean>(false);
  public creationForm: UntypedFormGroup = new FormGroup({
    name: new FormControl('', [
      Validators.required,
      Validators.maxLength(Constants.formNameMaxLength),
    ]),
    code: new FormControl('', [Validators.required]),
  });

  constructor(
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  /** Creates financial account. */
  public create(): void {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);

    const data: Partial<FinancialAccount> = {
      ...this.creationForm.getRawValue(),
      typeId: FinancialAccountType.expenses.id,
    };

    this.dataService
      .collection('FinancialAccounts')
      .insert(data)
      .subscribe({
        next: (response: FinancialAccount) => {
          this.notificationService.successLocal(
            'components.financialAccountCreationComponent.messages.created',
          );
          this.stateService.go('settings.financialAccount', {
            entityId: response.id,
          });
          this.isSaving.set(false);
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /** Closes modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
