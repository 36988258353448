import { ChangeDetectorRef, Component } from '@angular/core';
import { ListService } from 'src/app/shared/services/list.service';
import { ProjectExpenseEstimatesService } from '../project-expense-estimates.service';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-project-expense-estimates-toolbar',
  styleUrl: './project-expense-estimates-toolbar.component.scss',
  templateUrl: './project-expense-estimates-toolbar.component.html',
  standalone: false,
})
export class ProjectExpenseEstimatesToolbarComponent extends AbstractToolbar {
  constructor(
    public projectExpenseEstimatesService: ProjectExpenseEstimatesService,
    private listService: ListService,
    gridService: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  /** Executes `addEntry` command.  */
  public addEntry(): void {
    this.gridService.execute('addEntry');
  }

  /** Executes `recognize` command.  */
  public recognize(): void {
    this.gridService.execute('recognize');
  }

  /** Opens view settings modal and sets new settings on submit.  */
  public setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.projectExpenseEstimatesService.gridOptions.view =
          this.listService.getGridView();
        this.projectExpenseEstimatesService.load();
      },
      () => null,
    );
  }
}
