import { Component, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-domain-confirmation',
  templateUrl: './domain-confirmation.component.html',
  standalone: false,
})
export class DomainConfirmationComponent implements OnInit {
  @Input() domainName: string;

  isSaving = false;

  emails: NamedEntity[];

  form: UntypedFormGroup = this.fb.group({
    email: [null, Validators.required],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  public ok = () => {
    this.isSaving = true;

    this.data
      .collection('TenantDomains')
      .entity(`'${this.domainName}'`)
      .action('WP.SendActivationEmail')
      .execute({ email: this.form.value.email.name })
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.settings.domains.messages.activationLinkSent',
          );
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  ngOnInit(): void {
    this.emails = [
      {
        id: 'admin',
        name: 'admin@' + this.domainName,
      },
      {
        id: 'support',
        name: 'support@' + this.domainName,
      },
      {
        id: 'postmaster',
        name: 'postmaster@' + this.domainName,
      },
    ];

    this.form.controls.email.setValue(this.emails[0]);
  }
}
