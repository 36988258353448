/* eslint-disable @typescript-eslint/naming-convention */
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Constants } from 'src/app/shared/globals/constants';
import { CustomField } from 'src/app/shared/models/entities/settings/custom-field.model';
import { TranslateService } from '@ngx-translate/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { CustomFieldCardService } from './custom-field-card.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormColumn } from 'src/app/shared/models/entities/settings/metamodel.model';
import { Language } from 'src/app/shared/models/enums/language.enum';
import * as _ from 'lodash';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'wp-custom-field-card',
  templateUrl: './custom-field-card.component.html',
  styleUrl: './custom-field-card.component.scss',
  providers: [CustomFieldCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CustomFieldCardComponent implements OnInit {
  @Input() entityId: string;

  public field: CustomField;
  public state: CardState;
  public isSaving = false;
  public readonly: boolean;
  public typeName: string;
  public entityTypeName: string;
  public languages: NamedEntity[] = [
    {
      name: this.getLanguageName(Language.en.toLowerCase()),
      id: Language.en,
    },
    {
      name: this.getLanguageName(Language.ru.toLowerCase()),
      id: Language.ru,
    },
  ];

  public form = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    isActive: false,
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    localStrings: this.fb.array([]), // Determines labels and placeholders from configuration.
    configuration: this.fb.group({
      directoryId: null,
      isRequired: false,
      formColumn: null,
      orderNumber: null,
      defaultValue: null,
    }),
    isShownInEntityListFilters: false,
    isShownInEntityLists: false,
    isShownInEntityForms: false,
    isShownInReports: false,
    isOnlyForApi: false,
  });

  public availableFormColumns: { id: FormColumn; name: string }[] = [
    {
      id: 'Left',
      name: this.translate.instant('enums.formColumn.left'),
    },
    {
      id: 'Right',
      name: this.translate.instant('enums.formColumn.right'),
    },
  ];

  public get localStrings(): UntypedFormArray {
    return this.form.controls['localStrings'] as UntypedFormArray;
  }

  public get configuration(): UntypedFormGroup {
    return this.form.controls['configuration'] as UntypedFormGroup;
  }

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private cardService: CustomFieldCardService,
    private translate: TranslateService,
    private data: DataService,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
    private message: MessageService,
    private notification: NotificationService,
    private cdr: ChangeDetectorRef,
    private appService: AppService,
  ) {}

  public ngOnInit(): void {
    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });

    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: this.save,
      },
    ]);

    this.load();
  }

  /** Reloads form data. */
  public reload(): void {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message
        .confirmLocal('shared.leavePageMessage')
        .then(this.load, () => null);
    }
  }

  /** Saves the custom field data.This method validates the form, prepares the data, and sends it to the server. */
  public save: () => void = () => {
    this.form.markAllAsTouched();
    this.cardService.detectChanges();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const formData = this.form.value;

    // Check for unique languages in localStrings.
    const localStrings = formData.localStrings as any[];
    if (
      _.uniq(localStrings.map((ls) => ls.language.id)).length !==
      localStrings.length
    ) {
      this.notification.warningLocal(
        'settings.customFields.card.messages.languageMustBeUnique',
      );
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    // Prepare data for API.
    const data: any = {
      id: this.entityId,
      name: formData.name,
      isActive: formData.isActive,
      description: formData.description,
      entityType: this.field.entityType,
      type: this.field.type,
      directoryId: formData.configuration.directoryId,
      isShownInEntityListFilters: formData.isShownInEntityListFilters,
      isShownInEntityLists: formData.isShownInEntityLists,
      isShownInEntityForms: formData.isShownInEntityForms,
      isShownInReports: formData.isShownInReports,
      isOnlyForApi: formData.isOnlyForApi,
    };

    // Prepare configuration object.
    const cfg = {
      defaultValue: formData.configuration.defaultValue,
      isRequired: !!formData.configuration.isRequired,
      orderNumber: formData.configuration.orderNumber,
      formColumn: formData.configuration.formColumn.id,
      labels: [],
      placeholders: [],
    };

    // Process localStrings for labels and placeholders.
    (<any[]>formData.localStrings).forEach((localString) => {
      cfg.labels.push({
        language: localString.language.id,
        value: localString.label,
      });
      if (localString.placeholder) {
        cfg.placeholders.push({
          language: localString.language.id,
          value: localString.placeholder,
        });
      }
    });

    data.configuration = cfg;

    this.data
      .collection('CustomFields')
      .entity(this.entityId)
      .update(data)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.customFields.card.messages.saved',
          );
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
          this.cdr.detectChanges();
        },
      });
  };

  /**
   * Gets Localized Language name.
   *
   * @param lang Language key.
   * @returns Localized Language name.
   * */
  private getLanguageName(lang: string): string {
    return this.translate.instant(`enums.language.${lang}`);
  }

  /**
   * Loads the custom field data and populates the form.
   * This method is responsible for fetching the custom field data,
   * updating the form, and setting up the component's state.
   */
  private load: () => void = () => {
    this.form.reset(undefined, { emitEvent: false });
    this.localStrings.clear();

    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.data
      .collection('CustomFields')
      .entity(this.entityId)
      .get<CustomField>()
      .subscribe({
        next: (field) => {
          this.field = field;
          this.form.patchValue(field);

          this.configuration.patchValue(field.configuration);
          this.configuration.controls['directoryId'].patchValue(
            field.directoryId,
          );

          // Update navigation.
          this.navigationService.addRouteSegment({
            id: field.id,
            title: field.name,
          });

          this.readonly = !field.editAllowed;
          if (this.readonly) {
            this.form.disable();
          } else {
            this.form.enable();
          }

          // Update action panel.
          this.actionService.action('save').isShown = !this.readonly;

          // Set translated type and entity type names.
          this.typeName = this.translate.instant(
            'enums.metaEntityPropertyType.' + _.lowerFirst(field.type),
          );

          this.entityTypeName = this.appService.getMetaEntityDisplayName(
            field.entityType,
          );

          // Populate local strings (labels and placeholders).
          field.configuration.labels?.forEach((label) => {
            const lang = this.languages.find((l) => l.id === label.language);

            this.localStrings.push(
              this.fb.group({
                id: [Guid.generate()],
                language: [lang, Validators.required],
                label: [label.value, Validators.required],
                placeholder:
                  field.configuration.placeholders.find(
                    (ph) => ph.language === label.language,
                  )?.value ?? null,
              }),
            );
          });

          if (field.configuration.formColumn) {
            this.configuration
              .get('formColumn')
              .setValue(
                this.availableFormColumns.find(
                  (fc) => fc.id === field.configuration.formColumn,
                ),
              );
          }

          this.state = CardState.Ready;
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  };
}
