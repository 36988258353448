import { inject, Inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormGroup } from '@angular/forms';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';

import { Exception } from 'src/app/shared/models/exception';
import { ContactCreateModalComponent } from 'src/app/contacts/contact-create/contact-create.component';
import { DealContactsToolbarComponent } from 'src/app/deals/card/deal-contacts/toolbar/deal-contacts-toolbar.component';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ClientContactsService } from 'src/app/clients/card/client-contact/core/client-contacts.service';
import { DealCardService } from 'src/app/deals/card/deal-card.service';
import { Deal, DealContact } from 'src/app/deals/model/deal.model';
import { merge } from 'rxjs';
import { DealAddContactsModalComponent } from 'src/app/deals/card/deal-contacts/deal-add-contacts-modal/deal-add-contacts-modal.component';
import { DealContactsSettings } from 'src/app/deals/card/deal-contacts/deal-contacts-settings';

@Injectable()
export class DealContactsService extends ClientContactsService {
  public override gridOptions: GridOptions = {
    resizableColumns: true,
    sorting: true,
    selectionType: SelectionType.row,
    toolbar: DealContactsToolbarComponent,
    commands: [
      {
        name: 'create',
        allowedFn: () => !this.readonlyDeal() && !this.readonly(),
        handlerFn: () => {
          this.createClientContact();
        },
      },
      {
        name: 'select',
        allowedFn: () => !this.readonlyDeal() && !this.readonly(),
        handlerFn: () => {
          this.selectClientContact();
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonlyDeal() && !!formGroup,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.deleteContact(formGroup.value.id);
        },
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
    ],
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonlyDeal(),
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteContact(formGroup.value.id),
      },
    ],
    view: this.listService.getGridView(),
  };

  private dealContactsSettings: DealContactsSettings;
  private readonlyDeal = signal<boolean>(false);
  private readonly actionPanelService = inject(ActionPanelService);
  private readonly dealCardService = inject(DealCardService);

  constructor(
    @Inject('organizationId') public override organizationId: string,
  ) {
    super(organizationId);
    this.readonlyDeal.set(!this.dealCardService.deal.editAllowed);
    merge(this.actionPanelService.reload$, this.savingQueueService.error$)
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.load();
      });
  }

  /** Loads deal contacts. */
  public override load(): void {
    this.savingQueueService.save().then(
      () => {
        this.gridService.setLoadingState(true);
        this.formArray.clear();

        const query = this.buildContactsQuery();

        this.dealCardService.dealCollection
          .entity(this.dealCardService.dealId)
          .get<Deal>(query)
          .pipe(takeUntilDestroyed(this.destroyRef))
          .subscribe({
            next: (deal: Deal) => {
              const contacts = deal.dealContacts?.map(
                (dealContact) => dealContact.contact,
              );
              if (contacts?.length) {
                this.processContacts(contacts, true);
              }
              this.gridService.setLoadingState(false);
            },
            error: (error: Exception) => {
              this.notificationService.error(error.message);
              this.gridService.setLoadingState(false);
            },
          });
      },
      () => null,
    );
  }

  /**
   * Sets onlyActive setting.
   *
   * @param onlyActive indicator to show active or all entities.
   */
  protected override setConfig(onlyActive: boolean) {
    this.dealContactsSettings.onlyActive = onlyActive;
    this.localConfigService.setConfig(
      DealContactsSettings,
      this.dealContactsSettings,
    );
  }

  /** Loads onlyActive setting */
  protected override loadSettings(): void {
    this.dealContactsSettings =
      this.localConfigService.getConfig(DealContactsSettings);
    this.onlyActive.set(this.dealContactsSettings.onlyActive);
  }

  /** Opens creation contact modal window. */
  protected override createClientContact(): void {
    const modalRef = this.modal.open(ContactCreateModalComponent);

    (modalRef.componentInstance as ContactCreateModalComponent).organizationId =
      this.organizationId;

    modalRef.result.then(
      (result) => {
        this.addContactToDeal([<DealContact>{ contactId: result.id }]);
      },
      () => null,
    );
  }

  /**
   * Builds OData contacts query.
   * @returns OData contacts query object.
   */
  protected override buildContactsQuery(): any {
    const query: any = {
      select: ['id'],
      expand: {
        dealContacts: {
          select: ['contactId'],
          expand: {
            contact: {
              select: [
                'id',
                'name',
                'firstName',
                'lastName',
                'patronymic',
                'organizationId',
                'position',
                'description',
                'email',
                'mobilePhone',
                'phone',
                'isActive',
              ],
              expand: [{ role: { select: ['id', 'name'] } }],
            },
          },
          filter: {},
          orderBy: this.getOrderBy(),
        },
      },
    };

    this.customFieldService.enrichQuery(
      query.expand.dealContacts.expand.contact,
      'Contact',
    );

    if (this.onlyActive()) {
      query.expand.dealContacts.filter = 'contact/isActive eq true';
    } else {
      delete query.expand.dealContacts.filter;
    }

    return query;
  }

  /**
   * Gets orderBy for loading.
   *
   * @returns orderBy string.
   */
  protected override getOrderBy(): string {
    const column = this.gridService.order.column || 'firstName';
    const direction = this.gridService.order.reverse ? 'desc' : 'asc';

    return `contact/${column} ${direction}`;
  }

  /**
   * Deletes contact.
   *
   * @param contactId id of deleting contact.
   */
  protected override deleteContact(contactId: string): void {
    const dealContacts = this.dealCardService.deal.dealContacts;
    if (!dealContacts?.length) {
      return;
    }

    const index = dealContacts.findIndex(
      (dealContact) => dealContact.contactId === contactId,
    );

    if (index === -1) {
      return;
    }

    dealContacts.splice(index, 1);
    this.dealCardService.dealCollection
      .entity(this.dealCardService.dealId)
      .patch({ dealContacts })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.onContactDeleted(contactId);
          this.notificationService.successLocal(
            'components.dealContactsService.messages.contactDeleted',
          );
        },
        error: (error: Exception) =>
          this.notificationService.error(error.message),
      });
  }

  /**
   * Handles post-deletion logic for a contact.
   *
   * @param contactId id of deleting contact.
   */
  protected override onContactDeleted(contactId: string): void {
    super.onContactDeleted(contactId);

    this.dealCardService.dealContacts =
      this.dealCardService.deal.dealContacts.filter(
        (c) => c.contactId !== contactId,
      );
  }

  /** Opens adding contacts modal window. */
  private selectClientContact(): void {
    const modalRef = this.modal.open(DealAddContactsModalComponent, {
      size: 'xxl',
    });
    const instance =
      modalRef.componentInstance as DealAddContactsModalComponent;
    instance.parentEntityId = this.dealCardService.deal?.organization?.id;
    instance.existedEntities = this.dealCardService.deal?.dealContacts;
    modalRef.result.then(
      (contacts) => {
        this.addContactToDeal(contacts);
      },
      () => null,
    );
  }

  /**
   * Adds contact to deal.
   *
   * @param contactId id or array of id's adding contact.
   */
  private addContactToDeal(dealContacts: DealContact[]): void {
    if (!dealContacts || !dealContacts.length) {
      return;
    }

    const updatedContacts: DealContact[] = [];
    if (this.dealCardService.deal.dealContacts?.length) {
      const existingContacts = <DealContact[]>(
        this.dealCardService.deal.dealContacts.map((c) => ({
          contactId: c.contactId,
        }))
      );
      updatedContacts.push(...existingContacts);
      updatedContacts.push(...dealContacts);
    } else {
      updatedContacts.push(...dealContacts);
    }

    this.dealCardService.dealCollection
      .entity(this.dealCardService.dealId)
      .patch({ dealContacts: updatedContacts })
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.dealCardService.dealContacts = updatedContacts;
          this.load();
          this.notificationService.successLocal(
            dealContacts.length === 1
              ? 'components.dealContactsService.messages.contactAdded'
              : 'components.dealContactsService.messages.contactsAdded',
          );
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }
}
