<div class="toolbar-container">
  <div class="group">
    <button
      type="button"
      class="btn btn-default"
      title="  {{ 'shared2.actions.create' | translate }}"
      [disabled]="!gridService.canBeExecuted('create', gridService.selectedGroup$.getValue())"
      (click)="gridService.execute('create')"
      data-test="create"
    >
      <i class="bi bi-plus-lg bi-15"></i>
      {{ 'shared2.actions.create' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="  {{ 'components.dealContactsToolbarComponent.actions.selectFromClient' | translate }}"
      [disabled]="!gridService.canBeExecuted('select', gridService.selectedGroup$.getValue())"
      (click)="gridService.execute('select')"
    >
      {{ 'components.dealContactsToolbarComponent.actions.selectFromClient' | translate }}
    </button>

    <button
      type="button"
      class="btn btn-default"
      title="{{ 'shared2.actions.delete' | translate }}"
      [disabled]="!gridService.canBeExecuted('delete', gridService.selectedGroup$.getValue())"
      (click)="gridService.execute('delete', gridService.selectedGroup$.getValue())"
      data-test="delete"
    >
      <i class="bi bi-trash3" aria-hidden="true"></i>
    </button>
  </div>

  <div class="group ms-auto">
    <div ngbDropdown class="d-inline-block">
      <button
        type="button"
        class="btn btn-default without-caret"
        ngbDropdownToggle
        title="{{ 'shared2.actions.options' | translate }}"
        data-test="options"
      >
        <i class="bi bi-sliders2 bi-15" aria-hidden="true"></i>
      </button>
      <div ngbDropdownMenu class="p-3">
        <div class="form-check text-nowrap">
          <input
            [formControl]="filterControl"
            type="checkbox"
            class="form-check-input"
            id="onlyActive"
          />
          <label class="form-check-label" for="onlyActive">
            {{ 'shared2.filters.onlyActive' | translate }}
          </label>
        </div>
      </div>
    </div>
    <button
      type="button"
      class="btn btn-default"
      (click)="gridService.execute('setUserView')"
      title="{{ 'shared2.actions.setView' | translate }}"
      data-test="viewSettings"
    >
      <i class="bi bi-gear" aria-hidden="true"></i>
    </button>
  </div>
</div>
