import { DOCUMENT } from '@angular/common';
import { Component, Inject, Input, Renderer2 } from '@angular/core';

import { TranslateModule } from '@ngx-translate/core';

import { FileBoxViewMode } from 'src/app/shared/components/controls/file-box/file-box.model';
import { FilesService } from 'src/app/shared/components/controls/file-box/files.service';

@Component({
    selector: 'tmt-file-box-button-add',
    imports: [TranslateModule],
    templateUrl: './file-box-button-add.component.html',
    styleUrl: './file-box-button-add.component.scss'
})
export class FileBoxButtonAddComponent {
  @Input({ required: true }) public mode: FileBoxViewMode;

  protected inputFilesListener: () => void;

  constructor(
    protected renderer: Renderer2,
    protected filesService: FilesService,
    @Inject(DOCUMENT) protected document: Document,
  ) {}

  public attachFiles(): void {
    const input: HTMLInputElement = this.renderer.createElement('input');
    this.renderer.setAttribute(input, 'type', 'file');
    this.renderer.setAttribute(input, 'multiple', 'true');

    this.inputFilesListener = this.renderer.listen(
      input,
      'change',
      (event: Event) => {
        this.filesService.addFiles(
          Array.from((event.target as HTMLInputElement).files || []),
        );
        this.inputFilesListener();
      },
    );

    input.click();
  }
}
