import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { State } from 'src/app/shared/models/entities/state.model';

@Component({
  selector: 'tmt-state-badge',
  templateUrl: './state-badge.component.html',
  styleUrls: ['./state-badge.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StateBadgeComponent {
  @Input() state: State;
}
