import {
  Component,
  OnInit,
  Input,
  DestroyRef,
  inject,
  signal,
  effect,
} from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { AppService } from 'src/app/core/app.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import {
  UntypedFormBuilder,
  UntypedFormArray,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { forkJoin } from 'rxjs';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { UserSchedule } from 'src/app/shared/models/entities/settings/user-schedule.model';
import { UserScheduleToolbarComponent } from './user-schedule-toolbar/user-schedule-toolbar.component';
import { MessageService } from 'src/app/core/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { UserPersonalScheduleModalComponent } from './user-personal-schedule-modal/user-personal-schedule-modal.component';
import { Exception } from 'src/app/shared/models/exception';
import { filter } from 'rxjs/operators';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridDateControlColumn,
  GridSelectControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-user-schedule',
  templateUrl: './user-schedule.component.html',
  providers: [GridService],
  standalone: false,
})
export class UserScheduleComponent implements OnInit {
  @Input() entityId: string;

  public isSaving = signal(false);
  public isLoading = signal(false);
  public readonly = signal(
    !this.app.checkEntityPermission('UserSchedule', PermissionType.Modify),
  );

  // List of schedules available for selection.
  public schedules: NamedEntity[] = [];

  public scheduleForm: UntypedFormGroup = this.fb.group({
    initialValue: this.fb.group({
      effectiveDate: [null],
      schedule: [null, Validators.required],
      id: [Guid.generate()],
    }),
    values: this.fb.array([]),
  });

  public gridOptions: GridOptions = {
    selectionType: SelectionType.row,
    toolbar: UserScheduleToolbarComponent,
    rowCommands: [
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly(),
        handlerFn: (formGroup: UntypedFormGroup, index: number) => {
          (this.scheduleForm.controls.values as UntypedFormArray).removeAt(
            index,
          );
          this.scheduleForm.markAsDirty();
        },
      },
    ],
    commands: [
      {
        name: 'create',
        handlerFn: () => {
          const group = this.getGridFormGroup();
          (this.scheduleForm.controls.values as UntypedFormArray).insert(
            0,
            group,
          );
          this.scheduleForm.markAsDirty();
          this.gridService.selectGroup(group);
        },
      },
      {
        name: 'openUserSchedule',
        allowedFn: () => {
          const values = this.getAllSchedules();
          return (
            !this.isLoading() &&
            !!values.find((x) => x.schedule?.id === 'Personal')
          );
        },
        handlerFn: () => {
          this.openPersonalSchedule();
        },
      },
    ],
    view: {
      name: 'schedules',
      columns: [
        {
          name: 'effectiveDate',
          header: 'settings.users.card.schedules.columns.effectiveDate',
          hint: 'settings.users.card.schedules.columns.effectiveDate',
          type: GridColumnType.DateControl,
          property: 'effectiveDate',
          width: '150px',
          required: true,
        } as GridDateControlColumn,
        {
          name: 'schedule',
          header: 'settings.users.card.schedules.props.schedule.label',
          hint: 'settings.users.card.schedules.props.schedule.label',
          type: GridColumnType.SelectControl,
          property: 'schedule',
          width: '100%',
          required: true,
          placeholder: 'settings.users.card.schedules.props.schedule.ph',
          values: [],
        } as GridSelectControlColumn,
      ],
    },
  };

  private personalSchedule: NamedEntity = {
    id: 'Personal',
    name: this.translate.instant(
      'settings.users.card.schedules.personalSchedule.header',
    ),
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    private notification: NotificationService,
    private gridService: GridService,
    private translate: TranslateService,
    private blockUI: BlockUIService,
    private data: DataService,
    private app: AppService,
    private actionService: ActionPanelService,
    private fb: UntypedFormBuilder,
    private message: MessageService,
    private modalService: NgbModal,
  ) {
    effect(() => {
      if (this.isSaving()) {
        this.blockUI.start();
        this.actionService.action('save').start();
      } else {
        this.actionService.action('save').stop();
        this.blockUI.stop();
      }
    });
  }

  public ngOnInit(): void {
    this.actionService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.save();
      });

    if (!this.readonly()) {
      this.actionService.action('save').show();
    }

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.reload();
      });
    this.load();
  }

  /**
   * Creates a form group for the grid based on the provided row data.
   *
   * @param row The row data to base the form group on.
   * @returns An UntypedFormGroup representing the grid row.
   */
  private getGridFormGroup(row?: any): UntypedFormGroup {
    return this.fb.group({
      effectiveDate: [row?.effectiveDate ?? null, Validators.required],
      schedule: [row?.schedule ?? null, Validators.required],
      id: [row?.id ?? Guid.generate()],
    });
  }

  /** Reloads the user schedule data. If the form is dirty, prompts the user to confirm before reloading. */
  private reload(): void {
    if (!this.scheduleForm.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => {
          this.load();
        },
        () => null,
      );
    }
  }

  /** Load function to fetch data. */
  private load(): void {
    this.isLoading.set(true);

    this.actionService.action('save').hide();

    this.schedules = [this.personalSchedule];

    forkJoin([
      this.data.collection('Schedules').query<NamedEntity[]>({
        filter: [{ isActive: true }],
        orderBy: ['name'],
        select: ['id', 'name'],
      }),

      this.data
        .collection('Users')
        .entity(this.entityId)
        .collection('Schedules')
        .query<UserSchedule[]>({
          expand: [{ schedule: { select: ['id', 'name'] } }],
          orderBy: 'effectiveDate desc',
        }),
    ]).subscribe({
      next: (value: [NamedEntity[], UserSchedule[]]) => {
        (this.scheduleForm.controls.values as UntypedFormArray).clear();

        // Get the final list of schedules for selection.
        this.schedules = this.schedules.concat(value[0]);
        const columnValues: NamedEntity[] = (
          this.gridOptions.view.columns.find(
            (c) => c.name === 'schedule',
          ) as GridSelectControlColumn
        ).values;
        columnValues.splice(0, columnValues.length);
        this.schedules.forEach((s) => columnValues.push(s));

        // Convert user schedules and load into form.
        const userSchedules = value[1];

        userSchedules.forEach((userSchedule: UserSchedule) => {
          if (!userSchedule.schedule) {
            userSchedule.schedule = this.personalSchedule;
          }
        });

        // Remove the initial value from the list.
        let initialValue = userSchedules.find(
          (userSchedule: UserSchedule) => !userSchedule.effectiveDate,
        );
        if (initialValue) {
          const index = userSchedules.indexOf(initialValue);
          userSchedules.splice(index, 1);
        } else {
          initialValue = {
            effectiveDate: null,
            id: Guid.generate(),
            schedule: null,
            user: null,
          };
        }

        this.scheduleForm.get('initialValue').patchValue(initialValue);

        userSchedules.forEach((userSchedule: UserSchedule) => {
          (this.scheduleForm.controls.values as UntypedFormArray).push(
            this.getGridFormGroup(userSchedule),
          );
        });

        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        this.readonly()
          ? this.scheduleForm.disable()
          : this.scheduleForm.enable();
        this.scheduleForm.markAsPristine();
        this.scheduleForm.markAsUntouched();
        this.isLoading.set(false);
        this.actionService.action('save').isShown = !this.readonly();
      },
      error: (error: Exception) => {
        this.isLoading.set(false);
        this.notification.error(error.message);
      },
    });
  }

  /** Saves the user schedule. */
  public save(): void {
    this.scheduleForm.markAllAsTouched();
    this.gridService.detectChanges();

    if (this.scheduleForm.valid) {
      this.isSaving.set(true);
      const data = { schedules: [] };
      const value = this.scheduleForm.value;

      data.schedules.push({
        effectiveDate: null,
        id: this.scheduleForm.value.initialValue.id,
        scheduleId:
          value.initialValue.schedule.id === 'Personal'
            ? null
            : value.initialValue.schedule.id,
        userId: this.entityId,
      });

      (value.values as UserSchedule[]).forEach((us: UserSchedule) => {
        data.schedules.push({
          effectiveDate: us.effectiveDate,
          id: us.id,
          scheduleId: us.schedule.id === 'Personal' ? null : us.schedule.id,
          userId: this.entityId,
        });
      });

      this.data
        .collection('Users')
        .entity(this.entityId)
        .action('WP.UpdateSchedules')
        .execute(data)
        .subscribe({
          next: () => {
            this.notification.successLocal('shared.messages.saved');
            this.scheduleForm.markAsPristine();
            this.isSaving.set(false);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.isSaving.set(false);
          },
        });
    } else {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
    }
  }

  /**
   * Retrieves all schedules, including the initial value.
   *
   * @returns An array of all schedules, including the initial value.
   */
  private getAllSchedules(): any[] {
    const values = (this.scheduleForm.controls.values as UntypedFormArray)
      .value as any[];
    const initialValue = this.scheduleForm.controls.initialValue.value as any;
    return values.concat([initialValue]);
  }

  /** Opens the personal schedule modal for the user. */
  private openPersonalSchedule(): void {
    const modalRef = this.modalService.open(
      UserPersonalScheduleModalComponent,
      {
        size: 'lg',
      },
    );
    const modalInstance =
      modalRef.componentInstance as UserPersonalScheduleModalComponent;
    modalInstance.userId = this.entityId;
    modalInstance.readonly = this.readonly();
    modalInstance.usingSchedules = this.getAllSchedules();
  }
}
