import { ChangeDetectorRef, Component, Input } from '@angular/core';
import { DateTime } from 'luxon';
import { ScheduleNavigationService } from '../core/schedule-navigation.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  AllPlanningScales,
  PlanningScale,
} from 'src/app/shared/models/enums/planning-scale.enum';
import { ValueMode } from '../../planner/models/value-mode.enum';
import { TranslateService } from '@ngx-translate/core';
import { ScheduleNavigationContext } from '../models/schedule-navigation-context.enum';
import { VisibleParts } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-parts.enum';

@Component({
  selector: 'wp-schedule-navigation',
  templateUrl: './schedule-navigation.component.html',
  styleUrls: ['./schedule-navigation.component.scss'],
  standalone: false,
})
export class ScheduleNavigationComponent {
  @Input() currentDateSelector: string | null = null;
  @Input() maxScale = PlanningScale.Month;
  @Input() set usedValueModes(valueModes: ValueMode[]) {
    this.valueModes = valueModes;
  }
  @Input() visibleParts: VisibleParts[] = [
    VisibleParts.calendar,
    VisibleParts.planningScaleSelector,
    VisibleParts.valueModeSelector,
  ];

  public valueModes: ValueMode[] = [
    ValueMode.Hours,
    ValueMode.Percentage,
    ValueMode.FTE,
    ValueMode.Days,
  ];

  @Input() disabled = false;

  valueMode = ValueMode;

  public calendarIsOpen = false;

  public get scales(): PlanningScale[] {
    return AllPlanningScales.filter(
      (_, index) => index <= AllPlanningScales.indexOf(this.maxScale),
    );
  }

  get isCalendarVisible() {
    return this.visibleParts.includes(VisibleParts.calendar);
  }

  get isPlanningScaleVisible() {
    return this.visibleParts.includes(VisibleParts.planningScaleSelector);
  }

  get isValueModeVisible() {
    return this.visibleParts.includes(VisibleParts.valueModeSelector);
  }

  constructor(
    public service: ScheduleNavigationService,
    private changeDetector: ChangeDetectorRef,
    private translate: TranslateService,
  ) {}

  /** Предыдущий период. */
  public previousPeriod() {
    this.service.previous();
  }

  /** Последующий период. */
  public nextPeriod() {
    this.service.next();
  }

  public jump(date: NgbDate) {
    this.service.jump(DateTime.local(date.year, date.month, date.day));
    this.closeCalendar();
  }

  public getValueModeName(valueMode: ValueMode): string {
    switch (valueMode) {
      case ValueMode.Percentage:
        return this.translate.instant('shared.planner.valueMode.percentage');
      case ValueMode.Hours:
        return this.translate.instant('shared.planner.valueMode.hours');
      case ValueMode.FTE:
        return this.translate.instant('shared.planner.valueMode.fte');
      case ValueMode.Cost:
        return this.translate.instant('shared.planner.valueMode.cost');
      case ValueMode.Days:
        return this.translate.instant('shared.planner.valueMode.days');
      default:
        return null;
    }
  }

  public getPlanningScaleName(planningScale: PlanningScale): string {
    return this.translate.instant(`enums.planningScale.${planningScale}`);
  }

  public closeCalendar() {
    this.calendarIsOpen = false;
    this.changeDetector.detectChanges();
  }

  public isResourcesContext() {
    return (
      this.service.context === ScheduleNavigationContext.Booking ||
      this.service.context === ScheduleNavigationContext.Resources ||
      this.service.context === ScheduleNavigationContext.ResourceSummary ||
      this.service.context === ScheduleNavigationContext.ProjectSummary ||
      this.service.context === ScheduleNavigationContext.ResourcesCalendar ||
      this.service.context === ScheduleNavigationContext.ResourceRequirements
    );
  }
}
