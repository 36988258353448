import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'wp-validation-modal',
  templateUrl: './validation-modal.component.html',
  styleUrls: ['./validation-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ValidationModalComponent {
  @Input() items: any[];

  public get canOverride(): boolean {
    return this.items?.every((item) => item.canOverride);
  }

  constructor(private activeModal: NgbActiveModal) {}

  public ok = () => {
    this.activeModal.close();
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
