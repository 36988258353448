import {
  Component,
  ChangeDetectionStrategy,
  signal,
  DestroyRef,
  inject,
} from '@angular/core';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

import { Exception } from 'src/app/shared/models/exception';
import { Organization } from 'src/app/shared/models/entities/projects/organization.model';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';

import { LogService } from 'src/app/core/log.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { NavigationService } from 'src/app/core/navigation.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-client-settings-modal',
  templateUrl: './client-settings-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [ClientCardService, SavingQueueService],
  standalone: false,
})
export class ClientSettingsModalComponent {
  public readonly isSaving = signal<boolean>(false);

  public readonly clientCardService = inject(ClientCardService);

  private readonly customFieldService = inject(CustomFieldService);
  private readonly dataService = inject(DataService);
  private readonly destroyRef = inject(DestroyRef);
  private readonly logService = inject(LogService);
  private readonly navigationService = inject(NavigationService);
  private readonly ngbActiveModal = inject(NgbActiveModal);
  private readonly notificationService = inject(NotificationService);
  private readonly stateService = inject(StateService);

  constructor() {
    this.clientCardService.creatingMode = true;

    this.customFieldService.enrichFormGroup(
      this.clientCardService.clientForm,
      'Organizations',
    );
    this.customFieldService.enrichFormGroupWithDefaultValues(
      this.clientCardService.clientForm,
      'Organizations',
    );
  }

  /** Creates new client. */
  public create(): void {
    this.clientCardService.clientForm.markAllAsTouched({ emitEvent: false });
    if (this.clientCardService.clientForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);
    this.logService.debug('Saving client entity...');

    const client =
      this.clientCardService.clientForm.getRawValue() as Organization;

    const data: Partial<Organization> = {
      name: client.name,
      code: client.code,
      isActive: client.isActive,
      managerId: client.manager.id,
      phone: client.phone,
      site: client.site,
      contact: client.contact,
      contactEmail: client.contactEmail,
      description: client.description,
      postAddress: client.postAddress,
      billingAddress: client.billingAddress,
      billingEqualPost: client.billingEqualPost,
    };

    this.customFieldService.assignValues(data, client, 'Organization');

    this.dataService
      .collection('Organizations')
      .insert(data)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (response) => {
          this.notificationService.successLocal(
            'projects.clients.settings.messages.created',
          );
          this.stateService.go('client', {
            entityId: response.id,
            routeMode: RouteMode.continue,
            navigation: this.navigationService.selectedNavigationItem?.name,
          });
          this.isSaving.set(false);
          this.ngbActiveModal.close();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /** Closes modal window. */
  public cancel(): void {
    this.ngbActiveModal.dismiss('cancel');
  }
}
