import { Schema, Node } from 'prosemirror-model';
import { EditorState } from 'prosemirror-state';
import { findParentNodeOfType } from 'prosemirror-utils';
import { joinPreviousBlock } from 'src/app/shared/components/controls/rich-editor-box/commands/join';

export enum ListNode {
  ListItem = 'list_item',
  BulletList = 'bullet_list',
  OrderedList = 'ordered_list',
}

export const nodeTypeFactory = (nodeName: string) => (schema: Schema) =>
  schema.nodes[nodeName];

export const liType = nodeTypeFactory(ListNode.ListItem);
export const blType = nodeTypeFactory(ListNode.BulletList);
export const olType = nodeTypeFactory(ListNode.OrderedList);

export const findAnyParentList = (schema: Schema) =>
  findParentNodeOfType([blType(schema), olType(schema)]);

export const isIntoListOfType = (nodeName: ListNode) => (state: EditorState) =>
  findAnyParentList(state.schema)(state.selection)?.node.type.name === nodeName;

export const isListNode = ({ type }: Node): boolean =>
  type === blType(type.schema) || type === olType(type.schema);

export const isListItemNode = (node: Node): boolean =>
  node.type === liType(node.type.schema);

export const isListOrItemNode = (node: Node): boolean =>
  isListNode(node) || isListItemNode(node);

export const joinPrevList = joinPreviousBlock({
  checkPrevNode: isListNode,
  skipNode: isListOrItemNode,
});
