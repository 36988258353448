import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import { groupBy } from 'lodash';
import { Permission } from 'src/app/shared/models/session/client-session.model';
import { DataService } from 'src/app/core/data.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { NotificationService } from 'src/app/core/notification.service';

@Component({
  selector: 'wp-status-page',
  templateUrl: './status-page.component.html',
  standalone: false,
})
export class StatusPageComponent implements OnInit {
  granules: Dictionary<Permission[]>;

  public get roles(): string {
    return this.app.session.roles.join(', ');
  }

  constructor(
    public app: AppService,
    public data: DataService,
    public notification: NotificationService,
  ) {}

  renewSession() {
    this.data.model
      .action('ResetTenantCache')
      .execute()
      .subscribe(() => {
        location.reload();
      });
  }

  resetClientProfile() {
    this.app.clientProfile = {
      version: this.app.clientProfile.version,
      userViews: {},
      navigationViews: [],
      completedQuickStartPages: [],
    };
    this.app.saveProfile();
    this.notification.success('Client profile has been reset.');
  }

  ngOnInit() {
    this.granules = groupBy(
      this.app.session.permissions,
      (permission: Permission) => permission.granularName,
    );
  }
}
