import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'tmt-garland',
  template: `<ul class="garland">
    <li *ngFor="let light of lights"></li>
  </ul>`,
  styleUrls: ['./garland.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GarlandComponent {
  public lights = new Array(200);
}
