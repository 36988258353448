import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { StateService } from '@uirouter/angular';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectCardService } from '../../../core/project-card.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';

@Component({
  selector: 'wp-rbc-view-switcher',
  templateUrl: './rbc-view-switcher.component.html',
  styleUrls: ['./rbc-view-switcher.component.scss'],
  standalone: false,
})
export class RbcViewSwitcherComponent implements OnInit {
  public control = new FormControl('calendar');

  constructor(
    private state: StateService,
    public projectCardService: ProjectCardService,
  ) {}

  ngOnInit(): void {
    switch (this.state.current.name) {
      case 'project.rbc.calendar':
        this.control.setValue('calendar');
        break;
      case 'project.rbc.revenue':
        this.control.setValue('revenue');
        break;
      case 'project.rbc.billing':
        this.control.setValue('billing');
        break;
    }

    this.control.valueChanges.subscribe(() => {
      this.state.go(`project.rbc.${this.control.value}`, {
        routeMode: RouteMode.keep,
      });
    });

    this.projectCardService.project$.subscribe((project: Project) => {
      if (
        !project.financeViewAllowed &&
        this.state.current.name === 'project.rbc.calendar'
      ) {
        if (project.revenueEstimateViewAllowed) {
          this.control.setValue('revenue');
        } else {
          this.control.setValue('billing');
        }
      }
    });
  }
}
