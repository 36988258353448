import { Component, Input, OnInit } from '@angular/core';
import {
  UserView,
  UserViewColumn,
} from 'src/app/shared/models/inner/user-view';
import { List } from 'src/app/shared/models/inner/list';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';
import { View, ViewColumn } from 'src/app/shared/models/inner/view';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { TotalType } from 'src/app/shared/models/inner/total-type';
import { SortableEvent, SortableOptions } from 'sortablejs';
import { NotificationService } from '../../../../core/notification.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import _ from 'lodash';

/** Диалог настройки представления. */
@Component({
  selector: 'wp-view-settings-modal',
  templateUrl: './view-settings-modal.component.html',
  styleUrls: ['./view-settings-modal.component.scss'],
  standalone: false,
})
export class ViewSettingsModalComponent implements OnInit {
  columns: any[] = [];
  selectedColumns: any[] = [];
  columnsContainerId = 'columns-container';

  @Input() userView: UserView;
  @Input() defaultUserView: UserView;
  @Input() list: List;
  @Input() view: View;
  @Input() viewName: string;

  @Input() modern: boolean;

  public isSaving = false;
  public viewTableStyle: Dictionary<string> = {};

  dropEventHandler = () => null;

  columnsSortableOptions: SortableOptions = {
    group: {
      name: 'columns',
      pull: 'clone',
      put: false,
    },
    sort: false,
    handle: '.handle',
    animation: 0,
    filter: '.not-draggable',
  };

  selectedColumnsSortableOptions: SortableOptions = {
    group: {
      name: 'selectedColumns',
      pull: true,
      put: (from, to, dragEl) => {
        const columnName = dragEl.dataset.name;

        return !this.selectedColumns.find((c) => c.name === columnName);
      },
    },
    handle: '.handle',
    chosenClass: 'chosen-selected',
    ghostClass: 'ghost-selected',
    dragClass: 'drag-selected',
    onStart: (event: SortableEvent) => {
      const columnName = event.item.dataset.name;

      const column = this.selectedColumns.find((c) => c.name === columnName);

      this.dropEventHandler = () => {
        if (column.required) return;
        this.deleteItem(column);
      };

      document
        .getElementById(this.columnsContainerId)
        .addEventListener('drop', this.dropEventHandler, { once: true });
    },
    onEnd: () => {
      document
        .getElementById(this.columnsContainerId)
        .removeEventListener('drop', this.dropEventHandler);
    },
  };

  constructor(
    private translate: TranslateService,
    private activeModal: NgbActiveModal,
    private notification: NotificationService,
  ) {}

  /* Функция для клонирования элементов SortableJS списка. */
  cloneFieldHandler(column) {
    column.isSelected = true;
    return column;
  }

  /* Формирует представления по настройкам. */
  parseSettings() {
    this.columns = [];
    this.selectedColumns = [];

    this.view.columns.forEach((viewColumn: ViewColumn) => {
      const listColumn = this.list.columns.find(
        (c: GridColumn) => c.name === viewColumn.column,
      );
      const userViewColumn = this.userView.columns.find(
        (c: UserViewColumn) => c.column === viewColumn.column,
      );

      const column = {
        name: listColumn.name,
        header: this.translate.instant(listColumn.header ?? listColumn.hint),
        hint: this.translate.instant(listColumn.hint),
        total: userViewColumn
          ? userViewColumn.total
          : viewColumn.totalByDefault,
        totals: listColumn.availableTotals,
        fixedWidth: listColumn.fixedWidth === true,
        width: userViewColumn ? userViewColumn.width : viewColumn.width,
        isSelected: false,
        required: viewColumn.isRequired,
      };

      this.columns.push(column);
    });

    this.columns = _.sortBy(this.columns, ['required']);

    this.userView.columns.forEach((userViewColumn: UserViewColumn) => {
      const column: any = this.columns.find(
        (c: any) => c.name === userViewColumn.column,
      );

      column.isSelected = true;
      this.selectedColumns.push(column);
    });
  }

  /* Добавляет или удаляет из выбранных колонок*/

  onSelected = (column: any) => {
    if (column.isSelected) {
      // Insert with respect to view settings.
      let index = this.selectedColumns.length - 1;

      let viewColumnIndex = this.view.columns.findIndex(
        (c) => c.column === column.name,
      );

      // Search nearest predecessor.
      while (viewColumnIndex > 0) {
        viewColumnIndex--;
        const predecessorViewColumn = this.view.columns[viewColumnIndex];
        const selectedPredecessorColumnIndex = this.selectedColumns.findIndex(
          (c) => c.name === predecessorViewColumn.column,
        );
        if (selectedPredecessorColumnIndex > 0) {
          index = selectedPredecessorColumnIndex + 1;
          break;
        }
      }

      this.selectedColumns.splice(index, 0, column);
    } else {
      this.selectedColumns = this.selectedColumns.filter(
        (c: any) => c.isSelected,
      );
    }
  };

  /* Возвращает отображаемое имя функции итогов.*/
  getTotalTitle(total?: TotalType) {
    const localString = total ? `shared.total.${total}` : `shared.total.none`;
    return this.translate.instant(localString);
  }

  /* Сбрасывает настройки представления по умолчанию. */
  public toDefault() {
    this.userView = this.defaultUserView;
    this.parseSettings();
  }

  /* Сохраняет настройки представления */

  public ok() {
    if (this.selectedColumns.length === 0) {
      this.notification.warningLocal('shared.columnsCountError');
      return;
    }

    this.isSaving = true;

    const userView: UserView = {
      version: this.list.version,
      name: this.view.name,
      columns: [],
      order: this.userView.order,
    };

    this.selectedColumns.forEach((selectedColumn: any) => {
      const userViewColumn: UserViewColumn = {
        column: selectedColumn.name,
        // eslint-disable-next-line radix
        width: parseInt(selectedColumn.width),
        total: selectedColumn.total,
      };

      userView.columns.push(userViewColumn);
    });

    this.activeModal.close(userView);
  }

  /* Закрывает модальное окно */
  public cancel() {
    this.activeModal.dismiss();
  }

  /* Удаляет колонку из выбранных */
  public deleteItem(column: any) {
    column.isSelected = false;
    this.onSelected(column);
  }

  ngOnInit() {
    this.view = this.list.views.find((v: View) => v.name === this.viewName);
    this.parseSettings();
  }
}
