import {
  Component,
  Injector,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { DatePipe } from '@angular/common';

import { RawParams, StateService } from '@uirouter/core';
import { TranslateService } from '@ngx-translate/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

import _ from 'lodash';

import { NavigationService } from 'src/app/core/navigation.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { ResourceRequest } from 'src/app/shared/models/entities/resources/resource-request.model';
import { ResourceRequestModalComponent } from 'src/app/resource-requests/shared/resource-request/modal/resource-request-modal.component';
import { ProjectTeamService } from 'src/app/projects/card/project-team/project-team.service';

@Component({
  selector: 'tmt-request-cell',
  templateUrl: './request-cell.component.html',
  styleUrls: ['./request-cell.component.scss'],
  providers: [ProjectTeamService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RequestCellComponent implements OnChanges {
  @Input() public resourceRequests: Partial<ResourceRequest>[];
  @Input() public teamMemberId: string;

  public stateParams: RawParams = {
    navigation: this.navigationService.selectedNavigationItem?.name,
    routeMode: RouteMode.continue,
  };

  constructor(
    private navigationService: NavigationService,
    private stateService: StateService,
    private modal: NgbModal,
    private injector: Injector,
    private cdr: ChangeDetectorRef,
    private translateService: TranslateService,
    private datePipe: DatePipe,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['resourceRequests']) {
      this.resourceRequests = _.orderBy(
        this.resourceRequests,
        [
          (rr) => ResourceRequest.systemStateIds.includes(rr.state.id),
          'created',
        ],
        ['asc', 'desc'],
      );
    }
  }

  /** Opens resource request. */
  public openResourceRequest(requestId: string): void {
    this.stateService.go('resourceRequest', {
      navigation: this.stateParams.navigation,
      routeMode: this.stateParams.routeMode,
      entityId: requestId,
    });
  }

  /** Creates resource request. */
  public createRequest(): void {
    const modalRef = this.modal.open(ResourceRequestModalComponent, {
      size: 'xl',
      injector: this.injector,
    });

    (modalRef.componentInstance as ResourceRequestModalComponent).teamMemberId =
      this.teamMemberId;

    modalRef.result.then(
      (request: ResourceRequest) => {
        this.openResourceRequest(request.id);
      },
      () => null,
    );
  }

  public getHint(request: Partial<ResourceRequest>): string {
    return `${request.name}, ${this.translateService.instant(
      'shared.props.created',
    )}: ${this.datePipe.transform(
      request.created,
      'shortDate',
    )}, ${this.translateService.instant(
      'resources.requests.props.requestedHours',
    )}: ${(request.requestedHours ?? 0).toFixed(
      2,
    )} ${this.translateService.instant('shared.unitHour')}`;
  }
}
