import { DateTime } from 'luxon';
import { ScheduleService } from 'src/app/core/schedule.service';
import {
  ProjectTask,
  ProjectTaskDependency,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';

/** Uses for check circular dependency in the project tasks and task dependency chain search. */
export class TimelineGraph {
  /** All graph nodes and outgoing edges. Each node determines start or finish one of task. Each edge determines dependency. */
  private graph = new Map<string, GraphNodeInfo>();

  /** All graph nodes and incoming edges. Each node determines start or finish one of task. Each edge determines dependency. */
  private predecessorGraph = new Map<string, GraphNodeInfo>();

  private mainTaskId: string;
  private tasks: ProjectTask[];
  private scheduleService: ScheduleService;

  private criticalDependencies: {
    taskId: string;
    dependency: ProjectTaskDependency;
  }[] = [];
  private criticalTaskIds: string[] = [];

  constructor(tasks: ProjectTask[], scheduleService: ScheduleService) {
    this.mainTaskId = tasks.find((t) => !t.leadTaskId).id;
    this.tasks = structuredClone(tasks);
    this.scheduleService = scheduleService;

    this.initGraph();
    this.initPredecessorGraph();
    this.initCriticalPathTasksAndDependencies();
  }

  /**
   * Checks if a path is allowed between two nodes in the graph.
   *
   * @param beginNode - The starting node of the path.
   * @param endNode - The ending node of the path.
   * @returns True if the path is allowed, false otherwise.
   */
  public checkIsPathAllowed(beginNode: GraphNode, endNode: GraphNode): boolean {
    // There can be only one dependency between two tasks.
    if (
      this.tasks
        .find((t) => t.id === beginNode.id)
        .dependencies.some((d) => d.predecessorId === endNode.id) ||
      this.tasks
        .find((t) => t.id === endNode.id)
        .dependencies.some((d) => d.predecessorId === beginNode.id)
    ) {
      return false;
    }

    // A summary task can only have FS and SS dependencies.
    if (this.checkIsLeadTask(endNode.id) && endNode.type === 'finish') {
      return false;
    }

    // The 'start' node of a task cannot affect the node that affects the 'finish' node of the task.
    if (
      beginNode.type === 'start' &&
      this.checkIfPathExists(endNode, { id: beginNode.id, type: 'finish' })
    ) {
      return false;
    }

    // There is no path from 'endNode' to 'beginNode'.
    if (this.checkIfPathExists(endNode, beginNode)) {
      return false;
    }

    // Within the same hierarchy, a summary/child relationship can only exist between tasks at the same level.
    const allSummaryTasks = this.getAllSummaryTasks(beginNode.id);
    const allChildrenTasks = this.getAllChildTasks(beginNode.id);
    if (
      [...allSummaryTasks, ...allChildrenTasks].some((t) => t.id === endNode.id)
    ) {
      return false;
    }

    // Path is allowed if all checks passed.
    return true;
  }

  /**
   * Determines if a task is allowed to decrease its level considering existing dependencies.
   *
   * @param taskId - The ID of the task to check.
   * @returns A boolean indicating whether the task is allowed to decrease its level.
   */
  public checkIfDecreaseAllowed(taskId: string): boolean {
    const task = this.tasks.find((t) => t.id === taskId);
    const newLeadTask = this.tasks.find(
      (t) =>
        t.indent === task.indent &&
        t.number === task.number - 1 &&
        t.leadTaskId === task.leadTaskId,
    );
    if (!newLeadTask) return false;

    // New summary task can only have FS and SS dependencies
    if (
      newLeadTask.dependencies.some(
        (d) =>
          d.type === ProjectTaskDependencyType.finishToFinish ||
          d.type === ProjectTaskDependencyType.startToFinish,
      )
    ) {
      return false;
    }

    const taskStartNode: GraphNode = { id: task.id, type: 'start' };
    const taskEndNode: GraphNode = { id: task.id, type: 'finish' };
    const newLeadTaskStartNode: GraphNode = {
      id: newLeadTask.id,
      type: 'start',
    };
    const newLeadTaskEndNode: GraphNode = {
      id: newLeadTask.id,
      type: 'finish',
    };

    // Check if the new lead task has dependencies on any task in the task hierarchy
    if (this.checkIfPathExists(taskStartNode, newLeadTaskStartNode)) {
      return false;
    }

    // Check if any task in the task hierarchy depends on the end of new lead task
    if (this.checkIfPathExists(newLeadTaskEndNode, taskEndNode)) {
      return false;
    }

    return true;
  }

  /**
   * Retrieves the critical path elements, including task IDs and their dependencies.
   *
   * @returns An object containing two arrays:
   * - taskIds: An array of task IDs that are part of the critical path.
   * - dependencies: An array of objects, each containing a taskId and its corresponding dependency.
   */
  public getCriticalPathElements(): {
    taskIds: string[];
    dependencies: {
      taskId: string;
      dependency: ProjectTaskDependency;
    }[];
  } {
    return {
      taskIds: this.criticalTaskIds,
      dependencies: this.criticalDependencies,
    };
  }

  /**
   * Checks if a path exists between two nodes in the graph.
   *
   * @param beginNode - Beginning node.
   * @param endNode - Ending node.
   * @returns True if a path exists, false otherwise.
   */
  private checkIfPathExists(beginNode: GraphNode, endNode: GraphNode): boolean {
    if (beginNode.id === endNode.id) return true;

    // Prevent dependencies with main task
    if ([beginNode, endNode].some((n) => n.id === this.mainTaskId)) {
      return true;
    }

    const beginNodeKey = this.getGraphKey(beginNode);
    const endNodeKey = this.getGraphKey(endNode);

    let pathExist = false;
    // Map is used to mark the current node as visited
    const visited = new Set();
    const stack = [];

    // Push the current node to the stack which stores the result
    stack.push(beginNodeKey);

    // Traverse through the nodes until the stack is empty
    while (stack.length > 0 && !pathExist) {
      const nodeKey = stack.pop();

      // Mark the task as visited
      visited.add(nodeKey);

      // For take into account task duration preserving, needs to check start node dependencies.
      const nodeInfo = this.graph.get(nodeKey);
      let checkingDependencies = nodeInfo.dependencies;
      if (nodeInfo.nodeType === 'finish') {
        const startNode: GraphNode = {
          id: nodeInfo.task.id,
          type: 'start',
        };
        const taskSecondNodeInfo = this.graph.get(this.getGraphKey(startNode));
        if (!visited.has(this.getGraphKey(startNode))) {
          checkingDependencies = [
            ...checkingDependencies,
            ...taskSecondNodeInfo.dependencies.filter(
              (d) => !d.sticky && d.node.id !== nodeInfo.task.id,
            ),
          ];
        }
      }

      // Recur for all the nodes adjacent (by dependency) to this tasks
      checkingDependencies.forEach((dep) => {
        // Break the cycle if circularity already found
        if (pathExist) {
          return;
        }
        // Path exist check
        if (this.getGraphKey(dep.node) === endNodeKey) {
          pathExist = true;
          return;
        }
        // If the node are not visited
        if (!visited.has(this.getGraphKey(dep.node))) {
          // Push the current node to the stack which stores the result
          stack.push(this.getGraphKey(dep.node));
        }
      });
    }

    return pathExist;
  }

  /** Initializes the graph structure for the timeline. */
  private initGraph(): void {
    // Create a Map to store tasks for O(1) lookup
    const taskMap = new Map(this.tasks.map((task) => [task.id, task]));

    // Create a Map to store child tasks for each parent
    const childrenMap = new Map<string, ProjectTask[]>();

    // Create a Map to store dependent tasks for each task
    const dependentTasksMap = new Map<string, ProjectTask[]>();

    this.tasks.forEach((task) => {
      if (task.leadTaskId) {
        if (!childrenMap.has(task.leadTaskId)) {
          childrenMap.set(task.leadTaskId, []);
        }
        childrenMap.get(task.leadTaskId).push(task);
      }

      task.dependencies?.forEach((dep) => {
        if (!dependentTasksMap.has(dep.predecessorId)) {
          dependentTasksMap.set(dep.predecessorId, []);
        }
        dependentTasksMap.get(dep.predecessorId).push(task);
      });
    });

    this.tasks.forEach((task) => {
      const startNode: GraphNode = { id: task.id, type: 'start' };
      const finishNode: GraphNode = { id: task.id, type: 'finish' };

      const startNodeDeps: GraphDependency[] = [];
      const finishNodeDeps: GraphDependency[] = [];

      const childrenTasks = childrenMap.get(task.id) || [];

      childrenTasks.forEach((childTask) => {
        const dep = {
          node: {
            id: childTask.id,
            type: 'start' as GraphNodeType,
          },
          min: 0,
          sticky: true,
          task: childTask,
        };
        startNodeDeps.push(dep);
      });

      if (task.leadTaskId) {
        const leadTask = taskMap.get(task.leadTaskId);
        const dep = {
          node: {
            id: task.leadTaskId,
            type: 'finish' as GraphNodeType,
          },
          min: 0,
          sticky: true,
          task: leadTask,
        };
        finishNodeDeps.push(dep);
      }

      startNodeDeps.unshift({
        node: {
          id: task.id,
          type: 'finish' as GraphNodeType,
        },
        min: 0,
        task,
      });

      const dependentTasks = dependentTasksMap.get(task.id) || [];

      dependentTasks.forEach((dependentTask) => {
        dependentTask.dependencies.forEach((dep) => {
          if (dep.predecessorId !== task.id) return;
          switch (dep.type) {
            case ProjectTaskDependencyType.finishToStart:
            case ProjectTaskDependencyType.startToStart:
            case ProjectTaskDependencyType.finishToFinish:
            case ProjectTaskDependencyType.startToFinish: {
              const graphDep = {
                node: {
                  id: dependentTask.id,
                  type:
                    dep.type === ProjectTaskDependencyType.finishToStart ||
                    dep.type === ProjectTaskDependencyType.startToStart
                      ? 'start'
                      : ('finish' as GraphNodeType),
                },
                min:
                  dep.type === ProjectTaskDependencyType.finishToStart ? 1 : 0,
                task: dependentTask,
              };
              (dep.type === ProjectTaskDependencyType.finishToStart ||
              dep.type === ProjectTaskDependencyType.finishToFinish
                ? finishNodeDeps
                : startNodeDeps
              ).push(graphDep);
              break;
            }
          }
        });
      });

      const startNodeInfo = {
        task,
        nodeType: 'start' as GraphNodeType,
        dependencies: startNodeDeps,
      };
      const finishNodeInfo = {
        task,
        nodeType: 'finish' as GraphNodeType,
        dependencies: finishNodeDeps,
      };

      this.graph.set(this.getGraphKey(startNode), startNodeInfo);
      this.graph.set(this.getGraphKey(finishNode), finishNodeInfo);
    });
  }

  /**
   * Checks if a task is a lead task.
   *
   * @param taskId - The ID of the task to check.
   * @returns True if the task is a lead task, false otherwise.
   */
  private checkIsLeadTask(taskId: string): boolean {
    return this.tasks.some((t) => t.leadTaskId === taskId);
  }

  /**
   * Generates a unique key for a given GraphNode.
   *
   * This method combines the type and id of a GraphNode to create a unique string identifier.
   *
   * @param node - The GraphNode for which to generate the key.
   * @returns A string representing the unique key for the given GraphNode.
   */
  private getGraphKey(node: GraphNode): string {
    return `${node.type}-${node.id}`;
  }

  /**
   * Gets the corresponding date key for a given node type.
   *
   * @param type - The type of the graph node ('start' or 'finish').
   * @returns The corresponding date key ('startDate' for 'start' nodes, 'endDate' for 'finish' nodes).
   */
  private getNodeCorrespondingDateKey(type: GraphNodeType): string {
    return type === 'finish' ? 'endDate' : 'startDate';
  }

  /** Initializes the predecessor graph for the timeline. */
  private initPredecessorGraph(): void {
    const getTask = (id: string) => this.tasks.find((t) => t.id === id);
    this.tasks.forEach((task) => {
      const startNode: GraphNode = { id: task.id, type: 'start' };
      const finishNode: GraphNode = { id: task.id, type: 'finish' };

      const startNodeDeps: GraphDependency[] = [];

      //SS dependency from lead task
      if (task.leadTaskId) {
        const predecessorTask = getTask(task.leadTaskId);
        const dep = {
          node: {
            id: task.leadTaskId,
            type: 'start' as GraphNodeType,
          },
          min: 0,
          sticky: true,
          task: predecessorTask,
        };
        startNodeDeps.push(dep);
      }

      //FF dependencies from children tasks
      const finishNodeDeps: GraphDependency[] = this.tasks
        .filter((t) => t.leadTaskId === task.id)
        .map((t) => {
          const dep = {
            node: {
              id: t.id,
              type: 'finish' as GraphNodeType,
            },
            min: 0,
            sticky: true,
            task: t,
          };
          return dep;
        });

      //FS dependency from self start date
      finishNodeDeps.unshift({
        node: {
          id: task.id,
          type: 'start' as GraphNodeType,
        },
        min: 0,
        task,
      });

      task.dependencies?.forEach((dep) => {
        switch (dep.type) {
          case ProjectTaskDependencyType.finishToStart: {
            const predecessorTask = getTask(dep.predecessorId);
            const graphDep = {
              node: {
                id: dep.predecessorId,
                type: 'finish' as GraphNodeType,
              },
              min: 1,
              task: predecessorTask,
            };
            startNodeDeps.push(graphDep);
            break;
          }
          case ProjectTaskDependencyType.startToStart: {
            const predecessorTask = getTask(dep.predecessorId);
            const graphDep = {
              node: {
                id: dep.predecessorId,
                type: 'start' as GraphNodeType,
              },
              min: 0,
              task: predecessorTask,
            };
            startNodeDeps.push(graphDep);
            break;
          }

          case ProjectTaskDependencyType.finishToFinish: {
            const predecessorTask = getTask(dep.predecessorId);
            const graphDep = {
              node: {
                id: dep.predecessorId,
                type: 'finish' as GraphNodeType,
              },
              min: 0,
              task: predecessorTask,
            };
            finishNodeDeps.push(graphDep);
            break;
          }
          case ProjectTaskDependencyType.startToFinish: {
            const predecessorTask = getTask(dep.predecessorId);
            const graphDep = {
              node: {
                id: dep.predecessorId,
                type: 'start' as GraphNodeType,
              },
              min: 0,
              task: predecessorTask,
            };
            finishNodeDeps.push(graphDep);
            break;
          }
        }
      });

      const startNodeInfo = {
        task,
        nodeType: 'start' as GraphNodeType,
        dependencies: startNodeDeps,
      };
      const finishNodeInfo = {
        task,
        nodeType: 'finish' as GraphNodeType,
        dependencies: finishNodeDeps,
      };

      this.predecessorGraph.set(this.getGraphKey(startNode), startNodeInfo);
      this.predecessorGraph.set(this.getGraphKey(finishNode), finishNodeInfo);
    });
  }

  /**
   * Returns all child task chain in the flat array.
   *
   * @param leadTaskId - id of the task for which searches child tasks
   * @returns all child tasks
   */
  private getAllChildTasks(leadTaskId: string): ProjectTask[] {
    if (!this.checkIsLeadTask(leadTaskId)) {
      return [];
    }

    const findFirstLevelChildren = (taskId: string) =>
      this.tasks.filter((t) => t.leadTaskId === taskId);

    return findFirstLevelChildren(leadTaskId).flatMap((childTask) => [
      childTask,
      ...this.getAllChildTasks(childTask.id),
    ]);
  }

  /**
   * Returns all summary task chain in the flat array.
   *
   * @param taskId - id of the task for which searches summary tasks.
   * @param targetTasks - project tasks array for search (default uses array from data service).
   * @param excludeMain - determines whether to exclude main task from resulting tasks.
   * @returns all parent tasks.
   */
  private getAllSummaryTasks(
    taskId: string,
    excludeMain = false,
  ): ProjectTask[] {
    const task = this.tasks.find((t) => t.id === taskId);
    if (!task.leadTaskId) {
      return [];
    }

    const findFirstLevelSummaryTask = (childTask: ProjectTask) => [
      this.tasks.find((t) => t.id === childTask.leadTaskId),
    ];

    let summaryTasks = findFirstLevelSummaryTask(task).flatMap(
      (summaryTask) => [
        summaryTask,
        ...this.getAllSummaryTasks(summaryTask.id),
      ],
    );
    if (excludeMain) {
      summaryTasks = summaryTasks.filter((st) => !!st.leadTaskId);
    }
    return summaryTasks;
  }

  /** Initializes the critical path in the graph.  */
  private initCriticalPathTasksAndDependencies(): void {
    // Define the starting node for the traversal
    const beginNode: GraphNode = { id: this.mainTaskId, type: 'finish' };
    const beginNodeKey = this.getGraphKey(beginNode);

    // Initialize data structures for the traversal
    const visited = new Set(); // Keeps track of visited nodes
    const stack = []; // Stack for depth-first search
    const criticalDependencies: {
      taskId: string;
      dependency: ProjectTaskDependency;
    }[] = []; // Stores critical dependencies
    const criticalTaskIds = new Set<string>(); // Stores IDs of critical tasks

    // Start the traversal from the beginning node
    stack.push(beginNodeKey);
    while (stack.length > 0) {
      const nodeKey = stack.pop();
      // Retrieve information about the current node and its dependencies
      const nodeInfo = this.graph.get(nodeKey);
      const finishNodeKey = this.getGraphKey({
        id: nodeInfo.task.id,
        type: 'finish',
      });
      if (
        nodeInfo.nodeType === 'start' &&
        !visited.has(finishNodeKey) &&
        !this.checkIsLeadTask(nodeInfo.task.id) // Summary task has not SF and FF dependencies
      ) {
        stack.push(finishNodeKey);
        continue;
      }

      visited.add(nodeKey);

      const nodeInfoDate = DateTime.fromISO(
        nodeInfo.task[this.getNodeCorrespondingDateKey(nodeInfo.nodeType)],
      );
      const dependentNodeInfo = this.predecessorGraph.get(nodeKey);

      const criticalDeps = dependentNodeInfo.dependencies.filter((dep) => {
        if (dep.task.id === nodeInfo.task.id) {
          return true;
        }
        const depNodeDate = DateTime.fromISO(
          dep.task[this.getNodeCorrespondingDateKey(dep.node.type)],
        );
        const depNodeDateNotWorking = this.scheduleService.getClosestWorkDay(
          depNodeDate.plus({ days: dep.min }),
          'right',
        );
        return depNodeDateNotWorking.equals(nodeInfoDate);
      });

      // Skip if no critical dependency is found
      if (!criticalDeps.length) continue;

      criticalDeps.forEach((criticalDependency) => {
        // Process the next node in the critical path
        const nextNodeInfo = this.graph.get(
          this.getGraphKey(criticalDependency.node),
        );
        if (nextNodeInfo.task.id === dependentNodeInfo.task.id) {
          criticalTaskIds.add(nextNodeInfo.task.id);
          // All of the summary task for critical task are critical too
          this.getAllSummaryTasks(nextNodeInfo.task.id)
            .map((t) => t.id)
            .forEach((id) => criticalTaskIds.add(id));
        } else if (!criticalDependency.sticky) {
          // Determine the type of dependency based on node types
          if (nodeInfo.nodeType === 'start') {
            if (nextNodeInfo.nodeType === 'finish') {
              criticalDependencies.push({
                taskId: dependentNodeInfo.task.id,
                dependency: {
                  predecessorId: nextNodeInfo.task.id,
                  type: ProjectTaskDependencyType.finishToStart,
                },
              });
            } else {
              criticalDependencies.push({
                taskId: dependentNodeInfo.task.id,
                dependency: {
                  predecessorId: nextNodeInfo.task.id,
                  type: ProjectTaskDependencyType.startToStart,
                },
              });
            }
          } else {
            if (nextNodeInfo.nodeType === 'finish') {
              criticalDependencies.push({
                taskId: dependentNodeInfo.task.id,
                dependency: {
                  predecessorId: nextNodeInfo.task.id,
                  type: ProjectTaskDependencyType.finishToFinish,
                },
              });
            } else {
              criticalDependencies.push({
                taskId: dependentNodeInfo.task.id,
                dependency: {
                  predecessorId: nextNodeInfo.task.id,
                  type: ProjectTaskDependencyType.startToFinish,
                },
              });
            }
          }
        }

        if (!visited.has(this.getGraphKey(criticalDependency.node))) {
          // Add the next node to the stack for processing
          stack.push(
            this.getGraphKey({
              id: nextNodeInfo.task.id,
              type: nextNodeInfo.nodeType,
            }),
          );
        }
      });
    }

    // Store the results of the traversal
    this.criticalDependencies = criticalDependencies;
    criticalTaskIds.delete(this.mainTaskId);
    this.criticalTaskIds = [...criticalTaskIds];
  }
}

export interface GraphNode {
  id: string;
  type: GraphNodeType;
}

export interface GraphNodeInfo {
  earliestDate?: DateTime; // Earliest possible date
  nodeType: GraphNodeType;
  task: ProjectTask;
  dependencies: GraphDependency[];
}

export interface GraphDependency {
  node: GraphNode;
  min: number; // Determines min dependency offset (1 just for FS dependencies)
  sticky?: boolean; // Determines sticky dependency between boundary children and lead task. It means that parent task must fit to their children tasks.
  task: ProjectTask;
}

export type GraphNodeType = 'start' | 'finish';
