import {
  AfterViewInit,
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Optional,
  Output,
  QueryList,
  signal,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { filter } from 'rxjs';
import { ContactCardService } from 'src/app/contacts/card/contact-card.service';
import { AppService } from 'src/app/core/app.service';
import { NotificationService } from 'src/app/core/notification.service';
import { DealCardService } from 'src/app/deals/card/deal-card.service';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';
import {
  Interaction,
  InteractionType,
} from 'src/app/shared/modules/interactions/models/interaction.model';
import { InteractionsService } from 'src/app/shared/modules/interactions/interactions.service';

@Component({
  selector: 'tmt-interaction-form',
  templateUrl: './interaction-form.component.html',
  styleUrl: './interaction-form.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class InteractionFormComponent implements OnChanges, AfterViewInit {
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;
  @ViewChild('notPlanned', { static: false })
  private notPlanned: ElementRef;

  @Input() public interaction: Interaction;

  @Output() onCancel = new EventEmitter<void>();

  public isSaving = signal<boolean>(false);
  public interactionForm = this.fb.group({
    date: [null, [Validators.required]],
    isPlanned: [null, [Validators.required]],
    type: [null, [Validators.required]],
    performer: [null, [Validators.required]],
    deal: null,
    organization: null,
    interactionContacts: [[]],
    description: [null, [Validators.required]],
  });

  constructor(
    public readonly interactionsService: InteractionsService,
    public readonly appService: AppService,
    private readonly notificationService: NotificationService,
    private readonly fb: UntypedFormBuilder,
    private readonly destroyRef: DestroyRef,
    @Optional() private readonly contactCardService: ContactCardService,
    @Optional()
    private readonly dealCardService: DealCardService,
    @Optional()
    private readonly clientCardService: ClientCardService,
  ) {
    this.interactionsService.interactionTypes$
      .pipe(
        filter((types: InteractionType[]) => !!types.length),
        takeUntilDestroyed(),
      )
      .subscribe(() => {
        this.initForm();
      });
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['interaction']) {
      this.interactionForm.patchValue(this.interaction, { emitEvent: false });

      this.interactionForm
        .get('isPlanned')
        .patchValue(this.interaction.isPlanned ? 'true' : 'false', {
          emitEvent: false,
        });

      if (this.interaction.interactionContacts?.length) {
        this.interactionForm.get('interactionContacts').patchValue(
          this.interaction.interactionContacts.map((interactionContact) => ({
            id: interactionContact.contact.id,
            name: interactionContact.contact.name,
          })),
          {
            emitEvent: false,
          },
        );
      }
    }
  }

  public ngAfterViewInit(): void {
    FormHelper.cascadeDependency(
      this.interactionForm,
      this.cascadeControls,
      [
        [
          {
            controlName: 'organization',
          },
          {
            controlName: 'deal',
            dependedProperty: 'organizationId',
          },
        ],
        [
          {
            controlName: 'organization',
            isDisableDepended: true,
          },
          {
            controlName: 'interactionContacts',
            dependedProperty: 'organizationId',
          },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );
  }

  /** Clears and cancel form. */
  public cancel(): void {
    this.onCancel.emit();
    this.resetForm();
    this.interaction = null;
  }

  /** Saves interaction. */
  public save(): void {
    this.interactionForm.markAllAsTouched();
    if (!this.interactionForm.valid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving.set(true);

    const interactionFormValue = this.interactionForm.value;
    interactionFormValue.isPlanned === 'true'
      ? (interactionFormValue.isPlanned = true)
      : (interactionFormValue.isPlanned = false);

    if (!this.interaction?.id) {
      this.interactionsService
        .saveInteraction(interactionFormValue)
        .subscribe((isSaved) => {
          this.isSaving.set(false);
          if (isSaved) {
            this.resetForm();
            this.initForm();
          }
        });
    } else {
      this.interactionsService
        .editInteraction(interactionFormValue, this.interaction.id)
        .subscribe((isEdited) => {
          this.isSaving.set(false);
          if (isEdited) {
            this.cancel();
          }
        });
    }
  }

  /** Resets form. */
  private resetForm(): void {
    this.interactionForm.markAsPristine();
    this.interactionForm.markAsUntouched();
    this.interactionForm.reset(null, { emitEvent: false });
  }

  /** Initializes form with default values. */
  private initForm(): void {
    this.interactionForm
      .get('type')
      .setValue(this.interactionsService.defaultInteractionType, {
        emitEvent: false,
      });
    this.interactionForm.get('date').setValue(new Date().toISOString(), {
      emitEvent: false,
    });
    this.interactionForm.get('isPlanned').setValue('false', {
      emitEvent: false,
    });
    if (this.notPlanned) {
      this.notPlanned.nativeElement.click();
    }
    this.interactionForm
      .get('performer')
      .setValue(this.appService.session.user, {
        emitEvent: false,
      });
    if (this.contactCardService) {
      this.interactionForm
        .get('organization')
        .setValue(this.contactCardService.contact?.organization);
      this.interactionForm
        .get('interactionContacts')
        .setValue([this.contactCardService.contact], {
          emitEvent: false,
        });
    } else if (this.dealCardService) {
      this.interactionForm
        .get('organization')
        .setValue(this.dealCardService.deal?.organization);
      this.interactionForm.get('deal').setValue(this.dealCardService.deal, {
        emitEvent: false,
      });
    } else if (this.clientCardService) {
      this.interactionForm
        .get('organization')
        .setValue(this.clientCardService.currentClient);
    }
  }
}
