import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { Subject } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { MessageService } from 'src/app/core/message.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { Exception } from 'src/app/shared/models/exception';
import { UserCardService } from '../user-card.service';

@Component({
  selector: 'wp-user-notifications',
  templateUrl: './user-notifications.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class UserNotificationsComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public readonly: boolean;
  public loading: boolean;
  public form: UntypedFormGroup;

  private _isSaving: boolean;
  public get isSaving() {
    return this._isSaving;
  }
  public set isSaving(value: boolean) {
    this._isSaving = value;

    if (value) {
      this.blockUIService.start();
      this.actionPanelService.action('save').start();
    } else {
      this.actionPanelService.action('save').stop();
      this.blockUIService.stop();
    }
  }

  private destroyed$ = new Subject<void>();

  constructor(
    private notificationService: NotificationService,
    private blockUIService: BlockUIService,
    private dataService: DataService,
    private actionPanelService: ActionPanelService,
    private service: UserCardService,
    private messageService: MessageService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.actionPanelService.run$
      .pipe(
        filter((x) => x.name === 'save'),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        this.save();
      });

    this.actionPanelService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.reload();
      });

    this.load();
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public load(): void {
    this.loading = true;

    this.dataService
      .collection('UserSettings')
      .entity(this.entityId)
      .get<any>()
      .subscribe({
        next: (data) => {
          this.form.patchValue(data);
          this.form.markAsPristine();
          this.form.markAsUntouched();

          this.readonly = !data.editAllowed;

          if (this.readonly) {
            this.form.disable();
          } else {
            this.form.enable();
          }

          this.actionPanelService.action('save').isShown = !this.readonly;
          this.loading = false;
        },
        error: (error: Exception) => {
          this.loading = false;
          this.notificationService.error(error.message);
        },
      });
  }

  public save(): void {
    this.form.markAllAsTouched();

    if (this.form.valid) {
      this.isSaving = true;

      this.dataService
        .collection('UserSettings')
        .entity(this.entityId)
        .patch(this.form.value)
        .subscribe({
          next: () => {
            this.notificationService.successLocal('shared.messages.saved');
            this.isSaving = false;
            this.form.markAsPristine();
          },
          error: (error: Exception) => {
            this.notificationService.error(error.message);
            this.isSaving = false;
          },
        });
    } else {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
    }
  }

  /**
   * Sets form
   *
   * @param form UntypedFormGroup
   */
  public onForm(form: UntypedFormGroup): void {
    this.form = form;
  }

  private reload(): void {
    this.form.enable();

    if (!this.form.dirty) {
      this.load();
    } else {
      this.messageService.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }
}
