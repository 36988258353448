import {
  Component,
  OnInit,
  Input,
  ViewChild,
  inject,
  DestroyRef,
  Injector,
} from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormArray,
  UntypedFormBuilder,
} from '@angular/forms';
import { ListService } from 'src/app/shared/services/list.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ClientCardService } from '../client-card.service';
import { VIEW_NAME, LIST } from 'src/app/shared/tokens';
import { Exception } from 'src/app/shared/models/exception';
import { Organization } from 'src/app/shared/models/entities/projects/organization.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import _ from 'lodash';
import { ClientTariffService } from './client-tariff.service';
import { ClientTariffsToolbarComponent } from './client-tariffs-toolbar/client-tariffs-toolbar.component';
import { ClientTariffModalComponent } from './client-tariff-modal/client-tariff-modal.component';
import { CLIENT_TARIFF_LIST } from './models/client-tariff.list';
import { ClientTariff } from '../../shared/client-tariff.model';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Component({
  selector: 'tmt-client-tariffs',
  templateUrl: './client-tariffs.component.html',
  providers: [
    { provide: VIEW_NAME, useValue: 'default' },
    { provide: LIST, useValue: CLIENT_TARIFF_LIST },
    ListService,
    GridService,
    ClientTariffService,
    SavingQueueService,
  ],
  standalone: false,
})
export class ClientTariffsComponent implements OnInit {
  @Input() entityId: string;
  public readonly = false;
  public formArray: UntypedFormArray = this.fb.array([]);

  public gridOptions: GridOptions = {
    sorting: false,
    toolbar: ClientTariffsToolbarComponent,
    clientTotals: true,
    resizableColumns: true,
    selectionType: SelectionType.range,
    commands: [
      {
        name: 'add',
        handlerFn: () => this.add(),
        allowedFn: () => !this.readonly,
      },
      {
        name: 'edit',
        handlerFn: (id) =>
          this.edit(
            id,
            this.gridService.selectedGroup$.getValue()?.getRawValue(),
          ),
        allowedFn: (id) => id,
      },
      {
        name: 'delete',
        handlerFn: (id) => this.delete(id),
        allowedFn: (id) => id && !this.readonly,
      },
      { name: 'setUserView', handlerFn: () => this.setUserView() },
    ],
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.edit(formGroup.value.id, formGroup.getRawValue()),
        allowedFn: () => !this.readonly,
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.delete(formGroup.value.id),
        allowedFn: () => !this.readonly,
      },
    ],
    view: this.listService.getGridView(),
  };

  private destroyRef = inject(DestroyRef);
  private organizationId: string;

  constructor(
    private service: ClientCardService,
    private listService: ListService,
    private gridService: GridService,
    private data: DataService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private modal: NgbModal,
    public clientTariffService: ClientTariffService,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    this.service.client$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((client: Organization) => {
        this.readonly = !client.tariffsEditAllowed;
        this.organizationId = client.id;
      });

    this.service.reloadTab$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.clientTariffService.load(this.entityId, this.organizationId),
      );
    this.clientTariffService.load(this.entityId, this.organizationId);

    this.clientTariffService.reloadData$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() =>
        this.clientTariffService.load(this.entityId, this.organizationId),
      );
  }

  private add(): void {
    const modalRef = this.modal.open(ClientTariffModalComponent, {
      injector: this.injector,
    });
    const instance = modalRef.componentInstance as ClientTariffModalComponent;

    instance.entityId = this.entityId;
    instance.organizationId = this.organizationId;

    modalRef.result.then(
      () => {
        this.service.reloadTab();
      },
      () => null,
    );
  }

  private edit(clientTariffId: string, tariff: ClientTariff): void {
    const ref = this.modal.open(ClientTariffModalComponent, {
      injector: this.injector,
    });
    const instance = ref.componentInstance as ClientTariffModalComponent;
    instance.clientTariffId = clientTariffId;
    instance.tariff = tariff;
    instance.readonly = this.readonly;

    ref.result.then(
      () => this.clientTariffService.load(this.entityId, this.organizationId),
      () => null,
    );
  }

  private delete(organizationTariffId: string): void {
    this.data
      .collection('OrganizationTariffs')
      .entity(organizationTariffId)
      .delete()
      .subscribe({
        next: () => {
          this.service.reloadTab();
          this.notification.successLocal(
            'projects.clients.card.tariffs.modal.messages.deleted',
          );
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
        complete: () => {},
      });
  }

  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.clientTariffService.load(this.entityId, this.organizationId);
      },
      () => null,
    );
  }
}
