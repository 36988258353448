import {
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  EventEmitter,
  forwardRef,
  inject,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { DatePeriodType } from 'src/app/shared/models/enums/date-period-type.enum';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { DateService } from 'src/app/core/date.service';
import { isEqual } from 'lodash';

import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { DateTime } from 'luxon';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Контрол выбора периода дат. */
@Component({
  selector: 'wp-date-period-box',
  templateUrl: './date-period-box.component.html',
  styleUrls: ['./date-period-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatePeriodBoxComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class DatePeriodBoxComponent implements OnInit, ControlValueAccessor {
  /** Заменить значения в пустом контроле. */
  @Input() placeholder: string;

  /** True - показывать расширенный набор вычисляемых периодов. */
  @Input() advanced = false;

  @Output() onDisplayTextChanged = new EventEmitter<string>();

  @ViewChild('input') inputEl: ElementRef;

  public disabled = false;

  private _value: NamedEntity = null;
  set value(obj: NamedEntity) {
    this._value = obj;

    this.setDisplayText();
    this.textControl.markAsPristine();
  }
  get value(): NamedEntity {
    return this._value;
  }

  rows: NamedEntity[] = [];
  storedRows: NamedEntity[] = null;
  listOpened = false;
  customPeriodOpened = false;

  selectedRow: NamedEntity;
  textControl = new UntypedFormControl('');
  title: string;
  areaId: string = Guid.generate();

  datesForm = this.fb.group({
    from: [null, Validators.required],
    to: [null, Validators.required],
  });

  propagateChange = (_: DatePeriod) => null;
  propagateTouch = () => null;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private readonly scrollToService: ScrollToService,
    private readonly dateService: DateService,
    private readonly fb: UntypedFormBuilder,
    private readonly translate: TranslateService,
    private readonly element: ElementRef,
    private readonly cdr: ChangeDetectorRef,
  ) {}

  private setDisplayText() {
    let displayText = '';

    if (this.value) {
      displayText =
        this.value.id === DatePeriodType.Custom
          ? this.getDatePeriodTitle()
          : this.value.name;
    }

    this.textControl.setValue(displayText, { emitEvent: false });
  }

  /** Изменить значение в форме. */
  private changeValue(value: NamedEntity) {
    if (!isEqual(this.value, value) || value?.id === DatePeriodType.Custom) {
      this.propagateChange(this.getDatePeriodFromRow(value));
    }

    this.value = value;
    this.updateTitle();
  }

  private updateTitle() {
    this.title = this.value
      ? `${this.value.name} (${this.getDatePeriodTitle()})`
      : '';
    this.onDisplayTextChanged.emit(this.title);
  }

  private getDatePeriodFromRow(value: NamedEntity): DatePeriod {
    let period: DatePeriod = null;

    if (value) {
      period = {
        periodType: <DatePeriodType>value.id,
        from: null,
        to: null,
      };

      if (period.periodType === DatePeriodType.Custom) {
        period.from = this.datesForm.get('from').value;
        period.to = this.datesForm.get('to').value;
      } else {
        const pair = this.dateService.getDatePair(period.periodType);
        period.from = pair.from;
        period.to = pair.to;
      }
    }
    return period;
  }

  writeValue(value: DatePeriod): void {
    if (value) {
      if (value.periodType === DatePeriodType.Custom) {
        this.datesForm.patchValue(value);
      }

      this.value = this.storedRows.find(
        (row: NamedEntity) => row.id === value.periodType,
      );
    } else {
      this.value = null;
      this.datesForm.reset();
    }

    this.updateTitle();
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.cdr.markForCheck();
  }

  onBlur() {
    this.propagateTouch();
  }

  refreshRows() {
    if (this.textControl.dirty && this.textControl.value) {
      const filteringValue = this.textControl.value.toLowerCase().trim();
      this.rows = this.storedRows.filter(
        (row: NamedEntity) =>
          row.name.toLowerCase().indexOf(filteringValue) !== -1,
      );
    } else {
      this.rows = Object.assign([], this.storedRows);
    }
  }

  selectRow(row: any) {
    this.selectedRow = row;
  }

  clickRow(row: NamedEntity) {
    if (row.id === 'Custom') {
      this.customPeriodOpened = true;
      this.closeList();
      this.setDisplayText();
      // this.ref.detectChanges();
      return;
    }

    this.changeValue(row);
    this.closeList();
    // this.ref.detectChanges();
  }

  selectCustomPeriod() {
    if (this.datesForm.valid) {
      const row = this.storedRows.find(
        (r: NamedEntity) => r.id === DatePeriodType.Custom,
      );
      this.changeValue(row);
      this.customPeriodOpened = false;
    }
  }

  getDatePeriodTitle(): string {
    if (!this.value) {
      return '';
    }
    const datePeriod = this.getDatePeriodFromRow(this.value);
    return `${DateTime.fromISO(
      datePeriod.from,
    ).toLocaleString()}-${DateTime.fromISO(datePeriod.to).toLocaleString()}`;
  }

  onKeyDown(event: KeyboardEvent) {
    const escCode = 27;
    const enterCode = 13;
    const downCode = 40;
    const upCode = 38;

    if (event.keyCode === escCode) {
      this.cancel();
    }

    if (event.keyCode === enterCode) {
      // Очистили текст, нажали Enter (но нет активной записи) = убрали выбор.
      if (!this.textControl.value && !this.selectedRow) {
        this.changeValue(null);
        this.cancel();
        return;
      }

      if (this.selectedRow) {
        this.clickRow(this.selectedRow);
      }
    }

    if (event.keyCode === downCode) {
      this.selectNext();
      event.preventDefault();
      event.stopPropagation();
    }

    if (event.keyCode === upCode) {
      this.selectPrevious();
      event.preventDefault();
      event.stopPropagation();
    }
  }

  // Команда выделения следующего элемента.
  selectNext() {
    if (!this.listOpened) {
      this.openList();
    }
    if (this.rows.length === 0) {
      return;
    }

    if (!this.selectedRow) {
      this.selectedRow = this.rows[0];
    } else {
      const index = this.rows.indexOf(this.selectedRow);
      if (index < this.rows.length - 1) {
        this.selectedRow = this.rows[index + 1];
        this.scrollToSelectRow();
      }
    }

    // this.ref.detectChanges();
  }

  // Команда выделения предыдущего элемента
  selectPrevious() {
    if (!this.listOpened) {
      this.openList();
    }
    if (this.rows.length === 0) {
      return;
    }

    if (!this.selectedRow) {
      this.selectedRow = this.rows[0];
    } else {
      const index = this.rows.indexOf(this.selectedRow);
      if (index > 0) {
        this.selectedRow = this.rows[index - 1];
        this.scrollToSelectRow();
      }
    }

    // this.ref.detectChanges();
  }

  // Скролл до выбранной строки.
  scrollToSelectRow() {
    if (this.selectedRow) {
      this.scrollToService.scrollTo(
        this.selectedRow.id,
        this.element.nativeElement,
      );
    }
  }

  onInputClick() {
    if (!this.listOpened) {
      this.openList();
    }
  }

  clear() {
    this.changeValue(null);
    this.closeList();
    this.customPeriodOpened = false;
  }

  closeList() {
    this.listOpened = false;
    this.selectedRow = null;
  }

  openList() {
    if (this.listOpened || this.customPeriodOpened) {
      this.cancel();
      return;
    }

    this.selectedRow = null;
    this.inputEl.nativeElement.select();
    this.propagateTouch();
    this.listOpened = true;
    this.refreshRows();
  }

  cancel() {
    this.closeList();
    this.customPeriodOpened = false;
    this.textControl.markAsPristine();

    if (this.textControl.value && this.value) {
      this.setDisplayText();
    } else {
      this.changeValue(null);
    }
  }

  ngOnInit() {
    const advancedPeriods = [
      DatePeriodType.Last30Days,
      DatePeriodType.Last7Days,
      DatePeriodType.NextMonth,
      DatePeriodType.NextQuarter,
      DatePeriodType.NextWeek,
      DatePeriodType.NextYear,
    ];

    // Заполнить список периодов.
    this.storedRows = [];
    for (const datePeriodType in DatePeriodType) {
      if (
        !this.advanced &&
        advancedPeriods.includes(<DatePeriodType>datePeriodType)
      ) {
        continue;
      }

      this.storedRows.push({
        name: this.translate.instant(`enums.datePeriodType.${datePeriodType}`),
        id: datePeriodType,
      });
    }

    this.textControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        if (!this.listOpened) {
          this.openList();
        }

        this.refreshRows();
      });

    FormHelper.controlDatePair(
      this.datesForm,
      'from',
      'to',
      takeUntilDestroyed(this.destroyRef),
    );
  }
}
