import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  OnInit,
  inject,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { EntityListService } from 'src/app/shared/components/entity-list/entity-list.service';
import { CURRENCY_EXCHANGE_RATES_LIST } from 'src/app/shared/lists/currency-exchange-rates.list';
import { CurrencyExchangeRate } from 'src/app/shared/models/entities/settings/currency-exchange-rate.model';
import { Exception } from 'src/app/shared/models/exception';
import { ListService } from 'src/app/shared/services/list.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { CurrencyService } from '../currency.service';
import { CurrencyExchangeRateSettingComponent } from 'src/app/settings-app/currencies/card/currency-exchange-rates/currency-exchange-rate-setting/currency-exchange-rate-setting.component';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { DefaultFilterService } from 'src/app/settings-app/shared/default-filter/default-filter.service';

@Component({
  selector: 'wp-currency-exchange-rates',
  templateUrl: './currency-exchange-rates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    { provide: VIEW_NAME, useValue: 'currency' },
    { provide: LIST, useValue: CURRENCY_EXCHANGE_RATES_LIST },
    { provide: FilterService, useClass: DefaultFilterService },
    EntityListService,
    ListService,
    GridService,
    SavingQueueService,
  ],
  standalone: false,
})
export class CurrencyExchangeRatesComponent implements OnInit {
  public isLoading = false;
  public isSaving = false;

  private exchangeRatesCollection = this.data.collection(
    'CurrencyExchangeRates',
  );
  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public currencyId,
    public gridService: GridService,
    private listService: EntityListService,
    private data: DataService,
    private notification: NotificationService,
    public service: CurrencyService,
    public autosave: SavingQueueService,
    private modal: NgbModal,
    private actionService: ActionPanelService,
  ) {}

  public ngOnInit(): void {
    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());

    this.addContextFilter();

    if (!this.service.readonly) {
      this.addRowCommands();
    }
  }

  /**
   * Deletes exchange rate.
   * @param id
   */
  public deleteExchangeRate(id: string): void {
    this.exchangeRatesCollection
      .entity(id)
      .delete()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.currencies.card.props.currencyExchangeRates.message.deleted',
          );
          this.listService.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  /**
   * Edits exchange rates.
   * @param exchangeRate
   */
  public editExchangeRate(exchangeRate?: CurrencyExchangeRate): void {
    const ref = this.modal.open(CurrencyExchangeRateSettingComponent, {
      size: 'sm',
    });
    ref.componentInstance.currencyId = this.currencyId;
    if (exchangeRate) {
      ref.componentInstance.exchangeRate = exchangeRate;
    }

    ref.result.then(
      () => this.listService.reload(),
      () => null,
    );
  }

  private addContextFilter(): void {
    this.listService.contextFilter = [
      { currencyId: { eq: { type: 'guid', value: this.currencyId } } },
    ];
  }

  private addRowCommands(): void {
    this.listService.rowCommands = [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.editExchangeRate(formGroup.getRawValue()),
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        handlerFn: (formGroup: UntypedFormGroup) =>
          this.deleteExchangeRate(formGroup.value.id),
      },
    ];
  }

  private reload(): void {
    this.listService.reload();
  }
}
