import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import {
  LifecycleInfo,
  LifecycleInfoTransition,
} from 'src/app/shared/models/entities/lifecycle/lifecycle-info.model';
import { MessageService } from 'src/app/core/message.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'wp-state-indicator',
  templateUrl: './state-indicator.component.html',
  styleUrls: ['./state-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class StateIndicatorComponent implements OnDestroy, OnInit {
  private destroyed$ = new Subject<void>();
  public lifecycle: LifecycleInfo;

  constructor(
    private lifecycleService: LifecycleService,
    private cdr: ChangeDetectorRef,
    private messageService: MessageService,
    private translate: TranslateService,
  ) {}
  ngOnInit(): void {
    this.lifecycleService.lifecycleInfo$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((lc) => {
        this.lifecycle = lc;
        this.cdr.detectChanges();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Check have at least one icon. */
  public checkHasIcon(): boolean {
    return !!this.lifecycle.transitions.find((item) => item.iconClass);
  }

  /** Запустить переход ЖЦ. */
  public performTransition(transition: LifecycleInfoTransition) {
    if (this.lifecycle.workflowIndicatorData) {
      this.messageService
        .confirmLocal(
          this.translate.instant('shared.workflow.workflowCancelConfirmation'),
        )
        .then(
          () => {
            this.lifecycleService.setState(
              transition.nextStateId,
              null,
              transition,
            );
          },
          () => null,
        );
    } else {
      this.lifecycleService.setState(transition.nextStateId, null, transition);
    }
  }
}
