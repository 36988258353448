import {
  Component,
  Output,
  EventEmitter,
  ChangeDetectionStrategy,
  OnInit,
  HostBinding,
  Input,
  HostListener,
  OnChanges,
  ViewChild,
} from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import { HistoryItem, HistoryService } from 'src/app/core/history.service';
import { AppName } from 'src/app/shared/globals/app-name';

/** Панель приложений. */
@Component({
  selector: 'wp-app-menu',
  templateUrl: './app-menu.component.html',
  styleUrls: ['./app-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class AppMenuComponent implements OnInit, OnChanges {
  @HostBinding('id') id = 'moduleTitle';
  @HostBinding('hidden') @Input() isClosed = true;
  @ViewChild('scrollBox') scrollBox;
  @Output() closed = new EventEmitter<boolean>();
  public appName = AppName;
  constructor(
    private app: AppService,
    private history: HistoryService,
  ) {}

  ngOnInit(): void {
    this.history.getHistory().subscribe((value) => {
      this.historyState = value;
    });
  }

  ngOnChanges(): void {
    this.scrollBox?.nativeElement?.scrollTo(0, 0);
  }

  public historyState: HistoryItem[] = [];

  public checkAppAccess(app: AppName): boolean {
    return this.app.checkAppAccess(app);
  }

  @HostListener('window:keyup.escape')
  public close() {
    if (!this.isClosed) {
      this.closed.emit();
    }
  }
}
