import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  WorkflowAction,
  WorkflowTransition,
} from '../../../model/workflow-function.model';
import { WorkflowCardService } from '../../workflow-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-workflow-transition-modal',
  templateUrl: './workflow-transition-modal.component.html',
  providers: [GridService],
  standalone: false,
})
export class WorkflowTransitionModalComponent implements OnInit {
  @Input() actions: WorkflowAction[];
  @Input() transitionFormGroup: WorkflowTransition;
  @Input() functionId: string;

  public languages: NamedEntity[] = [];

  form = this.fb.group({
    id: [Guid.generate()],
    name: ['', Validators.required],
    nextFunctionId: [null],
    conditions: null,
    localStrings: this.fb.array([]),
    moveToStateId: null,
  });

  public get localStrings(): UntypedFormArray {
    return this.form.controls['localStrings'] as UntypedFormArray;
  }

  public get conditions(): UntypedFormControl {
    return this.form.controls['conditions'] as UntypedFormControl;
  }

  public get nextFunctionId(): UntypedFormControl {
    return this.form.controls['nextFunctionId'] as UntypedFormControl;
  }

  private destroyRef = inject(DestroyRef);

  states$ = this.service.getStates().pipe(takeUntilDestroyed(this.destroyRef));

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private service: WorkflowCardService,
  ) {}

  public ok() {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.service.detectChanges();
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.activeModal.close(this.form.getRawValue());
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  getHeader(): string {
    return this.transitionFormGroup.name
      ? this.transitionFormGroup.name
      : 'settings.lifecycles.card.props.transition.createHeader';
  }

  ngOnInit() {
    if (this.transitionFormGroup) {
      this.form.patchValue(this.transitionFormGroup);
    }
  }
}
