import {
  Directive,
  ElementRef,
  Input,
  Renderer2,
  OnDestroy,
  Optional,
  Output,
  EventEmitter,
  OnInit,
  OnChanges,
  SimpleChanges,
  AfterViewInit,
  NgZone,
} from '@angular/core';

import {
  asyncScheduler,
  fromEvent,
  merge,
  Observable,
  Subscription,
  Subject,
  firstValueFrom,
  of,
} from 'rxjs';
import {
  auditTime,
  concatMap,
  filter,
  map,
  takeUntil,
  throttleTime,
} from 'rxjs/operators';

import { ChromeService } from 'src/app/core/chrome.service';
import { LogService } from 'src/app/core/log.service';

import { FreezeTableService } from './freeze-table.service';
import {
  FreezeTableSource,
  FreezeTableStrategy,
} from './freeze-table.interface';

/** Directive for fixation of table elements with scrolling (toolbar, headers, bodies, totals). */
@Directive({
  selector: '[wpFreezeTable]',
  standalone: false,
})
export class FreezeTableDirective
  implements OnChanges, OnInit, AfterViewInit, OnDestroy
{
  @Input() name: string;
  @Input() syncWith: string[];
  @Input() leftWidthStrategy: FreezeTableStrategy = 'byContainer';
  @Input() rightWidthStrategy: FreezeTableStrategy = 'byTable';
  @Input() inContainerMode: boolean;
  @Input() pending$: Observable<boolean> | null;
  @Input() disableHorizontalScroller = false;
  /** Uses with dynamic tables rendering. TODO: use just AfterViewInit initialization after full refactoring. */
  @Input() afterViewInitInitialization = false;

  private _rootContainerId: string;
  @Input() get rootContainerId(): string {
    return this._rootContainerId ?? this.defaultRootContainerId;
  }
  set rootContainerId(value: string) {
    this._rootContainerId = value;
  }

  @Output() scroll = new EventEmitter();

  public verticalShadow: boolean;

  private destroyed$ = new Subject<void>();
  private scrollSubscription: Subscription;

  private tablesCount = 0;
  private defaultRootContainerId = 'main-area';
  private hasHorizontalScroll: boolean;

  // Audit time for events in milliseconds.
  private auditTime = 15;
  private throttle = throttleTime(this.auditTime, asyncScheduler, {
    leading: true,
    trailing: true,
  });

  // Root container for attach of table elements.
  private rootContainer: HTMLElement;

  // Virtual scroll container.
  private scrollerContainer: HTMLElement;
  private scroller: HTMLElement;

  private toolbar: HTMLElement;
  private scrollTableHeaders: NodeListOf<HTMLElement>;
  private scrollTableFooters: NodeListOf<HTMLElement>;
  private scrollTableBodies: NodeListOf<HTMLElement>;

  // Host container.
  private containerElement = this.container.nativeElement as HTMLElement;

  private mutationObserverEnabled = true;
  private mutationObserver = new MutationObserver(() => {
    this.log.debug('Freeze table directive. Mutation observer is triggered.');
    this.redraw();
  });

  private get scrolledTableIndex(): number {
    return this.scrollTableBodies.length === 1 ? 0 : 1;
  }

  constructor(
    private container: ElementRef,
    private renderer: Renderer2,
    private chromeService: ChromeService,
    private log: LogService,
    private zone: NgZone,
    @Optional() private freezeTableService: FreezeTableService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['disableHorizontalScroller']) {
      this.destroyed$.next();
      this.initializeDirective();
    }
  }

  public ngOnInit(): void {
    if (this.afterViewInitInitialization) {
      return;
    }
    this.initializeDirective();
  }

  public ngAfterViewInit(): void {
    if (this.afterViewInitInitialization) {
      this.initializeDirective();
    }
  }

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /** Enable MutationObserver. */
  public enableMutationObserver(): void {
    this.mutationObserver.observe(this.container.nativeElement, {
      childList: true,
      subtree: true,
    });
  }

  /** Disable MutationObserver. */
  public disableMutationObserver(): void {
    this.mutationObserver.disconnect();
  }

  private isNeedFreezeHeader(): boolean {
    const headerRect = this.scrollTableHeaders.item(0).getBoundingClientRect();
    const bodyRect = this.scrollTableBodies.item(0).getBoundingClientRect();
    // eslint-disable-next-line radix
    const bodyPaddingTop = parseInt(
      this.scrollTableBodies.item(0).style.paddingTop,
    );
    const rootRect = this.rootContainer.getBoundingClientRect();

    const top = Math.round(rootRect.top);
    const toolbarRect = this.toolbar?.getBoundingClientRect();

    if (bodyRect.width === 0 || bodyRect.height === 0) {
      return false;
    }

    return (
      Math.round(
        bodyRect.top +
          bodyPaddingTop -
          (headerRect.height + (toolbarRect?.height ?? 0)),
      ) < top
    );
  }

  private isNeedFreezeFooter(): boolean {
    const bodyRect = this.scrollTableBodies.item(0).getBoundingClientRect();
    const footerRect = this.scrollTableFooters.item(0).getBoundingClientRect();
    const rootRect = this.rootContainer.getBoundingClientRect();
    const headerRect = this.scrollTableHeaders.item(0).getBoundingClientRect();
    const scrollerHeight = this.scrollerContainer?.offsetHeight ?? 0;

    // Шапка таблицы проскроленная ниже видимой области.
    if (headerRect.bottom >= rootRect.bottom) {
      return false;
    }

    return (
      Math.round(bodyRect.bottom + footerRect.height + scrollerHeight) >
      Math.round(rootRect.bottom)
    );
  }

  private updatePositions() {
    if (!this.rootContainer) {
      return;
    }

    const container = this.containerElement;
    const containerRect = container.getBoundingClientRect();
    const rootRect = this.rootContainer.getBoundingClientRect();
    const toolbarRect = this.toolbar?.getBoundingClientRect();
    const leftBodyRect: DOMRect = this.scrollTableBodies
      .item(0)
      .getBoundingClientRect();
    const scrollerHeight = this.scrollerContainer?.offsetHeight ?? 0;

    const leftHeaderRect = this.scrollTableHeaders
      .item(0)
      .getBoundingClientRect();
    const leftFooterRect = this.scrollTableFooters
      .item(0)
      .getBoundingClientRect();

    // Ширина левой таблицы. Для таблиц без фиксации по горизонтали (1 левая часть) в компоненте должен быть
    // зафиксирован контейнер (например, грид ограничен по ширине). Для таблиц с фиксацией требуется установка ширина фиксируемой таблицы.
    const leftTable = this.scrollTableBodies.item(0).firstChild as HTMLElement;
    const leftTableWidth = leftTable.getBoundingClientRect().width;
    const leftContainerWidth =
      this.leftWidthStrategy === 'byTable'
        ? Math.min(leftTable.getBoundingClientRect().width, containerRect.width)
        : containerRect.width;

    this.freezeTableService?.leftTableWidth$.next(leftContainerWidth);

    if (leftContainerWidth === 0) {
      return;
    }

    const rightTableWidth =
      this.scrollTableBodies.length === 2
        ? (
            this.scrollTableBodies.item(1).firstChild as HTMLElement
          )?.getBoundingClientRect().width
        : 0;

    // Высота общего фиксируемого заголовка (тулбар + высота заголовка талицы);
    const headerBlockHeight =
      leftHeaderRect.height + (toolbarRect?.height ?? 0);

    // Горизонтальный скролл есть, если ширина двух таблиц больше ширины контейнера (-1 - поскольку в нахлест).
    const hasHorizontalScroll =
      containerRect.width < leftTableWidth + rightTableWidth - 1;

    // Правый контейнер = ширина равна контенту (таблице), если нет скролла (т.е. по ширине не превышает контейнер) или таковое требуется,
    // иначе ширина = ширине контейнера за вычетом ширины левой таблицы, т.е. надо заполнить все пространство контейнера по ширине.
    const rightContainerWidth =
      hasHorizontalScroll || this.rightWidthStrategy === 'byContainer'
        ? containerRect.width - leftTableWidth
        : rightTableWidth;

    const freezeHeader = this.isNeedFreezeHeader();
    const freezeFooter = this.isNeedFreezeFooter();

    const updateToolbar = () => {
      if (!this.toolbar) {
        return;
      }

      this.renderer.setStyle(
        this.toolbar,
        'max-width',
        leftContainerWidth +
          (rightContainerWidth < 0 ? 0 : rightContainerWidth) +
          'px',
      );

      if (freezeHeader) {
        this.renderer.addClass(this.toolbar, 'fixed');

        if (
          leftBodyRect.bottom <= headerBlockHeight + rootRect.top &&
          leftFooterRect
        ) {
          const top = leftBodyRect.bottom - headerBlockHeight;
          this.renderer.setStyle(this.toolbar, 'top', top + 'px');
        } else {
          this.renderer.setStyle(this.toolbar, 'top', rootRect.top + 'px');
        }
      } else {
        this.renderer.removeClass(this.toolbar, 'fixed');
        this.renderer.removeStyle(this.toolbar, 'width');
      }
    };

    const updateHeader = () => {
      for (let fixIndex = 0; fixIndex < this.tablesCount; fixIndex++) {
        if (freezeHeader) {
          this.renderer.addClass(
            this.scrollTableHeaders.item(fixIndex),
            'fixed',
          );

          if (!this.inContainerMode) {
            // Плавно скрываем шапку, если футер уже выше края шапки.
            // Если футер уже выше хедера, то и хедер плавно поднимаем.
            if (
              leftBodyRect.bottom <= headerBlockHeight + rootRect.top &&
              leftFooterRect
            ) {
              const top = leftBodyRect.bottom - headerBlockHeight;
              this.renderer.setStyle(
                this.scrollTableHeaders.item(fixIndex),
                'top',
                top + (toolbarRect?.height ?? 0) + 'px',
              );
            } else {
              this.renderer.setStyle(
                this.scrollTableHeaders.item(fixIndex),
                'top',
                rootRect.top + (toolbarRect?.height ?? 0) + 'px',
              );
            }
          }
        } else {
          this.renderer.removeClass(
            this.scrollTableHeaders.item(fixIndex),
            'fixed',
          );
        }

        this.renderer.setStyle(
          this.scrollTableHeaders.item(fixIndex),
          'width',
          (fixIndex === 0 ? leftContainerWidth : rightContainerWidth) + 'px',
        );
      }
    };

    const updateFooter = () => {
      for (let fixIndex = 0; fixIndex < this.tablesCount; fixIndex++) {
        if (freezeFooter) {
          this.renderer.addClass(
            this.scrollTableFooters.item(fixIndex),
            'fixed',
          );

          if (this.inContainerMode) {
            this.renderer.setStyle(
              this.scrollTableBodies.item(fixIndex),
              'margin-bottom',
              scrollerHeight + leftFooterRect.height + 'px',
            );
            this.renderer.setStyle(
              this.scrollTableFooters.item(fixIndex),
              'bottom',
              scrollerHeight + 'px',
            );
          } else {
            this.renderer.setStyle(
              this.scrollTableBodies.item(fixIndex),
              'margin-bottom',
              leftFooterRect.height + 'px',
            );
            this.renderer.setStyle(
              this.scrollTableFooters.item(fixIndex),
              'top',
              rootRect.bottom - leftFooterRect.height - scrollerHeight + 'px',
            );
          }
        } else {
          this.renderer.removeClass(
            this.scrollTableFooters.item(fixIndex),
            'fixed',
          );
          this.renderer.setStyle(
            this.scrollTableBodies.item(fixIndex),
            'margin-bottom',
            0,
          );
        }

        this.renderer.setStyle(
          this.scrollTableFooters.item(fixIndex),
          'width',
          (fixIndex === 0 ? leftContainerWidth : rightContainerWidth) + 'px',
        );
      }
    };

    const updateBody = () => {
      for (let fixIndex = 0; fixIndex < this.tablesCount; fixIndex++) {
        if (freezeHeader) {
          this.renderer.setStyle(
            this.scrollTableBodies.item(fixIndex),
            'padding-top',
            headerBlockHeight + 'px',
          );
        } else {
          this.renderer.setStyle(
            this.scrollTableBodies.item(fixIndex),
            'padding-top',
            0,
          );
        }

        this.renderer.setStyle(
          this.scrollTableBodies.item(fixIndex),
          'width',
          (fixIndex === 0 ? leftContainerWidth : rightContainerWidth) + 'px',
        );
      }
    };

    const updateScroller = () => {
      if (this.hasHorizontalScroll) {
        // Ширина = ширина контейнера (т.е. ширина рабочей области) за вычетом ширины левой таблицы.
        this.renderer.setStyle(
          this.scroller,
          'width',
          rightTableWidth + leftTableWidth + 1 + 'px',
        );
        if (freezeFooter) {
          this.renderer.addClass(this.scrollerContainer, 'fixed');

          if (!this.inContainerMode) {
            this.renderer.setStyle(
              this.scrollerContainer,
              'top',
              rootRect.bottom - scrollerHeight + 'px',
            );
          }
        } else {
          this.renderer.removeClass(this.scrollerContainer, 'fixed');
          this.renderer.setStyle(this.scrollerContainer, 'margin-bottom', 0);
        }

        this.renderer.setStyle(
          this.scrollerContainer,
          'width',
          containerRect.width + 'px',
        );
      }

      if (hasHorizontalScroll !== this.hasHorizontalScroll) {
        this.hasHorizontalScroll = hasHorizontalScroll;
        if (hasHorizontalScroll) {
          this.renderer.setStyle(this.scrollerContainer, 'display', 'block');
        } else {
          this.renderer.setStyle(this.scrollerContainer, 'display', 'none');
        }
        updateScroller();
        this.updatePositions();
      }
    };

    updateToolbar();
    updateHeader();
    updateFooter();
    updateBody();

    if (!this.disableHorizontalScroller) {
      updateScroller();
    }
  }

  private createContainers(): void {
    if (!this.disableHorizontalScroller) {
      this.scroller?.remove();
      this.scrollerContainer?.remove();

      this.scroller = this.renderer.createElement('div');
      this.scrollerContainer = this.renderer.createElement('div');
      this.renderer.setAttribute(
        this.scrollerContainer,
        'name',
        'scroller-container',
      );
      this.renderer.appendChild(
        this.container.nativeElement,
        this.scrollerContainer,
      );
      this.renderer.appendChild(this.scrollerContainer, this.scroller);
    }
  }

  private onHorizontalScrolled(left: number) {
    if (!this.scrollTableBodies.item(1)) {
      return;
    }
    // Добавить тень к левой таблице.
    if (left > 0) {
      if (!this.verticalShadow) {
        this.verticalShadow = true;
        this.renderer.addClass(this.scrollTableBodies.item(0), 'scrolled');
        this.renderer.addClass(this.scrollTableBodies.item(0), 'wp-shadow');

        const leftHeader = this.scrollTableHeaders.item(0).firstChild;
        if (leftHeader && this.scrolledTableIndex !== 0) {
          this.renderer.addClass(leftHeader, 'wp-shadow');
        }

        const leftFooter = this.scrollTableFooters.item(0).firstChild;
        if (
          leftFooter &&
          this.scrolledTableIndex !== 0 &&
          leftFooter.nodeName !== '#comment'
        ) {
          this.renderer.addClass(leftFooter, 'wp-shadow');
        }
      }
    } else {
      if (this.verticalShadow) {
        this.verticalShadow = false;
        // Убрать тень с левой таблицы.
        this.renderer.removeClass(this.scrollTableBodies.item(0), 'scrolled');
        this.renderer.removeClass(this.scrollTableBodies.item(0), 'wp-shadow');

        const leftHeader = this.scrollTableHeaders.item(0).firstChild;
        if (leftHeader && this.scrolledTableIndex !== 0) {
          this.renderer.removeClass(leftHeader, 'wp-shadow');
        }

        const leftFooter = this.scrollTableFooters.item(0).firstChild;
        if (
          leftFooter &&
          this.scrolledTableIndex !== 0 &&
          leftFooter.nodeName !== '#comment'
        ) {
          this.renderer.removeClass(leftFooter, 'wp-shadow');
        }
      }
    }
  }

  private subscribeToScroll(el: HTMLElement, from: FreezeTableSource) {
    this.scrollSubscription?.unsubscribe();
    this.zone.runOutsideAngular(() => {
      this.scrollSubscription = fromEvent(el, 'scroll')
        .pipe(this.throttle, takeUntil(this.destroyed$))
        .subscribe(() => {
          this.scrollHandler(from);
        });
    });
  }

  // TODO: actualize during refactoring
  private getCurrentScrolledPosition(
    source: FreezeTableSource,
    scrollType: 'scrollLeft' | 'scrollTop' = 'scrollLeft',
  ) {
    switch (source) {
      case 'fromHeader':
        return this.scrollTableHeaders.item(this.scrolledTableIndex)[
          scrollType
        ];
      case 'fromBody':
        return this.scrollTableBodies.item(this.scrolledTableIndex)[scrollType];
      case 'fromFooter':
        return this.scrollTableFooters.item(this.scrolledTableIndex)[
          scrollType
        ];
      case 'fromScroller':
        return this.scrollerContainer[scrollType];
    }
  }

  /**
   * Handles scrolling behavior based on the source and scroll values.
   *
   * @param from The source of the scroll.
   * @param leftScrollValue The value to scroll to horizontally.
   * @param emitEvent Whether to emit the scroll event.
   */
  private scrollHandler(
    from: FreezeTableSource,
    leftScrollValue?: number,
    emitEvent = true,
  ): void {
    const left = leftScrollValue ?? this.getCurrentScrolledPosition(from);

    if (
      from !== 'fromHeader' &&
      left !== this.getCurrentScrolledPosition('fromHeader')
    ) {
      const scrollTop = this.getCurrentScrolledPosition(
        'fromHeader',
        'scrollTop',
      );
      this.scrollTableHeaders
        .item(this.scrolledTableIndex)
        .scrollTo(left, scrollTop);
    }

    if (
      from !== 'fromBody' &&
      left !== this.getCurrentScrolledPosition('fromBody')
    ) {
      const scrollTop = this.getCurrentScrolledPosition(
        'fromBody',
        'scrollTop',
      );
      this.scrollTableBodies
        .item(this.scrolledTableIndex)
        .scrollTo(left, scrollTop);
    }

    if (
      from !== 'fromFooter' &&
      left !== this.getCurrentScrolledPosition('fromFooter')
    ) {
      const scrollTop = this.getCurrentScrolledPosition(
        'fromFooter',
        'scrollTop',
      );
      this.scrollTableFooters
        .item(this.scrolledTableIndex)
        .scrollTo(left, scrollTop);
    }

    if (
      !this.disableHorizontalScroller &&
      from !== 'fromScroller' &&
      left !== this.getCurrentScrolledPosition('fromScroller')
    ) {
      const scrollTop = this.getCurrentScrolledPosition(
        'fromScroller',
        'scrollTop',
      );
      this.scrollerContainer.scrollTo(left, scrollTop);
    }

    this.freezeTableService?.currentScrollPosition$?.next(left);

    // If synchronization with another component occurs, stop the loop.
    this.onHorizontalScrolled(left);
    if (emitEvent) {
      this.scroll.emit();
      this.freezeTableService?.onScrollHorizontal(this.name, left);
    }
  }

  /** Принудительно горизонтально проскролить до позиции. */
  private scrollTo(left: number, behavior: ScrollBehavior = 'smooth') {
    if (this.freezeTableService) {
      this.freezeTableService.currentScrollPosition$.next(left);
    }
    const param: ScrollToOptions = { behavior, left };
    this.scrollerContainer?.scrollTo(param);
    this.scrollTableHeaders.item(this.scrolledTableIndex).scrollTo(param);
    this.scrollTableBodies.item(this.scrolledTableIndex).scrollTo(param);
    this.scrollTableFooters.item(this.scrolledTableIndex).scrollTo(param);
  }

  private redraw() {
    this.updatePositions();
    this.scrollHandler('fromBody', null, false);
  }

  /** Initializes of directive logic. */
  private initializeDirective() {
    if (this.inContainerMode) {
      this.renderer.addClass(this.containerElement, 'in-container');
    }

    this.rootContainer =
      document.querySelector('ngb-modal-window') ??
      document.getElementById(this.rootContainerId);

    if (!this.rootContainer) {
      return;
    }

    this.scrollTableHeaders = (<HTMLElement>(
      this.container.nativeElement
    )).querySelectorAll('[name=scroll-table-header]');

    this.scrollTableFooters = (<HTMLElement>(
      this.container.nativeElement
    )).querySelectorAll('[name=scroll-table-footer]');

    this.scrollTableBodies = (<HTMLElement>(
      this.container.nativeElement
    )).querySelectorAll('[name=scroll-table-body]');
    this.toolbar = (<HTMLElement>this.container.nativeElement).querySelector(
      '[name=toolbar]',
    );

    this.tablesCount = this.scrollTableHeaders.length;
    this.createContainers();

    if (this.defaultRootContainerId !== this.rootContainerId) {
      this.zone.runOutsideAngular(() => {
        this.chromeService.scroll$
          .pipe(takeUntil(this.destroyed$))
          .subscribe(() => {
            this.updatePositions();
            this.freezeTableService?.onScrollVertical(this.name);
            this.scroll.emit();
          });
      });
    }

    this.zone.runOutsideAngular(() => {
      fromEvent(this.rootContainer, 'scroll')
        .pipe(this.throttle, takeUntil(this.destroyed$))
        .subscribe(() => {
          this.updatePositions();
          this.freezeTableService?.onScrollVertical(this.name);
          this.scroll.emit();
        });
    });

    this.chromeService.mainAreaSize$
      .pipe(this.throttle)
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.updatePositions();
        this.scroll.emit();
        this.freezeTableService?.onScrollVertical(this.name);
      });

    this.enableMutationObserver();

    const addStartDragEvent = (el: HTMLElement, name: string) => {
      if (el) {
        // TODO: get rid from subscribe in subscribe?
        this.zone.runOutsideAngular(() => {
          merge(
            fromEvent(el, 'mousedown'),
            fromEvent(el, 'touchstart'),
            fromEvent(el, 'wheel'),
          )
            .pipe(takeUntil(this.destroyed$))
            .subscribe(() => this.subscribeToScroll(el, name as any));
        });
      }
    };

    addStartDragEvent(
      this.scrollTableHeaders.item(this.scrolledTableIndex),
      'fromHeader',
    );

    addStartDragEvent(
      this.scrollTableBodies.item(this.scrolledTableIndex),
      'fromBody',
    );

    addStartDragEvent(
      this.scrollTableFooters.item(this.scrolledTableIndex),
      'fromFooter',
    );

    if (!this.disableHorizontalScroller) {
      addStartDragEvent(this.scrollerContainer, 'fromScroller');
    }

    if (this.freezeTableService) {
      this.freezeTableService.mutationObserver$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((state) => {
          this.mutationObserverEnabled = state;
          if (state) {
            this.enableMutationObserver();
          } else {
            this.disableMutationObserver();
          }
        });

      this.freezeTableService.redraw$
        .pipe(auditTime(0), takeUntil(this.destroyed$))
        .subscribe(() => {
          this.redraw();
        });

      if (this.syncWith) {
        this.freezeTableService.horizontalScroll$
          .pipe(
            filter((v) => v.from !== this.name && this.mutationObserverEnabled),
            takeUntil(this.destroyed$),
          )
          .subscribe((v) => {
            if (v?.value != null) {
              this.scrollSubscription?.unsubscribe();
              this.log.debug(
                `Freeze table directive ${this.name}. Sync from ${v.from}.`,
              );
              this.scrollHandler(null, v.value, false);
            }
          });
      }

      this.freezeTableService.scrollToLeft$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          this.scrollTo(0);
        });

      this.freezeTableService.scrollToPosition$
        .pipe(
          concatMap((position) => {
            this.scrollTo(position, 'auto');
            if (this.pending$) {
              return this.pending$.pipe(
                filter((v) => !v),
                map(() => position),
              );
            } else {
              return of(position);
            }
          }),
          takeUntil(this.destroyed$),
        )
        .subscribe((position: number) => {
          this.updatePositions();
          this.scrollTo(position, 'auto');
        });

      this.freezeTableService.scrollToRight$
        .pipe(takeUntil(this.destroyed$))
        .subscribe(() => {
          const leftTable = this.scrollTableBodies.item(0)
            .firstChild as HTMLElement;
          const leftTableWidth = leftTable.getBoundingClientRect().width;

          const rightTableWidth =
            this.scrollTableBodies.length === 2
              ? (
                  this.scrollTableBodies.item(1).firstChild as HTMLElement
                )?.getBoundingClientRect().width
              : 0;
          this.scrollTo(rightTableWidth + leftTableWidth);
        });

      this.freezeTableService.scrollToSelector$
        .pipe(takeUntil(this.destroyed$))
        .subscribe((options) => {
          const anchorElement: HTMLElement =
            this.container.nativeElement.querySelector(options.selector);

          if (anchorElement) {
            const tableHeader = this.scrollTableHeaders.item(1) as HTMLElement;
            const headerWidth = tableHeader.getBoundingClientRect().width;
            const widthToScrolling = headerWidth / 3;

            this.scrollTo(
              anchorElement.offsetLeft - widthToScrolling,
              options.behavior,
            );

            if (this.pending$) {
              firstValueFrom(
                this.pending$.pipe(
                  filter((v) => !v),
                  takeUntil(this.destroyed$),
                ),
              ).then(() => {
                this.updatePositions();
                this.scrollTo(
                  anchorElement.offsetLeft - widthToScrolling,
                  options.behavior,
                );
              });
            }
          }
        });
    }
  }
}
