import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Guid } from 'src/app/shared/helpers/guid';
import { CurrencyExchangeRate } from 'src/app/shared/models/entities/settings/currency-exchange-rate.model';
import { Exception } from 'src/app/shared/models/exception';

/** Компонент создания/редактирования курса валюты */
@Component({
  selector: 'wp-currency-exchange-rate-setting',
  templateUrl: './currency-exchange-rate-setting.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class CurrencyExchangeRateSettingComponent implements OnInit {
  @Input() currencyId: string;
  @Input() exchangeRate: CurrencyExchangeRate = null;

  public isSaving: boolean;

  public form = this.fb.group({
    id: [Guid.generate()],
    effectiveDate: [null, Validators.required],
    exchangeRate: [null, Validators.required],
  });

  private exchangeRatesCollection = this.data.collection(
    'CurrencyExchangeRates',
  );

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    if (this.exchangeRate) {
      this.form.patchValue(this.exchangeRate);
    }
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ok() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    if (this.exchangeRate) {
      this.editCurrencyExchangeRate();
    } else {
      this.addCurrencyExchangeRate();
    }
  }

  /** Добавление курса валюты */
  private addCurrencyExchangeRate() {
    const data = {
      ...this.form.value,
      isActive: true,
      currencyId: this.currencyId,
    };

    this.exchangeRatesCollection.insert(data).subscribe({
      next: () => {
        this.isSaving = false;
        this.notification.successLocal(
          'settings.currencies.card.props.currencyExchangeRates.setting.creation.created',
        );
        this.activeModal.close();
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isSaving = false;
        this.cdr.detectChanges();
      },
    });
  }

  /** Редактирование курса валюты */
  private editCurrencyExchangeRate() {
    const data = {
      effectiveDate: this.form.value.effectiveDate,
      exchangeRate: this.form.value.exchangeRate,
    };

    this.exchangeRatesCollection
      .entity(this.exchangeRate.id)
      .patch(data)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.notification.successLocal(
            'settings.currencies.card.props.currencyExchangeRates.setting.editing.edited',
          );
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
          this.cdr.detectChanges();
        },
      });
  }

  /** Получить имя модального окна */
  public getHeader(): string {
    return this.exchangeRate
      ? 'settings.currencies.card.props.currencyExchangeRates.setting.editing.header'
      : 'settings.currencies.card.props.currencyExchangeRates.setting.creation.header';
  }

  /** Получить имя кнопки подтверждения */
  public getActionBtnName(): string {
    return this.exchangeRate ? 'shared.actions.save' : 'shared.actions.create';
  }
}
