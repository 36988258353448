import { Component } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'tmt-checkbox-test',
  templateUrl: './checkbox-test.component.html',
  standalone: false,
})
export class CheckboxTestComponent {
  public form = this.fb.group({
    active: [false],
    activeReversed: [false],
  });

  public formInputParams = this.fb.group({
    readonly: [false],
  });

  constructor(private fb: UntypedFormBuilder) {}

  /** Set value to the control. */
  public setValue(): void {
    this.form.patchValue({
      active: true,
      activeReversed: true,
    });
  }
}
