import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
} from '@angular/core';
import { Validators, UntypedFormBuilder, FormGroup } from '@angular/forms';
import { Exception } from 'src/app/shared/models/exception';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';

@Component({
  selector: 'tmt-changing-program-modal',
  templateUrl: './changing-program-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ChangingProgramModalComponent implements OnInit {
  @Input() project: Project;
  public form: FormGroup;
  public isSaving: boolean;
  public query: { [key: string]: any } = null;

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      program: [null, Validators.required],
    });

    if (this.project?.program?.id) {
      this.query = {
        filter: {
          id: {
            ne: { type: 'guid', value: this.project?.program?.id },
          },
        },
      };
    }
  }

  /** Change project's program. */
  public change(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving = true;

    if (this.project.program) {
      this.dataService
        .collection('Programs')
        .entity(this.project.program.id)
        .action('ExcludeProject')
        .execute({ projectId: this.project.id })
        .subscribe({
          next: () => {
            this.includeProject();
          },
          error: (error: Exception) => {
            this.notificationService.error(error.message);
            this.isSaving = false;
            this.cdr.markForCheck();
          },
        });
    } else {
      this.includeProject();
    }
  }

  /** Include project to program. */
  private includeProject(): void {
    this.dataService
      .collection('Programs')
      .entity(this.form.value?.program?.id)
      .action('IncludeProject')
      .execute({ projectId: this.project.id })
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'projects.projects.changingProgram.messages.programWasChanged',
          );
          this.isSaving = false;
          this.activeModal.close();
          this.cdr.markForCheck();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.isSaving = false;
          this.cdr.markForCheck();
        },
      });
  }

  /** Close program changing modal. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
