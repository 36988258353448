import { DecimalPipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { ValueMode } from 'src/app/shared-features/planner/models/value-mode.enum';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';

@Pipe({
  name: 'projectSummaryTotal',
  standalone: false,
})
export class ProjectSummaryTotalPipe implements PipeTransform {
  private readonly decimalTemplate = '1.0-2';

  constructor(
    private currencyPipe: WpCurrencyPipe,
    private decimalPipe: DecimalPipe,
  ) {}

  transform(value: number, mode: ValueMode): string {
    if (value == null || value === 0) return null;

    switch (mode) {
      case ValueMode.Hours:
        return this.decimalPipe.transform(value, this.decimalTemplate);
      case ValueMode.Cost:
        return this.currencyPipe.transform(value, null, null, true);
      default:
        return null;
    }
  }
}
