import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  Type,
} from '@angular/core';
import { DatePipe, DecimalPipe, PercentPipe } from '@angular/common';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { AppService } from 'src/app/core/app.service';
import {
  GridBooleanColumn,
  GridColumn,
  GridColumnType,
  GridCommandColumn,
  GridComponentColumn,
  GridCurrencyColumn,
  GridCurrencyControlColumn,
  GridDateControlColumn,
  GridEntityColumn,
  GridNavigationColumn,
  GridNumberControlColumn,
  GridSelectControlColumn,
  GridStringControlColumn,
  GridUserControlColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { UserCellComponent } from './user-cell/user-cell.component';
import { NavigationCellComponent } from './navigation-cell/navigation-cell.component';
import { BooleanReadonlyCellComponent } from './boolean-cell/boolean-cell.component';
import { WpCurrencyPipe } from 'src/app/shared/pipes/currency.pipe';
import { StateCellComponent } from './state-cell/state-cell.component';
import { CommandCellComponent } from './command-cell/command-cell.component';
import { Subject, isObservable } from 'rxjs';
import { debounceTime, take, takeUntil } from 'rxjs/operators';
import { GridOrchestratorService } from 'src/app/shared-features/grid/core/grid-orchestrator.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { NgxNl2brPipe } from 'ngx-nl2br';
import { TextBoxComponent } from 'src/app/shared/components/controls/text-box/text-box.component';
import { DateBoxComponent } from 'src/app/shared/components/controls/date-box/date-box.component';
import { UserBoxComponent } from 'src/app/shared/components/controls/user-box/user-box.component';
import { SelectBoxComponent } from 'src/app/shared/components/controls/select-box/select-box.component';
import { NumberBoxComponent } from 'src/app/shared/components/controls/number-box/number-box.component';
import { CurrencyBoxComponent } from 'src/app/shared/components/controls/currency-box/currency-box.component';
import { SelectionType } from 'src/app/shared-features/grid/models/grid-options.model';
import _ from 'lodash';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { checkChangesDebounceTime } from 'src/app/shared-features/grid/models/grid-constants';
import { ResourceNavigationCellComponent } from 'src/app/shared-features/grid/grid-cell/resource-navigation-cell/resource-navigation-cell.component';
import { TranslateService } from '@ngx-translate/core';
import { CheckboxComponent } from 'src/app/shared/components/controls/checkbox/checkbox.component';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';

/** Ячейка грида. */
@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-grid-cell]',
  template: `<div
    *ngComponentOutlet="componentCell; inputs: componentCellInputs"
  ></div>`,
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GridCellComponent implements OnInit, OnDestroy {
  @Input() column: GridColumn;
  @Input('group') formGroup: UntypedFormGroup;
  @Input() selectionType: SelectionType;
  @Input() get editing(): boolean {
    return this._editing;
  }
  set editing(value: boolean) {
    if (this._editing !== value) {
      this.renderer.removeClass(this.elRef.nativeElement, 'editing-cell');
      this._editing = value;
      if (this.canCellBeEditable()) {
        this.render();

        // Saves table focus after cell rerendering. Necessary for correct keyboard key listens.
        if (
          this.cellOrchestratorService.options.selectionType ===
            SelectionType.range ||
          value ||
          !this.cellOrchestratorService.leftTable?.matches(':focus-within')
        ) {
          this.elRef.nativeElement.parentElement.focus();
        }
      }
    }
  }

  public componentCell: Type<unknown>;
  public componentCellInputs: Record<string, any> = {};

  private _editing = false;

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  /** Last value of cell control. Turned on with the force cell update option. */
  private lastValue: any = null;

  public get row(): any {
    return this.formGroup.getRawValue();
  }

  public get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  public get value(): any {
    return this.formGroup.get(this.column.name).value;
  }

  public textElement: string;

  constructor(
    private app: AppService,
    private renderer: Renderer2,
    private currencyPipe: WpCurrencyPipe,
    private numberPipe: DecimalPipe,
    private percentPipe: PercentPipe,
    private workPipe: WorkPipe,
    private datePipe: DatePipe,
    private elRef: ElementRef,
    private cdr: ChangeDetectorRef,
    private nl2br: NgxNl2brPipe,
    private cellOrchestratorService: GridOrchestratorService,
    private translateService: TranslateService,
    private phonePipe: PhonePipe,
  ) {}

  public ngOnDestroy(): void {
    this.destroyed$.next();
  }

  public ngOnInit(): void {
    if (!this.canCellBeEditable()) {
      this.formGroup.controls[this.column.name].disable({ emitEvent: false });
    }
    this.render();

    if (this.column.forceCellUpdating) {
      this.addForceCellUpdating();
    }
  }

  /** Determines can cell be editable. */
  private canCellBeEditable(): boolean {
    // New logic means that component type cell must regulate control enabling state by itself
    if (this.column.type === GridColumnType.Component) {
      return true;
    }

    //Not control type cells always are not editable
    if (
      [
        GridColumnType.Boolean,
        GridColumnType.Currency,
        GridColumnType.Date,
        GridColumnType.Decimal,
        GridColumnType.Integer,
        GridColumnType.Work,
        GridColumnType.Percent,
        GridColumnType.Label,
        GridColumnType.Navigation,
        GridColumnType.Entity,
        GridColumnType.String,
        GridColumnType.User,
        GridColumnType.State,
        GridColumnType.ResourceNavigation,
        GridColumnType.Command,
        GridColumnType.DateTime,
        GridColumnType.MultilineString,
        GridColumnType.Url,
        GridColumnType.Phone,
        GridColumnType.Email,
      ].includes(this.column.type)
    ) {
      return false;
    }

    return true;
  }

  /** Renders cell. */
  private render(): void {
    this.renderer.removeClass(this.elRef.nativeElement, 'control-cell');

    if (this.textElement) {
      this.renderer.removeChild(this.elRef.nativeElement, this.textElement);
      this.renderer.removeClass(this.elRef.nativeElement, 'trim');
      this.renderer.removeAttribute(this.elRef.nativeElement, 'title');
    }

    if (this.editing) {
      if (
        this.selectionType === SelectionType.range &&
        this.control.enabled &&
        this.canCellBeEditable()
      ) {
        this.renderer.addClass(this.elRef.nativeElement, 'editing-cell');
      }

      switch (this.column.type) {
        case GridColumnType.Component:
          this.renderComponent();
          return;
        case GridColumnType.DateControl:
          this.renderDateControl();
          return;
        case GridColumnType.SelectControl:
          this.renderSelectControl();
          return;
        case GridColumnType.UserControl:
          this.renderUserControl();
          return;
        case GridColumnType.NumberControl:
          this.renderNumberControl();
          return;
        case GridColumnType.CurrencyControl:
          this.renderCurrencyControl();
          return;
        case GridColumnType.StringControl:
          this.renderStringControl();
          return;
        case GridColumnType.BooleanControl:
          this.renderBooleanControl();
          return;
      }
    }

    if (this.column.type === GridColumnType.User) {
      this.renderUser();
      return;
    }

    this.renderPlainValues();
  }

  /** Renders plain text values in the grid cell. */
  private renderPlainValues(): void {
    this.componentCell = null;
    this.componentCellInputs = {};

    if (this.handleSpecialColumnTypes()) {
      return;
    }

    const text = this.getFormattedText();
    if (text) {
      this.renderText(text);
    }
  }

  /**
   * Handles rendering of special column types.
   *
   * This method checks if the current column type is a special type that requires a specific rendering.
   * If it is, it calls the corresponding rendering method. If not, it returns false.
   *
   * @returns {boolean} True if a special column type was handled, false otherwise.
   */
  private handleSpecialColumnTypes(): boolean {
    const specialTypes = {
      [GridColumnType.Component]: () => this.renderComponent(true),
      [GridColumnType.ResourceNavigation]: () =>
        this.renderResourceNavigation(),
      [GridColumnType.UserControl]: () => this.renderUser(),
      [GridColumnType.Navigation]: () => this.renderNavigation(),
      [GridColumnType.Command]: () => this.renderCommand(),
      [GridColumnType.State]: () => this.renderState(),
      [GridColumnType.Boolean]: () => this.renderBoolean(),
      [GridColumnType.BooleanControl]: () => this.renderBoolean(),
      [GridColumnType.MultilineString]: () => this.renderMultilineString(),
      [GridColumnType.Phone]: () => this.renderLink(this.value, 'phone'),
      [GridColumnType.Email]: () => this.renderLink(this.value, 'email'),
      [GridColumnType.Url]: () => this.renderLink(this.value, 'url'),
    };

    const handler = specialTypes[this.column.type];
    if (handler) {
      handler();
      return true;
    }
    return false;
  }

  /**
   * Formats the cell value based on its type.
   *
   * @returns {string | null} The formatted text or null if the type is not recognized.
   */
  private getFormattedText(): string | null {
    if (this.isStringType()) {
      const extendedType = (this.column as GridStringControlColumn)
        .extendedType;
      if (this.isExtendedStringType(extendedType)) {
        this.renderLink(this.value, extendedType);
        return null;
      } else {
        return this.value;
      }
    }

    if (this.isEntityType()) return this.getEntityText();
    if (this.isSelectControlType()) return this.getSelectControlText();
    if (this.isDateType()) return this.getDateText();
    if (this.isDateTimeType()) return this.getDateTimeText();

    // Below represents number texts with right side alignment.
    this.renderer.addClass(this.elRef.nativeElement, 'text-end');
    if (this.isDecimalPercentType()) {
      return this.getDecimalPercentText();
    } else if (this.isDecimalType()) {
      return this.getDecimalText();
    }
    if (this.isIntegerPercentType()) return this.getPercentText();
    if (this.isIntegerType()) return this.getIntegerText();
    if (this.isWorkType()) return this.getWorkText();
    if (this.isCurrencyType()) return this.getCurrencyText();
    return null;
  }

  /**
   * Renders a text element within the grid cell.
   *
   * @param {string} text The text to be rendered.
   */
  private renderText(text: string): void {
    this.textElement = this.renderer.createText(text);
    this.renderer.appendChild(this.elRef.nativeElement, this.textElement);
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
    this.renderer.setAttribute(this.elRef.nativeElement, 'title', text);
  }

  /**
   * Renders a link element within the grid cell based on the provided value and type.
   *
   * @param {string} value The value to be used for the link.
   * @param {'email' | 'phone' | 'url'} type The type of link to be rendered.
   */
  private renderLink(value: string, type: 'email' | 'phone' | 'url'): void {
    if (!value) return;

    this.removeChildren();

    if (type === 'phone') value = this.phonePipe.transform(value);

    const hrefMap = {
      email: `mailto:${value}`,
      phone: `tel:${this.phonePipe.transform(value)}`,
      url: value,
    };

    const anchor = this.renderer.createElement('a');
    this.renderer.addClass(anchor, 'cell-link');
    if (
      this.cellOrchestratorService.options.selectionType !== SelectionType.range
    ) {
      this.renderer.addClass(anchor, 'cell-link-light');
    }
    this.renderer.setAttribute(anchor, 'href', hrefMap[type]);

    if (type === 'url') {
      this.renderer.setAttribute(anchor, 'target', '_blank');
      value = value.replace(/^https?:\/\//, ''); // Remove the protocol from the value.
    }

    this.renderer.appendChild(anchor, this.renderer.createText(value));
    this.renderer.appendChild(this.elRef.nativeElement, anchor);
  }

  /** Renders number control. */
  private renderNumberControl(): void {
    this.componentCell = NumberBoxComponent;

    const column = this.column as GridNumberControlColumn;
    this.componentCellInputs = {
      control: this.control,
      type: column.controlType,
      min: column.min,
      max: column.max,
      precision: column.precision ?? 2,
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
      propagationMode: column.propagationMode ?? PropagationMode.onInput,
    };
    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders currency control. */
  private renderCurrencyControl(): void {
    this.componentCell = CurrencyBoxComponent;

    const column = this.column as GridCurrencyControlColumn;
    const currencyCode =
      (column.currencyCodeProperty &&
        _.get(this.row, column.currencyCodeProperty)) ||
      column.currencyCode ||
      this.app.session.configuration.baseCurrencyCode;

    this.componentCellInputs = {
      control: this.control,
      min: column.min,
      max: column.max,
      currencyCode,
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
    };
    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }
    if (column.isCurrencyEditable === false) {
      this.componentCellInputs.isCurrencyEditable = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders select control. */
  private renderSelectControl(readonly = false): void {
    this.componentCell = SelectBoxComponent;

    const column = this.column as GridSelectControlColumn;
    this.componentCellInputs = {
      control: this.control,
      values: column.values,
      collection: column.collection,
      query: column.query,
      placeholder: column.placeholder
        ? this.translateService.instant(column.placeholder)
        : '',
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
      isIdMode: column.isIdMode,
      directoryId: column.directoryId,
      readonly,
    };
    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders string control. */
  private renderStringControl(): void {
    this.componentCell = TextBoxComponent;

    const column = this.column as GridStringControlColumn;
    if (column.extendedType) {
      this.removeChildren();
    }

    this.componentCellInputs = {
      control: this.control,
      typeName: column.extendedType ?? 'string',
      placeholder: column.placeholder
        ? this.translateService.instant(column.placeholder)
        : '',
      propagationMode: column.propagationMode ?? PropagationMode.onInput,
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
    };

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders boolean control. */
  private renderBooleanControl(): void {
    this.componentCell = CheckboxComponent;
    this.componentCellInputs = {
      control: this.control,
      autofocus: this.selectionType === SelectionType.range,
      initialValue: this.cellOrchestratorService.initialControlValue,
      reversed: (this.column as GridBooleanColumn).reversed,
    };

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders user control. */
  private renderUserControl(): void {
    this.componentCell = UserBoxComponent;

    const column = this.column as GridUserControlColumn;
    const initialControlValue =
      this.cellOrchestratorService.initialControlValue;
    this.componentCellInputs = {
      control: this.control,
      placeholder: column.placeholder
        ? this.translateService.instant(column.placeholder)
        : '',
      query: column.query,
      initialValue: initialControlValue,
      autofocus:
        this.selectionType === SelectionType.range &&
        initialControlValue === undefined,
    };

    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders date control. */
  private renderDateControl(): void {
    this.componentCell = DateBoxComponent;

    const column = this.column as GridDateControlColumn;
    this.componentCellInputs = {
      control: this.control,
      initialValue: this.cellOrchestratorService.initialControlValue,
      autofocus: this.selectionType === SelectionType.range,
    };
    if (column.allowNull === false) {
      this.componentCellInputs.allowNull = false;
    }

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders component cell. */
  private renderComponent(readonlyVersion?: boolean): void {
    const gridViewComponentColumn = this.column as GridComponentColumn;
    const component =
      readonlyVersion && gridViewComponentColumn.readonlyComponent
        ? gridViewComponentColumn.readonlyComponent
        : gridViewComponentColumn.component;

    this.componentCell = component;
    this.componentCellInputs = {
      formGroup: this.formGroup,
      column: this.column,
      initialValue: this.cellOrchestratorService.initialControlValue,
    };

    this.renderer.addClass(this.elRef.nativeElement, 'control-cell');
  }

  /** Renders user navigation control. */
  private renderResourceNavigation(): void {
    this.componentCell = ResourceNavigationCellComponent;
    this.componentCellInputs = {
      row: this.row,
      column: this.column as GridNavigationColumn,
    };

    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row.name,
    );
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
  }

  /** Renders user cell. */
  private renderUser(): void {
    this.componentCell = UserCellComponent;
    this.componentCellInputs = {
      row: this.row,
      column: this.column as GridNavigationColumn,
    };
  }

  /** Renders navigation cell. */
  private renderNavigation(): void {
    this.componentCell = NavigationCellComponent;
    this.componentCellInputs = {
      row: this.row,
      column: this.column as GridNavigationColumn,
    };

    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row[this.column.name],
    );
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
  }

  /** Renders command cell. */
  private renderCommand(): void {
    this.componentCell = CommandCellComponent;
    this.componentCellInputs = {
      row: this.row,
      column: this.column as GridCommandColumn,
    };

    this.renderer.setAttribute(
      this.elRef.nativeElement,
      'title',
      this.row[this.column.name],
    );
  }

  /** Renders state cell. */
  private renderState(): void {
    this.componentCell = StateCellComponent;
    this.componentCellInputs = {
      value: this.value,
      column: this.column,
    };
  }

  /** Renders boolean cell. */
  private renderBoolean(): void {
    this.componentCell = BooleanReadonlyCellComponent;
    this.componentCellInputs = {
      control: this.control,
      readonly: true,
      reversed: (this.column as GridBooleanColumn).reversed,
    };
  }

  /** Renders multiline string. */
  private renderMultilineString(): void {
    if (!this.value) {
      return;
    }
    const text = this.nl2br.transform(this.value);
    this.renderer.setProperty(this.elRef.nativeElement, 'innerHTML', text);
    this.renderer.addClass(this.elRef.nativeElement, 'trim');
    this.renderer.setAttribute(this.elRef.nativeElement, 'title', this.value);
  }

  /**
   * Adds a subscription to the cell's value changes to force cell updating.
   *
   * This method is responsible for detecting changes in the cell's value and
   * re-rendering the cell if necessary. It debounces the value changes to prevent
   * excessive re-renders. The re-render is skipped if the cell is currently being
   * edited or if it cannot be edited.
   */
  private addForceCellUpdating(): void {
    this.lastValue = this.formGroup.controls[this.column.name].value;
    this.formGroup.controls[this.column.name].valueChanges
      .pipe(debounceTime(checkChangesDebounceTime), takeUntil(this.destroyed$))
      .subscribe((change) => {
        if (!_.isEqual(this.lastValue, change)) {
          this.lastValue = change;
          // Check if the cell is not being edited or is not editable to prevent re-render during editing
          if (
            (this.selectionType === SelectionType.range &&
              this.formGroup.controls[this.column.name] !==
                this.cellOrchestratorService.editingControl) ||
            !this.canCellBeEditable()
          ) {
            this.render();
            this.cdr.detectChanges();
          }
        }
      });
  }

  private isDateType(): boolean {
    return (
      this.column.type === GridColumnType.Date ||
      this.column.type === GridColumnType.DateControl
    );
  }

  private isDateTimeType(): boolean {
    return this.column.type === GridColumnType.DateTime;
  }

  private isDecimalPercentType(): boolean {
    return (
      (this.column.type === GridColumnType.Decimal &&
        this.column.contentType === GridColumnType.Percent) ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'percent')
    );
  }

  private isIntegerPercentType(): boolean {
    return this.column.type === GridColumnType.Percent;
  }

  private isDecimalType(): boolean {
    return (
      this.column.type === GridColumnType.Decimal ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'decimal')
    );
  }

  private isIntegerType(): boolean {
    return (
      this.column.type === GridColumnType.Integer ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'integer')
    );
  }

  private isWorkType(): boolean {
    return (
      this.column.type === GridColumnType.Work ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'work')
    );
  }

  private isStringType(): boolean {
    return (
      this.column.type === GridColumnType.String ||
      this.column.type === GridColumnType.StringControl
    );
  }

  private isExtendedStringType(
    extendedType?: 'url' | 'email' | 'phone',
  ): boolean {
    return extendedType && this.column.type === GridColumnType.StringControl;
  }

  private isEntityType(): boolean {
    return this.column.type === GridColumnType.Entity;
  }

  private isSelectControlType(): boolean {
    return this.column.type === GridColumnType.SelectControl;
  }

  private isCurrencyType(): boolean {
    return (
      this.column.type === GridColumnType.Currency ||
      this.column.type === GridColumnType.CurrencyControl ||
      (this.column.type === GridColumnType.NumberControl &&
        (this.column as GridNumberControlColumn).controlType === 'currency')
    );
  }

  private getDateText(): string {
    return this.datePipe.transform(this.value, 'shortDate');
  }

  private getDateTimeText(): string {
    return this.datePipe.transform(
      this.value,
      'short',
      this.app.session.timeZoneOffset,
    );
  }

  private getCurrencyText(): string {
    if (_.isObject(this.value)) {
      return this.currencyPipe.transform(
        this.value['value'],
        this.value['currencyCode'],
      );
    } else {
      const column = this.column as GridCurrencyColumn;
      const currencyCode =
        (column.currencyCodeProperty &&
          _.get(this.row, column.currencyCodeProperty)) ||
        column.currencyCode ||
        this.app.session.configuration.baseCurrencyCode;
      return this.currencyPipe.transform(this.value, currencyCode);
    }
  }

  private getEntityText(): string {
    if (typeof this.value === 'string') {
      const values = (this.column as GridEntityColumn).values;
      if (isObservable(values)) {
        values
          .pipe(take(1))
          .subscribe(
            (val: NamedEntity[]) => val.find((v) => v.id === this.value).name,
          );
      } else {
        return values.find((v) => v.id === this.value).name;
      }
    } else {
      return this.value?.name;
    }
  }

  /**
   * Returns the text representation of the select control value.
   *
   * If the select control is in ID mode, it renders the select control and returns null.
   * Otherwise, it returns the name of the value if it exists, or an empty string if not.
   *
   * @returns {string | null} The text representation of the select control value or null if in ID mode.
   */
  private getSelectControlText(): string | null {
    if ((this.column as GridSelectControlColumn).isIdMode) {
      this.renderSelectControl(true);
      return null;
    }
    return this.value ? this.value.name : '';
  }

  private getDecimalPercentText(): string {
    const column = this.column as GridNumberControlColumn;
    const precision = column.precision ?? 2;
    return this.percentPipe.transform(this.value, `1.0-${precision}`);
  }

  private getPercentText(): string {
    return this.percentPipe.transform(this.value);
  }

  private getDecimalText(): string {
    const column = this.column as GridNumberControlColumn;
    const precision = column.precision ?? 2;
    return this.numberPipe.transform(this.value, `1.0-${precision}`);
  }

  private getIntegerText(): string {
    return this.numberPipe.transform(this.value, '1.0-0');
  }

  private getWorkText(): string {
    return this.workPipe.transform(this.value);
  }

  private removeChildren(): void {
    const childElements = this.elRef.nativeElement.children;
    for (const child of childElements) {
      this.renderer.removeChild(this.elRef.nativeElement, child);
    }
  }
}
