import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { TimeOffType } from 'src/app/shared/models/entities/settings/time-off-type.model';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  TimeOffTypeUnit,
  TimeOffTypeUnits,
} from 'src/app/shared/models/enums/time-off-type-unit.enum';
import { TranslateService } from '@ngx-translate/core';
import { TimeOffTypeCardService } from './time-off-type-card.service';
import { forkJoin, Observable } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NavigationService } from 'src/app/core/navigation.service';

@Component({
  selector: 'tmt-time-off-type-card',
  templateUrl: './time-off-type-card.component.html',
  providers: [TimeOffTypeCardService],
  standalone: false,
})
export class TimeOffTypeCardComponent implements OnInit {
  @Input() entityId: string;

  public isLoading = false;
  public isSaving = false;

  public readonly: boolean;
  public timeOffTypeUnits: NamedEntity[] = [];
  public timeOffType: TimeOffType;
  public timeOffTypeUnitsEnum = TimeOffTypeUnit;

  timeOffTypeForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    paid: [true, Validators.required],
    code: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    unit: [null, Validators.required],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: false,
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private actionService: ActionPanelService,
    private navigationService: NavigationService,
    private message: MessageService,
    private notification: NotificationService,
    private translate: TranslateService,
    private service: TimeOffTypeCardService,
  ) {
    service.timeOffType$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.timeOffType = value;
      });

    actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());
  }

  // Загрузка данных.
  private load() {
    this.isLoading = true;
    this.timeOffTypeForm.markAsPristine();
    this.timeOffTypeForm.markAsUntouched();

    // Загрузка данных.
    const accrualPolicy = this.data.collection('AccrualPolicies').query({
      expand: {
        excludedTimeOffTypes: { expand: ['timeOffType'] },
      },
    }) as Observable<any[]>;

    const usingPolicy = this.data
      .collection('UsingPolicies')
      .query({}) as Observable<any[]>;

    const timeOffType = this.data
      .collection('TimeOffTypes')
      .entity(this.entityId)
      .get<TimeOffType>({ expand: 'unit' });

    forkJoin({ timeOffType, accrualPolicy, usingPolicy }).subscribe({
      next: (data) => {
        data.timeOffType.accrualPolicy = data.accrualPolicy.find(
          (x) => x.id === this.entityId,
        );
        data.timeOffType.usingPolicy = data.usingPolicy.find(
          (x) => x.id === this.entityId,
        );
        this.service.updateTimeOffType(data.timeOffType);

        this.readonly = !data.timeOffType.editAllowed;
        this.timeOffTypeForm.patchValue(data.timeOffType);

        this.actionService.action('save').isShown = !this.readonly;

        this.readonly
          ? this.timeOffTypeForm.disable()
          : this.timeOffTypeForm.enable();

        const currentTimeOffTypeUnit = this.timeOffTypeUnits.find(
          (u) => u.id === data.timeOffType.unitId,
        );

        if (currentTimeOffTypeUnit) {
          this.timeOffTypeForm.controls.unit.setValue(currentTimeOffTypeUnit);
          this.timeOffTypeForm.controls.unit.disable();
        }

        this.navigationService.addRouteSegment({
          id: this.entityId,
          title: data.timeOffType.name,
        });

        this.isLoading = false;
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
      },
    });
  }

  public reload() {
    if (!this.timeOffTypeForm.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  // Сохранение данных.
  public save(): void {
    this.timeOffTypeForm.markAllAsTouched();

    if (this.timeOffTypeForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    this.isSaving = true;
    this.actionService.action('save').start();

    const timeOffType = this.timeOffTypeForm.getRawValue();

    const data: any = {
      code: timeOffType.code,
      paid: timeOffType.paid,
      description: timeOffType.description,
      isActive: timeOffType.isActive,
      name: timeOffType.name,
      unitId: timeOffType.unit.id,
    };

    this.data
      .collection('TimeOffTypes')
      .entity(this.entityId)
      .patch(data)
      .subscribe({
        next: () => {
          this.timeOffType.unitId = data.unitId;
          this.service.updateTimeOffType(this.timeOffType);

          if (this.timeOffType.unitId) {
            this.timeOffTypeForm.controls.unit.disable();
          }

          this.timeOffTypeForm.markAsPristine();
          this.isSaving = false;

          this.actionService.action('save').stop();
          this.notification.successLocal('settings.timeOffTypes.saved');
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit() {
    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.timeOffTypeUnits = TimeOffTypeUnits.map((unit) => ({
      id: unit.id,
      name: this.translate.instant(`enums.timeOffTypeUnit.${unit.code}`),
    }));

    this.load();
  }
}
