import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-icon-picker]',
  templateUrl: './icon-picker.component.html',
  styleUrl: './icon-picker.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class IconPickerComponent {
  @Input({ required: true }) public control: FormControl;

  public readonly biLink = {
    link: 'https://icons.getbootstrap.com/',
    title: 'icons.getbootstrap.com',
  };
}
