import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { BehaviorSubject, Observable, pipe, Subject } from 'rxjs';
import { FormGroupService } from 'src/app/shared/services/form-group.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { DataService } from 'src/app/core/data.service';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { TranslateService } from '@ngx-translate/core';
import {
  RuleCalculationMethod,
  RuleCalculationMethods,
} from 'src/app/shared/models/enums/rule-calculation-method.enum';
import { Guid } from 'src/app/shared/helpers/guid';
import {
  RuleCalculationBase,
  RuleCalculationBases,
} from 'src/app/shared/models/enums/rule-calculation-base.enum';
import { RecurringExpenseFrequencies } from 'src/app/shared/models/enums/recurring-expense-frequency.enum';
import { FormHelper } from 'src/app/shared/helpers/form-helper';
import { filter, switchMap, takeUntil } from 'rxjs/operators';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectTask } from 'src/app/shared/models/entities/projects/project-task.model';
import { RecurringExpenseRule } from 'src/app/shared/models/entities/projects/recurring-expense-rule.model';
import { camelCase, cloneDeep } from 'lodash';
import { Exception } from 'src/app/shared/models/exception';
import {
  RuleModalSavingState,
  RuleModalSavingStateMap,
} from 'src/app/projects/card/project-expenses/recurring-expense-rule-modal/enums/rule-modal-saving-state.enum';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { ProjectTasksService } from 'src/app/shared/services/project-tasks.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { FinancialAccountsService } from 'src/app/core/financial-accounts.service';

@Component({
  selector: 'wp-recurring-expenses-modal',
  templateUrl: './recurring-expense-rule-modal.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class RecurringExpenseRuleModalComponent implements OnInit, OnDestroy {
  @Input() ruleId: string | null = null;
  @Input() projectId: string;
  @Input() projectVersion: ProjectVersion;
  @Input() projectCurrencyCode: string;
  @Input() readonly: boolean;

  public collection = this.data.collection('RecurringExpenseRules');

  public form = this.fb.group({
    id: null,
    crossId: null,
    name: [
      null,
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    description: [null, Validators.maxLength(Constants.formTextMaxLength)],
    account: [null, Validators.required],
    projectTask: [null, Validators.required],
    calculationMethod: [null, Validators.required],
    calculationBase: null,
    amount: null,
    percent: null,
    frequency: [null, Validators.required],
    startDate: null,
    endDate: null,
    isWholeTask: true,
  });

  /** Component loading state. */
  public isLoading$ = new BehaviorSubject<boolean>(true);
  /** Component saving state. */
  public savingState$ = new BehaviorSubject<RuleModalSavingState>(
    RuleModalSavingState.none,
  );

  /** Determines if Rule has estimate entries or not. */
  public get hasEstimates() {
    return this._hasEstimates;
  }

  /** Is `true` when Percent Calculation method is selected, `false` otherwise. */
  public get isPercentRule() {
    return (
      this.form.controls.calculationMethod.value?.id ===
      RuleCalculationMethod.Percent
    );
  }

  /** Is `true` when Working Capital Calculation base is selected, `false` otherwise. */
  public get isWorkingCapitalBase() {
    return (
      this.form.controls.calculationBase.value?.id ===
      RuleCalculationBase.WorkingCapital
    );
  }

  /** Whole Task form control value. */
  public get isWholeTask() {
    return this.form.controls.isWholeTask.value === true;
  }

  /** Recurring expense rule Calculation method values. */
  public get calculationMethods() {
    return this._calculationMethods;
  }

  /**
   * Recurring expense rule Calculation base values.
   *
   * @remarks Depends on Project task kind selected (summary or not).
   * */
  public get calculationBases() {
    return this._calculationBases;
  }

  /** Recurring expense rule Frequency values. */
  public get frequencies() {
    return this._frequencies;
  }

  public readonly ruleModalSavingStateMap = RuleModalSavingStateMap;

  private ruleQuery = {
    select: [
      'id',
      'name',
      'crossId',
      'isActive',
      'created',
      'modified',
      'projectId',
      'versionId',
      'description',
      'calculationMethod',
      'calculationBase',
      'amount',
      'percent',
      'frequency',
      'startDate',
      'endDate',
      'isWholeTask',
      'hasEstimates',
    ],
    expand: {
      account: {
        select: ['id', 'name'],
      },
      projectTask: {
        select: [
          'id',
          'name',
          'indent',
          'leadTaskId',
          'structNumber',
          'number',
        ],
      },
    },
  };

  private rule: RecurringExpenseRule;

  private _hasEstimates = false;

  private _mainTask: ProjectTask;
  private _calculationMethods: Array<NamedEntity>;
  private _calculationBases: Array<NamedEntity>;
  private _revenueCalculationBase: NamedEntity;
  private _frequencies: Array<NamedEntity>;
  private _isProgramValueChange = false;
  private isCreatingMode = false;
  private isTrivialUpdate = true;
  private formInitData: Record<string, any>;
  private trivialUpdateFields: Partial<keyof RecurringExpenseRule>[] = [
    'name',
    'description',
  ];

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public financialAccountsService: FinancialAccountsService,
    private projectTasksService: ProjectTasksService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
    private formGroupService: FormGroupService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private message: MessageService,
    private data: DataService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.initVars();

    if (this.ruleId) {
      this.loadRule();
    } else {
      this.initForm();
      this.isLoading$.next(false);
    }
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  /**
   * Modal Save Rule and Estimate button click event handler.
   * Creates new/Updates Rule and its Estimate entries.
   * */
  public saveRuleAndEstimate(): void {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isTrivialUpdate = true;
    const ruleData = this.form.getRawValue();
    for (const key in ruleData) {
      if (ruleData[key] !== this.formInitData[key]) {
        this.isTrivialUpdate = this.trivialUpdateFields.includes(
          key as keyof RecurringExpenseRule,
        );

        if (!this.isTrivialUpdate) {
          break;
        }
      }
    }

    if (!this.isTrivialUpdate && !this.isCreatingMode) {
      this.message
        .confirmLocal(
          'projects.projects.recurringExpenseRules.modal.messages.updateConfirmation',
        )
        .then(
          () => this.save(),
          () => null,
        );
    } else {
      this.save();
    }
  }

  /**
   * Modal Delete button click event handler.
   * Deletes the Recurring expense rule.
   * */
  public delete(): void {
    this.message
      .confirmLocal(
        'projects.projects.recurringExpenseRules.modal.messages.deleteConfirmation',
      )
      .then(
        () => {
          this.savingState$.next(RuleModalSavingState.delete);
          this.updateFormState(true);
          this.detectChanges();

          this.collection
            .entity(this.ruleId)
            .delete()
            .subscribe({
              next: () => {
                this.notification.successLocal(
                  'projects.projects.recurringExpenseRules.modal.messages.deleteCompleted',
                );
                this.savingState$.next(RuleModalSavingState.none);
                this.activeModal.close();
              },
              error: (error: Exception) => {
                this.notification.error(error.message);
                this.updateFormState(false);
                this.savingState$.next(RuleModalSavingState.none);
              },
            });
        },
        () => null,
      );
  }

  /**
   * Modal Cancel/Cross button click event handler.
   * Closes the active modal.
   * */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Inits variables.
   * */
  private initVars(): void {
    // Init enum select items.
    const enumValueKeyPrefix =
      'projects.projects.recurringExpenseRules.modal.props.';
    this._calculationMethods = this.getEnumSelectItems(
      RuleCalculationMethods,
      `${enumValueKeyPrefix}calculationMethod.values.`,
    );
    this._calculationBases = this.getEnumSelectItems(
      RuleCalculationBases,
      `${enumValueKeyPrefix}calculationBase.values.`,
    );
    this._revenueCalculationBase = this._calculationBases.find(
      (x) => x.id === RuleCalculationBase.Revenue,
    );
    this._frequencies = this.getEnumSelectItems(
      RecurringExpenseFrequencies,
      `${enumValueKeyPrefix}frequency.values.`,
    );
  }

  /**
   * Initializes Recurring expense rule form group.
   * */
  private initForm(): void {
    if (!this.ruleId) {
      const ruleId = Guid.generate();
      this.rule = {
        id: ruleId,
        crossId: ruleId,
        description: null,
        name: '',
        account: null,
        projectTask: null,
        calculationMethod: RuleCalculationMethod.Fixed,
        amount: null,
        frequency: null,
        isWholeTask: true,
      };

      this.isCreatingMode = true;
    }

    const rule = <any>cloneDeep(this.rule);
    rule.calculationMethod = this._calculationMethods.find(
      (x) => x.id === this.rule.calculationMethod,
    );
    rule.calculationBase = this._calculationBases.find(
      (x) => x.id === this.rule.calculationBase,
    );
    rule.frequency = this._frequencies.find(
      (x) => x.id === this.rule.frequency,
    );
    rule.startDate = this.rule.startDate ?? null;
    rule.endDate = this.rule.endDate ?? null;

    this.form.patchValue(rule);
    this.formInitData = this.form.getRawValue();

    this.handleCalculationMethodChange(rule.calculationMethod);
    this.handleIsWholeTaskChange(rule.isWholeTask);

    if (this.readonly) {
      this.form.disable();
    } else {
      this.projectTasksService
        .getProjectTasks(this.projectId, this.projectVersion)
        .pipe(takeUntil(this.destroyed$))
        .subscribe((tasks) => {
          this._mainTask = tasks.find((t) => !t.leadTaskId);
        });

      if (!this._hasEstimates) {
        this.form.controls.projectTask.valueChanges
          .pipe(takeUntil(this.destroyed$))
          .subscribe((value: ProjectTask) =>
            this.handleProjectTaskChange(value),
          );

        this.form.controls.calculationBase.valueChanges
          .pipe(
            filter(() => !this._isProgramValueChange),
            takeUntil(this.destroyed$),
          )
          .subscribe(() => this.handleCalculationBaseChange());
      } else {
        this.form.controls.account.disable();
        this.form.controls.projectTask.disable();
      }

      this.form.controls.calculationMethod.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value: NamedEntity) =>
          this.handleCalculationMethodChange(value),
        );

      this.form.controls.isWholeTask.valueChanges
        .pipe(takeUntil(this.destroyed$))
        .subscribe((value) => this.handleIsWholeTaskChange(value));

      FormHelper.controlDatePair(
        this.form,
        'startDate',
        'endDate',
        takeUntil(this.destroyed$),
      );
    }
  }

  /**
   * Loads Recurring expense rule.
   * */
  private loadRule(): void {
    this.isLoading$.next(true);
    this.detectChanges();

    this.collection
      .entity(this.ruleId)
      .get<RecurringExpenseRule>(this.ruleQuery)
      .subscribe({
        next: (rule) => {
          this.rule = rule;
          this.isLoading$.next(false);
          this._hasEstimates = rule.hasEstimates;
          const isProject = ProjectVersionUtil.isWorkProjectVersion(
            this.projectVersion,
          );
          // Forbid editing other version or non-project rule.
          if (
            (!isProject && rule.versionId !== this.projectVersion.id) ||
            (isProject && !!rule.versionId)
          ) {
            this.readonly = true;
          }
          this.initForm();
          this.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading$.next(false);
          this.detectChanges();
        },
      });
  }

  /**
   * Gets Observable to Create new/Save the Recurring expense rule.
   * */
  public getSaveRuleObs(): Observable<any> {
    const rule = this.form.getRawValue();
    const calculationMethod = this.form.controls.calculationMethod.value.id;
    const calculationBase = this.isPercentRule
      ? this.form.controls.calculationBase.value.id
      : null;
    const amount = !this.isPercentRule ? rule.amount : null;
    const percent = this.isPercentRule ? rule.percent : null;
    const frequency = this.form.controls.frequency.value.id;
    const startDate = !this.isWholeTask ? rule.startDate : null;
    const endDate = !this.isWholeTask ? rule.endDate : null;

    const data =
      !this.isCreatingMode && this.isTrivialUpdate
        ? this.trivialUpdateFields.reduce(
            (result, value) => ({ ...result, [value]: rule[value] }),
            {},
          )
        : {
            id: rule.id,
            name: rule.name,
            crossId: rule.crossId,
            description: rule.description,
            accountId: rule.account.id,
            projectTaskId: rule.projectTask.id,
            calculationMethod,
            calculationBase,
            amount,
            percent,
            frequency,
            startDate,
            endDate,
            isWholeTask: rule.isWholeTask,
          };

    if (!this.isTrivialUpdate) {
      ProjectVersionUtil.setEntityRootPropertyId(
        this.projectVersion,
        data,
        this.projectId,
      );
    }

    if (this.ruleId) {
      return this.isTrivialUpdate
        ? this.collection.entity(this.ruleId).patch(data)
        : this.collection
            .entity(this.ruleId)
            .update(data, { withResponse: true });
    } else {
      return this.collection.insert(data);
    }
  }

  /**
   * Triggers change detection.
   * */
  private detectChanges(): void {
    this.cdRef.detectChanges();
  }

  private updateFormState(disable: boolean) {
    if (disable) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }
  }

  /**
   * Project task form control value change handler.
   * Updates Recurring expense rule form group.
   *
   * @param task New Project task form control value.
   * */
  private handleProjectTaskChange(task: ProjectTask): void {
    if (task.leadTaskId && this.isWorkingCapitalBase) {
      this.form.controls.calculationBase.setValue(
        this._revenueCalculationBase,
        {
          emitEvent: false,
        },
      );
    }
  }

  /**
   * Calculation method form control value change handler.
   * Updates Recurring expense rule form group.
   *
   * @param calculationMethod New Calculation method form control value.
   * */
  private handleCalculationMethodChange(calculationMethod: NamedEntity): void {
    const amountControl = this.form.controls.amount as UntypedFormControl;
    const percentControl = this.form.controls.percent as UntypedFormControl;
    const calculationBaseControl = this.form.controls
      .calculationBase as UntypedFormControl;
    this._isProgramValueChange = true;

    switch (calculationMethod.id) {
      case RuleCalculationMethod.Fixed:
        this.formGroupService.toggleFormControlValidators(percentControl);
        this.formGroupService.toggleFormControlValidators(
          amountControl,
          Validators.required,
        );
        this.formGroupService.toggleFormControlValidators(
          calculationBaseControl,
        );
        break;
      case RuleCalculationMethod.Percent:
        this.formGroupService.toggleFormControlValidators(
          percentControl,
          Validators.required,
        );
        this.formGroupService.toggleFormControlValidators(amountControl);
        this.formGroupService.toggleFormControlValidators(
          calculationBaseControl,
          Validators.required,
        );
        break;
    }

    this._isProgramValueChange = false;
  }

  /**
   * Calculation base form control value change handler.
   * Updates Recurring expense rule form group.
   * */
  private handleCalculationBaseChange(): void {
    const projectTaskControl = this.form.controls
      .projectTask as UntypedFormControl;

    if (
      this.isWorkingCapitalBase &&
      !!projectTaskControl.value?.leadTaskId &&
      !!this._mainTask
    ) {
      projectTaskControl.setValue(this._mainTask, {
        emitEvent: false,
      });
    }
  }

  /**
   * Whole task form control value change handler.
   * Updates Recurring expense rule form group.
   *
   * @param isWholeTask New Whole task form control value.
   * */
  private handleIsWholeTaskChange(isWholeTask: boolean): void {
    const startDateControl = this.form.controls.startDate as UntypedFormControl;
    const endDateControl = this.form.controls.endDate as UntypedFormControl;

    if (isWholeTask === true) {
      this.formGroupService.toggleFormControlValidators(startDateControl);
      this.formGroupService.toggleFormControlValidators(endDateControl);
    } else {
      this.formGroupService.toggleFormControlValidators(
        startDateControl,
        Validators.required,
      );
      this.formGroupService.toggleFormControlValidators(
        endDateControl,
        Validators.required,
      );
    }
  }

  /**
   * Gets transformed enum values for select list.
   *
   * @param enumValues Enum values.
   * @param keyPrefix Translation key prefix.
   * @returns Transformed enum values.
   * */
  private getEnumSelectItems(
    enumValues: Array<any>,
    keyPrefix: string,
  ): Array<NamedEntity> {
    return enumValues.map((e) => ({
      id: e,
      name: this.translate.instant(`${keyPrefix}${camelCase(e)}`),
    }));
  }

  private save(): void {
    let messageKey = 'projects.projects.recurringExpenseRules.modal.messages.';

    if (this.ruleId) {
      messageKey += !this.isTrivialUpdate
        ? 'updateCompleted'
        : 'ruleUpdateCompleted';
    } else {
      messageKey += 'createCompleted';
    }

    this.savingState$.next(RuleModalSavingState.saveRuleAndEstimate);
    this.updateFormState(true);
    this.detectChanges();

    this.getSaveRuleObs()
      .pipe(
        !this.isTrivialUpdate
          ? switchMap((rule: RecurringExpenseRule) =>
              this.collection
                .entity(rule.id)
                .action('CreateOrUpdateRuleEstimate')
                .execute(),
            )
          : pipe(),
        takeUntil(this.destroyed$),
      )
      .subscribe({
        next: () => {
          this.notification.successLocal(messageKey);
          this.savingState$.next(RuleModalSavingState.none);
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.updateFormState(false);
          this.savingState$.next(RuleModalSavingState.none);
        },
      });
  }
}
