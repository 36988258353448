import {
  Component,
  Input,
  ViewRef,
  ChangeDetectorRef,
  OnInit,
  OnDestroy,
} from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { InvoiceCardService } from '../../invoice-card.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'wp-invoice-task-cell',
  templateUrl: './invoice-task-cell.component.html',
  styleUrls: ['./invoice-task-cell.component.scss'],
  standalone: false,
})
export class InvoiceTaskCellComponent
  implements GridComponentCell, OnInit, OnDestroy
{
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  public projectId: string;

  get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }

  /** The component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: GridService,
    private invoiceCardService: InvoiceCardService,
    private ref: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.service.detectChanges$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        if (this.ref && !(this.ref as ViewRef).destroyed) {
          this.ref.detectChanges();
        }
      });
    this.invoiceCardService.invoice$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((invoice) => {
        this.projectId = invoice.project?.id;

        // Disable for old invoices (Before update June 2022) or not Draft ones.
        if (!this.projectId || !invoice.editAllowed) {
          this.control.disable({ emitEvent: false });
        }
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
