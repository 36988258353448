import { List } from '../models/inner/list';
import { TotalType } from '../models/inner/total-type';

import { MembersCellComponent } from 'src/app/projects/card/shared/members-cell/members-cell.component';
import { MembersReadonlyCellComponent } from 'src/app/projects/card/shared/members-cell/members-readonly-cell.component';

import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { TaskCollapsibleCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-collapsible-cell/task-collapsible-cell.component';
import { TaskCollapsibleReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-collapsible-cell/task-collapsible-readonly-cell.component';
import { TaskDateCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-date-cell/task-date-cell.component';
import { TaskDateReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-date-cell/task-date-readonly-cell.component';
import { TaskDurationCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-duration-cell/task-duration-cell.component';
import { TaskDurationReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-duration-cell/task-duration-readonly-cell.component';
import { TaskNameCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-name-cell/task-name-cell.component';
import { TaskNameReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-name-cell/task-name-readonly-cell.component';
import { TaskPredecessorCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-predecessor-cell/task-predecessor-cell.component';
import { TaskPredecessorReadonlyCellComponent } from 'src/app/projects/card/project-tasks/shared/tasks-grid/task-predecessor-cell/task-predecessor-readonly-cell.component';
import { ProjectTaskTableView } from 'src/app/projects/card/project-tasks/shared/models/project-task-view.enum';
import {
  GridColumnType,
  GridComponentColumn,
  GridSelectControlColumn,
  GridStringControlColumn,
  GridBooleanColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

export const projectTaskTableNoAutoPlanningColumns = [
  {
    column: 'structNumber',
    visibleByDefault: true,
    width: 75,
  },
  {
    column: 'isActive',
    visibleByDefault: true,
    width: 45,
  },
  {
    column: 'name',
    visibleByDefault: true,
    width: 300,
    totalByDefault: TotalType.Count,
    isRequired: true,
  },
  {
    column: 'startDate',
    visibleByDefault: true,
    width: 130,
  },
  {
    column: 'endDate',
    visibleByDefault: true,
    width: 130,
  },
  {
    column: 'duration',
    visibleByDefault: true,
    width: 130,
  },
  {
    column: 'plannedStartDate',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'plannedEndDate',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'isMilestone',
    visibleByDefault: true,
    width: 90,
  },
  {
    column: 'estimatedHours',
    visibleByDefault: true,
    width: 105,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualHours',
    visibleByDefault: false,
    width: 105,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'description',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'projectTaskAssignments',
    visibleByDefault: true,
    width: 300,
  },
  {
    column: 'dependencies',
    visibleByDefault: true,
    width: 250,
  },
  {
    column: 'plannedHours',
    visibleByDefault: true,
    width: 105,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'percentComplete',
    visibleByDefault: true,
    width: 105,
  },
  {
    column: 'plannedCost',
    visibleByDefault: false,
    width: 120,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'estimatedCost',
    visibleByDefault: false,
    width: 120,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualCost',
    visibleByDefault: false,
    width: 120,
    totalByDefault: TotalType.Sum,
  },
];
export const projectTaskTimelineNoAutoPlanningColumns = [
  {
    column: 'structNumber',
    visibleByDefault: true,
    width: 75,
  },
  {
    column: 'isActive',
    visibleByDefault: true,
    width: 45,
  },
  {
    column: 'name',
    visibleByDefault: true,
    width: 300,
    totalByDefault: TotalType.Count,
    isRequired: true,
  },
  {
    column: 'startDate',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'endDate',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'duration',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'plannedStartDate',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'plannedEndDate',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'isMilestone',
    visibleByDefault: false,
    width: 90,
  },
  {
    column: 'estimatedHours',
    visibleByDefault: false,
    width: 105,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualHours',
    visibleByDefault: false,
    width: 105,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'description',
    visibleByDefault: false,
    width: 130,
  },
  {
    column: 'projectTaskAssignments',
    visibleByDefault: false,
    width: 300,
  },
  {
    column: 'dependencies',
    visibleByDefault: false,
    width: 250,
  },
  {
    column: 'plannedHours',
    visibleByDefault: false,
    width: 105,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'percentComplete',
    visibleByDefault: false,
    width: 105,
  },
  {
    column: 'plannedCost',
    visibleByDefault: false,
    width: 120,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'estimatedCost',
    visibleByDefault: false,
    width: 120,
    totalByDefault: TotalType.Sum,
  },
  {
    column: 'actualCost',
    visibleByDefault: false,
    width: 120,
    totalByDefault: TotalType.Sum,
  },
];

export const PROJECT_TASK_LIST: List = {
  name: 'projectTasks',
  version: 28,
  customFieldEntityType: 'ProjectTask',
  views: [
    {
      name: ProjectTaskTableView.tableDefault,
      resizableColumns: true,
      columns: [
        ...projectTaskTableNoAutoPlanningColumns,
        ...[
          {
            column: 'type',
            visibleByDefault: true,
            width: 150,
          },
          {
            column: 'effortDriven',
            visibleByDefault: false,
            width: 120,
          },
        ],
      ],
    },
    {
      name: ProjectTaskTableView.tableNoAutoPlanning,
      resizableColumns: true,
      columns: projectTaskTableNoAutoPlanningColumns,
    },
    {
      name: ProjectTaskTableView.timelineDefault,
      resizableColumns: true,
      columns: [
        ...projectTaskTimelineNoAutoPlanningColumns,
        ...[
          {
            column: 'type',
            visibleByDefault: false,
            width: 150,
          },
          {
            column: 'effortDriven',
            visibleByDefault: false,
            width: 120,
          },
        ],
      ],
    },
    {
      name: ProjectTaskTableView.timelineNoAutoPlanning,
      resizableColumns: true,
      columns: projectTaskTimelineNoAutoPlanningColumns,
    },
  ],
  columns: [
    <GridComponentColumn>{
      name: 'actualCost',
      header: 'shared2.props.actualCost.short',
      hint: 'shared2.props.actualCost.hint',
      availableTotals: [TotalType.Sum],
      type: GridColumnType.Component,
      contentType: GridColumnType.Currency,
      component: TaskCollapsibleCellComponent,
      readonlyComponent: TaskCollapsibleReadonlyCellComponent,
    },
    <GridComponentColumn>{
      name: 'actualHours',
      header: 'shared2.props.actualHours.short',
      hint: 'shared2.props.actualHours.hint',
      type: GridColumnType.Component,
      availableTotals: [TotalType.Sum],
      contentType: GridColumnType.Work,
      component: TaskCollapsibleCellComponent,
      readonlyComponent: TaskCollapsibleReadonlyCellComponent,
    },
    <GridComponentColumn>{
      name: 'plannedHours',
      header: 'shared2.props.plannedHours.short',
      hint: 'shared2.props.plannedHours.hint',
      type: GridColumnType.Component,
      availableTotals: [TotalType.Sum],
      contentType: GridColumnType.Work,
      component: TaskCollapsibleCellComponent,
      readonlyComponent: TaskCollapsibleReadonlyCellComponent,
    },
    <GridComponentColumn>{
      name: 'plannedCost',
      header: 'components.projectTaskList.props.plannedCost.short',
      hint: 'components.projectTaskList.props.plannedCost.hint',
      type: GridColumnType.Component,
      availableTotals: [TotalType.Sum],
      contentType: GridColumnType.Currency,
      component: TaskCollapsibleCellComponent,
      readonlyComponent: TaskCollapsibleReadonlyCellComponent,
    },
    <GridComponentColumn>{
      name: 'estimatedHours',
      header: 'shared2.props.estimatedHours',
      hint: 'shared2.props.estimatedHours',
      type: GridColumnType.Component,
      availableTotals: [TotalType.Sum],
      contentType: GridColumnType.Work,
      component: TaskCollapsibleCellComponent,
      readonlyComponent: TaskCollapsibleReadonlyCellComponent,
      precision: 1,
      getDefaultValue: () => 0,
    },
    <GridComponentColumn>{
      name: 'estimatedCost',
      header: 'components.projectTaskList.props.estimatedCost.short',
      hint: 'components.projectTaskList.props.estimatedCost.hint',
      type: GridColumnType.Component,
      availableTotals: [TotalType.Sum],
      contentType: GridColumnType.Currency,
      component: TaskCollapsibleCellComponent,
      readonlyComponent: TaskCollapsibleReadonlyCellComponent,
    },
    <GridComponentColumn>{
      name: 'percentComplete',
      header: 'shared2.props.percentComplete.short',
      hint: 'shared2.props.percentComplete.value',
      type: GridColumnType.Component,
      contentType: GridColumnType.Percent,
      component: TaskCollapsibleCellComponent,
      readonlyComponent: TaskCollapsibleReadonlyCellComponent,
    },
    <GridComponentColumn>{
      name: 'structNumber',
      header: 'components.projectTaskList.props.structNumber.value',
      hint: 'components.projectTaskList.props.structNumber.hint',
      type: GridColumnType.String,

      forceCellUpdating: true,
    },
    <GridComponentColumn>{
      name: 'name',
      header: 'shared2.props.name',
      hint: 'shared2.props.name',
      type: GridColumnType.Component,
      contentType: GridColumnType.String,
      availableTotals: [TotalType.Count],
      component: TaskNameCellComponent,
      readonlyComponent: TaskNameReadonlyCellComponent,
      getDefaultValue: () => '',
    },
    <GridStringControlColumn>{
      name: 'description',
      header: 'shared2.props.description',
      hint: 'shared2.props.description',
      type: GridColumnType.StringControl,
      forceCellUpdating: true,
      propagationMode: PropagationMode.onExitFromEditing,
    },
    <GridComponentColumn>{
      name: 'duration',
      header: 'shared2.props.projectTaskDuration.value',
      hint: 'shared2.props.projectTaskDuration.hint',
      component: TaskDurationCellComponent,
      readonlyComponent: TaskDurationReadonlyCellComponent,
      type: GridColumnType.Component,
      contentType: GridColumnType.Integer,
      getDefaultValue: () => 1,
    },
    <GridComponentColumn>{
      name: 'startDate',
      header: 'components.projectTaskList.props.startDate',
      hint: 'components.projectTaskList.props.startDate',
      component: TaskDateCellComponent,
      readonlyComponent: TaskDateReadonlyCellComponent,
      type: GridColumnType.Component,
      contentType: GridColumnType.Date,
      allowNull: false,
    },
    <GridComponentColumn>{
      name: 'endDate',
      header: 'components.projectTaskList.props.endDate',
      hint: 'components.projectTaskList.props.endDate',
      component: TaskDateCellComponent,
      readonlyComponent: TaskDateReadonlyCellComponent,
      type: GridColumnType.Component,
      contentType: GridColumnType.Date,
      allowNull: false,
    },
    {
      name: 'plannedStartDate',
      header: 'components.projectTaskList.props.plannedStartDate.short',
      hint: 'components.projectTaskList.props.plannedStartDate.hint',
      type: GridColumnType.Date,
    },
    {
      name: 'plannedEndDate',
      header: 'components.projectTaskList.props.plannedEndDate.short',
      hint: 'components.projectTaskList.props.plannedEndDate.hint',
      type: GridColumnType.Date,
    },
    <GridBooleanColumn>{
      name: 'isActive',
      header: null,
      hint: 'shared2.props.completed',
      type: GridColumnType.BooleanControl,
      reversed: true,
    },
    {
      name: 'isMilestone',
      header: 'shared2.props.milestone',
      hint: 'shared2.props.milestone',
      type: GridColumnType.BooleanControl,
    },
    {
      name: 'effortDriven',
      header: 'shared2.props.effortDriven',
      hint: 'shared2.props.effortDriven',
      type: GridColumnType.BooleanControl,
      forceCellUpdating: true,
    },
    <GridSelectControlColumn>{
      name: 'type',
      header: 'shared2.props.type',
      hint: 'shared2.props.type',
      placeholder: 'shared2.props.type',
      type: GridColumnType.SelectControl,
      forceCellUpdating: true,
      allowNull: false,
    },
    <GridComponentColumn>{
      name: 'projectTaskAssignments',
      header: 'shared2.props.assignments',
      hint: 'shared2.props.assignments',
      type: GridColumnType.Component,
      component: MembersCellComponent,
      readonlyComponent: MembersReadonlyCellComponent,
      getDefaultValue: () => [],
    },
    <GridComponentColumn>{
      name: 'dependencies',
      header: 'shared2.props.dependencies',
      hint: 'shared2.props.dependencies',
      component: TaskPredecessorCellComponent,
      readonlyComponent: TaskPredecessorReadonlyCellComponent,
      type: GridColumnType.Component,
      getDefaultValue: () => [],
    },
  ],
  dataColumns: [],
};
