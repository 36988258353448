import { Component } from '@angular/core';

/** Индикатор блокировки интерфейса. */
@Component({
  selector: 'wp-block-ui',
  templateUrl: './block-ui.component.html',
  styleUrls: ['./block-ui.component.scss'],
  standalone: false,
})
export class BlockUIComponent {}
