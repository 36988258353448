import { Component, Input, Inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'wp-client-filter',
  templateUrl: './client-filter.component.html',
  standalone: false,
})
export class ClientFilterComponent implements FilterDetails {
  @Input() values: any;

  detailsForm = this.fb.group({
    period: [null],
    manager: [null],
  });

  constructor(
    @Inject(VIEW_NAME) public viewName: string,
    public readonly service: FilterService,
    private readonly fb: UntypedFormBuilder,
  ) {
    this.service.resetValues$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges.pipe(takeUntilDestroyed()).subscribe(() => {
      const values = Object.assign(this.service.values, this.detailsForm.value);
      this.service.changeValues(values);
    });
  }
}
