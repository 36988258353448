import {
  Component,
  OnInit,
  Input,
  OnDestroy,
  AfterViewInit,
  ElementRef,
  Output,
  EventEmitter,
} from '@angular/core';
import { WidgetConfig } from '../../../shared/widget-config.model';
import { PivotRenderService } from 'src/app/analytics/shared/pivot-table/pivot-render.service';
import { PivotDataService } from 'src/app/analytics/shared/pivot-table/pivot-data.service';
import { PivotTableService } from 'src/app/analytics/shared/pivot-table/pivot-table.service';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { WidgetService } from '../widget.service';
import { ChromeService } from 'src/app/core/chrome.service';
import { DashboardService } from '../../dashboard.service';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { Subscription } from 'rxjs';
import { Guid } from 'src/app/shared/helpers/guid';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';

@Component({
  selector: 'wp-widget-table',
  templateUrl: './widget-table.component.html',
  styleUrls: ['./widget-table.component.scss'],
  providers: [
    FreezeTableService,
    PivotRenderService,
    PivotDataService,
    PivotTableService,
  ],
  standalone: false,
})
export class WidgetTableComponent implements OnInit, OnDestroy, AfterViewInit {
  @Input() widget: WidgetConfig;
  @Input() dashboardId: string;
  @Input() period: DatePeriod;

  @Output() onChanged = new EventEmitter<WidgetConfig>();

  public containerStyles: Dictionary<string> = {};
  public containerId = `c-${Guid.generate()}`;

  private subscriptions: Subscription[];

  constructor(
    private widgetService: WidgetService,
    private chromeService: ChromeService,
    public pivotTableService: PivotTableService,
    private elementRef: ElementRef,
    public dashboardService: DashboardService,
    private freezingTable: FreezeTableService,
  ) {}

  private adaptSize() {
    const container = this.elementRef.nativeElement as HTMLElement;
    if (!container) {
      return;
    }
    const rect = container.getBoundingClientRect();
    this.containerStyles['height'] = rect.height - 20 + 'px';
    this.containerStyles['width'] = rect.width - 20 + 'px';
    this.freezingTable.redraw();
  }

  private rebuild() {
    this.pivotTableService.initForWidget(
      this.widget.id,
      this.dashboardId,
      this.widget.viewSettings,
      this.widget.userSettings,
    );
    this.pivotTableService.run(false, this.period);
    this.adaptSize();
  }

  ngOnInit(): void {
    if (!this.widget.userSettings) {
      this.widget.userSettings = {};
    }

    if (!this.widget.userSettings.columns) {
      this.widget.userSettings.columns = {};
    }

    this.subscriptions = [
      this.widgetService.rebuild$.subscribe(() => this.rebuild()),
      this.widgetService.reflow$.subscribe(() => this.adaptSize()),
      this.chromeService.mainAreaSize$.subscribe(() => this.adaptSize()),
      this.dashboardService.reflowWidgets$.subscribe(() =>
        setTimeout(() => this.adaptSize()),
      ),
      this.dashboardService.reloadWidgets$.subscribe(() =>
        this.pivotTableService.run(true, this.period),
      ),

      this.pivotTableService.resizing$.subscribe((param) => {
        this.widget.userSettings.columns[param[0]] = param[1];
        this.onChanged.emit(this.widget);
      }),

      this.pivotTableService.sorting$.subscribe((value) => {
        this.widget.userSettings.sorting = value;
        this.onChanged.emit(this.widget);
      }),
    ];
  }

  ngAfterViewInit(): void {
    this.rebuild();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach((s) => s.unsubscribe());
  }
}
