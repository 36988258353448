import { Injectable, OnDestroy } from '@angular/core';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { TranslateService } from '@ngx-translate/core';
import { TransitionService } from '@uirouter/core';
import { firstValueFrom, isObservable, Observable, Subject } from 'rxjs';
import { Exception, SavingQueueException } from '../models/exception';
import { MessageService } from 'src/app/core/message.service';
import { LogService } from 'src/app/core/log.service';
import { AutosaveStateService } from './autosave-state.service';
import { DateTime } from 'luxon';
import _ from 'lodash';

/** API call service in FIFO queue. */
@Injectable()
export class SavingQueueService implements OnDestroy {
  public error$ = new Subject<SavingQueueException>();
  public save$ = new Subject<any>();

  /** Queue. Key - object Id. */
  private queue: {
    id: string;
    task: QueueTask;
  }[] = [];

  /** Delay from the last change to the start of saving. */
  public delayDuration = 2000;

  /** Disabled saving flag.
   *
   * @deprecated - optimize calls for saving instead.
   */
  public disabled = false;

  // Saving process indicator.
  private _isSaving = false;

  public get isSaving(): boolean {
    return this._isSaving;
  }
  public set isSaving(value: boolean) {
    this._isSaving = value;
    this.autosaveStateService.setState(value);
  }

  private lastAddingTime;
  private timeoutId;
  private transitionOnStartListener: any;

  constructor(
    private messageService: MessageService,
    private autosaveStateService: AutosaveStateService,
    transition: TransitionService,
    private log: LogService,
    private blockUI: BlockUIService,
    private translate: TranslateService,
  ) {
    window.onbeforeunload = this.onbeforeunload;

    // Route change listener.
    this.transitionOnStartListener = transition.onStart(
      {},
      () =>
        new Promise<void>((resolve) => {
          this.save().then(() => resolve());
        }),
    );
  }

  public ngOnDestroy(): void {
    this.transitionOnStartListener();
    window.onbeforeunload = null;
  }

  public addToQueue(id: string, task: QueueTask) {
    if (this.disabled) {
      return;
    }

    this.lastAddingTime = DateTime.now();

    const existedItem = this.queue.find((item) => item.id === id);
    if (existedItem) {
      existedItem.task = task;
      this.log.debug(`Saving queue: ${id} has been updated in queue.`);
    } else {
      this.queue.push({ id, task });
      this.log.debug(`Saving queue: ${id} has been added into queue.`);
    }

    this.saveNext(false);
  }

  /** Adds saving queue task in the queue with patch mode
   * or merge them with closest saving queue task with same id.
   * Both of tasks must be able to merge.
   *
   * @param id entity id.
   * @param rowVersionFactory factory for getting last actual entityRowVersion.
   * @param handlerFactory factory for getting the request to server observable by patch DTO.
   * @param patchDTO data transfer object for patch request.
   * @param canBeMerged a sign if this task able to merge.
   */
  public addToQueuePatch(
    id: string,
    rowVersionFactory: () => string,
    handlerFactory: (object: any) => Observable<any>,
    patchDTO: any,
    canBeMerged?: boolean,
  ): void {
    if (this.disabled) {
      return;
    }

    this.lastAddingTime = DateTime.now();

    const lastExistQueueTaskIndex = _.findLastIndex(
      this.queue,
      (task) => task.id === id,
    );
    const queueTask =
      lastExistQueueTaskIndex !== -1
        ? (this.queue[lastExistQueueTaskIndex].task as any)
        : null;
    if (queueTask && queueTask.canBeMerged && canBeMerged) {
      Object.keys(patchDTO).forEach((key) => {
        queueTask.patchDTO[key] = patchDTO[key];
      });
      this.log.debug(`Saving queue: ${id} has been updated in queue.`);
    } else {
      this.queue.push({
        id,
        task: {
          rowVersionFactory,
          handlerFactory,
          patchDTO,
          canBeMerged,
        },
      });
      this.log.debug(`Saving queue: ${id} has been added into queue.`);
    }

    this.saveNext(false);
  }
  private saveNext(force: boolean) {
    clearTimeout(this.timeoutId);

    // If queue is empty or already is saving, finish the operation.
    if (this.isSaving || this.queue.length === 0) {
      return;
    }

    const elapsedTime = DateTime.now().diff(
      this.lastAddingTime,
      'milliseconds',
    ).milliseconds;

    // If not enough time has passed, postpone the operation.
    if (!force && elapsedTime < this.delayDuration) {
      this.timeoutId = setTimeout(
        () => this.saveNext(force),
        this.delayDuration - elapsedTime,
      );
      return;
    }

    // Start operation.
    this.isSaving = true;
    const item = this.queue.splice(0, 1);

    this.log.debug(`Saving queue: ${item[0].id} is saving...`);
    let observable: Observable<any>;
    const nextQueueTask = item[0].task as any;

    // Handle factory preferred using, else works with complete queue task.
    if (nextQueueTask.handlerFactory) {
      nextQueueTask.patchDTO.rowVersion = nextQueueTask.rowVersionFactory();
      observable = nextQueueTask.handlerFactory(nextQueueTask.patchDTO);
    } else {
      observable = isObservable(nextQueueTask)
        ? nextQueueTask
        : nextQueueTask();
    }

    return firstValueFrom(observable).then(
      (response) => {
        this.log.debug(`Saving queue: ${item[0].id} has been saved.`);
        this.isSaving = false;

        this.save$.next(response);
        return this.saveNext(force);
      },
      (error: Exception) => {
        const savingQueueException: SavingQueueException = {
          savingQueueId: item[0].id,
          ...error,
        };
        this.errorHandler(savingQueueException);
      },
    );
  }

  /** Saves all entires immediately. */
  public save(): Promise<void> {
    if ((this.queue.length === 0 || this.disabled) && !this.isSaving) {
      return Promise.resolve();
    }

    this.log.debug(`Saving queue: entire queue is saving...`);
    this.blockUI.start();

    return new Promise((resolve, reject) => {
      this.saveNext(true);

      const finishFn = () => {
        this.blockUI.stop();
        subscription.unsubscribe();
        errorSubscription.unsubscribe();
      };

      const subscription = this.save$.subscribe(() => {
        if (this.queue.length === 0) {
          finishFn();
          resolve();
        }
      });

      const errorSubscription = this.error$.subscribe(() => {
        finishFn();
        reject();
      });
    });
  }

  // Page closing listener.
  private onbeforeunload = (event: any) => {
    if (this.queue.length === 0 && !this.isSaving) {
      return;
    }
    this.save();
    const message = this.translate.instant('common.leavePageMessage');

    if (typeof event == 'undefined') {
      event = window.event;
    }
    if (event) {
      event.returnValue = message;
    }
    return message;
  };

  private errorHandler(error: SavingQueueException) {
    this.isSaving = false;
    this.blockUI.stop();
    let msg = error.message;
    let btnText = null;
    if (error.code === SavingQueueException.BtConcurrencyException.code) {
      msg = this.translate.instant(
        SavingQueueException.BtConcurrencyException.message,
      );
      btnText = this.translate.instant('shared.actions.reload');
    }
    this.messageService.message(msg, '', [], btnText).then(
      () => this.error$.next(error),
      () => this.error$.next(error),
    );
  }
}

export type QueueTask =
  | (() => Observable<any>)
  | Observable<any>
  | {
      rowVersionFactory?: () => string;
      handlerFactory?: (object: any) => Observable<any>;
      patchDTO?: any;
      canBeMerged?: boolean;
    };
