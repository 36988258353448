import { ChangeDetectionStrategy, Component, inject } from '@angular/core';
import { DealCardService } from 'src/app/deals/card/deal-card.service';
import { SystemDirectory } from 'src/app/shared/models/enums/system-directory.enum';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { AppService } from 'src/app/core/app.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

@Component({
  selector: 'tmt-deal-profile',
  templateUrl: './deal-profile.component.html',
  styleUrl: './deal-profile.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: 'organizationId',
      useFactory: (dealCardService: DealCardService) =>
        dealCardService.deal.organization?.id,
      deps: [DealCardService],
    },
  ],
  standalone: false,
})
export class DealProfileComponent {
  public activeTab: string;
  public readonly commentedEntityCollectionType = CommentedEntityCollectionType;
  public readonly propagationMode = PropagationMode;
  public readonly systemDirectory = SystemDirectory;
  public readonly appService = inject(AppService);
  public readonly dealCardService = inject(DealCardService);
  public readonly savingQueueService = inject(SavingQueueService);
}
