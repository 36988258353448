import {
  Directive,
  ElementRef,
  Input,
  OnInit,
  Renderer2,
  EventEmitter,
  Output,
  OnDestroy,
  NgZone,
  Inject,
} from '@angular/core';
import { DOCUMENT } from '@angular/common';
import { LogService } from 'src/app/core/log.service';
import { Subject, asyncScheduler, fromEvent } from 'rxjs';
import { takeUntil, throttleTime } from 'rxjs/operators';

@Directive({
  selector: '[wpResizing]',
  standalone: false,
})
export class ResizingDirective implements OnInit, OnDestroy {
  @Input() selector: string;
  @Output() columnResized = new EventEmitter<[string, number]>();

  /** Minimal column width in px. */
  @Input() minColumnWidth = 25;

  private destroyed$ = new Subject<void>();
  private resizedSubject = new Subject<any>();

  private auditTime = 15;
  private throttle = throttleTime(this.auditTime, asyncScheduler, {
    leading: true,
    trailing: true,
  });

  isResizing: boolean;
  startPageX: number;
  currentColumn: HTMLTableColElement;
  currentColumnName: string;
  startWidth: number;

  constructor(
    private log: LogService,
    private table: ElementRef,
    private renderer: Renderer2,
    private zone: NgZone,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.resizedSubject.pipe(this.throttle).subscribe((event: any) => {
      const diffX = event.pageX - this.startPageX;
      const newWidth = this.startWidth + diffX;

      if (newWidth < this.minColumnWidth) {
        return;
      }

      this.currentColumn.style.width = newWidth + 'px';
      this.columnResized.emit([this.currentColumnName, newWidth]);
    });
  }
  ngOnInit(): void {
    const mutationObserver = new MutationObserver(() => {
      this.log.debug(
        'Resizing table directive. Mutation observer is triggered.',
      );
      this.init();
    });
    mutationObserver.observe(this.table.nativeElement, {
      childList: true,
      subtree: true,
    });

    this.renderer.listen(this.document, 'mouseup', (event: any) =>
      this.stop(event),
    );

    this.zone.runOutsideAngular(() => {
      fromEvent(this.document, 'mousemove')
        .pipe(this.throttle, takeUntil(this.destroyed$))
        .subscribe((event) => {
          if (!this.isResizing) {
            return;
          }
          this.resizedSubject.next(event);
        });
    });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private init() {
    const table = this.table.nativeElement as HTMLElement;
    const cells = table.querySelectorAll(this.selector);

    cells.forEach((cell) => {
      if (!cell.querySelector('.rz-handle')) {
        const handle = this.renderer.createElement('div');
        this.renderer.addClass(handle, 'rz-handle');
        this.renderer.appendChild(cell, handle);
        this.renderer.listen(handle, 'mousedown', (event: any) =>
          this.start(event),
        );
        this.renderer.listen(handle, 'click', (event: any) =>
          event.stopPropagation(),
        );
      }
    });
  }

  private start(event: any) {
    this.isResizing = true;
    const target: HTMLElement = event.target;
    const columns = (this.table.nativeElement as HTMLElement)
      .querySelector('colgroup')
      .querySelectorAll('col');
    let index = 0;
    let el = target.parentElement;
    // eslint-disable-next-line no-cond-assign
    while ((el = el.previousSibling as HTMLTableCellElement) != null) {
      if (el.nodeName.toLowerCase() === 'th') {
        index++;
      }
    }
    this.currentColumn = columns[index] as HTMLTableColElement;
    this.startPageX = event.pageX;

    // eslint-disable-next-line radix
    this.startWidth = parseInt(this.currentColumn.style.width);
    this.currentColumnName = target.parentElement.getAttribute('name');
  }

  private stop(event) {
    if (this.isResizing) {
      this.resizedSubject.next(event);
    }

    this.isResizing = false;
  }
}
