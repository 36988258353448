import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { KpiType } from 'src/app/shared/models/enums/kpi-type.enum';
import { ProjectSummaryViewSettingsService } from 'src/app/project-summary/shared/project-summary-view-settings/core/project-summary-view-settings.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Project Summary View Settings content. */
@Component({
  selector: 'tmt-project-summary-view-settings',
  templateUrl: './project-summary-view-settings.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectSummaryViewSettingsComponent implements OnInit {
  /** Gets KPI controls. */
  public get kpiControls(): KpiType[] {
    return Object.values(this.orderedKpi);
  }

  public form: UntypedFormGroup = this.fb.group({
    [KpiType.Plan]: false,
    [KpiType.Estimate]: false,
    [KpiType.Actual]: false,
  });

  protected kpiType = KpiType;

  private orderedKpi = [KpiType.Plan, KpiType.Estimate, KpiType.Actual];

  private destroyRef = inject(DestroyRef);

  constructor(
    private service: ProjectSummaryViewSettingsService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.form.patchValue(this.service.settings.show);
    this.initSubscriptions();
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const settings = this.service.settings;
        settings.show = this.form.value;
        this.service.propagateSettingsChange(settings);
      });
  }
}
