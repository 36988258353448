import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
} from '@angular/core';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'tmt-grid-test-toolbar',
  templateUrl: './grid-test-toolbar.component.html',
  styleUrl: './grid-test-toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GridTestToolbarComponent extends AbstractToolbar {
  constructor(gridService: GridService, cdr: ChangeDetectorRef) {
    super(gridService, cdr);
  }
}
