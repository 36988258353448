import { Component } from '@angular/core';
import { GroupCardService } from './group-card.service';
import { UIRouterGlobals } from '@uirouter/core';
import { TabLink } from 'src/app/shared/models/navigation/navigation';

@Component({
  selector: 'tmt-group-card',
  templateUrl: './group-card.component.html',
  providers: [GroupCardService],
  standalone: false,
})
export class GroupCardComponent {
  public tabs: TabLink[] = [
    {
      state: 'settings.group.properties',
      header: 'settings.groups.card.tabs.properties',
    },
    {
      state: 'settings.group.users',
      header: 'settings.groups.card.tabs.users',
    },
    {
      state: 'settings.group.permissionSets',
      header: 'settings.groups.card.tabs.permissionSets',
    },
  ];

  constructor(
    public service: GroupCardService,
    public uiGlobals: UIRouterGlobals,
  ) {}
}
