import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
} from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ExpensesAccountFilterService } from '../core/expenses-account-filter.service';
import { ExpensesAccountFilterSettingsForm } from '../model/expenses-account-filter-settings-form.model';
import { FinancialAccount } from 'src/app/shared/models/entities/finance/financial-account.model';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'tmt-expenses-account-filter',
  templateUrl: './expenses-account-filter.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ExpensesAccountFilterComponent implements OnInit {
  /** Determines whether Project Expenses Calendar tab is opened or not. */
  @Input() isCalendar = true;

  /** Gets form group, consisting of {@link FinancialAccount.name} (which are not included in balance) controls. */
  public get formAccountNamesNotIncludedInBalance(): UntypedFormGroup {
    return this.form.controls
      .accountNamesNotIncludedInBalance as UntypedFormGroup;
  }

  /** Gets {@link FinancialAccount.name} (which are not included in balance) list. */
  public get accountNamesNotIncludedInBalance(): string[] {
    return Object.keys(
      this.form.controls.accountNamesNotIncludedInBalance.value,
    );
  }

  public form: UntypedFormGroup = this.fb.group({
    includeLaborCost: false,
    accountNamesNotIncludedInBalance: this.fb.group({}),
  });

  private _formUpdating = false;

  private destroyRef = inject(DestroyRef);

  constructor(
    private service: ExpensesAccountFilterService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.service.accountNamesNotIncludedInBalance$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((accounts) => this.onAccountsLoaded(accounts));

    this.form.valueChanges
      .pipe(
        filter(() => !this._formUpdating),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.service.saveSettings(this.form.getRawValue());
      });
  }

  /**
   * Handles loaded accounts subscription.
   * @param accounts List of {@link FinancialAccount} (which are not included in balance).
   * */
  private onAccountsLoaded(accounts: FinancialAccount[]): void {
    this._formUpdating = true;
    const settings = this.service.settings;
    const settingsForm = {} as ExpensesAccountFilterSettingsForm;
    let hasRemovedItems = false;

    settingsForm.includeLaborCost = settings.includeLaborCost;
    // Init account name items.
    settingsForm.accountNamesNotIncludedInBalance = {};
    accounts.forEach((account) => {
      settingsForm.accountNamesNotIncludedInBalance[account.name] = false;
    });

    // Fill account name items from settings.
    settings.includeFinAccountNamesNotIncludedInBalance.forEach((name) => {
      // Check, if settings have any account, which was included in balance.
      if (!accounts.find((account) => account.name === name)) {
        hasRemovedItems = true;
        return;
      }

      settingsForm.accountNamesNotIncludedInBalance[name] = true;
    });

    const ctrlIncludeLaborCost = this.form.controls.includeLaborCost;
    ctrlIncludeLaborCost.patchValue(settingsForm.includeLaborCost);
    // This control does not make sense outside of Project Expenses Calendar tab.
    if (!this.isCalendar) {
      ctrlIncludeLaborCost.disable();
    }

    // Add account name record controls dynamically, since they can only be determined in the runtime.
    Object.keys(settingsForm.accountNamesNotIncludedInBalance).forEach(
      (name) => {
        this.formAccountNamesNotIncludedInBalance.addControl(
          name,
          this.fb.control(settingsForm.accountNamesNotIncludedInBalance[name]),
        );
      },
    );

    // Update settings, if they have some accounts, included in balance.
    if (hasRemovedItems) {
      this.service.saveSettings(this.form.getRawValue(), false);
    }

    this._formUpdating = false;
  }
}
