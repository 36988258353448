import { Component, OnInit } from '@angular/core';
import { ProjectVersionsMergeModalService } from 'src/app/projects/project-versions/project-versions-merge-modal/core/project-versions-merge-modal.service';
import { NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

/**
 * Represents the MergeUpdateDetails Merge tool step content.
 * */
@Component({
  selector: 'wp-project-versions-merge-details',
  templateUrl: './project-versions-merge-details.component.html',
  styleUrls: ['./project-versions-merge-details.component.scss'],
  providers: [GridService],
  standalone: false,
})
export class ProjectVersionsMergeDetailsComponent implements OnInit {
  /** The selected tab. */
  public activeTab: string;

  /** The available tabs. */
  public tabs: any[] = [
    {
      header:
        'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.tabs.kpi',
      name: 'kpi',
    },
    {
      header:
        'projects.projectVersions.projectVersionsMergeModal.steps.mergeUpdateDetails.tabs.details',
      name: 'details',
    },
  ];

  constructor(public mergeModalService: ProjectVersionsMergeModalService) {}

  ngOnInit(): void {
    this.activeTab = this.tabs[0].name;
  }

  /**
   * The Tab change handler.
   * Changes the active tab.
   *
   * @param changeEvent The event object.
   * */
  public onNavChange(changeEvent: NgbNavChangeEvent) {
    this.activeTab = changeEvent.nextId;
  }
}
