import { Component, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { DataService } from 'src/app/core/data.service';

@Component({
  selector: 'wp-user-personal-schedule-modal',
  templateUrl: './user-personal-schedule-modal.component.html',
  standalone: false,
})
export class UserPersonalScheduleModalComponent {
  @Input() userId: string;
  @Input() readonly: boolean;
  @Input() usingSchedules: any[];

  public isSaving: boolean;
  private dataForSaving: any;

  constructor(
    private data: DataService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
  ) {}

  public dataChanges(data: any) {
    this.dataForSaving = data;
  }

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  public ok = () => {
    if (!this.data) {
      this.activeModal.close();
      return;
    }
    this.isSaving = true;

    this.data
      .collection('Users')
      .entity(this.userId)
      .action('WP.UpdatePersonalSchedule')
      .execute(this.dataForSaving)
      .subscribe({
        next: () => {
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.isSaving = false;
          this.notification.error(error.message);
        },
      });
  };
}
