<form [formGroup]="interactionForm" class="interaction-form" novalidate autocomplete="off">
  <div class="interaction-form__primary-content">
    <div class="interaction-form__types-date">
      <div tmtIndicator class="interaction-form__type">
        <label class="interaction-form__label">
          {{ 'components.interactionFormComponent.props.interactionType' | translate }}
        </label>
        <wp-select-box
          [allowNull]="false"
          [values]="interactionsService.interactionTypes$"
          formControlName="type"
          placeholder="{{
            'components.interactionFormComponent.props.interactionType' | translate
          }}"
        />
      </div>

      <div tmtIndicator class="interaction-form__date">
        <label class="interaction-form__label">
          {{ 'shared2.props.dateTime' | translate }}
        </label>
        <wp-date-box formControlName="date" [allowNull]="false" includeTime="true" />
      </div>

      <div class="interaction-form__type">
        <div
          radioGroup
          class="btn-group btn-group-toggle"
          name="radioBasic"
          formControlName="isPlanned"
        >
          <label radioLabel class="btn-default trim fw-normal mb-0" #notPlanned>
            <input radioButton type="radio" [value]="false" />{{
              'components.interactionFormComponent.props.actual' | translate
            }}
          </label>
          <label radioLabel class="btn-default trim fw-normal mb-0">
            <input radioButton type="radio" [value]="true" />{{
              'components.interactionFormComponent.props.planned' | translate
            }}
          </label>
        </div>
      </div>
    </div>

    <div class="interaction-form__description">
      <tmt-rich-editor-box
        [isAlwaysEditingMode]="true"
        [hasMentions]="false"
        formControlName="description"
        placeholder="{{ 'shared2.props.description' | translate }}"
      />
    </div>
  </div>

  <div class="interaction-form__secondary-content">
    <div tmtIndicator class="interaction-form__performer">
      <label class="interaction-form__label">
        {{ 'components.interactionFormComponent.props.performer' | translate }}
      </label>
      <wp-user-box
        [allowNull]="false"
        formControlName="performer"
        placeholder="{{ 'components.interactionFormComponent.props.performer' | translate }}"
      />
    </div>
    <div tmtIndicator class="interaction-form__client">
      <label class="interaction-form__label">
        {{ 'shared2.props.client' | translate }}
      </label>
      <wp-select-box
        #cascadeControl
        formControlName="organization"
        collection="Organizations"
        placeholder="{{ 'shared2.props.client' | translate }}"
      />
    </div>
    <div tmtIndicator class="interaction-form__deal">
      <label class="interaction-form__label">
        {{ 'shared2.props.deal' | translate }}
      </label>
      <wp-select-box
        #cascadeControl
        formControlName="deal"
        collection="Deals"
        placeholder="{{ 'shared2.props.deal' | translate }}"
      />
    </div>
    <div class="interaction-form__contacts">
      <label class="interaction-form__label">
        {{ 'components.interactionFormComponent.props.contacts' | translate }}
      </label>
      <tmt-multiselect-box
        #cascadeControl
        formControlName="interactionContacts"
        collection="Contacts"
        placeholder="{{ 'components.interactionFormComponent.props.contacts' | translate }}"
      />
    </div>
  </div>

  <div class="interaction-form__buttons-content">
    @if (this.interaction) {
      <button type="button" class="btn btn-light" (click)="cancel()" [disabled]="isSaving()">
        {{ 'shared2.actions.cancel' | translate }}
      </button>
    }
    <button
      type="button"
      class="btn btn-primary"
      (click)="save()"
      wpButtonSpinner
      [isBusy]="isSaving()"
    >
      {{ 'shared2.actions.save' | translate }}
    </button>
  </div>
</form>
