import {
  Component,
  OnInit,
  Input,
  ViewEncapsulation,
  ViewChild,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import {
  GridsterComponent,
  IGridsterOptions,
  IGridsterDraggableOptions,
} from 'angular2gridster';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LocalStorageService } from 'ngx-webstorage';
import { DashboardSettingsComponent } from '../settings/dashboard-settings.component';
import { AutosaveService } from 'src/app/shared/services/autosave.service';
import { DashboardService } from './dashboard.service';
import { AppService } from 'src/app/core/app.service';
import { ChromeService } from 'src/app/core/chrome.service';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { Dashboard } from 'src/app/shared/models/entities/analytics/dashboard.model';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { DatePeriod } from 'src/app/shared/models/entities/date-period.model';
import { CopyDashboardModalComponent } from './copy-dashboard-modal/copy-dashboard-modal.component';

@Component({
  selector: 'wp-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
  providers: [AutosaveService, DashboardService],
  // eslint-disable-next-line @angular-eslint/use-component-view-encapsulation
  encapsulation: ViewEncapsulation.None,
  standalone: false,
})
export class DashboardComponent implements OnInit {
  @Input() entityId: string;
  @ViewChild(GridsterComponent) gridster: GridsterComponent;

  public readonly: boolean;

  public gridsterOptions: IGridsterOptions = {
    lanes: 8,
    direction: 'vertical',
    dragAndDrop: true,
    resizable: true,
    useCSSTransforms: false,
    shrink: true,
  };

  public draggableOptions: IGridsterDraggableOptions = {
    handlerClass: 'drag',
  };

  public period = new UntypedFormControl(null);

  constructor(
    private modal: NgbModal,
    public service: DashboardService,
    private app: AppService,
    private chrome: ChromeService,
    private data: DataService,
    private actionService: ActionPanelService,
    public autosave: AutosaveService,
    private localStorageService: LocalStorageService,
    private modalService: NgbModal,
  ) {}

  /** Открыть свойства отчета. */
  public openSettings(): void {
    const ref = this.modal.open(DashboardSettingsComponent);
    (ref.componentInstance as DashboardSettingsComponent).dashboard =
      this.service.dashboard;
    ref.result.then(
      () => this.service.load(this.entityId),
      () => null,
    );
  }

  public saveName = (name: string) =>
    this.data
      .collection('Dashboards')
      .entity(this.service.dashboardId)
      .patch({ name });

  public reload() {
    this.service.reloadWidgets();
  }

  private updateUI(dashboard: Dashboard) {
    this.actionService.action('settings').isShown = !this.readonly;

    this.actionService.action('createWidget').isShown = !this.readonly;
    const publishIsAllowed = this.app.checkPermission(
      'DashboardPublish',
      'My',
      PermissionType.Execute,
    );

    this.actionService.action('publishDashboard').isShown =
      !this.readonly && !dashboard.isPublished && publishIsAllowed;
    this.actionService.action('callOfDashboard').isShown =
      !this.readonly && dashboard.isPublished && publishIsAllowed;
    this.actionService.action('copy').isShown = !this.readonly;

    this.setPasteWidgetButtonVisibility();
  }

  /** Sets visibility for paste widget button. */
  public setPasteWidgetButtonVisibility(): void {
    this.actionService.action('pasteWidgetFromStorage').isShown =
      this.localStorageService.retrieve(this.service.widgetStorageName) &&
      !this.readonly;
  }

  ngOnInit() {
    /* Скрыть кнопку перезагрузки для этого компонента */
    //this.actionService.hideReloader();

    // Установка главного меню.
    this.actionService.set([
      {
        title: 'analytics.dashboards.card.actions.settings.title',
        hint: 'analytics.dashboards.card.actions.settings.title',
        name: 'settings',
        iconClass: 'bi bi-gear',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.openSettings(),
      },
      {
        title: 'analytics.dashboards.card.actions.createWidget.title',
        hint: 'analytics.dashboards.card.actions.createWidget.title',
        name: 'createWidget',
        iconClass: 'bi bi-plus-lg bi-15',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.createWidget(),
      },
      {
        title: 'analytics.dashboards.card.actions.publishDashboard.title',
        hint: 'analytics.dashboards.card.actions.publishDashboard.title',
        name: 'publishDashboard',
        iconClass: 'bi bi-share',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.publishDashboard(),
      },
      {
        title: 'analytics.dashboards.card.actions.callOfDashboard.title',
        hint: 'analytics.dashboards.card.actions.callOfDashboard.title',
        name: 'callOfDashboard',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.callOfDashboard(),
      },
      {
        title: 'analytics.dashboards.card.actions.pasteWidgetFromStorage.title',
        hint: 'analytics.dashboards.card.actions.pasteWidgetFromStorage.title',
        name: 'pasteWidgetFromStorage',
        iconClass: 'bi bi-clipboard',
        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.pasteWidget(),
      },
    ]);

    this.actionService.setAdditional([
      {
        name: 'copy',
        title: 'analytics.dashboards.list.actions.copy.title',
        hint: 'analytics.dashboards.list.actions.copy.title',
        isBusy: false,
        isVisible: false,
        handler: () => this.openCopyModal(),
      },
    ]);

    this.service.load(this.entityId);

    this.service.dashboard$.subscribe((dashboard) => {
      this.readonly = !dashboard.editAllowed;
      if (this.readonly) {
        this.autosave.disabled = true;

        this.gridsterOptions.dragAndDrop = false;
        this.gridsterOptions.resizable = false;
      }
      this.updateUI(dashboard);

      // TODO Совместимость
      const datePeriod: DatePeriod = this.service.configuration
        .defaultViewPeriod
        ? {
            from: this.service.configuration.defaultViewPeriod.From,
            to: this.service.configuration.defaultViewPeriod.To,
            periodType: this.service.configuration.defaultViewPeriod.PeriodType,
          }
        : null;

      this.period.setValue(datePeriod, { emitEvent: false });
    });

    this.chrome.mainAreaSize$.subscribe(() => {
      if (this.gridster) {
        this.gridster.reflowGridster();
      }
    });

    this.period.valueChanges.subscribe(() => {
      this.autosave.saveLazy();
      setTimeout(() => this.service.reloadWidgets());
    });
  }

  /** Opens copy dashboard modal. */
  private openCopyModal(): void {
    const ref = this.modalService.open(CopyDashboardModalComponent);
    (ref.componentInstance as CopyDashboardModalComponent).dashboardId =
      this.entityId;
  }
}
