import { Component, Inject, Input, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { Directory } from 'src/app/settings-app/directories/model/directory.model';

@Component({
  selector: 'tmt-directory-properties-modal',
  templateUrl: './directory-properties-modal.component.html',
  standalone: false,
})
export class DirectoryPropertiesModalComponent implements OnInit {
  @Input() directory: Directory;
  @Input() entityId: string;
  @Input() readonly: string;

  public isSaving = false;
  public creationForm: UntypedFormGroup = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: [
      '',
      [Validators.required, Validators.maxLength(Constants.formCodeMaxLength)],
    ],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: true,
    isSystem: false,
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notificationService: NotificationService,
    private dataService: DataService,
    private stateService: StateService,
    private activeModal: NgbActiveModal,
  ) {}

  public ngOnInit(): void {
    if (this.directory) {
      this.creationForm.patchValue(this.directory);
    }
    if (this.readonly || this.creationForm.value.isSystem === true) {
      this.creationForm.disable();
    }
  }

  /**
   *  Returns modal header.
   *
   * @returns Modal header as string.
   */
  public getHeader(): string {
    return this.directory
      ? 'components.directoryPropertiesModalComponent.groups.editingHeader'
      : 'components.directoryPropertiesModalComponent.groups.creationHeader';
  }

  /** Returns action button name. */
  public getActionBtnName(): string {
    return this.directory ? 'shared2.actions.save' : 'shared2.actions.create';
  }

  /** Modal submit handler. */
  public ok(): void {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notificationService.warningLocal(
        'shared2.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving = true;

    const data = {
      name: this.creationForm.value.name,
      code: this.creationForm.value.code,
      description: this.creationForm.value.description,
      isActive: this.creationForm.value.isActive,
      isSystem: this.creationForm.value.isSystem,
    };

    if (this.directory) {
      this.dataService
        .collection('Directories')
        .entity(this.entityId)
        .patch(data)
        .subscribe({
          next: () => {
            this.notificationService.successLocal(
              'components.directoryPropertiesModalComponent.messages.saved',
            );
            this.isSaving = false;
            this.activeModal.close(data);
          },
          error: (error: Exception) => {
            this.notificationService.error(error.message);
            this.isSaving = false;
          },
        });
    } else {
      this.dataService
        .collection('Directories')
        .insert(data)
        .subscribe({
          next: (response) => {
            this.notificationService.successLocal(
              'components.directoryPropertiesModalComponent.messages.created',
            );
            this.stateService.go('settings.directory', {
              entityId: response.id,
            });
            this.isSaving = false;
            this.activeModal.close();
          },
          error: (error: Exception) => {
            this.notificationService.error(error.message);
            this.isSaving = false;
          },
        });
    }
  }

  /** Modal cancel handler. */
  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }
}
