import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Inject,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  FormBuilder,
  FormGroup,
  UntypedFormArray,
  UntypedFormGroup,
} from '@angular/forms';
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  forkJoin,
  Observable,
  of,
  switchMap,
  throwError,
} from 'rxjs';
import _ from 'lodash';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { KindToolbarComponent } from 'src/app/settings-app/lifecycle/card/kind-settings/kind-toolbar/kind-toolbar.component';
import { LifecycleCardService } from 'src/app/settings-app/lifecycle/card/lifecycle-card.service';
import { TransitionFormService } from 'src/app/settings-app/lifecycle/card/state-modal/transition-modal/transition-form/transition-form.service';
import { TransitionsCellComponent } from 'src/app/settings-app/lifecycle/card/transitions-cell/transitions-cell.component';
import { TransitionsService } from 'src/app/settings-app/lifecycle/card/transitions.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridColumnType,
  GridCommandColumn,
  GridComponentColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { MermaidSchemaService } from 'src/app/shared-features/mermaid-schema/mermaid-schema.service';
import { Constants } from 'src/app/shared/globals/constants';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  Lifecycle,
  StateConfiguration,
} from 'src/app/shared/models/entities/settings/lifecycles/lifecycle.model';
import { MetaEntity } from 'src/app/shared/models/entities/settings/metamodel.model';
import { State } from 'src/app/shared/models/entities/state.model';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'tmt-kind-settings',
  templateUrl: './kind-settings.component.html',
  providers: [GridService, MermaidSchemaService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class KindSettingsComponent implements OnInit {
  @Input() private entityType: string;
  @Input() public readonly: boolean;
  @Input() public lifecycleData: Lifecycle;

  public isLoading = signal<boolean>(true);
  public entity: MetaEntity;
  public gridOptions: GridOptions = {
    toolbar: KindToolbarComponent,
    selectionType: SelectionType.row,
    rowCommands: [
      {
        name: 'edit',
        label: 'shared.actions.edit',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: FormGroup) => {
          this.editSelectedConfiguration(formGroup.getRawValue());
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: () => !this.readonly,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.deleteSelectedConfiguration(formGroup.getRawValue());
        },
      },
    ],
    commands: [
      {
        name: 'edit',
        allowedFn: (id: string) => !!id,
        handlerFn: () => {
          this.editSelectedConfiguration(this.gridService.selectedGroupValue);
        },
      },
      {
        name: 'delete',
        allowedFn: (id: string) => !!id,
        handlerFn: () => {
          this.deleteSelectedConfiguration(this.gridService.selectedGroupValue);
        },
      },
      {
        name: 'moveUp',
        allowedFn: (id: string) => !!id,
        handlerFn: () => {
          this.decreaseConfigStateIndex(this.gridService.selectedGroupValue);
        },
      },
      {
        name: 'moveDown',
        allowedFn: (id: string) => !!id,
        handlerFn: () => {
          this.increaseConfigStateIndex(this.gridService.selectedGroupValue);
        },
      },
    ],
    view: {
      name: 'kindStates',
      columns: [
        <GridCommandColumn>{
          name: 'name',
          command: 'edit',
          header: 'shared2.props.name',
          hint: 'shared2.props.name',
          type: GridColumnType.Command,
        },
        {
          name: 'isInitial',
          width: '100px',
          header: 'components.kindSettingsComponent.props.isInitial',
          hint: 'components.kindSettingsComponent.props.isInitial',
          type: GridColumnType.Boolean,
        },
        <GridComponentColumn>{
          name: 'transitions',
          width: '650px',
          type: GridColumnType.Component,
          component: TransitionsCellComponent,
          header: 'settings.lifecycles.card.columns.transitions.header',
          hint: 'settings.lifecycles.card.columns.transitions.hint',
        },
      ],
    },
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject('entityId') public entityId: string,
    public lifecycleCardService: LifecycleCardService,
    private cdr: ChangeDetectorRef,
    private formBuilder: FormBuilder,
    private gridService: GridService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private transitionsService: TransitionsService,
    private transitionFormService: TransitionFormService,
    private mermaidSchemaService: MermaidSchemaService,
    private dataService: DataService,
    private blockUIService: BlockUIService,
  ) {}

  ngOnInit(): void {
    this.initSubscriptions();
    this.lifecycleCardService.loadMetaEntity(this.entityType);
  }

  /**
   * Adds new state to lifecycle.
   *
   * @param state adding state.
   */
  private addState(state: State): void {
    const foundState = this.lifecycleData.states.find(
      (item) => item.id === state.id,
    );
    foundState.isInitial = !this.lifecycleCardService.states.length
      ? true
      : false;
    const group = this.initNewStateFormGroup();
    group.patchValue(foundState);
    group.get('index').setValue(this.lifecycleCardService.states.length);
    group.controls.style.setValue({
      name: foundState.name,
      code: foundState.code,
      style: foundState.style,
    });

    const data = this.prepareSavingData(
      group.value,
      this.lifecycleCardService.states.length,
    );
    this.blockUIService.start();
    this.lifecycleCardService
      .setConfiguration(group.value.id, data)
      .pipe(
        catchError((error) => {
          this.notificationService.error(error.message);
          this.blockUIService.stop();
          return throwError(() => error);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.lifecycleCardService.states.push(group);
        this.lifecycleData.stateConfigurations.push(data);
        this.initAvailableStateOptions();
        this.blockUIService.stop();
        this.gridService.detectChanges();
        this.cdr.detectChanges();
      });
  }

  /** Loads configuration. */
  private loadConfiguration(): void {
    this.isLoading.set(true);
    this.lifecycleCardService.kindForm.get('states').reset();
    this.lifecycleCardService.states.clear();
    const transitionsRequests: Record<string, Observable<any>> = {};
    this.lifecycleData.stateConfigurations
      .filter((item) => item.entityKindId === this.lifecycleCardService.kindId)
      .sort((a, b) => a.index - b.index)
      .forEach((configuration, index) => {
        transitionsRequests[configuration.stateId] =
          this.lifecycleCardService.getConfiguration(configuration.stateId);

        const state = this.lifecycleData.states.find(
          (state) => state.id === configuration.stateId,
        );
        state.isInitial = configuration.isInitial;
        state.index = index;
        const group = this.initNewStateFormGroup();
        group.patchValue(state);
        group.controls.style.setValue({
          name: state.name,
          code: state.code,
          style: state.style,
        });
        this.lifecycleCardService.states.push(group);
      });

    if (!Object.keys(transitionsRequests).length) {
      this.initAvailableStateOptions();
      this.isLoading.set(false);
      this.gridService.detectChanges();
      return;
    }

    forkJoin(transitionsRequests)
      .pipe(
        catchError((error) => {
          this.notificationService.error(error.message);
          return of([]);
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: (allTransitions) => {
          this.lifecycleCardService.states.controls.map((state: FormGroup) => {
            const stateTransitions = allTransitions[state.value.id];
            if (stateTransitions !== null) {
              stateTransitions?.forEach((transition) => {
                const transitionGroup =
                  this.transitionsService.getTransitionGroup(transition);
                const transitionsFormArray = state.controls[
                  'transitions'
                ] as UntypedFormArray;
                transitionsFormArray.push(transitionGroup);
              });
              state.disable();
            }
          });

          this.transitionFormService.setAllowedTransitionFormProperties(
            this.entityId,
            this.lifecycleData.entityType,
          );

          this.initAvailableStateOptions();
          this.gridService.detectChanges();
          this.cdr.detectChanges();
          this.isLoading.set(false);
        },
        error: (error: Exception) => {
          this.isLoading.set(false);
          this.notificationService.error(error.message);
        },
      });
  }

  /** Inits new state form group. */
  private initNewStateFormGroup(): UntypedFormGroup {
    return this.formBuilder.group({
      id: '',
      index: null,
      name: '',
      code: '',
      style: null,
      isInitial: false,
      transitions: this.formBuilder.array([]),
    });
  }

  /**
   * Edits selected configuration.
   *
   * @param formGroupValue selected group.
   */
  private editSelectedConfiguration(formGroupValue: StateConfiguration): void {
    this.lifecycleCardService.editKindState(
      this.entityId,
      formGroupValue.id,
      formGroupValue.isInitial,
      formGroupValue.index,
    );
  }

  /**
   * Deletes selected configuration state.
   *
   * @param formGroupValue selected group value.
   */
  private deleteSelectedConfiguration(
    formGroupValue: StateConfiguration,
  ): void {
    this.messageService
      .confirmLocal('settings.lifecycles.card.props.state.deleteConfirmation')
      .then(
        () => {
          this.blockUIService.start();
          this.lifecycleCardService
            .setConfiguration(formGroupValue.id, null)
            .pipe(
              switchMap(() => {
                const observables: Observable<StateConfiguration>[] = [];
                /** Updates indexes for configs with indexes bigger than removing element's index. */
                for (
                  let i = formGroupValue.index + 1;
                  i < this.lifecycleCardService.states.length;
                  i++
                ) {
                  observables.push(
                    this.lifecycleCardService.setConfiguration(
                      this.lifecycleCardService.states.at(i).getRawValue().id,
                      this.prepareSavingData(
                        this.lifecycleCardService.states.at(i).getRawValue(),
                        i - 1,
                      ),
                    ),
                  );
                }

                return observables.length ? forkJoin(observables) : of(null);
              }),
              switchMap(() => {
                if (
                  formGroupValue.isInitial &&
                  this.lifecycleCardService.states.length > 1
                ) {
                  const newInitial = this.lifecycleCardService.states.controls
                    .find((state) => state.value.id !== formGroupValue.id)
                    .getRawValue();
                  newInitial.isInitial = true;
                  return this.lifecycleCardService.setConfiguration(
                    newInitial.id,
                    this.prepareSavingData(newInitial, 0),
                  );
                } else {
                  return of(null);
                }
              }),
              takeUntilDestroyed(this.destroyRef),
            )
            .subscribe({
              next: () => {
                this.lifecycleCardService.states.removeAt(formGroupValue.index);
                this.deleteStateFromConfig(formGroupValue.id);
                this.initAvailableStateOptions();
                this.lifecycleCardService.states.controls.forEach(
                  (state, index) => state.get('index').setValue(index),
                );
                if (
                  formGroupValue.isInitial &&
                  this.lifecycleCardService.states.length
                ) {
                  this.lifecycleCardService.states
                    .at(0)
                    .get('isInitial')
                    .setValue(true);
                }
                this.blockUIService.stop();
              },
              error: (error: Exception) => {
                this.notificationService.error(error.message);
                this.blockUIService.stop();
              },
            });
        },
        () => null,
      );
  }

  /**
   * Deletes state from configuration after removing used state.
   *
   * @param id removed state id.
   */
  private deleteStateFromConfig(id: string): void {
    const configIndex = this.lifecycleData.stateConfigurations.findIndex(
      (config) =>
        config.entityKindId === this.lifecycleCardService.kindId &&
        config.stateId === id,
    );
    this.lifecycleData.stateConfigurations.splice(configIndex, 1);
  }

  /** Inits available for adding states options. */
  private initAvailableStateOptions(): void {
    this.lifecycleCardService.availableStatesOptions =
      this.lifecycleData.states.filter(
        (item) =>
          !this.lifecycleCardService.states.controls.some(
            (state) => state.value.id === item.id,
          ),
      );
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.lifecycleCardService.isShowKind$
      .pipe(
        switchMap((value) => {
          if (value) {
            return this.dataService
              .collection(this.lifecycleCardService.entityTypeToCollection)
              .query({ select: ['id', 'name'] })
              .pipe(
                catchError((error) => {
                  this.notificationService.error(error.message);
                  return of([]);
                }),
              );
          } else {
            return of([]);
          }
        }),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((options: NamedEntity[]) => {
        let value = options.length ? options[0] : null;
        if (
          this.lifecycleCardService.kindId &&
          this.lifecycleCardService.entityTypeToCollection &&
          options.find(
            (option) => option.id === this.lifecycleCardService.kindId,
          )
        ) {
          value = this.lifecycleCardService.kindForm.value.kind;
        }
        this.lifecycleCardService.kindForm.get('kind').setValue(value);
      });

    this.lifecycleCardService.states.valueChanges
      .pipe(
        debounceTime(Constants.mainFormControlChangedDelayTime),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        const states = this.lifecycleCardService.states
          .getRawValue()
          .map((state) => ({
            ...state,
            style: state.style.style,
          }));
        this.mermaidSchemaService.updateSchema({ states });
      });

    this.lifecycleCardService.kindForm
      .get('kind')
      .valueChanges.pipe(
        debounceTime(Constants.mainFormControlChangedDelayTime),
        distinctUntilChanged(),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.loadConfiguration();
      });

    this.lifecycleCardService.kindForm
      .get('addingState')
      .valueChanges.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (value) {
          this.addState(value);
          this.initAvailableStateOptions();
        }
        this.lifecycleCardService.kindForm
          .get('addingState')
          .setValue(null, { emitEvent: false });
      });
  }

  /**
   * Decreases configuration state index.
   *
   * @param formGroupValue selected form group value.
   */
  private decreaseConfigStateIndex(formGroupValue: StateConfiguration): void {
    const index = formGroupValue.index;
    if (!index || this.blockUIService.isActive) {
      return;
    }

    this.blockUIService.start();
    this.lifecycleCardService
      .setConfiguration(
        formGroupValue.id,
        this.prepareSavingData(formGroupValue, index - 1),
      )
      .pipe(
        switchMap(() =>
          this.lifecycleCardService
            .setConfiguration(
              this.lifecycleCardService.states.at(index - 1).getRawValue().id,
              this.prepareSavingData(
                this.lifecycleCardService.states.at(index - 1).getRawValue(),
                index,
              ),
            )
            .pipe(
              catchError((error) => {
                this.notificationService.error(error.message);
                this.blockUIService.stop();
                return throwError(() => error);
              }),
            ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          const currentGroup = this.lifecycleCardService.states.at(index);
          this.lifecycleCardService.states.removeAt(index);
          this.lifecycleCardService.states.insert(index - 1, currentGroup);
          this.lifecycleCardService.states.controls.forEach((state, index) =>
            state.get('index').setValue(index),
          );
          this.updateConfigIndexes(formGroupValue, index, index - 1);
          this.blockUIService.stop();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.blockUIService.stop();
        },
      });
  }

  /**
   * Increases configuration state index.
   *
   * @param formGroupValue selected form group value.
   */
  private increaseConfigStateIndex(formGroupValue: StateConfiguration): void {
    const index = formGroupValue.index;
    if (
      index === this.lifecycleCardService.states.length - 1 ||
      this.blockUIService.isActive
    ) {
      return;
    }

    this.blockUIService.start();
    this.lifecycleCardService
      .setConfiguration(
        formGroupValue.id,
        this.prepareSavingData(formGroupValue, index + 1),
      )
      .pipe(
        switchMap(() =>
          this.lifecycleCardService
            .setConfiguration(
              this.lifecycleCardService.states.at(index + 1).getRawValue().id,
              this.prepareSavingData(
                this.lifecycleCardService.states.at(index + 1).getRawValue(),
                index,
              ),
            )
            .pipe(
              catchError((error) => {
                this.notificationService.error(error.message);
                this.blockUIService.stop();
                return throwError(() => error);
              }),
            ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          const currentGroup = this.lifecycleCardService.states.at(index);
          this.lifecycleCardService.states.removeAt(index);
          this.lifecycleCardService.states.insert(index + 1, currentGroup);
          this.lifecycleCardService.states.controls.forEach((state, index) =>
            state.get('index').setValue(index),
          );
          this.updateConfigIndexes(formGroupValue, index, index + 1);
          this.blockUIService.stop();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
          this.blockUIService.stop();
        },
      });
  }

  /**
   * Updates config indexes after moving.
   *
   * @param formGroupValue moved state form group value.
   * @param prevIndex previous position index.
   * @param currentIndex current position index.
   */
  private updateConfigIndexes(
    formGroupValue: StateConfiguration,
    prevIndex: number,
    currentIndex: number,
  ): void {
    this.lifecycleData.stateConfigurations.find(
      (config) =>
        config.entityKindId === this.lifecycleCardService.kindId &&
        config.stateId === formGroupValue.id,
    ).index = currentIndex;
    this.lifecycleData.stateConfigurations.find(
      (config) =>
        config.entityKindId === this.lifecycleCardService.kindId &&
        config.stateId ===
          this.lifecycleCardService.states.at(prevIndex).getRawValue().id,
    ).index = prevIndex;
  }

  /**
   * Prepares config data before saving.
   * @param formGroupValue form group value.
   * @param index state index.
   */
  private prepareSavingData(
    formGroupValue: StateConfiguration,
    index: number,
  ): StateConfiguration {
    const data: StateConfiguration = {
      stateId: formGroupValue.id,
      isInitial: formGroupValue.isInitial,
      entityKindId: this.lifecycleCardService.kindId,
      index,
      transitions: this.transitionsService.prepareTransitionsDTO(
        formGroupValue.transitions,
      ),
    };
    this.lifecycleCardService.prepareSavingLocalization(data.transitions);

    return data;
  }
}
