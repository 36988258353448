import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { ProjectCardService } from '../core/project-card.service';
import { PlannerCommandService } from 'src/app/shared-features/planner/core/planner-command.service';
import { ResourceRequirementsDataService } from 'src/app/projects/card/project-resource-requirements/core/resource-requirements-data.service';
import { ResourceRequirementsService } from 'src/app/projects/card/project-resource-requirements/core/resource-requirements.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { UntypedFormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ResourceRequestGroup } from 'src/app/projects/card/project-resource-requirements/models/view-data/resource-group.model';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';

@Component({
  selector: 'wp-project-resource-requirements',
  templateUrl: './project-resource-requirements.component.html',
  styleUrls: ['./project-resource-requirements.component.scss'],
  providers: [
    ResourceRequirementsService,
    ResourceRequirementsDataService,
    ScheduleNavigationService,
    SavingQueueService,
    PlannerCommandService,
    FreezeTableService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectResourceRequirementsComponent implements OnInit {
  @Input() entityId: string;
  @Input() readonly: boolean;
  @Input() periodOffset: number;

  public leftTableWidth = 410;
  public selectAllControl = new UntypedFormControl(false);
  public selectedControlCount: number;

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public projectCardService: ProjectCardService,
    public service: ResourceRequirementsService,
    public dataService: ResourceRequirementsDataService,
    public navigationService: ScheduleNavigationService,
    private cdr: ChangeDetectorRef,
  ) {}

  public ngOnInit(): void {
    this.service.init();
    this.dataService.init();

    this.selectAllControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value: boolean) => {
        this.dataService.groups.forEach((group) => {
          group.states.selected = value;
        });

        this.selectedControlCount = this.dataService.groups.filter(
          (g) => g.states.selected,
        ).length;

        this.service.emitChanges();
      });

    this.service.changes$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.cdr.markForCheck();
      });

    this.service.selected$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.selectedControlCount = this.dataService.groups.filter(
          (g) => g.states.selected,
        ).length;

        this.selectAllControl.setValue(
          !this.dataService.groups.some((g) => !g.states.selected),
          { emitEvent: false },
        );
      });
  }

  public trackById = (index: number, row: any): string => row.id;

  /** Creates resource requests */
  public createResourceRequests(): void {
    this.selectedControlCount = 0;
    this.service.createResourceRequests();
  }

  /**
   * Toggles expand state of  request group.
   *
   * @param group group for toggle.
   */
  public toggleRequestGroupExpand(group: ResourceRequestGroup): void {
    group.isExpanded = !group.isExpanded;
  }
}
