<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      <div class="form-group">
        <label>{{ 'shared2.filters.role.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="role"
          collection="Roles"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.role.ph' | translate }}"
        />
      </div>
      <div class="form-group">
        <label>{{ 'shared2.filters.competence.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="competence"
          collection="Competences"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.competence.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.level.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="level"
          collection="Levels"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.level.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.grade.value' | translate }}</label>
        <wp-select-box
          #cascadeControl
          formControlName="grade"
          collection="Grades"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.grade.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.skills.value' | translate }}</label>
        <tmt-multiselect-box
          collection="Skills"
          formControlName="skills"
          placeholder="{{ 'shared2.filters.skills.ph' | translate }}"
          [allowInactive]="service.allowInactive$ | async"
        />
      </div>
    </div>

    <div class="col-6">
      <div class="form-group">
        <label>{{ 'shared2.filters.location.value' | translate }}</label>
        <wp-select-box
          formControlName="location"
          collection="Locations"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.location.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.resourcePool.value' | translate }}</label>
        <tmt-hierarchical-box
          formControlName="resourcePool"
          collection="ResourcePools"
          [allowInactive]="service.allowInactive$ | async"
          parentIdKey="leadResourcePoolId"
          placeholder="{{ 'shared2.filters.resourcePool.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.legalEntity.value' | translate }}</label>
        <wp-select-box
          formControlName="legalEntity"
          collection="LegalEntities"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.legalEntity.ph' | translate }}"
        />
      </div>

      <div class="form-group">
        <label>{{ 'shared2.filters.department.value' | translate }}</label>
        <tmt-hierarchical-box
          collection="Departments"
          formControlName="department"
          parentIdKey="leadDepartmentId"
          [allowInactive]="service.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.department.ph' | translate }}"
        />
      </div>
      <div class="form-group">
        <label>{{ 'shared2.filters.supervisor.value' | translate }}</label>
        <wp-user-box
          [allowInactive]="service.allowInactive$ | async"
          formControlName="supervisor"
          placeholder="{{ 'shared2.filters.supervisor.ph' | translate }}"
        />
      </div>
    </div>
  </div>
</form>
