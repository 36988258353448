import { Component } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'wp-number-box-test',
  templateUrl: './number-box-test.component.html',
  standalone: false,
})
export class NumberBoxTestComponent {
  form = this.fb.group({
    integer: [null, Validators.required],
    currency: [null, Validators.required],
    work: [null, Validators.required],
    precision: [null],
    decimal: [null, Validators.required],
    decimalWithPrecision: [null, Validators.required],
    percent: [null, Validators.required],
  });

  public formInputParams = this.fb.group({
    readonly: [false],
  });

  readonly labels = {
    btnDisable: 'Disable',
    btnEnable: 'Enable',
    btnSetValue: 'Set value',
    checkboxReadonly: 'Readonly',
    formValue: 'Form value:',
    controlIsValid: 'Form control is valid:',
    numberBoxInteger: 'NumberBox (Integer)',
    numberBoxDecimal: 'NumberBox (Decimal)',
    numberBoxDecimalWithPrecision: 'NumberBox (Decimal) with precision',
    numberBoxCurrency: 'NumberBox (Currency)',
    numberBoxWork: 'NumberBox (Work)',
    numberBoxPercent: 'NumberBox (Percent)',
  };

  constructor(private fb: UntypedFormBuilder) {}

  setValue() {
    this.form.setValue({
      integer: 1,
      currency: 2,
      work: 3,
      decimal: 4,
      percent: 5,
      precision: 6,
      decimalWithPrecision: 7,
    });
  }
}
