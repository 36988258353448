import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { Exception } from 'src/app/shared/models/exception';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { DateTime } from 'luxon';
import { NavigationService } from 'src/app/core/navigation.service';
import { HistoryLogEntry } from 'src/app/shared/models/entities/settings/history-log-entry.model';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'tmt-history-log-card',
  templateUrl: './history-log-card.component.html',
  standalone: false,
})
export class HistoryLogCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public state: CardState;

  logEntry: HistoryLogEntry;
  destroyed$ = new Subject<void>();
  name$ = new Subject<string>();

  constructor(
    public appService: AppService,
    private data: DataService,
    private actionService: ActionPanelService,
    private notification: NotificationService,
    private navigationService: NavigationService,
  ) {}

  ngOnInit() {
    this.actionService.set([]);
    this.load();

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.load());
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }

  private load() {
    this.state = CardState.Loading;

    this.data
      .collection('HistoryLogEntries')
      .entity(this.entityId)
      .get<HistoryLogEntry>({
        expand: {
          user: { select: ['id', 'name'] },
        },
      })
      .subscribe({
        next: (logEntry: HistoryLogEntry) => {
          this.logEntry = logEntry;
          const name = `${logEntry.entityType} / ${logEntry.type}`;
          this.name$.next(name);

          this.navigationService.addRouteSegment({
            id: this.entityId,
            title: name,
          });

          this.state = CardState.Ready;
        },
        error: (error: Exception) => {
          this.state = CardState.Error;
          this.notification.error(error.message);
        },
      });
  }
}
