<form class="modal-form h-100 d-flex flex-column" novalidate [formGroup]="form">
  <div class="modal-header">
    <h3 *ngIf="!form.getRawValue().name" class="modal-title">
      {{ 'shared.noName' | translate }}
    </h3>
    <h3 *ngIf="form.getRawValue().name" class="modal-title trim">
      {{ form.getRawValue().name }}
    </h3>
    <button
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs mb-15">
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'shared2.groups.main' | translate }}</a>
        <ng-template ngbNavContent>
          <div class="form-group" tmtIndicator>
            <label>{{ 'shared.props.name' | translate }}</label>
            <wp-text-box
              formControlName="name"
              [maxLength]="constants.formNameMaxLength"
              placeholder="{{ 'shared2.props.taskName' | translate }}"
              [propagationMode]="propagationMode.onExitFromEditing"
            ></wp-text-box>
          </div>

          <table>
            <tr>
              <td>
                <div class="form-group pe-3" tmtIndicator>
                  <label>{{ 'projects.planStart' | translate }}</label>
                  <wp-date-box
                    formControlName="startDate"
                    [checkIsHighlighted]="scheduleService.checkNonWorkingDay"
                    [allowNull]="false"
                  ></wp-date-box>
                </div>
              </td>
              <td>
                <div class="form-group pe-3" tmtIndicator>
                  <label>{{ 'projects.planEnd' | translate }}</label>
                  <wp-date-box
                    formControlName="endDate"
                    [checkIsHighlighted]="scheduleService.checkNonWorkingDay"
                    [readonly]="isSummaryTask"
                    [allowNull]="false"
                  ></wp-date-box>
                </div>
              </td>
              <td>
                <div class="form-group" tmtIndicator>
                  <div
                    class="form-group"
                    title="{{ 'shared2.props.projectTaskDuration.hint' | translate }}"
                  >
                    <label>{{ 'shared2.props.projectTaskDuration.value' | translate }}</label>
                    <tmt-duration-box
                      class="w-100"
                      formControlName="duration"
                      [allowNull]="false"
                      [readonly]="isSummaryTask"
                    ></tmt-duration-box>
                  </div>
                </div>
              </td>
            </tr>
          </table>

          <div class="form-group">
            <label>{{ 'shared2.props.estimatedHours' | translate }}</label>
            <wp-number-box
              formControlName="estimatedHours"
              [propagationMode]="propagationMode.onExitFromEditing"
              [type]="'work'"
              [precision]="1"
            ></wp-number-box>
          </div>

          <div class="form-group">
            <label>{{ 'shared.props.description' | translate }}</label>
            <wp-multiline-text-box
              formControlName="description"
              placeholder="{{ 'shared.props.description' | translate }}"
              rows="2"
              [propagationMode]="propagationMode.onExitFromEditing"
            >
            </wp-multiline-text-box>
          </div>

          <div
            class="form-check"
            title="{{ 'components.taskCardModalComponent.props.trackingAllowed' | translate }}"
          >
            <input
              formControlName="allowTimeEntry"
              type="checkbox"
              class="form-check-input"
              id="allowTimeEntry"
            />
            <label class="form-check-label" for="allowTimeEntry">
              {{ 'components.taskCardModalComponent.props.trackingAllowed' | translate }}
            </label>
          </div>

          @if (this.task.leadTaskId) {
            <div title="{{ 'shared2.props.completed' | translate }}">
              <tmt-checkbox
                formControlName="isActive"
                id="isActive"
                label="{{ 'shared2.props.completed' | translate }}"
                [reversed]="true"
              />
            </div>
          }

          <div class="form-check" title="{{ 'shared2.props.completed.milestone' | translate }}">
            <input
              formControlName="isMilestone"
              type="checkbox"
              class="form-check-input"
              id="isMilestone"
            />
            <label class="form-check-label" for="isMilestone">
              {{ 'shared2.props.milestone' | translate }}
            </label>
          </div>

          <div class="mt-2">
            <label class="group">{{ 'shared2.props.dependencies' | translate }}</label>
            <div *ngIf="!readonly" class="toolbar-container pt-0">
              <div class="group">
                <button
                  type="button"
                  class="btn btn-default"
                  title="{{ 'shared.actions.addLine' | translate }}"
                  [disabled]="
                    !this.gridService.canBeExecuted('create', gridService.selectedGroup$.getValue())
                  "
                  (click)="gridService.execute('create')"
                  data-test="addLine"
                >
                  <i class="bi bi-plus-lg bi-15" aria-hidden="true"></i>
                  {{ 'shared.actions.addLine' | translate }}
                </button>
                <button
                  type="button"
                  class="btn btn-default"
                  (click)="gridService.execute('delete', gridService.selectedGroup$.getValue())"
                  [disabled]="
                    !gridService.canBeExecuted('delete', gridService.selectedGroup$.getValue())
                  "
                  data-test="delete"
                >
                  <span class="bi bi-trash3"></span>
                </button>
              </div>
            </div>
            <tmt-grid [formArray]="dependencies" [options]="gridOptions" />
          </div>
        </ng-template>
      </li>
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'shared2.props.assignments' | translate }}</a>
        <ng-template ngbNavContent>
          <tmt-task-assignments
            [task]="task"
            [assignmentsFormGroup]="form?.controls.projectTaskAssignments"
            [readonly]="readonly"
          ></tmt-task-assignments>
        </ng-template>
      </li>
      <li [ngbNavItem]>
        <a ngbNavLink>{{ 'shared2.groups.advanced' | translate }}</a>
        <ng-template ngbNavContent>
          <ng-container *ngIf="autoPlanning">
            <div class="form-group">
              <label class="control-label">
                {{ 'shared2.props.type' | translate }}
              </label>
              <wp-select-box
                formControlName="type"
                [values]="taskTypes"
                [allowNull]="false"
                placeholder="{{ 'shared2.props.type' | translate }}"
              ></wp-select-box>
            </div>
            <div class="form-check" title="{{ 'shared2.props.effortDriven' | translate }}">
              <input
                formControlName="effortDriven"
                type="checkbox"
                class="form-check-input"
                id="effortDriven"
              />
              <label class="form-check-label" for="effortDriven">
                {{ 'shared2.props.effortDriven' | translate }}
              </label>
            </div>
          </ng-container>
          <wp-custom-fields
            columnClasses="col-12"
            [isVisibleHr]="autoPlanning"
            [formGroup]="form"
            [entityType]="'ProjectTask'"
            [propagationMode]="propagationMode.onExitFromEditing"
          />
        </ng-template>
      </li>
    </ul>
    <div [ngbNavOutlet]="nav" class="tab-content"></div>
  </div>
</form>
