import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Inject,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { UntypedFormBuilder } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-billing-summary-filter',
  templateUrl: './billing-summary-filter.component.html',
  styleUrl: './billing-summary-filter.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BillingSummaryFilterComponent implements OnInit, FilterDetails {
  @Input() values: any;

  public detailsForm = this.fb.group({
    period: [null],
    states: [null],
    withoutInvoices: false,
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    @Inject(VIEW_NAME) public viewName: string,
    public service: FilterService,
    private fb: UntypedFormBuilder,
  ) {}

  ngOnInit(): void {
    this.service.resetValues$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.detailsForm.reset();
      });

    this.detailsForm.patchValue(this.service.values);
    this.detailsForm.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const values = Object.assign(
          this.service.values,
          this.detailsForm.value,
        );
        this.service.changeValues(values);
      });
  }
}
