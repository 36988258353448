import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { Exception } from 'src/app/shared/models/exception';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { ProjectCostCenter } from 'src/app/projects/card/project-cost-centers/models/project-cost-center.model';

@Injectable()
export class ExpensesRequestService {
  private projectList = new BehaviorSubject<NamedEntity[]>([]);
  public projectList$ = this.projectList.asObservable();

  public loadingState$ = new BehaviorSubject<boolean>(false);
  public setProject$ = new Subject<boolean>();

  private readonly projectCollection = this.dataService.collection('Projects');

  constructor(
    private dataService: DataService,
    private messageService: MessageService,
  ) {}

  public loadUserInfoProjects(userId: string): void {
    if (!userId) {
      return;
    }

    setTimeout(() => this.loadingState$.next(true)); // TODO: for what?

    this.projectCollection
      .function('GetProjectsInfo')
      .get<NamedEntity[]>(
        { userId },
        {
          orderBy: 'name',
        },
      )
      .subscribe({
        next: (projects) => {
          this.loadingState$.next(false);
          this.projectList.next(projects);
        },
        error: (error: Exception) => {
          this.messageService.error(error.message);
          this.loadingState$.next(false);
        },
      });
  }

  /**
   * Gets expense project cost centers.
   * @param userId id of user.
   * @param projectId id of selected project.
   * @returns project cost centers observable.
   */
  public getUserProjectCostCenters(
    userId: string,
    projectId: string,
  ): Observable<ProjectCostCenter[]> {
    return this.projectCollection
      .entity(projectId)
      .function('GetUserCostCenters')
      .get<ProjectCostCenter[]>({ userId });
  }
}
