import { Component, Injector, Input, OnDestroy, OnInit } from '@angular/core';
import {
  StateService,
  TransitionService,
  UIRouterGlobals,
} from '@uirouter/core';
import { FormControl } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { Project } from 'src/app/shared/models/entities/projects/project.model';
import { ProjectSettingsComponent } from '../settings/project-settings.component';
import { ProjectCardService } from './core/project-card.service';
import { AppService } from 'src/app/core/app.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { ProjectVersion } from 'src/app/shared/models/entities/projects/project-version.model';
import { ProjectVersionsService } from 'src/app/projects/project-versions/project-versions.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { ProjectVersionState } from 'src/app/shared/models/enums/project-version-state.enum';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { ScheduleService } from 'src/app/core/schedule.service';
import { OverviewKpiService } from 'src/app/shared/components/features/overview/overview-kpi/core/overview-kpi.service';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import { MessageService } from 'src/app/core/message.service';
import { CoManagersModalComponent } from 'src/app/shared/components/co-managers-modal/co-managers-modal.component';

@Component({
  selector: 'wp-project-card',
  templateUrl: './project-card.component.html',
  styleUrls: ['./project-card.component.scss'],
  providers: [
    LifecycleService,
    { provide: META_ENTITY_TYPE, useValue: 'Project' },
    ProjectCardService,
    ProjectVersionsService,
    ProjectVersionCardService,
    OverviewKpiService,
    ScheduleService,
    ScheduleNavigationService,
  ],
  standalone: false,
})
export class ProjectCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;
  project: Project;
  projectTotal: any;

  projectVersions: ProjectVersion[] = [];
  projectVersionCtrl = new FormControl<ProjectVersion>(null);
  tabs: TabLink[] = [];
  private subscriptions: Subscription[] = [];
  private onTransitionSuccessListener;

  constructor(
    public uiRouterGlobals: UIRouterGlobals,
    public versionService: ProjectVersionsService,
    public versionCardService: ProjectVersionCardService,
    public service: ProjectCardService,
    public stateService: StateService,
    private app: AppService,
    private modal: NgbModal,
    private notification: NotificationService,
    private actionPanelService: ActionPanelService,
    private data: DataService,
    private overviewKpiService: OverviewKpiService,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private transitionService: TransitionService,
    private readonly messageService: MessageService,
    private readonly navigationService: NavigationService,
  ) {}

  public saveName = (name: string) =>
    this.data.collection('Projects').entity(this.entityId).patch({ name });

  public openSettings() {
    const ref = this.modal.open(ProjectSettingsComponent, { size: 'lg' });
    (ref.componentInstance as ProjectSettingsComponent).projectId =
      this.entityId;
    ref.result.then(
      () => this.service.load(),
      () => null,
    );
  }

  public openCoManagers() {
    const modalRef = this.modal.open(CoManagersModalComponent);
    const instance = modalRef.componentInstance as CoManagersModalComponent;
    instance.readonly = !this.project.coManagersEditAllowed;
    instance.type = 'project';
    instance.manager = this.project.manager;
    instance.entityId = this.entityId;
  }

  public openUserInfo() {
    const userId = this.project.manager.id;
    const target = document.getElementById('manager');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  openBooking(): void {
    this.stateService.go('filteredBooking', {
      routeMode: RouteMode.continue,
      filter: JSON.stringify(<EntityFilter>{
        name: this.project.name,
        filter: [{ projectId: { type: 'guid', value: this.project.id } }],
      }),
    });
  }

  openProjectVersions(): void {
    this.stateService.go('projectsVersions', {
      routeMode: RouteMode.continue,
      projectId: this.entityId,
    });
  }

  ngOnInit() {
    this.actionPanelService.setHasAutosave(true);

    if (this.uiRouterGlobals.params.openVersionId) {
      this.versionService
        .getVersion(this.uiRouterGlobals.params.openVersionId)
        .pipe(take(1))
        .subscribe({
          next: (version) => {
            this.versionCardService.openVersionCard(version);
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
          },
        });
      this.stateService.go('.', {
        entityId: this.uiRouterGlobals.params.entityId,
        openVersionId: null,
      });
    } else {
      this.service.load();
    }

    this.actionPanelService.set([
      {
        title: 'projects.projects.card.actions.settings.label',
        hint: 'projects.projects.card.actions.settings.hint',
        name: 'openSettings',
        isDropDown: false,
        iconClass: 'bi bi-sliders2',
        isBusy: false,
        isVisible: true,
        handler: () => this.openSettings(),
      },
      {
        title: 'projects.projects.card.actions.booking.label',
        hint: 'projects.projects.card.actions.booking.hint',
        name: 'openBooking',
        isDropDown: false,
        iconClass: 'bi bi-calendar-check',
        isBusy: false,
        isVisible: this.app.checkFeature(Feature.booking),
        handler: () => this.openBooking(),
      },
      {
        title: 'projects.projects.card.actions.projectVersions.label',
        hint: 'projects.projects.card.actions.projectVersions.hint',
        name: 'projectVersions',
        isDropDown: false,
        iconClass: null,
        isBusy: false,
        isVisible: false,
        handler: () => this.openProjectVersions(),
      },
      {
        name: 'updateInheritedProjects',
        title:
          'components.projectCardComponent.actions.updateInheritedProjects',
        hint: 'components.projectCardComponent.actions.updateInheritedProjects',

        isDropDown: false,
        isBusy: false,
        isVisible: false,
        handler: () => this.service.updateInheritedProjects(),
      },
    ]);

    this.actionPanelService.setAdditional([
      {
        name: 'delete',
        title: 'projects.projects.card.actions.delete.label',
        hint: 'projects.projects.card.actions.delete.hint',
        isBusy: false,
        isVisible: true,
        handler: () => this.deleteProject(),
      },
    ]);

    const project$$ = this.service.project$.subscribe((project: Project) => {
      this.project = project;
      this.versionCardService.updateWorkProjectVersion(project);
      if (
        !this.versionCardService.projectVersion ||
        this.versionCardService.isWorkProjectVersion()
      ) {
        this.versionCardService.initProjectVersion();
        this.projectVersionCtrl.patchValue(
          this.versionCardService.projectVersion,
          { emitEvent: false },
        );
      }

      this.tabs = [];

      this.tabs.push({
        header: 'projects.projects.card.tabs.overview',
        state: 'project.overview',
      });

      if (project.teamViewAllowed) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.team',
          state: 'project.team',
        });
      }

      if (project.tariffsViewAllowed && this.service.isProjectBillable) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.tariffs',
          state: 'project.tariffs',
        });
      }

      if (
        project.tasksViewAllowed &&
        this.app.checkFeature(Feature.projectTasks)
      ) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.tasks',
          state: 'project.tasks',
        });
      }

      if (
        project.resourcePlanViewAllowed &&
        this.app.checkFeature(Feature.resourcePlan)
      ) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.resources',
          state: 'project.resources',
        });
      }

      if (
        this.app.checkFeature(Feature.booking) &&
        this.versionCardService.isWorkProjectVersion()
      ) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.resourceRequirements',
          state: 'project.resourceRequirements',
        });
      }

      if (
        (project.financeViewAllowed ||
          project.revenueEstimateViewAllowed ||
          project.billingEstimateViewAllowed) &&
        this.app.checkFeature(Feature.finance) &&
        project.billingType.code !== ProjectBillingType.nonBillable.code
      ) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.rbc',
          state: 'project.rbc',
        });
      }

      if (
        (project.financeViewAllowed || project.expenseEstimateViewAllowed) &&
        this.app.checkFeature(Feature.finance)
      ) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.expenses',
          state: 'project.expenses',
        });
      }

      if (
        project.costCentersViewAllowed &&
        this.versionCardService.isWorkProjectVersion()
      ) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.costCenters',
          state: 'project.costCenters',
        });
      }

      if (project.financeViewAllowed && this.app.checkFeature(Feature.pnl)) {
        this.tabs.push({
          header: 'projects.projects.card.tabs.pnl',
          state: 'project.pnl',
        });
      }

      if (
        !this.versionCardService.isWorkProjectVersion() &&
        this.tabs.findIndex((t) =>
          (this.uiRouterGlobals?.current?.name ?? '').startsWith(t.state),
        ) === -1
      ) {
        this.stateService.go('project.overview');
      }

      this.actionPanelService.action('projectVersions').isShown =
        project.versionViewAllowed;
      this.versionService.load(project.id);
    });

    this.onTransitionSuccessListener = this.transitionService.onSuccess(
      null,
      () => {
        if (this.project) {
          this.service.resetTaskCache();
        }
      },
    );

    this.subscriptions.push(
      project$$,
      this.service.projectTotal$.subscribe((projectTotal: any) => {
        this.projectTotal = projectTotal;
      }),
      // Обработка события открытия карточки версии.
      this.versionCardService.openProjectVersionCard$.subscribe((version) => {
        this.projectVersionCtrl.patchValue(version, { emitEvent: false });
        this.service.load();
      }),
      this.versionService.projectVersions$.subscribe((versions) => {
        this.projectVersions = versions.filter(
          (v) => v.state.id !== ProjectVersionState.cancelledStateId,
        );
        if (
          !this.versionCardService.isWorkProjectVersion() &&
          !versions.find((v) => v.id === this.projectVersionCtrl.value.id)
        ) {
          this.versionCardService.initProjectVersion();
          this.projectVersionCtrl.patchValue(
            this.versionCardService.projectVersion,
          );
        }
      }),
      this.projectVersionCtrl.valueChanges.subscribe(() => {
        this.versionService
          .changeVersion(this.projectVersionCtrl.value)
          .subscribe({
            next: () => {
              this.overviewKpiService.settings.task = null;
              this.service.load();
            },
            error: (error: Exception) => {
              this.notification.error(error.message);
            },
          });
      }),
    );
  }

  /** Deletes project. */
  private deleteProject(): void {
    this.messageService
      .confirmLocal('shared2.messages.deleteConfirmation')
      .then(
        () => {
          this.service.deleteProject().subscribe((isDeleted) => {
            if (isDeleted) {
              this.notification.successLocal(
                'shared2.messages.deleteCompleted',
              );
              const selectedNavigationItem =
                this.navigationService.selectedNavigationItem;
              this.stateService.go(
                selectedNavigationItem?.state ?? 'projects',
                selectedNavigationItem?.stateParams,
              );
            }
          });
        },
        () => null,
      );
  }

  ngOnDestroy() {
    this.subscriptions.forEach((s) => s.unsubscribe());
    this.onTransitionSuccessListener();
    this.versionCardService.initProjectVersion();
    this.service.dispose();
  }
}
