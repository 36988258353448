import { wrapIn } from 'prosemirror-commands';
import { Command, EditorState } from 'prosemirror-state';
import { ResolvedPos } from 'prosemirror-model';
import { MenuItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';
import { isTextSelection } from 'src/app/shared/components/controls/rich-editor-box/utils/selection';
import { isBlockqouteNode } from 'src/app/shared/components/controls/rich-editor-box/markdown/blockquote/consts';
import { hasParentNodeOfType } from 'prosemirror-utils';

/**
 * Toggles blockquote.
 *
 * @returns editor view state command.
 */
export function toggleQuote(): Command {
  return (state, dispatch) => {
    const { selection } = state;
    const qType = state.schema.nodes.blockquote;
    if (!isTextSelection(selection) || !selection.$cursor) {
      return wrapIn(qType)(state, dispatch);
    }
    const { $cursor } = selection;
    let { depth } = $cursor;
    while (depth > 0) {
      const node = $cursor.node(depth);
      const nodeSpec = node.type.spec;
      if (!nodeSpec.complex || nodeSpec.complex === 'root') {
        const targetDepth = depth - 1;
        const range = getBlockRange($cursor, depth);
        if (isBlockqouteNode($cursor.node(targetDepth))) {
          dispatch?.(state.tr.lift(range!, targetDepth - 1).scrollIntoView());
        } else {
          dispatch?.(state.tr.wrap(range!, [{ type: qType }]).scrollIntoView());
        }
        return true;
      }
      depth--;
    }
    return false;
  };
}

/**
 * Gets editor view selection range.
 *
 * @param $pos cursor position.
 * @param depth node depth
 * @returns selection range.
 */
function getBlockRange($pos: ResolvedPos, depth?: number) {
  const { doc } = $pos;
  const $before = doc.resolve($pos.before(depth));
  const $after = doc.resolve($pos.after(depth));
  return $before.blockRange($after);
}

/**
 * Toggles blockquote menu item isActive.
 *
 * @param item selected item.
 * @param state editor view state.
 */
export function toggleBlockquoteActive(
  item: MenuItem,
  state: EditorState,
): void {
  item.isActive = hasParentNodeOfType(state.schema.nodes.blockquote)(
    state.selection,
  );
}
