import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { UntypedFormBuilder } from '@angular/forms';
import { Dictionary } from 'src/app/shared/models/dictionary';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { forkJoin } from 'rxjs';
import { AppService } from 'src/app/core/app.service';

@Component({
  selector: 'wp-user-assessment-modal',
  templateUrl: './user-assessment-modal.component.html',
  styleUrls: ['./user-assessment-modal.component.scss'],
  standalone: false,
})
export class UserAssessmentModalComponent implements OnInit {
  @Input() member: any;
  @Input() readonly: boolean;

  get user(): NamedEntity {
    return this.member.resource;
  }

  public isSaving: boolean;
  public isLoading: boolean;

  @ViewChild('leftTbl') leftTbl: ElementRef;

  public leftTableStyles: Dictionary<string> = {};

  public userSkills: any[] = [];

  public filterForm = this.fb.group({ name: [''] });

  constructor(
    private app: AppService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  public resizeLeftTbl() {
    this.leftTableStyles['display'] = 'table';
    this.leftTableStyles['width'] =
      (this.leftTbl.nativeElement as HTMLElement).getBoundingClientRect()
        .width + 'px';
  }

  public ok() {
    this.isSaving = true;
    const estimations = [];

    this.userSkills.forEach((userSkill: any) => {
      if (userSkill.level > 0) {
        estimations.push({
          skillId: userSkill.skill.id,
          projectTeamMemberId: this.member.id,
          ownerId: this.app.session.user.id,
          level: userSkill.level,
        });
      }
    });

    this.data
      .collection('ProjectTeamMembers')
      .entity(this.member.id)
      .action('WP.UpdateSkillsEstimation')
      .execute({
        estimations,
      })
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'projects.projects.card.team.assessmentModal.messages.estimationSaved',
          );
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  private load() {
    this.userSkills = [];
    this.isLoading = true;

    const skillsQuery = {
      select: ['id'],
      expand: { skill: { select: ['id', 'name', 'description'] } },
      filter: [{ userId: { type: 'guid', value: this.user.id } }],
    };

    const estimationsQuery = {};

    const observables = [
      this.data.collection('UserSkills').query<any[]>(skillsQuery),
      this.data
        .collection('ProjectTeamMembers')
        .entity(this.member.id)
        .collection('UserSkillEstimationByProjects')
        .query<any[]>(estimationsQuery),
    ];

    forkJoin(observables).subscribe({
      next: (response: [any[], any[]]) => {
        this.userSkills = response[0];

        this.userSkills.forEach((userSkill: any) => {
          const estimation = response[1].find(
            (e) => userSkill.skill.id === e.skillId,
          );
          userSkill.level = estimation ? estimation.level : 0;
        });
        this.isLoading = false;
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isLoading = false;
      },
    });
  }

  public getUserSkills() {
    return this.userSkills.filter(
      (userSkill: any) =>
        userSkill.skill.name
          .toLowerCase()
          .indexOf(this.filterForm.value.name.toLowerCase()) !== -1,
    );
  }

  public removeEstimation(userSkill: any) {
    userSkill.level = 0;
  }

  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit() {
    this.load();
  }
}
