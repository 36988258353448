import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { LevelCardService } from './level-card.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { Constants } from 'src/app/shared/globals/constants';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { Level } from 'src/app/shared/models/entities/settings/level.model';
import { BlockUIService } from 'src/app/core/block-ui.service';

@Component({
  selector: 'wp-level-card',
  templateUrl: './level-card.component.html',
  providers: [LevelCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class LevelCardComponent implements OnInit {
  @Input() entityId: string;

  public readonly: boolean;
  public levelForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: '',
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
    isActive: false,
    isDefault: false,
  });
  public isSaving$ = new BehaviorSubject<boolean>(false);

  private destroyRef = inject(DestroyRef);

  constructor(
    public levelCardService: LevelCardService,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private actionService: ActionPanelService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private blockUIService: BlockUIService,
  ) {}

  public ngOnInit(): void {
    this.levelForm.controls.name.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => this.levelCardService.updateName(value));

    this.actionService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());

    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
      {
        title: 'shared.actions.useByDefault',
        hint: 'shared.actions.useByDefault',
        name: 'setAsDefault',
        isDropDown: false,
        isBusy: false,
        isVisible: !this.readonly,
        handler: () => this.setAsDefault(),
      },
    ]);

    this.levelCardService.level$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((level: Level) => {
        this.levelForm.patchValue(level);
        this.readonly = !level.editAllowed;
        this.readonly ? this.levelForm.disable() : this.levelForm.enable();
        this.actionService.action('setAsDefault').isShown =
          !this.readonly && !level.isDefault;
      });
  }

  /** Reloads data. */
  public reload(): void {
    if (!this.levelForm.dirty) {
      this.levelCardService.load();
    } else {
      this.messageService.confirmLocal('shared.leavePageMessage').then(
        () => {
          this.levelForm.markAsPristine();
          this.levelCardService.load();
        },
        () => null,
      );
    }
  }

  /** Saves data. */
  public save(): void {
    this.levelForm.markAllAsTouched();

    if (this.levelForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);
    this.actionService.action('save').start();
    this.blockUIService.start();

    this.dataService
      .collection('Levels')
      .entity(this.entityId)
      .patch(this.levelForm.value)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.levelForm.markAsPristine();
          this.isSaving$.next(false);
          this.actionService.action('save').stop();
          this.blockUIService.stop();
          this.notificationService.successLocal(
            'settings.levels.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving$.next(false);
          this.actionService.action('save').stop();
          this.blockUIService.stop();
          this.notificationService.error(error.message);
        },
      });
  }

  /** Sets level as default. */
  private setAsDefault(): void {
    this.dataService
      .collection('levels')
      .entity(this.entityId)
      .action('SetAsDefault')
      .execute()
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: () => {
          this.notificationService.successLocal(
            'settings.levels.card.setAsDefault',
          );

          this.actionService.action('setAsDefault').isShown = false;
          this.reload();
        },
        error: (error: Exception) => {
          this.notificationService.error(error.message);
        },
      });
  }
}
