import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  effect,
  inject,
  Input,
  OnInit,
  Optional,
  SkipSelf,
} from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { TranslateModule } from '@ngx-translate/core';
import { NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';

import { init } from 'src/app/shared/animations';
import {
  Attachment,
  AttachmentItem,
} from 'src/app/shared/models/entities/attachments/attachment.interface';

import { FilesService } from './files.service';
import { FileBoxViewMode } from './file-box.model';
import { FileBoxButtonAddComponent } from './file-box-button-add/file-box-button-add.component';
import { FileBoxDnDZoneDirective } from './file-box-dnd-zone/dnd-zone.directive';
import { SharedModule } from 'src/app/shared/shared.module';
import { SpinnerParams } from 'src/app/shared/components/features/loading-indicator/loading-indicator.model';

@Component({
    selector: 'tmt-file-box',
    imports: [
        BrowserAnimationsModule,
        SharedModule,
        DatePipe,
        TranslateModule,
        NgbTooltipModule,
        FileBoxButtonAddComponent,
        FileBoxDnDZoneDirective,
    ],
    providers: [
        {
            provide: FilesService,
            deps: [[new Optional(), new SkipSelf(), FilesService]],
            useFactory: (parentService: FilesService | null) => parentService || new FilesService(),
        },
    ],
    templateUrl: './file-box.component.html',
    styleUrl: './file-box.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    animations: [init]
})
export class FileBoxComponent implements OnInit {
  @Input({ required: true }) public entityType: string;
  @Input({ required: true }) public entityId: string;
  @Input() public mode: FileBoxViewMode = 'preview';
  @Input() public isInstantMode = true;
  @Input() public isContainerMode?: boolean;
  @Input() public maxCount?: number;
  @Input() public editAllowed = true;
  @Input() public initialFiles: Attachment[];

  public isAddButtonShown = true;
  public readonly spinnerParams: SpinnerParams = {
    spinnerSize: '20px',
    spinnerSpeed: '0.8s',
    spinnerCircleColor: '--bs-selected-color-o15',
    spinnerSpinColor: '--bs-primary',
    spinnerBackgroundColor: '#56565666',
    spinnerBorderRadius: '0.3rem',
  };

  protected readonly destroyRef = inject(DestroyRef);

  constructor(protected filesService: FilesService) {
    effect(() => {
      const files = filesService.files();

      if (typeof this.maxCount === 'number') {
        this.isAddButtonShown =
          files.filter((file) => file.afterSaveAction !== 'delete').length <
          this.maxCount;
      }
    });
  }

  public ngOnInit(): void {
    this.filesService.init(
      this.entityType,
      this.entityId,
      this.mode,
      this.maxCount,
      this.isInstantMode,
      this.initialFiles,
    );
  }

  /**
   * Opens file viewer.
   *
   * @param id first item to show.
   */
  public openViewer(id: string): void {
    this.filesService.openViewer(this.filesService.files(), id);
  }

  /**
   * File actions handler.
   *
   * @param event Mouse event.
   * @param item file.
   * @param type action type.
   */
  public action(
    event: MouseEvent,
    item: AttachmentItem,
    type: 'save' | 'remove' | 'abortRemove',
  ): void {
    event.stopPropagation();

    switch (type) {
      case 'save':
        this.filesService.downloadAttachmentItem(item);
        break;
      case 'remove':
        this.filesService.removeFile(item);
        break;
      case 'abortRemove':
        this.filesService.abortFileRemove(item);
        break;
    }
  }
}
