import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { UntypedFormBuilder, UntypedFormArray } from '@angular/forms';
import { sortBy } from 'lodash';
import { Exception } from 'src/app/shared/models/exception';
import { DateTime, Info } from 'luxon';

@Component({
  selector: 'wp-user-personal-schedule',
  templateUrl: './user-personal-schedule.component.html',
  styleUrls: ['./user-personal-schedule.component.scss'],
  standalone: false,
})
export class UserPersonalScheduleComponent implements OnInit {
  @Input() userId: string;
  @Input() usingSchedules: any[];
  @Input() readonly: boolean;

  @Output() changes = new EventEmitter<any>();

  /** Текущий рабочий период (период=месяц). Дельта от текущего месяца. */
  currentPeriod = 0;

  /** Начало (дата) текущего периода. */
  startPeriod: DateTime;

  /** Окончание (дата) текущего периода. */
  endPeriod: DateTime;

  minDate: DateTime;
  maxDate: DateTime;

  /** Все дни, загруженные для текущего и прочих периодов. */
  storedDays: any[] = [];
  loadedPeriods: any[] = [];

  public header: string;
  public isLoading: boolean;

  days: object;
  weekNumbers: any[];
  weekDays: any[];

  public formArray: UntypedFormArray = this.fb.array([]);

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
  ) {}

  private updatePeriodDates() {
    const currentMonthFirstDay = DateTime.now().startOf('month');

    this.startPeriod = currentMonthFirstDay.plus({
      months: this.currentPeriod,
    });

    this.endPeriod = this.startPeriod.endOf('month');

    if (
      !this.maxDate ||
      this.endPeriod.startOf('day') > this.maxDate.startOf('day')
    ) {
      this.maxDate = this.endPeriod;
    }

    if (
      !this.minDate ||
      this.minDate.startOf('day') > this.startPeriod.startOf('day')
    ) {
      this.minDate = this.startPeriod;
    }

    // Сформировать заголовок периода.
    this.header = this.startPeriod.toFormat('LLLL yyyy');
    this.header =
      this.header.substring(0, 1).toUpperCase() + this.header.substring(1);
  }

  private loadPeriod() {
    this.updatePeriodDates();

    if (this.loadedPeriods.indexOf(this.currentPeriod) !== -1) {
      this.updateView();
      return;
    }
    this.isLoading = true;
    this.data
      .collection('Users')
      .entity(this.userId)
      .collection('PersonalSchedule')
      .query({
        filter: [
          {
            date: {
              ge: {
                type: 'raw',
                value: this.startPeriod.toFormat('yyyy-MM-dd'),
              },
            },
          },
          {
            date: {
              le: { type: 'raw', value: this.endPeriod.toFormat('yyyy-MM-dd') },
            },
          },
        ],
      })
      .subscribe({
        next: (loadedDays: any[]) => {
          this.isLoading = false;
          this.updateView(loadedDays);
          this.loadedPeriods.push(this.currentPeriod);
        },
        error: (error: Exception) => {
          this.isLoading = false;
          this.notification.error(error.message);
        },
      });
  }

  // Выбрать предыдущий период.
  public previousPeriod() {
    this.currentPeriod--;
    this.loadPeriod();
  }

  // Выбрать последующий период.
  public nextPeriod() {
    this.currentPeriod++;
    this.loadPeriod();
  }

  // Выбрать текущий период.
  public today() {
    this.currentPeriod = 0;
    this.loadPeriod();
  }

  // Обновить представление.
  private updateView(loadedDays?: any[]) {
    this.weekNumbers = [];
    this.days = {};

    const schedules = sortBy(this.usingSchedules, 'effectiveDate');
    let date = this.startPeriod;
    while (this.endPeriod.startOf('day') >= date.startOf('day')) {
      const weekNumber = date.weekNumber;
      if (this.weekNumbers.indexOf(weekNumber.toString()) === -1) {
        this.weekNumbers.push(weekNumber.toString());
      }

      if (!this.days[weekNumber.toString()]) {
        this.days[weekNumber.toString()] = {};
      }

      const weekDayNumber = date.weekday - 1;

      if (!this.days[weekNumber.toString()][weekDayNumber.toString()]) {
        const formattedDate = date.toFormat('yyyy-MM-dd');
        let day = this.storedDays.find((d) => d.date === formattedDate);

        if (!day && loadedDays) {
          const loadedDay = loadedDays.find((d) => d.date === formattedDate);

          const control = this.fb.control(loadedDay?.duration);
          day = {
            title: date.toFormat('d'),
            hint: date.toLocaleString(DateTime.DATE_SHORT),
            date: formattedDate,
            control,
            index: this.formArray.length,
          };

          let schedule = null;

          schedules.forEach((usingSchedule: any) => {
            if (!usingSchedule.effectiveDate && !schedule) {
              schedule = usingSchedule;
            } else {
              if (date >= DateTime.fromISO(usingSchedule.effectiveDate)) {
                schedule = usingSchedule;
              }
            }
          });

          if (!schedule || schedule.schedule.id !== 'Personal') {
            day.notPersonal = true;
          }

          this.storedDays.push(day);
          this.formArray.push(control);
        }

        this.days[weekNumber.toString()][weekDayNumber.toString()] = day;
      }
      date = date.plus({ days: 1 });
    }

    if (this.readonly) {
      this.formArray.disable();
    }
  }

  ngOnInit(): void {
    // Сформировать дни недели.
    this.weekDays = [];
    let dayNumber = 0;

    Info.weekdaysFormat('short').forEach((dayName: string) => {
      this.weekDays.push({
        title: dayName,
        number: dayNumber.toString(),
      });
      dayNumber++;
      if (dayNumber === 7) {
        dayNumber = 0;
      }
    });

    this.formArray.valueChanges.subscribe(() => {
      const days = this.storedDays
        .filter((d) => d.control.value > 0)
        .map((day) => ({
          duration: day.control.value,
          date: day.date,
          userId: this.userId,
        }));

      this.changes.emit({
        from: this.minDate.toFormat('yyyy-MM-dd'),
        to: this.maxDate.toFormat('yyyy-MM-dd'),
        userScheduleDays: days,
      });
    });

    this.loadPeriod();
  }
}
