<form class="modal-form" novalidate ngDraggable [formGroup]="providerForm">
  <div class="modal-header">
    <h3 class="modal-title">
      {{
        providerName ? providerName : ('settings.settings.authProviders.modal.title' | translate)
      }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div class="form-group" tmtIndicator>
      <label>{{
        'settings.settings.authProviders.modal.properties.providerName' | translate
      }}</label>
      <wp-text-box
        formControlName="name"
        placeholder="{{
          'settings.settings.authProviders.modal.properties.providerName' | translate
        }}"
      >
      </wp-text-box>
    </div>

    <div class="form-group">
      <label>{{
        'settings.settings.authProviders.modal.properties.providerType' | translate
      }}</label>
      <wp-text-box
        formControlName="type"
        readonly="true"
        placeholder="{{
          'settings.settings.authProviders.modal.properties.providerType' | translate
        }}"
      >
      </wp-text-box>
    </div>
    @if (providerForm?.value?.type === AuthProviderType.OpenId) {
      <div formGroupName="openIdConfiguration">
        <div class="form-group" tmtIndicator>
          <label>{{
            'settings.settings.authProviders.modal.properties.authority' | translate
          }}</label>
          <wp-text-box
            formControlName="authority"
            placeholder="{{
              'settings.settings.authProviders.modal.properties.authority' | translate
            }}"
          >
          </wp-text-box>
        </div>
        <div class="form-group" tmtIndicator>
          <label>{{
            'settings.settings.authProviders.modal.properties.clientId' | translate
          }}</label>
          <wp-text-box
            formControlName="clientId"
            placeholder="{{
              'settings.settings.authProviders.modal.properties.clientId' | translate
            }}"
          />
        </div>
        <div class="form-group" tmtIndicator>
          <label>{{
            'settings.settings.authProviders.modal.properties.clientSecret' | translate
          }}</label>
          <wp-text-box
            formControlName="clientSecret"
            placeholder="{{
              'settings.settings.authProviders.modal.properties.clientSecret' | translate
            }}"
          />
        </div>
      </div>
    } @else if (providerForm?.value?.type === AuthProviderType.Local) {
      <ng-container formGroupName="localSetting">
        <div class="row">
          <div class="col-6">
            <label class="group">
              {{
                'components.settingsAuthCreateProviderModelComponent.props.localSetting.complexity.groupHeader'
                  | translate
              }}
            </label>
            <div class="form-group" tmtIndicator>
              <label>{{
                'components.settingsAuthCreateProviderModelComponent.props.localSetting.complexity.properties.minPasswordLength.label'
                  | translate
              }}</label>
              <wp-number-box
                style="width: 100px"
                formControlName="minPasswordLength"
                type="integer"
                [align]="'left'"
                [min]="formValidatorValues.localSetting.minPasswordLength.min"
                [max]="formValidatorValues.localSetting.minPasswordLength.max"
                placeholder="{{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.complexity.properties.minPasswordLength.placeholder'
                    | translate
                }}"
              />
            </div>

            <div class="form-check">
              <input
                formControlName="requireDigits"
                type="checkbox"
                class="form-check-input"
                id="requireDigits"
              />
              <label class="form-check-label" for="requireDigits">
                {{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.complexity.properties.requireDigits.label'
                    | translate
                }}
              </label>
            </div>

            <div class="form-check">
              <input
                formControlName="requireSpecSymbols"
                type="checkbox"
                class="form-check-input"
                id="requireSpecSymbols"
              />
              <label class="form-check-label" for="requireSpecSymbols">
                {{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.complexity.properties.requireSpecSymbols.label'
                    | translate
                }}
              </label>
            </div>

            <div class="form-check">
              <input
                formControlName="requireCapitalLetters"
                type="checkbox"
                class="form-check-input"
                id="requireCapitalLetters"
              />
              <label class="form-check-label" for="requireCapitalLetters">
                {{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.complexity.properties.requireCapitalLetters.label'
                    | translate
                }}
              </label>
            </div>
          </div>
          <div class="col-6">
            <label class="group">
              {{
                'components.settingsAuthCreateProviderModelComponent.props.localSetting.lockout.groupHeader'
                  | translate
              }}
            </label>
            <div class="form-group" tmtIndicator>
              <label>
                {{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.lockout.properties.maxInvalidLoginAttempts.label'
                    | translate
                }}
              </label>
              <wp-number-box
                formControlName="maxInvalidLoginAttempts"
                style="width: 100px"
                type="integer"
                [align]="'left'"
                [min]="formValidatorValues.localSetting.maxInvalidLoginAttempts.min"
                [max]="formValidatorValues.localSetting.maxInvalidLoginAttempts.max"
                placeholder="{{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.lockout.properties.maxInvalidLoginAttempts.placeholder'
                    | translate
                }}"
              />
            </div>
            <div class="form-group" tmtIndicator>
              <label>
                {{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.lockout.properties.lockoutMinutes.label'
                    | translate
                }}
              </label>
              <wp-number-box
                style="width: 100px"
                formControlName="lockoutMinutes"
                type="integer"
                [align]="'left'"
                [min]="formValidatorValues.localSetting.lockoutMinutes.min"
                [max]="formValidatorValues.localSetting.lockoutMinutes.max"
                placeholder="{{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.lockout.properties.lockoutMinutes.placeholder'
                    | translate
                }}"
              />
            </div>

            <label class="group mt-3">
              {{
                'components.settingsAuthCreateProviderModelComponent.props.localSetting.mfa'
                  | translate
              }}
            </label>
            <div class="form-check">
              <input
                formControlName="useOtp"
                type="checkbox"
                class="form-check-input"
                id="useOtp"
              />
              <label class="form-check-label" for="useOtp">
                {{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.useOtp'
                    | translate
                }}
              </label>
            </div>
          </div>
        </div>
        <div class="row">
          <hr class="mt-3" />
          <div class="col-6">
            <div class="form-group" tmtIndicator>
              <label>{{
                'components.settingsAuthCreateProviderModelComponent.props.localSetting.passwordUpdatePeriodDays.label'
                  | translate
              }}</label>
              <wp-number-box
                style="width: 100px"
                formControlName="passwordUpdatePeriodDays"
                type="integer"
                [align]="'left'"
                [min]="formValidatorValues.localSetting.passwordUpdatePeriodDays.min"
                [max]="formValidatorValues.localSetting.passwordUpdatePeriodDays.max"
                placeholder="{{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.passwordUpdatePeriodDays.placeholder'
                    | translate
                }}"
              />
            </div>
          </div>
          <div class="col-6">
            <div class="form-check">
              <input
                formControlName="allowedGrantPassword"
                type="checkbox"
                class="form-check-input"
                id="allowedGrantPassword"
              />
              <label class="form-check-label" for="allowedGrantPassword">
                {{
                  'components.settingsAuthCreateProviderModelComponent.props.localSetting.allowedGrantPassword'
                    | translate
                }}
              </label>
            </div>
          </div>
        </div>
      </ng-container>
    }
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="ok()"
      wpButtonSpinner
      [isBusy]="isSaving"
      *ngIf="!readonly"
    >
      {{ 'shared.actions.save' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
