import {
  Component,
  forwardRef,
  Input,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  ViewRef,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  Inject,
  Optional,
  OnChanges,
  SimpleChanges,
  OnDestroy,
  Renderer2,
  NgZone,
  booleanAttribute,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  UntypedFormControl,
} from '@angular/forms';
import { fromEvent, Subject, Subscription } from 'rxjs';
import { filter, takeUntil } from 'rxjs/operators';
import { StandaloneFormControlDirective } from 'src/app/shared/directives/form-control.directive';
import { Constants } from 'src/app/shared/globals/constants';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { PhonePipe } from 'src/app/shared/pipes/phone.pipe';
import { PhoneService } from 'src/app/shared/services/phone.service';

/** Контрол ввода текста. */
@Component({
  selector: 'wp-text-box',
  templateUrl: './text-box.component.html',
  styleUrls: ['./text-box.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TextBoxComponent),
      multi: true,
    },
  ],
  hostDirectives: [
    {
      directive: StandaloneFormControlDirective,
      inputs: ['formControl: control'],
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TextBoxComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnChanges, OnDestroy
{
  /** Заменить пустого текста. */
  @Input() placeholder: string;

  /** Признак режима только-чтение. */
  @Input({ transform: booleanAttribute }) readonly: boolean;

  /** Признак режима "пароль". */
  @Input() password = false;

  /** Признак установки автофокуса при отображении. */
  @Input() autofocus = false;

  /** Максимальная длина вводимого текста. */
  @Input() maxLength = Constants.formTextMaxLength;

  /** Value change propagation mode. */
  @Input() propagationMode: PropagationMode = PropagationMode.onInput;

  /** Initial value for input element after rendering. */
  @Input() initialValue?: unknown;

  /** Angular abstract control for binding to form outside of template. */
  @Input() control?: AbstractControl;

  /** Property for working in chosen link mode. */
  @Input() typeName: 'url' | 'email' | 'phone' | 'string' = 'string';

  private keyboardSubscription: Subscription;

  @ViewChild('editor') editor: ElementRef;
  @ViewChild('clearBtn') clearBtn: ElementRef;

  public disabled = false;
  public type = 'text';
  public formControl = new UntypedFormControl(null);
  public previousValue = '';
  public typeNames = {
    phone: 'phone',
    email: 'email',
    url: 'url',
  };

  private get input() {
    if (this.readonly) {
      return null;
    }
    return this.editor.nativeElement as HTMLInputElement;
  }

  private _value: string;

  private clearBtnHover = false;
  private onClearBtnMouseEnterListener: () => void;
  private onClearBtnMouseLeaveListener: () => void;

  private destroyed$ = new Subject<void>();

  constructor(
    private ref: ChangeDetectorRef,
    @Optional() @Inject(NG_VALIDATORS) private validators: any,
    private renderer: Renderer2,
    private zone: NgZone,
    private phonePipe: PhonePipe,
    private phoneService: PhoneService,
  ) {}

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes['readonly']) {
      this.disabled = this.readonly;
      this.ref.markForCheck();
    }
  }

  public ngOnInit(): void {
    if (this.password) {
      this.type = 'password';
    }
    this.formControl.valueChanges
      .pipe(
        filter(() => this.propagationMode === PropagationMode.onInput),
        takeUntil(this.destroyed$),
      )
      .subscribe(() => {
        const value = this.formControl.value.trim();
        this._value = value;
        this.propagateChange(value);
      });

    switch (this.typeName) {
      case this.typeNames.phone:
        this.placeholder = '+0 (000) 000-00-00';
        break;
      case this.typeNames.email:
        this.placeholder = 'example@domain.com';
        break;
      case this.typeNames.url:
        this.placeholder = 'https://example.com';
        break;
      default:
    }
  }

  public ngAfterViewInit(): void {
    if (this.autofocus) {
      this.input?.focus();
    }

    this.applyInitialValue();

    this.zone.runOutsideAngular(() => {
      this.onClearBtnMouseEnterListener = this.renderer.listen(
        this.clearBtn.nativeElement,
        'mouseenter',
        () => {
          this.clearBtnHover = true;
        },
      );
      this.onClearBtnMouseLeaveListener = this.renderer.listen(
        this.clearBtn.nativeElement,
        'mouseleave',
        () => {
          this.clearBtnHover = false;
        },
      );
    });
  }

  public ngOnDestroy(): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.onBlur();
    }
    this.onClearBtnMouseEnterListener();
    this.onClearBtnMouseLeaveListener();
    this.destroyed$.next();
    this.keyboardSubscription?.unsubscribe();
  }

  propagateChange = (_: any) => null;
  propagateTouch = () => null;

  public getTitle() {
    if (this.password) {
      return null;
    }

    return this.formControl.value;
  }

  writeValue(value: any): void {
    // Do not patch value if control in editing process.
    if (
      this.propagationMode === PropagationMode.onExitFromEditing &&
      this.formControl.value !== null &&
      this._value !== this.formControl.value
    ) {
      return;
    }

    this._value = value ? value : '';

    const formattedValue =
      this.typeName === this.typeNames.phone
        ? this.phonePipe.transform(this._value)
        : this._value;

    this.formControl.setValue(formattedValue, { emitEvent: false });
    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  onInput(event: Event): void {
    if (this.typeName !== this.typeNames.phone) return;

    const input = event.target as HTMLInputElement;
    const onlyNumbersValue = input.value.replace(/\D/g, '');
    const fixedNumbersLength =
      onlyNumbersValue.length > Constants.maxPhoneLength
        ? onlyNumbersValue.slice(0, Constants.maxPhoneLength)
        : onlyNumbersValue;
    const isDeleting = input.value.length < this.previousValue.length;
    const patternLength = this.phoneService.phonePattern.split('_').length - 1;

    const maxLength = isDeleting
      ? Math.min(patternLength, fixedNumbersLength.length)
      : Math.max(patternLength, fixedNumbersLength.length);

    this._value = fixedNumbersLength.slice(0, maxLength);

    const formattedValue = isDeleting
      ? input.value
      : this.phonePipe.transform(this._value);

    this.formControl.setValue(formattedValue, { emitEvent: false });

    this.propagateChange(this._value);
    this.previousValue = input.value;
  }

  registerOnTouched(fn: any): void {
    this.propagateTouch = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
    if (!this.readonly) {
      this.disabled = isDisabled;
    }

    if (!(this.ref as ViewRef).destroyed) {
      this.ref.detectChanges();
    }
  }

  /** Input onFocus logic.*/
  public onFocus(): void {
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      this.initKeysSubscribes();
    }
  }

  /** Input onBlur logic. */
  public onBlur() {
    this.propagateTouch();
    const value = this.formControl.value?.trim();

    if (
      this.propagationMode === PropagationMode.onExitFromEditing &&
      !this.clearBtnHover &&
      this.typeName !== this.typeNames.phone
    ) {
      if (this._value !== value) {
        this._value = value;
        this.propagateChange(value);
      }
      this.keyboardSubscription?.unsubscribe();
    }

    if (this.typeName === this.typeNames.phone && this._value) {
      this._value =
        this._value.length === Constants.minPhoneLength
          ? this._value.replace(/^8/, '7')
          : this._value;

      if (this._value !== value) {
        this.formControl.setValue(this.phonePipe.transform(this._value), {
          emitEvent: false,
        });
        this.propagateChange(this._value);
      }
    }

    if (
      this.typeName === this.typeNames.url &&
      this._value &&
      !/^https?:\/\//.test(this._value)
    ) {
      this._value = `https://${this._value}`;
      this.formControl.setValue(this._value, { emitEvent: false });
      this.propagateChange(this._value);
    }
  }

  /** Clears control value. */
  public clear() {
    const value = '';
    this.formControl.setValue(value);
    if (this.propagationMode === PropagationMode.onExitFromEditing) {
      if (this._value !== value) {
        this._value = value;
        this.propagateChange(value);
      }
    }
  }

  /** Apply initial value after rendering. */
  private applyInitialValue() {
    if (this.initialValue === undefined) {
      return;
    }
    if (!this.readonly && this.input) {
      const event = new Event('input');
      if (typeof this.initialValue === 'string') {
        this.input.value = this.initialValue;
        this.input.dispatchEvent(event);
        this.input.focus();
      } else if (this.initialValue === null) {
        this.input.value = '';
        this.input.dispatchEvent(event);
        this.input.focus();
      }
    }
    this.initialValue = undefined;
  }

  /** Initializes keyboard listener. */
  private initKeysSubscribes() {
    if (this.keyboardSubscription) {
      this.keyboardSubscription.unsubscribe();
    }
    this.keyboardSubscription = fromEvent(window, 'keydown').subscribe(
      (event: KeyboardEvent) => {
        if (!event.repeat) {
          switch (event.code) {
            case 'Enter':
            case 'NumpadEnter': {
              const value = this.formControl.value.trim();
              if (this._value !== value) {
                this._value = value;
                this.propagateChange(value);
              }
              break;
            }
          }
        }
        return;
      },
    );
  }
}
