import { Component, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'tmt-roles-readonly-cell',
  templateUrl: './roles-readonly-cell.component.html',
  styleUrls: ['./roles-readonly-cell.component.scss'],
  standalone: false,
})
export class RolesReadonlyCellComponent implements GridComponentCell {
  @Input() public column: GridColumn;
  @Input() public formGroup: UntypedFormGroup;
  @Input() public initialValue: unknown;

  public get control(): UntypedFormControl {
    return <UntypedFormControl>this.formGroup.controls[this.column.name];
  }
}
