import {
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
} from '@angular/core';
import { DatePipe } from '@angular/common';
import { DataService } from 'src/app/core/data.service';
import { DateTime } from 'luxon';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { DayPart } from 'src/app/shared/models/enums/day-part.enum';
import { TranslateService } from '@ngx-translate/core';
import { WorkPipe } from 'src/app/shared/pipes/work.pipe';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeOffPeriodModalComponent } from './time-off-period-modal/time-off-period-modal.component';
import { AppService } from 'src/app/core/app.service';
import { BlockUIService } from 'src/app/core/block-ui.service';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import { Exception } from 'src/app/shared/models/exception';
import { TimeOffRequest } from 'src/app/shared/models/entities/base/time-off-request.model';
import { TimeOffRequestService } from '../shared/time-off-request.service';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Constants } from 'src/app/shared/globals/constants';
import { saveAs } from 'file-saver';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { UserInfoComponent } from 'src/app/shared/components/features/user-info/user-info.component';
import { BehaviorSubject, forkJoin, Subject } from 'rxjs';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { META_ENTITY_TYPE } from 'src/app/shared/tokens';
import { takeUntil } from 'rxjs/operators';
import { CommentedEntityCollectionType } from 'src/app/shared/models/enums/commented-entity-collection-type.enum';
import { IntersectedTimesheetsComponent } from '../shared/intersected-timesheets-modal/intersected-timesheets-modal.component';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { StateService } from '@uirouter/core';
import { AppName } from 'src/app/shared/globals/app-name';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';

@Component({
  selector: 'wp-time-off-card',
  templateUrl: './time-off-request-card.component.html',
  styleUrls: ['time-off-request-card.component.scss'],
  providers: [
    SavingQueueService,
    TimeOffRequestService,
    { provide: META_ENTITY_TYPE, useValue: 'TimeOffRequest' },
    LifecycleService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimeOffCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public isSaving = false;
  public maxAttachmentSize = Constants.maxAttachmentSize;

  public form = this.fb.group({
    timeOffType: [null, Validators.required],
    description: ['', Validators.maxLength(Constants.formTextMaxLength)],
  });

  public currentBalance: number | null = null;
  public currentDuration: number | null = null;

  public request: TimeOffRequest;
  public readonly = false;

  public isOwnRequest: boolean;

  public activeTab: string;

  public timeOffTypeQuery = {
    select: ['id', 'name', 'code'],
    expand: { unit: { select: ['id', 'name', 'code'] } },
  };

  //** Dates of time off in a single line. */
  public periodTitle: string;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly CommentedEntityCollectionType =
    CommentedEntityCollectionType;

  private destroyed$ = new Subject<void>();

  constructor(
    private fb: UntypedFormBuilder,
    public autosave: SavingQueueService,
    public navigation: NavigationService,
    public blockUI: BlockUIService,
    private app: AppService,
    private workPipe: WorkPipe,
    private datePipe: DatePipe,
    private data: DataService,
    public actionService: ActionPanelService,
    private message: MessageService,
    private notification: NotificationService,
    private translate: TranslateService,
    private modalService: NgbModal,
    private infoPopupService: InfoPopupService,
    private injector: Injector,
    private lifecycleService: LifecycleService,
    private customFieldService: CustomFieldService,
    private cdr: ChangeDetectorRef,
    private stateService: StateService,
  ) {
    lifecycleService.onActionError = (error: Exception): boolean => {
      if (error.code === Exception.TimeOff_CrossNonDraftTimesheetExists.code) {
        const ref = this.modalService.open(IntersectedTimesheetsComponent);
        const instance =
          ref.componentInstance as IntersectedTimesheetsComponent;
        instance.request = this.request;
        instance.error = error;
      } else {
        this.message.errorDetailed(error);
      }
      this.load();
      return true;
    };
    this.customFieldService.enrichFormGroup(this.form, 'TimeOffRequest');
  }

  // Загрузка данных.
  public load = (silent = false): Promise<void> =>
    new Promise((resolve, reject) => {
      this.autosave.save().then(() => {
        if (!silent) {
          this.state$.next(CardState.Loading);
        } else {
          this.blockUI.start();
        }

        this.form.markAsPristine();
        this.form.markAsUntouched();

        const query = {
          select: ['*'],
          expand: [
            { attachment: { select: ['id', 'fileName'] } },
            { user: { select: ['id', 'name'] } },
            { state: { select: ['id', 'name', 'code'] } },
            {
              timeOffType: {
                select: ['id', 'name'],
                expand: { unit: { select: ['id', 'name', 'code'] } },
              },
            },
          ],
        };

        this.customFieldService.enrichQuery(query, 'TimeOffRequest');

        this.data
          .collection('TimeOffRequests')
          .entity(this.entityId)
          .get<TimeOffRequest>(query)
          .subscribe({
            next: (request: TimeOffRequest) => {
              this.request = request;

              this.form.patchValue(request, { emitEvent: false });

              this.readonly = !request.editAllowed;
              // eslint-disable-next-line @typescript-eslint/no-unused-expressions
              this.readonly
                ? this.form.disable({ emitEvent: false })
                : this.form.enable({ emitEvent: false });

              this.state$.next(CardState.Ready);
              this.blockUI.stop();
              this.updateUIState();
              this.reloadInfo();

              this.navigation.addRouteSegment({
                id: request.id,
                title: request.name,
              });

              this.updatePeriodDisplayText();
              this.cdr.markForCheck();

              resolve();
            },
            error: (error: Exception) => {
              this.state$.next(CardState.Error);
              this.cdr.markForCheck();
              this.blockUI.stop();
              if (error.code !== Exception.BtEntityNotFoundException.code) {
                this.notification.error(error.message);
              }
            },
          });
      });
    });

  public openUserInfo() {
    const userId = this.request.user.id;
    const target = document.getElementById('request-user');
    this.infoPopupService.open({
      target,
      data: {
        component: UserInfoComponent,
        params: {
          userId,
        },
        injector: this.injector,
      },
    });
  }

  /** Updates the title of the period. */
  public updatePeriodDisplayText(): void {
    if (!this.request) {
      return;
    }

    let period = this.datePipe.transform(this.request.startDate, 'longDate');

    if (this.request.startDayInterval) {
      period +=
        ' (' +
        this.translate.instant(
          `enums.dayPart.${this.request.startDayInterval}`,
        );

      if (this.request.startDayInterval === DayPart.Hours) {
        period += ', ' + this.workPipe.transform(this.request.startDayHours);
      }
      period += ')';
    }

    if (
      !DateTime.fromISO(this.request.startDate).equals(
        DateTime.fromISO(this.request.finishDate),
      )
    ) {
      period += ' — ';
      period += this.datePipe.transform(this.request.finishDate, 'longDate');
      if (this.request.finishDayInterval) {
        period +=
          ' (' +
          this.translate.instant(
            `enums.dayPart.${this.request.finishDayInterval}`,
          );

        if (this.request.finishDayInterval === DayPart.Hours) {
          period += ', ' + this.workPipe.transform(this.request.finishDayHours);
        }
        period += ')';
      }
    }

    this.periodTitle = period;
  }

  private updateUIState() {
    if (!this.request) {
      return;
    }

    // Установка признака Собственная заявка.
    this.isOwnRequest = this.app.session.user.id === this.request.user.id;

    // Признак режима чтения.
    this.readonly = !this.request.editAllowed;

    const deleteItem = this.actionService.action('delete');
    if (deleteItem) {
      deleteItem.isShown = this.request.deleteAllowed;
    }
  }

  private updateTitle() {
    const typeName = this.form.controls['timeOffType'].value?.name;
    const userName = this.request.user.name;
    this.request.name = this.translate.instant('timeOff.card.nameTemplate', {
      typeName,
      userName,
    });
  }

  /** Открыть диалог изменения дат. */
  public setPeriod() {
    this.autosave.save().then(() => {
      const ref = this.modalService.open(TimeOffPeriodModalComponent);
      const instance = ref.componentInstance as TimeOffPeriodModalComponent;
      instance.request = this.getSaveData();

      ref.result.then(
        () => this.load(true),
        (result) => {
          if (result === 'error') {
            this.load();
          }
        },
      );
    });
  }

  /** Удаление заявки. */
  public delete() {
    this.message.confirmLocal('timeOff.card.messages.deleteConfirmation').then(
      () => {
        this.data
          .collection('TimeOffRequests')
          .entity(this.entityId)
          .delete()
          .subscribe({
            next: () => {
              this.notification.successLocal('shared.messages.deleted');
              this.navigation.goToSelectedNavItem();
            },
            error: (error: Exception) => {
              this.message.error(error.message).then(this.load, this.load);
            },
          });
      },
      () => null,
    );
  }

  public onFileAdded(event: any) {
    const file = event.addedFiles[0];

    if (!file) {
      this.notification.warningLocal('shared.messages.attachmentIsWrong');
      return;
    }

    const formData: FormData = new FormData();
    this.blockUI.start();
    formData.append('attachment', file, file.name);

    this.data
      .collection('TimeOffRequests')
      .entity(this.entityId)
      .action('WP.UploadAttachment')
      .execute(formData)
      .subscribe({
        next: () => {
          this.blockUI.stop();
          this.request.attachment = { fileName: file.name };
          this.notification.successLocal(
            'shared.messages.attachmentWasUploaded',
          );
          this.cdr.markForCheck();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.cdr.markForCheck();
          this.blockUI.stop();
        },
      });
  }

  public removeAttachment() {
    this.blockUI.start();

    this.data
      .collection('TimeOffRequests')
      .entity(this.entityId)
      .action('WP.DeleteAttachment')
      .execute()
      .subscribe({
        next: () => {
          this.blockUI.stop();
          this.cdr.markForCheck();
          this.notification.successLocal(
            'shared.messages.attachmentWasRemoved',
          );
          this.request.attachment = null;
        },
        error: (error: Exception) => {
          this.cdr.markForCheck();
          this.notification.error(error.message);
          this.blockUI.stop();
        },
      });
  }

  public openAttachment() {
    this.data
      .collection('TimeOffRequests')
      .entity(this.entityId)
      .function('Attachment')
      .getBlob()
      .subscribe({
        next: (data: any) => {
          saveAs(data, this.request.attachment.fileName);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  private reloadInfo() {
    if (!this.form.value?.timeOffType?.unit) {
      this.currentDuration = null;
      this.currentBalance = null;
      return;
    }

    /** Получение текущего баланса и длительности */
    forkJoin({
      balance: this.data
        .collection('TimeOffRequests')
        .function('GetTimeOffBalance')
        .query({
          userId: this.request.user.id,
          timeOffTypeId: this.form.value?.timeOffType?.id || null,
        }),
      duration: this.data
        .collection('TimeOffRequests')
        .function('GetTimeOffDuration')
        .query({
          startDate: this.request.startDate,
          finishDate: this.request.finishDate,
          startDayInterval: `WP.DayPart'${this.request.startDayInterval}'`,
          finishDayInterval: `WP.DayPart'${this.request.finishDayInterval}'`,
          startDayHours: this.request.startDayHours?.toString() ?? null,
          finishDayHours: this.request.finishDayHours?.toString() ?? null,
          userId: this.request.user.id,
          timeOffTypeId: this.form.value?.timeOffType?.id || null,
        }),
    })
      .pipe(takeUntil(this.destroyed$))
      .subscribe({
        next: (response: any) => {
          this.currentBalance = response.balance;
          this.currentDuration = response.duration as number;
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
        },
      });
  }

  private getSaveData() {
    const timeOffRequest = this.form.getRawValue() as TimeOffRequest;
    const data = {
      id: this.request.id,
      name: 'name',
      rowVersion: this.request.rowVersion,
      userId: this.request.user?.id,
      description: this.form.value.description,
      timeOffTypeId: this.form.value.timeOffType.id,
      startDate: this.request.startDate,
      finishDate: this.request.finishDate,
      startDayInterval: this.request.startDayInterval,
      finishDayInterval: this.request.finishDayInterval,
      finishDayHours: this.request.finishDayHours,
      startDayHours: this.request.startDayHours,
    };

    this.customFieldService.assignValues(
      data,
      timeOffRequest,
      'TimeOffRequest',
    );

    return data;
  }

  /**
   * Navigate to the list of time-off balances with context
   * */
  public goToBalances() {
    this.stateService.go('timeOffBalanceEntries', {
      routeMode: RouteMode.continue,
      view: 'all',
      filter: JSON.stringify(<EntityFilter>{
        name: this.request.name,
        filter: [{ documentId: { type: 'guid', value: this.request.id } }],
      }),
    });
  }

  ngOnInit() {
    this.actionService.setHasAutosave(true);

    // Установка главного меню.
    this.actionService.setAdditional([
      {
        title: 'shared.actions.delete',
        hint: 'timeOff.card.actions.delete.hint',
        name: 'delete',
        isBusy: false,
        isVisible: false,
        handler: () => this.delete(),
      },
      {
        title: 'timeOff.card.actions.openBalance.title',
        hint: 'timeOff.card.actions.openBalance.hint',
        name: 'openBalance',
        isBusy: false,
        isVisible: this.app.checkAppAccess(AppName.Team),
        handler: () => this.goToBalances(),
      },
    ]);

    this.load();

    this.autosave.error$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => this.load());

    this.autosave.save$
      .pipe(takeUntil(this.destroyed$))
      .subscribe((data: any) => {
        this.request.rowVersion = data.rowVersion;
        this.request.timeOffType = this.form.value.timeOffType;
      });

    this.form.valueChanges.pipe(takeUntil(this.destroyed$)).subscribe(() => {
      this.autosave.addToQueue(this.entityId, () =>
        this.data
          .collection('TimeOffRequests')
          .entity(this.entityId)
          .update(this.getSaveData(), { withResponse: true }),
      );
    });

    this.form.controls.timeOffType.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.updateTitle();
        this.autosave.save().then(() => this.reloadInfo());
      });

    this.actionService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      });

    this.lifecycleService.reload$
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        this.load();
        this.lifecycleService.reloadLifecycle();
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
