import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ProjectTaskTimelineService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/project-task-timeline.service';
import { TimelineRenderingService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/timeline-rendering.service';
import { TimelineCellEntry } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/models/timeline-entry.interface';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[wpTaskTimelineRightGroup]',
  templateUrl: './task-timeline-right-group.component.html',
  styleUrls: ['./task-timeline-right-group.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TaskTimelineRightGroupComponent {
  @Input() taskFormGroup: UntypedFormGroup;

  public entries: TimelineCellEntry[];
  constructor(
    public gridService: GridService,
    public service: ProjectTaskTimelineService,
    public timelineRenderingService: TimelineRenderingService,
  ) {}

  public trackById(index: number, row: any): any {
    return row.id;
  }
}
