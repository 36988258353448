import { Component, Input } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';

@Component({
  selector: 'wp-booking-notify',
  templateUrl: './booking-notify.component.html',
  standalone: false,
})
export class BookingNotifyComponent {
  @Input() bookingId: string;

  isSending = false;
  form = this.fb.group({
    comment: ['', [Validators.maxLength(Constants.formTextMaxLength)]],
  });

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  public ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSending = true;

    this.data
      .collection('BookingEntries')
      .entity(this.bookingId)
      .action('Notify')
      .execute(this.form.value)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'resources.booking.notifyForm.messages.sent',
          );

          this.isSending = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSending = false;
        },
      });
  };

  public cancel = () => {
    this.activeModal.dismiss('cancel');
  };
}
