import { Component, ChangeDetectionStrategy } from '@angular/core';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';
import { TaskNameCellComponent } from './task-name-cell.component';

@Component({
  selector: 'wp-task-name-readonly-cell',
  templateUrl: './task-name-readonly-cell.component.html',
  styleUrls: ['./task-name-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TaskNameReadonlyCellComponent
  extends TaskNameCellComponent
  implements GridComponentCell {}
