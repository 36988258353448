import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { NotificationService } from 'src/app/core/notification.service';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { Exception } from 'src/app/shared/models/exception';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';

import { ResourceRequestService } from '../resource-request.service';
import { ResourceRequestCalendarService } from '../../calendar/resource-request-calendar.service';

@Component({
  selector: 'tmt-resource-request-modal',
  templateUrl: './resource-request-modal.component.html',
  styleUrls: ['./resource-request-modal.component.scss'],
  providers: [
    ResourceRequestService,
    ResourceRequestCalendarService,
    SavingQueueService,
    ScheduleNavigationService,
    FreezeTableService,
  ],
  standalone: false,
})
export class ResourceRequestModalComponent implements OnInit {
  @Input() public teamMemberId: string | null;

  public isSaving = false;
  public isBookingUpdate = false;

  private formMain: UntypedFormGroup;

  constructor(
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
    public resourceRequestService: ResourceRequestService,
  ) {}

  public ngOnInit(): void {
    if (this.teamMemberId) {
      this.resourceRequestService
        .loadResourceRequestTemplate(this.teamMemberId)
        .subscribe({
          next: (value) => {
            this.isBookingUpdate = !!value.resource;
          },
          error: (error: Exception) => {
            this.notification.error(error.message);
            this.cancel();
          },
        });
    } else {
      this.resourceRequestService.onChange({}, 'menuCreate');
      this.resourceRequestService.state$.next(CardState.Ready);
    }
  }

  public create(): void {
    this.formMain.markAllAsTouched();

    if (this.formMain.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.resourceRequestService.create().subscribe({
      next: (request) => {
        this.isSaving = false;
        this.activeModal.close(request);
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isSaving = false;
      },
    });
  }

  public cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  public onForm(form: UntypedFormGroup): void {
    this.formMain = form;
  }
}
