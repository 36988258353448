import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Type,
} from '@angular/core';

import { BookingDataService } from 'src/app/booking/booking/core/booking-data.service';
import { BookingService } from 'src/app/booking/booking/core/booking.service';
import { BookingBasicComponent } from 'src/app/booking/booking/shared/booking-basic/booking-basic.component';
import { BookingDetailedLineRightComponent } from 'src/app/booking/booking/shared/booking-detailed/booking-detailed-line-right/booking-detailed-line-right.component';

@Component({
  selector: 'tmt-booking-detailed',
  templateUrl: './booking-detailed.component.html',
  styleUrls: [
    '../booking-basic/booking-basic.component.scss',
    'booking-detailed.component.scss',
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BookingDetailedComponent extends BookingBasicComponent {
  public override componentLine: Type<unknown> =
    BookingDetailedLineRightComponent;

  constructor(
    service: BookingService,
    dataService: BookingDataService,
    cdr: ChangeDetectorRef,
  ) {
    super(null, service, dataService, cdr);
  }
}
