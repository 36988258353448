import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'wp-report-properties',
  templateUrl: './report-properties.component.html',
  standalone: false,
})
export class ReportPropertiesComponent {
  @Input() form: UntypedFormGroup;
}
