import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { PlanningScale } from 'src/app/shared/models/enums/planning-scale.enum';
import { ProjectExpensesCalendarService } from '../../core/project-expenses-calendar.service';
import { ProjectExpensesService } from 'src/app/projects/card/project-expenses/project-expenses.service';

@Component({
  selector: 'wp-project-expenses-calendar-toolbar',
  templateUrl: './project-expenses-calendar-toolbar.component.html',
  styles: [':host {display: block}'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectExpensesCalendarToolbarComponent {
  @Input() disabled = false;

  // eslint-disable-next-line @typescript-eslint/naming-convention
  protected readonly planningScale = PlanningScale;

  constructor(
    public projectExpensesService: ProjectExpensesService,
    public service: ProjectExpensesCalendarService,
  ) {}
}
