import {
  ChangeDetectionStrategy,
  Component,
  DestroyRef,
  Input,
  OnInit,
  inject,
} from '@angular/core';
import { GradeCardService } from './grade-card.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Validators, UntypedFormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { DataService } from 'src/app/core/data.service';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Constants } from 'src/app/shared/globals/constants';
import { Exception } from 'src/app/shared/models/exception';
import { Grade } from 'src/app/shared/models/entities/settings/grade.model';
import { BlockUIService } from 'src/app/core/block-ui.service';

@Component({
  selector: 'wp-grade-card',
  templateUrl: './grade-card.component.html',
  providers: [GradeCardService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class GradeCardComponent implements OnInit {
  @Input() entityId: string;

  public readonly: boolean;
  public isSaving$ = new BehaviorSubject<boolean>(false);
  public gradeForm = this.fb.group({
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    code: '',
    level: [null, Validators.required],
    isActive: false,
  });

  private destroyRef = inject(DestroyRef);

  constructor(
    public gradeCardService: GradeCardService,
    private fb: UntypedFormBuilder,
    private dataService: DataService,
    private actionPanelService: ActionPanelService,
    private messageService: MessageService,
    private notificationService: NotificationService,
    private blockUIService: BlockUIService,
  ) {}

  public ngOnInit(): void {
    this.gradeForm.controls.name.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => this.gradeCardService.updateName(value));

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.reload());

    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.gradeCardService.grade$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((grade: Grade) => {
        this.gradeForm.patchValue(grade);
        this.readonly = !grade.editAllowed;
        this.readonly ? this.gradeForm.disable() : this.gradeForm.enable();
        this.gradeForm.controls.level.disable();
      });
  }

  /** Reloads data. */
  public reload(): void {
    if (!this.gradeForm.dirty) {
      this.gradeCardService.load();
    } else {
      this.messageService.confirmLocal('shared.leavePageMessage').then(
        () => {
          this.gradeForm.markAsPristine();
          this.gradeCardService.load();
        },
        () => null,
      );
    }
  }

  /** Saves data. */
  public save(): void {
    this.gradeForm.markAllAsTouched();

    if (this.gradeForm.invalid) {
      this.notificationService.warningLocal(
        'shared.messages.requiredFieldsError',
      );
      return;
    }

    this.isSaving$.next(true);
    this.actionPanelService.action('save').start();
    this.blockUIService.start();

    this.dataService
      .collection('Grades')
      .entity(this.entityId)
      .patch(this.gradeForm.value)
      .subscribe({
        next: () => {
          this.gradeForm.markAsPristine();
          this.isSaving$.next(false);
          this.actionPanelService.action('save').stop();
          this.blockUIService.stop();
          this.notificationService.successLocal(
            'settings.grades.card.messages.saved',
          );
        },
        error: (error: Exception) => {
          this.isSaving$.next(false);
          this.actionPanelService.action('save').stop();
          this.blockUIService.stop();
          this.notificationService.error(error.message);
        },
      });
  }
}
