import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnInit,
  OnDestroy,
  AfterViewInit,
  ViewChildren,
  QueryList,
} from '@angular/core';

import { NgbSlideEvent } from '@ng-bootstrap/ng-bootstrap';

import _isFunction from 'lodash/isFunction';
import { saveAs } from 'file-saver';

import { ImageZoomDirective } from 'src/app/shared/directives/image-zoom/image-zoom.directive';
import { ViewerComponentParams } from 'src/app/shared/components/features/viewer/viewer.interface';
import {
  AttachmentTemplate,
  AttachmentItem,
} from 'src/app/shared/models/entities/attachments/attachment.interface';
import { AttachmentHelper } from 'src/app/shared/models/entities/attachments/attachment.helper';

@Component({
  selector: 'tmt-viewer',
  templateUrl: './viewer.component.html',
  styleUrls: ['./viewer.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ViewerComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChildren(ImageZoomDirective)
  private zoomDirectives: QueryList<ImageZoomDirective>;

  @Input() public params: ViewerComponentParams;

  public pdfZoom = 1;
  public activeAttachmentTemplate: AttachmentTemplate = 'other';
  public activeZoomItem: ImageZoomDirective | null = null;
  public readonly typeWithZoom: AttachmentTemplate[] = ['image', 'pdf'];
  public readonly attachmentStatuses: Record<
    string,
    AttachmentItem['dataReadyStatus']
  > = {};

  constructor(private cdr: ChangeDetectorRef) {}

  public ngOnInit(): void {
    this.params.activeId = String(
      this.params.activeId || this.params.attachments[0].id,
    );
    this.prepareAttachments();
    this.lazyLoad(this.activeItem);
    this.activeAttachmentTemplate = this.activeItem.template;
  }

  public ngAfterViewInit(): void {
    this.checkZoom(this.activeItem);
  }

  public ngOnDestroy(): void {
    this.clear();
  }

  /**
   * Get attachment from current slide.
   *
   * @return attachment item.
   * */
  public get activeItem(): AttachmentItem {
    return this.params.attachments.find((el) => el.id === this.params.activeId);
  }

  /**
   * Slide transition handler.
   *
   * @param event NgbSlideEvent.
   *
   * */
  public onSlide(event: NgbSlideEvent): void {
    this.params.activeId = String(event.current);
    const item: AttachmentItem = this.activeItem;
    this.activeAttachmentTemplate = item.template;
    this.pdfZoom = 1;
    this.lazyLoad(item);
    this.checkZoom(item);
    this.cdr.markForCheck();
  }

  /**
   * Download the file.
   *
   * @param file AttachmentItem.
   *
   * */
  public saveFile(file: AttachmentItem): void {
    if (!file.data) {
      this.attachmentStatuses[file.id] = 'awaiting';
      this.cdr.markForCheck();
      this.lazyLoad(file, () => saveAs(file.data, file.name));

      return;
    }

    saveAs(file.data, file.name);
  }

  /** Zoom in! */
  public zoomIn(): void {
    this.activeZoomItem?.zoomIn();

    if (this.activeAttachmentTemplate === 'pdf') {
      this.pdfZoom += 0.2;
    }
  }

  /** Zoom out! */
  public zoomOut(): void {
    this.activeZoomItem?.zoomOut();

    if (this.activeAttachmentTemplate === 'pdf') {
      this.pdfZoom -= 0.2;
    }
  }

  private prepareAttachments(): void {
    this.params.attachments.forEach((item) => {
      item.id = String(item.id);
      item.template = AttachmentHelper.getTemplateType(
        item.ext || item.name.split('.').pop().toLowerCase(),
      );

      if (item.data) {
        item.url = window.URL.createObjectURL(item.data);
      }

      this.attachmentStatuses[item.id] =
        _isFunction(item.dataLazyLoad) && item.template !== 'other'
          ? 'awaiting'
          : 'success';
    });
  }

  private lazyLoad(item: AttachmentItem, onSuccess?: () => void): void {
    if (item.data) {
      this.attachmentStatuses[item.id] = 'success';
      return;
    }

    if (this.attachmentStatuses[item.id] === 'awaiting') {
      this.attachmentStatuses[item.id] = 'loading';

      item
        .dataLazyLoad()
        .then((response) => {
          item.data = response;
          item.url = window.URL.createObjectURL(response);
          this.attachmentStatuses[item.id] = 'success';

          if (onSuccess) {
            onSuccess();
          }
        })
        .catch(() => {
          this.attachmentStatuses[item.id] = 'fail';
        })
        .finally(() => {
          this.cdr.markForCheck();
        });
    }
  }

  private checkZoom(item: AttachmentItem): void {
    if (item.template === 'image') {
      this.activeZoomItem = this.zoomDirectives.find(
        (el) => el.imageId === item.id,
      );
    } else {
      this.activeZoomItem = null;
    }
  }

  private clear(): void {
    this.params.attachments.forEach((item) => {
      window.URL.revokeObjectURL(item.url);
    });
  }
}
