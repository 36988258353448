import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Output,
  EventEmitter,
  ViewChildren,
  QueryList,
  inject,
  DestroyRef,
} from '@angular/core';
import {
  Validators,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import _ from 'lodash';
import { Observable, of, filter, switchMap, tap } from 'rxjs';

import { DataService } from 'src/app/core/data.service';
import { naturalSort } from 'src/app/shared/helpers/natural-sort.helper';
import { Constants } from 'src/app/shared/globals/constants';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  ResourceRequest,
  ResourceRequestTemplate,
  ResourceRequestUser,
} from 'src/app/shared/models/entities/resources/resource-request.model';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { Resource } from 'src/app/shared/models/entities/settings/resource.model';
import { ProjectTeamMember } from 'src/app/shared/models/entities/projects/project-team-member.model';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';

import { ResourceRequestCalendarService } from 'src/app/resource-requests/shared/calendar/resource-request-calendar.service';
import { ResourceRequestService } from '../resource-request/resource-request.service';
import {
  ResourceRequestEvents,
  ResourceRequestMode,
} from '../resource-request/resource-request.interface';
import {
  BoxControlComponent,
  FormHelper,
} from 'src/app/shared/helpers/form-helper';

@Component({
  selector: 'tmt-resource-request-form-main',
  templateUrl: './resource-request-form-main.component.html',
  styleUrls: ['./resource-request-form-main.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ResourceRequestFormMainComponent implements OnInit {
  @ViewChildren('cascadeControl')
  private cascadeControls: QueryList<BoxControlComponent>;

  @Input() public mode: ResourceRequestMode = 'edit';
  @Output() public form$ = new EventEmitter<UntypedFormGroup>();

  public readonly = false;
  public form: UntypedFormGroup = this.fb.group({
    id: null,
    state: null,
    opened: null,
    completed: null,
    project: [null, Validators.required],
    role: null,
    competence: null,
    resourcePool: [null, Validators.required],
    from: null,
    to: null,
    requestedHours: null,
    bookedHours: null,
    requirementEntries: null,
    resource: null,
    resourceDescription: null,
    teamMemberId: null,
    grade: null,
    level: null,
    location: null,
    legalEntity: null,
    employmentTypeId: null,
    name: null,
    note: ['', Validators.maxLength(Constants.formTextMaxLength)],
  });
  public users: ResourceRequestUser[] = [];
  public cascadeListener: () => void | null = null;
  public readonly formTextMaxLength: number = Constants.formTextMaxLength;

  private destroyRef = inject(DestroyRef);

  public get request(): any {
    return this.form.getRawValue();
  }

  constructor(
    private resourceRequestService: ResourceRequestService,
    private resourceRequestCalendarService: ResourceRequestCalendarService,
    private dataService: DataService,
    private cdr: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
  ) {}

  public ngOnInit(): void {
    this.setChangeDetectionFix();
    this.form$.emit(this.form);

    this.resourceRequestService.request$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        if (value.id) {
          const request = value as ResourceRequest;
          this.readonly = this.resourceRequestService.readonly;
          this.form.patchValue(request, { emitEvent: false });

          this.users = [
            this.buildUserItem(
              request.teamMember,
              request.teamMember?.resource,
            ),
          ];
          this.form.patchValue(
            {
              resource: this.users[0],
            },
            { emitEvent: false },
          );
        } else {
          const requestTemplate = value as ResourceRequestTemplate;
          this.form.patchValue(requestTemplate, { emitEvent: false });

          if (this.mode !== 'menuCreate') {
            this.users = [
              this.buildUserItem(
                {
                  id: requestTemplate.teamMemberId,
                  description: requestTemplate.resourceDescription,
                },
                requestTemplate.resource,
              ),
            ];
          }

          this.form.patchValue(
            {
              resource:
                this.users.find(
                  (el) => el.teamMemberId === requestTemplate.teamMemberId,
                ) ?? this.users[0],
            },
            { emitEvent: false },
          );
        }

        this.toggleFormEnable();
        this.cdr.markForCheck();
        this.initCascade();
      });

    this.resourceRequestService.event$
      .pipe(
        filter((event) => event.name === ResourceRequestEvents.updatedRequest),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.form.patchValue(this.resourceRequestService.request, {
          emitEvent: false,
        });
        this.cdr.markForCheck();
      });

    this.resourceRequestService.onChange(this.form.getRawValue(), 'create');
    this.initFormSubscribers();
  }

  public ngAfterViewInit(): void {
    this.initCascade();
  }

  public trackById(index: number, row: any): string {
    return row.id;
  }

  private initCascade(): void {
    if (!this.cascadeControls?.length) {
      return;
    }

    if (this.cascadeListener) {
      this.cascadeListener();
    }

    this.cascadeListener = FormHelper.cascadeDependency(
      this.form,
      this.cascadeControls,
      [
        [
          {
            controlName: 'level',
          },
          { controlName: 'grade', dependedProperty: 'levelId' },
        ],
        [
          {
            controlName: 'role',
          },
          { controlName: 'competence', dependedProperty: 'roleId' },
        ],
      ],
      takeUntilDestroyed(this.destroyRef),
    );

    this.cdr.detectChanges();
  }

  /**
   * Returns `TeamMember` collection.
   *
   * @param projectId
   *
   * @return Observable
   * */
  private getTeamMembers(
    projectId: string,
  ): Observable<Partial<ProjectTeamMember[]>> {
    if (!projectId) {
      return of([]);
    }

    const query: any = {
      select: ['id', 'isActive', 'description'],
      filter: [
        {
          isActive: true,
          resource: {
            resourceType: { ne: ResourceType.department },
          },
        },
      ],
      expand: {
        resource: {
          select: ['id', 'name', 'resourceType'],
        },
      },
    };

    ProjectVersionUtil.addProjectEntityIdFilter(query, null, projectId);

    return this.dataService
      .collection('ProjectTeamMembers')
      .query<Partial<ProjectTeamMember[]>>(query);
  }

  private initFormSubscribers(): void {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((value) => {
        this.resourceRequestService.onChange(value, this.mode);
        this.cdr.markForCheck();
      });

    if (this.mode === 'menuCreate') {
      this.form.controls.project.valueChanges
        .pipe(
          filter(
            (p) => p.id !== this.resourceRequestService.requestTemplate?.id,
          ),
          switchMap((project: NamedEntity | null) =>
            this.getTeamMembers(project?.id),
          ),
          takeUntilDestroyed(this.destroyRef),
        )
        .subscribe((value) => {
          this.users = value.map((el) => this.buildUserItem(el, el.resource));
          this.users.sort(naturalSort('name'));
          this.users = _.sortBy(
            this.users,
            (el) => el.resourceType === ResourceType.generic,
          );

          this.form.controls.resource.patchValue(null);
        });
    }

    this.form.controls.resource.valueChanges
      .pipe(
        tap((value) => {
          this.form.patchValue({
            level: null,
            grade: null,
            location: null,
            role: null,
            competence: null,
            legalEntity: null,
            teamMemberId: value?.teamMemberId ?? null,
          });

          if (this.resourceRequestService.requestTemplate) {
            this.resourceRequestService.requestTemplate.teamMemberId =
              value?.teamMemberId ?? null;
          }

          this.toggleResourceFields();
        }),
        switchMap((value) =>
          !value
            ? of(null)
            : this.resourceRequestService.loadResourceRequestTemplate(
                value.teamMemberId,
                false,
              ),
        ),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe({
        next: () => {
          this.resourceRequestService.onChange(
            this.form.getRawValue(),
            'menuCreate',
          );

          this.resourceRequestCalendarService.loadFrame(null);
        },
        error: () => null,
      });
  }

  private buildUserItem(
    teamMember: Partial<ProjectTeamMember>,
    resource: Partial<Resource>,
  ): ResourceRequestUser {
    if (!teamMember) {
      return null;
    }

    return {
      teamMemberId: teamMember.id,
      id: resource?.id ?? teamMember.id,
      name: resource?.name ?? teamMember.description,
      resourceType: resource.resourceType,
    };
  }

  private toggleResourceFields(): void {
    const fieldToDisable: Partial<keyof ResourceRequest>[] = [];

    if (this.request.resourcePool) {
      fieldToDisable.push('resourcePool');
    }

    if (this.mode !== 'menuCreate') {
      this.form.controls['resource'].disable({ emitEvent: false });
    }

    if (
      (this.request.resource &&
        this.request.resource.resourceType !== ResourceType.generic) ||
      this.readonly
    ) {
      fieldToDisable.forEach((key) => {
        this.form.controls[key].disable({ emitEvent: false });
      });
    } else {
      fieldToDisable.forEach((key) => {
        this.form.controls[key].enable({ emitEvent: false });
      });
    }
  }

  private toggleFormEnable(): void {
    if (this.readonly) {
      this.form.disable({ emitEvent: false });
    } else {
      this.form.enable({ emitEvent: false });
    }

    this.toggleResourceFields();
  }

  private setChangeDetectionFix(): void {
    // TODO: check and fix this code
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    const origFunc = this.form.markAllAsTouched;
    this.form.markAllAsTouched = function () {
      origFunc.apply(this);
      self.cdr.markForCheck();
    };
  }
}
