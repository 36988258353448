import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  Input,
  OnInit,
  signal,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import _ from 'lodash';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridModule } from 'src/app/shared-features/grid/grid.module';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { Entity } from 'src/app/shared/models/entities/entity.model';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { ListService } from 'src/app/shared/services/list.service';
import { SharedModule } from 'src/app/shared/shared.module';

/** Adding entities modal window. */
@Component({
    selector: 'tmt-add-entities-modal',
    templateUrl: './add-entities-modal.component.html',
    styleUrls: ['./add-entities-modal.component.scss'],
    imports: [SharedModule, GridModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export abstract class AddEntitiesModalComponent
  implements OnInit, AfterViewInit
{
  @Input() public parentEntityId!: string;
  @Input() public existedEntities: Entity[] = [];

  /**  Field to sort on client after removing. It can be nested.
   *
   * @example
   * 'resource.name',
   * 'name'
   */
  protected fieldToSortOnClient: string;

  protected selectionLimitWarning: string;
  protected modalWindowHeader: string;
  protected noDisplayDataMessage: string;

  protected hasAvatar: boolean;
  protected loadingLimit = 250;
  protected selectionLimit = 30;
  protected formArray: UntypedFormArray;
  protected gridOptions: GridOptions;
  protected availableEntities: NamedEntity[] = [];
  protected selectedEntities: NamedEntity[] = [];

  protected readonly isSaving = signal<boolean>(false);
  protected readonly isLoading = signal<boolean>(false);
  protected readonly loadedPartly = signal<boolean>(false);

  protected readonly activeModal = inject(NgbActiveModal);
  protected readonly cdr = inject(ChangeDetectorRef);
  protected readonly dataService = inject(DataService);
  protected readonly destroyRef = inject(DestroyRef);
  protected readonly fb = inject(UntypedFormBuilder);
  protected readonly filterService = inject(FilterService);
  protected readonly gridService = inject(GridService);
  protected readonly listService = inject(ListService);
  protected readonly notification = inject(NotificationService);

  constructor() {
    this.formArray = this.fb.array([]);
    this.gridOptions = {
      toolbar: AbstractToolbar,
      resizableColumns: true,
      selectionType: SelectionType.row,
      view: this.listService.getGridView(),
      commands: [{ name: 'setUserView', handlerFn: () => this.setUserView() }],
    };

    this.filterService.values$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.load());
  }

  public ngOnInit(): void {
    this.load();
  }

  public ngAfterViewInit(): void {
    this.gridService.selectedGroup$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((group) => {
        if (!group) return;
        const index = this.formArray.controls.findIndex(
          (control) => control.value.id === group.value.id,
        );
        const entity = this.availableEntities.find(
          (entity) => entity.id === group.value.id,
        );

        this.selectEntity(entity, index);
      });
  }

  /** Loads data. */
  protected abstract load(): void;

  /** Saves data. */
  protected abstract ok(): void;

  /** Creates a form group for a given entity. */
  protected abstract getEntityFormGroup(entity: NamedEntity): UntypedFormGroup;

  /** Dismisses changes. */
  protected cancel(): void {
    this.activeModal.dismiss('cancel');
  }

  /**
   * Removes entity.
   *
   * @param entity entity to unselect.
   * @param index number.
   */
  protected removeEntity(
    entityTotal: NamedEntity,
    index: number,
    event: any,
  ): void {
    event.stopPropagation();
    this.selectedEntities.splice(index, 1);

    this.availableEntities.push(entityTotal);
    this.availableEntities = _.sortBy(this.availableEntities, ['name']);

    const group = this.getEntityFormGroup(entityTotal);
    this.formArray.push(group);
    this.formArray.controls = _.sortBy(this.formArray.controls, [
      (control: AbstractControl) =>
        _.get(control.getRawValue(), this.fieldToSortOnClient),
    ]);
  }

  /**
   * Selects entities.
   *
   * @param entity entity.
   * @param index number.
   */
  private selectEntity(entity: NamedEntity, index: number): void {
    if (this.selectedEntities.length === this.selectionLimit) {
      this.notification.warningLocal(this.selectionLimitWarning);
      return;
    }

    this.selectedEntities.push(entity);
    this.selectedEntities = _.sortBy(this.selectedEntities, [
      this.fieldToSortOnClient,
    ]);

    this.availableEntities.splice(index, 1);
    this.formArray.controls.splice(index, 1);
  }

  /** Opens view configuration dialog. */
  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
        this.load();
      },
      () => null,
    );
  }
}
