import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'wp-assessment-cell',
  templateUrl: './assessment-cell.component.html',
  styleUrls: ['./assessment-cell.component.scss'],
  standalone: false,
})
export class AssessmentCellComponent implements GridComponentCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() initialValue: unknown;

  constructor(public service: GridService) {}
}
