import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { SettingsCardService } from './settings-card.service';
import { TranslateService } from '@ngx-translate/core';
import { StateService } from '@uirouter/core';
import { environment } from 'src/environments/environment';
import { TabLink } from 'src/app/shared/models/navigation/navigation';

@Component({
  selector: 'wp-settings',
  templateUrl: './settings.component.html',
  providers: [SettingsCardService],
  standalone: false,
})
export class SettingsComponent implements OnInit {
  isReadMode = !this.app.checkPermission(
    'ManageAccount',
    null,
    PermissionType.Execute,
  );
  allowBillingManage = this.app.checkPermission(
    'ManageBilling',
    null,
    PermissionType.Execute,
  );

  public formName = this.translate.instant('settings.settings.header');

  public tabs: TabLink[] = [];

  constructor(
    public state: StateService,
    private translate: TranslateService,
    public service: SettingsCardService,
    private app: AppService,
  ) {}

  ngOnInit() {
    if (!environment.onprem) {
      this.tabs.push({
        header: 'settings.settings.tabs.main',
        state: 'settings.settings.main',
      });
    }

    if (this.allowBillingManage && !environment.onprem) {
      this.tabs.push({
        header: 'settings.settings.tabs.billing',
        state: 'settings.settings.billing',
      });
    }

    if (!this.isReadMode) {
      this.tabs.push({
        header: 'settings.settings.tabs.system',
        state: 'settings.settings.system',
      });
      this.tabs.push({
        header: 'settings.settings.tabs.applications',
        state: 'settings.settings.applications',
      });
      this.tabs.push({
        header: 'settings.settings.tabs.security',
        state: 'settings.settings.security',
      });

      if (!environment.onprem) {
        this.tabs.push({
          header: 'settings.settings.tabs.domains',
          state: 'settings.settings.domains',
        });
      }

      this.tabs.push({
        header: 'settings.settings.tabs.dad',
        state: 'settings.settings.dad',
      });
    }
  }
}
