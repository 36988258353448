import { Component, ChangeDetectionStrategy, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ClientCardService } from 'src/app/clients/card/client-card.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AppService } from 'src/app/core/app.service';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';

@Component({
  selector: 'tmt-client-settings',
  templateUrl: './client-settings.component.html',
  styleUrl: './client-settings.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ClientSettingsComponent {
  public readonly propagationMode = PropagationMode;
  public readonly appService = inject(AppService);
  public readonly clientCardService = inject(ClientCardService);
  public readonly savingQueueService = inject(SavingQueueService);

  private readonly actionPanelService = inject(ActionPanelService);

  constructor() {
    this.actionPanelService.reload$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.clientCardService.load(this.clientCardService.clientId);
    });
  }
}
