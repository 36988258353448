import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { Subject } from 'rxjs';
import { LicenseProductInfo } from 'src/app/shared/models/entities/settings/license-info.model';
import { FormBuilder } from '@angular/forms';
import { ModalForm } from 'src/app/shared/models/inner/modal-form.interface';
import { LicenseProduct } from 'src/app/shared/models/session/client-session.model';

@Component({
  selector: 'tmt-product-selector-modal',
  templateUrl: './product-selector-modal.component.html',
  styleUrls: ['./product-selector-modal.component.scss'],
  standalone: false,
})
export class ProductSelectorModalComponent implements OnInit, ModalForm {
  @Input() licenseProducts: LicenseProductInfo[] = [];

  public saving$ = new Subject<boolean>();
  public form = this.fb.group({});
  isSharedModal = true;

  public selectedProducts: LicenseProduct[] = [];

  constructor(
    private fb: FormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
  ) {}

  ngOnInit() {
    this.licenseProducts = this.licenseProducts.filter(
      (product) => !product.isLegacy,
    );
    this.licenseProducts.forEach((product) => {
      this.form.addControl(product.product, this.fb.control(product.limit > 0));
    });

    this.form.valueChanges.subscribe(() => {
      this.updateSelectedProducts();
    });

    this.updateSelectedProducts();
  }

  private updateSelectedProducts() {
    const value = this.form.value;
    this.selectedProducts = [];
    Object.keys(value).forEach((product: LicenseProduct) => {
      if (value[product]) {
        this.selectedProducts.push(product);
      }
    });
  }

  ok() {
    this.saving$.next(true);
    this.data
      .singleton('Tenant')
      .action('SetTrialProducts')
      .execute({ products: this.selectedProducts })
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.account.productSelector.messages.configured',
          );
          this.saving$.next(false);
          location.reload();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.saving$.next(false);
        },
      });
  }

  cancel() {
    this.activeModal.dismiss('cancel');
  }

  close() {
    this.activeModal.close();
  }
}
