import { Component, DestroyRef, Input, OnInit, inject } from '@angular/core';
import {
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { WorkflowFunctionType } from 'src/app/settings-app/workflows/model/workflow.enum';
import { WorkflowCardService } from '../workflow-card.service';
import { WorkflowFunctionCondition } from '../../model/workflow-function.model';
import { Guid } from 'src/app/shared/helpers/guid';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NotificationService } from 'src/app/core/notification.service';
import { WorkflowConditionToolbarComponent } from './workflow-condition-toolbar/workflow-condition-toolbar.component';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import {
  GridColumnType,
  GridEntityColumn,
} from 'src/app/shared-features/grid/models/grid-column.interface';

@Component({
  selector: 'wp-workflow-condition',
  templateUrl: './workflow-condition.component.html',
  styleUrls: ['./workflow-condition.component.scss'],
  providers: [GridService],
  standalone: false,
})
export class WorkflowConditionComponent implements OnInit {
  @Input() workflowFunction?: WorkflowFunctionCondition;
  @Input() lifecycleId: string;

  public isLoading: boolean;
  public isSaving: boolean;
  public readonly: boolean;
  public functionType = WorkflowFunctionType;

  public conditionsGridOptions: GridOptions;

  public form = this.fb.group({
    id: null,
    name: ['', Validators.required],
    conditions: this.fb.array([]),
    type: null,
  });

  private destroyRef = inject(DestroyRef);
  private workflowFunctions = [];

  public get conditionsFormArray(): UntypedFormArray {
    return this.form.controls.conditions as UntypedFormArray;
  }

  constructor(
    private activeModal: NgbActiveModal,
    private fb: UntypedFormBuilder,
    public gridService: GridService,
    private notification: NotificationService,
    public workflowCardService: WorkflowCardService,
  ) {}

  public ngOnInit(): void {
    this.load();

    this.workflowCardService.addTransition$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((newGroup: UntypedFormGroup) => {
        this.addNewConditionGroup(newGroup);
        this.gridService.detectChanges();
      });

    this.workflowCardService.workflow?.functions.forEach((func) => {
      if (func.type !== WorkflowFunctionType.start) {
        this.workflowFunctions.push({ id: func.id, name: func.name });
      }
    });
    this.initGridOptions();
  }

  /**
   * Gets header for transitionFormGroup.
   *
   * @returns header.
   */
  public getHeader(): string {
    return this.workflowFunction.name
      ? this.workflowFunction.name
      : 'settings.workflows.card.function.createCondition';
  }

  /** Closes modal and sends data to service. */
  public ok(): void {
    if (!this.form.valid) {
      this.form.markAllAsTouched();
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    const newWorkflowFunction = this.form.getRawValue();
    this.activeModal.close(newWorkflowFunction);
  }

  /** Closes modal and dismisses changes. */
  public cancel = (): void => {
    this.activeModal.dismiss('cancel');
  };

  private load(): void {
    if (!this.workflowFunction) {
      return;
    }

    this.form.patchValue(this.workflowFunction);
    this.workflowFunction?.conditions?.forEach((condition) => {
      condition.id = Guid.generate();
      const group = this.getConditionFormGroup();
      group.patchValue(condition);
      group.controls.moveToStateId.disable();
      group.controls.nextFunctionId.disable();
      this.conditionsFormArray.push(group);
    });
  }

  private addNewConditionGroup(condition): void {
    const conditionGroup = this.getConditionFormGroup();
    conditionGroup.patchValue(condition);
    conditionGroup.controls.moveToStateId.disable();
    conditionGroup.controls.nextFunctionId.disable();
    this.conditionsFormArray.insert(0, conditionGroup);
  }

  private getConditionFormGroup(): UntypedFormGroup {
    return this.fb.group({
      id: '',
      name: '',
      nextFunctionId: null,
      moveToStateId: null,
      expression: null,
      isElse: false,
    });
  }

  private deleteCondition(id: string): void {
    const conditions = this.conditionsFormArray as UntypedFormArray;

    const index = conditions.value.findIndex(
      (condition) => condition.id === id,
    );

    if (index >= 0) {
      conditions.removeAt(index);
    }
    this.gridService.detectChanges();
  }

  private initGridOptions(): void {
    this.conditionsGridOptions = {
      toolbar: WorkflowConditionToolbarComponent,
      selectionType: SelectionType.row,
      commands: [
        {
          name: 'create',
          handlerFn: () => {
            const newCondition = this.getConditionFormGroup();
            this.workflowCardService.editCondition(
              this.workflowFunction.id,
              newCondition,
            );
          },
        },
        {
          name: 'edit',
          allowedFn: (group) => !!group,
          handlerFn: () => {
            const condition = this.gridService.selectedGroup$.getValue();
            this.workflowCardService.editCondition(
              this.workflowFunction.id,
              condition,
            );
          },
        },
        {
          name: 'delete',
          allowedFn: (group) => !!group && !group?.isElse,
          handlerFn: () => {
            this.deleteCondition(this.gridService.selectedGroupValue.id);
          },
        },
        {
          name: 'moveUp',
          allowedFn: (group) =>
            !!group &&
            !group?.value?.isElse &&
            this.conditionsFormArray.value.findIndex(
              (g) => g.id === group?.value?.id,
            ) !== 0,
          handlerFn: (group) => {
            const groupIndex = this.conditionsFormArray.value.findIndex(
              (g) => g.id === group.value?.id,
            );
            this.conditionsFormArray.removeAt(groupIndex);
            this.conditionsFormArray.insert(groupIndex - 1, group);
            this.gridService.selectGroup(group);
          },
        },
        {
          name: 'moveDown',
          allowedFn: (group) =>
            !!group &&
            this.conditionsFormArray.value.findIndex(
              (g) => g.id === group?.value?.id,
            ) <
              this.conditionsFormArray.length - 2,
          handlerFn: (group) => {
            const groupIndex = this.conditionsFormArray.value.findIndex(
              (g) => g.id === group.value.id,
            );

            this.conditionsFormArray.removeAt(groupIndex);
            this.conditionsFormArray.insert(groupIndex + 1, group);
            this.gridService.selectGroup(group);
          },
        },
      ],
      rowCommands: [
        {
          name: 'edit',
          label: 'shared.actions.edit',
          handlerFn: (group) => {
            this.workflowCardService.editCondition(
              this.workflowFunction.id,
              group,
            );
          },
        },
        {
          name: 'delete',
          label: 'shared.actions.delete',
          handlerFn: (group) => {
            this.deleteCondition(group.getRawValue().id);
          },
          allowedFn: (group) => group?.value?.isElse !== true,
        },
      ],
      view: {
        name: 'conditions',
        columns: [
          {
            name: 'name',
            header: 'settings.workflows.card.transitions.name',
            hint: 'settings.workflows.card.transitions.name',
            type: GridColumnType.String,
            forceCellUpdating: true,
          },
          {
            name: 'expression',
            header: 'settings.workflows.card.function.expression',
            hint: 'settings.workflows.card.function.expression',
            type: GridColumnType.String,
            forceCellUpdating: true,
          },
          <GridEntityColumn>{
            name: 'nextFunctionId',
            header: 'settings.workflows.card.transitions.nextFunction',
            hint: 'settings.workflows.card.transitions.nextFunction',
            type: GridColumnType.Entity,
            values: this.workflowFunctions,
            forceCellUpdating: true,
          },
          <GridEntityColumn>{
            name: 'moveToStateId',
            header: 'settings.workflows.card.transitions.nextState',
            hint: 'settings.workflows.card.transitions.nextState',
            type: GridColumnType.Entity,
            values: this.workflowCardService.getStates(),
            forceCellUpdating: true,
          },
        ],
      },
    };
  }
}
