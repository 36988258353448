<wp-loading-indicator [loading]="loading$ | async"></wp-loading-indicator>

@if (!(loading$ | async)) {
  <form class="modal-form" novalidate [formGroup]="form" ngDraggable>
    <div class="modal-header">
      @if (projectId) {
        <h3 class="modal-title">{{ mainForm?.value.name }}</h3>
      } @else {
        <h3 class="modal-title">
          {{ 'projects.projects.settings.newProject' | translate }}
        </h3>
      }
      <button
        [disabled]="saving$ | async"
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
      ></button>
    </div>
    <div class="modal-body">
      <ul ngbNav #nav="ngbNav" [destroyOnHide]="false" class="nav-tabs">
        <li [ngbNavItem]>
          <a [ngClass]="{ 'invalid-tab': mainForm.invalid && mainForm.touched }" ngbNavLink>{{
            'projects.projects.settings.tabs.main' | translate
          }}</a>
          <ng-template ngbNavContent>
            <div formGroupName="main" class="row pt-3">
              <div class="col-6">
                <div class="form-group" tmtIndicator>
                  <label>{{ 'projects.projects.settings.props.name.label' | translate }}</label>
                  <wp-text-box
                    placeholder="{{ 'projects.projects.settings.props.name.ph' | translate }}"
                    formControlName="name"
                  />
                </div>
                <div class="form-group" tmtIndicator>
                  <label>
                    <span class="editable-property">{{
                      'projects.projects.settings.props.billingType' | translate
                    }}</span>
                    @if (project?.changingBillingTypeAllowed) {
                      <button
                        type="button"
                        class="btn btn-link btn-link-content editable-property-btn"
                        (click)="changeProperty('billingType')"
                      >
                        {{ 'projects.projects.settings.actions.changeBillingType' | translate }}
                      </button>
                    }
                  </label>
                  <div class="clearfix"></div>
                  @if (creatingMode) {
                    <div
                      class="btn-group btn-group-toggle"
                      radioGroup
                      name="radioBasic"
                      formControlName="billingTypeId"
                    >
                      @for (type of billingTypes; track type) {
                        <label radioLabel class="btn-default trim fw-normal mb-0">
                          <input radioButton type="radio" [value]="type.id" />{{
                            type.name | translate
                          }}
                        </label>
                      }
                    </div>
                  } @else {
                    <div class="form-control form-control-plaintext">
                      {{ project?.billingType.name }}
                    </div>
                  }
                </div>
                <div class="form-group">
                  <label>{{ 'projects.projects.settings.props.client.label' | translate }}</label>
                  <wp-select-box
                    collection="Organizations"
                    placeholder="{{ 'projects.projects.settings.props.client.label' | translate }}"
                    formControlName="organization"
                  />
                  @if (project?.hasLinkedInvoices) {
                    <div class="text-body-secondary">
                      <i class="bi bi-exclamation-circle" aria-hidden="true"></i>&nbsp;{{
                        'projects.projects.settings.props.client.clientRestrict' | translate
                      }}
                    </div>
                  }
                </div>
                <div class="form-group" tmtIndicator>
                  <label>{{ 'projects.projects.settings.props.manager.label' | translate }}</label>
                  <wp-user-box
                    placeholder="{{ 'projects.projects.settings.props.manager.ph' | translate }}"
                    formControlName="manager"
                  />
                </div>
                <div class="form-group">
                  <label>{{ 'projects.projects.settings.props.externalUrl' | translate }}</label>
                  <wp-text-box
                    placeholder="{{ 'projects.projects.settings.props.externalUrl' | translate }}"
                    formControlName="externalUrl"
                  />
                </div>
                <div class="form-group">
                  <label>{{
                    'projects.projects.settings.props.description.label' | translate
                  }}</label>
                  <wp-multiline-text-box
                    formControlName="description"
                    placeholder="{{
                      'projects.projects.settings.props.description.ph' | translate
                    }}"
                    rows="4"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="form-group code-field-group">
                  <label>{{ 'shared.props.code' | translate }}</label>
                  <wp-text-box
                    placeholder="{{ 'shared.props.code' | translate }}"
                    formControlName="code"
                  />
                </div>

                <div class="form-group" tmtIndicator>
                  <label>
                    {{ 'projects.projects.settings.props.schedule.label' | translate }}
                  </label>
                  <button
                    type="button"
                    class="btn btn-link btn-action mx-1"
                    tmtDocsArticle="settings/time-accounting/work-schedule"
                  >
                    <i class="bi bi-question-circle" aria-hidden="true"></i>
                  </button>
                  <wp-select-box
                    collection="Schedules"
                    placeholder="{{ 'projects.projects.settings.props.schedule.ph' | translate }}"
                    formControlName="schedule"
                  />
                </div>

                <table>
                  <tr>
                    <td>
                      <div class="form-group" tmtIndicator>
                        <label>{{ 'projects.planStart' | translate }}</label>
                        <wp-date-box formControlName="startDate" />
                      </div>
                    </td>
                    <td class="ps-4">
                      <div class="form-group" tmtIndicator>
                        <label>{{ 'projects.planEnd' | translate }}</label>
                        <wp-date-box formControlName="endDate" />
                      </div>
                    </td>
                  </tr>
                </table>
                @if (!creatingMode || program) {
                  <div class="form-group">
                    <label>
                      <span class="editable-property">{{
                        'projects.projects.settings.props.program' | translate
                      }}</span>
                      @if (canEditProgram) {
                        <button
                          type="button"
                          class="btn btn-link btn-link-content editable-property-btn"
                          (click)="changeProperty('program')"
                        >
                          {{ 'projects.projects.settings.actions.changeProgram' | translate }}
                        </button>
                      }
                    </label>
                    <div class="changeable-form-control">
                      <wp-select-box
                        [readonly]="true"
                        collection="Programs"
                        formControlName="program"
                      />
                    </div>
                  </div>
                }
                @if (creatingMode) {
                  <div class="space-height">&nbsp;</div>
                }
                <div
                  class="form-check"
                  title="{{ 'projects.projects.settings.props.allowTracking' | translate }}"
                >
                  <input
                    formControlName="allowTimeEntry"
                    type="checkbox"
                    class="form-check-input"
                    id="allowTimeEntry"
                  />
                  <label class="form-check-label" for="allowTimeEntry">
                    {{ 'projects.projects.settings.props.allowTracking' | translate }}
                  </label>
                </div>
                <div
                  class="form-check"
                  title="{{ 'projects.projects.settings.props.skipManagerApprove' | translate }}"
                >
                  <input
                    formControlName="skipManagerApprove"
                    type="checkbox"
                    class="form-check-input"
                    id="skipManagerApprove"
                  />
                  <label class="form-check-label" for="skipManagerApprove">
                    {{ 'projects.projects.settings.props.skipManagerApprove' | translate }}
                  </label>
                </div>

                <hr />

                <div
                  class="form-check"
                  title="{{ 'projects.projects.settings.props.isAutoPlanning' | translate }}"
                >
                  <input
                    formControlName="isAutoPlanning"
                    type="checkbox"
                    class="form-check-input"
                    id="isAutoPlanning"
                  />
                  <label class="form-check-label" for="isAutoPlanning">
                    {{ 'projects.projects.settings.props.isAutoPlanning' | translate }}
                  </label>
                  <button
                    type="button"
                    class="btn btn-link btn-action mx-1"
                    tmtDocsArticle="projects/conceptions/planning/modes"
                  >
                    <i class="bi bi-question-circle" aria-hidden="true"></i>
                  </button>
                </div>
                @if (msgKeyIsAutoPlanningChanged) {
                  <div class="alert alert-warning p-2" role="alert">
                    {{ msgKeyIsAutoPlanningChanged | translate }}
                  </div>
                }
                @if (isAutoPlanning) {
                  <div class="form-group mt-3" tmtIndicator>
                    <label
                      >{{ 'projects.projects.settings.props.defaultTaskType.label' | translate }}
                    </label>
                    <button
                      type="button"
                      class="btn btn-link btn-action mx-1"
                      tmtDocsArticle="/projects/conceptions/planning/auto-mode"
                    >
                      <i class="bi bi-question-circle" aria-hidden="true"></i>
                    </button>
                    <wp-select-box
                      [values]="taskTypes"
                      [allowNull]="false"
                      formControlName="defaultTaskType"
                    />
                  </div>
                }
              </div>
            </div>
          </ng-template>
        </li>
        <li [ngbNavItem]>
          <a
            [ngClass]="{ 'invalid-tab': advancedForm.invalid && advancedForm.touched }"
            ngbNavLink
            >{{ 'projects.projects.settings.tabs.advanced' | translate }}</a
          >
          <ng-template ngbNavContent>
            <div class="row" formGroupName="advanced">
              <div class="col-6 pt-3">
                <label class="group">{{
                  'projects.projects.settings.sections.finance' | translate
                }}</label>
                <div class="form-group" tmtIndicator>
                  <label>{{ 'projects.projects.settings.props.currency' | translate }}</label>
                  @if (creatingMode) {
                    <wp-select-box
                      collection="Currencies"
                      placeholder="{{ 'projects.projects.settings.props.currency' | translate }}"
                      formControlName="currency"
                    />
                  } @else {
                    <div class="form-control form-control-plaintext">
                      {{ project?.currency.name }}
                    </div>
                  }
                </div>
                <div class="form-group" tmtIndicator>
                  <label>{{ 'projects.projects.settings.props.legalEntity' | translate }}</label>
                  <wp-select-box
                    collection="legalEntities"
                    placeholder="{{ 'projects.projects.settings.props.legalEntity' | translate }}"
                    formControlName="legalEntity"
                    [allowNull]="false"
                  />
                </div>
                <div class="form-group">
                  <label>{{ 'shared2.props.corporateTaxRate' | translate }}</label>
                  <wp-number-box
                    formControlName="corporateTaxRate"
                    type="percent"
                    [max]="1000"
                  ></wp-number-box>
                </div>
              </div>
              @if (isBillingPlanningAllowed) {
                <div class="col-6 pt-3" formGroupName="billingEstimationSettings">
                  <label class="group">{{
                    'projects.projects.settings.sections.billing' | translate
                  }}</label>
                  <div class="form-group" tmtIndicator>
                    <label
                      >{{ 'projects.projects.settings.props.billingMode.label' | translate }}
                    </label>
                    <button
                      type="button"
                      class="btn btn-link btn-action mx-1"
                      tmtDocsArticle="projects/components/projects/autobilling"
                    >
                      <i class="bi bi-question-circle" aria-hidden="true"></i>
                    </button>
                    <wp-select-box
                      [values]="billingModes"
                      [allowNull]="false"
                      formControlName="billingMode"
                    />
                  </div>
                  @if (isBillingPlanningSettingsAllowed) {
                    <div class="form-group">
                      <label>{{ 'projects.projects.settings.props.deferment' | translate }}</label>
                      <div class="d-flex align-items-center">
                        <tmt-duration-box
                          [min]="0"
                          [max]="365"
                          [allowNull]="false"
                          [durationMode]="durationModes.calendar"
                          formControlName="deferment"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>{{
                        'projects.projects.settings.props.collectionDeferment' | translate
                      }}</label>
                      <div class="d-flex align-items-center">
                        <tmt-duration-box
                          [min]="0"
                          [max]="365"
                          [allowNull]="false"
                          [durationMode]="durationModes.calendar"
                          formControlName="collectionDeferment"
                        />
                      </div>
                    </div>
                    <div class="form-group">
                      <label>{{
                        'projects.projects.settings.props.accumulationPeriod' | translate
                      }}</label>
                      <div class="d-flex align-items-center">
                        <tmt-duration-box
                          [min]="0"
                          [max]="12"
                          [allowNull]="false"
                          [durationMode]="durationModes.calendar"
                          [dimensionItem]="dimensionItems.month"
                          formControlName="accumulationPeriod"
                        />
                      </div>
                    </div>
                  }
                </div>
              }
            </div>
            <wp-custom-fields [formGroup]="advancedForm" [entityType]="'Project'" />
          </ng-template>
        </li>
      </ul>
      <div [ngbNavOutlet]="nav" class="tab-content"></div>
    </div>
    <div class="modal-footer">
      @if (!(readonly$ | async)) {
        <button
          type="button"
          class="btn btn-primary"
          (click)="ok()"
          wpButtonSpinner
          [isBusy]="saving$ | async"
        >
          {{ okButtonText }}
        </button>
      }
      <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="saving$ | async">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </form>
}
