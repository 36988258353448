import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { DateBoxComponent } from 'src/app/shared/components/controls/date-box/date-box.component';
import { Wrapper } from './wrapper.interface';

@Component({
  selector: 'wp-date-box-wrapper',
  template: `<wp-date-box
    [formControl]="control"
    [allowNull]="allowNull"
    [autofocus]="autofocus"
    [readonly]="readonly"
    #component
  ></wp-date-box>`,
  standalone: false,
})
export class DateBoxWrapperComponent implements Wrapper, OnInit {
  @Input() control: UntypedFormControl;
  @Input() allowNull: boolean;
  @Input() autofocus?: boolean;
  @Input() readonly?: boolean;

  @ViewChild('component') component: DateBoxComponent;

  public ngOnInit(): void {
    if (this.allowNull === undefined) {
      this.allowNull = true;
    }
  }
}
