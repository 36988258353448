<ng-container [formGroup]="formGroup">
  <label class="group">
    {{ 'settings.customFields.card.props.groupSettings' | translate }}
  </label>

  <div class="row">
    <div class="col">
      @if (fieldEntityType !== 'TimeAllocation') {
        @if (isStringType) {
          <div class="form-group">
            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
            <wp-text-box
              placeholder="{{ 'settings.customFields.card.props.defaultValue.label' | translate }}"
              formControlName="defaultValue"
              [typeName]="fieldType.toLowerCase()"
            />
          </div>
        }
        @if (fieldType === customFieldType.dateOnly) {
          <div class="form-group">
            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
            <wp-date-box formControlName="defaultValue" />
          </div>
        }
        @if (fieldType === customFieldType.decimal) {
          <div class="form-group">
            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
            <wp-number-box type="decimal" formControlName="defaultValue" />
          </div>
        }
        @if (fieldType === customFieldType.integer) {
          <div class="form-group">
            <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
            <wp-number-box type="integer" formControlName="defaultValue" />
          </div>
        }
      }
      @if (fieldType === customFieldType.directory) {
        <div class="form-group">
          <label>{{ 'settings.customFields.card.props.directory' | translate }}</label>
          <wp-select-box
            formControlName="directoryId"
            [collection]="'Directories'"
            [isIdMode]="true"
            placeholder="{{ 'settings.customFields.card.props.directory' | translate }}"
            [readonly]="true"
          />
        </div>
        <div class="form-group">
          <label>{{ 'settings.customFields.card.props.defaultValue.label' | translate }}</label>
          <wp-select-box
            placeholder="{{ 'settings.customFields.card.props.defaultValue.label' | translate }}"
            [isIdMode]="true"
            [directory]="this.formGroup.controls.directoryId.value"
            formControlName="defaultValue"
          />
        </div>
      }
    </div>
    <div class="col">
      @if (fieldEntityType !== 'TimeSheetLine') {
        <div class="form-group">
          <label>{{ 'settings.customFields.card.props.formColumn' | translate }}</label>
          <wp-select-box
            style="width: 250px"
            [values]="formColumns"
            [allowNull]="false"
            placeholder="{{ 'settings.customFields.card.props.formColumn' | translate }}"
            formControlName="formColumn"
          />
        </div>
      }

      <div class="form-group">
        <label>{{ 'settings.customFields.card.props.orderNumber.label' | translate }}</label>
        <wp-number-box
          type="integer"
          [min]="0"
          [allowNull]="false"
          placeholder="{{ 'settings.customFields.card.props.orderNumber.ph' | translate }}"
          formControlName="orderNumber"
        />
      </div>
      @if (cardService.isRequiredFieldVisible(fieldEntityType)) {
        <div class="form-group">
          <div class="form-check">
            <input
              formControlName="isRequired"
              type="checkbox"
              class="form-check-input"
              id="isRequired"
            />
            <label class="form-check-label" for="isRequired">
              {{ 'settings.customFields.card.props.isRequired' | translate }}
            </label>
          </div>
        </div>
      }
    </div>
  </div>
</ng-container>
