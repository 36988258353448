import { ChangeDetectionStrategy, Component, Input } from '@angular/core';

@Component({
  selector: 'wp-booking-toolbar',
  templateUrl: './booking-toolbar.component.html',
  styleUrls: ['./booking-toolbar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BookingToolbarComponent {
  @Input() public filterHidden = false;
}
