import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  Input,
  OnInit,
  Optional,
  Type,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, UntypedFormGroup } from '@angular/forms';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { GridDataService } from 'src/app/debug-app/grid-test/core/grid-data.service';
import { GridTestRightSideTableComponent } from 'src/app/debug-app/grid-test/grid-test-right-side-table/grid-test-right-side-table.component';
import { GridTestToolbarComponent } from 'src/app/debug-app/grid-test/grid-test-toolbar/grid-test-toolbar.component';
import { GRID_TEST_LIST } from 'src/app/debug-app/grid-test/model/grid-test.list';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridComponent } from 'src/app/shared-features/grid/grid.component';
import {
  GridOptions,
  SelectionType,
} from 'src/app/shared-features/grid/models/grid-options.model';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation';
import { ListService } from 'src/app/shared/services/list.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-grid-test',
  templateUrl: './grid-test.component.html',
  styleUrl: './grid-test.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    GridService,
    GridDataService,
    ListService,
    { provide: LIST, useValue: GRID_TEST_LIST },
    { provide: VIEW_NAME, useValue: 'default' },
    ScheduleNavigationService,
  ],
  standalone: false,
})
export class GridTestComponent implements OnInit {
  @Input() isModalMode = false;

  public settingForm = this.fb.group({
    isShowRightSideComponent: false,
    isShowMultiselectColumn: null,
    isReadonly: false,
    isShowMenuColumn: false,
    selectionType: null,
  });

  public readonly selectionTypes = [
    { id: SelectionType.none.toString(), name: 'none' },
    { id: SelectionType.row.toString(), name: 'row' },
    { id: SelectionType.rows.toString(), name: 'rows' },
    { id: SelectionType.range.toString(), name: 'range' },
  ];

  public gridComponent: Type<unknown>;
  public gridComponentInputs: Record<string, any> = {};

  public gridOptions: GridOptions = {
    resizableColumns: true,
    toolbar: GridTestToolbarComponent,
    selectionType: SelectionType.rows,
    multiSelectColumn: true,
    commands: [{ name: 'setUserView', handlerFn: () => this.setUserView() }],
    rowContextMenu: [
      {
        name: 'testLog',
        label: 'Log group value',
        handlerFn: (formGroup: UntypedFormGroup) =>
          console.log(formGroup.value),
      },
    ],
    view: this.listService.getGridView(),
  };

  private destroyRef = inject(DestroyRef);

  constructor(
    public dataService: GridDataService,
    public gridService: GridService,
    private fb: FormBuilder,
    private listService: ListService,
    private actionPanelService: ActionPanelService,
    private cdr: ChangeDetectorRef,
    @Optional() private modal: NgbModal,
    @Optional() private activeModal: NgbActiveModal,
  ) {}

  public ngOnInit(): void {
    this.dataService.initMockData();
    this.initGridComponent();

    this.patchSettingForm();
    this.subscribeSettingForm();

    this.actionPanelService.reload$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.dataService.formArray.clear();
        this.cdr.detectChanges();
        this.gridService.detectChanges();
        this.dataService.initMockData();
      });

    this.gridService.detectChanges$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.cdr.detectChanges());
  }

  /** Opens grid in the modal. */
  public showInModal(): void {
    const ref = this.modal.open(GridTestComponent);
    ref.componentInstance.isModalMode = true;
    ref.result.then(
      () => null,
      () => null,
    );
  }

  /** Closes modal. */
  public closeModal() {
    this.activeModal?.dismiss('cancel');
  }

  /** Opens view configuration dialog. */
  private setUserView(): void {
    this.listService.setUserView().then(
      () => {
        this.gridOptions.view = this.listService.getGridView();
      },
      () => null,
    );
  }

  private initGridComponent() {
    this.gridComponent = GridComponent;
    this.gridComponentInputs = {
      formArray: this.dataService.formArray,
      options: this.gridOptions,
      readonly: this.settingForm.controls.isReadonly.value,
    };
    this.cdr.detectChanges();
  }

  private rebuildGridComponent(): void {
    this.gridComponent = null;
    this.gridComponentInputs = {};
    this.cdr.detectChanges();
    this.initGridComponent();
  }

  private patchSettingForm(): void {
    this.settingForm.controls.selectionType.patchValue(
      this.selectionTypes.find(
        (t) => t.id === this.gridOptions.selectionType.toString(),
      ),
    );
    this.settingForm.controls.isShowMultiselectColumn.patchValue(
      this.gridOptions.multiSelectColumn,
    );
  }

  private subscribeSettingForm(): void {
    this.settingForm.controls.selectionType.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((selectionType: { id: string; name: string }) => {
        this.gridOptions.selectionType = +selectionType.id;

        this.rebuildGridComponent();
      });

    this.settingForm.controls.isShowMultiselectColumn.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isShowMultilineColumn: boolean) => {
        this.gridOptions.multiSelectColumn = isShowMultilineColumn;

        this.rebuildGridComponent();
      });

    this.settingForm.controls.isShowRightSideComponent.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isShowRightSideComponent: boolean) => {
        if (isShowRightSideComponent) {
          this.gridOptions.rightSideComponent = GridTestRightSideTableComponent;
        } else {
          this.gridOptions.rightSideComponent = null;
        }
        this.rebuildGridComponent();
      });

    this.settingForm.controls.isReadonly.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => this.rebuildGridComponent());

    this.settingForm.controls.isShowMenuColumn.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((isShowMenuColumn: boolean) => {
        if (isShowMenuColumn) {
          this.gridOptions.rowCommands = [
            {
              name: 'edit',
              label: 'shared.actions.edit',
              allowedFn: () => true,
              handlerFn: () => {
                console.log('edit');
              },
            },
            {
              name: 'delete',
              label: 'shared.actions.delete',
              allowedFn: () => true,
              handlerFn: () => console.log('delete'),
            },
          ];
        } else {
          this.gridOptions.rowCommands = null;
        }

        this.rebuildGridComponent();
      });
  }
}
