import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnInit,
} from '@angular/core';
import { LifecycleCardService } from 'src/app/settings-app/lifecycle/card/lifecycle-card.service';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';

@Component({
  selector: 'tmt-kind-toolbar',
  templateUrl: './kind-toolbar.component.html',
  styleUrl: './kind-toolbar.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class KindToolbarComponent extends AbstractToolbar implements OnInit {
  constructor(
    public lifecycleCardService: LifecycleCardService,
    override gridService: GridService,
    override cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
  }

  /** Adds state. */
  public selectState(state: NamedEntity): void {
    this.lifecycleCardService.kindForm.get('addingState').setValue(state);
  }
}
