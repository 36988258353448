import { Component, Input } from '@angular/core';
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { forkJoin, Observable } from 'rxjs';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';

@Component({
  selector: 'wp-users-editing',
  templateUrl: './users-editing.component.html',
  standalone: false,
})
export class UsersEditingComponent {
  /** Идентификаторы сущностей (высший приоритет). */
  @Input() rows: NamedEntity[];

  /** Запрос для поиска сущностей. */
  @Input() filter: string;

  isSaving = false;
  progress = 0;

  public authProviders$ = this.data
    .collection('Users')
    .function('GetAuthProviders')
    .query<AuthProvider[]>();

  form: UntypedFormGroup = this.fb.group({
    supervisor: null,
    department: null,
    scheduleDate: null,
    schedule: null,
    costNormalizationRule: null,
    authProvider: [null],
    resourcePool: null,
    grade: null,
    location: null,
    legalEntity: [null],
  });

  visibleControls: string[] = [];

  constructor(
    private message: MessageService,
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private activeModal: NgbActiveModal,
  ) {}

  apply() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const finishFn = (index) => {
      if (index < this.rows.length - 1) {
        handler(index + 1);
      } else {
        this.notification.successLocal('shared.messages.done');
        this.isSaving = false;
        this.activeModal.close();
      }
    };

    const handler = (index: number) => {
      this.progress = Math.round((index / this.rows.length) * 100);

      const data: any = {};

      if (this.visibleControls.includes('supervisor')) {
        data.supervisorId = this.form.value.supervisor?.id ?? null;
      }

      if (this.visibleControls.includes('costNormalizationRule')) {
        data.costNormalizationRuleId =
          this.form.value.costNormalizationRule?.id;
      }

      if (this.visibleControls.includes('department')) {
        data.departmentId = this.form.value.department?.id;
      }

      if (this.visibleControls.includes('authProvider')) {
        data.authProviderId = this.form.value.authProvider?.id ?? null;
      }

      if (this.visibleControls.includes('resourcePool')) {
        data.resourcePoolId = this.form.value.resourcePool?.id ?? null;
      }

      if (this.visibleControls.includes('legalEntity')) {
        data.legalEntityId = this.form.value.legalEntity?.id ?? null;
      }

      if (this.visibleControls.includes('location')) {
        data.locationId = this.form.value.location?.id ?? null;
      }

      if (this.visibleControls.includes('grade')) {
        data.gradeId = this.form.value.grade?.id ?? null;
      }

      const observables: Observable<any>[] = [];

      if (Object.keys(data).length > 0) {
        observables.push(
          this.data.collection('Users').entity(this.rows[index].id).patch(data),
        );
      }

      if (this.visibleControls.includes('schedule')) {
        observables.push(
          this.data
            .collection('Users')
            .entity(this.rows[index].id)
            .action('AddSchedule')
            .execute({
              schedule: {
                scheduleId: this.form.value.schedule.id,
                userId: this.rows[index].id,
                effectiveDate: this.form.value.scheduleDate,
              },
            }),
        );
      }

      forkJoin(observables).subscribe({
        next: () => {
          finishFn(index);
        },
        error: (error: Exception) => {
          this.message.errorDetailed(error, this.rows[index].name).then(
            () => finishFn(index),
            () => finishFn(index),
          );
        },
      });
    };

    if (this.filter) {
      this.data
        .collection('Users')
        .query<NamedEntity[]>({ filter: this.filter, select: ['id', 'name'] })
        .subscribe({
          next: (rows) => {
            this.rows = rows;
            handler(0);
          },
          error: (error: Exception) => this.message.errorDetailed(error),
        });
    } else {
      handler(0);
    }
  }

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  addControl(controlName: string) {
    this.visibleControls.push(controlName);
    switch (controlName) {
      case 'authProvider':
        this.form.controls['authProvider'].setValidators(Validators.required);
        break;
      case 'resourcePool':
        this.form.controls['resourcePool'].setValidators(Validators.required);
        break;
      case 'legalEntity':
        this.form.controls['legalEntity'].setValidators(Validators.required);
        break;
      case 'schedule':
        this.form.controls['schedule'].setValidators(Validators.required);
        this.form.controls['scheduleDate'].setValidators(Validators.required);
        break;
    }
  }
}
