import { Component, Input } from '@angular/core';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { TimeOffTypeCardService } from '../../time-off-type-card.service';
import { TranslateService } from '@ngx-translate/core';
import { UsingPolicyService } from 'src/app/settings-app/time-off-types/card/using-policy/using-policy.service';

@Component({
  selector: 'wp-using-policy-section',
  templateUrl: './using-policy-section.component.html',
  styleUrls: ['./using-policy-section.component.scss'],
  providers: [UsingPolicyService],
  standalone: false,
})
export class UsingPolicySectionComponent {
  @Input() timeOffTypeUnit: NamedEntity;
  @Input() entityId: string;
  public accrualPolicyName: string;
  constructor(
    public service: UsingPolicyService,
    public timeOffTypeService: TimeOffTypeCardService,
    private translate: TranslateService,
  ) {}
}
