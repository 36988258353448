import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ChangeDetectionStrategy,
  Injector,
  Type,
  inject,
  DestroyRef,
} from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import _ from 'lodash';
import { filter } from 'rxjs';

import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { CellsOrchestratorService } from 'src/app/shared/services/cell-orhestrator/cells-orchestrator.service';
import { BookingType } from 'src/app/shared/models/enums/booking-type.enum';
import { BookingEntry } from 'src/app/shared/models/entities/resources/booking-entry.model';
import { BookingService } from '../../core/booking.service';
import { BookingRenderingService } from '../../core/booking-rendering.service';
import { BookingDataService } from '../../core/booking-data.service';
import { BookingViewSettingsService } from '../booking-view-settings/booking-view-settings.service';
import { BookingSlotInfoComponent } from '../booking-slot-info/booking-slot-info.component';
import { Total } from '../../models/total.model';

@Component({
  // eslint-disable-next-line @angular-eslint/component-selector
  selector: '[tmt-booking-right-group]',
  templateUrl: './booking-right-group.component.html',
  styleUrls: ['./booking-right-group.component.scss'],
  providers: [CellsOrchestratorService],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class BookingRightGroupComponent implements OnInit {
  @Input() public resourceId: string;
  @Input() public componentLine: Type<any>;

  public height: number;
  public isExpanded: boolean;
  public totals: Total[];
  public formLines: UntypedFormArray = this.fb.array([]);
  public componentLineInputs: Record<string, any> = {};

  private readonly destroyRef = inject(DestroyRef);

  public get bookings(): BookingEntry[] {
    return this.dataService.getResourceBookings(this.resourceId);
  }

  constructor(
    public bookingService: BookingService,
    public dataService: BookingDataService,
    public bookingRenderingService: BookingRenderingService,
    private bookingViewSettingsService: BookingViewSettingsService,
    private infoPopupService: InfoPopupService,
    private fb: UntypedFormBuilder,
    private cdr: ChangeDetectorRef,
    private injector: Injector,
  ) {}

  public ngOnInit(): void {
    this.initSubscribers();
    this.initTotals();
    this.recalculateTotals();

    this.componentLineInputs = _.merge({}, this.componentLineInputs, {
      totals: this.totals,
      resourceId: this.resourceId,
    });
  }

  public openPopup(total: Total, event: MouseEvent): void {
    if (total.active) {
      this.infoPopupService.open({
        target: event.target,
        data: {
          component: BookingSlotInfoComponent,
          params: {
            resourceId: this.resourceId,
            totalInfo: total,
            valueMode: this.bookingService.settings.valueMode,
          },
          injector: this.injector,
        },
      });
    }
  }

  public getSummaryIndicatorStyle(total: Total): Record<string, string> {
    let percent = 0;
    total.overtime = false;

    if (total.scheduleHours > 0) {
      percent = _.round((total.hours / total.scheduleHours) * 100, 2);

      if (percent > 100) {
        total.overtime = true;
      }
    } else {
      if (total.hours > 0) {
        percent = 100;
        total.overtime = true;
      }
    }

    const color = total.overtime
      ? 'rgba(193, 102, 107, 0.8)'
      : 'rgba(72, 169, 166, 0.8)';
    const style: Record<string, string> = {
      background: `linear-gradient(to top, ${color} ${percent}%, var(--bs-total-cell-bg-o8) 0%)`,
    };

    return style;
  }

  private initTotals(): void {
    this.totals = [];

    for (const slot of this.bookingService.slots) {
      const scheduleHours =
        this.dataService.schedules[this.resourceId]?.find(
          (e) => e.date === slot.date.toISODate(),
        )?.hours ?? 0;

      const fteHours = this.dataService.fte[slot.date.toISODate()] ?? 0;

      this.totals.push({
        date: slot.date.toISODate(),
        hours: 0,
        id: slot.id,
        nonWorking: scheduleHours === 0,
        fteHours,
        scheduleHours,
        active: false,
        overtime: false,
      });
    }
  }

  /** Рассчитывает итоги по строкам и слотам.  */
  public recalculateTotals(): void {
    for (const total of this.totals) {
      if (
        this.bookingViewSettingsService.settings.show === 'workload' ||
        this.dataService.resourceRequestMode
      ) {
        total.hours = 0;
      } else {
        total.hours = total.scheduleHours;
      }

      total.active = false;
    }

    this.bookings
      .filter((b) => b.type === BookingType.Hard)
      .forEach((booking) => {
        booking.detailEntries.forEach((detailEntry) => {
          const total = this.totals.find((t) => t.date === detailEntry.date);

          if (total) {
            if (
              this.bookingViewSettingsService.settings.show === 'workload' ||
              this.dataService.resourceRequestMode
            ) {
              total.hours += detailEntry.hours;
            } else {
              total.hours -= detailEntry.hours;
            }
          }

          if (total?.hours > 0) {
            total.active = true;
          }
        });
      });

    this.componentLineInputs = _.merge({}, this.componentLineInputs, {
      totals: this.totals,
    });
    this.cdr.detectChanges();
  }

  private initSubscribers(): void {
    this.bookingViewSettingsService.settings$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.recalculateTotals();
        this.cdr.detectChanges();
      });

    this.bookingService.detectChanges$
      .pipe(
        filter((id) => id === this.resourceId),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.bookingService.recalculateGroup$
      .pipe(
        filter((e) => e.id === this.resourceId),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((e) => {
        if (e.rebuild) {
          this.initTotals();
        }

        this.recalculateTotals();
      });

    this.bookingRenderingService?.groupBoardHeight$
      .pipe(
        filter((params) => params.id === this.resourceId),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((params) => {
        this.height = params.height;
        this.cdr.detectChanges();
      });

    this.bookingService.toggleGroup$
      .pipe(
        filter((event) => event?.id === this.resourceId),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((event) => {
        this.isExpanded = event.state;

        if (this.isExpanded) {
          this.componentLineInputs = _.merge({}, this.componentLineInputs, {
            totals: this.totals,
            resourceId: this.resourceId,
          });

          this.cdr.markForCheck();
        }
      });
  }
}
