<form class="modal-form" novalidate [formGroup]="form" ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ 'shared.groupEditing.header' | translate }}
    </h3>
    <button
      [disabled]="isSaving"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body">
    <div ngbDropdown class="d-inline-block mb-3" placement="bottom-left">
      <button type="button" class="btn btn-default" ngbDropdownToggle title="">
        {{ 'shared.groupEditing.actions.whatToChange' | translate }}
      </button>
      <div ngbDropdownMenu>
        <div *ngIf="visibleControls.length === 4" class="text-center text-body-secondary">
          {{ 'shared.noOptions' | translate }}
        </div>

        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('costNormalizationRule')"
          (click)="addControl('costNormalizationRule')"
        >
          {{ 'settings.users.list.editing.costNormalizationRule' | translate }}
        </button>

        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('supervisor')"
          (click)="addControl('supervisor')"
        >
          {{ 'shared2.props.supervisor' | translate }}
        </button>

        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('department')"
          (click)="addControl('department')"
        >
          {{ 'shared2.props.department' | translate }}
        </button>

        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('schedule')"
          (click)="addControl('schedule')"
        >
          {{ 'settings.users.list.editing.addSchedule' | translate }}
        </button>

        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('authProvider')"
          (click)="addControl('authProvider')"
        >
          {{ 'settings.users.card.profile.props.authProvider' | translate }}
        </button>
        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('resourcePool')"
          (click)="addControl('resourcePool')"
        >
          {{ 'shared2.props.resourcePool' | translate }}
        </button>

        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('legalEntity')"
          (click)="addControl('legalEntity')"
        >
          {{ 'shared2.props.legalEntity' | translate }}
        </button>

        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('grade')"
          (click)="addControl('grade')"
        >
          {{ 'shared2.props.grade' | translate }}
        </button>

        <button
          ngbDropdownItem
          *ngIf="!visibleControls.includes('location')"
          (click)="addControl('location')"
        >
          {{ 'shared2.props.location' | translate }}
        </button>
      </div>
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('supervisor')">
      <label>{{ 'shared2.props.supervisor' | translate }}</label>

      <wp-user-box
        formControlName="supervisor"
        placeholder="{{ 'shared2.props.supervisor' | translate }}"
      >
      </wp-user-box>
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('department')">
      <label>{{ 'shared2.props.department' | translate }}</label>
      <tmt-hierarchical-box
        formControlName="department"
        placeholder="{{ 'shared2.props.department' | translate }}"
        collection="Departments"
        parentIdKey="leadDepartmentId"
      />
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('costNormalizationRule')">
      <label>{{ 'settings.users.card.cost.props.rule' | translate }}</label>
      <wp-select-box
        formControlName="costNormalizationRule"
        collection="CostNormalizationRules"
        placeholder="{{ 'settings.users.card.cost.props.rule' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('schedule')">
      <label>{{ 'settings.users.list.editing.addSchedule' | translate }}</label>
      <div class="d-flex">
        <wp-date-box formControlName="scheduleDate"></wp-date-box>

        <wp-select-box
          style="width: 350px; display: block"
          class="ms-3"
          formControlName="schedule"
          collection="Schedules"
          placeholder="{{ 'settings.users.card.schedules.props.schedule.ph' | translate }}"
        >
        </wp-select-box>
      </div>
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('authProvider')">
      <label>{{ 'settings.users.card.profile.props.authProvider' | translate }}</label>
      <wp-select-box
        formControlName="authProvider"
        [values]="authProviders$"
        placeholder="{{ 'settings.users.card.profile.props.authProvider' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('resourcePool')">
      <label>{{ 'shared2.props.resourcePool' | translate }}</label>
      <tmt-hierarchical-box
        formControlName="resourcePool"
        collection="ResourcePools"
        parentIdKey="leadResourcePoolId"
        placeholder="{{ 'shared2.props.resourcePool' | translate }}"
      />
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('legalEntity')">
      <label>{{ 'shared2.props.legalEntity' | translate }}</label>
      <wp-select-box
        collection="LegalEntities"
        formControlName="legalEntity"
        placeholder="{{ 'shared2.props.legalEntity' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('grade')">
      <label>{{ 'shared2.props.grade' | translate }}</label>
      <wp-select-box
        collection="Grades"
        formControlName="grade"
        placeholder="{{ 'shared2.props.grade' | translate }}"
      ></wp-select-box>
    </div>

    <div class="form-group" *ngIf="visibleControls.includes('location')">
      <label>{{ 'shared2.props.location' | translate }}</label>
      <wp-select-box
        formControlName="location"
        placeholder="{{ 'shared2.props.location' | translate }}"
        collection="Locations"
      ></wp-select-box>
    </div>

    <wp-loading-indicator [loading]="isSaving" [progress]="progress"></wp-loading-indicator>
  </div>
  <div class="modal-footer">
    <button
      type="button"
      class="btn btn-primary"
      (click)="apply()"
      wpButtonSpinner
      [isBusy]="isSaving"
    >
      {{ 'shared.actions.apply' | translate }}
    </button>
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
