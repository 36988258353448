import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';

@Component({
  selector: 'wp-rule-project-planned-hours',
  templateUrl: './rule-project-planned-hours.component.html',
  standalone: false,
})
export class RuleProjectPlannedHoursComponent implements OnInit {
  @Output() changes = new EventEmitter<string>();
  @Input() data: string;
  @Input() readonly: boolean;

  public form = this.fb.group({
    type: null,
  });

  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    const rule = JSON.parse(this.data);
    let type = '';
    if (rule.CheckTaskPlan) {
      type = 'CheckTaskPlan';
    }

    if (rule.CheckProjectPlan) {
      type = 'CheckProjectPlan';
    }

    this.form.controls['type'].patchValue(type);
    if (this.readonly) {
      this.form.disable();
    }

    this.form.valueChanges.subscribe(() => {
      const data = {
        CheckProjectPlan: this.form.value.type === 'CheckProjectPlan',
        CheckTaskPlan: this.form.value.type === 'CheckTaskPlan',
      };

      this.changes.emit(JSON.stringify(data));
    });
  }
}
