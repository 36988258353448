import { Component } from '@angular/core';
import { RbcGroupType } from '../../../models/rbc-view.model';
import { ProjectRbcCalendarGroupFilterService } from '../../core/project-rbc-calendar-group-filter.service';

@Component({
  selector: 'wp-project-rbc-group-filter',
  templateUrl: './project-rbc-group-filter.component.html',
  standalone: false,
})
export class ProjectRbcCalendarGroupFilterComponent {
  constructor(public service: ProjectRbcCalendarGroupFilterService) {}

  public onToggle(group: RbcGroupType) {
    this.service.toggleGroup(group);
  }
}
