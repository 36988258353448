import {
  Component,
  OnInit,
  ViewChild,
  Input,
  ViewContainerRef,
  OnDestroy,
  input,
} from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { CustomFieldMode } from 'src/app/shared/components/features/custom-fields/custom-fields.component';
import { PropagationMode } from 'src/app/shared/models/enums/control-propagation-mode.enum';
import {
  MetaEntityBaseProperty,
  MetaEntityDirectoryProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import { DateBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/date-box-wrapper.component';
import { NumberBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/number-box-wrapper.component';
import { SelectBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/select-box-wrapper.component';
import { TextBoxWrapperComponent } from 'src/app/shared/components/features/custom-fields/wrappers/text-box-wrapper.component';
import { Wrapper } from 'src/app/shared/components/features/custom-fields/wrappers/wrapper.interface';
import { CustomFieldHostDirective } from 'src/app/shared/components/features/custom-fields/wrappers/custom-field-host.directive';

@Component({
  selector: 'wp-custom-field',
  template: '<ng-template tmt-custom-field-host></ng-template>',
  standalone: false,
})
export class CustomFieldComponent implements OnInit, OnDestroy {
  @ViewChild(CustomFieldHostDirective, { static: true })
  private host: CustomFieldHostDirective;

  @Input() control: UntypedFormControl;
  @Input() field: MetaEntityBaseProperty;
  @Input() mode: CustomFieldMode = 'default';
  public propagationMode = input<PropagationMode>(PropagationMode.onInput);

  private componentRef: any;
  private viewContainerRef: ViewContainerRef;

  public ngOnInit(): void {
    this.viewContainerRef = this.host.hostContainer;

    switch (this.field.type) {
      case MetaEntityPropertyType.dateOnly:
        this.renderDateControl();
        break;
      case MetaEntityPropertyType.decimal:
        this.renderNumberControl('decimal');
        break;
      case MetaEntityPropertyType.integer:
        this.renderNumberControl('integer');
        break;
      case MetaEntityPropertyType.directory:
        this.renderSelectControl();
        break;
      case MetaEntityPropertyType.string:
      case MetaEntityPropertyType.url:
      case MetaEntityPropertyType.email:
      case MetaEntityPropertyType.phone:
        this.renderStringControl();
        break;
    }

    if (this.field.isOnlyForApi && this.mode === 'default') {
      this.componentRef.instance.readonly = true;
    }
  }

  public ngOnDestroy(): void {
    if (this.componentRef) {
      this.componentRef.destroy();
      this.componentRef = null;
    }
    if (this.viewContainerRef) {
      this.viewContainerRef.remove();
      this.viewContainerRef = null;
    }
  }

  private renderNumberControl(type: string): void {
    this.componentRef = this.viewContainerRef.createComponent(
      NumberBoxWrapperComponent,
    );
    const instance = <NumberBoxWrapperComponent>this.componentRef.instance;
    instance.control = this.control;
    instance.type = type;
    instance.propagationMode = this.propagationMode();
  }

  private renderSelectControl(): void {
    this.componentRef = this.viewContainerRef.createComponent(
      SelectBoxWrapperComponent,
    );
    const instance = <SelectBoxWrapperComponent>this.componentRef.instance;
    instance.isIdMode = true;
    instance.control = this.control;
    instance.directoryId = (
      this.field as MetaEntityDirectoryProperty
    ).directoryId;
    instance.placeholder = this.field.viewConfiguration.placeholder;
  }

  private renderStringControl(): void {
    this.componentRef = this.viewContainerRef.createComponent(
      TextBoxWrapperComponent,
    );
    const instance = <TextBoxWrapperComponent>this.componentRef.instance;
    instance.control = this.control;
    instance.placeholder = this.field.viewConfiguration.placeholder;
    instance.propagationMode = this.propagationMode();
    instance.type = this.field.type;
  }

  private renderDateControl(): void {
    this.componentRef = this.viewContainerRef.createComponent(
      DateBoxWrapperComponent,
    );
    const instance = <Wrapper>this.componentRef.instance;
    instance.control = this.control;
  }
}
