import { Component, Input, OnInit } from '@angular/core';
import { DataService } from 'src/app/core/data.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { MessageService } from 'src/app/core/message.service';
import { Exception } from 'src/app/shared/models/exception';
import { TenantSettingService } from 'src/app/shared/services/tenant-setting.service';
import { BehaviorSubject } from 'rxjs';

/** Диалог сброса (принудительной установки) пароля пользователя. */
@Component({
  selector: 'wp-password-reset-modal',
  templateUrl: './password-reset-modal.component.html',
  standalone: false,
})
export class PasswordResetModalComponent implements OnInit {
  @Input() userId: string;

  public form = this.fb.group({
    password: ['', [Validators.required]],
  });
  public isSaving: boolean;

  public passwordRequirements$$ = new BehaviorSubject<string>('');

  constructor(
    private message: MessageService,
    private data: DataService,
    private notification: NotificationService,
    private fb: UntypedFormBuilder,
    private activeModal: NgbActiveModal,
    private tenantSetting: TenantSettingService,
  ) {}

  ngOnInit(): void {
    this.tenantSetting.getPasswordRequirements().subscribe({
      next: (data) => {
        this.passwordRequirements$$.next(data);
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
      },
    });
  }

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  ok = () => {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const data = { newPassword: this.form.value.password };

    this.data
      .collection('Users')
      .entity(this.userId)
      .action('WP.ResetPassword')
      .execute(data)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'settings.users.card.profile.passwordReset.wasChanged',
          );
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.message.errorDetailed(error);
          this.isSaving = false;
        },
      });
  };
}
