import { Injectable } from '@angular/core';
import _ from 'lodash';
import {
  ChartDataset,
  SortedData,
} from 'src/app/analytics/dashboards/dashboard/widget/models/widget-stacked-column.model';

@Injectable()
export class WidgetDataService {
  /**
   * Gets chart data set.
   *
   * @param data Sorted chart data.
   * @returns Chart data set.
   */
  public getChartDataset(data: SortedData[]): ChartDataset[] {
    const legendsSet = new Set();

    data.forEach((item: SortedData) => {
      Object.keys(item.detailed).forEach((legend: string) => {
        legendsSet.add(legend);
      });
    });

    const legends = Array.from(legendsSet);
    const datasets = legends.map((legend: string) => ({
      label: legend,
      data: [],
    }));

    data.forEach((item: SortedData) => {
      datasets.forEach((dataset: ChartDataset) => {
        dataset.data.push(+item.detailed[dataset.label] || 0);
      });
    });

    return datasets;
  }
}
