import {
  AfterContentInit,
  ChangeDetectorRef,
  ContentChildren,
  Directive,
  ElementRef,
  forwardRef,
  OnInit,
  Renderer2,
  QueryList,
  ViewRef,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { RadioLabelDirective } from './radio-label.directive';

/**
 *
 * It's directive solve problem with migrate BS4 to BS5.
 *
 * */

@Directive({
  selector: '[radioGroup]',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RadioGroupDirective),
      multi: true,
    },
  ],
  standalone: false,
})
export class RadioGroupDirective
  implements ControlValueAccessor, OnInit, AfterContentInit
{
  public readonly: boolean;

  private group!: NodeListOf<HTMLInputElement>;
  private groupName!: string | null;
  private value: string | undefined;
  private onChange!: (value: string) => void;
  private onTouched!: () => void;

  private valueSubject = new Subject<string>();
  @ContentChildren(RadioLabelDirective) labels: QueryList<RadioLabelDirective>;

  constructor(
    private element: ElementRef,
    private changeDetector: ChangeDetectorRef,
    private renderer: Renderer2,
  ) {}

  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
    this.updateDisabledState();
  }

  private updateDisabledState() {
    if (!this.group) {
      return;
    }

    if (this.readonly) {
      this.renderer.addClass(
        this.element.nativeElement,
        'radio-group-disabled',
      );
    }

    this.group.forEach((node, index) => {
      if (this.readonly) {
        (node as HTMLInputElement).setAttribute('disabled', 'true');
      } else {
        (node as HTMLInputElement).removeAttribute('disabled');
      }
    });

    if (!(this.changeDetector as ViewRef).destroyed) {
      this.changeDetector.detectChanges();
    }
  }

  writeValue(value: string): void {
    this.value = value;
  }

  registerOnChange(fn: (value: string) => void): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  initValue(value: string): void {
    if (!this.value) {
      this.value = value;
    }
    this.valueSubject.next(this.value);
  }

  setValue(value: string): void {
    this.value = value;
    this.valueSubject.next(this.value);
    this.onChange(this.value);
  }

  ngOnInit() {
    this.groupName = (this.element.nativeElement as HTMLElement).getAttribute(
      'name',
    );
  }

  ngAfterContentInit() {
    if (!this.labels) {
      throw Error('RadioGroupDirective required radioLabels directives into.');
    }

    this.labels.forEach((l) => l.initLabel(this.valueSubject.asObservable()));

    this.group = (this.element.nativeElement as HTMLElement).querySelectorAll(
      'input[radioButton]',
    );

    if (!this.groupName) {
      this.groupName = 'radio-name';
    }

    if (!this.group) {
      throw Error(
        'RadioGroupDirective required inner input elements with type radio.',
      );
    }

    this.group.forEach((node, index) => {
      if (this.groupName) {
        (node as HTMLInputElement).setAttribute('name', this.groupName);
      }

      if (index === 0) {
        this.initValue(node.value);
      }

      node.addEventListener('change', (event) => {
        this.setValue((event.target as HTMLInputElement).value);
      });
    });

    this.updateDisabledState();
  }
}
