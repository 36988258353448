import { Component, Inject, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { VIEW_NAME } from 'src/app/shared/tokens';

@Component({
  selector: 'tmt-metamodel-description-filter',
  template: '',
  standalone: false,
})
export class MetamodelDescriptionFilterComponent implements FilterDetails {
  @Input() values: any;

  public detailsForm: UntypedFormGroup;

  constructor(@Inject(VIEW_NAME) public viewName: string) {}
}
