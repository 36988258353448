<wp-loading-indicator [loading]="loading()" class="mt-3 d-block" />
<wp-data-not-saved-indicator [hidden]="!isNotSavedIndicator()" [form]="form" />

<form class="form-container" [hidden]="loading()" [formGroup]="form" novalidate autocomplete="off">
  <div ngbAccordion class="accordion--custom">
    <div ngbAccordionItem [collapsed]="false">
      <h2 ngbAccordionHeader>
        <button class="group" ngbAccordionButton>
          {{ 'shared2.groups.mainProperties' | translate }}
        </button>
      </h2>
      <div ngbAccordionCollapse>
        <div ngbAccordionBody>
          <div class="form-grid">
            <div class="d-flex align-items-end">
              <div class="form-group" style="width: 350px">
                <label>{{ 'components.clientLegalComponent.props.taxNumber' | translate }}</label>
                <wp-text-box
                  formControlName="taxNumber"
                  placeholder="{{ 'components.clientLegalComponent.props.taxNumber' | translate }}"
                  [propagationMode]="propagationMode.onExitFromEditing"
                />
              </div>
              <button class="btn btn-default ms-3" (click)="updateViaDadata()">
                {{ 'shared2.actions.update' | translate }}
              </button>
            </div>

            <div class="form-group" tmtIndicator>
              <label>{{
                'components.clientLegalComponent.props.registrationNumber' | translate
              }}</label>
              <wp-text-box
                readonly="true"
                formControlName="registrationNumber"
                placeholder="{{
                  'components.clientLegalComponent.props.registrationNumber' | translate
                }}"
              />
            </div>

            <div class="form-group">
              <label>{{ 'shared2.props.name' | translate }}</label>
              <wp-text-box
                [readonly]="true"
                formControlName="name"
                placeholder="{{ 'shared2.props.name' | translate }}"
              />
            </div>

            <div class="form-group">
              <label>{{
                'components.clientLegalComponent.props.lastAutoUpdated' | translate
              }}</label>
              <wp-date-box [readonly]="true" formControlName="lastAutoUpdated" />
            </div>

            <div class="form-group">
              <label>{{ 'components.clientLegalComponent.props.income' | translate }}</label>
              <wp-number-box [readonly]="true" [type]="'currency'" formControlName="income" />
            </div>

            <div class="form-group">
              <label>{{ 'components.clientLegalComponent.props.headcount' | translate }}</label>
              <wp-number-box [readonly]="true" formControlName="headcount" />
            </div>

            <div class="form-group" style="grid-column: span 2">
              <label>{{
                'components.clientLegalComponent.props.additionalInformation' | translate
              }}</label>

              <div
                [hidden]="form.value.additionalInformation"
                class="form-control-static text-body-secondary text-uppercase empty"
              >
                {{ 'shared.empty' | translate }}
              </div>

              <div [innerHTML]="form.value.additionalInformation?.toString() | linky | nl2br"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
