import {
  Component,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  forwardRef,
  ElementRef,
  Renderer2,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';

import { ScrollToService } from 'src/app/shared/services/scroll-to.service';
import { AbstractMultiSelectBoxComponent } from 'src/app/shared/components/controls/multiselect-box/abstract-multiselect-box.component';

@Component({
  selector: 'tmt-multiselect-box',
  templateUrl: './multiselect-box.component.html',
  styleUrls: ['./multiselect-box.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiSelectBoxComponent),
      multi: true,
    },
  ],
  standalone: false,
})
export class MultiSelectBoxComponent extends AbstractMultiSelectBoxComponent {
  constructor(
    protected override cdr: ChangeDetectorRef,
    renderer: Renderer2,
    el: ElementRef,
    dataService: DataService,
    notificationService: NotificationService,
    scrollToService: ScrollToService,
    infoPopupService: InfoPopupService,
  ) {
    super(
      cdr,
      renderer,
      el,
      dataService,
      notificationService,
      scrollToService,
      infoPopupService,
    );
  }

  public override writeValue(value: any): void {
    if (!value) {
      this.selectedItems.clear();
      this.updateTitle();
      this.cdr.markForCheck();
      return;
    }

    if (Array.isArray(value)) {
      value.forEach((el) => {
        if (!this.selectedItems.has(el.id)) {
          el.selected = true;
          this.selectedItems.set(el.id, el);
        }
      });
    } else {
      value.selected = true;
      this.selectedItems.set(value.id, value);
    }

    if (this.listOpened) {
      this.updateOption();
    } else {
      this.updateTitle();
    }

    this.cdr.markForCheck();
  }
}
