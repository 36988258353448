import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { AccountingEntryService } from '../core/accounting-entry.service';

@Component({
  selector: 'wp-accounting-entry-card',
  templateUrl: './accounting-entry-card.component.html',
  providers: [AccountingEntryService],
  standalone: false,
})
export class AccountingEntryCardComponent implements OnInit, OnDestroy {
  @Input() entityId: string;

  /** Component subscriptions cancel subject. */
  private destroyed$ = new Subject<void>();

  constructor(
    public service: AccountingEntryService,
    private actionService: ActionPanelService,
  ) {}

  ngOnInit() {
    this.initMainMenu();
    this.initCardSubscriptions();
    this.service.mode = 'edit';
    this.service.init();
  }

  ngOnDestroy(): void {
    this.service.dispose();
    this.destroyed$.next();
  }

  /**
   * Inits Main menu.
   * */
  private initMainMenu() {
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.service.save(),
      },
    ]);
  }

  /**
   * Inits Card level subscriptions.
   * */
  private initCardSubscriptions() {
    this.service.saving$.pipe(takeUntil(this.destroyed$)).subscribe((state) => {
      this.actionService.action('save').isBusy = state;
    });
  }
}
