import { Component, OnInit, signal, DestroyRef } from '@angular/core';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';
import { SettingsCardService } from '../settings-card.service';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { MessageService } from 'src/app/core/message.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { DomainAddingComponent } from './domain-adding/domain-adding.component';
import { DomainConfirmationComponent } from './domain-confirmation/domain-confirmation.component';
import { TenantDomain } from 'src/app/shared/models/entities/settings/multitenant/tenant-domain.model';
import { GridOptions } from 'src/app/shared-features/grid/models/grid-options.model';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { GridColumnType } from 'src/app/shared-features/grid/models/grid-column.interface';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'wp-settings-domains',
  templateUrl: './settings-domains.component.html',
  providers: [GridService],
  standalone: false,
})
export class SettingsDomainsComponent implements OnInit {
  public state = signal<CardState>(CardState.Loading);
  public readonly = signal<boolean>(
    !this.app.checkPermission('ManageAccount', null, PermissionType.Execute),
  );
  public domains = this.fb.array([]);
  public gridOptions: GridOptions = {
    rowCommands: [
      {
        name: 'confirm',
        label: 'settings.settings.domains.actions.confirm',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly() && !formGroup.getRawValue().isConfirmed,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.confirmDomain(formGroup.getRawValue().domainName);
        },
      },
      {
        name: 'delete',
        label: 'shared.actions.delete',
        allowedFn: (formGroup: UntypedFormGroup) =>
          !this.readonly() && !formGroup.getRawValue().isPrimary,
        handlerFn: (formGroup: UntypedFormGroup) => {
          this.deleteDomain(formGroup.getRawValue().domainName);
        },
      },
    ],
    commands: [],
    view: {
      name: 'domains',
      columns: [
        {
          name: 'domainName',
          header: 'shared.props.name',
          hint: 'shared.props.name',
          type: GridColumnType.String,
          width: '100%',
        },
        {
          name: 'isPrimary',
          header: 'settings.settings.domains.props.isPrimary',
          hint: 'settings.settings.domains.props.isPrimary',
          type: GridColumnType.Boolean,
          width: '130px',
        },
        {
          name: 'isConfirmed',
          header: 'settings.settings.domains.props.isConfirmed',
          hint: 'settings.settings.domains.props.isConfirmed',
          type: GridColumnType.Boolean,
          width: '130px',
        },
      ],
    },
  };

  constructor(
    private app: AppService,
    private service: SettingsCardService,
    private messages: MessageService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private modalService: NgbModal,
  ) {
    this.service.reloadTab$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.load();
    });
  }

  public ngOnInit(): void {
    this.load();
  }

  /** Opens a modal to add a domain and reloads data upon successful addition */
  public addDomain(): void {
    const instance = this.modalService.open(DomainAddingComponent);
    instance.result.then(
      () => {
        this.load();
      },
      () => null,
    );
  }

  /** Opens a modal to confirm a domain using the provided domain name */
  public confirmDomain(domainName: string): void {
    const instance = this.modalService.open(DomainConfirmationComponent);
    (instance.componentInstance as DomainConfirmationComponent).domainName =
      domainName;
  }

  private load(): void {
    this.state.set(CardState.Loading);

    this.data
      .collection('TenantDomains')
      .query<TenantDomain[]>()
      .subscribe({
        next: (domains) => {
          this.domains.clear();
          domains.forEach((domain) => {
            this.domains.push(
              this.fb.group({
                id: domain.domainName,
                domainName: domain.domainName,
                isPrimary: domain.isPrimary,
                isConfirmed: domain.isConfirmed,
              }),
            );
          });

          this.state.set(CardState.Ready);
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state.set(CardState.Error);
        },
      });
  }

  private deleteDomain(domainName: string): void {
    this.messages
      .confirmLocal('settings.settings.domains.messages.deleteConfirmation')
      .then(
        () => {
          this.data
            .collection('TenantDomains')
            .entity(`'${domainName}'`)
            .delete()
            .subscribe({
              next: () => {
                this.notification.successLocal(
                  'settings.settings.domains.messages.domainDeleted',
                );
                this.load();
              },
              error: (error: Exception) => {
                this.notification.error(error.message);
              },
            });
        },
        () => null,
      );
  }
}
