import { Component, OnInit } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { NotificationService } from 'src/app/core/notification.service';
import { DataService } from 'src/app/core/data.service';
import { StateService } from '@uirouter/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Constants } from 'src/app/shared/globals/constants';
import { NamedEntity } from '../../../shared/models/entities/named-entity.model';
import { TranslateService } from '@ngx-translate/core';
import { TimeOffTypeUnits } from '../../../shared/models/enums/time-off-type-unit.enum';

@Component({
  selector: 'wp-time-off-type-creation',
  templateUrl: './time-off-type-creation.component.html',
  standalone: false,
})
export class TimeOffTypeCreationComponent implements OnInit {
  public timeOffTypeUnits: NamedEntity[] = [];
  isSaving = false;
  creationForm: UntypedFormGroup;

  constructor(
    private fb: UntypedFormBuilder,
    private notification: NotificationService,
    private data: DataService,
    private state: StateService,
    private activeModal: NgbActiveModal,
    private translate: TranslateService,
  ) {
    this.creationForm = fb.group({
      name: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formNameMaxLength),
        ],
      ],
      code: [
        '',
        [
          Validators.required,
          Validators.maxLength(Constants.formCodeMaxLength),
        ],
      ],
      unit: [null, Validators.required],
    });
  }

  ok = () => {
    this.creationForm.markAllAsTouched();

    if (this.creationForm.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;

    const timeOffType = this.creationForm.getRawValue();

    const data: any = {
      code: timeOffType.code,
      name: timeOffType.name,
      unitId: timeOffType.unit.id,
    };

    this.data
      .collection('TimeOffTypes')
      .insert(data)
      .subscribe({
        next: (response) => {
          this.notification.successLocal('settings.timeOffTypes.created');
          this.state.go('settings.timeOffType', { entityId: response.id });
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  };

  cancel = () => {
    this.activeModal.dismiss('cancel');
  };

  ngOnInit(): void {
    this.timeOffTypeUnits = TimeOffTypeUnits.map((unit) => ({
      id: unit.id,
      name: this.translate.instant(`enums.timeOffTypeUnit.${unit.code}`),
    }));
  }
}
