import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormBuilder, Validators } from '@angular/forms';
import { Guid } from 'src/app/shared/helpers/guid';
import { AuthProviderType } from 'src/app/shared/models/enums/auth-provider-type.enum';
import { DataService } from 'src/app/core/data.service';
import { AuthProvider } from 'src/app/shared/models/entities/settings/auth-provider.model';
import { NotificationService } from 'src/app/core/notification.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { AppService } from 'src/app/core/app.service';
import { Exception } from 'src/app/shared/models/exception';
import { Constants } from 'src/app/shared/globals/constants';

@Component({
  selector: 'wp-settings-auth-create-provider-modal',
  templateUrl: './settings-auth-create-provider-modal.component.html',
  styleUrls: ['./settings-auth-create-provider-modal.component.scss'],
  standalone: false,
})
export class SettingsAuthCreateProviderModalComponent implements OnInit {
  public providerName: string;
  public providerType: AuthProviderType;
  public isSaving: boolean;

  public providerToEdit = null;

  public readonly formValidatorValues = {
    localSetting: {
      passwordUpdatePeriodDays: {
        min: 0,
        max: 999999,
      },
      maxInvalidLoginAttempts: {
        min: 1,
        max: 999999,
      },
      lockoutMinutes: {
        min: 1,
        max: 999999,
      },
      minPasswordLength: {
        min: 6,
        max: 15,
      },
    },
  };

  private readonly defaultLocalProviderConfiguration = {
    passwordUpdatePeriodDays: 30,
    maxInvalidLoginAttempts: 5,
    lockoutMinutes: 5,
    minPasswordLength: 6,
    requireCapitalLetters: false,
    requireDigits: false,
    requireSpecSymbols: true,
    useOtp: false,
    allowedGrantPassword: false,
  };

  public providerForm = this.formBuilder.group({
    id: Guid.generate(),
    type: null,
    name: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    openIdConfiguration: this.formBuilder.group({
      authority: ['', [Validators.required]],
      clientId: ['', [Validators.required]],
      clientSecret: ['', [Validators.required]],
    }),
    localSetting: this.formBuilder.group({
      passwordUpdatePeriodDays: [
        this.defaultLocalProviderConfiguration.passwordUpdatePeriodDays,
        [
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.passwordUpdatePeriodDays.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.passwordUpdatePeriodDays.max,
          ),
        ],
      ],
      maxInvalidLoginAttempts: [
        this.defaultLocalProviderConfiguration.maxInvalidLoginAttempts,
        [
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.maxInvalidLoginAttempts.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.maxInvalidLoginAttempts.max,
          ),
        ],
      ],
      lockoutMinutes: [
        this.defaultLocalProviderConfiguration.lockoutMinutes,
        [
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.lockoutMinutes.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.lockoutMinutes.max,
          ),
        ],
      ],
      minPasswordLength: [
        this.defaultLocalProviderConfiguration.minPasswordLength,
        [
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.minPasswordLength.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.minPasswordLength.max,
          ),
        ],
      ],
      requireDigits: this.defaultLocalProviderConfiguration.requireDigits,
      requireSpecSymbols:
        this.defaultLocalProviderConfiguration.requireSpecSymbols,
      requireCapitalLetters:
        this.defaultLocalProviderConfiguration.requireCapitalLetters,
      useOtp: this.defaultLocalProviderConfiguration.useOtp,
      allowedGrantPassword:
        this.defaultLocalProviderConfiguration.allowedGrantPassword,
    }),
  });

  public readonly: boolean;

  protected readonly AuthProviderType = AuthProviderType;

  constructor(
    private activeModal: NgbActiveModal,
    private formBuilder: FormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private app: AppService,
  ) {}

  public cancel() {
    this.activeModal.dismiss();
  }

  public ok() {
    if (this.readonly) {
      this.activeModal.dismiss();
      return;
    }
    this.providerForm.markAllAsTouched();
    if (!this.providerForm.valid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }
    const authProvider = {
      id: this.providerForm.value.id,
      type: this.providerForm.value.type,
      name: this.providerForm.value.name,
      openIdConfiguration:
        this.providerType === AuthProviderType.OpenId
          ? JSON.stringify({
              authority: this.providerForm.value.openIdConfiguration.authority,
              clientId: this.providerForm.value.openIdConfiguration.clientId,
              clientSecret:
                this.providerForm.value.openIdConfiguration.clientSecret,
            })
          : null,
      localSetting:
        this.providerType === AuthProviderType.Local
          ? this.providerForm.getRawValue().localSetting
          : null,
    };
    this.isSaving = true;

    const operation = this.providerToEdit
      ? this.data
          .collection('AuthProviders')
          .entity(this.providerToEdit.id)
          .update(authProvider)
      : this.data.collection('AuthProviders').insert(authProvider);

    operation.subscribe({
      next: () => {
        this.isSaving = false;
        this.activeModal.close();
      },
      error: (error: Exception) => {
        this.notification.error(error.message);
        this.isSaving = false;
      },
    });
  }

  ngOnInit(): void {
    this.readonly = !this.app.checkPermission(
      'AuthProvider',
      'All',
      PermissionType.Modify,
    );

    if (this.readonly) {
      this.providerForm.disable();
    }

    if (this.providerToEdit) {
      this.providerForm.setValue(this.providerToEdit);
      this.providerName = this.providerToEdit.name;
      this.providerType = this.providerToEdit.type;
    }

    this.providerForm.controls.type.valueChanges.subscribe((providerType) => {
      const openIdControls =
        this.providerForm.controls.openIdConfiguration.controls;
      const localControls = this.providerForm.controls.localSetting.controls;

      if (providerType === AuthProviderType.OpenId) {
        localControls.lockoutMinutes.removeValidators([
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.lockoutMinutes.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.lockoutMinutes.max,
          ),
        ]);
        localControls.maxInvalidLoginAttempts.removeValidators([
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.maxInvalidLoginAttempts.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.maxInvalidLoginAttempts.max,
          ),
        ]);
        localControls.minPasswordLength.removeValidators([
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.minPasswordLength.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.minPasswordLength.max,
          ),
        ]);
        localControls.passwordUpdatePeriodDays.removeValidators([
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.passwordUpdatePeriodDays.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.passwordUpdatePeriodDays.max,
          ),
        ]);

        Object.values(openIdControls).forEach((control) => {
          control.setValidators(Validators.required);
        });
      } else if (providerType === AuthProviderType.Local) {
        Object.values(openIdControls).forEach((control) => {
          control.removeValidators(Validators.required);
        });
        localControls.lockoutMinutes.setValidators([
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.lockoutMinutes.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.lockoutMinutes.max,
          ),
        ]);
        localControls.maxInvalidLoginAttempts.setValidators([
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.maxInvalidLoginAttempts.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.maxInvalidLoginAttempts.max,
          ),
        ]);
        localControls.minPasswordLength.setValidators([
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.minPasswordLength.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.minPasswordLength.max,
          ),
        ]);
        localControls.passwordUpdatePeriodDays.setValidators([
          Validators.required,
          Validators.min(
            this.formValidatorValues.localSetting.passwordUpdatePeriodDays.min,
          ),
          Validators.max(
            this.formValidatorValues.localSetting.passwordUpdatePeriodDays.max,
          ),
        ]);
      }

      this.providerForm.updateValueAndValidity();
      this.providerForm.markAsUntouched();
      this.providerForm.markAsPristine();
    });

    this.providerForm.controls.type.setValue(this.providerType);
  }
}
