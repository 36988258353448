import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ProjectRbcCalendarDataService } from './core/project-rbc-calendar-data.service';
import { ProjectRbcCalendarGroupFilterService } from './core/project-rbc-calendar-group-filter.service';
import { ProjectRbcCalendarSlotInfoService } from './core/project-rbc-calendar-slot-info.service';
import { ProjectRbcCalendarService } from './core/project-rbc-calendar.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';

@Component({
  selector: 'wp-project-rbc-calendar',
  templateUrl: './project-rbc-calendar.component.html',
  styleUrls: ['./project-rbc-calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    ProjectRbcCalendarDataService,
    ProjectRbcCalendarService,
    ProjectRbcCalendarSlotInfoService,
    ProjectRbcCalendarGroupFilterService,
    ScheduleNavigationService,
    GridService,
    FreezeTableService,
  ],
  standalone: false,
})
export class ProjectRbcCalendarComponent implements OnInit, OnDestroy {
  @Input() frName = 'rbc';
  @Input() hasHeader = true;
  @Input() hasToolbar = true;

  public trackById(index: number, row: any): string {
    if (row.id) {
      return row.id;
    } else {
      return row.type;
    }
  }

  constructor(
    public service: ProjectRbcCalendarService,
    public rbcDataService: ProjectRbcCalendarDataService,
    public groupFilterService: ProjectRbcCalendarGroupFilterService,
    private changeDetector: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.service.init();
    this.service.changes$.subscribe(() => {
      this.changeDetector.detectChanges();
    });
    this.groupFilterService.changes$.subscribe(() => {
      this.changeDetector.detectChanges();
    });
  }

  ngOnDestroy(): void {
    this.service.dispose();
  }
}
