import { CommonModule } from '@angular/common';
import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnDestroy,
  OnInit,
  signal,
} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { toggleMark } from 'prosemirror-commands';
import { EditorState } from 'prosemirror-state';
import { EditorView } from 'prosemirror-view';
import { RichEditorBoxMenuService } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/services/rich-editor-box-menu.service';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { SharedModule } from 'src/app/shared/shared.module';

@Component({
    selector: 'tmt-link-popup',
    templateUrl: './link-popup.component.html',
    styleUrl: './link-popup.component.scss',
    imports: [CommonModule, SharedModule],
    changeDetection: ChangeDetectionStrategy.OnPush
})
export class LinkPopupComponent implements OnInit, OnDestroy {
  @Input() public link = '';
  @Input() public text = '';

  @Input() private editorView: EditorView;

  public form: FormGroup = this.formBuilder.group({
    link: '',
    text: '',
  });
  public isUpdateMode = signal<boolean>(false);

  private state: EditorState;

  constructor(
    private formBuilder: FormBuilder,
    private infoPopupService: InfoPopupService,
    private richEditorBoxMenuService: RichEditorBoxMenuService,
  ) {}

  ngOnInit(): void {
    this.form.patchValue({
      link: this.link,
      text: this.text,
    });
    this.state = this.editorView.state;
    this.isUpdateMode.set(!!(this.link || (!this.link && this.text)));
  }

  ngOnDestroy(): void {
    if (!this.link && this.text) {
      toggleMark(this.editorView.state.schema.marks.link)(
        this.state,
        this.editorView.dispatch,
      );
    }
  }

  /** Updates link. */
  public update(): void {
    if (!this.form.value.link) {
      return;
    }
    this.link = this.form.value.link;
    this.updateLinkMark({
      text: this.form.value.text || this.form.value.link,
      href: this.form.value.link,
    });
    this.editorView.dom
      .querySelectorAll(`a[href="${this.link}"]`)
      .forEach((link) => {
        this.richEditorBoxMenuService.linkClickEventSubscriber(
          link as HTMLElement,
        );
      });
    this.close();
  }

  /** Creates new link. */
  public create(): void {
    if (!this.form.value.link) {
      return;
    }

    const attrs: Record<string, string> = {
      text: this.form.value.text || this.form.value.link,
      href: this.form.value.link,
    };
    const schema = this.editorView.state.schema;
    const node = schema.text(attrs.text, [schema.marks.link.create(attrs)]);
    this.editorView.dispatch(
      this.editorView.state.tr.replaceSelectionWith(node, false),
    );
    this.subscribeCurrentElementClick(attrs.text);
    this.close();
  }

  /** Closes link popup. */
  public close(): void {
    this.infoPopupService.close();
  }

  /**
   * Updates link mark.
   *
   * @param attrs links new attributes.
   * @returns function for updating editor state.
   */
  private updateLinkMark(attrs: object): void {
    let from = this.editorView.state.selection.from;
    let to = this.editorView.state.selection.to;
    if (from === to) {
      const position = this.editorView.state.tr.doc.resolve(from);
      const parentInfo = position.parent.childBefore(position.parentOffset);
      from = parentInfo.offset + 1;
      to = from + (parentInfo.node?.nodeSize || 0);
    }
    this.editorView.dispatch(
      this.editorView.state.tr.addMark(
        from,
        to,
        this.editorView.state.schema.marks.link.create(attrs),
      ),
    );
  }

  /** Subscribes to current element's click event. */
  private subscribeCurrentElementClick(text: string): void {
    const currentElement =
      text.length > 1
        ? this.editorView.domAtPos(this.editorView.state.selection.head - 1)
            .node.parentElement
        : this.editorView.nodeDOM(this.editorView.state.selection.head - 1)
            .parentElement;
    this.richEditorBoxMenuService.linkClickEventSubscriber(currentElement);
  }
}
