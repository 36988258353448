import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import { Feature } from 'src/app/shared/models/enums/feature.enum';
import { ProjectTeamService } from '../project-team.service';
import { UntypedFormControl } from '@angular/forms';
import { AbstractToolbar } from 'src/app/shared-features/grid/abstract-toolbar.directive';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'tmt-team-toolbar',
  templateUrl: './team-toolbar.component.html',
  standalone: false,
})
export class TeamToolbarComponent extends AbstractToolbar implements OnInit {
  public feature = Feature;

  public filterControl = new UntypedFormControl(false);

  constructor(
    public app: AppService,

    private projectTeamService: ProjectTeamService,
    gridService: GridService,
    cdr: ChangeDetectorRef,
  ) {
    super(gridService, cdr);
  }

  public override ngOnInit(): void {
    super.ngOnInit();
    this.filterControl.setValue(this.projectTeamService.settings.onlyActive, {
      emitEvent: false,
    });

    this.filterControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.projectTeamService.setFilter(this.filterControl.value);
      });
  }
}
