import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  ElementRef,
  inject,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { DateTime } from 'luxon';
import { take } from 'rxjs/operators';
import {
  ProjectTask,
  ProjectTaskDependency,
  ProjectTaskDependencyType,
} from 'src/app/shared/models/entities/projects/project-task.model';
import {
  DragContext,
  DragDropService,
} from 'src/app/shared/services/drag-drop';
import { ProjectTasksDataService } from 'src/app/projects/card/project-tasks/core/project-tasks-data.service';
import { ProjectTaskDependenciesService } from 'src/app/projects/card/project-tasks/core/project-task-dependencies.service';
import { TranslateService } from '@ngx-translate/core';
import { ProjectTaskTimelineService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/project-task-timeline.service';
import { TimelinePositionService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/timeline-position.service';
import { TimelineRenderingService } from 'src/app/projects/card/project-tasks/shared/tasks-grid/timeline-right-side/core/timeline-rendering.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ProjectTeamService } from 'src/app/shared/services/project-team.service';
import { DatePipe, PercentPipe } from '@angular/common';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { GraphNodeType } from 'src/app/projects/card/project-tasks/shared/models/timeline-graph.model';
import { merge } from 'rxjs';

@Component({
  selector: 'wp-timeline-entry',
  templateUrl: './timeline-entry.component.html',
  styleUrls: ['./timeline-entry.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class TimelineEntryComponent implements OnInit {
  @Input() taskFormGroup: UntypedFormGroup;
  @ViewChild('taskStrip') taskStrip: ElementRef;

  /** Left shift (means start position on the board). */
  public left: number;

  /** Plan task left shift. */
  public plannedTaskLeft: number;

  /** Task width. */
  public width: number;

  /** Plan task width. */
  public plannedTaskWidth: number;

  public isMilestone: boolean;

  /** Breakpoint for outside brackets. */
  public widthBreakpoint = 15;

  public plannedTaskStyles: Record<string, string> = {};

  public get percentCompleteStyle(): string | null {
    const percentComplete = this.taskFormGroup.getRawValue().isActive
      ? this.percentCompleteForView
      : 1;

    return percentComplete
      ? `linear-gradient(
        90deg,
        var(--main-task-color) 0%,
        var(--main-task-color) ${percentComplete * 100}%,
        var(--secondary-task-color) ${percentComplete * 100}%,
        var(--secondary-task-color) 100%
      )`
      : null;
  }

  public get percentCompleteForView(): number | null {
    if (this.taskFormGroup.value.isExpanded) {
      return (this.taskFormGroup.getRawValue().percentComplete ??= null);
    } else {
      return (this.taskFormGroup.getRawValue().percentCompleteSum ??= null);
    }
  }

  /** Return is task lead. */
  public get isLeadTask(): boolean {
    return this.dataService.checkIsLeadTask(this.taskFormGroup.value.id);
  }

  private _hiddenLeftSideDeps: ProjectTask[];
  public get hiddenLeftSideDeps(): ProjectTask[] {
    return this._hiddenLeftSideDeps;
  }
  private _hiddenLeftSideDepsTitle: string;
  public get hiddenLeftSideDepsTitle(): string {
    return this._hiddenLeftSideDepsTitle;
  }

  private _hiddenRightSideDeps: ProjectTask[];
  public get hiddenRightSideDeps(): ProjectTask[] {
    return this._hiddenRightSideDeps;
  }
  private _hiddenRightSideDepsTitle: string;
  public get hiddenRightSideDepsTitle(): string {
    return this._hiddenRightSideDepsTitle;
  }

  private _taskHint: string;
  public get taskHint(): string {
    return this._taskHint;
  }

  private _plannedTaskHint: string;
  public get plannedTaskHint(): string {
    return this._plannedTaskHint;
  }

  /** Determines is some drag&drop action in progress (task drag, task resizing or dependency creating). */
  private isDragAndDropBusy: boolean;

  private taskHintNames: Record<string, string> = {
    name: '',
    dates: '',
    duration: '',
    percentComplete: '',
    assignments: '',
  };

  private readonly destroyRef = inject(DestroyRef);

  constructor(
    public gridService: GridService,
    public cdr: ChangeDetectorRef,
    public service: ProjectTaskTimelineService,
    private dragDropService: DragDropService,
    public timelineRenderingService: TimelineRenderingService,
    public dataService: ProjectTasksDataService,
    public dependenciesService: ProjectTaskDependenciesService,
    public timelinePositionService: TimelinePositionService,
    private translateService: TranslateService,
    private projectTeamService: ProjectTeamService,
    private datePipe: DatePipe,
    private percentPipe: PercentPipe,
    private versionCardService: ProjectVersionCardService,
    private elementRef: ElementRef,
  ) {}

  public ngOnInit(): void {
    this.initSubscribes();
    this.updateTaskView();

    merge(
      this.dataService.criticalTaskIds$,
      this.dataService.isShowCriticalPath$,
    )
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        const mainTaskColorVarName = '--main-task-color';
        const secondaryTaskColorVarName = '--secondary-task-color';
        const baseMainTaskColor = 'rgba(70, 90, 170, 0.8)';
        const baseSecondaryTaskColor = 'rgba(140, 160, 250, 0.8)';
        const criticalMainTaskColor = '#ff0000ad';
        const criticalSecondaryTaskColor = '#ff000070';
        if (
          this.dataService.isShowCriticalPath$.getValue() &&
          this.dataService.criticalTaskIds$
            .getValue()
            .includes(this.taskFormGroup.value.id)
        ) {
          this.elementRef.nativeElement.style.setProperty(
            mainTaskColorVarName,
            criticalMainTaskColor,
          );
          this.elementRef.nativeElement.style.setProperty(
            secondaryTaskColorVarName,
            criticalSecondaryTaskColor,
          );
        } else {
          this.elementRef.nativeElement.style.setProperty(
            mainTaskColorVarName,
            baseMainTaskColor,
          );
          this.elementRef.nativeElement.style.setProperty(
            secondaryTaskColorVarName,
            baseSecondaryTaskColor,
          );
        }
      });
  }

  /**
   * Begins drag of task.
   *
   * @param startEvent pointer event.
   * @param task html task element.
   * @returns
   */
  public initDrag(startEvent: PointerEvent, task: HTMLElement): void {
    startEvent.preventDefault();
    if (this.isDragAndDropBusy) {
      return;
    }
    this.isDragAndDropBusy = true;

    if (this.dataService.readonly) {
      return;
    }

    /** Select task. */
    this.gridService.selectGroup(this.taskFormGroup);

    /** Dragging subscription. */
    this.dragDropService
      .drag(startEvent)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ diffX }) => {
          task.style.left = `${this.left + diffX}px`;
        },
      });

    /** End of dragging subscription. */
    this.dragDropService.dragEnd$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ diffX }) => {
          const startDate = DateTime.fromISO(
            this.taskFormGroup.value.startDate,
          );
          const roundedDaysCount = this.timelinePositionService.getDays(
            startDate,
            diffX,
          );
          if (roundedDaysCount) {
            if (this.isMilestone && !this.isLeadTask) {
              this.left =
                this.timelinePositionService.getPosition(
                  DateTime.fromISO(this.taskFormGroup.value.endDate),
                ) +
                this.timelinePositionService.getDayWidth(
                  this.taskFormGroup.value.endDate,
                ) -
                this.timelinePositionService.halfOfMilestoneSize;
            } else {
              this.left = this.timelinePositionService.getPosition(startDate);
            }

            this.updateTaskDates(roundedDaysCount, roundedDaysCount);
          } else {
            task.style.left = `${this.left}px`;
            task.style.width = `${this.width}px`;
          }
          this.timelineRenderingService.highlightTaskDependencies();
          this.cdr.detectChanges();
          this.isDragAndDropBusy = false;
        },
      });
  }

  /**
   * Begins resize of task.
   *
   * @param startEvent pointer event.
   * @param task html task element.
   * @param side side of task strip.
   */
  public initResize(
    startEvent: PointerEvent,
    task: HTMLElement,
    side: string,
  ): void {
    startEvent.preventDefault();
    if (this.isDragAndDropBusy) {
      return;
    }
    this.isDragAndDropBusy = true;

    /** Select task. */
    this.gridService.selectGroup(this.taskFormGroup);

    const startDate = DateTime.fromISO(this.taskFormGroup.value.startDate);
    const endDate = DateTime.fromISO(this.taskFormGroup.value.endDate);
    const taskDuration = endDate.diff(startDate, 'days').plus({ day: 1 });

    /** Dragging subscription. */
    this.dragDropService
      .drag(startEvent)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ diffX }) => {
          if (side === 'left') {
            const rightBorderDayWidth =
              this.timelinePositionService.getDayWidth(endDate);
            /** Check min width. */
            if (this.width - diffX >= rightBorderDayWidth) {
              task.style.left = `${this.left + diffX}px`;
              task.style.width = `${this.width - diffX}px`;
            } else {
              task.style.left = `${this.timelinePositionService.getPosition(
                endDate,
              )}px`;
              task.style.width = `${rightBorderDayWidth}px`;
            }
          } else {
            const leftBorderDayWidth =
              this.timelinePositionService.getDayWidth(startDate);
            /** Check min width. */
            if (this.width + diffX >= leftBorderDayWidth) {
              task.style.width = `${this.width + diffX}px`;
            } else {
              task.style.width = `${leftBorderDayWidth}px`;
            }
          }
        },
      });

    /** End of dragging subscription. */
    this.dragDropService.dragEnd$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ diffX }) => {
          let roundedDaysCount;
          if (side === 'left') {
            roundedDaysCount = this.timelinePositionService.getDays(
              startDate,
              diffX,
            );
            if (roundedDaysCount) {
              if (taskDuration.days - roundedDaysCount <= 0) {
                roundedDaysCount = taskDuration.days - 1;
              }
              this.left = this.timelinePositionService.getPosition(startDate);
              this.updateTaskDates(roundedDaysCount, null);
            }
          } else {
            roundedDaysCount = this.timelinePositionService.getDays(
              endDate,
              diffX,
            );
            if (roundedDaysCount) {
              if (taskDuration.days + roundedDaysCount <= 0) {
                roundedDaysCount = -taskDuration.days + 1;
              }
              this.updateTaskDates(null, roundedDaysCount);
            }
          }
          if (!roundedDaysCount) {
            task.style.left = `${this.left}px`;
            task.style.width = `${this.width}px`;
          }
          this.timelineRenderingService.highlightTaskDependencies();
          this.cdr.detectChanges();
          this.isDragAndDropBusy = false;
        },
      });
  }

  /**
   * Creates task depending.
   *
   * @param startEvent pointer event.
   * @param task html task element.
   * @param createType task dependency creating type.
   * @param taskId id of the task.
   */
  public initCreateDepend(
    startEvent: PointerEvent,
    task: HTMLElement,
    dependencyMarkerType: GraphNodeType,
    taskId: string,
  ): void {
    startEvent.preventDefault();
    if (this.isDragAndDropBusy) {
      return;
    }
    this.isDragAndDropBusy = true;

    /** Select task. */
    this.gridService.selectGroup(this.taskFormGroup);

    const canvas = this.timelineRenderingService.dynamicCanvas;
    const canvasPosition = canvas.getBoundingClientRect();
    const dragContext: DragContext = {
      leftOffset: canvasPosition.left,
      topOffset: canvasPosition.top,
    };

    const taskPosition = task.getBoundingClientRect();
    let beginPointCoordinates = { x: null, y: null };
    switch (dependencyMarkerType) {
      case 'finish':
        beginPointCoordinates = {
          x: taskPosition.left + taskPosition.width - dragContext.leftOffset,
          y: taskPosition.top + taskPosition.height / 2 - dragContext.topOffset,
        };
        break;
      case 'start':
        beginPointCoordinates = {
          x: taskPosition.left - dragContext.leftOffset,
          y: taskPosition.top + taskPosition.height / 2 - dragContext.topOffset,
        };
    }

    const predecessorNode = { id: taskId, type: dependencyMarkerType };
    // Show allowed dependency markers
    this.dependenciesService.filterDependencyMarkers(predecessorNode);
    this.dependenciesService.setIsCreatingDependency(true);

    /** Dragging subscription. */
    this.dragDropService
      .drag(startEvent, dragContext)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ x, y }) => {
          const ctx = canvas.getContext('2d');
          /** Set line styles. */
          ctx.strokeStyle = 'rgba(67, 87, 173, 0.8)';
          ctx.lineWidth = 2;

          this.timelineRenderingService.highlightTaskDependencies();
          ctx.beginPath();
          ctx.moveTo(beginPointCoordinates.x, beginPointCoordinates.y);
          ctx.lineTo(x, y);
          ctx.stroke();
        },
      });

    /** End of dragging subscription. */
    this.dragDropService.dragEnd$
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: ({ target }) => {
          const targetElement = target as HTMLElement;
          const targetElementType = targetElement.dataset.type;
          const targetElementId = targetElement.dataset.id;

          if (targetElementType === 'start' || targetElementType === 'finish') {
            const targetFormGroup = this.dataService.formArray.controls.find(
              (taskGroup) => taskGroup.value.id === targetElementId,
            ) as UntypedFormGroup;
            switch (dependencyMarkerType) {
              case 'finish': {
                let dependency: ProjectTaskDependency;
                if (targetElementType === 'start') {
                  dependency = {
                    predecessorId: this.taskFormGroup.value.id,
                    type: ProjectTaskDependencyType.finishToStart,
                  };
                } else {
                  dependency = {
                    predecessorId: this.taskFormGroup.value.id,
                    type: ProjectTaskDependencyType.finishToFinish,
                  };
                }

                this.service.addDependency(targetFormGroup, dependency);
                break;
              }

              case 'start': {
                let dependency: ProjectTaskDependency;
                if (targetElementType === 'finish') {
                  dependency = {
                    predecessorId: this.taskFormGroup.value.id,
                    type: ProjectTaskDependencyType.startToFinish,
                  };
                } else {
                  dependency = {
                    predecessorId: this.taskFormGroup.value.id,
                    type: ProjectTaskDependencyType.startToStart,
                  };
                }
                this.service.addDependency(targetFormGroup, dependency);
                break;
              }
            }
          }
          this.timelineRenderingService.clearCreateDynamicCanvas();
          this.timelineRenderingService.drawDependencies();
          this.timelineRenderingService.highlightTaskDependencies();
          // Hide allowed dependency markers
          this.dependenciesService.setIsCreatingDependency(false);
          this.dependenciesService.setDefaultAllowedMarkerTaskIds();
          this.cdr.detectChanges();
          this.isDragAndDropBusy = false;
        },
      });
  }

  /**
   * Returns the styles for the right side strip of the lead task based on its completion status and milestone properties.
   *
   * @returns A record containing CSS style properties and their values for the right side strip.
   */
  public getLeadTaskRightSideStripStyles(): Record<string, string> {
    return {
      'background-color':
        this.percentCompleteForView >= 1 ||
        !this.taskFormGroup.getRawValue().isActive
          ? 'var(--main-task-color)'
          : 'var(--secondary-task-color)',
      width: this.isMilestone
        ? this.timelinePositionService.milestoneSize + 'px'
        : '',
      height: this.isMilestone
        ? this.timelinePositionService.milestoneSize + 'px'
        : '',
      right: this.isMilestone
        ? -this.timelinePositionService.halfOfMilestoneSize + 'px'
        : '',
    };
  }

  /**
   * Updates start/end task dates.
   *
   * @param startDayShift start day shift in days.
   * @param endDayShift end day shift in days.
   */
  private updateTaskDates(startDayShift: number, endDayShift: number): void {
    if (!startDayShift && !endDayShift) {
      return;
    }

    let datesForUpdate = {};
    if (endDayShift && !startDayShift) {
      const endDate = DateTime.fromISO(this.taskFormGroup.value.endDate);
      const newEndDate = endDate.plus({ days: endDayShift });
      datesForUpdate = {
        ...datesForUpdate,
        endDate: newEndDate.toISODate(),
      };
    }

    if (startDayShift) {
      const startDate = DateTime.fromISO(this.taskFormGroup.value.startDate);
      const newStartDate = startDate.plus({ days: startDayShift });
      datesForUpdate = {
        ...datesForUpdate,
        startDate: newStartDate.toISODate(),
      };
    }

    if (startDayShift && endDayShift) {
      this.dataService.taskMovedByTimeline = true;
    }

    this.taskFormGroup.patchValue(datesForUpdate);
  }

  /** Updates task view. */
  private updateTaskView(): void {
    /** Params for task width. */
    const startDate = DateTime.fromISO(
      this.taskFormGroup.getRawValue().startDate,
    );
    const endDate = DateTime.fromISO(this.taskFormGroup.getRawValue().endDate);

    /** Is hit in the visible board part. */
    if (this.isTaskStripVisible(startDate, endDate)) {
      this.isMilestone = this.taskFormGroup.getRawValue().isMilestone;
      const isCommonMilestoneTask = this.isMilestone && !this.isLeadTask;
      const positionDate = isCommonMilestoneTask ? endDate : startDate;
      this.left = this.timelinePositionService.getPosition(positionDate);
      if (isCommonMilestoneTask) {
        this.left +=
          this.timelinePositionService.getDayWidth(positionDate) -
          this.timelinePositionService.halfOfMilestoneSize;
      }
      this.width = isCommonMilestoneTask
        ? this.timelinePositionService.milestoneSize
        : this.timelinePositionService.getWidth(startDate, endDate);

      /** Restore task styles. */
      const taskElement = this.taskStrip;
      if (taskElement) {
        taskElement.nativeElement.style.left = `${this.left}px`;
        taskElement.nativeElement.style.width = `${this.width}px`;
      }
    } else {
      this.width = null;
    }
    this.updateHiddenDependencies();

    this.updateTaskHint();

    this.updatePlannedTaskView();
    this.updatePlannedTaskHint();

    this.cdr.detectChanges();
  }

  /** Updates the view for the planned task. */
  private updatePlannedTaskView(): void {
    const taskValue = this.taskFormGroup.getRawValue();
    if (
      this.versionCardService.projectVersion.masterBaseline ||
      !taskValue.plannedStartDate ||
      !taskValue.plannedEndDate
    ) {
      this.plannedTaskWidth = null;
      return;
    }
    const startDate = DateTime.fromISO(taskValue.plannedStartDate);
    const endDate = DateTime.fromISO(taskValue.plannedEndDate);
    if (this.isTaskStripVisible(startDate, endDate)) {
      this.plannedTaskLeft =
        this.timelinePositionService.getPosition(startDate);
      this.plannedTaskWidth = this.timelinePositionService.getWidth(
        startDate,
        endDate,
      );
      let top = '';
      if (this.width) {
        if (this.isLeadTask) {
          top = '8px';
        } else {
          top = '13px';
        }
      } else {
        top = '28px';
      }
      this.plannedTaskStyles = {
        width: this.plannedTaskWidth + 'px',
        left: this.plannedTaskLeft + 'px',
        top,
      };
    } else {
      this.plannedTaskWidth = null;
    }
  }

  /**
   * Determines if the task strip is visible within the timeline slots.
   *
   * @param startDate - The start date of the task.
   * @param endDate - The end date of the task.
   * @returns A boolean indicating whether the task strip is visible.
   */
  private isTaskStripVisible(startDate: DateTime, endDate: DateTime): boolean {
    const slotMinDate = this.service.slots[0]?.date;
    const slotMaxDate = this.service.slots[this.service.slots.length - 1]?.date;
    return (
      (slotMinDate <= startDate && startDate <= slotMaxDate) ||
      (slotMinDate <= endDate && endDate <= slotMaxDate) ||
      (startDate < slotMinDate && endDate > slotMaxDate)
    );
  }

  /** Updates the task hint. */
  private updateTaskHint(): void {
    const taskValue = this.taskFormGroup.getRawValue();
    let hint = '';
    hint += `${this.service.taskHintNames.taskName}: ${taskValue.name}\n`;
    hint += `${this.service.taskHintNames.dates}: ${this.datePipe.transform(taskValue.startDate, 'shortDate')} — ${this.datePipe.transform(taskValue.endDate, 'shortDate')}\n`;
    hint += `${this.service.taskHintNames.projectTaskDuration}: ${taskValue.duration} ${this.translateService.instant('shared.duration.day')}\n`;
    hint += `${this.service.taskHintNames.percentComplete}: ${this.percentPipe.transform(taskValue.percentComplete, `1.0-1`)}\n`;

    const taskAssignmentsString = this.projectTeamService.getAssignmentsString(
      taskValue.projectTaskAssignments,
      3,
    );
    hint += `${this.service.taskHintNames.assignments}: ${taskAssignmentsString ?? ''}`;

    this._taskHint = hint;
  }

  /** Updates the planned task hint. */
  private updatePlannedTaskHint(): void {
    const taskValue = this.taskFormGroup.getRawValue();
    let hint = '';
    const startDateString = this.datePipe.transform(
      taskValue.plannedStartDate,
      'shortDate',
    );
    const endDateString = this.datePipe.transform(
      taskValue.plannedEndDate,
      'shortDate',
    );
    hint += `${this.service.taskHintNames.dates}: ${startDateString} — ${endDateString}\n`;
    this._plannedTaskHint = hint;
  }

  /** Subscribes initialization. */
  private initSubscribes(): void {
    this.service.updateTasks$
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.updateTaskView();
      });
  }

  /** Updates hidden dependencies markers. */
  private updateHiddenDependencies(): void {
    if (!this.width) {
      return;
    }
    const filterHiddenPredecessorTask: (
      type: ProjectTaskDependencyType,
    ) => ProjectTask[] = (type: ProjectTaskDependencyType) =>
      this.taskFormGroup.value.dependencies
        ?.filter((d) => d.type === type)
        .filter(
          (dependency) =>
            !this.dataService.formArray.value.find(
              (task) => task.id === dependency.predecessorId,
            ),
        )
        .map((dependency) =>
          this.dataService.getTask(dependency.predecessorId),
        );

    const filterHiddenDependentTasks: (
      type: ProjectTaskDependencyType,
    ) => ProjectTask[] = (type: ProjectTaskDependencyType) =>
      this.dataService.tasks
        ?.filter(
          (task) =>
            !this.dataService.formArray.value.some(
              (visibleTask) => visibleTask.id === task.id,
            ),
        )
        .filter((hiddenTask) =>
          hiddenTask.dependencies?.some(
            (d) =>
              d.type === type &&
              d.predecessorId === this.taskFormGroup.value.id,
          ),
        );

    const predecessorFS = filterHiddenPredecessorTask(
      ProjectTaskDependencyType.finishToStart,
    );
    const predecessorSS = filterHiddenPredecessorTask(
      ProjectTaskDependencyType.startToStart,
    );
    const predecessorFF = filterHiddenPredecessorTask(
      ProjectTaskDependencyType.finishToFinish,
    );
    const predecessorSF = filterHiddenPredecessorTask(
      ProjectTaskDependencyType.startToFinish,
    );

    const dependentSF = filterHiddenDependentTasks(
      ProjectTaskDependencyType.startToFinish,
    );
    const dependentSS = filterHiddenDependentTasks(
      ProjectTaskDependencyType.startToStart,
    );
    const dependentFF = filterHiddenDependentTasks(
      ProjectTaskDependencyType.finishToFinish,
    );
    const dependentFS = filterHiddenDependentTasks(
      ProjectTaskDependencyType.finishToStart,
    );

    this._hiddenLeftSideDeps = [
      ...predecessorFS,
      ...predecessorSS,
      ...dependentSF,
      ...dependentSS,
    ];

    this._hiddenRightSideDeps = [
      ...predecessorSF,
      ...predecessorFF,
      ...dependentFS,
      ...dependentFF,
    ];

    const hiddenDependentsDescription = this.translateService.instant(
      'components.timelineEntryComponent.props.hiddenDependencies.value',
    );

    /**
     * Generates a string representation of a list of dependent tasks.
     *
     * @param depsTasks - An array of ProjectTask objects representing the dependent tasks.
     * @param type - A string indicating the type of dependency.
     * @returns A string representation of the dependency list.
     */
    const generateDependencyList = (
      depsTasks: ProjectTask[],
      type: ProjectTaskDependencyType,
    ): string => {
      if (!depsTasks.length) return '';
      let dependencyList = '';
      dependencyList += `\t${this.translateService.instant(`shared2.props.${type}.value`)}\n`;

      dependencyList += depsTasks
        .map((task) => `\t\t${task.structNumber} - ${task.name};\n`)
        .join('');
      return dependencyList;
    };

    let rightSideTitle = `${hiddenDependentsDescription} - ${this._hiddenRightSideDeps.length}.\n`;
    if (predecessorSF.length || predecessorFF.length) {
      rightSideTitle += `${this.translateService.instant(`components.timelineEntryComponent.groups.incoming`)}:\n`;
      rightSideTitle += generateDependencyList(
        predecessorSF,
        ProjectTaskDependencyType.startToFinish,
      );
      rightSideTitle += generateDependencyList(
        predecessorFF,
        ProjectTaskDependencyType.finishToFinish,
      );
    }
    if (dependentFS.length || dependentFF.length) {
      rightSideTitle += `${this.translateService.instant(`components.timelineEntryComponent.groups.outgoing`)}:\n`;
      rightSideTitle += generateDependencyList(
        dependentFS,
        ProjectTaskDependencyType.finishToStart,
      );
      rightSideTitle += generateDependencyList(
        dependentFF,
        ProjectTaskDependencyType.finishToFinish,
      );
    }
    this._hiddenRightSideDepsTitle = rightSideTitle;

    let leftSideTitle = `${hiddenDependentsDescription} - ${this._hiddenLeftSideDeps.length}.\n`;
    if (predecessorFS.length || predecessorSS.length) {
      leftSideTitle += `${this.translateService.instant(`components.timelineEntryComponent.groups.incoming`)}:\n`;
      leftSideTitle += generateDependencyList(
        predecessorFS,
        ProjectTaskDependencyType.finishToStart,
      );
      leftSideTitle += generateDependencyList(
        predecessorSS,
        ProjectTaskDependencyType.startToStart,
      );
    }
    if (dependentSF.length || dependentSS.length) {
      leftSideTitle += `${this.translateService.instant(`components.timelineEntryComponent.groups.outgoing`)}:\n`;
      leftSideTitle += generateDependencyList(
        dependentSF,
        ProjectTaskDependencyType.startToFinish,
      );
      leftSideTitle += generateDependencyList(
        dependentSS,
        ProjectTaskDependencyType.startToStart,
      );
    }
    this._hiddenLeftSideDepsTitle = leftSideTitle;
  }
}
