<div class="gauge" [ngStyle]="gaugeStyle">
  @for (tick of ticks; track tick) {
    <div class="gauge__tick" [ngStyle]="getTickStyle(tick)"></div>
  }

  @for (number of numbers; track number; let i = $index) {
    <div class="gauge__number" [ngStyle]="getNumberStyle(number)">
      {{ number.value }}
    </div>
  }
  <div class="gauge__inner" [ngStyle]="gaugeInnerStyle">
    <div
      class="gauge__value"
      [ngStyle]="gaugeValueStyle"
      [title]="config.title + ': ' + config.value"
    >
      {{ config.value }}
    </div>
  </div>

  <div class="gauge__needle" [ngStyle]="gaugeNeedleStyle"></div>
</div>
