import { Component, OnInit, OnDestroy } from '@angular/core';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { DataService } from 'src/app/core/data.service';
import { Constants } from 'src/app/shared/globals/constants';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import { SettingsCardService } from '../settings-card.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Subscription } from 'rxjs';
import { Tenant } from 'src/app/shared/models/entities/settings/multitenant/tenant.model';
import { Exception } from 'src/app/shared/models/exception';
import { MessageService } from 'src/app/core/message.service';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';

@Component({
  selector: 'wp-settings-main',
  templateUrl: './settings-main.component.html',
  styleUrls: ['./settings-main.component.scss'],
  standalone: false,
})
export class SettingsMainComponent implements OnInit, OnDestroy {
  public readonly = false;
  public isSaving = false;
  public state: CardState;
  private reloadSubscription: Subscription;

  public form = this.fb.group({
    displayName: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    ownerName: [
      '',
      [Validators.required, Validators.maxLength(Constants.formNameMaxLength)],
    ],
    ownerEmail: [
      '',
      [
        Validators.required,
        Validators.email,
        Validators.maxLength(Constants.formNameMaxLength),
      ],
    ],
    ownerPhone: ['', [Validators.required]],
    isServiceUserAllowed: [false],
  });

  constructor(
    private service: SettingsCardService,
    private actionService: ActionPanelService,
    private app: AppService,
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private message: MessageService,
  ) {}

  private load() {
    this.state = CardState.Loading;
    this.form.markAsPristine();
    this.form.markAsUntouched();

    this.data
      .singleton('Tenant')
      .get<Tenant>()
      .subscribe({
        next: (data) => {
          this.form.patchValue(data);
          this.state = CardState.Ready;

          this.readonly = !this.app.checkPermission(
            'ManageAccount',
            null,
            PermissionType.Execute,
          );

          if (this.readonly) {
            this.form.disable();
          }

          if (!this.readonly) {
            this.actionService.action('save').show();
          }
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.state = CardState.Error;
        },
      });
  }

  public reload() {
    if (!this.form.dirty) {
      this.load();
    } else {
      this.message.confirmLocal('shared.leavePageMessage').then(
        () => this.load(),
        () => null,
      );
    }
  }

  public save() {
    this.form.markAllAsTouched();

    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.isSaving = true;
    this.actionService.action('save').start();

    const tenant = <Tenant>this.form.value;

    const setting: any = {};

    this.data
      .singleton('Tenant')
      .update(this.form.value)
      .subscribe({
        next: () => {
          this.form.markAsPristine();
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.successLocal(
            'settings.settings.messages.settingSaved',
          );
        },
        error: (error: any) => {
          this.isSaving = false;
          this.actionService.action('save').stop();
          this.notification.error(error.message);
        },
      });
  }

  ngOnInit() {
    // Установка главного меню.
    this.actionService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
        handler: () => this.save(),
      },
    ]);

    this.load();

    this.reloadSubscription = this.service.reloadTab$.subscribe(() =>
      this.reload(),
    );
  }

  ngOnDestroy(): void {
    this.reloadSubscription.unsubscribe();
  }
}
