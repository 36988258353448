<form [formGroup]="detailsForm" class="form">
  <div class="row">
    <div class="col-6">
      @if (filterInstance.hasClient) {
        <div class="form-group">
          <label>{{
            'components.contactListFilterComponent.filters.client.value' | translate
          }}</label>
          <wp-select-box
            formControlName="organization"
            collection="Organizations"
            [allowInactive]="filterService.allowInactive$ | async"
            placeholder="{{
              'components.contactListFilterComponent.filters.client.ph' | translate
            }}"
          >
          </wp-select-box>
        </div>
      }

      <div class="form-group">
        <label>{{ 'shared2.filters.role.value' | translate }}</label>
        <wp-select-box
          formControlName="role"
          [directory]="{ entityName: 'Contact', propertyName: 'Role' }"
          [allowInactive]="filterService.allowInactive$ | async"
          placeholder="{{ 'shared2.filters.role.ph' | translate }}"
        />
      </div>
    </div>
  </div>
</form>
