import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/core/app.service';
import { PermissionType } from 'src/app/shared/models/inner/permission-type.enum';
import { UserCardService } from './user-card.service';
import { StateService } from '@uirouter/core';
import { TabLink } from 'src/app/shared/models/navigation/navigation';
import { ActionPanelService } from 'src/app/core/action-panel.service';

@Component({
  selector: 'wp-user-card',
  templateUrl: './user-card.component.html',
  providers: [UserCardService],
  standalone: false,
})
export class UserCardComponent implements OnInit {
  @Input() entityId: string;

  tabs: TabLink[] = [];

  constructor(
    public state: StateService,
    public service: UserCardService,
    private app: AppService,
    private actionPanelService: ActionPanelService,
  ) {}

  ngOnInit() {
    this.actionPanelService.set([
      {
        title: 'shared.actions.save',
        hint: 'shared.actions.save',
        name: 'save',
        isDropDown: false,
        iconClass: 'bi bi-save',
        isBusy: false,
        isVisible: false,
      },
      {
        title: 'settings.users.card.actions.management',
        hint: 'settings.users.card.actions.management',
        name: 'management',
        isDropDown: true,
        isVisible: false,
        isBusy: false,
        actions: [
          {
            name: 'restorePassword',
            title: 'settings.users.card.actions.sendResetMessage',
            hint: 'settings.users.card.actions.sendResetMessage',
            isBusy: false,
            isVisible: true,
            handler: () => this.service.restorePassword(),
          },
          {
            name: 'resetPassword',
            title: 'settings.users.card.actions.resetPassword',
            hint: 'settings.users.card.actions.resetPassword',
            isBusy: false,
            isVisible: true,
            handler: () => this.service.resetPassword(),
          },
          {
            name: 'removeOtp',
            title: 'settings.users.card.actions.removeOtpConfiguration',
            hint: 'settings.users.card.actions.removeOtpConfiguration',
            isBusy: false,
            isVisible: true,
            handler: () => this.service.removeOtpConfiguration(),
          },
        ],
      },
    ]);

    this.actionPanelService.setAdditional([
      {
        title: 'settings.users.card.actions.openLoginLog',
        hint: 'settings.users.card.actions.openLoginLog',
        name: 'openLoginLog',
        isBusy: false,
        isVisible: this.app.checkPermission(
          'LoginLogEntry',
          'All',
          PermissionType.Read,
        ),
        handler: () => this.service.goToLoginLog(),
      },
      {
        title: 'shared.actions.delete',
        hint: 'shared.actions.delete',
        name: 'delete',
        isBusy: false,
        isVisible: true,
        handler: () => this.service.delete(),
      },
    ]);

    this.tabs.push({
      header: 'settings.users.card.tabs.profile',
      state: 'settings.user.profile',
    });

    if (this.app.checkPermission('UserProduct', 'All', PermissionType.Read)) {
      this.tabs.push({
        header: 'settings.users.card.tabs.licenses',
        state: 'settings.user.licenses',
      });
    }

    if (this.app.checkPermission('UserGroup', 'All', PermissionType.Read)) {
      this.tabs.push({
        header: 'settings.users.card.tabs.groups',
        state: 'settings.user.groups',
      });
    }

    if (
      this.app.checkPermission('UserPermissionSet', 'All', PermissionType.Read)
    ) {
      this.tabs.push({
        header: 'settings.users.card.tabs.access',
        state: 'settings.user.access',
      });
    }

    this.tabs.push({
      header: 'settings.users.card.tabs.roles',
      state: 'settings.user.roles',
    });

    if (this.app.checkPermission('UserSchedule', 'All', PermissionType.Read)) {
      this.tabs.push({
        header: 'settings.users.card.tabs.schedule',
        state: 'settings.user.schedule',
      });
    }

    if (this.app.checkPermission('UserCostValue', 'All', PermissionType.Read)) {
      this.tabs.push({
        header: 'settings.users.card.tabs.cost',
        state: 'settings.user.cost',
      });
    }

    this.tabs.push({
      header: 'settings.users.card.tabs.timesheets',
      state: 'settings.user.timesheets',
    });

    if (this.app.checkPermission('UserSetting', 'All', PermissionType.Read)) {
      this.tabs.push(
        {
          header: 'settings.users.card.tabs.settings',
          state: 'settings.user.settings',
        },
        {
          header: 'settings.users.card.tabs.notifications',
          state: 'settings.user.notifications',
        },
      );
    }

    if (
      this.app.checkPermission('UserSubstitute', 'All', PermissionType.Read)
    ) {
      this.tabs.push({
        header: 'settings.users.card.tabs.substitutes',
        state: 'settings.user.substitutes',
      });
    }
  }
}
