import { Component, inject } from '@angular/core';
import _ from 'lodash';
import { UntypedFormGroup } from '@angular/forms';
import { Exception } from 'src/app/shared/models/exception';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import { ProjectVersionCardService } from 'src/app/projects/card/core/project-version-card.service';
import { ProjectVersionDataService } from 'src/app/projects/project-versions/project-version-data.service';
import { ProjectVersionUtil } from 'src/app/projects/project-versions/project-version-util';
import { filterResourceTypes } from 'src/app/projects/card/project-team/add-resources-modal/models/filter-resource-types';
import { ListService } from 'src/app/shared/services/list.service';
import { GridService } from 'src/app/shared-features/grid/core/grid.service';
import { LIST, VIEW_NAME } from 'src/app/shared/tokens';
import { AddResourceModalToolbarComponent } from 'src/app/projects/card/project-team/add-resources-modal/add-resources-modal-toolbar/add-resources-modal-toolbar.component';
import { ResourcesFilterService } from 'src/app/shared/modules/resources-filter/resources-filter.service';
import { Resource } from 'src/app/shared/models/entities/settings/resource.model';
import { ResourceType } from 'src/app/shared/models/enums/resource-type.enum';
import { RESOURCES_LIST } from 'src/app/resources/resources.list';
import { AddEntitiesModalComponent } from 'src/app/shared/components/add-entities-modal/add-entities-modal.component';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Adding resources modal window. */
@Component({
  selector: 'tmt-add-resources-modal',
  templateUrl:
    '../../../../shared/components/add-entities-modal/add-entities-modal.component.html',
  styleUrls: [
    '../../../../shared/components/add-entities-modal/add-entities-modal.component.scss',
  ],
  providers: [
    { provide: FilterService, useClass: ResourcesFilterService },
    GridService,
    ListService,
    { provide: VIEW_NAME, useValue: 'addResourceModal' },
    { provide: LIST, useValue: RESOURCES_LIST },
  ],
  standalone: false,
})
export class AddResourcesModalComponent extends AddEntitiesModalComponent {
  public readonly filterResourceTypes = filterResourceTypes;

  protected override fieldToSortOnClient = 'resource.name';
  protected override selectionLimitWarning =
    'projects.projects.card.team.messages.30limit';
  protected override modalWindowHeader =
    'projects.projects.card.team.addResourcesModal.header';
  protected override noDisplayDataMessage =
    'projects.projects.card.team.addResourcesModal.noDisplayData';
  protected override hasAvatar = true;

  private readonly resourcesForTeamFilterNames = [
    'department',
    'resourcePool',
    'role',
    'legalEntity',
    'competence',
    'level',
    'grade',
    'location',
  ];
  private readonly versionCardService = inject(ProjectVersionCardService);
  private readonly versionDataService = inject(ProjectVersionDataService);

  constructor() {
    super();

    this.gridOptions.toolbar = AddResourceModalToolbarComponent;
  }

  /** Saves data. */
  protected override ok(): void {
    this.isSaving.set(true);

    const team = [];
    this.selectedEntities.forEach((resource: Resource) => {
      const member = {
        resourceId: resource.id,
      };

      ProjectVersionUtil.setEntityRootPropertyId(
        this.versionCardService.projectVersion,
        member,
        this.parentEntityId,
      );

      team.push(member);
    });

    this.versionDataService
      .projectCollectionEntity(
        this.versionCardService.projectVersion,
        this.parentEntityId,
      )
      .action('UpdateTeam')
      .execute({ team })
      .subscribe({
        next: () => {
          this.isSaving.set(false);
          this.notification.successLocal(
            'projects.projects.card.team.messages.membersWereAdded',
          );
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving.set(false);
        },
      });
  }

  /** Loads data. */
  protected override load(): void {
    this.availableEntities = [];
    this.isLoading.set(true);
    this.loadedPartly.set(false);

    const oDataParams = {
      select: ['id', 'name', 'resourceType', 'isActive'],
      expand: [
        { department: { select: ['id', 'name'] } },
        { role: { select: ['id', 'name'] } },
        { resourcePool: { select: ['id', 'name'] } },
        { level: { select: ['id', 'name'] } },
        { grade: { select: ['id', 'name'] } },
        { legalEntity: { select: ['id', 'name'] } },
        { location: { select: ['id', 'name'] } },
        { competence: { select: ['id', 'name'] } },
        { currentRate: { select: ['valueBC'] } },
      ],
      filter: [],
      orderBy: ['name'],
      top: this.loadingLimit,
    };

    oDataParams.filter.push({
      resourceType: { ne: ResourceType.generic },
    });
    oDataParams.filter.push({ isActive: true });

    const existedFilter = {
      teamMembers: {
        all: {},
      },
    };

    const projectOrVersionId = this.versionCardService.isWorkProjectVersion()
      ? this.parentEntityId
      : this.versionCardService.projectVersion.id;
    const projectOrVersionPropertyName =
      this.versionCardService.isWorkProjectVersion()
        ? 'projectId'
        : 'versionId';

    existedFilter.teamMembers.all[projectOrVersionPropertyName] = {
      ne: { type: 'guid', value: projectOrVersionId },
    };

    oDataParams.filter.push(existedFilter);

    if (this.selectedEntities?.length) {
      oDataParams.filter.push([
        `not (id in (${this.selectedEntities.map((r) => r.id).join(',')}))`,
      ]);
    }

    const resourceTypeConditions = [];
    this.filterResourceTypes.forEach((type) => {
      if (
        this.filterService.values.resourceTypes &&
        this.filterService.values.resourceTypes[type]
      ) {
        resourceTypeConditions.push({
          resourceType: _.upperFirst(type),
        });
      }
    });
    oDataParams.filter.push({ or: resourceTypeConditions });

    if (this.filterService.values.text) {
      oDataParams.filter.push({
        'tolower(name)': {
          contains: this.filterService.values.text.toLowerCase(),
        },
      });
    }

    this.resourcesForTeamFilterNames.forEach((name) => {
      if (!this.filterService.values[name]?.id) return;
      oDataParams.filter.push({
        [`${name}Id`]: {
          type: 'guid',
          value: this.filterService.values[name]?.id,
        },
      });
    });

    this.dataService
      .collection('Resources')
      .query(oDataParams)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe({
        next: (resources: Resource[]) => {
          this.formArray.clear();
          this.availableEntities = resources.filter(
            (resource) =>
              !this.selectedEntities.some((r) => r.id === resource.id),
          );

          this.availableEntities.forEach((resource) => {
            const formGroup = this.getEntityFormGroup(resource);
            this.formArray.push(formGroup);
          });

          this.isLoading.set(false);
          this.loadedPartly.set(
            this.availableEntities.length === this.loadingLimit,
          );
          this.cdr.detectChanges();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isLoading.set(false);
        },
      });
  }

  /**
   * Creates a form group for a given resource.
   *
   * @param resource - The resource for which the form group is created.
   * @returns A form group containing the resource details.
   */
  protected override getEntityFormGroup(entity: NamedEntity): UntypedFormGroup {
    const resource = entity as Resource;
    return this.fb.group({
      id: resource.id,
      resource,
      role: resource.role?.name ?? null,
      level: resource.level?.name ?? null,
      department: resource.department?.name ?? null,
      resourcePool: resource.resourcePool?.name ?? null,
      currentRate: resource.currentRate.valueBC,
      competence: resource.competence?.name ?? null,
      grade: resource.grade?.name ?? null,
      legalEntity: resource.legalEntity?.name ?? null,
      location: resource.location?.name ?? null,
      isActive: resource.isActive,
    });
  }
}
