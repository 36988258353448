import MarkdownIt from 'markdown-it';
import {
  ruler2Func,
  tokenize,
} from 'src/app/shared/components/controls/rich-editor-box/markdown/custom-rules';
import { additionalMarks } from 'src/app/shared/components/controls/rich-editor-box/markdown/marks/consts';

export const md = MarkdownIt('commonmark', {
  html: false,
  breaks: true,
  maxNesting: 100,
});

const defaultTextRenderer =
  md.renderer.rules.text ||
  function (tokens, idx, options, env, self) {
    return self.renderInline(tokens, idx, options);
  };

md.renderer.rules.mention = function (tokens, idx, options, env, self) {
  return `<span class="mention">${defaultTextRenderer(tokens, idx, options, env, self)}</span>`;
};

md.inline.ruler.push('mention', function (state, silent) {
  let pos = state.pos;
  const start = pos;

  // 0x40 - it is '@'
  if (state.src.charCodeAt(pos) === 0x40) {
    pos++;

    while (pos < state.posMax && /[a-zA-Z0-9_]/.test(state.src.charAt(pos))) {
      pos++;
    }

    if (pos > start + 1) {
      if (!silent) {
        const token = state.push('mention', '', 0);
        token.content = state.src.slice(start, pos);
      }

      state.pos = pos;
      return true;
    }
  }

  return false;
});

md.inline.ruler.before('strikethrough', 'strike', (state, silent) =>
  tokenize(state, silent, additionalMarks.strike.markerCode),
);
md.inline.ruler2.before('strikethrough', 'strike', (state) =>
  ruler2Func(state, additionalMarks.strike, 'strike'),
);

md.inline.ruler.before('emphasis', 'ins', (state, silent) =>
  tokenize(state, silent, additionalMarks.ins.markerCode),
);
md.inline.ruler2.before('emphasis', 'ins', (state) =>
  ruler2Func(state, additionalMarks.ins, 'ins'),
);

md.inline.ruler.before('entity', 'samp', (state, silent) =>
  tokenize(state, silent, additionalMarks.samp.markerCode),
);
md.inline.ruler2.before('fragments_join', 'samp', (state) =>
  ruler2Func(state, additionalMarks.samp, 'samp'),
);

const defaultLinkRender =
  md.renderer.rules.link_open ||
  function (tokens, idx, options, env, self) {
    return self.renderToken(tokens, idx, options);
  };

md.renderer.rules.link_open = function (tokens, idx, options, env, self) {
  tokens[idx].attrPush(['target', '_blank']);
  return defaultLinkRender(tokens, idx, options, env, self);
};
