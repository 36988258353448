<wp-loading-indicator [loading]="isLoading" />

@if (!isLoading) {
  <form class="modal-form" novalidate [formGroup]="transitionForm" ngDraggable>
    <div class="modal-header">
      <h3 class="modal-title">
        {{ 'settings.lifecycles.card.transitionForm.header' | translate }}
      </h3>
      <button
        type="button"
        class="btn-close"
        data-dismiss="modal"
        aria-hidden="true"
        (click)="cancel()"
      ></button>
    </div>

    <div class="modal-body">
      @for (control of transitionProperties; track control.name) {
        <div class="form-group">
          <div [formGroup]="transitionForm">
            <label class="control-label">
              {{ appService.getPropertyLocalizationName(metaEntity.name, control.name) }}
            </label>

            @switch (this.getMetaEntityProperty(control.name).type) {
              @case (MetaEntityPropertyType.dateOnly) {
                <wp-date-box formControlName="{{ control.name }}" />
              }
              @case (MetaEntityPropertyType.dateTime) {
                <wp-date-box formControlName="{{ control.name }}" />
              }
              @case (MetaEntityPropertyType.string) {
                <wp-multiline-text-box
                  [placeholder]="
                    appService.getPropertyLocalizationName(metaEntity.name, control.name)
                  "
                  formControlName="{{ control.name }}"
                />
              }
              @case (MetaEntityPropertyType.integer) {
                <wp-number-box
                  [placeholder]="
                    appService.getPropertyLocalizationName(metaEntity.name, control.name)
                  "
                  type="integer"
                  formControlName="{{ control.name }}"
                />
              }
              @case (MetaEntityPropertyType.navigation) {
                <wp-select-box
                  [placeholder]="
                    appService.getPropertyLocalizationName(metaEntity.name, control.name)
                  "
                  [values]="(collectionValues$ | async)?.[control.name]"
                  formControlName="{{ control.name }}"
                />
              }
              @case (MetaEntityPropertyType.directory) {
                <wp-select-box
                  [placeholder]="
                    appService.getPropertyLocalizationName(metaEntity.name, control.name)
                  "
                  [directory]="{ entityName: metaEntity.name, propertyName: control.name }"
                  formControlName="{{ control.name }}"
                  [isIdMode]="true"
                />
              }
            }
          </div>
        </div>
      }

      @if (requestComment) {
        <div class="form-group">
          <label class="control-label">
            {{ 'settings.lifecycles.card.transitionForm.comment' | translate }}
          </label>

          <wp-multiline-text-box
            formControlName="comment"
            placeholder="{{ 'settings.lifecycles.card.transitionForm.comment' | translate }}"
          />
        </div>
      }
    </div>

    <div class="modal-footer">
      <button type="button" class="btn btn-primary" (click)="ok()">
        {{ 'shared.actions.execute' | translate }}
      </button>
      <button type="button" class="btn btn-default" (click)="cancel()">
        {{ 'shared.actions.cancel' | translate }}
      </button>
    </div>
  </form>
}
