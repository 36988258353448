import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  inject,
  OnInit,
} from '@angular/core';
import { filter } from 'rxjs/operators';
import { FreezeTableService } from 'src/app/shared/directives/freeze-table/freeze-table.service';
import { ScheduleNavigationService } from 'src/app/shared-features/schedule-navigation/core/schedule-navigation.service';
import { ScheduleNavigationContext } from 'src/app/shared-features/schedule-navigation/models/schedule-navigation-context.enum';
import { ProjectSummaryDataService } from 'src/app/project-summary/core/project-summary-data.service';
import { ProjectSummaryService } from 'src/app/project-summary/core/project-summary.service';
import { ProjectSummaryViewSettingsService } from 'src/app/project-summary/shared/project-summary-view-settings/core/project-summary-view-settings.service';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

/** Represents Project Summary tab content. */
@Component({
  selector: 'tmt-project-summary',
  templateUrl: './project-summary.component.html',
  styleUrls: ['./project-summary.component.scss'],
  providers: [
    ScheduleNavigationService,
    FreezeTableService,
    ProjectSummaryService,
    ProjectSummaryDataService,
    ProjectSummaryViewSettingsService,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: false,
})
export class ProjectSummaryComponent implements OnInit {
  /** Gets Left Table width numeric value. */
  public get leftTableWidth(): number {
    return this._leftTableWidth;
  }

  /** Gets NO DATA message localization key. */
  public get noDataMessageKey(): string {
    return this._noDataMessageKey;
  }

  private readonly _leftTableWidth = 400;

  private _noDataMessageKey: string;

  private destroyRef = inject(DestroyRef);

  constructor(
    public service: ProjectSummaryService,
    public summaryDataService: ProjectSummaryDataService,
    private summaryViewSettingsService: ProjectSummaryViewSettingsService,
    private scheduleNavigationService: ScheduleNavigationService,
    private cdRef: ChangeDetectorRef,
  ) {}

  ngOnInit(): void {
    this.scheduleNavigationService.init(
      ScheduleNavigationContext.ProjectSummary,
    );
    this.updateFields();
    this.service.init();
    this.initSubscriptions();
  }

  /** Inits subscriptions. */
  private initSubscriptions(): void {
    this.service.detectChanges$
      .pipe(
        filter((id) => !id),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.updateFields();
        this.cdRef.detectChanges();
      });
  }

  /** Updates template fields. */
  private updateFields(): void {
    const isTypeRequired = !Object.keys(
      this.summaryViewSettingsService.settings.show,
    ).some((key) => this.summaryViewSettingsService.settings.show[key]);
    this._noDataMessageKey = isTypeRequired
      ? 'projects.projectSummary.noType'
      : 'shared.noDisplayData';
  }
}
