import {
  Component,
  Input,
  OnInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  inject,
  DestroyRef,
  effect,
  Output,
  EventEmitter,
} from '@angular/core';
import { FormControl } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

import { NgbNav, NgbNavChangeEvent } from '@ng-bootstrap/ng-bootstrap';
import { filter, merge } from 'rxjs';

import { CommentsService } from 'src/app/shared-features/comments/core/comments.service';
import { CommentsSaveService } from 'src/app/shared-features/comments/core/comments-save.service';
import { CommentsDataService } from 'src/app/shared-features/comments/core/comments-data.service';
import { ActionPanelService } from 'src/app/core/action-panel.service';
import { LifecycleService } from 'src/app/core/lifecycle.service';

@Component({
  selector: 'wp-comments-tab',
  templateUrl: './comments-tab.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [CommentsService, CommentsSaveService, CommentsDataService],
  standalone: false,
})
export class CommentsTabComponent implements OnInit {
  @Input() public entityId: string;
  @Input() public collection: string;

  @Output() public queueLength$ = new EventEmitter<number>();

  public checkboxControl = new FormControl<boolean>(
    this.commentService.showSystemEvents,
  );

  private readonly destroyRef = inject(DestroyRef);
  private readonly lifecycleService = inject(LifecycleService);

  constructor(
    public readonly commentService: CommentsService,
    private readonly commentSaveService: CommentsSaveService,
    private readonly ngbNavService: NgbNav,
    private readonly cdr: ChangeDetectorRef,
    private readonly actionPanelService: ActionPanelService,
  ) {
    effect(() => {
      this.queueLength$.emit(this.commentSaveService.changingQueue().length);
    });
  }

  public ngOnInit(): void {
    merge(this.lifecycleService.reload$, this.actionPanelService.reload$)
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(() => {
        this.commentService.reload();
      });

    this.commentService.init(this.collection, this.entityId);

    this.commentService.loading$
      .pipe(
        filter((v) => !v),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(() => {
        this.cdr.detectChanges();
      });

    this.ngbNavService.navChange
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((event: NgbNavChangeEvent) => {
        if (
          this.commentSaveService.changingQueue().length &&
          !this.commentSaveService.getConfirmResult()
        ) {
          event.preventDefault();
        }
      });

    this.checkboxControl.valueChanges
      .pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe((v) => {
        this.commentService.setShowSystemEvents(v);
      });
  }
}
