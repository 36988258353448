import { Component, OnInit, Input } from '@angular/core';
import { UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ImportStates } from 'src/app/shared/models/entities/settings/import.model';
import { GridColumn } from 'src/app/shared-features/grid/models/grid-column.interface';
import { GridComponentCell } from 'src/app/shared-features/grid/models/grid-component-cell.interface';

@Component({
  selector: 'wp-import-state-cell',
  templateUrl: './import-state-cell.component.html',
  standalone: false,
})
export class ImportStateCellComponent implements OnInit, GridComponentCell {
  @Input() column: GridColumn;
  @Input() formGroup: UntypedFormGroup;
  @Input() public initialValue: unknown;

  get control(): UntypedFormControl {
    return this.formGroup.controls[this.column.name] as UntypedFormControl;
  }

  public labelClass: string;

  ngOnInit() {
    this.labelClass = ImportStates.find(
      (as) => as.code === this.control.value.code,
    ).class;
  }
}
