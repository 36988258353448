import { DestroyRef, inject, Inject, Injectable } from '@angular/core';
import { BehaviorSubject, forkJoin, Observable, of, Subject } from 'rxjs';
import { CardState } from 'src/app/shared/models/inner/card-state.enum';
import {
  IndirectUpdatedEntities,
  TimeAllocation,
  Timesheet,
  TimesheetLine,
} from 'src/app/shared/models/entities/base/timesheet.model';
import { BlockUIService } from 'src/app/core/block-ui.service';
import { DataService } from 'src/app/core/data.service';
import { Exception } from 'src/app/shared/models/exception';
import { NotificationService } from 'src/app/core/notification.service';
import { NamedEntity } from 'src/app/shared/models/entities/named-entity.model';
import {
  catchError,
  debounceTime,
  filter,
  first,
  map,
  pairwise,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/core/app.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TimeOffCreationComponent } from 'src/app/time-off-requests/creation/time-off-creation.component';
import { CustomFieldService } from 'src/app/shared/components/features/custom-fields/custom-field.service';
import { StopwatchService } from 'src/app/core/stopwatch.service';
import { Task } from 'src/app/timesheets/card/shared/models/task.model';
import { StateService, UIRouterGlobals } from '@uirouter/angular';
import {
  EntityFilter,
  NavigationService,
} from 'src/app/core/navigation.service';
import { HeaderIndicator } from 'src/app/shared/components/chrome/form-header2/header-indicator.model';
import { LifecycleService } from 'src/app/core/lifecycle.service';
import { RouteMode } from 'src/app/shared/models/inner/route-mode.enum';
import { SavingQueueService } from 'src/app/shared/services/saving-queue.service';
import {
  MetaEntityBaseProperty,
  MetaEntityDirectoryProperty,
  MetaEntityPropertyType,
} from 'src/app/shared/models/entities/settings/metamodel.model';
import {
  FormArray,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import { TimeSheetCacheService } from 'src/app/timesheets/card/core/timesheet-cache.service';
import { User } from 'src/app/shared/models/entities/settings/user.model';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { InfoPopupService } from 'src/app/shared/components/features/info-popup/info-popup.service';
import { Issue } from 'src/app/issues/models/issue.model';
import _ from 'lodash';
import { ProjectBillingType } from 'src/app/shared/models/enums/project-billing-type';
import { Guid } from 'src/app/shared/helpers/guid';
import { DateTime } from 'luxon';
import { Constants } from 'src/app/shared/globals/constants';

@Injectable()
export class TimesheetCardService {
  private timesheetSubject = new BehaviorSubject<Timesheet>(null);
  public timesheet$ = this.timesheetSubject
    .asObservable()
    .pipe(filter((p) => !!p));
  private linesUpdatedSubject = new Subject<void>();
  public linesUpdated$ = this.linesUpdatedSubject.pipe();
  private indicatorsSubject = new BehaviorSubject<HeaderIndicator[]>([]);
  public indicators$ = this.indicatorsSubject.asObservable();

  public name$ = new Subject<string>();
  public selectAllControl = new UntypedFormControl(false);
  public selectControls = new UntypedFormArray([]);
  public issueLines = new FormArray([]);
  public dataLines = this.fb.array([]);
  public state$ = new BehaviorSubject<CardState>(CardState.Loading);
  public activities$ = this.getAvailableActivities().pipe(shareReplay());
  public collection = this.data.collection('TimeSheets');
  public timeSheetLineCollection = this.data.collection('TimeSheetLines');
  public timeEntryCollection = this.data.collection('TimeAllocations');
  public allocationCustomFields: MetaEntityBaseProperty[];
  public lineCustomFields: MetaEntityBaseProperty[];
  public timesheetLinesStorageName = 'timesheetLines';
  public readonly lineAnalytics: Array<keyof TimesheetLine> = [
    'project',
    'projectTask',
    'activity',
    'role',
    'projectCostCenter',
    'projectTariff',
  ];

  private destroyRef = inject(DestroyRef);

  public get timesheet(): Timesheet {
    return this.timesheetSubject.getValue();
  }

  constructor(
    public autosave: SavingQueueService,
    @Inject('entityId') private entityId: string,
    private app: AppService,
    private translate: TranslateService,
    private data: DataService,
    private notification: NotificationService,
    private blockUI: BlockUIService,
    private modalService: NgbModal,
    private customFieldService: CustomFieldService,
    private state: StateService,
    private navigation: NavigationService,
    private lifecycleService: LifecycleService,
    private uiRouterGlobals: UIRouterGlobals,
    private timeSheetCacheService: TimeSheetCacheService,
    private infoPopupService: InfoPopupService,
    private fb: UntypedFormBuilder,
    stopwatchService: StopwatchService,
  ) {
    stopwatchService.externalUpdate$
      .pipe(takeUntilDestroyed())
      .subscribe(() => {
        this.load();
      });

    stopwatchService.stop$.pipe(takeUntilDestroyed()).subscribe(() => {
      this.load(true);
    });

    this.load();

    this.autosave.error$
      .pipe(takeUntilDestroyed())
      .subscribe(() => this.load());
  }

  /** Загрузка таймшита. */
  public load(silent = false): Promise<void> {
    return new Promise((resolve) => {
      this.infoPopupService.close();
      this.autosave.save().then(() => {
        if (!silent) {
          this.state$.next(CardState.Loading);
        } else {
          this.blockUI.start();
        }

        const query = {
          expand: {
            template: {
              select: [
                'showActivity',
                'showRole',
                'showClient',
                'showProjectCostCenter',
                'showTariff',
              ],
              expand: { customFields: { select: ['customFieldId'] } },
            },
            user: { select: ['id', 'name'] },
            legalEntity: { select: ['id', 'name'] },
            state: { select: ['id', 'name', 'code'] },
            timeSheetLines: {
              select: ['id', 'date', 'orderNumber', 'rowVersion'],
              expand: {
                project: {
                  select: ['id', 'name'],
                  expand: {
                    billingType: { select: ['code'] },
                    organization: { select: ['id', 'name'] },
                  },
                },
                projectTask: { select: ['id', 'name', 'leadTaskId'] },
                projectCostCenter: { select: ['id', 'name'] },
                projectTariff: { select: ['id', 'name'] },
                activity: { select: ['id', 'name'] },
                role: { select: ['id', 'name'] },
                timeAllocations: {
                  orderBy: 'date',
                  select: [
                    'id',
                    'hours',
                    'description',
                    'date',
                    'rowVersion',
                    'isBillable',
                  ],
                },
              },
              orderBy: 'orderNumber',
            },
            timeOffRequests: {
              select: ['id'],
              expand: {
                state: { select: ['id', 'name', 'code', 'style'] },
                timeOffType: { select: ['id', 'name'] },
                timeAllocations: { orderBy: 'date' },
              },
            },
            issues: {
              select: ['id', 'code', 'name'],
              expand: {
                state: { select: ['id', 'name', 'code', 'style'] },
                type: { select: ['id', 'name'] },
                timeAllocations: {
                  select: [
                    'id',
                    'hours',
                    'description',
                    'date',
                    'rowVersion',
                    'roleId',
                    'activityId',
                    'projectId',
                    'projectTaskId',
                    'projectCostCenterId',
                    'projectTariffId',
                    'isBillable',
                    'issueId',
                  ],
                  expand: {
                    role: { select: ['id', 'name'] },
                    activity: { select: ['id', 'name'] },
                    project: {
                      select: ['id', 'name'],
                      expand: {
                        billingType: { select: ['code'] },
                        organization: { select: ['id', 'name'] },
                      },
                    },
                    projectTask: { select: ['id', 'name', 'leadTaskId'] },
                    projectCostCenter: { select: ['id', 'name'] },
                    projectTariff: { select: ['id', 'name'] },
                  },
                },
              },
            },
          },
        };

        this.customFieldService.enrichQuery(
          query.expand.timeSheetLines.expand.timeAllocations,
          'TimeAllocation',
        );
        this.customFieldService.enrichQuery(
          query.expand.issues.expand.timeAllocations,
          'TimeAllocation',
        );
        this.customFieldService.enrichQuery(
          query.expand.timeSheetLines,
          'TimeSheetLine',
        );

        const observable = this.collection
          .entity(this.entityId)
          .get<Timesheet>(query);

        observable.subscribe({
          next: (timesheet: Timesheet) => {
            this.timesheetSubject.next(timesheet);
            this.lifecycleService.entityId = timesheet.id;

            this.fillCustomFieldsOut();

            const name =
              this.uiRouterGlobals.current.name === 'currentTimesheet'
                ? this.translate.instant('timesheets.current')
                : timesheet.name;
            this.name$.next(name);

            if (this.uiRouterGlobals.current.name !== 'currentTimesheet') {
              this.navigation.addRouteSegment({
                id: timesheet.id,
                title: timesheet.name,
              });
            }

            this.blockUI.stop();
            this.state$.next(CardState.Ready);
            resolve();
          },
          error: (error: Exception) => {
            this.state$.next(CardState.Error);
            this.blockUI.stop();
            if (error.code !== Exception.BtEntityNotFoundException.code) {
              this.notification.error(error.message);
            }
          },
        });
      });
    });
  }

  /** Opens `TimeOffRequest` creation modal. */
  public createTimeOffRequest(): void {
    this.modalService.open(TimeOffCreationComponent, {
      size: 'lg',
    });
  }

  public copyLines(withHours?: boolean) {
    this.autosave.save().then(() => {
      this.blockUI.start();

      this.collection
        .entity(this.timesheet.id)
        .action('WP.CopyLinesFromPrevious')
        .execute({ copyHours: withHours })
        .subscribe({
          next: (response: number) => {
            if (response === 0) {
              this.notification.warningLocal(
                'timesheets.card.messages.linesNotAdded',
              );
            } else {
              this.notification.successLocal(
                'timesheets.card.messages.linesAdded',
                { count: response },
              );
              this.load();
            }
            this.blockUI.stop();
          },
          error: (error: Exception) => {
            this.blockUI.stop();
            this.notification.error(error.message);
          },
        });
    });
  }

  public createLinesFromResourcePlan(withHours?: boolean) {
    this.autosave.save().then(() => {
      this.blockUI.start();

      this.collection
        .entity(this.timesheet.id)
        .action('WP.CreateLinesFromResourcePlan')
        .execute({ copyHours: withHours })
        .subscribe({
          next: (response: number) => {
            if (response === 0) {
              this.notification.warningLocal(
                'timesheets.card.messages.linesNotAdded',
              );
            } else {
              this.notification.successLocal(
                'timesheets.card.messages.linesAdded',
                { count: response },
              );
              this.load();
            }
            this.blockUI.stop();
          },
          error: (error: Exception) => {
            this.blockUI.stop();
            this.notification.error(error.message);
          },
        });
    });
  }

  /**
   * Navigates to Timesheet Accounting entries.
   * */
  public goToAccountingEntry(): void {
    this.state.go(`accountingEntries`, {
      routeMode: RouteMode.continue,
      filter: JSON.stringify(<EntityFilter>{
        name: this.timesheet.name,
        filter: [{ documentId: { type: 'guid', value: this.timesheet.id } }],
      }),
    });
  }

  /** Emits `linesUpdatedSubject`. Use it if you need to update the UI that depends on the lines data. */
  public emitLinesUpdated(): void {
    this.linesUpdatedSubject.next();
  }

  /** Проверяет возможность добавления строки ТШ с указанной задачей. */
  public checkIfTaskCanBeUsed(task: Task): Observable<boolean> {
    return this.timeSheetCacheService
      .getProjectTasks(
        this.timesheet.user.id,
        this.timesheet.templateId,
        task.project.id,
      )
      .pipe(map((result) => result.some((v) => v.id === task.projectTask.id)));
  }

  /**
   * Fills line with properties if they can be added.
   *
   * @param newLine updated line.
   * @param lineData old line.
   * @returns `false` if error occurred, otherwise `true`.
   *
   */
  public checkAndFillTimeSheetLine(
    newLine: Partial<TimesheetLine>,
    lineData: Partial<TimesheetLine>,
  ): Observable<boolean> {
    return forkJoin([
      lineData.role
        ? this.timeSheetCacheService
            .getRoles(this.timesheet.user.id, lineData.task.project.id)
            .pipe(first())
        : of(null),
      lineData.activity ? this.activities$.pipe(first()) : of(null),
      lineData.projectCostCenter
        ? this.timeSheetCacheService
            .getProjectCostCenters(
              this.timesheet.user.id,
              lineData.task.project.id,
            )
            .pipe(first())
        : of(null),
      lineData.projectTariff
        ? this.timeSheetCacheService
            .getProjectTariffs(this.timesheet.user.id, lineData.task.project.id)
            .pipe(first())
        : of(null),
    ]).pipe(
      tap(([roles, activities, projectCostCenters, projectTariffs]) => {
        if (roles?.length && roles.find((a) => a.id === lineData.role.id)) {
          newLine.role = lineData.role;
        }

        if (
          activities?.length &&
          activities.find((a) => a.id === lineData.activity.id)
        ) {
          newLine.activity = lineData.activity;
        }

        if (
          projectCostCenters?.length &&
          projectCostCenters.find((a) => a.id === lineData.projectCostCenter.id)
        ) {
          newLine.projectCostCenter = lineData.projectCostCenter;
        }

        if (
          projectTariffs?.length &&
          projectTariffs.find((a) => a.id === lineData.projectTariff.id)
        ) {
          newLine.projectTariff = lineData.projectTariff;
        }
      }),
      map(() => true),
      catchError((error: Exception) => {
        this.notification.errorLocal(error.message);
        return of(false);
      }),
    );
  }

  /** Update indicators. */
  public updateIndicators(headerIndicators: HeaderIndicator[]) {
    this.indicatorsSubject.next(headerIndicators);
  }

  /**
   * Prepares line to save.
   *
   * @param line form group values of line.
   * @param index actual index of line.
   * @returns Timesheet line.
   */
  public getTimesheetLineToSave(
    line: any,
    index: number,
  ): Partial<TimesheetLine> {
    const timeSheetLine: Partial<TimesheetLine> = {
      id: line.id,
      projectId: line.task.project?.id ?? null,
      projectTaskId: line.task.projectTask?.id ?? null,
      activityId: line.activity?.id ?? null,
      roleId: line.role?.id ?? null,
      projectCostCenterId: line.projectCostCenter?.id ?? null,
      projectTariffId: line.projectTariff?.id ?? null,
      orderNumber: index,
      rowVersion: line.rowVersion,
    };

    this.customFieldService.assignValues(timeSheetLine, line, 'TimeSheetLine');

    return timeSheetLine;
  }

  /**
   * Prepares time entry to save.
   *
   * @param allocation form group value of time entry.
   * @param timeSheetLineId line id of time entry.
   * @returns Time allocation.
   */
  public getAllocationToSave(
    allocation: any,
    timeSheetLineId: string,
  ): Partial<TimeAllocation> {
    const newAllocation: Partial<TimeAllocation> = {
      id: allocation.id,
      timeSheetLineId,
      timeSheetId: this.timesheet.id,
      userId: this.app.session.user.id,
      rowVersion: allocation.rowVersion,
      description: allocation.description,
      date: allocation.date,
      hours: allocation.hours,
    };

    this.customFieldService.assignValues(
      newAllocation,
      allocation,
      'TimeAllocation',
    );

    return newAllocation;
  }

  /**
   * Checks is allocation has properties to save.
   *
   * @param allocation time entry.
   * @returns true if properties are found, otherwise false.
   */
  public isAllocationFilledOut(allocation: TimeAllocation): boolean {
    return (
      allocation.description?.length > 0 ||
      allocation.hours > 0 ||
      this.allocationCustomFields.some((field) => {
        const key =
          field.type === MetaEntityPropertyType.directory
            ? (field as MetaEntityDirectoryProperty).keyProperty
            : field.name;
        return allocation[key] !== null && allocation[key] !== undefined;
      })
    );
  }

  /**
   * Add line.
   *
   * @param emitEvent Indicates whether to emit event in the array control.
   * @returns the line form group.
   */
  public addLine(emitEvent = true): UntypedFormGroup {
    this.selectControls.push(new UntypedFormControl(false));

    const group = this.buildGroup();

    this.customFieldService.enrichFormGroup(group, 'TimeSheetLine');
    this.subscribeDataLineGroup(group);
    this.fillOutAllocationsGroup(group);

    this.dataLines.push(group, { emitEvent });

    return group;
  }

  /**
   * Updates the allocation.
   *
   * @param lineId line id of the allocation.
   * @param allocationId allocation id.
   * @param updatedAllocation new allocation's data.
   */
  public patchAllocation(
    lineId: string,
    allocationId: string,
    updatedAllocation: Partial<TimeAllocation>,
  ): void {
    const lineForm = this.dataLines.controls.find(
      (line) => line.getRawValue().id === lineId,
    ) as UntypedFormGroup;

    if (lineForm) {
      const allocationForm = (
        lineForm.controls.allocations as UntypedFormArray
      ).controls.find(
        (allocation) => allocation.getRawValue().id === allocationId,
      );

      allocationForm?.patchValue({
        ...allocationForm.getRawValue(),
        ...updatedAllocation,
      });
    }
  }

  /**
   * Updates the issue time allocation in the timesheet, then re-renders work log UI.
   * If `projectId`, `projectTaskId` or `date` are updated, then reloads the `timesheet`.
   *
   * @param updatedAllocation updated time entry.
   * @param isRemoved Indicates whether the time entry was deleted.
   */
  public async updateIssueAllocation(
    updatedAllocation: Partial<TimeAllocation> | null,
    isRemoved: boolean,
  ): Promise<void> {
    const issue = this.timesheet.issues.find(
      (issue) => issue.id === updatedAllocation.issueId,
    );
    const issueAllocationIndex = issue?.timeAllocations.findIndex(
      (allocation) => allocation.id === updatedAllocation.id,
    );

    if (issueAllocationIndex === -1) {
      return;
    }

    if (isRemoved) {
      issue.timeAllocations.splice(issueAllocationIndex, 1);
    } else {
      const requestTriggerFields: Array<keyof TimeAllocation> = [
        'projectId',
        'projectTaskId',
        'date',
      ];
      const issueAllocation = issue.timeAllocations[issueAllocationIndex];

      for (const field of requestTriggerFields) {
        if (issueAllocation[field] !== updatedAllocation[field]) {
          this.load(true);
          return;
        }
      }

      Object.assign(issueAllocation, updatedAllocation);
    }

    this.fillOutIssueDataLines();
    this.emitLinesUpdated();
  }

  /**
   * Updates timesheet lines & time allocations.
   *
   * @param updates new data.
   */
  public resolveIndirectUpdates(updates: IndirectUpdatedEntities): void {
    if (!updates?.TimeAllocation?.length && !updates?.TimeSheetLine?.length) {
      return;
    }

    if (updates.TimeSheetLine?.length) {
      updates.TimeSheetLine.forEach((line: Partial<TimesheetLine>) => {
        const group = this.dataLines.controls.find(
          (group) => group.value.id === line.id,
        ) as UntypedFormGroup;

        group?.patchValue(line, { emitEvent: false });
      });
    }

    if (updates.TimeAllocation?.length) {
      this.dataLines.controls.forEach((lineGroup: UntypedFormGroup) => {
        const allocationFormArray = lineGroup.controls
          .allocations as UntypedFormArray;

        updates.TimeAllocation.forEach(
          (timeAllocation: Partial<TimeAllocation>) => {
            const allocationControl = allocationFormArray.controls.find(
              (control: UntypedFormControl) =>
                control.getRawValue().id === timeAllocation.id,
            );

            allocationControl?.patchValue(
              {
                ...allocationControl.getRawValue(),
                ...timeAllocation,
              },
              {
                emitEvent: false,
              },
            );
          },
        );
      });
    }

    this.emitLinesUpdated();
  }

  /** Fills FormArray with timesheet data. */
  public fillOutDataLines() {
    this.autosave.disabled = true;

    this.dataLines.clear();

    // Заполнить строки данных.
    this.timesheet.timeSheetLines.forEach((line) => {
      const group = this.buildGroup({
        ...line,
        task: {
          client: line.project?.organization,
          project: line.project
            ? {
                id: line.project?.id,
                name: line.project?.name,
              }
            : null,
          projectTask: line.projectTask,
          isMainTask: line.projectTask && !line.projectTask.leadTaskId,
          billingTypeCode: line.project?.billingType.code,
        },
      });

      this.customFieldService.enrichFormGroup(group, 'TimeSheetLine');
      this.lineCustomFields.forEach((field) => {
        group.controls[field.name].setValue(line[field.name], {
          emitEvent: false,
        });
      });

      this.subscribeDataLineGroup(group);
      this.fillOutAllocationsGroup(group, line);
      this.calculateGroupTotal(group);
      this.dataLines.push(group);
    });

    this.selectControls.clear();
    this.dataLines.controls.forEach(() =>
      this.selectControls.push(new UntypedFormControl(false)),
    );

    this.emitLinesUpdated();
    this.autosave.disabled = false;
  }

  /** Fills issue FormArray with timesheet data. */
  public fillOutIssueDataLines(): void {
    this.issueLines.clear({ emitEvent: false });

    const timeAllocations = this.timesheet.issues.reduce((entries, entry) => {
      entry.timeAllocations.forEach((timeEntry) => {
        timeEntry.issue = {
          id: entry.id,
          name: entry.name,
        } as Issue;
      });
      return entries.concat(entry.timeAllocations);
    }, []);
    // Group allocation by pseudo timesheet line.
    const timeAllocationsByLine = _.groupBy<TimeAllocation>(
      timeAllocations,
      (item) =>
        this.lineAnalytics
          .map((key) => `${key}Id`)
          .reduce((result, value) => result + item[value] + ' ', ''),
    );

    Object.entries(timeAllocationsByLine).forEach(([lineKey, timeEntries]) => {
      const entriesByDate = _.groupBy<TimeAllocation>(timeEntries, 'date');
      const line: Partial<TimesheetLine> = {
        id: lineKey,
        timeSheetId: this.timesheet.id,
        timeAllocations: [],
        allTimeAllocations: timeEntries,
      };

      Object.entries(entriesByDate).forEach(([date, groupedTimeEntries]) => {
        line.timeAllocations.push({
          ...groupedTimeEntries[0],
          date,
          hours: _.sumBy(groupedTimeEntries, (v) => v.hours),
        });
      });

      this.lineAnalytics.forEach((key) => {
        _.set(line, key, timeEntries[0][key] ?? null);
        _.set(line, `${key}Id`, timeEntries[0][`${key}Id`]);
      });

      const group = this.buildGroup({
        ...line,
        task: {
          client: line.project?.organization,
          project: line.project
            ? {
                id: line.project?.id,
                name: line.project?.name,
              }
            : null,
          projectTask: line.projectTask,
          isMainTask: line.projectTask && !line.projectTask.leadTaskId,
          billingTypeCode: line.project?.billingType?.code,
        },
      });

      this.fillOutAllocationsGroup(group, line);
      this.calculateGroupTotal(group);
      group.disable({ emitEvent: false });
      this.issueLines.push(group, { emitEvent: false });
    });
  }

  private fillOutAllocationsGroup(
    group: UntypedFormGroup,
    line?: Partial<TimesheetLine>,
  ) {
    const allocations = group.controls.allocations as UntypedFormArray;

    // Заполняем дни.
    const dateTo = DateTime.fromISO(this.timesheet.dateTo);
    let currentDate = DateTime.fromISO(this.timesheet.dateFrom);
    while (currentDate <= dateTo) {
      let allocation: Partial<TimeAllocation>;

      if (line) {
        allocation = line.timeAllocations.find(
          (a) => a.date === currentDate.toISODate(),
        );
      }

      if (!allocation) {
        allocation = {
          id: Guid.generate(),
          date: currentDate.toISODate(),
          hours: null,
          description: '',
          rowVersion: 0,
        };
      }

      const control = this.fb.control(allocation);
      this.subscribeAllocation(control, group);
      allocations.push(control, { emitEvent: false });

      currentDate = currentDate.plus({ days: 1 });
    }
  }

  private buildGroup(value?: Partial<TimesheetLine> | any): UntypedFormGroup {
    const group = this.fb.group({
      id: Guid.generate(),
      task: {
        client: null,
        project: null,
        projectTask: null,
      } as Task,
      activity: null,
      role: null,
      projectCostCenter: null,
      projectTariff: null,
      allocations: this.fb.array([]),
      allTimeAllocations: this.fb.array(value?.allTimeAllocations ?? []),
      totalHours: 0,
      rowVersion: 0,
      orderNumber: 0,
    });

    if (value) {
      group.patchValue(value);

      if (
        value.project?.billingType?.code === ProjectBillingType.nonBillable.code
      ) {
        group.get('projectTariff').disable({ emitEvent: false });
      }
    }

    return group;
  }

  private subscribeAllocation(
    cellGroup: UntypedFormGroup | UntypedFormControl,
    line: UntypedFormGroup,
  ): void {
    cellGroup.valueChanges
      .pipe(
        debounceTime(Constants.textInputClientDebounce),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe((updateValue) => {
        const getObservable = () => {
          const value = cellGroup.getRawValue();

          const isFilled = this.isAllocationFilledOut(value);
          const data = this.getAllocationToSave(value, line.getRawValue().id);

          let observable: Observable<TimeAllocation | null> | null = null;

          if (isFilled) {
            observable = this.timeEntryCollection
              .entity(value.id)
              .update(data, {
                withResponse: true,
              })
              .pipe(
                tap((result) => {
                  cellGroup.setValue(
                    {
                      ...cellGroup.getRawValue(),
                      rowVersion: result.rowVersion,
                    },
                    { emitEvent: false },
                  );
                }),
              );
          }

          if (!value.rowVersion && isFilled) {
            observable = this.timeEntryCollection.insert(data).pipe(
              tap((result) => {
                cellGroup.setValue(
                  {
                    ...cellGroup.getRawValue(),
                    ...result,
                    id: result.id,
                    rowVersion: result.rowVersion,
                  },
                  { emitEvent: false },
                );
                this.emitLinesUpdated();
              }),
            );
          }

          if (value.rowVersion && !isFilled) {
            observable = this.timeEntryCollection
              .entity(value.id)
              .delete()
              .pipe(
                tap(() => {
                  cellGroup.setValue(
                    {
                      ...cellGroup.getRawValue(),
                      rowVersion: 0,
                    },
                    { emitEvent: false },
                  );
                }),
              );
          }

          return observable ?? of(observable);
        };

        this.autosave.addToQueue(updateValue.id, getObservable);
      });
  }

  /**
   * Calculates line amount.
   *
   * @param group line form group
   */
  private calculateGroupTotal(group: UntypedFormGroup): void {
    group.controls['totalHours'].setValue(
      _.sumBy(
        group.controls['allocations'].value,
        (allocation: TimeAllocation) => allocation.hours ?? 0,
      ),
      { emitEvent: false },
    );
  }

  /**
   * Saves timesheet if value changed, also recalculates totals.
   *
   * @param group `TimeSheetLine` form group.
   */
  private subscribeDataLineGroup(group: UntypedFormGroup): void {
    group.valueChanges
      .pipe(
        startWith(group.getRawValue()),
        pairwise(),
        debounceTime(0),
        takeUntilDestroyed(this.destroyRef),
      )
      .subscribe(([previous, current]) => {
        const notNewLine = !!current.rowVersion;
        const notSavingProperties: string[] = [
          'allocations',
          'totalHours',
          'allTimeAllocations',
          'rowVersion',
        ];
        notSavingProperties.forEach((key) => {
          delete previous[key];
          delete current[key];
        });

        if (!_.isEqual(previous, current) && notNewLine) {
          const index = this.dataLines.controls.findIndex(
            (control) => control.getRawValue().id === current.id,
          );
          const isProjectSelected =
            !previous.task?.project && current.task?.project;
          const isProjectChanged =
            previous.task?.project &&
            current.task?.project &&
            previous.task.project.id !== current.task.project.id;

          if (isProjectChanged) {
            group.patchValue(
              {
                role: null,
                projectTariff: null,
                projectCostCenter: null,
              },
              { emitEvent: false },
            );
          }

          this.autosave.addToQueue(current.id, () =>
            this.timeSheetLineCollection
              .entity(current.id)
              .update(this.getTimesheetLineToSave(group.getRawValue(), index), {
                withResponse: true,
              })
              .pipe(
                tap((result: TimesheetLine) => {
                  if (isProjectSelected || isProjectChanged) {
                    this.load(true);
                    return;
                  }

                  group.patchValue(result, { emitEvent: false });
                  this.resolveIndirectUpdates(result.updated);
                }),
              ),
          );
        }

        this.calculateGroupTotal(group);
      });
  }

  private getAvailableActivities(): Observable<ReadonlyArray<NamedEntity>> {
    return this.timesheet$.pipe(
      filter((v) => !!v),
      switchMap(() =>
        this.data
          .collection('Users')
          .entity(this.timesheet.user.id)
          .get<User>({
            select: ['restrictActivities'],
            expand: {
              activities: {
                orderBy: 'activity/name',
                expand: {
                  activity: {
                    select: ['name', 'id'],
                    filter: { isActive: true },
                  },
                },
              },
            },
          })
          .pipe(
            switchMap((user) => {
              if (user.restrictActivities) {
                return of(user.activities.map((ua) => ua.activity));
              } else {
                return this.data.collection('Activities').query<NamedEntity[]>({
                  select: ['name', 'id'],
                  orderBy: 'name',
                  filter: { isActive: true },
                });
              }
            }),
          ),
      ),
    );
  }

  /** Calculates available custom fields based on the timesheet template configuration. */
  private fillCustomFieldsOut(): void {
    this.lineCustomFields = [];
    this.allocationCustomFields = [];
    const allocationFields = this.app.getCustomFields('TimeAllocation');
    const lineCustomFields = this.app.getCustomFields('TimeSheetLine');

    this.timesheet.template.customFields.forEach((templateField) => {
      const allocationField = allocationFields.find(
        (f) =>
          f.customFieldId === templateField.customFieldId &&
          f.viewConfiguration.isShownInEntityForms,
      );
      const lineCustomField = lineCustomFields.find(
        (f) =>
          f.customFieldId === templateField.customFieldId &&
          f.viewConfiguration.isShownInEntityForms,
      );

      if (allocationField) this.allocationCustomFields.push(allocationField);
      if (lineCustomField) this.lineCustomFields.push(lineCustomField);
    });
  }
}
