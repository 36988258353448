import { Pipe, PipeTransform } from '@angular/core';
import { hoursInDay, ValueMode } from '../models/value-mode.enum';
import { DecimalPipe } from '@angular/common';

/** Формат отображаемого времени в ресурсном плане и распределении. */
@Pipe({
  name: 'plannerFormat',
  standalone: false,
})
export class PlannerFormatPipe implements PipeTransform {
  constructor(private decimalPipe: DecimalPipe) {}

  transform(
    hours: number,
    mode?: ValueMode,
    scheduleHours?: number,
    fteHours?: number,
  ): string {
    let decimal = true;
    let displayValue;

    if (hours == null || hours === 0) {
      displayValue = null;
    } else {
      switch (mode) {
        case ValueMode.Percentage:
          hours = (hours / scheduleHours) * 100;
          decimal = false;
          break;
        case ValueMode.FTE:
          hours = hours / fteHours;
          decimal = true;
          break;
        case ValueMode.Days:
          hours = hours / hoursInDay;
          decimal = true;
          break;
      }
      const template = decimal === false ? '1.0-0' : '1.0-2';
      displayValue = this.decimalPipe.transform(hours, template);
    }
    return displayValue;
  }
}
