import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormGroup, UntypedFormBuilder } from '@angular/forms';
import { sortBy } from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { AppService } from 'src/app/core/app.service';
import { DataService } from 'src/app/core/data.service';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';
import {
  LicenseInfo,
  LicenseProductInfo,
} from 'src/app/shared/models/entities/settings/license-info.model';
import { Feature } from 'src/app/shared/models/enums/feature.enum';

@Component({
  selector: 'wp-user-filter',
  templateUrl: './user-filter.component.html',
  standalone: false,
})
export class UserFilterComponent implements OnInit, OnDestroy, FilterDetails {
  @Input() values: any;

  public products: LicenseProductInfo[] = [];
  private destroyed$ = new Subject<void>();

  detailsForm = this.fb.group({
    supervisor: [null],
    department: [null],
    resourcePool: [null],
    level: [null],
    location: [null],
    products: this.fb.group({}),
  });

  // eslint-disable-next-line @typescript-eslint/naming-convention
  public Feature = Feature;

  constructor(
    private fb: UntypedFormBuilder,
    public service: FilterService,
    public data: DataService,
    public app: AppService,
  ) {}

  ngOnInit() {
    this.service.resetValues$.subscribe(() => {
      this.detailsForm.reset();
    });

    this.detailsForm.patchValue(this.service.values);

    this.detailsForm.valueChanges
      .pipe(takeUntil(this.destroyed$))
      .subscribe(() => {
        const values = Object.assign(
          this.service.values,
          this.detailsForm.value,
        );
        this.service.changeValues(values);
      });

    this.data.model
      .function('GetLicenseInfo')
      .get<LicenseInfo>()
      .subscribe((licenseInfo) => {
        licenseInfo.products.forEach((product) =>
          (this.detailsForm.controls.products as FormGroup).addControl(
            product.product.toString(),
            this.fb.control(
              this.service.values.products
                ? this.service.values.products[product.product]
                : false,
            ),
            { emitEvent: false },
          ),
        );

        this.products = sortBy(
          licenseInfo.products.filter((x) => x.limit > 0 || x.used > 0),
          (p) => p.displayName,
        );
      });
  }

  ngOnDestroy(): void {
    this.destroyed$.next();
  }
}
