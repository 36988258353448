<div class="toolbar-container">
  <div class="btn-group" role="group">
    <button class="btn btn-default" (click)="form.disable()">Disable</button>
    <button class="btn btn-default" (click)="form.enable()">Enable</button>
  </div>

  <button class="btn btn-default" (click)="setValue()">Set value</button>

  <form [formGroup]="formInputParams">
    <div class="form-check">
      <input formControlName="readonly" type="checkbox" class="form-check-input" id="readonly" />
      <label class="form-check-label" for="readonly"> Readonly </label>
    </div>
  </form>
</div>

<hr />

<div [formGroup]="form" style="width: 400px">
  <h2>Checkbox (Base mode)</h2>
  <tmt-checkbox
    style="display: block"
    class="my-3"
    formControlName="active"
    label="Custom checkbox"
    [readonly]="formInputParams.controls['readonly'].value"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('active').value | json }}
    </code>
  </p>

  <hr />

  <h2>Checkbox (Reversed mode)</h2>
  <tmt-checkbox
    style="display: block"
    class="my-3"
    formControlName="activeReversed"
    label="Custom checkbox"
    [reversed]="true"
    [readonly]="formInputParams.controls['readonly'].value"
  />
  <p>
    Form value:<br />
    <code>
      {{ form.get('activeReversed').value | json }}
    </code>
  </p>
</div>
