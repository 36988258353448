import { toggleMark } from 'prosemirror-commands';
import { Command, EditorState } from 'prosemirror-state';
import { NodeItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/consts';
import { MenuItem } from 'src/app/shared/components/controls/rich-editor-box/rich-editor-box-menu/models/menu';

/**
 * Toggles mark by id.
 *
 * @param id mark's id.
 * @returns editor view state command.
 */
export function markItem(id: NodeItem): Command {
  return (state, dispatch) =>
    toggleMark(state.schema.marks[id])(state, dispatch);
}

/**
 * Toggles mark menu item isActive.
 *
 * @param item clicked item.
 * @param state editor view state.
 */
export function toggleMarkActive(item: MenuItem, state: EditorState): void {
  const { from, $from, to, empty } = state.selection;
  if (empty) {
    item.isActive = !!state.schema.marks[item.id]?.isInSet(
      state.storedMarks || $from.marks(),
    );
  } else {
    item.isActive = state.doc.rangeHasMark(
      from,
      to,
      state.schema.marks[item.id],
    );
  }

  if (item.id === 'code') {
    item.subItems.find((item) => item.id === 'inlineCode').isActive =
      item.isActive;
  }
}
