import { Component, OnInit, Input } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Report } from 'src/app/shared/models/entities/analytics/report.model';
import { ViewSettings } from 'src/app/analytics/shared/models/view-settings/view-settings.model';
import { DataService } from 'src/app/core/data.service';
import { NotificationService } from 'src/app/core/notification.service';
import { Exception } from 'src/app/shared/models/exception';
import { Filter } from 'src/app/analytics/shared/models/filter/filter-model';
import { Constants } from 'src/app/shared/globals/constants';
import { cloneDeep } from 'lodash';

@Component({
  selector: 'wp-report-settings',
  templateUrl: './report-settings.component.html',
  styleUrls: ['./report-settings.component.scss'],
  standalone: false,
})
export class ReportSettingsComponent implements OnInit {
  @Input('report') set inputReport(report: Report) {
    this.report = cloneDeep(report);
  }
  report: Report;

  public viewSettings: ViewSettings;
  public filters: Filter[];

  public form = this.fb.group({
    name: new UntypedFormControl('', [
      Validators.required,
      Validators.maxLength(Constants.formNameMaxLength),
    ]),
    description: new UntypedFormControl(
      '',
      Validators.maxLength(Constants.formTextMaxLength),
    ),
    isActive: new UntypedFormControl(false),
  });

  public isSaving: boolean;
  public hasTabs = true;

  constructor(
    private fb: UntypedFormBuilder,
    private data: DataService,
    private notification: NotificationService,
    private activeModal: NgbActiveModal,
  ) {}

  public onChanges(data: [ViewSettings, any]) {
    this.viewSettings = data[0];
  }

  public onFiltersChanged(filters: Filter[]) {
    this.filters = filters;
  }

  public onReportTypeChanged(reportType) {
    this.report.reportType = reportType;
    this.filters = [];
  }

  public ok() {
    this.form.markAllAsTouched();
    if (this.form.invalid) {
      this.notification.warningLocal('shared.messages.requiredFieldsError');
      return;
    }

    this.report.viewSettings = JSON.stringify(this.viewSettings);
    this.report.filters = this.filters ? JSON.stringify(this.filters) : null;
    this.report.name = this.form.value.name;
    this.report.description = this.form.value.description;
    this.report.isActive = this.form.value.isActive;

    const report = this.report as any;
    report.reportTypeId = report.reportType.id;

    this.isSaving = true;
    this.data
      .collection('Reports')
      .entity(this.report.id)
      .update(this.report)
      .subscribe({
        next: () => {
          this.notification.successLocal(
            'analytics.reports.reportSettings.messages.reportSaved',
          );
          this.isSaving = false;
          this.activeModal.close();
        },
        error: (error: Exception) => {
          this.notification.error(error.message);
          this.isSaving = false;
        },
      });
  }

  /** Закрыть диалог. */
  public cancel() {
    this.activeModal.dismiss('cancel');
  }

  ngOnInit(): void {
    this.viewSettings = JSON.parse(this.report.viewSettings);
    this.filters = JSON.parse(this.report.filters);
    this.hasTabs = this.report.isCommon || this.report.isTemplate;
    this.form.patchValue(this.report);
  }
}
