import { Component, Input, ViewChild } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { Wrapper } from './wrapper.interface';

import { UserBoxComponent } from 'src/app/shared/components/controls/user-box/user-box.component';

@Component({
  selector: 'wp-user-box-wrapper',
  template: `<wp-user-box
    [formControl]="control"
    placeholder="{{ placeholder | translate }}"
    [query]="query"
    [autofocus]="autofocus"
    #component
  ></wp-user-box>`,
  standalone: false,
})
export class UserBoxWrapperComponent implements Wrapper {
  @Input() control: UntypedFormControl;
  @Input() placeholder?: string;
  @Input() query?: any = null;
  @Input() autofocus?: boolean;

  @ViewChild('component') component: UserBoxComponent;
}
