import { Injectable, Type } from '@angular/core';
import { ContactListFilterComponent } from 'src/app/contacts/list/contact-list-filter/contact-list-filter.component';
import { FilterDetails } from 'src/app/shared/components/features/filter/filter-details.interface';
import { FilterService } from 'src/app/shared/components/features/filter/filter.service';

@Injectable()
export class ContactListFilterService extends FilterService {
  public override component: Type<FilterDetails> = ContactListFilterComponent;
  public override hasDetails = true;
  public override hasViewSelector = true;
  public override placeholder =
    'components.contactListFilterService.filter.placeholder';
  public override views = [
    { code: 'all', local: 'shared.filter.allEntities' },
    { code: 'active', local: 'shared.filter.onlyActiveEntities' },
  ];
  public override customCriteriaCount: Record<string, () => number> = {
    text: () => 0,
    view: () => 0,
  };

  public hasClient = true;
  public filterFields = [
    'name',
    'position',
    'organization/name',
    'role/name',
    'email',
    'mobilePhone',
    'phone',
  ];

  public override getODataFilter(): any[] {
    const result: any[] = super.getODataFilter();

    let text = this.values.text;
    if (text && text.length >= this.minStringLengthForFilter) {
      text = this.getClearText(text);

      result.push(
        this.getTextFilter(this.filterFields, this.getClearText(text)),
      );
    }

    if (this.values.organization) {
      result.push({
        organizationId: {
          type: 'guid',
          value: this.values.organization.id,
        },
      });
    }

    if (this.values.role) {
      result.push({
        roleId: { type: 'guid', value: this.values.role.id },
      });
    }

    if (
      this.values.view &&
      this.values.view.code === 'active' &&
      this.hasViewSelector
    ) {
      result.push({ isActive: true });
    }

    return result;
  }

  protected override getDefaultValues(): any {
    return {
      text: '',
      role: null,
      organization: null,
      view: this.views[0],
    };
  }
}
