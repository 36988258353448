<form class="modal-form" novalidate ngDraggable>
  <div class="modal-header">
    <h3 class="modal-title">
      {{ modalWindowHeader | translate }}
    </h3>
    <button
      [disabled]="isSaving()"
      type="button"
      class="btn-close"
      data-dismiss="modal"
      aria-hidden="true"
      (click)="cancel()"
    ></button>
  </div>
  <div class="modal-body d-flex flex-column">
    <div class="row">
      <tmt-grid [options]="gridOptions" [formArray]="formArray" [loading]="isLoading()" />
      @if (loadedPartly()) {
        <div class="text-body-secondary">
          {{ 'shared.showFirstNRecordsMessage' | translate: { limit: loadingLimit } }}
          {{ 'shared.refineQueryMessage' | translate }}
        </div>
      }
    </div>
    <div class="row">
      <div class="col">
        <div class="selected-entities-container">
          @for (entity of selectedEntities; track $index) {
            <div class="selected-entity-block" (click)="removeEntity(entity, $index, $event)">
              @if (hasAvatar) {
                <img class="avatar-bg" [src]="entity.id | avatar: 60" />
              }
              <div class="trim" title="{{ entity.name }}">
                {{ entity.name }}
              </div>

              <button type="button" class="close" (click)="removeEntity(entity, $index, $event)">
                &times;
              </button>
            </div>
          }
          @if (!selectedEntities.length) {
            <div class="text-uppercase text-body-secondary no-data">
              {{ noDisplayDataMessage | translate }}
            </div>
          }
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    @if (selectedEntities.length) {
      <button
        type="button"
        class="btn btn-primary"
        (click)="ok()"
        wpButtonSpinner
        [isBusy]="isSaving()"
      >
        {{ 'shared.actions.add' | translate }}
      </button>
    }
    <button type="button" class="btn btn-default" (click)="cancel()" [disabled]="isSaving()">
      {{ 'shared.actions.cancel' | translate }}
    </button>
  </div>
</form>
