@if (!readonly) {
  <input
    #input
    type="checkbox"
    class="form-check-input"
    [checked]="value"
    (change)="onChange($event)"
    [id]="id"
    [disabled]="disabled"
  />
  <label class="form-check-label" [for]="id">
    {{ label }}
  </label>
}
@if (readonly && value) {
  <i class="bi bi-check-lg"></i>
}
